import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import LanguageSelect from 'components/LanguageSelect';
import { MdArrowBackIos } from 'react-icons/md';
import { setToken } from 'store/slices';
import { useDispatch } from 'react-redux';
import useValidationSchema from 'validation/useValidationSchema';
import useApiClient from 'config/useAxiosClient';
import Checkbox from 'components/InputFields/Checkbox';
import InputFieldCustomForm from 'components/CustomFields/CustomFieldsOutlinedForm';
import { useSelector } from 'react-redux';
import { themeInputColor } from 'utils/constant';
import { Input } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Toast from 'components/Toast';
import ToastNotification from 'components/ToastNotification';

const CheckEmail = () => {
  const { themeMode, colorTheme, forgetEmail } = useSelector(
    (state) => state?.states
  );
  const { contextHolder, showMessage } = Toast();
  const { t } = useTranslation('translation');

  const { postRequestAuth } = useApiClient();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { heading, link, oneLineDescription, manyLineDescription } =
    t('CheckEmail');
  const { loginSchema } = useValidationSchema();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      setLoading(true);
      // dispatch(setToken(1234));
      const paylaod = {
        userName: values?.email,
        password: values?.password,
      };
      postRequestAuth(`/Auth`, paylaod)
        .then((res) => {
          dispatch(setToken(res?.token));
        })
        .catch((err) => ToastNotification('error', 'Something went wrong', themeMode))
        .finally(() => setLoading(false));
    },
  });
  return (
    <>
      {contextHolder}
      <div className=" flex">
        <div className="w-full h-[100vh]">
          <div className="w-full h-[90%] flex justify-center items-center px-[20px]">
            <div className="">
              <div
                className="text-[32px] font-bold text-center sm:text-center "
                style={{
                  color: themeMode === 'light' ? colorTheme : '',
                }}
              >
                {heading}
              </div>
              <div className="flex items-center gap-4">
                <div className=" text-[14px] text-[#676E85] mt-[10px] text-center sm:text-center">
                  {oneLineDescription}
                </div>
                <a
                  className="text-[12px] text-[#676E85] mt-[10px] text-center sm:text-center underline font-semiBold"
                  href="mailto:example@example.com"
                >
                  {forgetEmail}
                </a>
              </div>
              <div className=" flex justify-center !mt-18">
                <div
                  className=" flex items-center space-x-5 "
                  onClick={() => {
                    navigate('/login');
                  }}
                >
                  <div
                    className="text-[14px] font-semiBold cursor-pointer underline"
                    style={{
                      color: themeMode === 'light' ? colorTheme : '',
                    }}
                  >
                    {link}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full relative h-[100vh] sm:block hidden">
          <img
            src="/images/images/movingTruck.jpg"
            className="absolute h-[100vh] w-full"
          />
          {/* <img
            src="/images/images/loginBackGround.png"
            className="absolute h-[100vh] w-full"
          />
          <div className="absolute flex flex-col justify-between h-[100vh] px-[50px] py-[100px] ">
            <div className=" space-y-5 ">
              <div className=" flex items-center justify-center">
                <img
                  src="/images/images/TRACKER APP.png"
                  className=" w-[450px] h-[40px]"
                />
              </div>
              <div className=" text-center ml-[20px] pt-[10px]  text-[#FFFFFF] text-[14px] font-reg">
                {manyLineDescription}
              </div>
            </div>
            <div className=" flex justify-center items-center">
              <img
                src="/images/images/loginBackGroundImage1.png"
                className="w-[558.58px] h-[347.11px]"
              />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default CheckEmail;
