import { Button, Modal } from 'antd';
import DatePickerField from 'components/CustomFields/DatePickerField';
import SelectField from 'components/CustomFields/SelectField';
import useAxiosClientTMS from 'config/useAxiosClientTMS';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { documentTypesOptions } from 'utils/constant';
import PictureUpload from './PictureUpload';

const AddDocumentModal = ({
  openDocumentModal,
  setDocumentModal,
  items,
  setItems,
  formikDocuments,
}) => {
  const { themeMode } = useSelector((state) => state?.states);
  const { postRequest } = useAxiosClientTMS();
  const [fileList, setFileList] = useState([]);

  // States
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    setDocumentModal(false);
    formikDocuments.resetForm();
  };

  return (
    <>
      <Modal
        open={openDocumentModal}
        width={400}
        title={<span className="text-[18px]">Add Document</span>}
        onOk={formikDocuments.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formikDocuments.handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <>
          <div className="grid grid-cols-12 ">
            <div className="col-span-12 flex justify-center mb-10">
              <PictureUpload
                formik={formikDocuments}
                id={'documentPicture'}
                label="Upload Document"
                listType="picture-card"
                width={'100%'}
                height={120}
                fileList={fileList}
                setFileList={setFileList}
              />
            </div>
            {/* <div className="col-span-12">
              <TextField
                formik={formikDocuments}
                id={'documentName'}
                label={'Document Name'}
                maxLength={30}
              />
            </div> */}
            <div className="col-span-12 mt-[10px]">
              <SelectField
                formik={formikDocuments}
                id={'documentType'}
                label={'Document Type'}
                optionData={documentTypesOptions.filter(
                  (option) =>
                    !items?.some((item) => item.documentType === option.value)
                )}
                optionId={'value'}
                optionName={'label'}
              />
            </div>
            {/* <div className="col-span-12">
              <SelectField
                formik={formikDocuments}
                id={'entityType'}
                label={'EntityType'}
                optionData={documentOptions}
                optionId={'value'}
                optionName={'label'}
              />
            </div> */}
            <div className="col-span-12">
              <DatePickerField
                formik={formikDocuments}
                id={'issueDate'}
                label={'Issue Date'}
              />
            </div>
            <div className="col-span-12">
              <DatePickerField
                formik={formikDocuments}
                id={'expiryDate'}
                label={'Expiry Date'}
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default AddDocumentModal;
