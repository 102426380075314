import { Breadcrumb, Spin, Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import DatePickerField from 'components/CustomFields/DatePickerField';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
import TextArea from 'components/InputFields/TextArea';
import LabelGrid from 'components/LabelGrid';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import { get_country_by_dropdown } from 'config/services';
import useAxiosClient from 'config/useAxiosClient';
import useAxiosClientTMS from 'config/useAxiosClientTMS';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { usePermissionVariables } from 'Hooks/HasPermission';
import Layout from 'layout/AdminLayout';
import { useEffect, useState } from 'react';
import { BiDownArrowAlt, BiSave, BiUpArrowAlt } from 'react-icons/bi';
import { FaPlus, FaRegNoteSticky } from 'react-icons/fa6';
import { GoHome } from 'react-icons/go';
import { LiaTruckLoadingSolid } from 'react-icons/lia';
import { PiSteeringWheel, PiUsersFourLight } from 'react-icons/pi';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DATE_FORMATS } from 'utils/constant';
import { createOptions } from 'utils/helper';
import {
  GET_COUNTRIES_DROPDOWN_KEY,
  GET_CUSTOMERS_DROPDOWN_KEY,
  GET_DRIVERS_DROPDOWN_KEY,
  GET_LOADS_KEY,
  GET_TRAILERS_DROPDOWN_KEY,
  GET_VEHICLES_DROPDOWN_KEY,
} from 'utils/queryKey';
import useValidationSchema from 'validation/useValidationSchema';

const { Title, Text } = Typography;

const AddLoad = () => {
  const { Logs_View, Status_View, Configurations_View, General_View } =
    usePermissionVariables();
  const { postRequest, useGetRequest } = useAxiosClientTMS();
  const { useGetRequest: useGetRequest2 } = useAxiosClient();
  const queryClient = useQueryClient();
  const [openAddPickupDeliveryModal, setOpenPickupDeliveryModal] =
    useState(false);
  const [openEditPickupDeliveryModal, setOpenEditPickupDeliveryModal] =
    useState(false);
  const [rowPickupDeliveryData, setRowPickupDeliveryData] = useState({});
  const [formDropdownData, setFormDropdownData] = useState({});
  const { addLoadSchema } = useValidationSchema();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [selectorders, setSelectOrders] = useState([]);
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const { data: LoadsData, isLoading: loadLoading } = useGetRequest(
    `/api/GetAllLoads?pagenumber=${pageNumber}&pagesize=${rowsPerPage}&searchquery=${search}`,
    {},
    GET_LOADS_KEY
  );
  const formik = useFormik({
    initialValues: {
      pickupDate: '',
      deliveryDate: '',
      loadStatus: null,
      loadDispatcher: 'ABC',
      pickupAddress: '',
      pickupCountry: null,
      pickupState: null,
      pickupCity: null,
      pickupZipCode: null,
      deliveryCountry: null,
      deliveryAddress: '',
      deliveryState: null,
      deliveryCity: null,
      deliveryZipCode: null,
      customerId: null,
      postOffice: '',
      driverId: null,
      vehicleId: null,
      trailerId: null,
      loadDescription: '',
    },
    validationSchema: addLoadSchema,
    onSubmit: (
      {
        pickupDate,
        deliveryDate,
        loadStatus,
        loadDispatcher,
        pickupCountry,
        pickupState,
        pickupCity,
        pickupZipCode,
        deliveryCountry,
        deliveryState,
        deliveryCity,
        deliveryZipCode,
        customerId,
        postOffice,
        driverId,
        vehicleId,
        trailerId,
        loadDescription,
        pickupAddress,
        deliveryAddress,
      },
      { resetForm }
    ) => {
      const payload = {
        customerId: customerId,
        loadDescription: loadDescription,
        loadStatus: `${loadStatus}`,
        loadDate: dayjs().format(DATE_FORMATS.PAYLOAD_FORMAT),
        totalWeight: 1400,
        loadPoints: [
          {
            pointType: 'pickup',
            location: pickupAddress,
            latitude: 34.0522,
            longitude: -118.2437,
            city: `${formDropdownData?.pickupCity}`,
            state: `${formDropdownData?.pickupState}`,
            zipCode: `${formDropdownData?.pickupZipCode}`,
            scheduledDate: dayjs(pickupDate).format(
              DATE_FORMATS.PAYLOAD_FORMAT
            ),
          },
          {
            pointType: 'delivery',
            location: deliveryAddress,
            latitude: 35.3733,
            longitude: -119.0187,
            city: `${formDropdownData?.deliveryCity}`,
            state: `${formDropdownData?.deliveryState}`,
            zipCode: `${formDropdownData?.deliveryZipCode}`,
            scheduledDate: dayjs(deliveryDate).format(
              DATE_FORMATS.PAYLOAD_FORMAT
            ),
          },
        ],
        loadAssignments: [
          {
            driverId: driverId,
            vehicleId: vehicleId,
            trailerId: trailerId,
            assignmentType: 'pickup',
          },
        ],
      };
      postRequest(`/api/AddLoad`, payload)
        .then((res) => {
          if (res?.success) {
            resetForm();
            setSelectOrders([]);
            queryClient.invalidateQueries({
              queryKey: [GET_LOADS_KEY],
            });
            setFormDropdownData({});
            // navigate('/load-management');
            ToastNotification('success', res?.message, themeMode);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const { data: driverData, isLoading: driverLoading } = useGetRequest(
    '/api/GetdriversDropdown',
    {},
    GET_DRIVERS_DROPDOWN_KEY
  );
  const { data: vehicleData, isLoading: vehicleLoading } = useGetRequest(
    '/api/GetVehiclesDropdown',
    {},
    GET_VEHICLES_DROPDOWN_KEY
  );
  const { data: trailerData, isLoading: trailerLoading } = useGetRequest(
    '/api/GetTrailerDropdown',
    {},
    GET_TRAILERS_DROPDOWN_KEY
  );
  const { data: customerData, isLoading: customerLoading } = useGetRequest(
    '/api/GetCustomerDropdown',
    {},
    GET_CUSTOMERS_DROPDOWN_KEY
  );
  const customerOptions = createOptions(customerData?.data, 'name', 'id');
  const driversOptions = createOptions(driverData?.data, 'name', 'id');
  const vehicleOptions = createOptions(vehicleData?.data, 'number', 'id');
  const trailerOptions = createOptions(
    trailerData?.data,
    'trailerNumber',
    'id'
  );

  const { data: pickupCountryData, isLoading: pickupCountryLoading } =
    useGetRequest2(get_country_by_dropdown, {}, GET_COUNTRIES_DROPDOWN_KEY);
  const {
    data: pickupStateData,
    isLoading: pickupStateLoading,
    refetch: pickupStatesRefetch,
  } = useGetRequest2(`api/StateDropdown?Id=${formik.values.pickupCountry}`, {
    enabled: false,
  });
  const {
    data: pickupCityData,
    isLoading: pickupCityLoading,
    refetch: pickupCitiesRefetch,
  } = useGetRequest2(`api/CitiesDropdown?Id=${formik.values.pickupState}`, {
    enabled: false,
  });
  const {
    data: pickupZipCodeData,
    isLoading: pickupZipCodeLoading,
    refetch: pickupZipCodeRefetch,
  } = useGetRequest2(`/api/ZipCodesDropdown?Id=${formik.values.pickupCity}`, {
    enabled: false,
  });

  const { data: deliveryCountryData, isLoading: deliveryCountryLoading } =
    useGetRequest2(get_country_by_dropdown, {}, GET_COUNTRIES_DROPDOWN_KEY);
  const {
    data: deliveryStateData,
    isLoading: deliveryStateLoading,
    refetch: deliveryStatesRefetch,
  } = useGetRequest2(`api/StateDropdown?Id=${formik.values.deliveryCountry}`, {
    enabled: false,
  });
  const {
    data: deliveryCityData,
    isLoading: deliveryCityLoading,
    refetch: deliveryCitiesRefetch,
  } = useGetRequest2(`api/CitiesDropdown?Id=${formik.values.deliveryState}`, {
    enabled: false,
  });
  const {
    data: deliveryZipCodeData,
    isLoading: deliveryZipCodeLoading,
    refetch: deliveryZipCodeRefetch,
  } = useGetRequest2(`/api/ZipCodesDropdown?Id=${formik.values.deliveryCity}`, {
    enabled: false,
  });

  const pickupCountryOptions = createOptions(
    pickupCountryData?.data,
    'name',
    'countryId'
  );
  const pickupStateOptions = createOptions(
    pickupStateData?.data,
    'name',
    'stateId'
  );
  const pickupCityOptions = createOptions(
    pickupCityData?.data,
    'name',
    'cityId'
  );
  const PickupZipCodeOptions = createOptions(
    pickupZipCodeData?.data,
    'code',
    'zipCodeId'
  );
  const deliveryCountryOptions = createOptions(
    deliveryCountryData?.data,
    'name',
    'countryId'
  );
  const deliveryStateOptions = createOptions(
    deliveryStateData?.data,
    'name',
    'stateId'
  );
  const deliveryCityOptions = createOptions(
    deliveryCityData?.data,
    'name',
    'cityId'
  );
  const deliveryZipCodeOptions = createOptions(
    deliveryZipCodeData?.data,
    'code',
    'zipCodeId'
  );

  const loadColumns = [
    { title: 'Dispatcher', dataIndex: 'loadDispatcher' },
    { title: 'Status', dataIndex: 'loadStatus' },
    {
      title: 'Driver',
      dataIndex: 'driverId',
      render: (text, record) => (
        <>
          <span>{`
            ${record?.driverId}/${record?.trailerId}/${record?.deliveryCity}
          `}</span>
        </>
      ),
    },
    { title: 'Date', dataIndex: 'pickupDate' },
    {
      title: 'Pickup',
      dataIndex: 'pickupCountry',
      render: (text, record) => (
        <>
          <span>{`
            ${record?.pickupCountry}, ${record?.pickupState}, ${record?.pickupCity}, ${record?.pickupZipCode}
          `}</span>
        </>
      ),
    },
    {
      title: 'Delivery',
      dataIndex: 'deliveryCountry',
      render: (text, record) => (
        <>
          <span>{`
            ${record?.deliveryCountry}, ${record?.deliveryState},
            ${record?.deliveryCity}, ${record?.deliveryZipCode}
          `}</span>
        </>
      ),
    },
    { title: 'Customer', dataIndex: 'customerId' },
    { title: 'PO', dataIndex: 'postOffice' },
    { title: 'Description', dataIndex: 'loadDescription' },
  ];

  useEffect(() => {
    if (formik.values.pickupCountry) pickupStatesRefetch();
  }, [formik.values.pickupCountry]);
  useEffect(() => {
    if (formik.values.pickupState) pickupCitiesRefetch();
  }, [formik.values.pickupState]);
  useEffect(() => {
    if (formik.values.pickupCity) pickupZipCodeRefetch();
  }, [formik.values.pickupCity]);

  useEffect(() => {
    if (formik.values.deliveryCountry) deliveryStatesRefetch();
  }, [formik.values.deliveryCountry]);
  useEffect(() => {
    if (formik.values.deliveryState) deliveryCitiesRefetch();
  }, [formik.values.deliveryState]);
  useEffect(() => {
    if (formik.values.deliveryCity) deliveryZipCodeRefetch();
  }, [formik.values.deliveryCity]);

  return (
    <Layout>
      <>
        <div className="mb-[10px] ">
          <Title
            level={4}
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
              fontFamily: 'inter-bold',
            }}
          >
            Add Load
          </Title>
          <div className="flex justify-between items-center mb-0">
            <div>
              <Breadcrumb
                separator="/"
                items={[
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <GoHome />
                          <span
                            className=" cursor-pointer font-reg text-[13px]"
                            onClick={() => {
                              navigate('/dashboard');
                            }}
                          >
                            Dashboard
                          </span>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <LiaTruckLoadingSolid />
                          <span
                            className=" cursor-pointer font-reg text-[13px]"
                            onClick={() => {
                              navigate('/load-management');
                            }}
                          >
                            Load Management
                          </span>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <FaPlus
                            style={{
                              color: themeMode === 'light' ? colorTheme : '',
                            }}
                          />
                          <span
                            className=" font-bold text-[13px]"
                            style={{
                              color: themeMode === 'light' ? colorTheme : '',
                            }}
                          >
                            Add Load
                          </span>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
            </div>
            <div>
              <PrimaryButton
                title="Save"
                icon={<BiSave className=" w-[17px] h-[17px]" />}
                htmlType="submit"
                onClick={() => {
                  formik.handleSubmit();
                }}
                loading={loading}
              />
            </div>
          </div>
        </div>
        <div className="">
          <form onSubmit={formik.handleSubmit}>
            <div className="flex gap-20 place-items-start">
              <div className="grid flex-1">
                <Title
                  style={{
                    color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                    fontFamily: 'inter-bold',
                    marginBottom: '0px',
                    fontSize: '12px',
                  }}
                >
                  New Load
                </Title>
                <div>
                  <SelectField
                    formik={formik}
                    id={'loadStatus'}
                    label={'Status'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={[
                      { value: 'New', label: 'New' },
                      { value: 'Pending', label: 'Pending' },
                      { value: 'Active', label: 'Active' },
                    ]}
                    notModal={true}
                  />
                </div>
                <div>
                  <SelectField
                    formik={formik}
                    id={'loadDispatcher'}
                    label={'Dispatcher'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={[]}
                    notModal={true}
                  />
                </div>
              </div>
              <div className="grid flex-1">
                <Title
                  style={{
                    color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                    fontFamily: 'inter-bold',
                    marginBottom: '0px',
                    fontSize: '12px',
                  }}
                >
                  <div className="flex gap-3">
                    <BiUpArrowAlt size={14} />
                    Pickup
                  </div>
                </Title>
                <div>
                  <DatePickerField
                    formik={formik}
                    id={'pickupDate'}
                    label={'Date'}
                    notModal={true}
                  />
                </div>
                <div>
                  <TextField
                    formik={formik}
                    id={'pickupAddress'}
                    label={'Address'}
                    notModal={true}
                  />
                </div>
                <div className="flex gap-2">
                  <div className="flex-1">
                    <SelectField
                      formik={formik}
                      id={'pickupCountry'}
                      label={'Country'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={pickupCountryOptions}
                      isLoading={pickupCountryLoading}
                      notModal={true}
                      onChange={(newValue, _) => {
                        formik.setFieldValue('pickupCountry', newValue);
                        formik.setFieldValue('pickupState', null);
                        formik.setFieldValue('pickupCity', null);
                        formik.setFieldValue('pickupZipCode', null);
                        setFormDropdownData({
                          ...formDropdownData,
                          pickupCountry: _?.label,
                        });
                      }}
                    />
                  </div>
                  <div className="flex-1">
                    <SelectField
                      formik={formik}
                      id={'pickupState'}
                      label={'State'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={pickupStateOptions}
                      isLoading={pickupStateLoading}
                      notModal={true}
                      onChange={(newValue, _) => {
                        formik.setFieldValue('pickupState', newValue);
                        formik.setFieldValue('pickupCity', null);
                        formik.setFieldValue('pickupZipCode', null);
                        setFormDropdownData({
                          ...formDropdownData,
                          pickupState: _?.label,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="flex gap-2">
                  <div className=" flex-1">
                    <SelectField
                      formik={formik}
                      id={'pickupCity'}
                      label={'City'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={pickupCityOptions}
                      isLoading={pickupCityLoading}
                      notModal={true}
                      onChange={(newValue, _) => {
                        formik.setFieldValue('pickupCity', newValue);
                        formik.setFieldValue('pickupZipCode', null);
                        setFormDropdownData({
                          ...formDropdownData,
                          pickupCity: _?.label,
                        });
                      }}
                    />
                  </div>
                  <div className=" flex-1">
                    <SelectField
                      formik={formik}
                      id={'pickupZipCode'}
                      label={'Zip Code'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={PickupZipCodeOptions}
                      isLoading={pickupZipCodeLoading}
                      notModal={true}
                      onChange={(newValue, _) => {
                        formik.setFieldValue('pickupZipCode', newValue);
                        setFormDropdownData({
                          ...formDropdownData,
                          pickupZipCode: _?.label,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="grid flex-1">
                <Title
                  style={{
                    color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                    fontFamily: 'inter-bold',
                    marginBottom: '0px',
                    fontSize: '12px',
                  }}
                >
                  <div className="flex gap-3">
                    <BiDownArrowAlt size={14} />
                    Delivery
                  </div>
                </Title>
                <div>
                  <DatePickerField
                    formik={formik}
                    id={'deliveryDate'}
                    label={'Date'}
                    notModal={true}
                  />
                </div>
                <div>
                  <TextField
                    formik={formik}
                    id={'deliveryAddress'}
                    label={'Address'}
                    notModal={true}
                  />
                </div>
                <div className="flex gap-2">
                  <div className="flex-1">
                    <SelectField
                      formik={formik}
                      id={'deliveryCountry'}
                      label={'Country'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={deliveryCountryOptions}
                      isLoading={deliveryCountryLoading}
                      notModal={true}
                      onChange={(newValue, _) => {
                        formik.setFieldValue('deliveryCountry', newValue);
                        formik.setFieldValue('deliveryState', null);
                        formik.setFieldValue('deliveryCity', null);
                        formik.setFieldValue('deliveryZipCode', null);
                        setFormDropdownData({
                          ...formDropdownData,
                          deliveryCountry: _?.label,
                        });
                      }}
                    />
                  </div>
                  <div className="flex-1">
                    <SelectField
                      formik={formik}
                      id={'deliveryState'}
                      label={'State'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={deliveryStateOptions}
                      isLoading={deliveryStateLoading}
                      notModal={true}
                      onChange={(newValue, _) => {
                        formik.setFieldValue('deliveryState', newValue);
                        formik.setFieldValue('deliveryCity', null);
                        formik.setFieldValue('deliveryZipCode', null);
                        setFormDropdownData({
                          ...formDropdownData,
                          deliveryState: _?.label,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="flex gap-2">
                  <div className=" flex-1">
                    <SelectField
                      formik={formik}
                      id={'deliveryCity'}
                      label={'City'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={deliveryCityOptions}
                      isLoading={deliveryCityLoading}
                      notModal={true}
                      onChange={(newValue, _) => {
                        formik.setFieldValue('deliveryCity', newValue);
                        formik.setFieldValue('deliveryZipCode', null);
                        setFormDropdownData({
                          ...formDropdownData,
                          deliveryCity: _?.label,
                        });
                      }}
                    />
                  </div>
                  <div className=" flex-1">
                    <SelectField
                      formik={formik}
                      id={'deliveryZipCode'}
                      label={'Zip Code'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={deliveryZipCodeOptions}
                      isLoading={deliveryZipCodeLoading}
                      notModal={true}
                      onChange={(newValue, _) => {
                        formik.setFieldValue('deliveryZipCode', newValue);
                        setFormDropdownData({
                          ...formDropdownData,
                          deliveryZipCode: _?.label,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="grid flex-1">
                <Title
                  style={{
                    color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                    fontFamily: 'inter-bold',
                    marginBottom: '0px',
                    fontSize: '12px',
                  }}
                >
                  <div className="flex gap-3">
                    <PiUsersFourLight size={14} />
                    Customer
                  </div>
                </Title>
                <div className=" ">
                  <SelectField
                    formik={formik}
                    id={'customerId'}
                    label={'Customer'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={customerOptions}
                    isLoading={customerLoading}
                    notModal={true}
                  />
                </div>
                <div>
                  <TextField
                    formik={formik}
                    id={'postOffice'}
                    label={'PO'}
                    maxLength={30}
                    notModal={true}
                  />
                </div>
              </div>
              <div className="grid flex-1">
                <Title
                  style={{
                    color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                    fontFamily: 'inter-bold',
                    marginBottom: '0px',
                    fontSize: '12px',
                  }}
                >
                  <div className="flex gap-3">
                    <PiSteeringWheel size={14} />
                    Driver
                  </div>
                </Title>
                <div className=" ">
                  <SelectField
                    formik={formik}
                    id={'driverId'}
                    label={'Driver'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={driversOptions}
                    isLoading={driverLoading}
                    notModal={true}
                  />
                </div>
                <div className=" ">
                  <SelectField
                    formik={formik}
                    id={'vehicleId'}
                    label={'Vehicle'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={vehicleOptions}
                    isLoading={vehicleLoading}
                    notModal={true}
                  />
                </div>
                <div className=" ">
                  <SelectField
                    formik={formik}
                    id={'trailerId'}
                    label={'Trailer'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={trailerOptions}
                    isLoading={trailerLoading}
                    notModal={true}
                  />
                </div>
              </div>
            </div>
            <div className="grid flex-1">
              <Title
                style={{
                  color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                  fontFamily: 'inter-bold',
                  marginBottom: '0px',
                  fontSize: '12px',
                }}
              >
                <div className="flex gap-3 items-center">
                  <FaRegNoteSticky size={12} />
                  Notes
                </div>
              </Title>

              <div className="grid grid-cols-12">
                <div className="wrapper-fields col-span-6">
                  <TextArea
                    id="loadDescription"
                    name="loadDescription"
                    rows={2}
                    placeholder="Description"
                    required={true}
                    value={formik.values.loadDescription}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.loadDescription &&
                      Boolean(formik.errors.loadDescription)
                    }
                    errorMessage={
                      formik.touched.loadDescription &&
                      formik.errors.loadDescription
                        ? formik.errors.loadDescription
                        : null
                    }
                  />
                </div>
              </div>
            </div>
            <LabelGrid
              label="Load Details"
              className=" rounded-[5px] px-10 py-5"
            >
              <Spin spinning={loadLoading}>
                <Table
                  data={LoadsData?.data || []}
                  columns={loadColumns}
                  pageNumber={pageNumber}
                  setTotalRecord={setTotalRecord}
                  rowsPerPage={rowsPerPage}
                  totalRecord={totalRecord}
                  setPageNumber={setPageNumber}
                  setRowsPerPage={setRowsPerPage}
                  setSortOrder={setSortOrder}
                  setFilters={setFilters}
                  loading={false}
                />
              </Spin>
            </LabelGrid>
          </form>
        </div>
      </>
    </Layout>
  );
};
export default AddLoad;
