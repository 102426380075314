import CheckEmail from 'pages/Auth/CheckEmail';
import ForgotPassword from 'pages/Auth/ForgotPassword';
import Login from 'pages/Auth/Login';
import NewPassword from 'pages/Auth/NewPassword';
import Dashboard from 'pages/Dashboard';
import MasterSettings from 'pages/MasterSettings';
import SubscriptionPlan from 'pages/PlanManagement';
import RoleManagement from 'pages/RoleManagement';
import Role from 'pages/RoleManagement/components/Role';
import AddRole from 'pages/RoleManagement/components/Role/component/AddRole';
import EditRole from 'pages/RoleManagement/components/Role/component/EditRole';
import AssignPermission from 'pages/RoleManagement/components/RoleRights/AssignPermission';
import Security from 'pages/Security';
import Settings from 'pages/Settings';
import TenantManagement from 'pages/Tenant Management';
import TenantSettings from 'pages/TenantSettings';
import TimeSheet from 'pages/TimeSheet';
import DeviceManagement from 'pages/TrackerSettings/DeviceManagement';
import AddNewDevice from 'pages/TrackerSettings/DeviceManagement/components/Devices/components/AddNewDevice';
import EditDevice from 'pages/TrackerSettings/DeviceManagement/components/Devices/components/EditDevice';
import SimManagement from 'pages/TrackerSettings/SimManagement';
import Users from 'pages/Users';
import AddUsers from 'pages/Users/component/AddUsers';
import EditUser from 'pages/Users/component/EditUser';
// import AddDriver from 'pages/TenantSettings/components/Drivers/components/AddDriver/index.';
// import AddVehicle from 'pages/TenantSettings/components/Vehicles/components/AddVehicle';
// import EditVehicle from 'pages/TenantSettings/components/Vehicles/components/EditVehicle';
// import EditDriver from 'pages/TenantSettings/components/Drivers/components/EditDriver';

import CapacityReport from 'pages/CapacityReport';
import Customers from 'pages/Customers';
import Dispatchers from 'pages/Dispatcher';
import AddDispatchers from 'pages/Dispatcher/components/AddDispatcher';
import EditDispatchers from 'pages/Dispatcher/components/EditDispatcher';
import Drivers from 'pages/Drivers';
import AddDriver from 'pages/Drivers/components/AddDriver';
import Details from 'pages/Drivers/components/Details';
import EditDriver from 'pages/Drivers/components/EditDriver';
import FuelEfficiency from 'pages/FuelEfficiency';
import Jobs from 'pages/Jobs';
import AddJobs from 'pages/Jobs/components/AddJobs';
import LoadManagement from 'pages/LoadManagement';
import AddLoad from 'pages/LoadManagement/components/AddLoad';
import Orders from 'pages/Orders';
import AddOrders from 'pages/Orders/components/AddOrders';
import EditOrders from 'pages/Orders/components/EditOrders';
import PickupAndDeliveryStatus from 'pages/PickupAndDeliveryStatus';
import AddSubscriptionPlan from 'pages/PlanManagement/components/AddSubscriptionPlan';
import EditSubscriptionPlan from 'pages/PlanManagement/components/EditSubscriptionPlan';
import RateCardManagement from 'pages/RateCardManagement';
import AddRateCardManagement from 'pages/RateCardManagement/components/AddRateCardManagement';
import AddRoleRights from 'pages/RoleManagement/components/RoleRights/components/AddRoleRights';
import RoutePlanning from 'pages/RoutePlanning';
import StatusBoard from 'pages/StatusBoard';
import AddTenant from 'pages/Tenant Management/components/AddTenant';
import EditTenant from 'pages/Tenant Management/components/EditTenant';
import FleetViewDetail from 'pages/Tms/FleetView/components/FleetViewDetail';
import GeoFencing from 'pages/Tms/FleetView/components/index.jsx';
import FleetView from 'pages/Tms/FleetView/index.jsx';
import AddTrailers from 'pages/Trailer/components/AddTrailer';
import EditTrailers from 'pages/Trailer/components/EditTrailer';
import Trailers from 'pages/Trailer/index.jsx';
import TripOverView from 'pages/TripOverView';
import Unites from 'pages/Unites';
import VehicleDetails from 'pages/VehicleDetails';
import Vehicles from 'pages/Vehicles';
import AddVehicleInformation from 'pages/Vehicles/components/AddVehicleInformation';
import EditVehicleInformation from 'pages/Vehicles/components/EditVehicleInformation';

// List of Routes name using for links and route
export const routes = Object.freeze({
  ROUTE_LOGIN: '/login',
  ROUTE_FORGOT_PASSWORD: '/forgot-password',
  ROUTE_NEW_PASSWORD: '/reset-password',
  ROUTE_CHECK_EMAIL: '/check-email',
  ROUTE_DASHBOARD: '/dashboard',
  ROUTE_SECURITY: '/security',
  ROUTE_USERS: '/users',
  ROUTE_ROLE: '/role',
  ROUTE_ADD_ROLE: '/add-role',
  ROUTE_EDIT_ROLE: '/edit-role',
  ROUTE_ADD_USER: '/add-user',
  ROUTE_Edit_USER: '/edit-user',
  ROUTE_TIME_SHEET: '/time-sheet',
  ROUTE_SETTINGS: '/settings',
  ROUTE_SUBSCRIPTION_PLAN: '/plan-management',
  ROUTE_ADD_SUBSCRIPTION_PLAN: '/add-subscription-plan',
  ROUTE_EDIT_SUBSCRIPTION_PLAN: '/edit-subscription-plan/:id',
  ROUTE_ROLE_MANAGEMENT: '/role-management',
  ROUTE_ROLE_RIGHTS: '/add-rolerights',
  ROUTE_ASSIGN_PERMISSION: '/assign-permission/:id',
  ROUTE_DEVICE_MANAGEMENT: '/device-management',
  ROUTE_ADD_NEW_DEVICE: '/add-new-device',
  ROUTE_EDIT_DEVICE: '/edit-device/:id',
  ROUTE_SIM_MANAGEMENT: '/sim-management',
  ROUTE_TENANT_MANAGEMENT: '/tenant-management',
  ROUTE_ADD_TENANT: '/add-tenant',
  ROUTE_EDIT_TENANT: '/edit-tenant/:id',
  ROUTE_TENANT_SETTING: '/tenant-settings',
  // ROUTE_ADD_VEHICLE: '/add-vehicle',
  // ROUTE_EDIT_VEHICLE: '/edit-vehicle/:id',
  ROUTE_ADD_DRIVER: '/add-driver',
  ROUTE_EDIT_DRIVER: '/edit-driver/:id',
  ROUTE_DETAIL_DRIVER: '/driver-detail/:id',
  ROUTE_GET_DISPATCHER: '/dispatcher',
  ROUTE_ADD_DISPATCHER: '/add-dispatcher',
  ROUTE_EDIT_DISPATCHER: '/edit-dispatcher/:id',
  ROUTE_MASTER_SETTING: '/master-setting',
  ROUTE_STATUS_BOARD: '/status-board',
  ROUTE_ORDERS: '/orders',
  ROUTE_ADD_ORDERS: '/add-order',
  ROUTE_EDIT_ORDERS: '/edit-order/:id',
  ROUTE_UNITES: '/unites',
  ROUTE_JOBS: '/jobs',
  ROUTE_ADD_JOBS: '/add-jobs',
  ROUTE_ADD_CUSTOMER: '/add-customer',
  ROUTE_CUSTOMERS: '/customers',
  ROUTE_RATE_CARD_MANAGEMENT: '/rate-card-management',
  ROUTE_ADD_RATE_CARD_MANAGEMENT: '/add-rate-card-management',
  ROUTE_VEHICLES: '/vehicles',
  ROUTE_VEHICLES_DETAILS: '/vehicles-details/:tenantId/:vehicleId',
  ROUTE_ADD_VEHICLES_INFORMATION: '/add-vehicle-information',
  ROUTE_EDIT_VEHICLES_INFORMATION: '/edit-vehicle-information/:id',
  ROUTE_DRIVERS: '/drivers',
  ROUTE_LOAD_MANAGEMENT: '/load-management',
  ROUTE_ADD_LOAD: '/add-load-management',
  ROUTE_EDIT_LOAD: '/edit-load-management/:id',
  ROUTE_FLEETVIEW: '/fleet-view',
  ROUTE_FLEETVIEW_DETAIL: '/fleet-view-detail/:id',
  ROUTE_TRAILERS: '/trailers',
  ROUTE_ADD_TRAILERS: '/add-trailer',
  ROUTE_EDD_TRAILERS: '/edit-trailer/:id',
  ROUTE_TRIP_OVERVIEW: '/trip-overview',
  ROUTE_GEO_FENCING: '/geo-fencing',
  ROUTE_FUEL_EFFICIENCY: '/fuel-efficiency',
  ROUTE_PICKUP_AND_DELIVERY_STATUS: '/pickup-and-delivery-status',
  ROUTE_CAPACITY_REPORT: '/capacity-report',
  ROUTE_ROUTE_PLANNING: '/route-planning',
});
export const Permissions = Object.freeze({
  Users_View: 'User.View',
  Role_View: 'Role.View',
  Menu_View: 'Menu.View',
  Pages_View: 'Pages.View',
  Role_Rights_View: 'Role Rights.View',
});
export const AllRoutes = [
  {
    path: routes.ROUTE_LOGIN,
    page: <Login />,
    isPrivate: false,
  },
  {
    path: routes.ROUTE_FORGOT_PASSWORD,
    page: <ForgotPassword />,
    isPrivate: false,
  },
  {
    path: routes.ROUTE_CHECK_EMAIL,
    page: <CheckEmail />,
    isPrivate: false,
  },
  {
    path: routes.ROUTE_NEW_PASSWORD,
    page: <NewPassword />,
    isPrivate: false,
  },
  {
    path: routes.ROUTE_DASHBOARD,
    page: <Dashboard />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_SECURITY,
    page: <Security />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_USERS,
    page: <Users />,
    isPrivate: true,
    isPermission: Permissions.Users_View,
  },
  {
    path: routes.ROUTE_ROLE,
    page: <Role />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_ADD_ROLE,
    page: <AddRole />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_EDIT_ROLE,
    page: <EditRole />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_ADD_USER,
    page: <AddUsers />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_Edit_USER,
    page: <EditUser />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_TIME_SHEET,
    page: <TimeSheet />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_SUBSCRIPTION_PLAN,
    page: <SubscriptionPlan />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_ADD_SUBSCRIPTION_PLAN,
    page: <AddSubscriptionPlan />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_EDIT_SUBSCRIPTION_PLAN,
    page: <EditSubscriptionPlan />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_SETTINGS,
    page: <Settings />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_ROLE_MANAGEMENT,
    page: <RoleManagement />,
    isPrivate: true,
    isPermission: [
      Permissions.Role_Rights_View,
      Permissions.Pages_View,
      Permissions.Role_View,
      Permissions.Menu_View,
    ],
  },
  {
    path: routes.ROUTE_ROLE_RIGHTS,
    page: <AddRoleRights />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_ASSIGN_PERMISSION,
    page: <AssignPermission />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_DEVICE_MANAGEMENT,
    page: <DeviceManagement />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_ADD_NEW_DEVICE,
    page: <AddNewDevice />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_EDIT_DEVICE,
    page: <EditDevice />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_SIM_MANAGEMENT,
    page: <SimManagement />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_TENANT_MANAGEMENT,
    page: <TenantManagement />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_ADD_TENANT,
    page: <AddTenant />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_EDIT_TENANT,
    page: <EditTenant />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_TENANT_SETTING,
    page: <TenantSettings />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_VEHICLES,
    page: <Vehicles />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_VEHICLES_DETAILS,
    page: <VehicleDetails />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_ADD_VEHICLES_INFORMATION,
    page: <AddVehicleInformation />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_EDIT_VEHICLES_INFORMATION,
    page: <EditVehicleInformation />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_DRIVERS,
    page: <Drivers />,
    isPrivate: true,
  },

  // {
  //   path: routes.ROUTE_ADD_VEHICLE,
  //   page: <AddVehicle />,
  //   isPrivate: true,
  // },
  // {
  //   path: routes.ROUTE_EDIT_VEHICLE,
  //   page: <EditVehicle />,
  //   isPrivate: true,
  // },
  {
    path: routes.ROUTE_ADD_DRIVER,
    page: <AddDriver />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_EDIT_DRIVER,
    page: <EditDriver />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_DETAIL_DRIVER,
    page: <Details />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_MASTER_SETTING,
    page: <MasterSettings />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_STATUS_BOARD,
    page: <StatusBoard />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_ORDERS,
    page: <Orders />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_ADD_ORDERS,
    page: <AddOrders />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_EDIT_ORDERS,
    page: <EditOrders />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_UNITES,
    page: <Unites />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_JOBS,
    page: <Jobs />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_ADD_JOBS,
    page: <AddJobs />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_CUSTOMERS,
    page: <Customers />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_RATE_CARD_MANAGEMENT,
    page: <RateCardManagement />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_ADD_RATE_CARD_MANAGEMENT,
    page: <AddRateCardManagement />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_LOAD_MANAGEMENT,
    page: <LoadManagement />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_ADD_LOAD,
    page: <AddLoad />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_FLEETVIEW,
    page: <FleetView />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_FLEETVIEW_DETAIL,
    page: <FleetViewDetail />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_TRAILERS,
    page: <Trailers />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_ADD_TRAILERS,
    page: <AddTrailers />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_EDD_TRAILERS,
    page: <EditTrailers />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_TRIP_OVERVIEW,
    page: <TripOverView />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_GEO_FENCING,
    page: <GeoFencing />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_FUEL_EFFICIENCY,
    page: <FuelEfficiency />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_PICKUP_AND_DELIVERY_STATUS,
    page: <PickupAndDeliveryStatus />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_CAPACITY_REPORT,
    page: <CapacityReport />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_ROUTE_PLANNING,
    page: <RoutePlanning />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_GET_DISPATCHER,
    page: <Dispatchers />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_ADD_DISPATCHER,
    page: <AddDispatchers />,
    isPrivate: true,
  },
  {
    path: routes.ROUTE_EDIT_DISPATCHER,
    page: <EditDispatchers />,
    isPrivate: true,
  },
];
