import React from 'react';
import Layout from 'layout/AdminLayout';
import { Breadcrumb } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'antd';
import { Tabs } from 'antd';
import { GoHome } from 'react-icons/go';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { MdAdminPanelSettings } from 'react-icons/md';
import { BsThreeDots } from 'react-icons/bs';

import { usePermissionVariables } from 'Hooks/HasPermission';
const { Title } = Typography;

const Rules = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { Email_Template_View } = usePermissionVariables();
  const onChange = (key) => {};
  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  const items = [
    {
      key: '1',
      label: 'Rule 1',
      children: '',
    },
    {
      key: '2',
      label: 'Rule 2',
      children: '',
    },
    {
      key: '3',
      label: 'Role 3',
      children: '',
    },
    {
      key: '4',
      label: 'Rule 4',
      children: '',
    },
    {
      key: '5',
      label: 'Rule 5',
      children: '',
    },
  ];

  return (
    <>
      <div className=" mb-[30px] ">
        <div className="mt-6">
          <Tabs
            // tabPosition={isMobile ? 'left' : ''}
            defaultActiveKey="1"
            items={items}
            // type="card"
            centered
            onChange={onChange}
            moreIcon={
              <span>
                <BsThreeDots
                  style={{
                    color: themeMode === 'light' ? colorTheme : '#939BC9',
                  }}
                />
              </span>
            }
            size="small"
          />
        </div>
      </div>
    </>
  );
};
export default Rules;
