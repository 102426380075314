import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import LanguageSelect from 'components/LanguageSelect';
import { MdArrowBackIos } from 'react-icons/md';
import { setForgetEmail, setToken } from 'store/slices';
import { useDispatch } from 'react-redux';
import useValidationSchema from 'validation/useValidationSchema';
import useApiClient from 'config/useAxiosClient';
import Checkbox from 'components/InputFields/Checkbox';
import InputFieldCustomForm from 'components/CustomFields/CustomFieldsOutlinedForm';
import { useSelector } from 'react-redux';
import { themeInputColor } from 'utils/constant';
import { Input } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Toast from 'components/Toast';
import ToastNotification from 'components/ToastNotification';
import TextField from 'components/CustomFields/TextField';

const ForgotPassword = () => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { contextHolder, showMessage } = Toast();
  const { t } = useTranslation('translation');
  const { email } = t('Label');
  const { postRequestAuth, postRequest } = useApiClient();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    heading,
    link,
    oneLineDescription,
    manyLineDescription,
    resetPasswordButton,
  } = t('ForgotPassword');
  const { sendPasswordAtEmailSchema } = useValidationSchema();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: sendPasswordAtEmailSchema,
    onSubmit: ({ email }) => {
      setLoading(true);
      const paylaod = {
        useremail: email,
      };
      postRequest(`/api/User/ForgotPassword`, paylaod)
        .then((res) => {
          if (res?.success) {
            ToastNotification('success', res?.message, themeMode);
            setLoading(false);
            dispatch(setForgetEmail(email));
            navigate('/check-email');
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => setLoading(false));
    },
  });
  return (
    <>
      {contextHolder}
      <div className=" flex">
        <div className="w-full h-[100vh]">
          <div className="w-full h-[90%] flex justify-center items-center px-[20px]">
            <div className="w-[400px]">
              <div
                className="text-[32px] font-bold text-center"
                style={{
                  color: themeMode === 'light' ? colorTheme : '',
                }}
              >
                {heading}
              </div>
              <div className=" text-[14px] text-[#676E85] mt-[10px] text-center">
                {oneLineDescription}
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="space-y-[40px]  sm:space-y-[16px] mt-[20px]">
                  <TextField
                    formik={formik}
                    id={'email'}
                    label={email}
                    notModal={true}
                  />
                  <div className=" flex justify-end !mt-26">
                    <div
                      className=" flex items-center space-x-5 "
                      onClick={() => {
                        navigate('/login');
                      }}
                    >
                      <div
                        className="text-[13px] font-semiBold cursor-pointer underline"
                        style={{
                          color: themeMode === 'light' ? colorTheme : '',
                        }}
                      >
                        {link}
                      </div>
                    </div>
                  </div>
                  <PrimaryButton
                    className="w-full !rounded-[6px] font-med text-[12px] mt-0 h-[38px]"
                    title={resetPasswordButton}
                    type="submit"
                    loading={loading}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="w-full relative h-[100vh] sm:block hidden">
          <img
            src="/images/images/movingTruck.jpg"
            className="absolute h-[100vh] w-full"
          />
          {/* <img
            src="/images/images/loginBackGround.png"
            className="absolute h-[100vh] w-full"
          />
          <div className="absolute flex flex-col justify-between h-[100vh] px-[50px] py-[100px] ">
            <div className=" space-y-5 ">
              <div className=" flex items-center justify-center">
                <img
                  src="/images/images/TRACKER APP.png"
                  className=" w-[450px] h-[40px]"
                />
              </div>
              <div className=" text-center ml-[20px] pt-[10px]  text-[#FFFFFF] text-[14px] font-reg">
                {manyLineDescription}
              </div>
            </div>
            <div className=" flex justify-center items-center">
              <img
                src="/images/images/loginBackGroundImage1.png"
                className="w-[558.58px] h-[347.11px]"
              />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
