import React from 'react';
import { Collapse } from 'antd';

const Accordion = ({ items, ...rest }) => {
  return (
    <>
      <Collapse accordion size="small" items={items} {...rest} />
    </>
  );
};
export default Accordion;
