import { QuestionCircleOutlined } from '@ant-design/icons';
import { Breadcrumb, Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Dropdown from 'components/Dropdown';
import SearchField from 'components/InputFields/SearchField';
import PopConfirm from 'components/PopConfirm';
import Status from 'components/Status';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import useAxiosClientTMS from 'config/useAxiosClientTMS';import { PiUniteBold } from "react-icons/pi";
import { usePermissionVariables } from 'Hooks/HasPermission';
import Layout from 'layout/AdminLayout';
import { useEffect, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaPlus } from 'react-icons/fa6';
import { GoHome } from 'react-icons/go';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { PiUsersFourFill } from 'react-icons/pi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddUnitesModal from './components/AddUnitesModal';
import EditUnitesModal from './components/EditUnitesModal';

const { Title } = Typography;

const Unites = () => {
  const { useGetRequest, deleteRequest } = useAxiosClientTMS();
  const { Customers_Delete, Customers_Edit, Customers_Add } =
    usePermissionVariables();

  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  // States
  const [openAddUnitesModal, setOpenUnitesModal] = useState(false);
  const [openEditUnitesModal, setOpenEditUnitesModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowData, setRowData] = useState({});
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [search, setSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchLoading, setSearchLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const { data, error, isLoading, refetch } = useGetRequest(
    `api/GetAllUnits?pagenumber=${pageNumber}&pagesize=${rowsPerPage}&searchquery=${search}`
  );
  const delHandler = (record) => {
    deleteRequest(`/api/DeleteUnit?id=${record?.unitId}`)
      .then((res) => {
        if (res?.success) {
          const newTotalRecord = totalRecord - 1; // Decrement the total record count
          setTotalRecord(newTotalRecord); // Update the state

          // Check if we need to change the page number
          if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1); // Redirect to the previous page
          }
          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };
  const columns = [
    {
      title: <div className="flex justify-between items-center">Unit Name</div>,
      ellipsis: true,
      dataIndex: 'unitName',
      sorter: (a, b) => a.unitName.localeCompare(b.unitName),
      sortDirections: ['ascend', 'descend'],
      sortOrder: sortOrder?.column?.dataIndex === 'unitName' ? 'descend' : null,
      defaultSortOrder: 'ascend',
      showSorterTooltip: false,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: (
        <div className="flex justify-between items-center">Driver Name</div>
      ),
      ellipsis: true,
      dataIndex: 'driverName',
      sorter: (a, b) => a.driverName.localeCompare(b.driverName),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'driverName' ? 'descend' : null,
      // defaultSortOrder: 'ascend',
      showSorterTooltip: false,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: 'Vehicle Number',
      dataIndex: 'vehicleNumber',
      ellipsis: true,
      sorter: (a, b) => a.vehicleNumber.localeCompare(b.vehicleNumber),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'vehicleNumber'
          ? sortOrder.order
          : null,
      defaultSortOrder: 'ascend',
      showSorterTooltip: false,
    },
    {
      title: 'Trailer Number',
      dataIndex: 'trailerNumber',
      ellipsis: true,
      sorter: (a, b) => a.trailerNumber.localeCompare(b.trailerNumber),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'trailerNumber'
          ? sortOrder.order
          : null,
      defaultSortOrder: 'ascend',
      showSorterTooltip: false,
    },
    {
      title: <span className="flex items-center justify-center">Status</span>,
      ellipsis: true,
      dataIndex: 'isActive',

      render: (tag, record) => {
        return (
          <span className="flex items-center justify-center">
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },
    {
      title: <span className=" flex items-center justify-center">Action</span>,
      dataIndex: '',
      ellipsis: true,
      width: 100,
      render: (text, record) => (
        <div className="flex justify-center">
          <Dropdown
            overlayClassName={`${
              themeMode === 'light'
                ? 'action-dropdown-light'
                : 'action-dropdown-dark'
            }`}
            items={[
              {
                key: '1',
                label: (
                  <div
                    className="p-[2px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                ),
                onClick: () => {
                  setOpenEditUnitesModal(true);
                  setRowData(record);
                },
              },

              {
                key: '2',
                label: (
                  <div
                    className="p-[2px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this task?"
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={
                        <div className="flex items-center justify-center">
                          <QuestionCircleOutlined
                            style={{
                              color: 'red',
                              marginTop: '5px',
                            }}
                          />
                        </div>
                      }
                    >
                      <MdDeleteOutline className="w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                ),
              },
            ]}
          >
            <div className="!underline cursor-pointer hover:underline">
              <BsThreeDotsVertical className="w-[15px] h-[15px] cursor-pointer" />
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (data) {
      setTotalRecord(data?.totalRecords);
    }
  }, [data]);
  return (
    <Layout>
      <>
        <div>
          <div className=" mb-[30px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
              }}
            >
              TMS
            </Title>
            <div className="flex justify-between items-center ">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className="flex items-center space-x-4 ">
                            <PiUniteBold
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />

                            <span
                              className="font-bold text-[13px]"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              Units
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="md:flex space-y-[20px] md:space-y-0 justify-between mt-10 mb-26">
            <SearchField
              htmlFor="search"
              id="myInputID"
              name="search"
              placeholder={'Search by Customer Name'}
              value={debouncedSearch}
              setDebouncedSearch={setDebouncedSearch}
              setPageNumber={setPageNumber}
              searchLoading={searchLoading}
              setSearchLoading={setSearchLoading}
              refetch={refetch}
              debouncedSearch={debouncedSearch}
              setSearch={setSearch}
            />
            {Customers_Add && (
              <div className="sm:flex justify-end">
                <PrimaryButton
                  title="Add Units"
                  icon={<FaPlus />}
                  onClick={() => {
                    setOpenUnitesModal(true);
                  }}
                />
              </div>
            )}
          </div>
          <div>
            <Table
              data={data?.data || []}
              columns={columns}
              pageNumber={pageNumber}
              setTotalRecord={setTotalRecord}
              rowsPerPage={rowsPerPage}
              totalRecord={totalRecord}
              setPageNumber={setPageNumber}
              setRowsPerPage={setRowsPerPage}
              setSortOrder={setSortOrder}
              setFilters={setFilters}
              loading={isLoading}
            />
          </div>
        </div>
        {openAddUnitesModal && (
          <AddUnitesModal
            setOpenUnitesModal={setOpenUnitesModal}
            openAddUnitesModal={openAddUnitesModal}
            refetch={refetch}
          />
        )}
        {openEditUnitesModal && rowData && (
          <EditUnitesModal
            setOpenEditUnitesModal={setOpenEditUnitesModal}
            openEditUnitesModal={openEditUnitesModal}
            record={rowData}
            refetch={refetch}
          />
        )}
      </>
    </Layout>
  );
};
export default Unites;
