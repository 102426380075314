import { Button, Modal } from 'antd';
import DatePickerField from 'components/CustomFields/DatePickerField';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
import TextArea from 'components/InputFields/TextArea';
import ToastNotification from 'components/ToastNotification';
import useAxiosClientTMS from 'config/useAxiosClientTMS';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { createOptions } from 'utils/helper';
import useValidationSchema from 'validation/useValidationSchema';
const maintenanceData = [
  { label: 'Oil Change', value: 'Oil Change' },
  { label: 'Engine Work', value: 'Engine Work' },
  { label: 'Electrical Work', value: 'Electrical Work' },
  { label: 'Tyre Replacement', value: 'Tyre Replacement' },
  { label: 'Suspension', value: 'Suspension' },
  { label: 'Body Maintenance', value: 'Body Maintenance' },
];
const EditVehicleMaintenanceModal = ({
  openEditVehicleMaintenanceModal,
  setOpenEditVehicleMaintenanceModal,
  rowData,
  refetch,
}) => {
  const { themeMode } = useSelector((state) => state?.states);
  const queryClient = useQueryClient();
  const { putRequest, useGetRequest } = useAxiosClientTMS();
  const [loading, setLoading] = useState(false);
  const { vehicleMaintenanceModalSchema } = useValidationSchema();
  const { data: vehicleData, isLoading: vehicleLoading } = useGetRequest(
    `/api/GetVehiclesDropdown`,
    {
      refetchOnMount: 'always',
    }
  );
  const vehicleOptions = createOptions(vehicleData?.data, 'number', 'id');
  const formik = useFormik({
    initialValues: {
      maintenanceType: '',
      cost: '',
      maintenanceDate: '',
      nextScheduledDate: '',
      description: '',
    },
    validationSchema: vehicleMaintenanceModalSchema,
    onSubmit: ({
      maintenanceType,
      cost,
      maintenanceDate,
      nextScheduledDate,
      description,
    }) => {
      setLoading(true);
      const paylaod = {
        maintenanceId: rowData?.maintenanceId,
        vehicleId: rowData?.vehicleId,
        tenantId: rowData?.tenantId,
        maintenanceType: maintenanceType,
        description: description,
        maintenanceDate: dayjs(maintenanceDate).format(
          'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
        ),
        cost: cost,
        nextScheduledDate: dayjs(nextScheduledDate).format(
          'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
        ),
      };
      putRequest(`/api/UpdateVehicleMaintenance`, paylaod)
        .then((res) => {
          if (res?.success) {
            refetch();
            formik.resetForm();
            ToastNotification('success', res.message, themeMode);
            setOpenEditVehicleMaintenanceModal(false);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCancel = () => {
    setOpenEditVehicleMaintenanceModal(false);
    formik.resetForm();
  };
  useEffect(() => {
    if (rowData) {
      formik.setValues({
        maintenanceType: rowData?.maintenanceType,
        cost: rowData?.cost,
        maintenanceDate: dayjs(rowData?.maintenanceDate),
        nextScheduledDate: dayjs(rowData?.nextScheduledDate),
        description: rowData?.description,
      });
    }
  }, [rowData, openEditVehicleMaintenanceModal]);

  return (
    <>
      <Modal
        open={openEditVehicleMaintenanceModal}
        title={<span className=" text-[18px]">Edit Vehicle Maintenance</span>}
        width={600}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <>
          <div className=" grid grid-cols-12 gap-x-20 py-[10px]">
            <div className="col-span-6">
              <SelectField
                formik={formik}
                id={'maintenanceType'}
                label={'Maintenance Type'}
                optionId={'value'}
                optionName={'label'}
                optionData={maintenanceData}
              />
            </div>
            <div className="col-span-6">
              <TextField
                formik={formik}
                id={'cost'}
                label={'Cost'}
                maxLength={30}
              />
            </div>
            <div className="col-span-6">
              <DatePickerField
                formik={formik}
                id={'maintenanceDate'}
                label={'Maintenance Date'}
              />
            </div>
            <div className="col-span-6">
              <DatePickerField
                formik={formik}
                id={'nextScheduledDate'}
                label={'Next Scheduled Date'}
              />
            </div>
            <div className=" col-span-12">
              <div className="wrapper-fields">
                <TextArea
                  id="description"
                  name="description"
                  rows={4}
                  placeholder="Description"
                  required={true}
                  maxLength={250}
                  value={formik.values.description}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldTouched('description', true, false);
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  errorMessage={
                    formik.touched.description && formik.errors.description
                      ? formik.errors.description
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default EditVehicleMaintenanceModal;
