import { QuestionCircleOutlined } from '@ant-design/icons';
import { usePermissionVariables } from 'Hooks/HasPermission';
import { Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SearchField from 'components/InputFields/SearchField';
import PopConfirm from 'components/PopConfirm/index';
import Status from 'components/Status';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import { delete_currency } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import AddCurrenciesModal from './components/AddCurrenciesModal';
import EditCurrenciesModal from './components/EditCurrenciesModal';
import { getRowNumber } from 'utils/helper';
const { Text } = Typography;
const Currencies = () => {
  const {
    Currencies_Delete,
    Currencies_Edit,
    Currencies_Add,
    Currencies_View,
  } = usePermissionVariables();
  const [openAddCurrenciesModal, setOpenAddCurrenciesModal] = useState(false);
  const [openEditCurrenciesModal, setOpenEditCurrenciesModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({
    code: '',
    name: '',
    symbol: '',
    isActive: false,
    id: '',
  });
  const [pageNumber, setPageNumber] = useState(1);
  const { useGetRequest, deleteRequest } = useApiClient();
  const [totalRecord, setTotalRecord] = useState(10);
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { data, isLoading, refetch } = useGetRequest(
    `api/GetAllCurrencies?pagenumber=${pageNumber}&pagesize=${rowsPerPage}&searchquery=${debouncedSearch}`
  );

  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const { themeMode } = useSelector((state) => state?.states);

  const delHandler = (record) => {
    deleteRequest(`${delete_currency}${record?.currencyId}`)
      .then((res) => {
        if (res?.success) {
          const newTotalRecord = totalRecord - 1; // Decrement the total record count
          setTotalRecord(newTotalRecord); // Update the state

          // Check if we need to change the page number
          if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1); // Redirect to the previous page
          }
          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };

  const columns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      key: 'sr',
      // align: 'center',
      ellipsis: true,
      width: 80,
      render: (text, row, index) =>
        getRowNumber(pageNumber, rowsPerPage, index),
    },
    {
      title: (
        <div className="flex justify-between items-center">Currency Name</div>
      ),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
      ellipsis: true,
      sortOrder:
        sortOrder?.column?.dataIndex === 'name' ? sortOrder.order : 'ascend',
      showSorterTooltip: false,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    { title: 'Currency Code', dataIndex: 'code', key: 'code', ellipsis: true },
    {
      title: (
        <span className="flex items-center justify-center">
          Currency Symbol
        </span>
      ),
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      ellipsis: true,
      render: (text, record) => {
        return (
          <span>
            <Text code className=" font-bold">
              {record?.symbol}
            </Text>
          </span>
        );
      },
    },

    // {
    //   title: 'Created Date',
    //   dataIndex: 'createdDate',
    //   key: 'createdDate',
    //   ellipsis: true,
    //   render: (data, record) => convertDateFormat(record.createdDate),
    // },
    {
      title: <span className="flex items-center justify-center">Status</span>,
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      width: 300,
      ellipsis: true,
      render: (tag, record) => {
        return (
          <span>
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },
    ...(Currencies_Edit || Currencies_Delete
      ? [
          {
            title: (
              <span className=" flex items-center justify-center">Action</span>
            ),
            dataIndex: '',
            ellipsis: true,
            width: 100,
            render: (text, record) => (
              <span className="flex justify-center items-center space-x-2">
                {Currencies_Edit && (
                  <div
                    className=" p-[5px] rounded-sm"
                    onClick={() => {
                      setSelectedRecord({
                        code: record?.code,
                        name: record?.name,
                        symbol: record?.symbol,
                        isActive: record?.isActive,
                        id: record?.currencyId,
                      });
                      setOpenEditCurrenciesModal(true);
                    }}
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                )}
                {Currencies_Delete && (
                  <div
                    className=" p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this currency? "
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    >
                      <MdDeleteOutline className=" w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                )}
              </span>
            ),
          },
        ]
      : []),
  ];
  useEffect(() => {
    if (data) {
      setTotalRecord(data?.totalRecords);
      refetch();
    }
  }, [data]);
  return (
    <div>
      <div className="md:flex space-y-[20px] md:space-y-0 justify-between mt-10 mb-26">
        <SearchField
          htmlFor="search"
          id="myInputID"
          name="search"
          placeholder={'Search by Currency Name'}
          value={debouncedSearch}
          setDebouncedSearch={setDebouncedSearch}
          setPageNumber={setPageNumber}
          searchLoading={searchLoading}
          setSearchLoading={setSearchLoading}
          refetch={refetch}
          debouncedSearch={debouncedSearch}
          setSearch={setSearch}
        />
        {Currencies_Add && (
          <div className="sm:flex justify-end">
            <PrimaryButton
              title="Add Currencies"
              icon={<FaPlus />}
              onClick={() => setOpenAddCurrenciesModal(true)}
            />
          </div>
        )}
      </div>
      <Table
        data={data?.data}
        columns={columns}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        setTotalRecord={setTotalRecord}
        totalRecord={totalRecord}
        setPageNumber={setPageNumber}
        setRowsPerPage={setRowsPerPage}
        setSortOrder={setSortOrder}
        setFilters={setFilters}
        loading={isLoading}
      />
      {openAddCurrenciesModal && (
        <AddCurrenciesModal
          setOpenAddCurrenciesModal={setOpenAddCurrenciesModal}
          openAddCurrenciesModal={openAddCurrenciesModal}
          refetch={refetch}
        />
      )}
      {openEditCurrenciesModal && (
        <EditCurrenciesModal
          setOpenEditCurrenciesModal={setOpenEditCurrenciesModal}
          openEditCurrenciesModal={openEditCurrenciesModal}
          selectedRecord={selectedRecord}
          refetch={refetch}
        />
      )}
    </div>
  );
};
export default Currencies;
