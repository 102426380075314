import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setColorTheme } from 'store/slices';
import ToastNotification from 'components/ToastNotification';
import { colorOptions } from 'utils/constant';

const ColorBlock = ({ color, label, themeMode, handleColorChange }) => (
  <div
    className="relative cursor-pointer rounded-[6px] flex flex-col items-center justify-center transition-all duration-300 ease-in-out transform hover:scale-120 hover:-translate-y-5 hover:z-10"
    style={{ backgroundColor: color, width: '70px', height: '40px' }}
    onClick={() => handleColorChange(color, label)}
  >
    <div className="text-white text-[13px]">{label}</div>
  </div>
);

const ColorPaletteModal = ({ setColorPaletteModal, openColorPaletteModal }) => {
  const { themeMode } = useSelector((state) => state?.states);
  const dispatch = useDispatch();

  // Define your color options as an array


  const handleColorChange = (color, label) => {
    dispatch(setColorTheme(color));
    handleCancel();
    ToastNotification(
      'success',
      `Theme Change Successfully to ${label}`,
      themeMode
    );
  };

  const handleCancel = () => setColorPaletteModal(false);

  return (
    <>
      <Modal
        open={openColorPaletteModal}
        onCancel={handleCancel}
        title={<span className="text-[18px]">Choose Color</span>}
        width={450} // Updated width to 500px
        footer={null}
      >
        <div className="py-[10px] px-[10px] flex flex-wrap gap-x-4 gap-y-8 ">
          {colorOptions?.map(({ value, label }) => (
            <ColorBlock
              key={value}
              color={value}
              label={label}
              themeMode={themeMode}
              handleColorChange={handleColorChange}
            />
          ))}
        </div>
      </Modal>
    </>
  );
};

export default ColorPaletteModal;
