import { QuestionCircleOutlined } from '@ant-design/icons';
import { usePermissionVariables } from 'Hooks/HasPermission';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SearchField from 'components/InputFields/SearchField';
import PopConfirm from 'components/PopConfirm/index';
import Status from 'components/Status';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import { delete_measure } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import AddUnitOfMeasureModal from './component/AddUnitOfMeasureModal';
import EditUnitOfMeasureModal from './component/EditUnitOfMeasureModal';
import { getRowNumber } from 'utils/helper';

const UnitOfMeasure = () => {
  const {
    Unit_of_Measure_Delete,
    Unit_of_Measure_Edit,
    Unit_of_Measure_Add,
    Unit_of_Measure_View,
  } = usePermissionVariables();
  const [openAddUnitOfMeasureModal, setOpenAddUnitOfMeasureModal] =
    useState(false);
  const [openEditUnitOfMeasureModal, setOpenEditUnitOfMeasureModal] =
    useState(false);
  const [selectedRecord, setSelectedRecord] = useState({
    code: '',
    name: '',
    symbol: '',
    isActive: '',
    id: '',
  });

  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const { useGetRequest, deleteRequest } = useApiClient();
  const { data, error, isLoading, refetch } = useGetRequest(
    `api/GetAllunitofmeasures?pagenumber=${pageNumber}&pagesize=${rowsPerPage}&searchquery=${debouncedSearch}`
  );
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({});
  const { themeMode } = useSelector((state) => state?.states);

  const delHandler = (record) => {
    deleteRequest(`${delete_measure}${record?.unitOfMeasureId}`)
      .then((res) => {
        if (res?.success) {
          const newTotalRecord = totalRecord - 1; // Decrement the total record count
          setTotalRecord(newTotalRecord); // Update the state

          // Check if we need to change the page number
          if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1); // Redirect to the previous page
          }
          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };

  const columns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      key: 'sr',
      // align: 'center',
      ellipsis: true,
      width: 80,
      render: (text, row, index) =>
        getRowNumber(pageNumber, rowsPerPage, index),
    },
    {
      title: <div className="flex justify-between items-center">Unit Name</div>,
      ellipsis: true,
      dataIndex: 'name',
      sorter: (a, b) => a?.name?.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'name' ? sortOrder.order : 'ascend',
      showSorterTooltip: false,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    { title: 'Unit Code', dataIndex: 'code', key: 'code', ellipsis: true },
    {
      title: 'Unit Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      ellipsis: true,
    },
    {
      title: <span className="flex items-center justify-center">Status</span>,
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      width: 300,
      ellipsis: true,
      render: (tag, record) => {
        return (
          <span>
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },
    ...(Unit_of_Measure_Edit || Unit_of_Measure_Delete
      ? [
          {
            title: (
              <span className=" flex items-center justify-center">Action</span>
            ),
            dataIndex: '',
            ellipsis: true,
            width: 100,
            render: (text, record) => (
              <span className="flex justify-center items-center space-x-2">
                {Unit_of_Measure_Edit && (
                  <div
                    className=" p-[5px] rounded-sm"
                    onClick={() => {
                      setSelectedRecord({
                        code: record?.code,
                        name: record?.name,
                        symbol: record?.symbol,
                        isActive: record?.isActive,
                        id: record?.unitOfMeasureId,
                      });
                      setOpenEditUnitOfMeasureModal(true);
                    }}
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                )}
                {Unit_of_Measure_Delete && (
                  <div
                    className=" p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this task? "
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    >
                      <MdDeleteOutline className=" w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                )}
              </span>
            ),
          },
        ]
      : []),
  ];
  useEffect(() => {
    if (data) {
      setTotalRecord(data?.totalRecords);
    }
  }, [data]);
  return (
    <div>
      <div className="md:flex space-y-[20px] md:space-y-0 justify-between mt-10 mb-26">
        <SearchField
          htmlFor="search"
          id="myInputID"
          name="search"
          placeholder={'Search by Unit Name'}
          value={debouncedSearch}
          setDebouncedSearch={setDebouncedSearch}
          setPageNumber={setPageNumber}
          searchLoading={searchLoading}
          setSearchLoading={setSearchLoading}
          refetch={refetch}
          debouncedSearch={debouncedSearch}
          setSearch={setSearch}
        />
        {Unit_of_Measure_Add && (
          <div className="sm:flex justify-end">
            <PrimaryButton
              title="Add Unit Of Measure"
              icon={<FaPlus />}
              onClick={() => setOpenAddUnitOfMeasureModal(true)}
            />
          </div>
        )}
      </div>
      <Table
        data={data?.data}
        columns={columns}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        setTotalRecord={setTotalRecord}
        totalRecord={totalRecord}
        setPageNumber={setPageNumber}
        setRowsPerPage={setRowsPerPage}
        setSortOrder={setSortOrder}
        setFilters={setFilters}
        loading={isLoading}
      />
      {openAddUnitOfMeasureModal && (
        <AddUnitOfMeasureModal
          setOpenAddUnitOfMeasureModal={setOpenAddUnitOfMeasureModal}
          openAddUnitOfMeasureModal={openAddUnitOfMeasureModal}
          refetch={refetch}
        />
      )}
      {openEditUnitOfMeasureModal && (
        <EditUnitOfMeasureModal
          setOpenEditUnitOfMeasureModal={setOpenEditUnitOfMeasureModal}
          openEditUnitOfMeasureModal={openEditUnitOfMeasureModal}
          selectedRecord={selectedRecord}
          refetch={refetch}
        />
      )}
    </div>
  );
};
export default UnitOfMeasure;
