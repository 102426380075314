import { Typography, theme } from 'antd';
import { useSelector } from 'react-redux';
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
const { Title } = Typography;
const { useToken } = theme;
// Sample data for the chart
const data = [
  { name: 'Jan', value: 400 },
  { name: 'Feb', value: 300 },
  { name: 'Mar', value: 200 },
  { name: 'Apr', value: 278 },
  { name: 'May', value: 189 },
  { name: 'Jun', value: 239 },
  { name: 'Jul', value: 349 },
  { name: 'Aug', value: 200 },
  { name: 'Sep', value: 100 },
  { name: 'Oct', value: 400 },
  { name: 'Nov', value: 500 },
  { name: 'Dec', value: 600 },
];

// Custom shape component for rounded bars
const RoundedBar = (props) => {
  const { x, y, width, height } = props;
  const radius = 2; // Adjust the radius to your liking

  return (
    <g>
      <path
        d={`M${x},${y + height} 
           L${x + width},${y + height} 
           L${x + width},${y + radius} 
           Q${x + width},${y} ${x + width - radius},${y} 
           L${x + radius},${y} 
           Q${x},${y} ${x},${y + radius} 
           Z`}
        fill={props.fill}
      />
    </g>
  );
};
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          padding: '10px',
          borderRadius: '5px',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        }}
      >
        <p style={{ margin: 0, fontWeight: 'bold' }}>{payload[0].name}</p>
        <p style={{ margin: 0 }}>Count: {payload[0].value}</p>
      </div>
    );
  }
  return null;
};
const CustomBarCharts = () => {
  const { themeMode } = useSelector((state) => state?.states);
  const { token } = useToken();
  const primaryColor = token.colorPrimary;
  return (
    <>
      <div
        className=" h-[250px] px-[15px] py-[20px] "
        style={{
          backgroundColor: themeMode === 'light' ? '#f6f4f3' : '#121212',
        }}
      >
        <div className="  flex justify-start items-center">
          <div className=" flex justify-between items-center space-x-5">
            <div
              className=" w-[7px] h-[7px] rounded-full "
              style={{ backgroundColor: primaryColor }}
            />
            <div className="">
              <Title
                level={5}
                style={{
                  color: primaryColor,
                  fontFamily: 'inter-semibold',
                  height: 18,
                }}
              >
                Bar Chart
              </Title>
            </div>
          </div>
        </div>
        <div>
          <ResponsiveContainer width="100%" height={200}>
            <BarChart
              data={data}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              barCategoryGap="2%"
            >
              <XAxis
                dataKey="name"
                tick={{
                  fill: '#5A5881',
                  fontFamily: '',
                  fontSize: 11,
                }}
                axisLine={false}
                tickLine={false}
              />
              <YAxis
                tick={{ fill: '#5A5881', fontFamily: '', fontSize: 11 }}
                axisLine={false}
                tickLine={false}
              />
              <Tooltip content={<CustomTooltip />} />
              <Bar
                dataKey="value"
                fill={primaryColor}
                shape={<RoundedBar />}
                barSize={5}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};

export default CustomBarCharts;
