import { QuestionCircleOutlined } from '@ant-design/icons';
import { usePermissionVariables } from 'Hooks/HasPermission';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SearchField from 'components/InputFields/SearchField';
import PopConfirm from 'components/PopConfirm';
import PopOver from 'components/PopOver';
import Status from 'components/Status';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import { delete_sms_template } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import AddSMSTemplateModal from './component/AddSMSTemplateModal';
import EditSMSTemplateModal from './component/EditSMSTemplateModal';

const SMSTemplate = () => {
  const { SMS_Template_Delete, SMS_Template_Edit, SMS_Template_Add } =
    usePermissionVariables();
  const [openAddSMSTemplateModal, setOpenAddSMSTemplateModal] = useState(false);
  const [openEditSMSTemplateModal, setOpenEditSMSTemplateModal] =
    useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const { useGetRequest, deleteRequest } = useApiClient();

  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const { data, error, isLoading, refetch } = useGetRequest(
    `api/GetAllSmsTemplates?tenantId=${process.env.REACT_APP_TENANT_ID}&pagenumber=${pageNumber}&pagesize=${rowsPerPage}&searchquery=${search}`
  );
  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  const delHandler = (record) => {
    deleteRequest(`${delete_sms_template}${record?.templateId}`)
      .then((res) => {
        if (res?.success) {
          const newTotalRecord = totalRecord - 1; // Decrement the total record count
          setTotalRecord(newTotalRecord); // Update the state

          // Check if we need to change the page number
          if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1); // Redirect to the previous page
          }
          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };
  useEffect(() => {
    if (data) {
      setTotalRecord(data?.totalRecords);
    }
  }, [data]);
  const columns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      ellipsis: true,
      width: 80,
      render: (text, row, index) => <>{index + 1}</>,
    },
    {
      title: (
        <div className="flex justify-between items-center">Template Name</div>
      ),
      ellipsis: true,
      dataIndex: 'templateName',
      sorter: (a, b) => a?.templateName?.localeCompare(b.templateName),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'templateName'
          ? sortOrder.order
          : 'ascend',
      showSorterTooltip: false,

      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    { title: 'Template Code', dataIndex: 'templateCode', ellipsis: true },
    {
      title: 'Template Body',
      dataIndex: 'templateBody',
      className: 'max-w-[250px]',
      ellipsis: true,
      render: (text, record) => (
        <span className="">
          <PopOver content={text} title={'Description'} width={'40%'}>
            <div className="truncate">{text}</div>
          </PopOver>
        </span>
      ),
    },
    { title: 'Language', dataIndex: 'language', ellipsis: true },
    {
      title: <span className="flex items-center justify-center">Status</span>,
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      width: 300,
      ellipsis: true,
      render: (tag, record) => {
        return (
          <span>
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },
    ...(SMS_Template_Edit || SMS_Template_Delete
      ? [
          {
            title: (
              <span className=" flex items-center justify-center">Action</span>
            ),
            ellipsis: true,
            width: 100,
            dataIndex: '',
            render: (text, record) => (
              <span className="flex justify-center items-center space-x-2">
                {SMS_Template_Edit && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                    onClick={() => {
                      setSelectedRecord({
                        templateId: record?.templateId,
                        templateName: record?.templateName,
                        templateCode: record?.templateCode,
                        templateBody: record?.templateBody,
                        language: record?.language,
                        isActive: record?.isActive,
                      });
                      setOpenEditSMSTemplateModal(true);
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                )}
                {SMS_Template_Delete && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this task? "
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    >
                      <MdDeleteOutline className=" w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                )}
              </span>
            ),
          },
        ]
      : []),
  ];

  return (
    <div>
      <div className="md:flex space-y-[20px] md:space-y-0 justify-between mt-10 mb-26">
        <SearchField
          htmlFor="search"
          id="myInputID"
          name="search"
          placeholder={'Search'}
          value={debouncedSearch}
          setDebouncedSearch={setDebouncedSearch}
          setPageNumber={setPageNumber}
          searchLoading={searchLoading}
          setSearchLoading={setSearchLoading}
          refetch={refetch}
          debouncedSearch={debouncedSearch}
          setSearch={setSearch}
        />
        {SMS_Template_Add && (
          <div className="sm:flex justify-end">
            <PrimaryButton
              title="Add SMS Template"
              icon={<FaPlus />}
              onClick={() => setOpenAddSMSTemplateModal(true)}
            />
          </div>
        )}
      </div>
      <Table
        data={data?.data}
        columns={columns}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        setTotalRecord={setTotalRecord}
        totalRecord={totalRecord}
        setPageNumber={setPageNumber}
        setRowsPerPage={setRowsPerPage}
        setSortOrder={setSortOrder}
        setFilters={setFilters}
        loading={isLoading}
      />
      {openAddSMSTemplateModal && (
        <AddSMSTemplateModal
          setOpenAddSMSTemplateModal={setOpenAddSMSTemplateModal}
          openAddSMSTemplateModal={openAddSMSTemplateModal}
          refetch={refetch}
        />
      )}
      {openEditSMSTemplateModal && (
        <EditSMSTemplateModal
          setOpenEditSMSTemplateModal={setOpenEditSMSTemplateModal}
          openEditSMSTemplateModal={openEditSMSTemplateModal}
          selectedRecord={selectedRecord}
          refetch={refetch}
        />
      )}
    </div>
  );
};
export default SMSTemplate;
