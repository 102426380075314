import { QuestionCircleOutlined } from '@ant-design/icons';
import { Breadcrumb, Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import PopConfirm from 'components/PopConfirm';
import Status from 'components/Status';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import { delete_users, get_users } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { usePermissionVariables } from 'Hooks/HasPermission';
import Layout from 'layout/AdminLayout';
import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { GoHome } from 'react-icons/go';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { TbBasketFilled } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { jobData } from 'utils/constant';

const { Title } = Typography;

const Jobs = () => {
  const { useGetRequest, deleteRequest } = useApiClient();
  const { Users_View, Users_Add, Users_Edit, Users_Delete } =
    usePermissionVariables();

  const { data, error, isLoading, refetch } = useGetRequest(get_users);
  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  // States
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const delHandler = (record) => {
    deleteRequest(`${delete_users}${record?.userId}`)
      .then((res) => {
        if (res?.success) {
          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };
  const columns = [
    {
      title: 'Job ID',
      dataIndex: 'jobId',
      key: 'jobId',
      // align: 'center',
      width: 80,
      ellipsis: true,
      // render: (text, row, index) => index + 1,
    },
    {
      title: (
        <div className="flex justify-between items-center">Customer Name</div>
      ),
      ellipsis: true,
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'name' ? sortOrder.order : 'ascend',

      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    { title: 'Job Type', dataIndex: 'jobType', ellipsis: true },
    { title: 'Assign Driver', dataIndex: 'assignDriver', ellipsis: true },
    { title: 'Assign Vehicle', dataIndex: 'assignVehicle', ellipsis: true },
    {
      title: 'Total Orders',
      dataIndex: 'totalOrders',
      ellipsis: true,
    },
    {
      title: <span className="flex items-center justify-center">Status</span>,
      ellipsis: true,
      dataIndex: 'isActive',
      render: (tag, record) => {
        return (
          <span className="flex items-center justify-center">
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },

    ...(Users_Edit || Users_Delete
      ? [
          {
            title: (
              <span className="flex items-center justify-center">Action</span>
            ),
            ellipsis: true,
            dataIndex: '',
            render: (text, record) => (
              <span className="flex justify-center items-center space-x-2">
                {Users_Edit && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                    onClick={() => {}}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                )}
                {Users_Delete && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this task?"
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={
                        <div className="flex items-center justify-center">
                          <QuestionCircleOutlined
                            style={{ color: 'red', marginTop: '5px' }}
                          />
                        </div>
                      }
                    >
                      <MdDeleteOutline className="w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                )}
              </span>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Layout>
      <>
        <div>
          <div className=" mb-[30px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
              }}
            >
              TMS
            </Title>
            <div className="flex justify-between items-center ">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className="flex items-center space-x-4 ">
                            <TbBasketFilled
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />

                            <span
                              className="font-bold text-[13px]"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              Jobs
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
              {Users_Add && (
                <div>
                  <PrimaryButton
                    title="Add Jobs"
                    icon={<FaPlus />}
                    onClick={() => {
                      navigate(`/add-jobs`);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            <Table
              data={jobData}
              columns={columns}
              pageNumber={pageNumber}
              setTotalRecord={setTotalRecord}
              rowsPerPage={rowsPerPage}
              totalRecord={totalRecord}
              setPageNumber={setPageNumber}
              setRowsPerPage={setRowsPerPage}
              setSortOrder={setSortOrder}
              setFilters={setFilters}
              // loading={isLoading}
            />
          </div>
        </div>
      </>
    </Layout>
  );
};
export default Jobs;
