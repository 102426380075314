// Route /settings start
// city
export const get_cities = 'api/GetCities';
export const get_city = 'api/GetCityById';
export const add_city = 'api/AddCity';
export const update_city = 'api/UpdateCity';
export const delete_city = '/api/DeleteCity?id=';

// country
export const get_country = 'api/GetCountries';
export const get_country_by_dropdown = 'api/GetCountriesdropdown';
export const add_country = 'api/AddCountry';
export const update_country = 'api/UpdateCountry';
export const delete_country = 'api/DeleteCountry?id=';

// currency
export const get_currencies = 'api/GetAllCurrencies';
export const get_currency = 'api/GetCurrencyById';
export const add_currency = 'api/AddCurrency';
export const update_currency = 'api/UpdateCurrency';
export const delete_currency = 'api/DeleteCurrency?id=';

// device type
export const get_device_types = 'api/GetDeviceTypes';
export const get_device_types_by_dropdown = 'api/GetDeviceTypesdropdown';
export const get_device_type_by_id = '/api/GetDeviceTypeById?id=';
export const add_device_type = 'api/AddDeviceType';
export const update_device_type = 'api/UpdateDeviceType?id=';
export const delete_device_type = 'api/DeleteDeviceType?id=';

// states
export const get_states = '/api/GetAllStates';
export const get_state_by_id = 'api/GetStatebyid';
export const add_state = 'api/AddState';
export const update_state = 'api/UpdateState';
export const delete_state = 'api/DeleteState?id=';

// device model
export const get_device_models = 'api/GetDeviceModels';
export const get_device_models_by_dropdown = 'api/GetDeviceModelsdropdown';
export const get_device_model_by_id = 'api/GetDeviceTypebyid?id=';
export const add_device_model = 'api/AddDeviceModel';
export const update_device_model = 'api/UpdateDeviceModel';
export const delete_device_model = 'api/DeleteDeviceModel?id=';

// unit of measure
export const get_all_measure = 'api/GetAllunitofmeasures';
export const get_by_id_measure = 'api/GetunitofmeasureByid';
export const add_measure = 'api/Addunitofmeasure';
export const update_measure = 'api/Updateunitofmeasure';
export const delete_measure = 'api/Deleteunitofmeasure?id=';

// timezone
export const get_all_timezones = 'api/GetAllTimezones';
export const get_timezone = 'api/GetTimezone';
export const add_timezone = 'api/AddTimezone';
export const update_timezone = 'api/UpdateTimezone';
export const delete_timezone = 'api/DeleteTimezone?id=';

// vehicle type
export const get_vehicle_types = 'api/GetAllVehicletypes';
export const get_vehicle_types_by_dropdown = 'api/GetVehicletypesdropdown';
export const get_vehicle_type_by_id = '/api/GetVehicletypeById?id=';
export const add_vehicle_type = 'api/AddVehicleType';
export const update_vehicle_type = 'api/UpdateVehicleType';
export const delete_vehicle_type = 'api/DeleteVehicleType?id=';

// zip code
export const get_zipCodes = 'api/GetZipCodes';
export const get_zipCode = 'api/zipcodes';
export const add_zipCode = 'api/AddZipCode';
export const update_zipCode = 'api/UpdateZipCode';
export const delete_zipCode = 'api/DeleteZipCode?id=';

// email Template
export const get_email_templates = 'api/GetAllEmailTemplates';
export const get_email_template_by_id = '/api/GetEmailTemplateById?id=';
export const add_email_template = 'api/AddEmailTemplate';
export const update_email_template = 'api/UpdateEmailTemplate?id=';
export const delete_email_template = 'api/DeleteEmailTemplate?id=';

// email Settings
export const get_email_settings = 'api/GetAllEmailSettings';
export const get_email_setting_by_id = 'api/GetEmailSettingById?id=';
export const add_email_setting = 'api/AddEmailSetting';
export const update_email_setting = 'api/UpdateEmailSetting';
export const delete_email_setting = 'api/DeleteEmailSetting?id=';

// Sms Template
export const get_sms_templates_by_tenant_id =
  'api/GetAllSmsTemplates?tenantId=';
export const get_sms_template_by_id = 'api/GetSmsTemplateById?id=';
export const add_sms_template = 'api/AddSmsTemplate';
export const update_sms_template = 'api/UpdateSmsTemplate';
export const delete_sms_template = 'api/DeleteSmsTemplate?id=';

// SMS Gateway
export const get_all_sms_gateway = 'api/GetAllSmsSettings';
export const get_sms_gateway_by_id = 'api/smssettings?id=';
export const add_sms_gateway = 'api/AddSmsSettings';
export const update_sms_gateway = 'api/UpdateSmsSetting';
export const delete_sms_gateway = 'api/DeleteSmsSetting?id=';

// Route /settings end

//  Route Subscribtion
export const get_subscribtion_plans = 'api/GetAllSubscriptionPlans';
export const get_subscribtion_plan_by_id = 'api/GetSubscriptionPlanById?id=';
export const add_subscribtion_plan = 'api/AddSubscriptionPlan';
export const update_subscribtion_plan = 'api/UpdateSubscriptionPlan';
export const delete_subscribtion_plan = 'api/DeleteSubscriptionPlan?id=';

//  Route Subscription
// feature
export const get_feature_plans = 'api/GetAllPlanFeatures';
export const get_feature_plans_by_id = 'api/GetPlanFeaturebyId?id=';
export const add_feature_plans = 'api/AddPlanFeature';
export const update_feature_plans = 'api/UpdatePlanFeature';
export const delete_feature_plans = 'api/DeletePlanFeature?id=';

// GetAllAssignedSubscriptionPlans
export const get_all_assigned_subscription_plans =
  'api/GetAllAssignedSubscriptionPlans';
export const get_assigned_subscription_plan_by_id = 'api/GetAssignPlanById?id=';
export const add_assigned_subscription_plan = 'api/AddAssignSubscriptionPlan';
export const update_assigned_subscription_plan =
  'api/UpdateAssignSubscriptionPlan';
export const delete_assigned_subscription_plan =
  'api/DeleteAssignSubscriptionPlan?id=';

// GetAllSubPlanFeatures
export const get_all_sub_plan_features = 'api/GetAllSubPlanFeatures';
export const get_sub_plan_features_by_plan_id_1 =
  'api/GetSubPlanFeaturesById?planId=';
export const get_sub_plan_features_by_id_2 = '&featureId=';
export const add_sub_plan_features = 'api/AddSubPlanFeatures';
export const update_sub_plan_features = 'api/UpdateSubPlanFeatures';
export const delete_sub_plan_features_by_plan_id_1 =
  'api/DeleteSubPlanFeatures?planId=';
export const delete_sub_plan_features_by_plan_id_2 = '&featureId=';

//Route /role-management

//Role
export const get_role = '/api/Roles/GetAllRoles';
export const get_role_by_id = '';
export const add_role = '/api/Roles/AddRole';
export const update_role = '/api/Roles/UpdateRole';
export const delete_role = '/api/Roles/Delete?role=';

//Menu
export const get_menu = '/api/AppMenus/GetAllMenus';
export const get_menu_by_id = '';
export const add_menu = '/api/AppMenus/AddMenu';
export const update_menu = '/api/AppMenus/UpdateMenu';
export const delete_menu = '/api/AppMenus/DeleteMenu?id=';

//Pages
export const get_page = '/api/AppPages/GetAllPages';
export const get_page_by_id = '';
export const add_page = '/api/AppPages/AddAppPage';
export const update_page = '/api/AppPages/UpdatePage?pageId=';
export const delete_page = '/api/AppPages/DeletePage?id=';

//Role Rights
export const get_role_rights = '/api/RoleRights/GetAllRoleRights';
export const get_role_rights_by_id = '/api/RoleRights/GetRoleByid?id=';
export const add_role_rights = '/api/RoleRights/AddRoleRight';
export const update_role_rights = '/api/RoleRights/UpdateRoleRight';
export const delete_role_rights = '/api/RoleRights/DeleteRoleRights?id=';

// Routes /users

export const get_users = '/api/User/GetAllUsers';
export const get_users_by_id = '';
export const add_users = '/api/User/AddNewUser';
export const update_users = '/api/User/UpdateUser?userId=';
export const delete_users = '/api/User/DeleteUser?userId=';

//Route /device-management

//All Devices
export const get_all_devices = '/api/GetAllDevices';
export const get_all_devices_by_id = '';
export const add_all_devices = '/api/AddDevice';
export const update_all_devices = '/api/UpdateDevice';
export const delete_all_devices = 'api/DeleteDevice?id=';

//Assign Device
export const get_assign_device = '/api/GetAllDevicesAssigned';
export const get_assign_device_by_id = '';
export const add_assign_device = '/api/AddDeviceAssign';
export const update_assign_device = '/api/UpdateDeviceAssigned';
export const delete_assign_device = '/api/DeleteAssignedDevice?id=';

//Configuration
export const get_configuration = '/api/GetAllDeviceConfigurations';
export const get_configuration_by_id = '';
export const add_configuration = '/api/AddDeviceConfiguration';
export const update_configuration = '/api/UpdateDeviceConfiguration';
export const delete_configuration = '/api/DeleteDeviceConfiguration?id=';

//Loges
export const get_loges = '/api/GetAllDeviceLogs';
