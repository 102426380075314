import { Table } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import Checkbox from 'components/InputFields/Checkbox';
import PopConfirm from 'components/PopConfirm';
import ToastNotification from 'components/ToastNotification';
import { delete_role_rights, get_role_rights } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { usePermissionVariables } from 'Hooks/HasPermission';
import { useEffect, useMemo, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setPermssionsArray } from 'store/slices';
import { transformDataRoleRights } from 'utils/helper';

function generatePagesArray(pages) {
  return pages?.map((page) => ({
    pageId: page.pageId,
    pageName: page.pageName,
    fullControl: page?.fullControl,
    canAdd: page?.canAdd,
    canView: page?.canView,
    canEdit: page?.canEdit,
    canDelete: page?.canDelete,
  }));
}
const RoleRights = () => {
  const { themeMode, colorTheme, clientId } = useSelector(
    (state) => state?.states
  );
  const { useGetRequest, deleteRequest, putRequest } = useApiClient();
  const queryClient = useQueryClient();
  const {
    Role_Rights_View,
    Role_Rights_Add,
    Role_Rights_Edit,
    Role_Rights_Delete,
  } = usePermissionVariables();
  const { data, error, isLoading, refetch } = useGetRequest(get_role_rights);
  const {
    data: permissionsData,
    isLoading: permissionsLoading,
    refetch: refetchPermissions,
  } = useGetRequest(`/api/RoleRights/GetUserPermissions?userid=${clientId}`, {
    enabled: false,
  });
  const transformedData = transformDataRoleRights(data?.data);
  const navigate = useNavigate();

  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <div className="flex items-center justify-center h-full">
          <SlArrowLeft
            className="w-[12px] h-[12px]"
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
            }}
          />
        </div>
      );
    }
    if (type === 'next') {
      return (
        <div className="flex items-center justify-center h-full">
          <SlArrowRight
            className="w-[12px] h-[12px]"
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
            }}
          />
        </div>
      );
    }
    return originalElement;
  };

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const dispatch = useDispatch();
  // States
  const [pageNumber, setPageNumber] = useState(1);
  const [openAddRoleRightsModal, setOpenRoleRightsModal] = useState(false);
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({});
  const [loading, setLoading] = useState(false);
  const [expandedMenuKey, setExpandedMenuKey] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [checkbox, setCheckboxes] = useState([]);
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });

  const delHandler = (record) => {
    deleteRequest(`${delete_role_rights}${record?.roleRightId}`)
      .then((res) => {
        // if (res?.success) {
        refetch();
        toast.success('Role Rights Deleted Successfully');

        //   toast.success(res?.message);
        // } else {
        //   toast.error(res?.message);
        // }
      })
      .catch((err) => toast.error('Something went wrong'));
  };
  useEffect(() => {
    refetch();
  }, [data]);

  useEffect(() => {
    setCheckboxes(transformedData);
  }, [data]);

  const handleCheckboxChange = (menuId, pageId, checkboxName, checked) => {
    setCheckboxes((prevCheckboxes) => {
      return prevCheckboxes.map((role) => {
        // Find the correct menu
        const updatedMenus = role.menus.map((menu) => {
          if (menu.menuId === menuId) {
            const updatedPages = menu.pages.map((page) => {
              if (page.pageId === pageId) {
                let updatedPage = { ...page };

                if (checkboxName === 'fullControl') {
                  // Set all permissions to checked or unchecked based on fullControl
                  updatedPage = {
                    ...updatedPage,
                    fullControl: checked,
                    canAdd: checked,
                    canEdit: checked,
                    canView: checked,
                    canDelete: checked,
                  };
                } else {
                  // Update specific permission based on the checkboxName
                  updatedPage[checkboxName] = checked;

                  // If 'canView' is unchecked, also uncheck other permissions
                  if (checkboxName === 'canView' && !checked) {
                    updatedPage.canAdd = false;
                    updatedPage.canEdit = false;
                    updatedPage.canDelete = false;
                  }

                  // If any of the action permissions are checked, 'canView' should be true
                  if (
                    ['canAdd', 'canEdit', 'canDelete'].includes(checkboxName)
                  ) {
                    updatedPage.canView = true;
                  }

                  // Check if all permissions are checked to set fullControl
                  const allChecked =
                    updatedPage.canAdd &&
                    updatedPage.canView &&
                    updatedPage.canEdit &&
                    updatedPage.canDelete;
                  updatedPage.fullControl = allChecked;
                }

                return updatedPage; // Return the updated page
              }

              return page; // Return the unchanged page
            });

            return { ...menu, pages: updatedPages };
          }

          return menu;
        });

        return { ...role, menus: updatedMenus };
      });
    });

    setExpandedRowKeys((prevExpandedKeys) => prevExpandedKeys);
  };

  const handleUpdate = (record) => {
    setLoading(true);
    const paylaod = {
      roleId: record?.roleId,
      menuId: record?.menuId,
      permission: generatePagesArray(record?.pages),
    };

    putRequest(`/api/RoleRights/UpdateBulkRoleRight`, paylaod)
      .then((res) => {
        if (res?.success) {
          refetch();
          refetchPermissions().then((res) => {
            dispatch(setPermssionsArray(res?.data?.data));
          });
          ToastNotification(
            'success',
            'Permissions Updated Successfully',
            themeMode
          );
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      )
      .finally(() => {
        setLoading(false);
      });
  };
  const pageColumns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      key: 'sr',
      ellipsis: true,
      width: 80,
      // render: (text, row, index) => index + 1,
    },
    {
      title: 'Page Name',
      dataIndex: 'pageName',
      ellipsis: true,
      key: 'pageName',
    },
    {
      title: 'Full Control',
      align: 'center',
      dataIndex: 'fullControl',
      ellipsis: true,
      key: 'fullControl',
      render: (text, record) => (
        <span className="flex justify-center items-center">
          <Checkbox
            type="checkbox"
            checked={record.fullControl}
            onChange={(e) =>
              handleCheckboxChange(
                record.menuId,
                record.pageId,
                'fullControl',
                e.target.checked
              )
            }
          />
        </span>
      ),
    },
    {
      title: 'Can View',
      dataIndex: 'canView',
      align: 'center',
      ellipsis: true,
      key: 'canView',
      render: (text, record) => (
        <span className="flex justify-center items-center">
          <Checkbox
            type="checkbox"
            checked={record.canView}
            onChange={(e) =>
              handleCheckboxChange(
                record.menuId,
                record.pageId,
                'canView',
                e.target.checked
              )
            }
          />
        </span>
      ),
    },
    {
      title: 'Can Add',
      dataIndex: 'canAdd',
      align: 'center',
      ellipsis: true,
      key: 'canAdd',
      render: (text, record) => (
        <span className="flex justify-center items-center">
          <Checkbox
            type="checkbox"
            checked={record.canAdd}
            onChange={(e) =>
              handleCheckboxChange(
                record.menuId,
                record.pageId,
                'canAdd',
                e.target.checked
              )
            }
          />
        </span>
      ),
    },
    {
      title: 'Can Edit',
      dataIndex: 'canEdit',
      align: 'center',
      ellipsis: true,
      key: 'canEdit',
      render: (text, record) => (
        <span className="flex justify-center items-center">
          <Checkbox
            type="checkbox"
            checked={record.canEdit}
            onChange={(e) =>
              handleCheckboxChange(
                record.menuId,
                record.pageId,
                'canEdit',
                e.target.checked
              )
            }
          />
        </span>
      ),
    },
    {
      title: 'Can Delete',
      dataIndex: 'canDelete',
      align: 'center',
      ellipsis: true,
      key: 'canDelete',
      render: (text, record) => (
        <span className="flex justify-center items-center">
          <Checkbox
            type="checkbox"
            checked={record.canDelete}
            onChange={(e) =>
              handleCheckboxChange(
                record.menuId,
                record.pageId,
                'canDelete',
                e.target.checked
              )
            }
          />
        </span>
      ),
    },
  ];

  const menuColumns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      ellipsis: true,
      key: 'sr',
      width: 80,
      // render: (text, row, index) => {
      //   console.log(row);
      //   return `${row.sr}`;
      // },
    },
    {
      title: 'Menu Name',
      dataIndex: 'menuName',
      ellipsis: true,
      key: 'menuName',
    },
  ];

  const roleColumns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      key: 'sr',
      ellipsis: true,
      width: 80,
      // render: (text, row, index) => index + 1,
    },
    {
      title: 'Role Name',
      dataIndex: 'roleName',
      key: 'roleName',
      ellipsis: true,
    },
    {
      title: 'Role Description',
      dataIndex: 'roleDescription',
      ellipsis: true,
      key: 'roleDescription',
    },
  ];
  const onChange = (pagination, filters, sorter, extra) => {
    setPageNumber(pagination.current);
    setRowsPerPage(pagination.pageSize);
    setSortOrder(sorter);
    setFilters(filters);
  };

  const MenuTable = ({ menus }) => {
    const expandedRowRender = (menuRecord) => (
      <>
        <Table
          columns={pageColumns}
          bordered
          scroll={{ x: true }}
          dataSource={menuRecord.pages.map((page) => ({
            ...page,
            key: `${menuRecord.menuId}-${page.pageId}`,
          }))}
          pagination={false}
        />
        {themeMode === 'light' && (
          <hr
            className="mt-[8px] mx-[-8px]"
            style={{ borderWidth: '-0.1em' }}
          />
        )}

        <div
          className="p-[5px] rounded-sm flex w-full justify-end items-center mb-[0px] mt-[10px] "
          style={{
            backgroundColor: themeMode === 'light' ? 'white' : '#121212',
          }}
        >
          <PopConfirm
            title="Update"
            description="Are you sure, you want to update these permissions?"
            placement="left"
            onClick={() => handleUpdate(menuRecord)}
          >
            <div>
              <SecondaryButton
                btnType={'dashed'}
                title="Update Permissions"
                loading={loading}
                // icon={<BiSave size={14} />}
                className=" h-[32px] w-full text-[13px]"
              />
            </div>
          </PopConfirm>
        </div>
      </>
    );

    return (
      <Table
        columns={menuColumns}
        dataSource={menus.map((menu) => ({
          ...menu,
          key: `${menu.menuId}`,
        }))}
        bordered
        scroll={{ x: true }}
        expandable={{
          expandedRowRender,
          rowExpandable: (record) => record.pages && record.pages.length > 0,
          expandedRowKeys: expandedMenuKey ? [expandedMenuKey] : [],
          onExpand: (expanded, record) => {
            setExpandedMenuKey(expanded ? record.key : null);
          },
        }}
        pagination={false}
      />
    );
  };

  const pageSizeOptions = useMemo(() => {
    const options = [];
    for (let i = 1; i * 10 <= totalRecord; i++) {
      options.push((i * 10).toString());
    }
    return options;
  }, [totalRecord]);

  const expandedRowRender = (record) => <MenuTable menus={record.menus} />;

  const handleExpand = (expanded, record) => {
    setExpandedRowKeys(expanded ? [record.key] : []);
    setExpandedMenuKey(null);
  };

  useEffect(() => {
    if (data) setTotalRecord(transformedData?.length);
  }, [data]);

  return (
    <>
      {Role_Rights_Add && (
        <div className="sm:flex justify-end">
          <PrimaryButton
            title="Add Role Rights"
            icon={<FaPlus />}
            onClick={() => {
              // setOpenRoleRightsModal(true);
              navigate('/add-rolerights');
            }}
          />
        </div>
      )}
      <div className={`mt-20 ${themeMode === 'light' ? '' : 'dark-mode'}`}>
        <Table
          columns={roleColumns}
          dataSource={checkbox?.map((role) => ({
            ...role,
            key: `${role.roleId}`,
          }))}
          expandable={{
            expandedRowRender,
            rowExpandable: (record) => record.menus && record.menus.length > 0,
            // expandIcon: customExpandIcon,
            expandedRowKeys: expandedRowKeys, // Set the expanded row keys
            onExpand: handleExpand, // Handle expand logic
          }}
          className={`${
            themeMode === 'light' ? 'light-table' : 'dark-table'
          } !overflow-x-auto`}
          onChange={onChange}
          size="small"
          bordered
          sticky
          scroll={{ x: true }}
          pagination={{
            current: pageNumber,
            pageSize: rowsPerPage,
            total: totalRecord,
            showSizeChanger: true,
            size: 'small',
            // position: ['bottomCenter'],
            responsive: true,
            hideOnSinglePage: totalRecord > 10 ? false : true,
            itemRender: itemRender,
            style: {
              fill: themeMode === 'light' ? colorTheme : '#9FA8DA',
              color: themeMode === 'light' ? colorTheme : '#9FA8DA', // Set the text color dynamically
            },
            pageSizeOptions: pageSizeOptions,
            onShowSizeChange: (current, size) => {
              setRowsPerPage(size);
              setPageNumber(1);
            },
            className:
              themeMode === 'light' ? 'light-pagination' : 'dark-pagination',
          }}
          loading={isLoading}
        />
      </div>
    </>
  );
};
export default RoleRights;
