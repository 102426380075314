import { Button, Modal, Select } from 'antd';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
import ToastNotification from 'components/ToastNotification';
import { get_country_by_dropdown, update_zipCode } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import useValidationSchema from 'validation/useValidationSchema';

const EditZipModal = ({
  openEditZipModal,
  setOpenEditZipModal,
  selectedRecord,
  refetch,
}) => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { useGetRequest, putRequest } = useApiClient();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const { zipModalSchema } = useValidationSchema();
  const formik = useFormik({
    initialValues: {
      zipCode: null,
      countryId: null,
      stateId: null,
      city: '',
    },
    validationSchema: zipModalSchema,
    onSubmit: ({ zipCode, city, countryId, stateId }) => {
      setLoading(true);
      const paylaod = {
        ZipCodeId: selectedRecord?.zipCodeId,
        code: zipCode,
        cityId: city,
      };
      putRequest(update_zipCode, paylaod)
        .then((res) => {
          if (res?.success) {
            refetch();
            formik.resetForm();
            ToastNotification('success', res.message, themeMode);
            setOpenEditZipModal(false);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const { data: countryData, isLoading: countryIsLoding } = useGetRequest(
    get_country_by_dropdown,
    {
      refetchOnMount: 'always',
    }
  );
  const {
    data: stateData,
    refetch: statesRefetch,
    isLoading: stateIsLoding,
  } = useGetRequest(`api/StateDropdown?Id=${formik.values.countryId}`, {
    enabled: false,
  });
  const {
    data: cityData,
    refetch: cityRefetch,
    isLoading: cityIsLoding,
  } = useGetRequest(`api/CitiesDropdown?Id=${formik.values.stateId}`, {
    enabled: false,
  });
  const handleCancel = () => {
    setOpenEditZipModal(false);
    formik.resetForm();
  };
  useEffect(() => {
    if (formik.values.countryId) statesRefetch();
    if (formik.values.stateId) cityRefetch();
  }, [formik.values.countryId, formik.values.stateId]);

  useEffect(() => {
    if (selectedRecord) {
      formik.setValues({
        countryId: selectedRecord?.countryId,
        stateId: selectedRecord?.stateId,
        zipCode: selectedRecord?.code,
        city: selectedRecord?.cityId,
      });
    }
  }, [selectedRecord, openEditZipModal]);

  return (
    <>
      <Modal
        open={openEditZipModal}
        title={<span className=" text-[18px]">Edit Zip Code</span>}
        width={400}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
            className="!shadow-none"
          >
            Submit
          </Button>,
        ]}
      >
        <>
          <div className=" grid grid-cols-12 gap-y-5 gap-x-20  py-[10px]">
            <div className=" col-span-12">
              <SelectField
                formik={formik}
                id={'countryId'}
                label={'Country'}
                required={true}
                optionId={'countryId'}
                optionName={'name'}
                isLoading={countryIsLoding}
                optionData={countryData?.data}
                onChange={(newValue, _) => {
                  formik.setFieldValue('countryId', newValue);
                  formik.setFieldValue('stateId', null);
                  formik.setFieldValue('city', null);
                  formik.setFieldValue('zipCode', null);
                }}
              />
            </div>
            <div className=" col-span-12">
              <SelectField
                formik={formik}
                id={'stateId'}
                label={'State'}
                required={true}
                optionId={'stateId'}
                optionName={'name'}
                isLoading={stateIsLoding}
                optionData={stateData?.data}
                onChange={(newValue, _) => {
                  formik.setFieldValue('stateId', newValue);
                  formik.setFieldValue('city', null);
                  formik.setFieldValue('zipCode', null);
                }}
              />
            </div>
            <div className=" col-span-12">
              <SelectField
                formik={formik}
                id={'city'}
                label={'City'}
                optionId={'cityId'}
                optionName={'name'}
                isLoading={cityIsLoding}
                optionData={cityData?.data}
                onChange={(newValue, _) => {
                  formik.setFieldValue('city', newValue);
                  formik.setFieldValue('zipCode', null);
                }}
              />
            </div>
            <div className="col-span-12">
              <TextField
                formik={formik}
                id={'zipCode'}
                label={'Zip Code'}
                type={'number'}
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default EditZipModal;
