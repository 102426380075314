import React, { useState, useEffect } from 'react';
import { Button, Modal, Input, Select } from 'antd';
import { useFormik } from 'formik';
import useValidationSchema from 'validation/useValidationSchema';
import { themeInputColor, isActiveOptions } from 'utils/constant';
import InputFieldCustomForm from 'components/CustomFields/CustomFieldsOutlinedForm';
import { useSelector } from 'react-redux';
import {
  update_country,
  get_country,
  update_configuration,
} from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useQueryClient } from 'react-query';
import Checkbox from 'components/InputFields/Checkbox';
import { toast } from 'react-toastify';
import { SlArrowDown } from 'react-icons/sl';
import ToastNotification from 'components/ToastNotification';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';

const EditConfigurationsModal = ({
  openEditConfigurationsModal,
  setOpenEditConfigurationsModal,
  selectedConfigurationsRecord,
  refetch,
}) => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const queryClient = useQueryClient();
  const { putRequest, useGetRequest } = useApiClient();
  const [loading, setLoading] = useState(false);
  const { configurationsModalSchema } = useValidationSchema();
  const { Option } = Select;
  const { data, error, isLoading } = useGetRequest('/api/GetAllDevices');
  const deviceOptions = data?.data?.map((item) => {
    return {
      value: item.deviceId,
      label: item.deviceName,
    };
  });
  const formik = useFormik({
    initialValues: {
      device: null,
      configurationKey: '',
      configurationValue: '',
      isActive: false,
    },
    validationSchema: configurationsModalSchema,
    onSubmit: ({ device, configurationKey, configurationValue, isActive }) => {
      setLoading(true);
      const paylaod = {
        configurationId: selectedConfigurationsRecord?.configurationId,
        deviceId: device,
        configKey: configurationKey,
        configValue: configurationValue,
        isActive: isActive,
      };
      putRequest(update_configuration, paylaod)
        .then((res) => {
          if (res?.success) {
            refetch();
            formik.resetForm();
            ToastNotification('success', res.message, themeMode);
            setOpenEditConfigurationsModal(false);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCancel = () => {
    setOpenEditConfigurationsModal(false);
    formik.resetForm();
  };
  useEffect(() => {
    if (selectedConfigurationsRecord) {
      formik.setValues({
        ...formik.values,
        device: selectedConfigurationsRecord?.deviceId,
        configurationKey: selectedConfigurationsRecord?.configKey,
        configurationValue: selectedConfigurationsRecord?.configValue,
        isActive: selectedConfigurationsRecord?.isActive,
      });
    }
  }, [selectedConfigurationsRecord, openEditConfigurationsModal]);

  return (
    <>
      <Modal
        open={openEditConfigurationsModal}
        title={
          <span className=" text-[18px]">Update Device Configuration</span>
        }
        width={400}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <>
          <div className=" grid grid-cols-12 gap-x-20  py-[5px]">
            <div className=" col-span-12">
              <SelectField
                formik={formik}
                id={'device'}
                label={'Device'}
                optionId={'value'}
                optionName={'label'}
                optionData={deviceOptions}
              />
            </div>
            <div className="col-span-12">
              <TextField
                formik={formik}
                id={'configurationKey'}
                label={'Configuration Key'}
                maxLength={30}
              />
            </div>
            <div className="col-span-12">
              <TextField
                formik={formik}
                id={'configurationValue'}
                label={'Configuration Value'}
                maxLength={30}
              />
            </div>
            <div className="col-span-6 ">
              <Checkbox
                title="IsActive"
                id="isActive"
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                error={formik.errors.isActive}
                errorMessage={
                  formik.touched.isActive && formik.errors.isActive
                    ? formik.errors.isActive
                    : null
                }
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default EditConfigurationsModal;
