import { Tag } from 'antd';
import Layout from 'layout/AdminLayout';
import { FaRoute } from 'react-icons/fa6';
import { FaMapMarkerAlt } from 'react-icons/fa';

const data = [
  {
    driverAssigned: 'John Doe',
    vehicleType: 'Sedan',
    range: '300 miles',
    distanceTravelled: '120 miles',
    quantity: 15,
    stops: 3,
  },
  {
    driverAssigned: 'Jane Smith',
    vehicleType: 'SUV',
    range: '400 miles',
    distanceTravelled: '250 miles',
    quantity: 10,
    stops: 5,
  },
  {
    driverAssigned: 'Mike Johnson',
    vehicleType: 'Truck',
    range: '500 miles',
    distanceTravelled: '350 miles',
    quantity: 20,
    stops: 4,
  },
  {
    driverAssigned: 'Anna Brown',
    vehicleType: 'Van',
    range: '350 miles',
    distanceTravelled: '200 miles',
    quantity: 8,
    stops: 2,
  },
  {
    driverAssigned: 'Chris Green',
    vehicleType: 'Sedan',
    range: '300 miles',
    distanceTravelled: '150 miles',
    quantity: 12,
    stops: 3,
  },
  {
    driverAssigned: 'David Wilson',
    vehicleType: 'Motorcycle',
    range: '200 miles',
    distanceTravelled: '100 miles',
    quantity: 5,
    stops: 1,
  },
  {
    driverAssigned: 'Emily White',
    vehicleType: 'SUV',
    range: '400 miles',
    distanceTravelled: '280 miles',
    quantity: 9,
    stops: 4,
  },
  {
    driverAssigned: 'Liam Miller',
    vehicleType: 'Truck',
    range: '500 miles',
    distanceTravelled: '450 miles',
    quantity: 18,
    stops: 6,
  },
  {
    driverAssigned: 'Sophia Davis',
    vehicleType: 'Sedan',
    range: '300 miles',
    distanceTravelled: '210 miles',
    quantity: 14,
    stops: 3,
  },
  {
    driverAssigned: 'Noah Brown',
    vehicleType: 'Van',
    range: '350 miles',
    distanceTravelled: '190 miles',
    quantity: 6,
    stops: 2,
  },
  {
    driverAssigned: 'Olivia Taylor',
    vehicleType: 'SUV',
    range: '400 miles',
    distanceTravelled: '260 miles',
    quantity: 11,
    stops: 5,
  },
  {
    driverAssigned: 'Isabella Martinez',
    vehicleType: 'Truck',
    range: '500 miles',
    distanceTravelled: '320 miles',
    quantity: 16,
    stops: 4,
  },
  {
    driverAssigned: 'James Moore',
    vehicleType: 'Sedan',
    range: '300 miles',
    distanceTravelled: '130 miles',
    quantity: 13,
    stops: 3,
  },
  {
    driverAssigned: 'William Anderson',
    vehicleType: 'Van',
    range: '350 miles',
    distanceTravelled: '175 miles',
    quantity: 7,
    stops: 2,
  },
  {
    driverAssigned: 'Lucas Thomas',
    vehicleType: 'SUV',
    range: '400 miles',
    distanceTravelled: '295 miles',
    quantity: 10,
    stops: 5,
  },
  {
    driverAssigned: 'Mia Lee',
    vehicleType: 'Truck',
    range: '500 miles',
    distanceTravelled: '400 miles',
    quantity: 17,
    stops: 6,
  },
  {
    driverAssigned: 'Ava Jackson',
    vehicleType: 'Sedan',
    range: '300 miles',
    distanceTravelled: '140 miles',
    quantity: 12,
    stops: 3,
  },
  {
    driverAssigned: 'Ethan Harris',
    vehicleType: 'Motorcycle',
    range: '200 miles',
    distanceTravelled: '95 miles',
    quantity: 4,
    stops: 1,
  },
  {
    driverAssigned: 'Charlotte Martin',
    vehicleType: 'Van',
    range: '350 miles',
    distanceTravelled: '225 miles',
    quantity: 9,
    stops: 2,
  },
  {
    driverAssigned: 'Amelia Thompson',
    vehicleType: 'SUV',
    range: '400 miles',
    distanceTravelled: '285 miles',
    quantity: 10,
    stops: 4,
  },
];

const CapacityReport = () => {
  return (
    <Layout>
      <>
        <div>
          <div className="flex w-full px-[10px] py-[10px] bg-[#F7F7FA] font-semiBold text-[12px]">
            <div className="w-full">Driver Assigned</div>
            <div className="w-full">Vehicle Type</div>
            <div className="w-full">Range</div>
            <div className="w-full">Distance Travelled</div>
            <div className="w-full text-center">Quantity</div>
          </div>
          {data?.map((item, index) => {
            return (
              <div
                key={index}
                className="flex items-center w-full px-[10px] py-[10px] text-[10px] border-b-[1px] border-[#f0f0f0]"
              >
                <div className="w-full">
                  <div className=" flex items-center gap-x-10 ">
                    <div className=" rounded-full w-[40px] h-[40px] border-black border-2">
                      <img src="" alt="" />
                    </div>
                    <div>
                      <div className=" text-[12px] font-semiBold">
                        {item.driverAssigned}
                      </div>
                      <div className=" flex items-center gap-x-5">
                        <div>
                          <FaRoute color="#3b82f6" />
                        </div>
                        <div className=" text-blue-500 font-semiBold">
                          {item.stops} stops
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full text-[12px] font-semiBold">
                  {item.vehicleType}
                </div>
                <div className="w-full">
                  <Tag color="magenta">
                    <div className=" flex items-center gap-x-5">
                      <div>
                        <FaMapMarkerAlt />
                      </div>
                      <div>{item.range}</div>
                    </div>
                  </Tag>
                </div>
                <div className="w-full  text-[12px] font-semiBold">{item.distanceTravelled}</div>
                <div className="w-full text-center  text-[12px] font-semiBold">{item.quantity}</div>
              </div>
            );
          })}
        </div>
      </>
    </Layout>
  );
};

export default CapacityReport;
