import { Button, Modal, Select } from 'antd';
import TextField from 'components/CustomFields/TextField';
import ToastNotification from 'components/ToastNotification';
import { add_menu } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { capitalizeWords } from 'utils/helper';
import useValidationSchema from 'validation/useValidationSchema';

const AddPagesModal = ({ openAddMenuModal, setOpenMenuModal, refetch }) => {
  const { postRequest, useGetRequest } = useApiClient();
  const queryClient = useQueryClient();
  // const { data, error, isLoading } = useGetRequest('/api/AppPages/GetAllPages');
  const { menuModalScehema } = useValidationSchema();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  // const options = data?.data?.map((item) => {
  //   return {
  //     label: item.pageName,
  //     value: item.pageId,
  //   };
  // });
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const formik = useFormik({
    initialValues: {
      menuName: '',
      isActive: false,
      // isDeleted: false,
    },
    validationSchema: menuModalScehema,
    onSubmit: ({ menuName, isActive }) => {
      setLoading(true);
      const paylaod = {
        menuName: capitalizeWords(menuName),
        menuId: 0,
        isActive: isActive,
      };
      postRequest(add_menu, paylaod)
        .then((res) => {
          if (res?.success) {
            setOpenMenuModal(false);
            refetch();
            formik.resetForm();
            ToastNotification('success', res.message, themeMode);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCancel = () => {
    setOpenMenuModal(false);
    formik.resetForm();
  };
  return (
    <>
      <Modal
        open={openAddMenuModal}
        // className="!w-[400px]"
        width={400}
        title={<span className="text-[18px]">Add Menu</span>}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <>
          <div className="grid grid-cols-12 gap-x-20 ">
            <div className="col-span-12">
              <TextField
                formik={formik}
                id={'menuName'}
                label={'Menu Name'}
                maxLength={30}
              />
            </div>
          </div>
          <div className=" grid grid-cols-12 mt-6">
            {/* <div className="col-span-6 ">
              <Checkbox
                title="IsActive"
                id="isActive"
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                error={formik.errors.isActive}
                errorMessage={
                  formik.touched.isActive && formik.errors.isActive
                    ? formik.errors.isActive
                    : null
                }
              />
            </div> */}
            {/* <div className="col-span-6">
              <Checkbox
                title="Deleted ?"
                id="isDeleted"
                name="isDeleted"
                checked={formik.values.isDeleted}
                onChange={formik.handleChange}
                error={formik.errors.isDeleted}
                errorMessage={
                  formik.touched.isDeleted && formik.errors.isDeleted
                    ? formik.errors.isDeleted
                    : null
                }
              />
            </div> */}
          </div>
        </>
      </Modal>
    </>
  );
};
export default AddPagesModal;
