import Layout from 'layout/AdminLayout';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { PiTruckLight } from 'react-icons/pi';
import { Progress } from 'antd';
import { Typography } from 'antd';
import SimpleLineChart from './component/SimpleLineChart';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { SiGooglemaps } from 'react-icons/si';
import { GiSandsOfTime } from 'react-icons/gi';
import { SiAdblock } from 'react-icons/si';
import { GiNightSleep } from 'react-icons/gi';
import { useDispatch, useSelector } from 'react-redux';
import { setPermssionsArray } from 'store/slices';
import useApiClient from 'config/useAxiosClient';

const { Title } = Typography;
const lineData = [
  { name: 'Jan', value: 400 },
  { name: 'Feb', value: 300 },
  { name: 'Mar', value: 200 },
  { name: 'Apr', value: 278 },
  { name: 'May', value: 189 },
  { name: 'Jun', value: 239 },
  { name: 'Jul', value: 349 },
  { name: 'Aug', value: 200 },
];
const getStrokeColor = (percent) => {
  if (percent <= 25) {
    return '#FF4D4F'; // Critical (Red)
  } else if (percent <= 50) {
    return '#FF9C6A'; // Bad (Orange)
  } else if (percent <= 75) {
    return '#FFC107'; // Moderate (Yellow)
  } else {
    return '#2D4DC2'; // Good (Blue)
  }
};

const Dashboard = () => {
  const { themeMode, colorTheme, clientId } = useSelector(
    (state) => state?.states
  );
  const dispatch = useDispatch();
  const { useGetRequest } = useApiClient();
  const { data, isLoading, refetch } = useGetRequest(
    `/api/RoleRights/GetUserPermissions?userid=${clientId}`
  );
  useEffect(() => {
    if (data?.success) {
      dispatch(setPermssionsArray(data?.data));
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return isLoading ? (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <img
        src="/images/gif/loading_gif.gif"
        alt="Loading..."
        className="w-[100px] h-[100px] sm:w-[300px] sm:h-[300px]"
      />
      {/* Road-like border under the truck */}
      <div className="w-[90px] sm:w-[300px] h-[2px] sm:h-[3px] bg-[#000000] mt-[-25px] sm:mt-[-70px] rounded-[5px]" />
    </div>
  ) : (
    <Layout>
      <div>
        <div className="mb-40">
          <Title
            level={4}
            className=""
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
              fontFamily: 'inter-bold',
            }}
          >
            Dashboard
          </Title>
        </div>
        <div className="  w-full space-y-[20px]">
          <div className=" sm:flex w-full sm:space-x-[30px] space-y-[30px] sm:space-y-0">
            <div className="sm:w-[70%]  space-y-[20px]">
              <div className=" grid grid-cols-10 max-lg:grid-cols-9 max-md:grid-cols-10 gap-[20px]">
                <div
                  className="max-sm:col-span-10 sm:col-span-5 md:col-span-3 lg:col-span-2 shadow-lg px-[20px] py-[20px] space-y-[20px] rounded-[8px]"
                  style={{
                    backgroundColor:
                      themeMode === 'light' ? '#f6f4f3' : '#121212',
                  }}
                >
                  <div
                    className=" w-[50px] h-[50px] rounded-[8px] flex items-center justify-center "
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? '#f6f8ff' : 'black',
                    }}
                  >
                    <PiTruckLight className=" text-[#2D4DC2] w-[25px] h-[25px]" />
                  </div>
                  <div className="">
                    <div className="text-[12px] text-[#5A5881] font-semiBold">
                      Total Vehicles1
                    </div>
                    <div
                      className=" text-[12px] font-bold"
                      style={{
                        color: themeMode === 'light' ? 'black' : '#f6f4f3',
                      }}
                    >
                      72.6k
                    </div>
                  </div>
                </div>
                <div
                  className="max-sm:col-span-10 sm:col-span-5 md:col-span-3 lg:col-span-2 shadow-lg px-[20px] py-[20px] space-y-[20px] rounded-[8px]"
                  style={{
                    backgroundColor:
                      themeMode === 'light' ? '#f6f4f3' : '#121212',
                  }}
                >
                  <div
                    className=" w-[50px] h-[50px] rounded-[8px] flex items-center justify-center "
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? '#f6f8ff' : 'black',
                    }}
                  >
                    <SiGooglemaps className=" text-[#00F26F] w-[25px] h-[25px]" />
                  </div>
                  <div className="">
                    <div className="text-[12px] text-[#5A5881] font-semiBold">
                      Total Vehicles2
                    </div>
                    <div
                      className=" text-[12px] font-bold"
                      style={{
                        color: themeMode === 'light' ? 'black' : '#f6f4f3',
                      }}
                    >
                      72.6k
                    </div>
                  </div>
                </div>
                <div
                  className="max-sm:col-span-10 sm:col-span-5 md:col-span-3 lg:col-span-2 shadow-lg px-[20px] py-[20px] space-y-[20px] rounded-[8px]"
                  style={{
                    backgroundColor:
                      themeMode === 'light' ? '#f6f4f3' : '#121212',
                  }}
                >
                  <div
                    className=" w-[50px] h-[50px] rounded-[8px] flex items-center justify-center "
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? '#f6f8ff' : 'black',
                    }}
                  >
                    <GiSandsOfTime className=" text-[#FAB503] w-[25px] h-[25px]" />
                  </div>
                  <div className="">
                    <div className="text-[12px] text-[#5A5881] font-semiBold">
                      Total Vehicles3
                    </div>
                    <div
                      className=" text-[12px] font-bold"
                      style={{
                        color: themeMode === 'light' ? 'black' : '#f6f4f3',
                      }}
                    >
                      72.6k
                    </div>
                  </div>
                </div>
                <div
                  className="max-sm:col-span-10 sm:col-span-5 md:col-span-3 lg:col-span-2 shadow-lg px-[20px] py-[20px] space-y-[20px] rounded-[8px]"
                  style={{
                    backgroundColor:
                      themeMode === 'light' ? '#f6f4f3' : '#121212',
                  }}
                >
                  <div
                    className=" w-[50px] h-[50px] rounded-[8px] flex items-center justify-center "
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? '#f6f8ff' : 'black',
                    }}
                  >
                    <SiAdblock className=" text-[#FF0000] w-[25px] h-[25px]" />
                  </div>
                  <div className="">
                    <div className="text-[12px] text-[#5A5881] font-semiBold">
                      Total Vehicles4
                    </div>
                    <div
                      className=" text-[12px] font-bold"
                      style={{
                        color: themeMode === 'light' ? 'black' : '#f6f4f3',
                      }}
                    >
                      72.6k
                    </div>
                  </div>
                </div>
                <div
                  className="max-sm:col-span-10 sm:col-span-5 md:col-span-3 lg:col-span-2 shadow-lg px-[20px] py-[20px] space-y-[20px] rounded-[8px]"
                  style={{
                    backgroundColor:
                      themeMode === 'light' ? '#f6f4f3' : '#121212',
                  }}
                >
                  <div
                    className=" w-[50px] h-[50px] rounded-[8px] flex items-center justify-center "
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? '#f6f8ff' : 'black',
                    }}
                  >
                    <GiNightSleep className=" text-[#4524F8] w-[25px] h-[25px]" />
                  </div>
                  <div className="">
                    <div className="text-[12px] text-[#5A5881] font-semiBold">
                      Total Vehicles5
                    </div>
                    <div
                      className=" text-[12px] font-bold"
                      style={{
                        color: themeMode === 'light' ? 'black' : '#f6f4f3',
                      }}
                    >
                      72.6k
                    </div>
                  </div>
                </div>
              </div>
              <div className=" ">
                <SimpleLineChart data={lineData} />
              </div>
            </div>
            <div className="sm:w-[30%] rounded-[10px] ">
              <div className=" space-y-[20px]">
                <div
                  className=" py-[15px] space-y-10   rounded-[8px] shadow-lg "
                  style={{
                    backgroundColor:
                      themeMode === 'light' ? '#f6f4f3' : '#121212',
                  }}
                >
                  <div className=" flex justify-evenly max-sm:flex-row max-lg:flex-col">
                    <div>
                      <div className="font-bold text-[#5A5881] text-[14px]">
                        Sessions By Device
                      </div>
                      <div className=" relative z-0  w-[165px] h-[165px] ">
                        <div className=" absolute z-10  w-[165px] h-[165px] flex items-center justify-center ">
                          <Progress
                            type="circle"
                            percent={70}
                            format={() => null}
                            strokeColor="#2D4DC2"
                            strokeWidth={5}
                            size={130}
                          />
                        </div>
                        <div className=" absolute z-10  w-[165px] h-[165px] flex items-center justify-center">
                          <Progress
                            type="circle"
                            percent={80}
                            format={() => null}
                            strokeColor="#FAB503"
                            size={90}
                            strokeWidth={7}
                          />
                        </div>
                        <div className=" absolute z-10  w-[165px] h-[165px] flex items-center justify-center">
                          <Progress
                            type="circle"
                            percent={60}
                            format={() => null}
                            strokeColor="#00F26F"
                            size={40}
                            strokeWidth={10}
                          />
                        </div>
                      </div>
                    </div>
                    <div className=" space-y-5 sm:flex justify-between items-end lg:flex-col">
                      <div>
                        <div className=" text-[12px] font-med text-[#5A5881] ">
                          Desktop
                        </div>
                        <div
                          className=" text-[12px] font-bold"
                          style={{
                            color: themeMode === 'light' ? 'black' : '#f6f4f3',
                          }}
                        >
                          8,085
                        </div>
                        <div className=" text-[12px] font-med text-[#5A5881] ">
                          13%
                        </div>
                      </div>
                      <div>
                        <div className=" text-[12px] font-med text-[#5A5881] ">
                          Desktop
                        </div>
                        <div
                          className=" text-[12px] font-bold"
                          style={{
                            color: themeMode === 'light' ? 'black' : '#f6f4f3',
                          }}
                        >
                          8,085
                        </div>
                        <div className=" text-[12px] font-med text-[#5A5881] ">
                          13%
                        </div>
                      </div>
                      <div>
                        <div className=" text-[12px] font-med text-[#5A5881] ">
                          Desktop
                        </div>
                        <div
                          className=" text-[12px] font-bold"
                          style={{
                            color: themeMode === 'light' ? 'black' : '#f6f4f3',
                          }}
                        >
                          8,085
                        </div>
                        <div className=" text-[12px] font-med text-[#5A5881] ">
                          13%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="  py-[19px] px-[15px] rounded-[8px] shadow-lg "
                  style={{
                    backgroundColor:
                      themeMode === 'light' ? '#f6f4f3' : '#121212',
                  }}
                >
                  <div className=" space-y-7">
                    <div>
                      <div className=" text-[#5A5881] font-bold text-[14px]">
                        Vehicle Health
                      </div>
                    </div>
                    <div className=" flex items-center space-x-[30px] text-[12px] text-[#5A5881] font-semiBold">
                      <div className=" ">Category</div>
                      <div className=" flex justify-between items-center w-full">
                        <div>Amount (%)</div>
                        <div>Value</div>
                      </div>
                    </div>
                    <div className="text-[12px] text-[#5A5881] font-semiBold space-y-[9px]">
                      <div className="flex justify-between items-center space-x-[50px]">
                        <div>Good</div>
                        <div className=" w-full">
                          <Progress
                            percent={51}
                            strokeColor={getStrokeColor(51)}
                            strokeWidth={5}
                            format={(percent) => (
                              <div className="text-center text-[#5A5881] ">
                                {`${percent}%`}
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <div className="flex justify-between items-center space-x-[50px] ">
                        <div>Good</div>
                        <div className=" w-full">
                          <Progress
                            percent={30}
                            strokeColor="#2D4DC2"
                            strokeWidth={5}
                            format={(percent) => (
                              <div className="text-center text-[#5A5881] ">
                                {`${percent}%`}
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <div className="flex justify-between items-center space-x-[50px]">
                        <div>Good</div>
                        <div className=" w-full">
                          <Progress
                            percent={30}
                            strokeColor="#2D4DC2"
                            strokeWidth={5}
                            format={(percent) => (
                              <div className="text-center text-[#5A5881] ">
                                {`${percent}%`}
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <div className="flex justify-between items-center space-x-[50px]">
                        <div>Good</div>
                        <div className=" w-full">
                          <Progress
                            percent={30}
                            strokeColor="#2D4DC2"
                            strokeWidth={5}
                            format={(percent) => (
                              <div className="text-center text-[#5A5881] ">
                                {`${percent}%`}
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" w-full ">
            <div className=" grid grid-cols-10 gap-x-[20px]">
              <div
                className=" col-span-10 sm:col-span-3  rounded-[8px] shadow-lg flex justify-between py-20 px-[30px]"
                style={{
                  backgroundColor:
                    themeMode === 'light' ? '#f6f4f3' : '#121212',
                }}
              >
                <div className=" space-y-5">
                  <div
                    className={`${
                      themeMode === 'dark' ? 'text-[#f6f4f3]' : colorTheme
                    } font-bold text-[12px]`}
                  >
                    5,461
                  </div>
                  <div className="text-[#5A5881] font-semiBold text-[12px]">
                    Today
                  </div>
                </div>
                <div>
                  <Progress
                    type="circle"
                    percent={60}
                    format={() => (
                      <div
                        className={`${
                          themeMode === 'dark' ? 'text-[#f6f4f3]' : colorTheme
                        } text-center`}
                      >{`User`}</div>
                    )}
                    strokeColor={`${
                      themeMode == 'dark' ? 'white' : colorTheme
                    }`}
                    size={60}
                    strokeWidth={10}
                  />
                </div>
                <div className=" space-y-5">
                  <div
                    className={`${
                      themeMode == 'dark' ? 'text-[#f6f4f3]' : colorTheme
                    } font-bold text-[12px]`}
                  >
                    5,461
                  </div>
                  <div className="text-[#5A5881] font-semiBold text-[12px]">
                    Today
                  </div>
                </div>
              </div>
              <div
                className=" col-span-10 sm:col-span-3  rounded-[8px] shadow-lg flex justify-between py-20 px-[30px]"
                style={{
                  backgroundColor:
                    themeMode === 'light' ? '#f6f4f3' : '#121212',
                }}
              >
                <div className=" space-y-5">
                  <div
                    className={`${
                      themeMode == 'dark' ? 'text-[#f6f4f3]' : colorTheme
                    } font-bold text-[12px]`}
                  >
                    5,461
                  </div>
                  <div className="text-[#5A5881] font-semiBold text-[12px]">
                    Today
                  </div>
                </div>
                <div>
                  <Progress
                    type="circle"
                    percent={60}
                    format={() => (
                      <div
                        className={`${
                          themeMode == 'dark' ? 'text-[#f6f4f3]' : colorTheme
                        } text-center`}
                      >{`User`}</div>
                    )}
                    strokeColor={`${
                      themeMode == 'dark' ? '#f6f4f3' : colorTheme
                    }`}
                    size={60}
                    strokeWidth={10}
                  />
                </div>
                <div className=" space-y-5">
                  <div
                    className={`${
                      themeMode == 'dark' ? 'text-[#f6f4f3]' : colorTheme
                    } font-bold text-[12px]`}
                  >
                    5,461
                  </div>
                  <div className="text-[#5A5881] font-semiBold text-[12px]">
                    Today
                  </div>
                </div>
              </div>
              <div
                className=" col-span-10 sm:col-span-4  rounded-[8px] shadow-lg sm:px-[50px] max-sm:px-[30px] py-20 sm:py-0 flex items-center justify-center "
                style={{
                  backgroundColor:
                    themeMode === 'light' ? '#f6f4f3' : '#121212',
                }}
              >
                <div className=" flex items-center justify-between  w-full">
                  <div>
                    <div
                      className={`${
                        themeMode === 'dark' ? 'text-[#f6f4f3]' : colorTheme
                      } font-semiBold text-[12px]`}
                    >
                      Sessions By Device
                    </div>
                    <div className="text-[#A3ABBD] text-[12px] font-med">
                      Top Region & session
                    </div>
                  </div>
                  <div className="">
                    <PrimaryButton title="View" className=" w-[100px]" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
