import React, { useState } from 'react';
import { Breadcrumb } from 'antd';
import LabelGrid from 'components/LabelGrid';
import Layout from 'layout/AdminLayout';
import { BiSave } from 'react-icons/bi';
import { GoHome } from 'react-icons/go';
import { PiUsersThreeBold } from 'react-icons/pi';
import TextField from 'components/InputFields/TextField';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { Typography } from 'antd';
import { FaPlus } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useQueryClient } from 'react-query';
import useApiClient from 'config/useAxiosClient';
import useValidationSchema from 'validation/useValidationSchema';
const { Title } = Typography;

const AddUsers = () => {
  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { postRequest } = useApiClient();
  const queryClient = useQueryClient();
  const { addUserSchema } = useValidationSchema();
  // States
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [iconsVisible, setIconsVisible] = useState(false);
  const formik = useFormik({
    initialValues: {
      uploadImage: '',
      name: '',
      email: '',
      role: '',
      address1: '',
      address2: '',
      countryCode: '',
      city: '',
      state: '',
      zipCode: '',
      phoneNumber: '',
      mobileNumber: '',
    },
    validationSchema: addUserSchema,
    onSubmit: ({}) => {},
  });
  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    setSelectedImage(selectedFile);
    formik.setFieldValue('uploadImage', selectedFile);
    e.target.value = null;
  };
  const handleRemoveImage = () => {
    setSelectedImage(null);
  };
  return (
    <Layout>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div className=" mb-[30px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
              }}
            >
              Add User
            </Title>
            <div className=" flex justify-between items-center ">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <PiUsersThreeBold />
                            <span
                              className=" cursor-pointer font-reg"
                              onClick={() => {
                                navigate('/users');
                              }}
                            >
                              Users
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <FaPlus
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />
                            <span
                              className="font-bold"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              Add User
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
              <div>
                <PrimaryButton
                  title="Save"
                  icon={<BiSave className=" w-[17px] h-[17px]" />}
                  htmlType="submit"
                  loading={loading}
                />
              </div>
            </div>
          </div>
          <div className=" mb-[40px]">
            <LabelGrid
              label="Basic Information"
              className="rounded-[5px] px-20 py-20"
            >
              <div className="grid grid-cols-12 sm:gap-x-[30px] gap-y-[25px] sm:gap-y-0 ">
                <div className=" col-span-12 sm:col-span-3 ">
                  {selectedImage ? (
                    <>
                      <div
                        className="w-full relative "
                        // onMouseEnter={() => {
                        //   setIconsVisible(true);
                        // }}
                        // onMouseLeave={() => {
                        //   setIconsVisible(false);
                        // }}
                      >
                        <div className="relative z-0">
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            alt="Selected"
                            className=" w-full h-[100px] object-fill flex items-center justify-center rounded-[8px] "
                          />
                        </div>
                        {/* {iconsVisible && (
                      <div className="absolute z-10 top-[1px] flex items-center justify-center w-full h-[100px]  rounded-[8px] ">
                        <div className=" flex items-center justify-center space-x-5">
                          <div className=" text-white cursor-pointer">
                            <EyeOutlined />
                          </div>
                          <div
                            className=" text-white cursor-pointer"
                            onClick={handleRemoveImage}
                          >
                            <DeleteOutlined />
                          </div>
                        </div>
                      </div>
                    )} */}
                      </div>
                    </>
                  ) : (
                    <>
                      <label
                        htmlFor="upload-image"
                        className=" w-full h-[100px]  font-semibold text-[14px]  flex items-center justify-center rounded-[8px] cursor-pointer"
                        style={{
                          border:
                            themeMode === 'light'
                              ? `1px dashed ${colorTheme}`
                              : '1px dashed white',
                          color: themeMode === 'light' ? '#000000' : '#9F9B93',
                          backgroundColor:
                            themeMode === 'light' ? '#FFFFFF' : '#121212',
                        }}
                      >
                        Upload Image here
                      </label>
                    </>
                  )}
                  <input
                    className=" hidden"
                    type="file"
                    id="upload-image"
                    accept="image/jpeg, image/png, image/jpg"
                    name="image"
                    onChange={handleImageChange}
                  />
                </div>
                <div className=" col-span-12 sm:col-span-3">
                  <div>
                    <TextField
                      id="name"
                      label="Name"
                      type="text"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      errorMessage={
                        formik.touched.name && formik.errors.name
                          ? formik.errors.name
                          : null
                      }
                    />
                  </div>
                </div>
                <div className=" col-span-12 sm:col-span-3">
                  <div>
                    <TextField
                      id="email"
                      label="Email"
                      type="text"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      errorMessage={
                        formik.touched.email && formik.errors.email
                          ? formik.errors.email
                          : null
                      }
                    />
                  </div>
                </div>
                <div className=" col-span-12 sm:col-span-3">
                  <div>
                    <TextField
                      id="role"
                      label="Role"
                      type="text"
                      name="role"
                      value={formik.values.role}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.role && Boolean(formik.errors.role)}
                      errorMessage={
                        formik.touched.role && formik.errors.role
                          ? formik.errors.role
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
            </LabelGrid>
          </div>
          <div>
            <LabelGrid
              label="Address & Contact"
              className="border-[#f0f0f0] border-[1px] rounded-[5px] px-20 py-20"
            >
              <div className="grid grid-cols-12 sm:gap-x-[30px]  gap-y-[25px] sm:gap-y-0 ">
                <div className=" col-span-12 sm:col-span-3">
                  <div>
                    <TextField
                      id="address1"
                      label="Address"
                      type="text"
                      name="address1"
                      value={formik.values.address1}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.address1 &&
                        Boolean(formik.errors.address1)
                      }
                      errorMessage={
                        formik.touched.address1 && formik.errors.address1
                          ? formik.errors.address1
                          : null
                      }
                    />
                  </div>
                </div>
                <div className=" col-span-12 sm:col-span-3">
                  <div>
                    <TextField
                      id="address2"
                      label="Address2"
                      type="text"
                      name="address2"
                      value={formik.values.address2}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.address2 &&
                        Boolean(formik.errors.address2)
                      }
                      errorMessage={
                        formik.touched.address2 && formik.errors.address2
                          ? formik.errors.address2
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 sm:gap-x-[30px]  gap-y-[25px] sm:gap-y-0  mt-[20px]">
                <div className=" col-span-12 sm:col-span-3">
                  <div>
                    <TextField
                      id="countryCode"
                      label="Country Code"
                      type="text"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      errorMessage={
                        formik.touched.email && formik.errors.email
                          ? formik.errors.email
                          : null
                      }
                    />
                  </div>
                </div>
                <div className=" col-span-12 sm:col-span-3">
                  <div>
                    <TextField
                      id="city"
                      label="City"
                      type="text"
                      name="city"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.city && Boolean(formik.errors.city)}
                      errorMessage={
                        formik.touched.city && formik.errors.city
                          ? formik.errors.city
                          : null
                      }
                    />
                  </div>
                </div>
                <div className=" col-span-12 sm:col-span-3">
                  <div>
                    <TextField
                      id="state"
                      label="State"
                      type="text"
                      name="state"
                      value={formik.values.state}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.state && Boolean(formik.errors.state)
                      }
                      errorMessage={
                        formik.touched.state && formik.errors.state
                          ? formik.errors.state
                          : null
                      }
                    />
                  </div>
                </div>
                <div className=" col-span-12 sm:col-span-3">
                  <div>
                    <TextField
                      id="zipCode"
                      label="Zip Code"
                      type="text"
                      name="zipCode"
                      value={formik.values.zipCode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.zipCode && Boolean(formik.errors.zipCode)
                      }
                      errorMessage={
                        formik.touched.zipCode && formik.errors.zipCode
                          ? formik.errors.zipCode
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 sm:gap-x-[30px] gap-y-[25px] sm:gap-y-0 mt-[20px]">
                <div className=" col-span-12 sm:col-span-3">
                  <div>
                    <TextField
                      id="phoneNumber"
                      label="Phone No."
                      type="text"
                      name="phoneNumber"
                      value={formik.values.phoneNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.phoneNumber &&
                        Boolean(formik.errors.phoneNumber)
                      }
                      errorMessage={
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                          ? formik.errors.phoneNumber
                          : null
                      }
                    />
                  </div>
                </div>
                <div className=" col-span-12 sm:col-span-3">
                  <div>
                    <TextField
                      id="mobileNumber"
                      label="Mobile Phone No."
                      type="text"
                      name="mobileNumber"
                      value={formik.values.mobileNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.mobileNumber &&
                        Boolean(formik.errors.mobileNumber)
                      }
                      errorMessage={
                        formik.touched.mobileNumber &&
                        formik.errors.mobileNumber
                          ? formik.errors.mobileNumber
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
            </LabelGrid>
          </div>
        </div>
      </form>
    </Layout>
  );
};

export default AddUsers;

// import React, { useState } from 'react';
// import { PlusOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
// import { Upload, message, Button, Image, Breadcrumb } from 'antd';
// import LabelGrid from 'components/LabelGrid';
// import Layout from 'layout/AdminLayout';
// import ImgCrop from 'antd-img-crop';
// import TextField from 'components/InputFields/TextField';
// import { BiSave } from 'react-icons/bi';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import './styles.css';
// import PrimaryButton from 'components/Buttons/PrimaryButton';
// import { useNavigate } from 'react-router-dom';
// const ImageUploadSchema = Yup.object().shape({
//   image: Yup.mixed().required('Image is required'),
// });
// const AddUsers = () => {
//   const [previewOpen, setPreviewOpen] = useState(false);
//   const [previewImage, setPreviewImage] = useState('');
//   const [fileList, setFileList] = useState([]);
//   const navigate = useNavigate();
//   const formik = useFormik({
//     initialValues: {
//       image: null,
//     },
//     validationSchema: ImageUploadSchema,
//     onSubmit: (values) => {
//       console.log('Submitted values:', values);
//     },
//   });
//   const handlePreview = async (file) => {
//     if (!file.url && !file.preview) {
//       file.preview = await new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(file.originFileObj);
//         reader.onload = () => resolve(reader.result);
//         reader.onerror = (error) => reject(error);
//       });
//     }
//     setPreviewImage(file.url || file.preview);
//     setPreviewOpen(true);
//   };
//   const handleChange = ({ fileList }) => {
//     console.log('fileList onchnage', fileList);
//     setFileList(fileList);
//     formik.setFieldValue('image', fileList[0]?.originFileObj || null);
//   };
//   const handleRemove = () => {
//     setFileList([]);
//     formik.setFieldValue('image', null);
//     setPreviewImage('');
//   };
//   const uploadButton = (
//     <button
//       style={{
//         border: 0,
//         background: 'none',
//       }}
//       type="button"
//     >
//       <PlusOutlined />
//       <div
//         style={{
//           marginTop: 8,
//         }}
//       >
//         Upload Image
//       </div>
//     </button>
//   );

//   return (
//     <Layout>
//       <div>
//         <div className=" mb-[30px]">
//           <div className="text-[28px] font-bold text-[#2D4DC2]">Add User</div>
//           <div className=" flex justify-between items-center">
//             <div>
//               <Breadcrumb
//                 separator="/"
//                 items={[
//                   {
//                     title: (
//                       <span
//                         className=" cursor-pointer font-reg"
//                         onClick={() => {
//                           navigate('/dashboard');
//                         }}
//                       >
//                         Dashboard
//                       </span>
//                     ),
//                   },
//                   {
//                     title: (
//                       <span
//                         className=" cursor-pointer font-reg"
//                         onClick={() => {
//                           navigate('/users');
//                         }}
//                       >
//                         Users
//                       </span>
//                     ),
//                   },
//                   {
//                     title: <span className=" font-med">Add User</span>,
//                   },
//                 ]}
//               />
//             </div>
//             <div>
//               <PrimaryButton
//                 title="Save"
//                 icon={<BiSave className=" w-[17px] h-[17px]" />}
//               />
//             </div>
//           </div>
//         </div>
//         <form onSubmit={formik.handleSubmit}>
//           <div>
//             <LabelGrid
//               label="Basic Information"
//               className="border-[#f0f0f0] border-[1px] rounded-[5px] px-20 py-20"
//             >
//               <div className="grid grid-cols-12 sm:gap-x-[30px] gap-y-[25px] sm:gap-y-0">
//                 <div className="col-span-12 sm:col-span-3">
//                   <ImgCrop showGrid rotationSlider aspectSlider showReset>
//                     <Upload
//                       // action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
//                       listType="picture-card"
//                       fileList={fileList}
//                       onPreview={handlePreview}
//                       onChange={handleChange}
//                       onRemove={handleRemove}
//                       beforeUpload={() => {
//                         return false;
//                       }}
//                       maxCount={1}
//                       className="customized"
//                     >
//                       {fileList?.length === 0 && uploadButton}
//                     </Upload>
//                   </ImgCrop>
//                   {previewImage && (
//                     <Image
//                       wrapperStyle={{
//                         display: 'none',
//                       }}
//                       preview={{
//                         visible: previewOpen,
//                         onVisibleChange: (visible) => setPreviewOpen(visible),
//                         afterOpenChange: (visible) =>
//                           !visible && setPreviewImage(''),
//                       }}
//                       src={previewImage}
//                     />
//                   )}
//                 </div>
//                 <div className="col-span-12 sm:col-span-3">
//                   <TextField
//                     id="name"
//                     label="Name"
//                     // Add other necessary props for TextField
//                   />
//                 </div>
//                 <div className="col-span-12 sm:col-span-3">
//                   <TextField
//                     id="email"
//                     label="Email"
//                     // Add other necessary props for TextField
//                   />
//                 </div>
//                 <div className="col-span-12 sm:col-span-3">
//                   <TextField
//                     id="role"
//                     label="Role"
//                     // Add other necessary props for TextField
//                   />
//                 </div>
//               </div>
//             </LabelGrid>
//           </div>
//           <div>
//             <LabelGrid
//               label="Address & Contact"
//               className="border-[#f0f0f0] border-[1px] rounded-[5px] px-20 py-20"
//             >
//               <div className="grid grid-cols-12 sm:gap-x-[30px] gap-y-[25px] sm:gap-y-0">
//                 <div className=" col-span-12 sm:col-span-3">
//                   <div>
//                     <TextField
//                       id="address1"
//                       label="Address"
//                       // type="text"
//                       // name="email"
//                       // placeholder="Enter User Email"
//                       // value={formik.values.email}
//                       // onChange={formik.handleChange}
//                       // onBlur={formik.handleBlur}
//                       // error={formik.touched.email && Boolean(formik.errors.email)}
//                       // errorMessage={
//                       //   formik.touched.email && formik.errors.email
//                       //     ? formik.errors.email
//                       //     : null
//                       // }
//                     />
//                   </div>
//                 </div>
//                 <div className=" col-span-12 sm:col-span-3">
//                   <div>
//                     <TextField
//                       id="address2"
//                       label="Address2"
//                       // type="text"
//                       // name="email"
//                       // placeholder="Enter User Email"
//                       // value={formik.values.email}
//                       // onChange={formik.handleChange}
//                       // onBlur={formik.handleBlur}
//                       // error={formik.touched.email && Boolean(formik.errors.email)}
//                       // errorMessage={
//                       //   formik.touched.email && formik.errors.email
//                       //     ? formik.errors.email
//                       //     : null
//                       // }
//                     />
//                   </div>
//                 </div>
//               </div>
//               <div className="grid grid-cols-12 sm:gap-x-[30px] gap-y-[25px] sm:gap-y-0 mt-[20px]">
//                 <div className=" col-span-12 sm:col-span-3">
//                   <div>
//                     <TextField
//                       id="countryCode"
//                       label="Country Code"
//                       // type="text"
//                       // name="email"
//                       // placeholder="Enter User Email"
//                       // value={formik.values.email}
//                       // onChange={formik.handleChange}
//                       // onBlur={formik.handleBlur}
//                       // error={formik.touched.email && Boolean(formik.errors.email)}
//                       // errorMessage={
//                       //   formik.touched.email && formik.errors.email
//                       //     ? formik.errors.email
//                       //     : null
//                       // }
//                     />
//                   </div>
//                 </div>
//                 <div className=" col-span-12 sm:col-span-3">
//                   <div>
//                     <TextField
//                       id="city"
//                       label="City"
//                       // type="text"
//                       // name="email"
//                       // placeholder="Enter User Email"
//                       // value={formik.values.email}
//                       // onChange={formik.handleChange}
//                       // onBlur={formik.handleBlur}
//                       // error={formik.touched.email && Boolean(formik.errors.email)}
//                       // errorMessage={
//                       //   formik.touched.email && formik.errors.email
//                       //     ? formik.errors.email
//                       //     : null
//                       // }
//                     />
//                   </div>
//                 </div>
//                 <div className=" col-span-12 sm:col-span-3">
//                   <div>
//                     <TextField
//                       id="state"
//                       label="State"
//                       // type="text"
//                       // name="email"
//                       // placeholder="Enter User Email"
//                       // value={formik.values.email}
//                       // onChange={formik.handleChange}
//                       // onBlur={formik.handleBlur}
//                       // error={formik.touched.email && Boolean(formik.errors.email)}
//                       // errorMessage={
//                       //   formik.touched.email && formik.errors.email
//                       //     ? formik.errors.email
//                       //     : null
//                       // }
//                     />
//                   </div>
//                 </div>
//                 <div className=" col-span-12 sm:col-span-3">
//                   <div>
//                     <TextField
//                       id="zipCode"
//                       label="Zip Code"
//                       // type="text"
//                       // name="email"
//                       // placeholder="Enter User Email"
//                       // value={formik.values.email}
//                       // onChange={formik.handleChange}
//                       // onBlur={formik.handleBlur}
//                       // error={formik.touched.email && Boolean(formik.errors.email)}
//                       // errorMessage={
//                       //   formik.touched.email && formik.errors.email
//                       //     ? formik.errors.email
//                       //     : null
//                       // }
//                     />
//                   </div>
//                 </div>
//               </div>
//               <div className="grid grid-cols-12 sm:gap-x-[30px] gap-y-[25px] sm:gap-y-0 mt-[20px]">
//                 <div className=" col-span-12 sm:col-span-3">
//                   <div>
//                     <TextField
//                       id="phoneNumber"
//                       label="Phone No."
//                       // type="text"
//                       // name="email"
//                       // placeholder="Enter User Email"
//                       // value={formik.values.email}
//                       // onChange={formik.handleChange}
//                       // onBlur={formik.handleBlur}
//                       // error={formik.touched.email && Boolean(formik.errors.email)}
//                       // errorMessage={
//                       //   formik.touched.email && formik.errors.email
//                       //     ? formik.errors.email
//                       //     : null
//                       // }
//                     />
//                   </div>
//                 </div>
//                 <div className=" col-span-12 sm:col-span-3">
//                   <div>
//                     <TextField
//                       id="mobileNumber"
//                       label="Mobile Phone No."
//                       // type="text"
//                       // name="email"
//                       // placeholder="Enter User Email"
//                       // value={formik.values.email}
//                       // onChange={formik.handleChange}
//                       // onBlur={formik.handleBlur}
//                       // error={formik.touched.email && Boolean(formik.errors.email)}
//                       // errorMessage={
//                       //   formik.touched.email && formik.errors.email
//                       //     ? formik.errors.email
//                       //     : null
//                       // }
//                     />
//                   </div>
//                 </div>
//               </div>
//             </LabelGrid>
//           </div>
//         </form>
//       </div>
//     </Layout>
//   );
// };

// export default AddUsers;
