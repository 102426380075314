import { Button, Modal } from 'antd';
import PasswordField from 'components/CustomFields/PasswordField';
import useApiClient from 'config/useAxiosClient';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useValidationSchema from 'validation/useValidationSchema';

const ResetPasswordModal = ({
  openResetPasswordModel,
  setOpenResetPasswordModel,
}) => {
  const [loading, setLoading] = useState(false);
  const { themeMode, colorTheme, clientId } = useSelector(
    (state) => state?.states
  );
  const { resetPasswordModalSchema } = useValidationSchema();
  const { postRequest } = useApiClient();
  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: resetPasswordModalSchema,
    onSubmit: ({ oldPassword, newPassword, confirmPassword }) => {
      setLoading(true);
      const paylaod = {
        userId: clientId,
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      };
      postRequest(`/api/User/ResetPassword`, paylaod)
        .then((res) => {
          if (res?.success) {
            setOpenResetPasswordModel(false);
            formik.resetForm();
            toast.success(res.message);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => toast.error('Something went wrong'))
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCancel = () => {
    setOpenResetPasswordModel(false);
    formik.resetForm();
  };
  return (
    <>
      <Modal
        open={openResetPasswordModel}
        // className="!w-[400px]"
        width={400}
        title={<span className="text-[18px]">Reset Password</span>}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
          >
            Reset
          </Button>,
        ]}
      >
        <>
          <div className="grid grid-cols-12 gap-x-20  py-[10px]">
            <div className="col-span-12">
              <PasswordField
                formik={formik}
                id={'oldPassword'}
                label={'Old Password'}
              />
            </div>
            <div className="col-span-12">
              <PasswordField
                formik={formik}
                id={'newPassword'}
                label={'New Password'}
              />
            </div>
            <div className="col-span-12">
              <PasswordField
                formik={formik}
                id={'confirmPassword'}
                label={'Confirm Password'}
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default ResetPasswordModal;
