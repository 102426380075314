import SvgTrailer from 'components/SVG/SvgTrailer';
import Tooltip from 'components/Tooltip';
import { usePermissionVariables } from 'Hooks/HasPermission';
import {
  BsBuildingFillLock,
  BsBuildingGear,
  BsDeviceSsd,
  BsDeviceSsdFill,
} from 'react-icons/bs';
import { CiLocationArrow1 } from 'react-icons/ci';
import { FaTrailer, FaTruck, FaTruckLoading } from 'react-icons/fa';
import {
  FaArrowLeftLong,
  FaArrowRightLong,
  FaLocationArrow,
} from 'react-icons/fa6';
import { FiSettings, FiTruck } from 'react-icons/fi';
import { GoHome, GoHomeFill } from 'react-icons/go';
import { HiDocumentText, HiOutlineDocumentText } from 'react-icons/hi';
import { LiaMoneyCheckSolid, LiaTruckLoadingSolid } from 'react-icons/lia';
import { LuShieldCheck } from 'react-icons/lu';
import { MdOutlineAddLocationAlt } from 'react-icons/md';
import {
  PiSteeringWheel,
  PiSteeringWheelFill,
  PiUsersFourFill,
  PiUsersFourLight,
  PiUsersThreeBold,
  PiUsersThreeFill,
} from 'react-icons/pi';
import {
  RiListSettingsFill,
  RiListSettingsLine,
  RiMapPinUserFill,
  RiMapPinUserLine,
  RiUserLocationLine,
} from 'react-icons/ri';
import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { doesMenuExist } from 'utils/helper';
import './style.css';

function SideBar({ isSidebarOpen, setSidebarOpen, isMobile }) {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const Permissions = useSelector((state) => state.states?.permssionsArray);
  const menuExists = doesMenuExist(Permissions, [
    'Dashboard',
    'Tenant Management',
    'Subscription Plan',
    'Device Management',
    'Sim Management',
    'User',
    'Role Management',
    'Admin Setup',
    'General Settings',
    'Status Board',
    'Vehicles',
    'Drivers',
    'Orders',
    'Customers',
  ]);
  const { Users_View, SIM_Mangement_View, Tenant_Mangement_View } =
    usePermissionVariables();

  const location = useLocation();

  const toggleSidebarCollapse = () => {
    setSidebarOpen((prev) => !prev);
  };
  return (
    <>
      <div
        className={`${
          themeMode === 'light'
            ? 'light-sidebarWrapperCustom'
            : 'dark-sidebarWrapperCustom'
        } sm:relative  fixed z-20`}
        style={{
          backgroundColor: themeMode === 'light' ? '#F7F7FA' : '#121212',
        }}
      >
        <div
          className={`absolute z-10 -right-12 sm:top-[25px] ${
            !isSidebarOpen && isMobile ? 'hidden' : 'top-[10px]'
          }`}
        >
          <div
            className={`${
              themeMode === 'light' ? 'bg-white' : 'bg-black'
            } flex justify-center items-center w-[24px] h-[24px] rounded-[6px] cursor-pointer border-[#DCDCDC] border-[1px]`}
            onClick={toggleSidebarCollapse}
          >
            {isSidebarOpen ? (
              <>
                <FaArrowLeftLong
                  className="w-[12px] h-[12px]"
                  style={{
                    color: themeMode === 'light' ? colorTheme : '#ffffff',
                  }}
                />
              </>
            ) : (
              <>
                <FaArrowRightLong
                  className="w-[12px] h-[12px]"
                  style={{
                    color: themeMode === 'light' ? colorTheme : '#ffffff',
                  }}
                />
              </>
            )}
          </div>
        </div>

        <Sidebar
          className="h-[100vh]"
          collapsed={!isSidebarOpen}
          backgroundColor={themeMode === 'light' ? '#F7F7FA' : '#121212'}
          style={{
            borderRight: themeMode === 'light' ? '' : '0',
            padding: '0px',
          }}
          collapsedWidth={isMobile ? '0px' : '80px'}
          width={'226px'}
        >
          <Menu
            menuItemStyles={{
              button: ({ active }) => ({
                padding: '0px 0px 0px 12px',
                height: '40px',
                backgroundColor:
                  active && themeMode === 'light'
                    ? '#fff'
                    : active && themeMode === 'dark'
                    ? 'black'
                    : null,
                marginLeft: isSidebarOpen ? '10px' : '10px',
                marginRight: isSidebarOpen ? '10px' : '10px',
                borderRadius: active ? '4px' : '',
                // boxShadow: "1px 0 3px rgba(0, 0, 0, 0.3)",
                // themeMode === 'dark' ? '' : colorTheme,
                color:
                  active && themeMode === 'light'
                    ? colorTheme
                    : active && themeMode === 'dark'
                    ? 'white'
                    : !active && themeMode === 'light'
                    ? 'black'
                    : !active && themeMode === 'dark'
                    ? '#EBE7E5'
                    : null,
                borderLeft:
                  active && themeMode === 'light'
                    ? `4px solid ${colorTheme}`
                    : active && themeMode === 'dark'
                    ? '4px solid white'
                    : null,
                fontFamily: 'inter-med',
                '&:hover': {
                  // borderRadius: '10px',
                  backgroundColor:
                    active && themeMode === 'light'
                      ? 'white'
                      : active && themeMode === 'dark'
                      ? 'black'
                      : !active && themeMode === 'light'
                      ? '#f2f3f4'
                      : !active && themeMode === 'dark'
                      ? 'white'
                      : null,
                  color:
                    active && themeMode === 'light'
                      ? ''
                      : active && themeMode === 'dark'
                      ? 'white'
                      : !active && themeMode === 'light'
                      ? ''
                      : !active && themeMode === 'dark'
                      ? 'black'
                      : null,
                  fontWeight: 'bold',
                  // active ? '' : '#242539',
                  // borderBottom: active ? '' : '1px solid #F7F7FA',
                },
              }),
            }}
          >
            <div
              className=" text-center h-[60px]"
              style={{
                color: themeMode === 'light' ? 'black' : 'white',
              }}
            >
              {isSidebarOpen ? (
                <>
                  <div
                    className={`${
                      themeMode === 'dark' ? 'text-white' : 'text-black'
                    } text-[18px] font-bold mt-[20px] cursor-pointer`}
                    onClick={toggleSidebarCollapse}
                  >
                    TRACKER APP
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={`${
                      themeMode === 'dark' ? 'text-white' : 'text-black'
                    } text-[16px] font-bold cursor-pointer mt-20`}
                    onClick={toggleSidebarCollapse}
                  >
                    <div>T-</div>
                    <div>APP</div>
                  </div>
                </>
              )}
            </div>
            <div className="space-y-[4px]">
              {menuExists['Dashboard'] && (
                <Tooltip placement="right" title="Dashboard">
                  <MenuItem
                    icon={
                      location.pathname === '/dashboard' ? (
                        <GoHomeFill className={`w-[16px] h-[16px]`} />
                      ) : (
                        <GoHome className={`w-[16px] h-[16px]`} />
                      )
                    }
                    className="text-[13px] pl-[2px] font-med"
                    active={location.pathname === '/dashboard'}
                    component={<Link to="/dashboard" />}
                  >
                    Dashboard
                  </MenuItem>
                </Tooltip>
              )}

              {menuExists['Tenant Management'] && (
                <Tooltip
                  placement="right"
                  title="Tenant Management"
                  overlayClassName="custom-tooltip"
                >
                  <MenuItem
                    icon={
                      location.pathname === '/tenant-management' ||
                      location.pathname === '/add-tenant' ||
                      location.pathname.startsWith('/edit-tenant/') ? (
                        <BsBuildingFillLock className={`w-[16px] h-[16px]`} />
                      ) : (
                        <BsBuildingGear className={`w-[16px] h-[16px]`} />
                      )
                    }
                    className="  text-[13px] pl-[2px] font-med"
                    active={
                      location.pathname === '/tenant-management' ||
                      location.pathname === '/add-tenant' ||
                      location.pathname.startsWith('/edit-tenant/')
                    }
                    component={<Link to="/tenant-management" />}
                  >
                    Tenant Management
                  </MenuItem>
                </Tooltip>
              )}

              {menuExists['Subscription Plan'] && (
                <Tooltip
                  placement="right"
                  title="Subscription Plan"
                  overlayClassName="custom-tooltip"
                >
                  <MenuItem
                    icon={
                      <LiaMoneyCheckSolid className={`w-[18px] h-[18px]`} />
                    }
                    className="text-[13px] pl-[2px] font-med"
                    active={
                      location.pathname === '/plan-management' ||
                      location.pathname === '/add-subscription-plan' ||
                      location.pathname.startsWith('/edit-subscription-plan/')
                    }
                    component={<Link to="/plan-management" />}
                  >
                    Subscription Plan
                  </MenuItem>
                </Tooltip>
              )}

              {menuExists['Device Management'] && (
                <Tooltip
                  placement="right"
                  title="Tracker"
                  overlayClassName="custom-tooltip"
                >
                  <SubMenu
                    label="Tracker"
                    className="text-[13px] font-med"
                    active={
                      (!isSidebarOpen &&
                        location.pathname === '/device-management') ||
                      (!isSidebarOpen &&
                        location.pathname === '/add-new-device') ||
                      (!isSidebarOpen &&
                        location.pathname === '/sim-management')
                    }
                    icon={
                      <MdOutlineAddLocationAlt
                        className={`w-[16px] h-[16px]`}
                      />
                    }
                    defaultOpen={
                      location.pathname.startsWith('/device-management') ||
                      location.pathname.startsWith('/add-new-device') ||
                      location.pathname.startsWith('/sim-management') ||
                      location.pathname.startsWith('/edit-device/')
                    }
                  >
                    {menuExists['Device Management'] && (
                      <Tooltip
                        placement="right"
                        title="Tracker / Device Management"
                        overlayClassName="custom-tooltip"
                      >
                        <MenuItem
                          icon={
                            location.pathname === '/device-management' ||
                            location.pathname === '/add-new-device' ||
                            location.pathname.startsWith('/edit-device/') ? (
                              <BsDeviceSsdFill
                                className={`w-[16px] h-[16px]  `}
                              />
                            ) : (
                              <BsDeviceSsd className={`w-[16px] h-[16px]  `} />
                            )
                          }
                          className="mt-[10px] text-[13px] pl-[2px] font-med"
                          active={
                            location.pathname === '/device-management' ||
                            location.pathname === '/add-new-device' ||
                            location.pathname.startsWith('/edit-device/')
                          }
                          component={<Link to="/device-management" />}
                        >
                          Device Management
                        </MenuItem>
                      </Tooltip>
                    )}
                    {/* {menuExists['Sim Management'] && (
                      <Tooltip
                        placement="right"
                        title="Tracker Settings / SIM Management"
                        overlayClassName="custom-tooltip"
                      >
                        <MenuItem
                          icon={
                            location.pathname === '/sim-management' ? (
                              <BsSimFill className={`w-[16px] h-[16px]`} />
                            ) : (
                              <PiSimCardDuotone
                                className={`w-[16px] h-[16px]`}
                              />
                            )
                          }
                          className=" text-[13px] pl-[2px] font-med"
                          active={location.pathname === '/sim-management'}
                          component={<Link to="/sim-management" />}
                        >
                          SIM Management
                        </MenuItem>
                      </Tooltip>
                    )} */}
                  </SubMenu>
                </Tooltip>
              )}

              {(menuExists['User'] || menuExists['Role Management']) && (
                <Tooltip
                  placement="right"
                  title="Security"
                  overlayClassName="custom-tooltip"
                >
                  <SubMenu
                    label="Security"
                    className="text-[13px] font-med"
                    active={
                      (!isSidebarOpen && location.pathname === '/users') ||
                      (!isSidebarOpen && location.pathname === '/role')
                    }
                    icon={<LuShieldCheck className={`w-[16px] h-[16px]`} />}
                    defaultOpen={
                      location.pathname.startsWith('/users') ||
                      location.pathname.startsWith('/add-user') ||
                      location.pathname.startsWith('/edit-user') ||
                      location.pathname.startsWith('/role') ||
                      location.pathname.startsWith('/add-role') ||
                      location.pathname.startsWith('/edit-role')
                    }
                  >
                    {menuExists['User'] && (
                      <Tooltip
                        placement="right"
                        title="Security / Users"
                        overlayClassName="custom-tooltip"
                      >
                        <MenuItem
                          icon={
                            location.pathname === '/users' ||
                            location.pathname === '/add-user' ||
                            location.pathname === '/edit-user' ? (
                              <PiUsersThreeFill
                                className={`w-[16px] h-[16px]`}
                              />
                            ) : (
                              <PiUsersThreeBold
                                className={`w-[16px] h-[16px]`}
                              />
                            )
                          }
                          className="mt-[10px] text-[13px] pl-[2px] font-med"
                          active={
                            location.pathname === '/users' ||
                            location.pathname === '/add-user' ||
                            location.pathname === '/edit-user'
                          }
                          component={<Link to="/users" />}
                        >
                          Users
                        </MenuItem>
                      </Tooltip>
                    )}

                    {menuExists['Role Management'] && (
                      <Tooltip
                        placement="right"
                        title="Security / Role Management"
                        overlayClassName="custom-tooltip"
                      >
                        <MenuItem
                          icon={
                            location.pathname === '/role-management' ||
                            location.pathname === '/add-rolerights' ? (
                              <HiDocumentText className={`w-[16px] h-[16px]`} />
                            ) : (
                              <HiOutlineDocumentText
                                className={`w-[16px] h-[16px]`}
                              />
                            )
                          }
                          className=" text-[13px] pl-[2px] font-med"
                          active={
                            location.pathname === '/role-management' ||
                            location.pathname === '/add-rolerights'
                          }
                          component={<Link to="/role-management" />}
                        >
                          Role Management
                        </MenuItem>
                      </Tooltip>
                    )}
                  </SubMenu>
                </Tooltip>
              )}

              {menuExists['Admin Setup'] && (
                <Tooltip
                  placement="right"
                  title="Settings"
                  overlayClassName="custom-tooltip"
                >
                  <SubMenu
                    label="Settings"
                    className="text-[13px] font-med"
                    icon={<FiSettings className={`w-[16px] h-[16px]`} />}
                    active={!isSidebarOpen && location.pathname === '/settings'}
                    defaultOpen={location.pathname.startsWith('/settings')}
                  >
                    {menuExists['Admin Setup'] && (
                      <Tooltip
                        placement="right"
                        title="Settings / Admin Setup"
                        overlayClassName="custom-tooltip"
                      >
                        <MenuItem
                          icon={
                            location.pathname === '/settings' ? (
                              <RiListSettingsFill
                                className={`w-[16px] h-[16px]`}
                              />
                            ) : (
                              <RiListSettingsLine
                                className={`w-[16px] h-[16px]`}
                              />
                            )
                          }
                          className="  text-[13px] pl-[2px] font-med"
                          active={location.pathname === '/settings'}
                          component={<Link to="/settings" />}
                        >
                          Admin Setup
                        </MenuItem>
                      </Tooltip>
                    )}
                    {/* {menuExists['General Settings'] && (
                      <Tooltip
                        placement="right"
                        title="Settings / General Settings"
                        overlayClassName="custom-tooltip"
                      >
                        <MenuItem
                          icon={
                            location.pathname === '/tenant-settings' ||
                            location.pathname === '/add-vehicle' ||
                            // location.pathname === '/add-driver' ||
                            location.pathname.startsWith('/edit-vehicle/') ? (
                              // ||
                              // location.pathname.startsWith('/edit-driver/')
                              <TiSpanner size={20} />
                            ) : (
                              <TiSpannerOutline size={20} />
                            )
                          }
                          className="  text-[13px] pl-[2px] font-med"
                          active={
                            location.pathname === '/tenant-settings' ||
                            location.pathname === '/add-vehicle' ||
                            // location.pathname === '/add-driver' ||
                            location.pathname.startsWith('/edit-vehicle/')
                            //  ||
                            // location.pathname.startsWith('/edit-driver/')
                          }
                          component={<Link to="/tenant-settings" />}
                        >
                          General Settings
                        </MenuItem>
                      </Tooltip>
                    )} */}

                    {/* <Tooltip
                      placement="right"
                      title="Settings / Master Settings"
                      overlayClassName="custom-tooltip"
                    >
                      <MenuItem
                        icon={
                          location.pathname === '/master-setting' ? (
                            <MdAdminPanelSettings
                              className={`w-[16px] h-[16px]`}
                            />
                          ) : (
                            <MdOutlineAdminPanelSettings
                              className={`w-[16px] h-[16px]`}
                            />
                          )
                        }
                        className="  text-[13px] pl-[2px] font-med"
                        active={location.pathname === '/master-setting'}
                        component={<Link to="/master-setting" />}
                      >
                        Master Settings
                      </MenuItem>
                    </Tooltip> */}
                  </SubMenu>
                </Tooltip>
              )}
              <Tooltip
                placement="right"
                title="Fleet View"
                overlayClassName="custom-tooltip"
              >
                <MenuItem
                  icon={
                    location.pathname === '/fleet-view' ||
                    location.pathname.startsWith('/fleet-view-detail/') ? (
                      <FaLocationArrow className={`w-[16px] h-[16px]`} />
                    ) : (
                      <CiLocationArrow1
                        style={{ strokeWidth: 0.1 }}
                        className={`w-[16px] h-[16px]`}
                      />
                    )
                  }
                  className="  text-[13px] pl-[2px] font-med"
                  active={
                    location.pathname === '/fleet-view' ||
                    location.pathname.startsWith('/fleet-view-detail/')
                  }
                  component={<Link to="/fleet-view" />}
                >
                  Fleet View
                </MenuItem>
              </Tooltip>
              {/* <Tooltip
                placement="right"
                title="General Settings"
                overlayClassName="custom-tooltip"
              >
                <SubMenu
                  label="Tenant Settings"
                  className="text-[13px] font-med"
                  icon={
                    <MdOutlineManageAccounts className={`w-[16px] h-[16px]`} />
                  }
                  active={
                    (!isSidebarOpen && location.pathname === '/vehicles') ||
                    (!isSidebarOpen && location.pathname === '/drivers') ||
                    (!isSidebarOpen && location.pathname === '/add-driver') ||
                    (!isSidebarOpen && location.pathname === '/edit-driver') ||
                    (!isSidebarOpen &&
                      location.pathname === '/add-vehicle-information') ||
                    (!isSidebarOpen &&
                      location.pathname.startsWith(
                        '/edit-vehicle-information/'
                      )) ||
                    (!isSidebarOpen && location.pathname === '/add-driver')
                  }
                  defaultOpen={
                    location.pathname === '/vehicles' ||
                    location.pathname === '/drivers' ||
                    location.pathname === '/add-vehicle-information' ||
                    location.pathname.startsWith(
                      '/edit-vehicle-information/'
                    ) ||
                    location.pathname === '/add-driver' ||
                    location.pathname.startsWith('/edit-driver/')
                  }
                >
                  <Tooltip
                    placement="right"
                    title="Settings / Admin Setup"
                    overlayClassName="custom-tooltip"
                  >
                    <MenuItem
                      icon={
                        location.pathname === '/vehicles' ||
                        location.pathname === '/add-vehicle-information' ||
                        location.pathname.startsWith(
                          '/edit-vehicle-information/'
                        ) ? (
                          <FaTruck className={`w-[16px] h-[16px]`} />
                        ) : (
                          <FiTruck className={`w-[16px] h-[16px]`} />
                        )
                      }
                      className="  text-[13px] pl-[2px] font-med"
                      active={
                        location.pathname === '/vehicles' ||
                        location.pathname === '/add-vehicle-information' ||
                        location.pathname.startsWith(
                          '/edit-vehicle-information/'
                        )
                      }
                      component={<Link to="/vehicles" />}
                    >
                      Vehicles
                    </MenuItem>
                  </Tooltip>

                  <Tooltip
                    placement="right"
                    title="Tenant Settings / Driver"
                    overlayClassName="custom-tooltip"
                  >
                    <MenuItem
                      icon={
                        location.pathname === '/drivers' ||
                        location.pathname === '/add-driver' ||
                        location.pathname.startsWith('/edit-driver/') ? (
                          <PiSteeringWheelFill size={20} />
                        ) : (
                          <PiSteeringWheel size={20} />
                        )
                      }
                      className="  text-[13px] pl-[2px] font-med"
                      active={
                        location.pathname === '/drivers' ||
                        location.pathname.startsWith('/add-driver') ||
                        location.pathname.startsWith('/edit-driver/')
                      }
                      defaultOpen={
                        location.pathname === '/drivers' ||
                        location.pathname.startsWith('/add-driver') ||
                        location.pathname.startsWith('/edit-driver/')
                      }
                      component={<Link to="/drivers" />}
                    >
                      Driver
                    </MenuItem>
                  </Tooltip>
                </SubMenu>
              </Tooltip> */}
              {(menuExists['Status Board'] ||
                menuExists['Vehicles'] ||
                menuExists['Drivers'] ||
                menuExists['Orders'] ||
                menuExists['Customers']) && (
                <Tooltip
                  placement="right"
                  title="TMS"
                  overlayClassName="custom-tooltip"
                >
                  <SubMenu
                    label="TMS"
                    className="text-[13px] font-med"
                    icon={
                      <RiUserLocationLine className={`w-[16px] h-[16px]`} />
                    }
                    defaultOpen={
                      // location.pathname.startsWith('/status-board') ||
                      // location.pathname.startsWith('/orders') ||
                      // location.pathname.startsWith('/add-order') ||
                      // location.pathname.startsWith('/edit-order/') ||
                      // location.pathname.startsWith('/trailers') ||
                      // location.pathname.startsWith('/jobs') ||
                      // location.pathname.startsWith('/add-jobs') ||
                      // location.pathname.startsWith('/customers') ||
                      // location.pathname.startsWith('/add-customer') ||
                      // location.pathname.startsWith('/rate-card-management') ||
                      // location.pathname.startsWith(
                      //   '/add-rate-card-management'
                      // ) ||
                      // location.pathname === '/vehicles' ||
                      // location.pathname.startsWith('/vehicles-details/') ||
                      // location.pathname === '/drivers' ||
                      // location.pathname === '/load-management' ||
                      // location.pathname === '/add-vehicle-information' ||
                      // location.pathname.startsWith(
                      //   '/edit-vehicle-information/'
                      // ) ||
                      // location.pathname === '/add-driver' ||
                      // location.pathname.startsWith('/edit-driver/') ||
                      // location.pathname.startsWith('/driver-detail/') ||
                      // location.pathname.startsWith('/add-load-management') ||
                      // location.pathname === '/add-trailer' ||
                      // location.pathname.startsWith('/edit-trailer/') ||
                      // location.pathname === '/T-view' ||
                      // location.pathname.startsWith('/fleet-view-detail/') ||
                      // location.pathname === '/unites' ||
                      // location.pathname === '/dispatcher' ||
                      // location.pathname === '/add-dispatcher' ||
                      // location.pathname.startsWith('/edit-dispatcher/')
                      true
                    }
                  >
                    {menuExists['Status Board'] && (
                      <Tooltip
                        placement="right"
                        title="TMS / Status Board"
                        overlayClassName="custom-tooltip"
                      >
                        <MenuItem
                          icon={
                            location.pathname === '/status-board' ? (
                              <RiListSettingsFill
                                className={`w-[16px] h-[16px]`}
                              />
                            ) : (
                              <RiListSettingsLine
                                className={`w-[16px] h-[16px]`}
                              />
                            )
                          }
                          className="  text-[13px] pl-[2px] font-med"
                          active={location.pathname === '/status-board'}
                          component={<Link to="/status-board" />}
                        >
                          Status Board
                        </MenuItem>
                      </Tooltip>
                    )}
                    {menuExists['Vehicles'] && (
                      <Tooltip
                        placement="right"
                        title="TMS / Vehicles"
                        overlayClassName="custom-tooltip"
                      >
                        <MenuItem
                          icon={
                            location.pathname === '/vehicles' ||
                            location.pathname === '/add-vehicle-information' ||
                            location.pathname.startsWith(
                              '/edit-vehicle-information/'
                            ) ||
                            location.pathname.startsWith(
                              '/vehicles-details/'
                            ) ? (
                              <FaTruck className={`w-[16px] h-[16px]`} />
                            ) : (
                              <FiTruck className={`w-[16px] h-[16px]`} />
                            )
                          }
                          className="  text-[13px] pl-[2px] font-med"
                          active={
                            location.pathname === '/vehicles' ||
                            location.pathname === '/add-vehicle-information' ||
                            location.pathname.startsWith(
                              '/edit-vehicle-information/'
                            ) ||
                            location.pathname.startsWith('/vehicles-details/')
                          }
                          component={<Link to="/vehicles" />}
                        >
                          Vehicles
                        </MenuItem>
                      </Tooltip>
                    )}
                    <Tooltip
                      placement="right"
                      title="TMS / Trailers"
                      overlayClassName="custom-tooltip"
                    >
                      <MenuItem
                        icon={
                          location.pathname === '/trailers' ||
                          location.pathname === '/add-trailer' ||
                          location.pathname.startsWith('/edit-trailer/') ? (
                            <FaTrailer size={20} />
                          ) : (
                            <SvgTrailer size={20} />
                          )
                        }
                        className="  text-[13px] pl-[2px] font-med"
                        active={
                          location.pathname === '/trailers' ||
                          location.pathname === '/add-trailer' ||
                          location.pathname.startsWith('/edit-trailer/')
                        }
                        defaultOpen={
                          location.pathname === '/trailers' ||
                          location.pathname === '/add-trailer' ||
                          location.pathname.startsWith('/edit-trailer/')
                        }
                        component={<Link to="/trailers" />}
                      >
                        Trailers
                      </MenuItem>
                    </Tooltip>

                    {menuExists['Drivers'] && (
                      <Tooltip
                        placement="right"
                        title="TMS / Drivers"
                        overlayClassName="custom-tooltip"
                      >
                        <MenuItem
                          icon={
                            location.pathname === '/drivers' ||
                            location.pathname === '/add-driver' ||
                            location.pathname.startsWith('/edit-driver/') ||
                            location.pathname.startsWith('/driver-detail/') ? (
                              <PiSteeringWheelFill size={20} />
                            ) : (
                              <PiSteeringWheel size={20} />
                            )
                          }
                          className="  text-[13px] pl-[2px] font-med"
                          active={
                            location.pathname === '/drivers' ||
                            location.pathname.startsWith('/add-driver') ||
                            location.pathname.startsWith('/edit-driver/') ||
                            location.pathname.startsWith('/driver-detail/')
                          }
                          defaultOpen={
                            location.pathname === '/drivers' ||
                            location.pathname.startsWith('/add-driver') ||
                            location.pathname.startsWith('/edit-driver/') ||
                            location.pathname.startsWith('/driver-detail/')
                          }
                          component={<Link to="/drivers" />}
                        >
                          Drivers
                        </MenuItem>
                      </Tooltip>
                    )}

                    {/* <Tooltip
                      placement="right"
                      title="TMS / Unites"
                      overlayClassName="custom-tooltip"
                    >
                      <MenuItem
                        icon={
                          location.pathname === '/unites' ? (
                            <PiUniteBold size={20} />
                          ) : (
                            <PiUniteThin size={20} />
                          )
                        }
                        className="  text-[13px] pl-[2px] font-med"
                        active={location.pathname === '/unites'}
                        defaultOpen={location.pathname === '/unites'}
                        component={<Link to="/unites" />}
                      >
                        Units
                      </MenuItem>
                    </Tooltip> */}
                    <Tooltip
                      placement="right"
                      title="TMS / Dispatchers"
                      overlayClassName="custom-tooltip"
                    >
                      <MenuItem
                        icon={
                          location.pathname === '/dispatcher' ||
                          location.pathname === '/add-dispatcher' ||
                          location.pathname.startsWith('/edit-dispatcher/') ? (
                            <RiMapPinUserFill size={20} />
                          ) : (
                            <RiMapPinUserLine size={20} />
                          )
                        }
                        className="  text-[13px] pl-[2px] font-med"
                        active={
                          location.pathname === '/dispatcher' ||
                          location.pathname === '/add-dispatcher' ||
                          location.pathname.startsWith('/edit-dispatcher/')
                        }
                        defaultOpen={
                          location.pathname === '/dispatcher' ||
                          location.pathname === '/add-dispatcher' ||
                          location.pathname.startsWith('/edit-dispatcher/')
                        }
                        component={<Link to="/dispatcher" />}
                      >
                        Dispatchers
                      </MenuItem>
                    </Tooltip>

                    {menuExists['Customers'] && (
                      <Tooltip
                        placement="right"
                        title="TMS / Customers"
                        overlayClassName="custom-tooltip"
                      >
                        <MenuItem
                          icon={
                            location.pathname === '/customers' ||
                            location.pathname === '/add-customer' ? (
                              <PiUsersFourFill
                                className={`w-[16px] h-[16px]`}
                              />
                            ) : (
                              <PiUsersFourLight
                                className={`w-[16px] h-[16px]`}
                              />
                            )
                          }
                          className="  text-[13px] pl-[2px] font-med"
                          active={
                            location.pathname === '/customers' ||
                            location.pathname === '/add-customer'
                          }
                          component={<Link to="/customers" />}
                        >
                          Customers
                        </MenuItem>
                      </Tooltip>
                    )}
                    {/* {menuExists['Orders'] && (
                      <Tooltip
                        placement="right"
                        title="TMS / Shipping Orders"
                        overlayClassName="custom-tooltip"
                      >
                        <MenuItem
                          icon={
                            location.pathname === '/orders' ||
                            location.pathname.startsWith('/edit-order/') ||
                            location.pathname === '/add-order' ? (
                              <HiMiniShoppingCart size={20} />
                            ) : (
                              <HiOutlineShoppingCart size={20} />
                            )
                          }
                          className="  text-[13px] pl-[2px] font-med"
                          active={
                            location.pathname === '/orders' ||
                            location.pathname.startsWith('/edit-order/') ||
                            location.pathname === '/add-order'
                          }
                          component={<Link to="/orders" />}
                        >
                          Shipping Orders
                        </MenuItem>
                      </Tooltip>
                    )} */}
                    {/* 
                    <Tooltip
                      placement="right"
                      title="Settings / Master Settings"
                      overlayClassName="custom-tooltip"
                    >
                      <MenuItem
                        icon={
                          location.pathname === '/jobs' ||
                          location.pathname === '/add-jobs' ? (
                            <TbBasketFilled className={`w-[16px] h-[16px]`} />
                          ) : (
                            <TbBasket className={`w-[16px] h-[16px]`} />
                          )
                        }
                        className="  text-[13px] pl-[2px] font-med"
                        active={
                          location.pathname === '/jobs' ||
                          location.pathname === '/add-jobs'
                        }
                        component={<Link to="/jobs" />}
                      >
                        Jobs
                      </MenuItem>
                    </Tooltip> */}

                    <Tooltip
                      placement="right"
                      title="TMS / Load Management"
                      overlayClassName="custom-tooltip"
                    >
                      <MenuItem
                        icon={
                          location.pathname === '/load-management' ||
                          location.pathname === '/add-load-management' ? (
                            <FaTruckLoading className={`w-[16px] h-[16px]`} />
                          ) : (
                            <LiaTruckLoadingSolid
                              className={`w-[16px] h-[16px]`}
                            />
                          )
                        }
                        className="  text-[13px] pl-[2px] font-med"
                        active={
                          location.pathname === '/load-management' ||
                          location.pathname === '/add-load-management'
                        }
                        component={<Link to="/load-management" />}
                      >
                        Load Management
                      </MenuItem>
                    </Tooltip>
                    {/* <Tooltip
                      placement="right"
                      title="TMS / Fleet View"
                      overlayClassName="custom-tooltip"
                    >
                      <MenuItem
                        icon={
                          location.pathname === '/fleet-view' ||
                          location.pathname.startsWith(
                            '/fleet-view-detail/'
                          ) ? (
                            <FaLocationArrow className={`w-[16px] h-[16px]`} />
                          ) : (
                            <CiLocationArrow1
                              style={{ strokeWidth: 0.1 }}
                              className={`w-[16px] h-[16px]`}
                            />
                          )
                        }
                        className="  text-[13px] pl-[2px] font-med"
                        active={
                          location.pathname === '/fleet-view' ||
                          location.pathname.startsWith('/fleet-view-detail/')
                        }
                        component={<Link to="/fleet-view" />}
                      >
                        Fleet View
                      </MenuItem>
                    </Tooltip> */}
                    {/* <Tooltip
                      placement="right"
                      title=""
                      overlayClassName="custom-tooltip"
                    >
                      <MenuItem
                        icon={
                          location.pathname === '/trip-overview' ? (
                            <FaTruckLoading className={`w-[16px] h-[16px]`} />
                          ) : (
                            <FaTruckLoading className={`w-[16px] h-[16px]`} />
                          )
                        }
                        className="  text-[13px] pl-[2px] font-med"
                        active={location.pathname === '/trip-overview'}
                        component={<Link to="/trip-overview" />}
                      >
                        Trip OverView
                      </MenuItem>
                    </Tooltip>
                    <Tooltip
                      placement="right"
                      title=""
                      overlayClassName="custom-tooltip"
                    >
                      <MenuItem
                        icon={
                          location.pathname === '/fuel-efficiency' ? (
                            <FaTruckLoading className={`w-[16px] h-[16px]`} />
                          ) : (
                            <FaTruckLoading className={`w-[16px] h-[16px]`} />
                          )
                        }
                        className="  text-[13px] pl-[2px] font-med"
                        active={location.pathname === '/fuel-efficiency'}
                        component={<Link to="/fuel-efficiency" />}
                      >
                        Fuel Efficiency
                      </MenuItem>
                    </Tooltip> */}
                  </SubMenu>
                </Tooltip>
              )}
            </div>
          </Menu>
        </Sidebar>
      </div>
    </>
  );
}
export default SideBar;
