import { Breadcrumb, Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import DatePickerField from 'components/CustomFields/DatePickerField';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
import LabelGrid from 'components/LabelGrid';
import ToastNotification from 'components/ToastNotification';
import useAxiosClientTMS from 'config/useAxiosClientTMS';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { usePermissionVariables } from 'Hooks/HasPermission';
import Layout from 'layout/AdminLayout';
import AddCustomerModal from 'pages/Customers/components/AddCustomerModal';
import { useState } from 'react';
import { BiSave } from 'react-icons/bi';
import { FaPlus } from 'react-icons/fa6';
import { GoHome } from 'react-icons/go';
import { HiOutlineShoppingCart } from 'react-icons/hi2';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { orderStatus } from 'utils/constant';
import { createOptions } from 'utils/helper';
import { GET_ORDERS_KEY } from 'utils/queryKey';
import useValidationSchema from 'validation/useValidationSchema';
const { Title } = Typography;

const AddOrders = () => {
  const location = useLocation();
  const { pageNumber, rowsPerPage } = location.state || {
    pageNumber: 1,
    rowsPerPage: 10,
  };
  const { Logs_View, Status_View, Configurations_View, General_View } =
    usePermissionVariables();
  const queryClient = useQueryClient();
  const { postRequest, useGetRequest } = useAxiosClientTMS();
  const [openAddCustomerModal, setOpenCustomerModal] = useState(false);
  const { addOrderSchema } = useValidationSchema();
  const {
    data: customerData,
    error,
    isLoading,
    refetch: customerDataRefetch,
  } = useGetRequest('/api/GetCustomerDropdown');
  const { refetch: orderRefetch } = useGetRequest(
    `/api/GetAllOrders?pagenumber=${pageNumber}&pagesize=${rowsPerPage}&searchquery=`,
    { enabled: false }
  );
  const customerOptions = createOptions(customerData?.data, 'name', 'id');

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  const formik = useFormik({
    initialValues: {
      customerId: null,
      orderName: '',
      orderDate: '',
      pickupDate: '',
      deliveryDate: '',
      pickupLocation: '',
      deliveryLocation: '',
      orderStatus: null,
      totalWeight: null,
    },
    validationSchema: addOrderSchema,
    onSubmit: ({
      customerId,
      orderName,
      orderDate,
      pickupDate,
      deliveryDate,
      pickupLocation,
      deliveryLocation,
      orderStatus,
      totalWeight,
    }) => {
      setLoading(true);
      const paylaod = {
        customerId: customerId,
        orderName: orderName,
        orderDate: dayjs(orderDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        deliveryDate: dayjs(deliveryDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        pickupLocation: pickupLocation,
        deliveryLocation: deliveryLocation,
        orderStatus: orderStatus,
        totalWeight: Number(totalWeight),
      };
      postRequest(`/api/AddOrder`, paylaod)
        .then((res) => {
          if (res?.success) {
            formik.resetForm();
            // orderRefetch();
            queryClient.invalidateQueries({
              queryKey: [GET_ORDERS_KEY],
            });
            ToastNotification('success', res.message, themeMode);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <Layout>
      <>
        <div className="mb-[30px] ">
          <Title
            level={4}
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
              fontFamily: 'inter-bold',
            }}
          >
            Add Shipping Orders
          </Title>
          <div className="flex justify-between items-center mb-20">
            <div>
              <Breadcrumb
                separator="/"
                items={[
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <GoHome />
                          <span
                            className=" cursor-pointer font-reg text-[13px]"
                            onClick={() => {
                              navigate('/dashboard');
                            }}
                          >
                            Dashboard
                          </span>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <HiOutlineShoppingCart />
                          <span
                            className=" cursor-pointer font-reg text-[13px]"
                            onClick={() => {
                              navigate('/orders');
                            }}
                          >
                            Shipping Orders
                          </span>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <FaPlus
                            style={{
                              color: themeMode === 'light' ? colorTheme : '',
                            }}
                          />
                          <span
                            className=" font-bold text-[13px]"
                            style={{
                              color: themeMode === 'light' ? colorTheme : '',
                            }}
                          >
                            Add Shipping Orders
                          </span>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="">
          <form onSubmit={formik.handleSubmit}>
            <LabelGrid
              label="Add Shipping Order"
              className=" rounded-[5px] px-20 py-20"
            >
              <div className="grid grid-cols-12 sm:gap-x-[20px]  gap-y-[25px] sm:gap-y-0 ">
                <div className="  col-span-12 md:col-span-3">
                  <SelectField
                    formik={formik}
                    id={'customerId'}
                    label={'Customers'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={customerOptions}
                    notModal={true}
                  />
                  <div
                    className="text-sm underline cursor-pointer my-[10px] text-end mt-[-8px] mr-2"
                    style={{
                      color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                    }}
                    onClick={() => {
                      setOpenCustomerModal(true);
                    }}
                  >
                    Create Customer
                  </div>
                </div>
                <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                  <TextField
                    formik={formik}
                    id={'orderName'}
                    label={'Order Name'}
                    maxLength={30}
                    notModal={true}
                  />
                </div>
                <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                  <DatePickerField
                    formik={formik}
                    id={'orderDate'}
                    label={'Order Date'}
                    minDate={dayjs()}
                    notModal={true}
                  />
                </div>

                {/* <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                  <DatePickerField
                    formik={formik}
                    id={'pickupDate'}
                    label={'Pickup Date'}
                    minDate={dayjs(formik.values.orderDate)}
                    notModal={true}
                  />
                </div> */}
                <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                  <DatePickerField
                    formik={formik}
                    id={'deliveryDate'}
                    label={'Delivery Date'}
                    minDate={dayjs(formik.values.orderDate)}
                    notModal={true}
                  />
                </div>
                <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                  <TextField
                    formik={formik}
                    id={'pickupLocation'}
                    label={'Pickup Location'}
                    maxLength={30}
                    notModal={true}
                  />
                </div>
                <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                  <TextField
                    formik={formik}
                    id={'deliveryLocation'}
                    label={'Delivery Location'}
                    maxLength={30}
                    notModal={true}
                  />
                </div>
                <div className="  col-span-12 md:col-span-3">
                  <SelectField
                    formik={formik}
                    id={'orderStatus'}
                    label={'Order Status'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={orderStatus}
                    notModal={true}
                  />
                </div>
                <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                  <TextField
                    formik={formik}
                    id={'totalWeight'}
                    label={'Total Weight'}
                    maxLength={10}
                    type={'number'}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.length <= 10) {
                        const numericValue = value === '' ? '' : Number(value);
                        if (numericValue >= 0) {
                          formik.setFieldValue('totalWeight', numericValue);
                        }
                        formik.setFieldTouched('totalWeight', true, false);
                      }
                    }}
                    notModal={true}
                  />
                </div>
              </div>
              <div className="flex justify-end">
                <PrimaryButton
                  title="Save"
                  icon={<BiSave className=" w-[17px] h-[17px]" />}
                  htmlType="submit"
                  loading={loading}
                />
              </div>
            </LabelGrid>
          </form>
          {openAddCustomerModal && (
            <AddCustomerModal
              setOpenCustomerModal={setOpenCustomerModal}
              openAddCustomerModal={openAddCustomerModal}
              refetch={customerDataRefetch}
            />
          )}
        </div>
      </>
    </Layout>
  );
};
export default AddOrders;
