import React, { useState } from 'react';
import { Dropdown, Menu, ColorPicker } from 'antd'; // Import ColorPicker from Ant Design
import { FiSettings } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { setColorTheme, setThemeMode } from 'store/slices';
import ColorPaletteModal from './ColorPaletteModal';

const CustomTheme = () => {
  const [openColorPaletteModal, setColorPaletteModal] = useState(false);
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const [selectedKeys, setSelectedKeys] = useState(
    themeMode === 'light' ? ['2'] : ['1']
  );
  const dispatch = useDispatch();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const handleMenuClick = ({ key }) => {
    setSelectedKeys([key]);
    setDropdownVisible(key == '2' || key == '3' ? true : false);
    switch (key) {
      case '1':
        dispatch(setThemeMode('dark'));
        break;
      case '2':
        dispatch(setThemeMode('light'));
        break;
      default:
        console.log('Unknown item clicked');
    }
  };

  const handleColorChange = (color) => {
    dispatch(setColorTheme(color.toHexString()));
  };
  const menu = (
    <Menu
      onClick={handleMenuClick}
      selectedKeys={selectedKeys}
      className=" !space-y-5 "
      style={{ backgroundColor: themeMode === 'light' ? '#F7F7FA' : '#121212' }}
    >
      <Menu.Item key="1">
        <span
          className={`${
            themeMode === 'light' ? 'text-black' : 'text-[#9FA8DA]'
          }`}
        >
          Dark Mode
        </span>
      </Menu.Item>
      <Menu.Item key="2">
        <span
          className={`${
            themeMode === 'light' ? 'text-black' : 'text-[#9FA8DA]'
          }`}
        >
          Light Mode
        </span>
      </Menu.Item>
      {themeMode === 'light' && (
        <Menu.Item
          key="3"
          onClick={() => {
            setColorPaletteModal(true);
          }}
        >
          {/* <ColorPicker
            defaultValue="#1677ff"
            size="small"
            value={colorTheme}
            onChange={handleColorChange}
            showText={(color) => <span> ({color.toHexString()})</span>}
            style={{
              border: 'none',
              backgroundColor: '#F7F7FA',
            }}
          /> */}
          <span
            className={`${
              themeMode === 'light' ? 'text-black' : 'text-[#9FA8DA]'
            }`}
          >
            Choose Color
          </span>
        </Menu.Item>
      )}
    </Menu>
  );
  return (
    <>
      <div className=" cursor-pointer">
        <Dropdown
          overlay={menu}
          trigger={['click']}
          placement="bottom"
          overlayClassName={`${
            themeMode === 'light' ? 'nav-light-mode' : 'nav-dark-mode'
          }`}
          overlayStyle={{
            width: 150,
          }}
          // visible={dropdownVisible}
          // onVisibleChange={(flag) => setDropdownVisible(flag)}
        >
          <FiSettings
            className="w-[17px] h-[17px] "
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
            }}
          />
        </Dropdown>
      </div>
      {openColorPaletteModal && (
        <ColorPaletteModal
          setColorPaletteModal={setColorPaletteModal}
          openColorPaletteModal={openColorPaletteModal}
        />
      )}
    </>
  );
};

export default CustomTheme;
