import { Button, Modal } from 'antd';
import TextField from 'components/CustomFields/TextField';
import PhoneInput from 'components/InputFields/PhoneInput';
import ToastNotification from 'components/ToastNotification';
import useAxiosClientTMS from 'config/useAxiosClientTMS';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useValidationSchema from 'validation/useValidationSchema';

const AddCustomerModal = ({
  openAddCustomerModal,
  setOpenCustomerModal,
  refetch,
}) => {
  const { themeMode } = useSelector((state) => state?.states);
  const { postRequest } = useAxiosClientTMS();
  const { customerModalSchema } = useValidationSchema();

  // States
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      customerName: '',
      phoneNumber: '+92',
      email: '',
      address: '',
    },
    validationSchema: customerModalSchema,
    onSubmit: ({ customerName, phoneNumber, email, address }) => {
      setLoading(true);
      const paylaod = {
        tenantId: 0,
        customerName: customerName,
        phoneNumber: phoneNumber,
        email: email,
        address: address,
      };
      postRequest(`/api/AddCustomer`, paylaod)
        .then((res) => {
          if (res?.success) {
            setOpenCustomerModal(false);
            refetch();
            formik.resetForm();
            ToastNotification('success', res?.message, themeMode);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const handleCancel = () => {
    setOpenCustomerModal(false);
    formik.resetForm();
  };
  return (
    <>
      <Modal
        open={openAddCustomerModal}
        width={600}
        title={<span className="text-[18px]">Create Customer</span>}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <>
          <div className="grid grid-cols-12 gap-x-20 gap-y-5  py-[10px]">
            <div className="sm:col-span-6 col-span-12">
              <TextField
                formik={formik}
                id={'customerName'}
                label={'Customer Name'}
                maxLength={30}
              />
            </div>
            <div className="sm:col-span-6 col-span-12">
              <TextField formik={formik} id={'email'} label={'Email'} />
            </div>
            <div className="sm:col-span-6 col-span-12">
              <PhoneInput
                label="Phone Number"
                name="phoneNumber"
                value={formik.values.phoneNumber}
                isModel={true}
                onChange={(value) => {
                  if (value.length <= 18) {
                    formik.setFieldValue('phoneNumber', value);
                    formik.setFieldTouched('phoneNumber', true, false);
                  }
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.phoneNumber && formik.errors.phoneNumber}
                errorMessage={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                    ? formik.errors.phoneNumber
                    : null
                }
              />
            </div>
            <div className="sm:col-span-6 col-span-12">
              <TextField
                formik={formik}
                id={'address'}
                label={'Address'}
                maxLength={30}
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default AddCustomerModal;
