import { Dropdown, Menu } from 'antd'; // Import ColorPicker from Ant Design
import { useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { RiUserFollowLine } from 'react-icons/ri';import { RiUserUnfollowLine } from "react-icons/ri";
import { useSelector } from 'react-redux';

const RateCardActions = () => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const [selectedKeys, setSelectedKeys] = useState();

  const handleMenuClick = ({ key }) => {
    setSelectedKeys([key]);
    switch (key) {
      case '1':
        console.log('Unknown item clicked');
        break;
      case '2':
        console.log('Unknown item clicked');
        break;
      case '3':
        console.log('Unknown item clicked');
        break;
      case '4':
        console.log('Unknown item clicked');
        break;
      default:
        console.log('Unknown item clicked');
    }
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      selectedKeys={selectedKeys}
      className=" !space-y-5 "
      style={{ backgroundColor: themeMode === 'light' ? '#F7F7FA' : '#121212' }}
    >
      <Menu.Item key="1" icon={<MdOutlineModeEditOutline size={14} />}>
        <span
          className={`${
            themeMode === 'light' ? 'text-black' : 'text-[#9FA8DA]'
          }`}
        >
          Edit
        </span>
      </Menu.Item>
      <Menu.Item key="2" icon={<MdDeleteOutline size={14} />}>
        <span
          className={`${
            themeMode === 'light' ? 'text-black' : 'text-[#9FA8DA]'
          }`}
        >
          Delete
        </span>
      </Menu.Item>
      <Menu.Item key="3" icon={<RiUserFollowLine size={14} />}>
        <span
          className={`${
            themeMode === 'light' ? 'text-black' : 'text-[#9FA8DA]'
          }`}
        >
          Activate
        </span>
      </Menu.Item>
      <Menu.Item key="4" icon={<RiUserUnfollowLine size={14} />}>
        <span
          className={`${
            themeMode === 'light' ? 'text-black' : 'text-[#9FA8DA]'
          }`}
        >
          Deactivate
        </span>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <div className=" cursor-pointer">
        <Dropdown
          overlay={menu}
          trigger={['click']}
          placement="bottom"
          overlayClassName={`${
            themeMode === 'light' ? 'nav-light-mode' : 'nav-dark-mode'
          }`}
          overlayStyle={{
            width: 150,
          }}
        >
          <BsThreeDotsVertical
            className="w-[17px] h-[17px] "
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
            }}
          />
        </Dropdown>
      </div>
    </>
  );
};

export default RateCardActions;
