import React from 'react';
import Layout from 'layout/AdminLayout';
import { Breadcrumb } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'antd';
import { Tabs } from 'antd';
import { GoHome } from 'react-icons/go';
import { AiFillSetting } from 'react-icons/ai';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { RiUserSettingsFill } from 'react-icons/ri';
import { BsThreeDots } from 'react-icons/bs';
import Vehicles from './components/Vehicles';
import Drivers from './components/Drivers';
import { TiSpanner } from 'react-icons/ti';
import { TiSpannerOutline } from 'react-icons/ti';
import { usePermissionVariables } from 'Hooks/HasPermission';

const { Title } = Typography;

const TenantSettings = () => {
  const { Drivers_View, Vehicles_View } = usePermissionVariables();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const onChange = (key) => {
    console.log(key);
  };
  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  const items = [
    Vehicles_View && {
      key: '1',
      label: 'Vehicles',
      children: <Vehicles />,
    },
    Drivers_View && {
      key: '2',
      label: 'Drivers',
      children: <Drivers />,
    },
  ];

  return (
    <Layout>
      <>
        <div>
          <div className=" mb-[30px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
                marginBottom: '10px',
              }}
            >
              Settings
            </Title>
            <div className="flex justify-between items-center mb-20">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <TiSpanner
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />
                            <span
                              className=" font-bold text-[13px]"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              General Settings
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
            <div className="mt-6">
              <Tabs
                // tabPosition={isMobile ? 'left' : ''}
                defaultActiveKey="1"
                items={items}
                type="card"
                onChange={onChange}
                moreIcon={
                  <span>
                    <BsThreeDots
                      style={{
                        color: themeMode === 'light' ? colorTheme : '#939BC9',
                      }}
                    />
                  </span>
                }
                size="small"
              />
            </div>
          </div>
          <div></div>
        </div>
      </>
    </Layout>
  );
};
export default TenantSettings;
