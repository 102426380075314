import React, { useEffect, useState } from 'react';
import { Button, Modal, Input, Select } from 'antd';
import { useFormik } from 'formik';
import useValidationSchema from 'validation/useValidationSchema';
import { useQueryClient } from 'react-query';
import useApiClient from 'config/useAxiosClient';
import Checkbox from 'components/InputFields/Checkbox';
import { SlArrowDown } from 'react-icons/sl';
import InputFieldCustomForm from 'components/CustomFields/CustomFieldsOutlinedForm';
import { useSelector } from 'react-redux';
import { themeInputColor } from 'utils/constant';
import { toast } from 'react-toastify';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
const EditSimManagementModal = ({
  openEditSimManagementModal,
  setOpenEditSimManagementModal,
  record,
  refetch,
}) => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { useGetRequest } = useApiClient();
  const { SIMManagementModalSchema } = useValidationSchema();
  const { postRequest, putRequest } = useApiClient();
  const queryClient = useQueryClient();
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      SIMNumber: '',
      brandName: null,
      isActive: false,
    },
    validationSchema: SIMManagementModalSchema,
    onSubmit: ({ SIMNumber, brandName, isActive }) => {
      setLoading(true);
      const paylaod = {};

      putRequest(`/api/User/UpdateUser?userId=${record?.userId}`, paylaod)
        .then((res) => {
          if (res?.success) {
            setOpenEditSimManagementModal(false);
            refetch();
            formik.resetForm();
            toast.success(res.message);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => toast.error('Something went wrong'))
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCancel = () => {
    setOpenEditSimManagementModal(false);
  };

  useEffect(() => {
    if (record) {
      formik.setValues({
        ...formik.values,
        name: record?.userName,
        // password: record?.passwordHash,
        email: record?.email,
        assignRole: record?.roleId,
        contactNumber: record?.contactNo,
        isTwoFactorEnabled: record?.isTwoFactorEnabled,
        isActive: record?.isActive,
      });
    }
  }, [record, openEditSimManagementModal]);
  return (
    <>
      <Modal
        open={openEditSimManagementModal}
        width={400}
        title={<span className="text-[18px]">Update SIM Info</span>}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
          >
            Update
          </Button>,
        ]}
      >
        <>
          <div className="grid grid-cols-12 gap-x-20 py-[10px]">
            <div className="col-span-12">
              <TextField formik={formik} id={'SIMNumber'} label={'SIM #'} />
            </div>
            <div className=" col-span-12">
              <SelectField
                formik={formik}
                id={'brandName'}
                label={'Brand Name'}
                optionId={'value'}
                optionName={'label'}
                optionData={[]}
              />
            </div>
          </div>
          <div className=" grid grid-cols-12">
            <div className="col-span-6">
              <Checkbox
                title="IsActive"
                id="isActive"
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                error={formik.errors.isActive}
                errorMessage={
                  formik.touched.isActive && formik.errors.isActive
                    ? formik.errors.isActive
                    : null
                }
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default EditSimManagementModal;
