import { QuestionCircleOutlined } from '@ant-design/icons';
import { usePermissionVariables } from 'Hooks/HasPermission';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SearchField from 'components/InputFields/SearchField';
import PopConfirm from 'components/PopConfirm';
import Status from 'components/Status';
import Table from 'components/Table';
import { delete_email_setting, get_email_settings } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { MdDeleteOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import AddEmailGatewayModal from './component/AddEmailGatewayModal';
import EditEmailGatewayModal from './component/EditEmailGatewayModal';

const EmailGateway = () => {
  const { themeMode } = useSelector((state) => state?.states);
  const {
    Email_Gateway_Delete,
    Email_Gateway_Edit,
    Email_Gateway_Add,
    Email_Gateway_View,
  } = usePermissionVariables();
  const [openAddEmailGatewayModal, setOpenAddEmailGatewayModal] =
    useState(false);
  const [openEditEmailGatewayModal, setOpenEditEmailGatewayModal] =
    useState(false);
  const [selectedRecord, setSelectedRecord] = useState({
    settingId: 0,
    smtpServer: '',
    smtpPort: null,
    senderEmail: '',
    senderName: '',
    smtpUserName: '',
    smtpPassword: '',
    enableSSL: false,
    isActive: false,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const { useGetRequest, deleteRequest } = useApiClient();
  const { data, error, isLoading, refetch } = useGetRequest(get_email_settings);
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({});

  const delHandler = (record) => {
    deleteRequest(`${delete_email_setting}${record?.settingId}`)
      .then((res) => {
        // if (res?.success) {
        const newTotalRecord = totalRecord - 1; // Decrement the total record count
        setTotalRecord(newTotalRecord); // Update the state

        // Check if we need to change the page number
        if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
          setPageNumber(pageNumber - 1); // Redirect to the previous page
        }
        refetch();
        toast.success('Gateway Deleted Successfully');
        //   toast.success(res?.message);
        // } else {
        //   toast.error(res?.message);
        // }
      })
      .catch((err) => toast.error('Something went wrong'));
  };

  const columns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      key: 'sr',
      // align: 'center',
      width: 80,
      ellipsis: true,
      render: (text, row, index) => index + 1,
    },
    {
      title: (
        <div className="flex justify-between items-center">Sender Name</div>
      ),
      dataIndex: 'senderName',
      sorter: (a, b) => a.senderName.localeCompare(b.senderName),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'senderName'
          ? sortOrder.order
          : 'ascend',
      showSorterTooltip: false,
      ellipsis: true,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    { title: 'Sender Email', dataIndex: 'senderEmail', ellipsis: true },
    { title: 'SMTP Server', dataIndex: 'smtpServer', ellipsis: true },
    { title: 'SMTP Port', dataIndex: 'smtpPort', ellipsis: true },
    {
      title: (
        <span className="flex items-center justify-center">Enable SSL</span>
      ),
      dataIndex: 'enableSSL',
      key: 'enableSSL',
      align: 'center',
      width: 300,
      ellipsis: true,
      render: (tag, record) => {
        return (
          <span>
            <Status tag={record?.enableSSL} />
          </span>
        );
      },
    },
    {
      title: <span className="flex items-center justify-center">Status</span>,
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      width: 300,
      ellipsis: true,
      render: (tag, record) => {
        return (
          <span>
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },
    ...(Email_Gateway_Edit || Email_Gateway_Delete
      ? [
          {
            title: (
              <span className=" flex items-center justify-center">Action</span>
            ),
            dataIndex: '',
            ellipsis: true,
            width: 100,
            render: (text, record) => (
              <span className="flex justify-center items-center space-x-2">
                {/* {Email_Gateway_Edit && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                    onClick={() => {
                      setSelectedRecord({
                        settingId: record?.settingId,
                        smtpServer: record?.smtpServer,
                        smtpPort: record?.smtpPort,
                        senderEmail: record?.senderEmail,
                        senderName: record?.senderName,
                        smtpUserName: record?.smtpUserName,
                        smtpPassword: record?.smtpPassword,
                        enableSSL: record?.enableSSL,
                        isActive: record?.isActive,
                      });
                      setOpenEditEmailGatewayModal(true);
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                )} */}
                {Email_Gateway_Delete && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this task? "
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    >
                      <MdDeleteOutline className=" w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                )}
              </span>
            ),
          },
        ]
      : []),
  ];

  return (
    <div>
      <div className="md:flex space-y-[20px] md:space-y-0 justify-between mt-10 mb-26">
        <SearchField
          htmlFor="search"
          id="myInputID"
          name="search"
          placeholder={'Search'}
          value={debouncedSearch}
          setDebouncedSearch={setDebouncedSearch}
          setPageNumber={setPageNumber}
          searchLoading={searchLoading}
          setSearchLoading={setSearchLoading}
          refetch={refetch}
          debouncedSearch={debouncedSearch}
          setSearch={setSearch}
        />
        {Email_Gateway_Add && (
          <div className="sm:flex justify-end">
            <PrimaryButton
              title="Add Email Gateway"
              icon={<FaPlus />}
              onClick={() => setOpenAddEmailGatewayModal(true)}
            />
          </div>
        )}
      </div>
      <Table
        data={data?.data}
        columns={columns}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        setTotalRecord={setTotalRecord}
        totalRecord={totalRecord}
        setPageNumber={setPageNumber}
        setRowsPerPage={setRowsPerPage}
        setSortOrder={setSortOrder}
        setFilters={setFilters}
        loading={isLoading}
      />
      {openAddEmailGatewayModal && (
        <AddEmailGatewayModal
          setOpenAddEmailGatewayModal={setOpenAddEmailGatewayModal}
          openAddEmailGatewayModal={openAddEmailGatewayModal}
        />
      )}
      {openEditEmailGatewayModal && (
        <EditEmailGatewayModal
          setOpenEditEmailGatewayModal={setOpenEditEmailGatewayModal}
          openEditEmailGatewayModal={openEditEmailGatewayModal}
          selectedRecord={selectedRecord}
        />
      )}
    </div>
  );
};
export default EmailGateway;
