import React, { useState } from 'react';
import Layout from 'layout/AdminLayout';
import { FaPlus } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Table } from 'antd';
import { HiOutlineDocumentText } from 'react-icons/hi';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import TextField from 'components/InputFields/TextField';
import { BiSave } from 'react-icons/bi';

import { Typography } from 'antd';
import { GoHome } from 'react-icons/go';
import { useSelector } from 'react-redux';
import Checkbox from 'components/InputFields/Checkbox';

const { Title } = Typography;

const initialData = [
  {
    key: '1',
    roleName: 'Admin',
    fullControl: false,
    canSearch: false,
    canView: false,
    canEdit: false,
    canDelete: false,
    canAdd: false,
    canPrint: false,
  },
  {
    key: '2',
    roleName: 'Editor',
    fullControl: false,
    canSearch: false,
    canView: false,
    canEdit: false,
    canDelete: false,
    canAdd: false,
    canPrint: false,
  },
  {
    key: '3',
    roleName: 'Permissions',
    fullControl: false,
    canSearch: false,
    canView: false,
    canEdit: false,
    canDelete: false,
    canAdd: false,
    canPrint: false,
  },
  {
    key: '4',
    roleName: 'Users',
    fullControl: false,
    canSearch: false,
    canView: false,
    canEdit: false,
    canDelete: false,
    canAdd: false,
    canPrint: false,
  },
  {
    key: '5',
    roleName: 'Templates',
    fullControl: false,
    canSearch: false,
    canView: false,
    canEdit: false,
    canDelete: false,
    canAdd: false,
    canPrint: false,
  },
  {
    key: '6',
    roleName: 'Tracking',
    fullControl: false,
    canSearch: false,
    canView: false,
    canEdit: false,
    canDelete: false,
    canAdd: false,
    canPrint: false,
  },
  {
    key: '7',
    roleName: 'Security',
    fullControl: false,
    canSearch: false,
    canView: false,
    canEdit: false,
    canDelete: false,
    canAdd: false,
    canPrint: false,
  },
  {
    key: '8',
    roleName: 'Manager',
    fullControl: false,
    canSearch: false,
    canView: false,
    canEdit: false,
    canDelete: false,
    canAdd: false,
    canPrint: false,
  },
  // Add more roles as needed
];

const AddRole = () => {
  const [data, setData] = useState(initialData);
  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  const handleCheckboxChange = (key, permission, checked) => {
    const updatedData = data.map((item) => {
      if (item.key === key) {
        if (permission === 'fullControl') {
          return {
            ...item,
            fullControl: checked,
            canSearch: checked,
            canView: checked,
            canEdit: checked,
            canDelete: checked,
            canAdd: checked,
            canPrint: checked,
          };
        } else {
          const updatedItem = { ...item, [permission]: checked };

          if (permission === 'canView' && !checked) {
            updatedItem.canEdit = false;
            updatedItem.canDelete = false;
            updatedItem.canAdd = false;
          }

          const allChecked =
            updatedItem.canSearch &&
            updatedItem.canView &&
            updatedItem.canEdit &&
            updatedItem.canDelete &&
            updatedItem.canAdd &&
            updatedItem.canPrint;

          return {
            ...updatedItem,
            fullControl: allChecked,
            canView:
              permission === 'canDelete' ||
              permission === 'canEdit' ||
              permission === 'canAdd'
                ? checked || updatedItem.canView
                : updatedItem.canView,
          };
        }
      }
      return item;
    });
    setData(updatedData);
  };
  const columns = [
    {
      title: 'Role Name',
      dataIndex: 'roleName',
    },
    {
      title: (
        <span className=" flex items-center justify-center whitespace-nowrap">
          Full Control
        </span>
      ),
      dataIndex: 'fullControl',
      render: (text, record) => (
        <span className=" flex justify-center items-center">
          <Checkbox
            type="checkbox"
            checked={record.fullControl}
            onChange={(e) =>
              handleCheckboxChange(record.key, 'fullControl', e.target.checked)
            }
          />
        </span>
      ),
    },
    {
      title: (
        <span className=" flex items-center justify-center whitespace-nowrap">
          Can Search
        </span>
      ),
      dataIndex: 'canSearch',
      render: (text, record) => (
        <span className=" flex justify-center items-center">
          <Checkbox
            type="checkbox"
            checked={record.canSearch}
            onChange={(e) =>
              handleCheckboxChange(record.key, 'canSearch', e.target.checked)
            }
          />
        </span>
      ),
    },
    {
      title: (
        <span className=" flex items-center justify-center whitespace-nowrap">
          Can View
        </span>
      ),
      dataIndex: 'canView',
      render: (text, record) => (
        <span className=" flex justify-center items-center">
          <Checkbox
            type="checkbox"
            checked={record.canView}
            onChange={(e) =>
              handleCheckboxChange(record.key, 'canView', e.target.checked)
            }
          />
        </span>
      ),
    },
    {
      title: (
        <span className=" flex items-center justify-center whitespace-nowrap">
          Can Edit
        </span>
      ),
      dataIndex: 'canEdit',
      render: (text, record) => (
        <span className=" flex justify-center items-center">
          <Checkbox
            type="checkbox"
            checked={record.canEdit}
            onChange={(e) =>
              handleCheckboxChange(record.key, 'canEdit', e.target.checked)
            }
          />
        </span>
      ),
    },
    {
      title: (
        <span className=" flex items-center justify-center whitespace-nowrap">
          Can Delete
        </span>
      ),
      dataIndex: 'canDelete',
      render: (text, record) => (
        <span className=" flex justify-center items-center">
          <Checkbox
            type="checkbox"
            checked={record.canDelete}
            onChange={(e) =>
              handleCheckboxChange(record.key, 'canDelete', e.target.checked)
            }
          />
        </span>
      ),
    },
    {
      title: (
        <span className=" flex items-center justify-center whitespace-nowrap">
          Can Add
        </span>
      ),
      dataIndex: 'canAdd',
      render: (text, record) => (
        <span className=" flex justify-center items-center">
          <Checkbox
            type="checkbox"
            checked={record.canAdd}
            onChange={(e) =>
              handleCheckboxChange(record.key, 'canAdd', e.target.checked)
            }
          />
        </span>
      ),
    },
    {
      title: (
        <span className=" flex items-center justify-center whitespace-nowrap">
          Can Print
        </span>
      ),
      dataIndex: 'canPrint',
      render: (text, record) => (
        <span className=" flex justify-center items-center">
          <Checkbox
            type="checkbox"
            checked={record.canPrint}
            onChange={(e) =>
              handleCheckboxChange(record.key, 'canPrint', e.target.checked)
            }
          />
        </span>
      ),
    },
  ];

  return (
    <Layout>
      <>
        <div>
          <div className=" mb-[40px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
              }}
            >
              Add Role
            </Title>
            <div className="flex justify-between items-center ">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <HiOutlineDocumentText />
                            <span
                              className=" cursor-pointer font-reg"
                              onClick={() => {
                                navigate('/role');
                              }}
                            >
                              Role
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <FaPlus
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />
                            <span
                              className=" font-bold"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              Add Role
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
              <div>
                <PrimaryButton
                  title="Save"
                  icon={<BiSave className=" w-[17px] h-[17px]" />}
                />
              </div>
            </div>
          </div>
          <div
            className=" rounded-[8px] pt-8 pb-20 px-20"
            style={{
              border:
                themeMode === 'light'
                  ? '1px solid #f0f0f0'
                  : '1px solid #9FA8DA',
            }}
          >
            <div className="grid grid-cols-12 gap-x-[25px] gap-y-[25px] sm:gap-y-0 ">
              <div className=" col-span-12 sm:col-span-5">
                <TextField
                  // id="email"
                  label="Name"
                  required
                  // type="text"
                  // name="email"
                  // placeholder="Enter User Email"
                  // value={formik.values.email}
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // error={
                  //   formik.touched.email && Boolean(formik.errors.email)
                  // }
                  // errorMessage={
                  //   formik.touched.email && formik.errors.email
                  //     ? formik.errors.email
                  //     : null
                  // }
                />
              </div>
              <div className="  col-span-12  sm:col-span-5">
                <TextField
                  // id="email"
                  label="Description"
                  required
                  // type="text"
                  // name="email"
                  // placeholder="Enter User Email"
                  // value={formik.values.email}
                  // onChange={formik.handleChange}
                  // onBlur={formik.handleBlur}
                  // error={
                  //   formik.touched.email && Boolean(formik.errors.email)
                  // }
                  // errorMessage={
                  //   formik.touched.email && formik.errors.email
                  //     ? formik.errors.email
                  //     : null
                  // }
                />
              </div>
            </div>
          </div>
          <div className="mt-[40px]">
            <Table
              dataSource={data}
              columns={columns}
              size="small"
              scroll={{ x: true }}
              pagination={false}
              bordered
              className={`${
                themeMode === 'light' ? 'light-table' : 'dark-table'
              }`}
            />
          </div>
        </div>
      </>
    </Layout>
  );
};

export default AddRole;
