import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Autocomplete,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import { Breadcrumb, Input, Spin, Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import CustomDrawer from 'components/CustomDrawer';
import InputFieldCustomForm from 'components/CustomFields/CustomFieldsOutlinedForm';
import DatePickerField from 'components/CustomFields/DatePickerField';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
import TimePickerField from 'components/CustomFields/TimePickerField';
import Dropdown from 'components/Dropdown';
import TextArea from 'components/InputFields/TextArea';
import LabelGrid from 'components/LabelGrid';
import PopConfirm from 'components/PopConfirm';
import PopOver from 'components/PopOver';
import Status from 'components/Status';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import { get_country_by_dropdown } from 'config/services';
import useAxiosClient from 'config/useAxiosClient';
import useAxiosClientTMS from 'config/useAxiosClientTMS';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { usePermissionVariables } from 'Hooks/HasPermission';
import Layout from 'layout/AdminLayout';
import { useEffect, useState } from 'react';
import { BiDownArrowAlt, BiSave, BiUpArrowAlt } from 'react-icons/bi';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaPlus, FaTruckLoading } from 'react-icons/fa';
import { FaRegNoteSticky } from 'react-icons/fa6';
import { GoHome } from 'react-icons/go';
import {
  MdCancel,
  MdDeleteOutline,
  MdOutlineModeEditOutline,
} from 'react-icons/md';
import { PiSteeringWheel, PiUsersFourLight } from 'react-icons/pi';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DATE_FORMATS, themeInputColor } from 'utils/constant';
import { createOptions, dayjsFormat } from 'utils/helper';
import {
  GET_COUNTRIES_DROPDOWN_KEY,
  GET_CUSTOMERS_DROPDOWN_KEY,
  GET_DISPATCHERS_DROPDOWN_KEY,
  GET_DRIVERS_DROPDOWN_KEY,
  GET_FLEET_VIEW_KEY,
  GET_LOADBYIDASYNC_KEY,
  GET_LOADS_KEY,
  GET_TRAILERS_DROPDOWN_KEY,
  GET_VEHICLES_DROPDOWN_KEY,
} from 'utils/queryKey';
import useValidationSchema from 'validation/useValidationSchema';
import * as Yup from 'yup';
import EditLoad from './components/EditLoad';

const { Title, Text } = Typography;

const Load = () => {
  const { Logs_View, Status_View, Configurations_View, General_View } =
    usePermissionVariables();
  const { postRequest, useGetRequest, deleteRequest, putRequest } =
    useAxiosClientTMS();
  const { useGetRequest: useGetRequest2 } = useAxiosClient();
  const queryClient = useQueryClient();
  const [openAddPickupDeliveryModal, setOpenPickupDeliveryModal] =
    useState(false);
  const [openEditPickupDeliveryModal, setOpenEditPickupDeliveryModal] =
    useState(false);
  const [rowPickupDeliveryData, setRowPickupDeliveryData] = useState({});
  const [formDropdownData, setFormDropdownData] = useState({});
  const { addLoadSchema, editLoadSchema } = useValidationSchema();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [selectorders, setSelectOrders] = useState([]);
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const [pageNumber, setPageNumber] = useState(1);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [totalRecord, setTotalRecord] = useState(10);
  const [isLoadOpen, setIsLoadOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editRecordId, setEditRecordId] = useState(null);
  const [search, setSearch] = useState('');
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const { data: LoadsData, isLoading: loadLoading } = useGetRequest(
    `/api/GetAllLoads?pagenumber=${pageNumber}&pagesize=${rowsPerPage}&searchquery=${search}`,
    {},
    GET_LOADS_KEY
  );
  const { data: dispatcherData, isLoading: dispatcherLoading } = useGetRequest(
    `/api/GetDispatcherDropdown`,
    {},
    GET_DISPATCHERS_DROPDOWN_KEY
  );

  const formik = useFormik({
    initialValues: {
      pickupDate: '',
      pickupTime: '',
      deliveryDate: '',
      deliveryTime: '',
      loadStatus: '',
      loadDispatcher: null,
      pickupAddress: '',
      pickupCountry: null,
      pickupState: null,
      pickupCity: null,
      pickupZipCode: null,
      deliveryCountry: null,
      deliveryAddress: '',
      deliveryState: null,
      deliveryCity: null,
      deliveryZipCode: null,
      customerId: null,
      postOffice: '',
      driverId: null,
      vehicleId: null,
      trailerId: null,
      loadDescription: '',
    },
    validationSchema: addLoadSchema,
    onSubmit: (
      {
        pickupDate,
        deliveryDate,
        loadStatus,
        pickupTime,
        deliveryTime,
        loadDispatcher,
        pickupCountry,
        pickupState,
        pickupCity,
        pickupZipCode,
        deliveryCountry,
        deliveryState,
        deliveryCity,
        deliveryZipCode,
        customerId,
        postOffice,
        driverId,
        vehicleId,
        trailerId,
        loadDescription,
        pickupAddress,
        deliveryAddress,
      },
      { resetForm }
    ) => {
      const pickupDateTime = `${dayjs(pickupDate).format(
        DATE_FORMATS.DATE_ONLY
      )} ${dayjs(pickupTime).format(DATE_FORMATS.TIME_ONLY)}`;
      const deliveryDateTime = `${dayjs(deliveryDate).format(
        DATE_FORMATS.DATE_ONLY
      )} ${dayjs(deliveryTime).format(DATE_FORMATS.TIME_ONLY)}`;
      const payload = {
        customerId: customerId,
        po: postOffice,
        dispatcherId: loadDispatcher,
        loadDescription: loadDescription,
        loadStatus: `${loadStatus}`,
        loadDate: dayjs().format(DATE_FORMATS.PAYLOAD_FORMAT),
        totalWeight: 1400,
        loadPoints: [
          {
            pointType: 'Pickup',
            location: pickupAddress,
            latitude: 34.0522,
            longitude: -118.2437,
            country: `${formDropdownData?.pickupCountry}`,
            city: `${formDropdownData?.pickupCity}`,
            state: `${formDropdownData?.pickupState}`,
            zipCode: `${formDropdownData?.pickupZipCode}`,
            scheduledDate: dayjs(pickupDateTime).format(
              DATE_FORMATS.PAYLOAD_DATE_TIME_FORMAT
            ),
          },
          {
            pointType: 'Delivery',
            location: deliveryAddress,
            latitude: 35.3733,
            longitude: -119.0187,
            country: `${formDropdownData?.deliveryCountry}`,
            city: `${formDropdownData?.deliveryCity}`,
            state: `${formDropdownData?.deliveryState}`,
            zipCode: `${formDropdownData?.deliveryZipCode}`,
            scheduledDate: dayjs(deliveryDateTime).format(
              DATE_FORMATS.PAYLOAD_DATE_TIME_FORMAT
            ),
          },
        ],
        loadAssignments: [
          {
            driverId: driverId,
            vehicleId: vehicleId,
            trailerId: trailerId,
            assignmentType: 'load',
          },
        ],
      };
      if (!editRecordId) {
        postRequest(`/api/AddLoad`, payload)
          .then((res) => {
            if (res?.success) {
              resetForm();
              setSelectOrders([]);
              queryClient.invalidateQueries({
                queryKey: [GET_LOADS_KEY],
              });
              setFormDropdownData({});
              // navigate('/load-management');
              ToastNotification('success', res?.message, themeMode);
            } else {
              ToastNotification('error', res.message, themeMode);
            }
          })
          .catch((err) =>
            ToastNotification('error', 'Something went wrong', themeMode)
          )
          .finally(() => {
            setLoading(false);
          });
      } else {
        putRequest(`/api/UpdateLoad/${editRecordId}`, payload)
          .then((res) => {
            if (res?.success) {
              resetForm();
              setSelectOrders([]);
              queryClient.invalidateQueries({
                queryKey: [GET_LOADS_KEY],
              });
              queryClient.invalidateQueries({
                queryKey: [GET_FLEET_VIEW_KEY],
              });
              setFormDropdownData({});
              setEditRecordId(null);
              // navigate('/load-management');
              ToastNotification('success', res?.message, themeMode);
            } else {
              ToastNotification('error', res.message, themeMode);
            }
          })
          .catch((err) =>
            ToastNotification('error', 'Something went wrong', themeMode)
          )
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });
  const innerFormik = useFormik({
    initialValues: {
      stop: [],
    },
    validationSchema: Yup.object({
      stop: Yup.array().of(
        Yup.object({
          stopDate: Yup.date()
            .required('Date is required')
            .test(
              'is-after-pickup',
              'Stop date must be on or after the pickup date',
              function (value) {
                const { pickupDate } = formik.values;
                return (
                  !pickupDate ||
                  dayjs(value).isSame(pickupDate, 'day') ||
                  dayjs(value).isAfter(pickupDate, 'day')
                );
              }
            )
            .test(
              'is-before-delivery',
              'Stop date must be on or before the delivery date',
              function (value) {
                const { deliveryDate } = formik.values;
                return (
                  !deliveryDate ||
                  dayjs(value).isBefore(deliveryDate, 'day') ||
                  dayjs(value).isSame(deliveryDate, 'day')
                );
              }
            ),
          stopTime: Yup.string().required('Time is required'),
          stopAddress: Yup.string().required('Address is required'),
          stopType: Yup.string().required('Type is required'),
        })
      ),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {},
  });
  const editFormik = useFormik({
    initialValues: {
      pickupDate: '',
      pickupTime: '',
      deliveryDate: '',
      deliveryTime: '',
      loadStatus: 'Pending',
      loadDispatcher: null,
      pickupAddress: '',
      pickupCountry: null,
      pickupState: null,
      pickupCity: null,
      pickupZipCode: null,
      deliveryCountry: null,
      deliveryAddress: '',
      deliveryState: null,
      deliveryCity: null,
      deliveryZipCode: null,
      customerId: null,
      postOffice: '',
      driverId: null,
      vehicleId: null,
      trailerId: null,
      loadDescription: '',
      stop: [],
    },
    validationSchema: editLoadSchema,
    onSubmit: (
      {
        pickupDate,
        deliveryDate,
        loadStatus,
        pickupTime,
        deliveryTime,
        loadDispatcher,
        pickupCountry,
        pickupState,
        pickupCity,
        pickupZipCode,
        deliveryCountry,
        deliveryState,
        deliveryCity,
        deliveryZipCode,
        customerId,
        postOffice,
        driverId,
        vehicleId,
        trailerId,
        loadDescription,
        pickupAddress,
        deliveryAddress,
      },
      { resetForm }
    ) => {
      const pickupDateTime = `${dayjs(pickupDate).format(
        DATE_FORMATS.DATE_ONLY
      )} ${dayjs(pickupTime).format(DATE_FORMATS.TIME_ONLY)}`;
      const deliveryDateTime = `${dayjs(deliveryDate).format(
        DATE_FORMATS.DATE_ONLY
      )} ${dayjs(deliveryTime).format(DATE_FORMATS.TIME_ONLY)}`;
      const Stops = innerFormik?.values?.stop?.map((stop, index) => ({
        pointType: stop.stopType,
        location: stop.stopAddress,
        latitude: 35.3733,
        longitude: -119.0187,
        country: 'pakistan',
        city: 'Lahore',
        state: 'punjab',
        zipCode: '43438',
        sequence: index + 2,
        scheduledDate: `${dayjs(stop.stopDate).format('YYYY-MM-DD')}T${dayjs(
          stop.stopTime
        ).format('HH:mm:ssZ')}`,
      }));
      const payload = {
        customerId: customerId,
        po: postOffice,
        dispatcherId: loadDispatcher,
        loadDescription: loadDescription,
        loadStatus: `${loadStatus}`,
        loadDate: dayjs().format(DATE_FORMATS.PAYLOAD_FORMAT),
        totalWeight: 1400,
        loadPoints: [
          {
            pointType: 'Pickup',
            location: pickupAddress,
            latitude: 34.0522,
            longitude: -118.2437,
            country: `${formDropdownData?.pickupCountry}`,
            city: `${formDropdownData?.pickupCity}`,
            state: `${formDropdownData?.pickupState}`,
            zipCode: `${formDropdownData?.pickupZipCode}`,
            sequence: 1,
            scheduledDate: dayjs(pickupDateTime).format(
              DATE_FORMATS.PAYLOAD_DATE_TIME_FORMAT
            ),
          },
          ...Stops,
          {
            pointType: 'Delivery',
            location: deliveryAddress,
            latitude: 35.3733,
            longitude: -119.0187,
            country: `${formDropdownData?.deliveryCountry}`,
            city: `${formDropdownData?.deliveryCity}`,
            state: `${formDropdownData?.deliveryState}`,
            zipCode: `${formDropdownData?.deliveryZipCode}`,
            sequence: Stops.length + 2,
            scheduledDate: dayjs(deliveryDateTime).format(
              DATE_FORMATS.PAYLOAD_DATE_TIME_FORMAT
            ),
          },
        ],
        loadAssignments: [
          {
            driverId: driverId,
            vehicleId: vehicleId,
            trailerId: trailerId,
            assignmentType: 'load',
          },
        ],
      };
      if (Object.keys(innerFormik?.errors).length === 0) {
        putRequest(`/api/UpdateLoad/${editRecordId}`, payload)
          .then((res) => {
            if (res?.success) {
              queryClient.invalidateQueries({
                queryKey: [GET_LOADS_KEY],
              });
              queryClient.invalidateQueries({
                queryKey: [GET_FLEET_VIEW_KEY],
              });
              setDrawerOpen((prev) => !prev);
              ToastNotification('success', res?.message, themeMode);
            } else {
              ToastNotification('error', res.message, themeMode);
            }
          })
          .catch((err) =>
            ToastNotification('error', 'Something went wrong', themeMode)
          )
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  const { data: driverData, isLoading: driverLoading } = useGetRequest(
    '/api/GetdriversDropdown',
    {},
    GET_DRIVERS_DROPDOWN_KEY
  );
  const { data: vehicleData, isLoading: vehicleLoading } = useGetRequest(
    '/api/GetVehiclesDropdown',
    {},
    GET_VEHICLES_DROPDOWN_KEY
  );
  const { data: trailerData, isLoading: trailerLoading } = useGetRequest(
    '/api/GetTrailerDropdown',
    {},
    GET_TRAILERS_DROPDOWN_KEY
  );
  const { data: customerData, isLoading: customerLoading } = useGetRequest(
    '/api/GetCustomerDropdown',
    {},
    GET_CUSTOMERS_DROPDOWN_KEY
  );
  const customerOptions = createOptions(customerData?.data, 'name', 'id');
  const driversOptions = createOptions(driverData?.data, 'name', 'id');
  const vehicleOptions = createOptions(vehicleData?.data, 'number', 'id');
  const trailerOptions = createOptions(
    trailerData?.data,
    'trailerNumber',
    'id'
  );

  const { data: pickupCountryData, isLoading: pickupCountryLoading } =
    useGetRequest2(get_country_by_dropdown, {}, GET_COUNTRIES_DROPDOWN_KEY);
  const {
    data: pickupStateData,
    isLoading: pickupStateLoading,
    refetch: pickupStatesRefetch,
  } = useGetRequest2(
    `/api/StateDropdownByCountryName?country=${formik.values.pickupCountry}`,
    {
      enabled: false,
    }
  );
  const {
    data: pickupCityData,
    isLoading: pickupCityLoading,
    refetch: pickupCitiesRefetch,
  } = useGetRequest2(
    `api/CitiesDropdownByStateName?state=${formik.values.pickupState}`,
    {
      enabled: false,
    }
  );
  const {
    data: pickupZipCodeData,
    isLoading: pickupZipCodeLoading,
    refetch: pickupZipCodeRefetch,
  } = useGetRequest2(
    `/api/ZipCodesDropdownByCityName?city=${formik.values.pickupCity}`,
    {
      enabled: false,
    }
  );

  const { data: deliveryCountryData, isLoading: deliveryCountryLoading } =
    useGetRequest2(get_country_by_dropdown, {}, GET_COUNTRIES_DROPDOWN_KEY);
  const {
    data: deliveryStateData,
    isLoading: deliveryStateLoading,
    refetch: deliveryStatesRefetch,
  } = useGetRequest2(
    `/api/StateDropdownByCountryName?country=${formik.values.deliveryCountry}`,
    {
      enabled: false,
    }
  );
  const {
    data: deliveryCityData,
    isLoading: deliveryCityLoading,
    refetch: deliveryCitiesRefetch,
  } = useGetRequest2(
    `api/CitiesDropdownByStateName?state=${formik.values.deliveryState}`,
    {
      enabled: false,
    }
  );
  // api/GetDispatcherDropdown
  const {
    data: deliveryZipCodeData,
    isLoading: deliveryZipCodeLoading,
    refetch: deliveryZipCodeRefetch,
  } = useGetRequest2(
    `/api/ZipCodesDropdownByCityName?city=${formik.values.deliveryCity}`,
    {
      enabled: false,
    }
  );

  const pickupCountryOptions = createOptions(
    pickupCountryData?.data,
    'name',
    'name'
  );
  const pickupStateOptions = createOptions(
    pickupStateData?.data,
    'name',
    'name'
  );
  const pickupCityOptions = createOptions(pickupCityData?.data, 'name', 'name');
  const PickupZipCodeOptions = createOptions(
    pickupZipCodeData?.data,
    'code',
    'code'
  );
  const deliveryCountryOptions = createOptions(
    deliveryCountryData?.data,
    'name',
    'name'
  );
  const deliveryStateOptions = createOptions(
    deliveryStateData?.data,
    'name',
    'name'
  );
  const deliveryCityOptions = createOptions(
    deliveryCityData?.data,
    'name',
    'name'
  );
  const deliveryZipCodeOptions = createOptions(
    deliveryZipCodeData?.data,
    'code',
    'code'
  );
  const dispatcherOptions = createOptions(dispatcherData?.data, 'name', 'id');

  const delHandler = (record) => {
    deleteRequest(`/api/DeleteLoad?id=${record?.loadId}`)
      .then((res) => {
        if (res?.success) {
          const newTotalRecord = totalRecord - 1; // Decrement the total record count
          setTotalRecord(newTotalRecord); // Update the state
          queryClient.invalidateQueries({
            queryKey: [GET_LOADS_KEY],
          });
          // Check if we need to change the page number
          if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1); // Redirect to the previous page
          }
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };

  const loadColumns = [
    {
      title: 'Dispatcher',
      dataIndex: 'dispatcherName',
      render: (text, record) => (
        <>
          <span className=" whitespace-nowrap">{text}</span>
        </>
      ),
    },
    {
      title: 'Customer',
      dataIndex: 'customerName',
      render: (text, record) => (
        <>
          <span className=" whitespace-nowrap">{text}</span>
        </>
      ),
    },
    {
      title: 'Driver',
      dataIndex: 'driverName',
      render: (text, record) => (
        <>
          <span className=" whitespace-nowrap">{`
            ${record?.driverName}
          `}</span>
        </>
      ),
    },
    {
      title: 'Vehicle',
      dataIndex: 'vehicleNumber',
      render: (text, record) => (
        <>
          <span className=" whitespace-nowrap">{`
            ${record?.vehicleNumber}
          `}</span>
        </>
      ),
    },
    {
      title: 'Pickup Date',
      dataIndex: 'scheduledDate',
      render: (text, record) => (
        <>
          <span className=" whitespace-nowrap">{`
             ${dayjsFormat(
               record?.loadPoints[0]?.scheduledDate,
               DATE_FORMATS.DATE_TIME
             )}
          `}</span>
        </>
      ),
    },
    {
      title: <span className=" whitespace-nowrap">Pickup</span>,
      dataIndex: 'location',
      className: 'max-w-[100px]',
      ellipsis: true,
      render: (text, record) => (
        <span className="">
          <PopOver
            content={record?.loadPoints[0]?.location}
            title={'Loaction'}
            width={'40%'}
          >
            <div className="truncate">{record?.loadPoints[0]?.location}</div>
          </PopOver>
        </span>
      ),
    },
    {
      title: 'Delivery Date',
      dataIndex: 'scheduledDate',
      render: (text, record) => (
        <>
          <span className=" whitespace-nowrap">{`
            ${dayjsFormat(
              record?.loadPoints[1]?.scheduledDate,
              DATE_FORMATS.DATE_TIME
            )}
          `}</span>
        </>
      ),
    },
    {
      title: <span className=" whitespace-nowrap">Delivery</span>,
      dataIndex: 'deliveryCountry',
      className: 'max-w-[100px]',
      ellipsis: true,
      render: (text, record) => (
        <span className="">
          <PopOver
            content={record?.loadPoints[1]?.location}
            title={'Loaction'}
            width={'40%'}
          >
            <div className="truncate">{record?.loadPoints[1]?.location}</div>
          </PopOver>
        </span>
      ),
    },

    // { title: 'Description', dataIndex: 'loadDescription' },
    {
      title: <span className=" flex items-center justify-center">Status</span>,
      dataIndex: 'loadStatus',
      render: (tag, record) => {
        return (
          <span className="flex items-center justify-center">
            <Status tag={record?.loadStatus} />
          </span>
        );
      },
    },
    {
      title: <span className=" flex items-center justify-center">Action</span>,
      dataIndex: '',
      ellipsis: true,
      width: 100,
      render: (text, record) => (
        <div className="flex justify-center">
          <Dropdown
            overlayClassName={`${
              themeMode === 'light'
                ? 'action-dropdown-light'
                : 'action-dropdown-dark'
            }`}
            items={[
              {
                key: '1',
                label: (
                  <div
                    className="p-[2px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                ),
                onClick: () => {
                  setEditRecordId(record?.loadId);
                  setDrawerOpen(true);
                },
              },

              {
                key: '2',
                label: (
                  <div
                    className="p-[2px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this task?"
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={
                        <div className="flex items-center justify-center">
                          <QuestionCircleOutlined
                            style={{
                              color: 'red',
                              marginTop: '5px',
                            }}
                          />
                        </div>
                      }
                    >
                      <MdDeleteOutline className="w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                ),
              },
            ]}
          >
            <div className="!underline cursor-pointer hover:underline">
              <BsThreeDotsVertical className="w-[15px] h-[15px] cursor-pointer" />
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];
  const {
    data: loadByIdData,
    isLoading: loadByIdDataLoading,
    refetch: loadByIdRefetch,
  } = useGetRequest(
    `/api/GetLoadByIdAsync?id=${editRecordId}`,
    { enabled: false },
    GET_LOADBYIDASYNC_KEY
  );

  useEffect(() => {
    if (formik.values.pickupCountry) pickupStatesRefetch();
  }, [formik.values.pickupCountry]);
  useEffect(() => {
    if (formik.values.pickupState) pickupCitiesRefetch();
  }, [formik.values.pickupState]);
  useEffect(() => {
    if (formik.values.pickupCity) pickupZipCodeRefetch();
  }, [formik.values.pickupCity]);

  useEffect(() => {
    if (formik.values.deliveryCountry) deliveryStatesRefetch();
  }, [formik.values.deliveryCountry]);
  useEffect(() => {
    if (formik.values.deliveryState) deliveryCitiesRefetch();
  }, [formik.values.deliveryState]);
  useEffect(() => {
    if (formik.values.deliveryCity) deliveryZipCodeRefetch();
  }, [formik.values.deliveryCity]);
  useEffect(() => {
    if (editRecordId) loadByIdRefetch();
  }, [editRecordId]);

  useEffect(() => {
    if (loadByIdData) {
      const loadIdData = loadByIdData?.data;
      editFormik.setValues({
        pickupDate: dayjs(loadIdData?.loadPoints[0]?.scheduledDate),
        pickupTime: dayjs(loadIdData?.loadPoints[0]?.scheduledDate),
        deliveryDate: dayjs(loadIdData?.loadPoints[1]?.scheduledDate),
        deliveryTime: dayjs(loadIdData?.loadPoints[1]?.scheduledDate),
        loadStatus: loadIdData?.loadStatus,
        loadDispatcher: loadIdData?.dispatcherId,
        pickupAddress: loadIdData?.loadPoints[0]?.location,
        pickupCountry: loadIdData?.loadPoints[0]?.country,
        pickupState: loadIdData?.loadPoints[0]?.state,
        pickupCity: loadIdData?.loadPoints[0]?.city,
        pickupZipCode: loadIdData?.loadPoints[0]?.zipCode,
        deliveryAddress: loadIdData?.loadPoints[1]?.location,
        deliveryCountry: loadIdData?.loadPoints[1]?.country,
        deliveryState: loadIdData?.loadPoints[1]?.state,
        deliveryCity: loadIdData?.loadPoints[1]?.city,
        deliveryZipCode: loadIdData?.loadPoints[1]?.zipCode,
        customerId: loadIdData?.customerId,
        postOffice: loadIdData?.postOffice,
        driverId: loadIdData?.loadAssignments?.[0]?.driverId,
        vehicleId: loadIdData?.loadAssignments?.[0]?.vehicleId,
        trailerId: loadIdData?.loadAssignments?.[0]?.trailerId,
        loadDescription: loadIdData?.loadDescription,
      });
      const filterAndTransformLoadPoints = (points) =>
        points.length <= 2
          ? []
          : points.slice(1, -1).map((point) => ({
              stopDate: dayjs(point.scheduledDate),
              stopTime: dayjs(point.scheduledDate),
              stopAddress: point.location,
              stopType: point.pointType,
            }));
      innerFormik.setValues({
        ...innerFormik.values,
        stop: filterAndTransformLoadPoints(loadByIdData?.data?.loadPoints),
      });
    }
  }, [loadByIdData]);

  const mapContainerStyle = {
    width: '100%',
    height: '500px',
  };

  const defaultCenter = {
    lat: 22.54992,
    lng: 0,
  };

  const [map, setMap] = useState(null);
  const [pickupAutocomplete, setPickupAutocomplete] = useState(null);
  const [deliveryAutocomplete, setDeliveryAutocomplete] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: API_KEY,
    libraries: ['places'],
  });

  const handleLoad = (mapInstance) => {
    setMap(mapInstance);
  };

  const handlePickupAutocompleteLoad = (autocompleteInstance) => {
    setPickupAutocomplete(autocompleteInstance);
  };
  const handleDeliveryAutocompleteLoad = (autocompleteInstance) => {
    setDeliveryAutocomplete(autocompleteInstance);
  };
  const handlePickupPlaceSelect = (formik) => {
    if (!pickupAutocomplete) return;

    const place = pickupAutocomplete.getPlace();
    if (place.geometry) {
      setSelectedPlace(place);
      const location = place.geometry.location;

      // Update map and marker
      setMarkerPosition({ lat: location.lat(), lng: location.lng() });
      formik.setFieldValue('pickupAddress', place?.formatted_address);

      // if (place.geometry.viewport) {
      //   map.fitBounds(place.geometry.viewport);
      // } else {
      //   map.panTo(location);
      //   map.setZoom(15);
      // }
    }
  };
  const handleDeliveryPlaceSelect = (formik) => {
    if (!deliveryAutocomplete) return;

    const place = deliveryAutocomplete.getPlace();
    if (place.geometry) {
      setSelectedPlace(place);
      const location = place.geometry.location;

      // Update map and marker
      setMarkerPosition({ lat: location.lat(), lng: location.lng() });
      formik.setFieldValue('deliveryAddress', place?.formatted_address);

      // if (map) {
      //   if (place.geometry.viewport) {
      //     map.fitBounds(place.geometry.viewport);
      //   } else {
      //     map.panTo(location);
      //     map.setZoom(15);
      //   }
      // }
    }
  };
  function extractComponentsFromPlace(address) {
    if (!address || typeof address !== 'string') {
      console.error('Invalid address format');
      return { city: null, state: null, country: null };
    }

    const components = address.split(',').map((item) => item.trim());

    return {
      city: components[1] || null,
      state: components[2] || null,
      country: components[3] || null,
    };
  }
  const { city, state, country } = extractComponentsFromPlace(
    formik.values?.pickupAddress
  );

  return (
    <Layout>
      <>
        <div className="mb-[10px] ">
          <Title
            level={4}
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
              fontFamily: 'inter-bold',
            }}
          >
            Load Management
          </Title>
          <div className="flex justify-between items-center mb-0">
            <div>
              <Breadcrumb
                separator="/"
                items={[
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <GoHome />
                          <span
                            className=" cursor-pointer font-reg text-[13px]"
                            onClick={() => {
                              navigate('/dashboard');
                            }}
                          >
                            Dashboard
                          </span>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <FaTruckLoading />
                          <span className=" cursor-pointer font-bold text-[13px]">
                            Load Management
                          </span>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
            </div>
            <div>
              <PrimaryButton
                title="Add Load"
                onClick={() => {
                  setIsLoadOpen((prev) => !prev);
                }}
                icon={<FaPlus size={14} />}
                htmlType="submit"
              />
            </div>
          </div>
        </div>
        <div className="">
          <div className={`transition-div ${isLoadOpen ? 'open' : 'closed'}`}>
            <div className={`flex gap-20 place-items-start`}>
              <div className="grid flex-1">
                <Title
                  style={{
                    color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                    fontFamily: 'inter-bold',
                    marginBottom: '0px',
                    fontSize: '12px',
                  }}
                >
                  New Load
                </Title>
                <div>
                  <SelectField
                    formik={formik}
                    id={'loadStatus'}
                    label={'Status'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={[
                      { value: 'Pending', label: 'Pending' },
                      { value: 'In-Transit', label: 'In-Transit' },
                    ]}
                    notModal={true}
                  />
                </div>
                <div>
                  <SelectField
                    formik={formik}
                    id={'loadDispatcher'}
                    label={'Dispatcher'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={dispatcherOptions}
                    isLoading={dispatcherLoading}
                    notModal={true}
                  />
                </div>
              </div>
              <div className="grid flex-1">
                <Title
                  style={{
                    color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                    fontFamily: 'inter-bold',
                    marginBottom: '0px',
                    fontSize: '12px',
                  }}
                >
                  <div className="flex gap-3">
                    <BiUpArrowAlt size={14} />
                    Pickup
                  </div>
                </Title>
                <div className="flex gap-2">
                  <div className="flex-1">
                    <DatePickerField
                      formik={formik}
                      id={'pickupDate'}
                      label={'Date'}
                      minDate={dayjs()}
                      notModal={true}
                    />
                  </div>
                  <div className="flex-1">
                    <TimePickerField
                      formik={formik}
                      id={'pickupTime'}
                      label={'Time'}
                      minDate={dayjs()}
                      notModal={true}
                    />
                  </div>
                </div>
                <div className="flex-1">
                  {isLoaded ? (
                    <div className="wrapper-fields flex pr-[22px]">
                      <div className="w-full">
                        <InputFieldCustomForm
                          label="Address"
                          name="pickupAddress"
                          notModal={true}
                          required={true}
                          value={formik.values.pickupAddress || ''}
                          error={
                            formik.touched.pickupAddress &&
                            Boolean(formik.errors.pickupAddress)
                          }
                          errorMessage={formik.errors.pickupAddress}
                        >
                          <Autocomplete
                            className="w-full"
                            onLoad={handlePickupAutocompleteLoad}
                            onPlaceChanged={() =>
                              handlePickupPlaceSelect(formik)
                            }
                          >
                            <Input
                              type="text"
                              id="pickupAddress"
                              placeholder=""
                              value={formik.values.pickupAddress}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'pickupAddress',
                                  e.target.value
                                );
                              }}
                              style={{
                                border: !(
                                  formik.touched.pickupAddress &&
                                  Boolean(formik.errors.pickupAddress)
                                )
                                  ? themeMode === 'light'
                                    ? `1px solid ${themeInputColor.lightBorderColor}`
                                    : `1px solid ${themeInputColor.darkBorderColor}`
                                  : `1px solid ${themeInputColor.errorBorderColor}`,
                                borderRadius: '5px',
                                caretColor:
                                  themeMode === 'light'
                                    ? themeInputColor.lightCaretColor
                                    : themeInputColor.darkCaretColor,
                                color:
                                  themeMode === 'light'
                                    ? themeInputColor.lightTextColor
                                    : themeInputColor.darkTextColor,
                              }}
                            />
                          </Autocomplete>
                        </InputFieldCustomForm>
                        {/* {formik.errors.pickupAddress && (
                            <div className="text-error text-[10px] absolute">
                              {formik.errors.pickupAddress}
                            </div>
                          )} */}
                      </div>
                    </div>
                  ) : (
                    <TextField
                      formik={formik}
                      id={'pickupAddress'}
                      label={'Address'}
                      notModal={true}
                    />
                  )}
                  {/* <TextField
                      formik={formik}
                      id={'pickupAddress'}
                      label={'Address'}
                      notModal={true}
                    /> */}
                </div>
                <div className="flex gap-2">
                  <div className="flex-1">
                    <SelectField
                      formik={formik}
                      id={'pickupCountry'}
                      label={'Country'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={pickupCountryOptions}
                      isLoading={pickupCountryLoading}
                      notModal={true}
                      onChange={(newValue, _) => {
                        formik.setFieldValue('pickupCountry', newValue);
                        formik.setFieldValue('pickupState', null);
                        formik.setFieldValue('pickupCity', null);
                        formik.setFieldValue('pickupZipCode', null);
                        setFormDropdownData({
                          ...formDropdownData,
                          pickupCountry: _?.label,
                        });
                      }}
                    />
                  </div>
                  <div className="flex-1">
                    <SelectField
                      formik={formik}
                      id={'pickupState'}
                      label={'State'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={pickupStateOptions}
                      isLoading={pickupStateLoading}
                      notModal={true}
                      onChange={(newValue, _) => {
                        formik.setFieldValue('pickupState', newValue);
                        formik.setFieldValue('pickupCity', null);
                        formik.setFieldValue('pickupZipCode', null);
                        setFormDropdownData({
                          ...formDropdownData,
                          pickupState: _?.label,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="flex gap-2">
                  <div className=" flex-1">
                    <SelectField
                      formik={formik}
                      id={'pickupCity'}
                      label={'City'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={pickupCityOptions}
                      isLoading={pickupCityLoading}
                      notModal={true}
                      onChange={(newValue, _) => {
                        formik.setFieldValue('pickupCity', newValue);
                        formik.setFieldValue('pickupZipCode', null);
                        setFormDropdownData({
                          ...formDropdownData,
                          pickupCity: _?.label,
                        });
                      }}
                    />
                  </div>
                  <div className=" flex-1">
                    <SelectField
                      formik={formik}
                      id={'pickupZipCode'}
                      label={'Zip Code'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={PickupZipCodeOptions}
                      isLoading={pickupZipCodeLoading}
                      notModal={true}
                      onChange={(newValue, _) => {
                        formik.setFieldValue('pickupZipCode', newValue);
                        setFormDropdownData({
                          ...formDropdownData,
                          pickupZipCode: _?.label,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="grid flex-1">
                <Title
                  style={{
                    color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                    fontFamily: 'inter-bold',
                    marginBottom: '0px',
                    fontSize: '12px',
                  }}
                >
                  <div className="flex gap-3">
                    <BiDownArrowAlt size={14} />
                    Delivery
                  </div>
                </Title>
                <div className="flex gap-2">
                  <div className="flex-1">
                    <DatePickerField
                      formik={formik}
                      id={'deliveryDate'}
                      label={'Date'}
                      notModal={true}
                    />
                  </div>
                  <div className="flex-1">
                    <TimePickerField
                      formik={formik}
                      id={'deliveryTime'}
                      label={'Time'}
                      notModal={true}
                    />
                  </div>
                </div>
                <div>
                  {isLoaded ? (
                    <div className="wrapper-fields flex pr-[22px]">
                      <div className="w-full">
                        <InputFieldCustomForm
                          label="Address"
                          name="deliveryAddress"
                          notModal={true}
                          required={true}
                          value={formik.values.deliveryAddress || ''}
                          error={
                            formik.touched.deliveryAddress &&
                            Boolean(formik.errors.deliveryAddress)
                          }
                          errorMessage={formik.errors.deliveryAddress}
                        >
                          <Autocomplete
                            className="w-full"
                            onLoad={handleDeliveryAutocompleteLoad}
                            onPlaceChanged={() =>
                              handleDeliveryPlaceSelect(formik)
                            }
                          >
                            <Input
                              type="text"
                              id="deliveryAddress"
                              placeholder=""
                              value={formik.values.deliveryAddress}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'deliveryAddress',
                                  e.target.value
                                );
                              }}
                              style={{
                                border: !(
                                  formik.touched.deliveryAddress &&
                                  Boolean(formik.errors.deliveryAddress)
                                )
                                  ? themeMode === 'light'
                                    ? `1px solid ${themeInputColor.lightBorderColor}`
                                    : `1px solid ${themeInputColor.darkBorderColor}`
                                  : `1px solid ${themeInputColor.errorBorderColor}`,
                                borderRadius: '5px',
                                caretColor:
                                  themeMode === 'light'
                                    ? themeInputColor.lightCaretColor
                                    : themeInputColor.darkCaretColor,
                                color:
                                  themeMode === 'light'
                                    ? themeInputColor.lightTextColor
                                    : themeInputColor.darkTextColor,
                              }}
                            />
                          </Autocomplete>
                        </InputFieldCustomForm>
                        {/* {formik.errors.deliveryAddress && (
                            <div className="text-error text-[10px] absolute">
                              {formik.errors.deliveryAddress}
                            </div>
                          )} */}
                      </div>
                    </div>
                  ) : (
                    <TextField
                      formik={formik}
                      id={'deliveryAddress'}
                      label={'Address'}
                      notModal={true}
                    />
                  )}
                </div>
                <div className="flex gap-2">
                  <div className="flex-1">
                    <SelectField
                      formik={formik}
                      id={'deliveryCountry'}
                      label={'Country'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={deliveryCountryOptions}
                      isLoading={deliveryCountryLoading}
                      notModal={true}
                      onChange={(newValue, _) => {
                        formik.setFieldValue('deliveryCountry', newValue);
                        formik.setFieldValue('deliveryState', null);
                        formik.setFieldValue('deliveryCity', null);
                        formik.setFieldValue('deliveryZipCode', null);
                        setFormDropdownData({
                          ...formDropdownData,
                          deliveryCountry: _?.label,
                        });
                      }}
                    />
                  </div>
                  <div className="flex-1">
                    <SelectField
                      formik={formik}
                      id={'deliveryState'}
                      label={'State'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={deliveryStateOptions}
                      isLoading={deliveryStateLoading}
                      notModal={true}
                      onChange={(newValue, _) => {
                        formik.setFieldValue('deliveryState', newValue);
                        formik.setFieldValue('deliveryCity', null);
                        formik.setFieldValue('deliveryZipCode', null);
                        setFormDropdownData({
                          ...formDropdownData,
                          deliveryState: _?.label,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="flex gap-2">
                  <div className=" flex-1">
                    <SelectField
                      formik={formik}
                      id={'deliveryCity'}
                      label={'City'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={deliveryCityOptions}
                      isLoading={deliveryCityLoading}
                      notModal={true}
                      onChange={(newValue, _) => {
                        formik.setFieldValue('deliveryCity', newValue);
                        formik.setFieldValue('deliveryZipCode', null);
                        setFormDropdownData({
                          ...formDropdownData,
                          deliveryCity: _?.label,
                        });
                      }}
                    />
                  </div>
                  <div className=" flex-1">
                    <SelectField
                      formik={formik}
                      id={'deliveryZipCode'}
                      label={'Zip Code'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={deliveryZipCodeOptions}
                      isLoading={deliveryZipCodeLoading}
                      notModal={true}
                      onChange={(newValue, _) => {
                        formik.setFieldValue('deliveryZipCode', newValue);
                        setFormDropdownData({
                          ...formDropdownData,
                          deliveryZipCode: _?.label,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="grid flex-1">
                <Title
                  style={{
                    color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                    fontFamily: 'inter-bold',
                    marginBottom: '0px',
                    fontSize: '12px',
                  }}
                >
                  <div className="flex gap-3">
                    <PiUsersFourLight size={14} />
                    Customer
                  </div>
                </Title>
                <div className=" ">
                  <SelectField
                    formik={formik}
                    id={'customerId'}
                    label={'Customer'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={customerOptions}
                    isLoading={customerLoading}
                    notModal={true}
                  />
                </div>
                <div>
                  <TextField
                    formik={formik}
                    id={'postOffice'}
                    label={'PO'}
                    maxLength={30}
                    notModal={true}
                  />
                </div>
              </div>
              <div className="grid flex-1">
                <Title
                  style={{
                    color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                    fontFamily: 'inter-bold',
                    marginBottom: '0px',
                    fontSize: '12px',
                  }}
                >
                  <div className="flex gap-3">
                    <PiSteeringWheel size={14} />
                    Driver
                  </div>
                </Title>
                <div className=" ">
                  <SelectField
                    formik={formik}
                    id={'driverId'}
                    label={'Driver'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={driversOptions}
                    isLoading={driverLoading}
                    notModal={true}
                  />
                </div>
                <div className=" ">
                  <SelectField
                    formik={formik}
                    id={'vehicleId'}
                    label={'Vehicle'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={vehicleOptions}
                    isLoading={vehicleLoading}
                    notModal={true}
                  />
                </div>
                <div className=" ">
                  <SelectField
                    formik={formik}
                    id={'trailerId'}
                    label={'Trailer'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={trailerOptions}
                    isLoading={trailerLoading}
                    notModal={true}
                  />
                </div>
              </div>
            </div>
            <div className="grid flex-1">
              <Title
                style={{
                  color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                  fontFamily: 'inter-bold',
                  marginBottom: '0px',
                  fontSize: '12px',
                }}
              >
                <div className="flex gap-3 items-center">
                  <FaRegNoteSticky size={12} />
                  Notes
                </div>
              </Title>
              <div className=" flex justify-between items-end">
                <div className="grid grid-cols-12 w-full">
                  <div className="wrapper-fields col-span-6">
                    <TextArea
                      id="loadDescription"
                      name="loadDescription"
                      rows={2}
                      placeholder="Description"
                      required={true}
                      value={formik.values.loadDescription}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.loadDescription &&
                        Boolean(formik.errors.loadDescription)
                      }
                      errorMessage={
                        formik.touched.loadDescription &&
                        formik.errors.loadDescription
                          ? formik.errors.loadDescription
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="flex gap-5">
                  <SecondaryButton
                    title="Cancel"
                    onClick={() => {
                      setIsLoadOpen((prev) => !prev);
                      formik.resetForm();
                    }}
                    icon={<MdCancel size={17} />}
                    className="w-full text-[13px]"
                  />
                  <PrimaryButton
                    title="Save"
                    icon={<BiSave className=" w-[17px] h-[17px]" />}
                    htmlType="submit"
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
          <LabelGrid label="Load Details" className=" rounded-[5px] px-10 py-5">
            <Spin spinning={loadLoading}>
              <Table
                data={LoadsData?.data || []}
                columns={loadColumns}
                pageNumber={pageNumber}
                setTotalRecord={setTotalRecord}
                rowsPerPage={rowsPerPage}
                totalRecord={totalRecord}
                setPageNumber={setPageNumber}
                setRowsPerPage={setRowsPerPage}
                setSortOrder={setSortOrder}
                setFilters={setFilters}
                loading={false}
              />
            </Spin>
          </LabelGrid>
          {isLoaded ? (
            <GoogleMap
              // mapContainerStyle={mapContainerStyle}
              center={defaultCenter}
              zoom={3}
              onLoad={handleLoad}
              options={{
                disableDefaultUI: true,
                gestureHandling: 'greedy',
              }}
            >
              {markerPosition && <Marker position={markerPosition} />}
            </GoogleMap>
          ) : (
            <div>Loading...</div>
          )}
          <CustomDrawer
            open={isDrawerOpen}
            setOpen={setDrawerOpen}
            title="Edit Load"
            placement="right"
            width={1200}
            children={
              <EditLoad
                loading={loadByIdDataLoading}
                formik={editFormik}
                innerFormik={innerFormik}
              />
            }
            extra={
              <div className=" flex items-center space-x-10">
                <SecondaryButton
                  title="Cancel"
                  icon={<MdCancel size={17} />}
                  className="w-full text-[13px]"
                  onClick={() => {
                    setDrawerOpen((prev) => !prev);
                  }}
                />
                <PrimaryButton
                  title="Save"
                  icon={<BiSave className=" w-[17px] h-[17px]" />}
                  htmlType="submit"
                  onClick={() => {
                    editFormik.handleSubmit();
                    innerFormik.handleSubmit();
                  }}
                />
              </div>
            }
          />
        </div>
      </>
    </Layout>
  );
};

export default Load;
