import { Button, Modal, Select } from 'antd';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
import Checkbox from 'components/InputFields/Checkbox';
import ToastNotification from 'components/ToastNotification';
import { get_country_by_dropdown, update_state } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import useValidationSchema from 'validation/useValidationSchema';

const EditStateModal = ({
  openEditStateModal,
  setOpenEditStateModal,
  selectedRecord,
  refetch,
}) => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { useGetRequest, putRequest } = useApiClient();
  const queryClient = useQueryClient();
  const { data: countryData } = useGetRequest(get_country_by_dropdown, {
    refetchOnMount: 'always',
  });

  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const { stateModalSchema } = useValidationSchema();
  const formik = useFormik({
    initialValues: {
      // stateID: '',
      stateName: '',
      countryId: '',
      isActive: false,
    },
    validationSchema: stateModalSchema,
    onSubmit: ({ stateName, countryId, isActive }) => {
      setLoading(true);
      const paylaod = {
        // stateId: selectedRecord?.stateId,
        // name: stateName,
        // countryId: countryId,
        stateId: selectedRecord?.stateId,
        name: stateName,
        countryId: countryId,
        isActive: isActive,
      };
      putRequest(`${update_state}`, paylaod)
        .then((res) => {
          if (res?.success) {
            refetch();
            setOpenEditStateModal(false);
            formik.resetForm();
            ToastNotification('success', res.message, themeMode);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCancel = () => {
    setOpenEditStateModal(false);
    formik.resetForm();
  };
  useEffect(() => {
    if (selectedRecord) {
      formik.setValues({
        stateName: selectedRecord?.stateName || '',
        countryId: selectedRecord?.countryId || '',
        isActive: selectedRecord?.isActive || '',
      });
    }
  }, [selectedRecord, openEditStateModal]);

  return (
    <>
      <Modal
        open={openEditStateModal}
        title={<span className=" text-[18px]">Edit State</span>}
        width={400}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
            className="!shadow-none"
          >
            Submit
          </Button>,
        ]}
      >
        <>
          <div className=" grid grid-cols-12 gap-x-20 gap-y-5  py-[10px]">
            <div className=" col-span-12">
              <SelectField
                formik={formik}
                id={'countryId'}
                label={'Country'}
                optionData={countryData?.data}
                optionId={'countryId'}
                optionName={'name'}
                onChange={(newValue, _) => {
                  formik.setFieldValue('countryId', newValue);
                  formik.setFieldValue('stateName', null);
                }}
              />
            </div>
            <div className=" col-span-12">
              <TextField
                formik={formik}
                id={'stateName'}
                label={'State Name'}
              />
            </div>
            <div className="col-span-12 mt-[5px]">
              <Checkbox
                title="IsActive"
                id="isActive"
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                error={formik.errors.isActive}
                errorMessage={
                  formik.touched.isActive && formik.errors.isActive
                    ? formik.errors.isActive
                    : null
                }
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default EditStateModal;
