import React, { useEffect, useState } from 'react';
import { Button, Modal, Input } from 'antd';
import { useFormik } from 'formik';
import useValidationSchema from 'validation/useValidationSchema';
import { useSelector } from 'react-redux';
import useApiClient from 'config/useAxiosClient';
import { useQueryClient } from 'react-query';
import { get_email_settings, update_email_setting } from 'config/services';
import Checkbox from 'components/InputFields/Checkbox';
import ToastNotification from 'components/ToastNotification';
import TextField from 'components/CustomFields/TextField';
import PasswordField from 'components/CustomFields/PasswordField';

const EditEmailGatewayModal = ({
  openEditEmailGatewayModal,
  setOpenEditEmailGatewayModal,
  selectedRecord,
}) => {
  const { themeMode } = useSelector((state) => state?.states);
  const { putRequest } = useApiClient();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const { emailGatewayeModalSchema } = useValidationSchema();
  const formik = useFormik({
    initialValues: {
      smtpUserName: '',
      smtpPassword: '',
      senderName: '',
      senderEmail: '',
      smtpServer: '',
      smtpPort: null,
      enableSSL: false,
      isActive: false,
    },
    validationSchema: emailGatewayeModalSchema,
    onSubmit: ({
      smtpServer,
      smtpPort,
      smtpPassword,
      smtpUserName,
      senderEmail,
      senderName,
      enableSSL,
      isActive,
    }) => {
      setLoading(true);
      const paylaod = {
        settingId: selectedRecord?.settingId,
        tenantId: 0,
        smtpServer: smtpServer,
        smtpPort: smtpPort,
        senderEmail: senderEmail,
        senderName: senderName,
        smtpUserName: smtpUserName,
        smtpPassword: smtpPassword,
        enableSSL: enableSSL || false,
        isActive: isActive || false,
      };
      putRequest(update_email_setting, paylaod)
        .then((res) => {
          if (res?.success) {
            setLoading(false);
            setOpenEditEmailGatewayModal(false);
            queryClient.invalidateQueries(get_email_settings);
            formik.resetForm();
            ToastNotification('success', res.message, themeMode);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )

        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCancel = () => {
    setOpenEditEmailGatewayModal(false);
    formik.resetForm();
  };

  useEffect(() => {
    if (selectedRecord) {
      formik.setValues({
        smtpServer: selectedRecord?.smtpServer,
        smtpPort: selectedRecord?.smtpPort,
        senderEmail: selectedRecord?.senderEmail,
        senderName: selectedRecord?.senderName,
        smtpUserName: selectedRecord?.smtpUserName,
        smtpPassword: selectedRecord?.smtpPassword,
        enableSSL: selectedRecord?.enableSSL,
        isActive: selectedRecord?.isActive,
      });
    }
  }, [selectedRecord, openEditEmailGatewayModal]);

  return (
    <>
      <Modal
        open={openEditEmailGatewayModal}
        title={<span className=" text-[18px]">Edit Email Gateway</span>}
        width={600}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <>
          <div className=" grid grid-cols-12 gap-x-20 gap-y-5  py-[10px]">
            <div className="sm:col-span-6 col-span-12">
              <TextField
                formik={formik}
                id={'senderName'}
                label={'Sender Name'}
                maxLength={30}
              />
            </div>
            <div className="sm:col-span-6 col-span-12">
              <TextField
                formik={formik}
                id={'senderEmail'}
                label={'Sender Email'}
              />
            </div>
            <div className="sm:col-span-6 col-span-12">
              <TextField
                formik={formik}
                id={'smtpUserName'}
                label={'SMTP User Name'}
                maxLength={30}
              />
            </div>
            <div className="sm:col-span-6 col-span-12">
              <PasswordField
                formik={formik}
                id={'smtpPassword'}
                label={'SMTP Password'}
              />
            </div>
            <div className="sm:col-span-6 col-span-12">
              <TextField
                formik={formik}
                id={'smtpServer'}
                label={'SMTP Server'}
                maxLength={30}
              />
            </div>
            <div className="sm:col-span-6 col-span-12">
              <TextField
                formik={formik}
                id={'smtpPort'}
                label={'SMTP Port'}
                type="number"
              />
            </div>
            <div className="sm:col-span-3 col-span-6  ml-4">
              <Checkbox
                title="Is Active"
                id="isActive"
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                error={formik.errors.isActive}
                errorMessage={
                  formik.touched.isActive && formik.errors.isActive
                    ? formik.errors.isActive
                    : null
                }
              />
            </div>
            <div className="sm:col-span-3  col-span-6">
              <Checkbox
                title="Is Enable SSL"
                id="enableSSL"
                name="enableSSL"
                checked={formik.values.enableSSL}
                onChange={formik.handleChange}
                error={formik.errors.enableSSL}
                errorMessage={
                  formik.touched.enableSSL && formik.errors.enableSSL
                    ? formik.errors.enableSSL
                    : null
                }
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default EditEmailGatewayModal;
