export const themeInputColor = {
  lightBorderColor: '#d9d9d9',
  lightCaretColor: '#9F9B93',
  lightTextColor: '#000000',
  darkBorderColor: '#353535',
  darkTextColor: '#9F9B93',
  darkCaretColor: '#9F9B93',
  errorBorderColor: '#ff4d4f',
  errorLabelTextColor: '#ff4d4f',
  darkBachgroundColor: '#000000',
  lightBachgroundColor: '#f7f7fa',
  notModalDarkBachgroundColor: '#1f1f1f',
  notModalLightBachgroundColor: '#ffffff',
  darkIconColor: '#353535',
  errorIconColor: '#ff4d4f',
};

export const DocumentsTypeKeyValue = [
  { value: 'drivinglicense', label: 'Driving License' },
  { value: 'medicalcertificate', label: 'Medical Certificate' },
  { value: 'insurance', label: 'Insurance' },
  { value: 'profilepicture', label: 'Profile Picture' },
  { value: 'driverdocuments', label: 'Driver Documents' },
  { value: 'vehicledocuments', label: 'Vehicle Documents' },
  { value: 'vehicleRegister', label: 'Vehicle Register' },
];
export const colorOptions = [
  { value: '#0d0c0cee', label: 'Default' },
  { value: '#a8071a', label: 'Red' },
  { value: '#ad4e00', label: 'Orange' },
  { value: '#237804', label: 'Green' },
  { value: '#006d75', label: 'Cyan' },
  { value: '#003eb3', label: 'Blue' },
  { value: '#391085', label: 'Purple' },
  { value: '#9e1068', label: 'Magenta' },
  { value: '#ad2102', label: 'Volcano' },
];
export const orderStatus = [
  { value: 'Pending', label: 'Pending' },
  { value: 'Active', label: 'Active' },
  { value: 'Completed', label: 'Completed' },
  { value: 'Delayed', label: 'Delayed' },
];

export const trailerStatus = [
  { value: 'Assigned', label: 'Assigned' },
  { value: 'UnAssigned', label: 'UnAssigned' },
];

export const documentTypesOptions = [
  { value: 'drivinglicense', label: 'Driving License' },
  { value: 'medicalcertificate', label: 'Medical Certificate' },
  { value: 'insurance', label: 'Insurance' },
];

export const documentOptions = [
  { value: 'driverdocuments', label: 'Driver Documents' },
  { value: 'vehicledocuments', label: 'Vehicle Documents' },
];

export const DATE_FORMATS = {
  DATE_ONLY: 'MM-DD-YYYY',
  TIME_ONLY: 'HH:mm:ss',
  DATE_TIME: 'MM-DD-YYYY hh:mm A',
  PAYLOAD_FORMAT: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
  PAYLOAD_DATE_TIME_FORMAT: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
  PAYLOAD_DATE_ONLY: 'YYYY-MM-DD',
};

export const BILLING_CYCLE = [
  {
    label: 'Weekly',
    value: 'weekly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Yearly',
    value: 'yearly',
  },
];
export const mapStyles = [
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#BED8F1' }],
  },
  {
    featureType: 'landscape.natural',
    elementType: 'geometry',
    stylers: [{ color: '#F8F4F0' }],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'landscape.natural.terrain',
    elementType: 'geometry',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'administrative.country',
    elementType: 'geometry',
    stylers: [{ visibility: 'on' }, { color: '#A4A2AE' }, { weight: 1.5 }],
  },
  {
    featureType: 'administrative.province',
    elementType: 'geometry',
    stylers: [{ visibility: 'on' }, { color: '#A4A2AE' }, { weight: 1 }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ visibility: 'simplified' }, { color: '#E8E9ED' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ visibility: 'on' }, { color: '#C0C0C0' }],
  },
  // {
  //   featureType: "road.highway",
  //   elementType: "geometry.stroke",
  //   stylers: [{ color: "#FFEEAA" }, { weight: 2 }],
  // },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [{ visibility: 'on' }, { color: '#F5F5F5' }],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [{ visibility: 'on' }, { color: '#F5F5F5s' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ visibility: 'off' }, { color: '#a0d7a4' }],
  },
  {
    featureType: 'poi.sports_complex',
    elementType: 'geometry',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.business',
    elementType: 'geometry',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.attraction',
    elementType: 'geometry',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.medical',
    elementType: 'geometry',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.place_of_worship',
    elementType: 'geometry',
    stylers: [{ visibility: 'off' }],
  },
];

export const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];
export const options = [
  {
    value: '1',
    label: 'Jack',
  },
  {
    value: '2',
    label: 'Lucy',
  },
  {
    value: '3',
    label: 'Tom',
  },
];
export const isActiveOptions = [
  {
    value: 'Active',
    label: 'Active',
  },
  {
    value: 'InActive',
    label: 'In-Active',
  },
];
export const subscriptionPlanOptions = [
  {
    value: 'Active',
    label: 'Active',
  },
  {
    value: 'InActive',
    label: 'In-Active',
  },
  {
    value: 'Suspended',
    label: 'Suspended',
  },
];
export const deviceStatusOptions = [
  {
    value: 'Assigned',
    label: 'Assigned',
  },
  {
    value: 'UnAssigned',
    label: 'Un-Assigned',
  },
  {
    value: 'Suspended',
    label: 'Suspended',
  },
];
export const menuOptions = [
  {
    value: 'dashboard',
    label: 'Dashboard',
  },
  {
    value: 'customers',
    label: 'Customers',
  },
  {
    value: 'timesheet',
    label: 'Time Sheet',
  },
  {
    value: 'security',
    label: 'Security',
  },
  {
    value: 'settings',
    label: 'Settings',
  },
];
export const userdata = [
  {
    key: '1',
    name: 'John Brown',
    description:
      'John is a seasoned software engineer with over 10 years of experience in full-stack development.',
  },
  {
    key: '2',
    name: 'John Brown',
    description:
      'John is an expert in data analysis and machine learning, working primarily with Python and R.',
  },
  {
    key: '3',
    name: 'Joe Black',
    description:
      'Joe is a creative graphic designer with a passion for branding and digital illustration.',
  },
  {
    key: '4',
    name: 'Jim Red',
    description:
      'Jim is a project manager who excels in agile methodologies and team coordination.',
  },
  {
    key: '5',
    name: 'Jane Doe',
    description:
      'Jane is a talented copywriter with a flair for crafting compelling marketing content.',
  },
  {
    key: '6',
    name: 'Michael Smith',
    description:
      'Michael is a cybersecurity specialist focusing on network security and threat mitigation.',
  },
  {
    key: '7',
    name: 'Emily White',
    description:
      'Emily is a UX/UI designer dedicated to creating user-friendly and aesthetically pleasing interfaces.',
  },
  {
    key: '8',
    name: 'Sophia Brown',
    description:
      'Sophia is a software developer with expertise in mobile app development for both iOS and Android.',
  },
  {
    key: '9',
    name: 'Oliver Green',
    description:
      'Oliver is a database administrator skilled in managing and optimizing complex databases.',
  },
  {
    key: '10',
    name: 'Emma Johnson',
    description:
      'Emma is a digital marketer specializing in SEO and content strategy to boost online visibility.',
  },
];

export const initialData = [
  {
    key: '1',
    roleName: 'Admin',
    fullControl: false,
    canSearch: false,
    canView: false,
    canEdit: false,
    canDelete: false,
    canAdd: false,
    canPrint: false,
  },
  {
    key: '2',
    roleName: 'Editor',
    fullControl: false,
    canSearch: false,
    canView: false,
    canEdit: false,
    canDelete: false,
    canAdd: false,
    canPrint: false,
  },
  {
    key: '3',
    roleName: 'Permissions',
    fullControl: false,
    canSearch: false,
    canView: false,
    canEdit: false,
    canDelete: false,
    canAdd: false,
    canPrint: false,
  },
  {
    key: '4',
    roleName: 'Users',
    fullControl: false,
    canSearch: false,
    canView: false,
    canEdit: false,
    canDelete: false,
    canAdd: false,
    canPrint: false,
  },
  {
    key: '5',
    roleName: 'Templates',
    fullControl: false,
    canSearch: false,
    canView: false,
    canEdit: false,
    canDelete: false,
    canAdd: false,
    canPrint: false,
  },
  {
    key: '6',
    roleName: 'Tracking',
    fullControl: false,
    canSearch: false,
    canView: false,
    canEdit: false,
    canDelete: false,
    canAdd: false,
    canPrint: false,
  },
  {
    key: '7',
    roleName: 'Security',
    fullControl: false,
    canSearch: false,
    canView: false,
    canEdit: false,
    canDelete: false,
    canAdd: false,
    canPrint: false,
  },
  {
    key: '8',
    roleName: 'Manager',
    fullControl: false,
    canSearch: false,
    canView: false,
    canEdit: false,
    canDelete: false,
    canAdd: false,
    canPrint: false,
  },

  // Add more roles as needed
];

export const driverData = [
  {
    key: '1',
    name: 'John Doe',
    licenseNumber: '12345',
    licenseExpiry: 'ABC123',
    contactPhone: '+1 123-456-7890',
    email: 'abx@xyz.com',
    createdDate: '2023-01-15',
    isActive: true,
  },
];

export const VehicleData = [
  {
    key: '1',
    name: 'Ferrari',
    licensePlate: 'ASD-123',
    vehicleType: 'Truck',
    isActive: true,
  },
];

export const dummyData = [
  {
    name: 'John Doe',
    assignedVehicle: 'Vehicle 1',
    currentJob: 'Delivery',
    location: 'New York, USA',
    hoursWorked: 40,
    fatigueLevel: 'Low',
    isActive: true,
  },
  {
    name: 'Jane Smith',
    assignedVehicle: 'Vehicle 2',
    currentJob: 'Maintenance',
    location: 'Los Angeles, USA',
    hoursWorked: 35,
    fatigueLevel: 'Moderate',
    isActive: false,
  },
  {
    name: 'Bob Johnson',
    assignedVehicle: 'Vehicle 3',
    currentJob: 'Transport',
    location: 'Chicago, USA',
    hoursWorked: 50,
    fatigueLevel: 'High',
    isActive: true,
  },
  {
    name: 'Alice Williams',
    assignedVehicle: 'Vehicle 4',
    currentJob: 'Logistics',
    location: 'Houston, USA',
    hoursWorked: 42,
    fatigueLevel: 'Low',
    isActive: true,
  },
  {
    name: 'Tom Brown',
    assignedVehicle: 'Vehicle 5',
    currentJob: 'Dispatch',
    location: 'Miami, USA',
    hoursWorked: 38,
    fatigueLevel: 'Moderate',
    isActive: false,
  },
];

export const jobsData = [
  {
    name: 'John Doe',
    assignedVehicle: 'Vehicle 1',
    assignedDriver: 'Driver A',
    deliveryTime: '10:30 AM',
    pickupLocation: '123 Main St, Springfield',
    deliveryLocation: '456 Elm St, Springfield',
    isActive: true,
  },
  {
    name: 'Jane Smith',
    assignedVehicle: 'Vehicle 2',
    assignedDriver: 'Driver B',
    deliveryTime: '1:15 PM',
    pickupLocation: '789 Oak St, Springfield',
    deliveryLocation: '321 Pine St, Springfield',
    isActive: false,
  },
  {
    name: 'Alice Johnson',
    assignedVehicle: 'Vehicle 3',
    assignedDriver: 'Driver C',
    deliveryTime: '2:00 PM',
    pickupLocation: '654 Cedar St, Springfield',
    deliveryLocation: '987 Birch St, Springfield',
    isActive: true,
  },
  {
    name: 'Michael Brown',
    assignedVehicle: 'Vehicle 4',
    assignedDriver: 'Driver D',
    deliveryTime: '4:45 PM',
    pickupLocation: '147 Spruce St, Springfield',
    deliveryLocation: '258 Fir St, Springfield',
    isActive: true,
  },
  {
    name: 'Emily Davis',
    assignedVehicle: 'Vehicle 5',
    assignedDriver: 'Driver E',
    deliveryTime: '3:30 PM',
    pickupLocation: '369 Maple St, Springfield',
    deliveryLocation: '741 Willow St, Springfield',
    isActive: false,
  },
];
export const vehicleData = [
  {
    vehicleID: 1,
    driverAssigned: 'Driver A',
    jobAssigned: 'Job 1',
    currentSpeed: '60 mph',
    lastLocation: '123 Main St, Springfield',
    deliveryLocation: '456 Elm St, Springfield',
    isActive: true,
  },
  {
    vehicleID: 2,
    driverAssigned: 'Driver B',
    jobAssigned: 'Job 2',
    currentSpeed: '45 mph',
    lastLocation: '789 Oak St, Springfield',
    deliveryLocation: '321 Pine St, Springfield',
    isActive: false,
  },
  {
    vehicleID: 3,
    driverAssigned: 'Driver C',
    jobAssigned: 'Job 3',
    currentSpeed: '30 mph',
    lastLocation: '654 Cedar St, Springfield',
    deliveryLocation: '987 Birch St, Springfield',
    isActive: true,
  },
  {
    vehicleID: 4,
    driverAssigned: 'Driver D',
    jobAssigned: 'Job 4',
    currentSpeed: '50 mph',
    lastLocation: '147 Spruce St, Springfield',
    deliveryLocation: '258 Fir St, Springfield',
    isActive: true,
  },
  {
    vehicleID: 5,
    driverAssigned: 'Driver E',
    jobAssigned: 'Job 5',
    currentSpeed: '35 mph',
    lastLocation: '369 Maple St, Springfield',
    deliveryLocation: '741 Willow St, Springfield',
    isActive: false,
  },
];

export const orderData = [
  {
    orderId: 1,
    name: 'John Doe',
    Job: 'Job 1',
    assignDriver: 'Driver A',
    assignVehicle: 'Vehicle 1',
    scheduledDateTime: '2024-09-28 10:30 AM',
    isActive: true,
  },
  {
    orderId: 2,
    name: 'Jane Smith',
    Job: 'Job 2',
    assignDriver: 'Driver B',
    assignVehicle: 'Vehicle 2',
    scheduledDateTime: '2024-09-28 12:45 PM',
    isActive: false,
  },
  {
    orderId: 3,
    name: 'Alice Johnson',
    Job: 'Job 3',
    assignDriver: 'Driver C',
    assignVehicle: 'Vehicle 3',
    scheduledDateTime: '2024-09-29 09:00 AM',
    isActive: true,
  },
  {
    orderId: 4,
    name: 'Bob Williams',
    Job: 'Job 4',
    assignDriver: 'Driver D',
    assignVehicle: 'Vehicle 4',
    scheduledDateTime: '2024-09-29 02:15 PM',
    isActive: true,
  },
  {
    orderId: 5,
    name: 'Carol Davis',
    Job: 'Job 5',
    assignDriver: 'Driver E',
    assignVehicle: 'Vehicle 5',
    scheduledDateTime: '2024-09-30 11:30 AM',
    isActive: false,
  },
];
export const jobData = [
  {
    jobId: 1,
    name: 'John Doe',
    jobType: 'Delivery',
    assignDriver: 'Driver A',
    assignVehicle: 'Vehicle 1',
    totalOrders: 5,
    isActive: true,
  },
  {
    jobId: 2,
    name: 'Jane Smith',
    jobType: 'Pickup',
    assignDriver: 'Driver B',
    assignVehicle: 'Vehicle 2',
    totalOrders: 8,
    isActive: false,
  },
  {
    jobId: 3,
    name: 'Alice Johnson',
    jobType: 'Delivery',
    assignDriver: 'Driver C',
    assignVehicle: 'Vehicle 3',
    totalOrders: 3,
    isActive: true,
  },
  {
    jobId: 4,
    name: 'Bob Williams',
    jobType: 'Maintenance',
    assignDriver: 'Driver D',
    assignVehicle: 'Vehicle 4',
    totalOrders: 2,
    isActive: true,
  },
  {
    jobId: 5,
    name: 'Carol Davis',
    jobType: 'Delivery',
    assignDriver: 'Driver E',
    assignVehicle: 'Vehicle 5',
    totalOrders: 10,
    isActive: false,
  },
];
export const customerData = [
  {
    name: 'John Doe',
    contactPerson: 'Jane Smith',
    emailAddress: 'john.doe@example.com',
    phoneNumber: '+1 555-123-4567',
    businessType: 'Retail',
    address: '123 Main St, Springfield',
    isActive: true,
  },
  {
    name: 'Alice Johnson',
    contactPerson: 'Bob Williams',
    emailAddress: 'alice.johnson@example.com',
    phoneNumber: '+1 555-987-6543',
    businessType: 'Wholesale',
    address: '456 Elm St, Springfield',
    isActive: false,
  },
  {
    name: 'Michael Brown',
    contactPerson: 'Carol Davis',
    emailAddress: 'michael.brown@example.com',
    phoneNumber: '+1 555-789-1234',
    businessType: 'Manufacturing',
    address: '789 Oak St, Springfield',
    isActive: true,
  },
  {
    name: 'Emily White',
    contactPerson: 'Tom Harris',
    emailAddress: 'emily.white@example.com',
    phoneNumber: '+1 555-321-9876',
    businessType: 'Logistics',
    address: '321 Pine St, Springfield',
    isActive: true,
  },
];
