import { QuestionCircleOutlined } from '@ant-design/icons';
import { usePermissionVariables } from 'Hooks/HasPermission';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SearchField from 'components/InputFields/SearchField';
import PopConfirm from 'components/PopConfirm/index';
import Status from 'components/Status/index';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import { delete_timezone } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { convertDateFormat } from 'utils/helper';
import AddTimeZonesModal from './component/AddTimeZonesModal';
import EditTimeZonesModal from './component/EditTimeZonesModal';
import { getRowNumber } from 'utils/helper';

const TimeZones = () => {
  const { Time_Zone_Delete, Time_Zone_Edit, Time_Zone_Add, Time_Zone_View } =
    usePermissionVariables();
  const [openAddTimeZonesModal, setOpenAddTimeZonesModal] = useState(false);
  const [openEditTimeZonesModal, setOpenEditTimeZonesModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({
    name: '',
    offset: '',
    timezoneId: '',
    isActive: '',
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const { useGetRequest, deleteRequest } = useApiClient();
  const { data, isLoading, refetch } = useGetRequest(
    `api/GetAllTimezones?pagenumber=${pageNumber}&pagesize=${rowsPerPage}&searchquery=${debouncedSearch}`
  );
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({});

  const delHandler = (record) => {
    deleteRequest(`${delete_timezone}${record?.timezoneId}`)
      .then((res) => {
        if (res?.success) {
          const newTotalRecord = totalRecord - 1; // Decrement the total record count
          setTotalRecord(newTotalRecord); // Update the state

          // Check if we need to change the page number
          if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1); // Redirect to the previous page
          }
          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };

  const { themeMode } = useSelector((state) => state?.states);
  const columns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      key: 'sr',
      // align: 'center',
      width: 80,
      ellipsis: true,
      render: (text, row, index) =>
        getRowNumber(pageNumber, rowsPerPage, index),
    },
    {
      title: <div className="flex justify-between items-center">Timezone</div>,
      dataIndex: 'name',
      sorter: (a, b) => a?.name?.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'name' ? sortOrder.order : 'ascend',
      showSorterTooltip: false,
      ellipsis: true,

      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: 'Offset',
      dataIndex: 'offset',
      ellipsis: true,
      key: 'offset',
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      ellipsis: true,
      key: 'createdDate',
      render: (data, record) => convertDateFormat(record.createdDate),
    },
    {
      title: <span className="flex items-center justify-center">Status</span>,
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      width: 300,
      ellipsis: true,
      render: (tag, record) => {
        return (
          <span>
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },
    ...(Time_Zone_Edit || Time_Zone_Delete
      ? [
          {
            title: (
              <span className=" flex items-center justify-center">Action</span>
            ),
            dataIndex: '',
            width: 100,
            render: (text, record) => (
              <span className="flex justify-center items-center space-x-2">
                {Time_Zone_Edit && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                    onClick={() => {
                      setSelectedRecord({
                        name: record?.name,
                        offset: record?.offset,
                        timezoneId: record?.timezoneId,
                        isActive: record?.isActive,
                      });
                      setOpenEditTimeZonesModal(true);
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                )}
                {Time_Zone_Delete && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this task? "
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    >
                      <MdDeleteOutline className=" w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                )}
              </span>
            ),
          },
        ]
      : []),
  ];
  useEffect(() => {
    if (data) {
      setTotalRecord(data?.totalRecords);
    }
  }, [data]);
  return (
    <div>
      <div className="md:flex space-y-[20px] md:space-y-0 justify-between mt-10 mb-26">
        <SearchField
          htmlFor="search"
          id="myInputID"
          name="search"
          placeholder={'Search by Timezone'}
          value={debouncedSearch}
          setDebouncedSearch={setDebouncedSearch}
          setPageNumber={setPageNumber}
          searchLoading={searchLoading}
          setSearchLoading={setSearchLoading}
          refetch={refetch}
          debouncedSearch={debouncedSearch}
          setSearch={setSearch}
        />
        {Time_Zone_Add && (
          <div className="sm:flex justify-end">
            <PrimaryButton
              title="Add Time Zone"
              icon={<FaPlus />}
              onClick={() => setOpenAddTimeZonesModal(true)}
            />
          </div>
        )}
      </div>
      <Table
        data={data?.data}
        columns={columns}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        setTotalRecord={setTotalRecord}
        totalRecord={totalRecord}
        setPageNumber={setPageNumber}
        setRowsPerPage={setRowsPerPage}
        setSortOrder={setSortOrder}
        setFilters={setFilters}
        loading={isLoading}
      />
      {openAddTimeZonesModal && (
        <AddTimeZonesModal
          setOpenAddTimeZonesModal={setOpenAddTimeZonesModal}
          openAddTimeZonesModal={openAddTimeZonesModal}
          refetch={refetch}
        />
      )}
      {openEditTimeZonesModal && (
        <EditTimeZonesModal
          setOpenEditTimeZonesModal={setOpenEditTimeZonesModal}
          openEditTimeZonesModal={openEditTimeZonesModal}
          selectedRecord={selectedRecord}
          refetch={refetch}
        />
      )}
    </div>
  );
};
export default TimeZones;
