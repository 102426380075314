import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { emailRegEx, onlyAcceptsNumber } from './Regex';
export const validateSpaces = (value) => {
  return Yup.string()
    .test('no-only-spaces', 'Input cannot be only spaces', (value) => {
      return value.trim().length > 0; // Ensures input is not only spaces
    })
    .test(
      'no-leading-spaces',
      'Spaces at the beginning are not allowed',
      (value) => {
        return !/^\s/.test(value); // Checks for leading spaces
      }
    )
    .test(
      'no-trailing-spaces',
      'Spaces at the end are not allowed',
      (value) => {
        return !/\s$/.test(value); // Checks for trailing spaces
      }
    )
    .test(
      'no-consecutive-spaces',
      'Consecutive spaces are not allowed',
      (value) => {
        return !/\s{2,}/.test(value); // Checks for consecutive spaces
      }
    );
};
const useValidationSchema = () => {
  const { t } = useTranslation('translation');
  const {
    emailRequired,
    validEmail,
    password,
    maxPassword,
    minPassword,
    newPassword,
    confirmPasswordRequired,
    validConfirmPassword,
  } = t('ValidationSchema');

  const loginSchema = Yup.object({
    email: Yup.string()
      .required('Email is required')
      .min(3, 'Email must be at least 3 characters')
      .max(30, 'Email cannot exceed 30 characters')
      .matches(emailRegEx, 'Invalid email format'),
    password: Yup.string()
      .required(password)
      .min(8, minPassword)
      .max(30, maxPassword),
  });
  const sendPasswordAtEmailSchema = Yup.object({
    email: Yup.string()
      .required('Email is required')
      .min(3, 'Email must be at least 3 characters')
      .max(30, 'Email cannot exceed 30 characters')
      .matches(emailRegEx, 'Invalid email format'),
  });
  const newPasswordModalSchema = Yup.object({
    newPassword: Yup.string().required(newPassword),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], validConfirmPassword)
      .required(confirmPasswordRequired),
  });
  const countryModalSchema = Yup.object({
    name: validateSpaces()
      .required('Name is required')
      .min(3, 'Name must be at least 3 characters')
      .max(30, 'Name cannot exceed 30 characters')
      .matches(/^[a-zA-Z\s]+$/, 'Name can only contain alphabetic characters'),
  });
  const unitModalSchema = Yup.object({
    unitName: validateSpaces()
      .required('Unit name is required')
      .min(3, 'Unit name must be at least 3 characters')
      .max(30, 'Unit name cannot exceed 30 characters'),
    vehicleId: Yup.string().required('Vehicle is required'),
    driverId: Yup.string().required('Driver is required'),
    trailerId: Yup.string().required('Trailer is required'),
  });
  const zipModalSchema = Yup.object({
    zipCode: validateSpaces()
      .required('Zip code is required')
      .min(3, 'Zip code must be at least 3 characters')
      .max(20, 'Zip code cannot exceed 20 characters')
      .matches(/^[a-zA-Z0-9 ]*$/, 'Zip cannot contain special characters'),
    countryId: Yup.string().required('Country is required'),
    stateId: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
  });

  const stateModalSchema = Yup.object({
    stateName: validateSpaces()
      .required('State name is required')
      .min(3, 'State name must be at least 3 characters')
      .max(30, 'State name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z\s]+$/,
        'State name can only contain alphabetic characters'
      ),
    countryId: Yup.string().required('Country is required'),
  });

  const citiesModalSchema = Yup.object({
    cityName: validateSpaces()
      .required('City name is required')
      .min(3, 'City name must be at least 3 characters')
      .max(30, 'City name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z\s]+$/,
        'City name can only contain alphabetic characters'
      ),
    countryId: Yup.string().required('Country is required'),
    stateId: Yup.string().required('State is required'),
  });

  const currenciesModalSchema = Yup.object({
    code: validateSpaces()
      .required('Currency code is required')
      .max(3, 'Currency code cannot exceed 3 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Currency code cannot contain special characters'
      ),
    symbol: validateSpaces()
      .required('Currency symbol required')
      .max(3, 'Currency symbol cannot exceed 3 characters'),
    name: validateSpaces()
      .required('Currency name is required')
      .min(3, 'Currency name must be at least 3 characters')
      .max(30, 'Currency name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z\s]+$/,
        'Currency name can only contain alphabetic characters'
      ),
  });

  const currencyExchangeRateModalSchema = Yup.object({
    exchangeRateID: Yup.string().required('Exchnage rate id is required'),
    fromCurrencyID: Yup.string().required('From currency id is required'),
    toCurrencyID: Yup.string().required('To currency id is required'),
    exchangeRate: Yup.string().required('Exchange rate is required'),
  });

  const emailTemplateModalSchema = Yup.object({
    templateName: validateSpaces()
      .required('Template name is required')
      .min(3, 'Template name must be at least 3 characters')
      .max(30, 'Template name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Template name cannot contain special characters'
      ),
    emailSubject: validateSpaces()
      .required('Email subject is required')
      .min(3, 'Email subject must be at least 3 characters')
      .max(100, 'Email subject cannot exceed 100 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Email subject cannot contain special characters'
      ),
  });

  const vehicleTypeModalSchema = Yup.object({
    vehicleTypeName: validateSpaces()
      .required('Vehicle type name  is required')
      .min(3, 'Vehicle type name must be at least 3 characters')
      .max(30, 'Vehicle type name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9_ ]*$/,
        'Vehicle type cannot contain special characters'
      ),
    description: Yup.string()
      .required('Description is required')
      .min(3, 'Description must be at least 3 characters')
      .max(100, 'Description cannot exceed 100 characters'),
  });

  const unitofMeasureModalSchema = Yup.object({
    name: validateSpaces()
      .required('Unit name is required')
      .min(3, 'Unit name must be at least 3 characters')
      .max(30, 'Unit name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Device type cannot contain special characters'
      ),
    code: validateSpaces()
      .required('Unit code  is required')
      .max(10, 'Unit code cannot exceed 10 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Unit code cannot contain special characters'
      ),
    symbol: validateSpaces()
      .required('Unit symbol is required')
      .max(10, 'Unit symbol cannot exceed 10 characters'),
  });

  const timezoneModalSchema = Yup.object({
    name: validateSpaces()
      .required('Name is required')
      .min(3, 'Name must be at least 3 characters')
      .max(30, 'Name cannot exceed 30 characters'),
    // .matches(/^[a-zA-Z0-9 ]*$/, 'Name cannot contain special characters'),
    offset: validateSpaces()
      .required('Offset is required')
      .min(3, 'Offset must be at least 3 characters')
      .max(10, 'Offset cannot exceed 10 characters'),
  });

  const roleModalScehema = Yup.object({
    roleName: validateSpaces()
      .required('Role name is required')
      .min(3, 'Role name must be at least 3 characters')
      .max(30, 'Role name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9_ ]*$/,
        'Role name cannot contain special characters'
      ),
    userTypeId: Yup.string().required('User Type is required'),
    roleDescription: Yup.string()
      .required('Description is required')
      .min(3, 'Description must be at least 3 characters')
      .max(100, 'Description cannot exceed 100 characters'),
  });

  const rightsModalScehema = Yup.object({
    rightsName: Yup.string()
      .required('Rights name is required')
      .min(3, 'Rights name must be at least 3 characters')
      .max(30, 'Rights name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Rights name cannot contain special characters'
      ),
    rightsDescription: Yup.string()
      .required('Description is required')
      .min(3, 'Description must be at least 3 characters')
      .max(100, 'Description cannot exceed 100 characters'),
  });

  const pageModalScehema = Yup.object({
    pagesName: validateSpaces()
      .required('Page name is required')
      .min(3, 'Page name must be at least 3 characters')
      .max(30, 'Page name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Page name cannot contain special characters'
      ),
    menuSelect: Yup.string().required('Menu name is required'),
  });

  const assignPermissionScehema = Yup.object({});

  const menuModalScehema = Yup.object({
    menuName: validateSpaces()
      .required('Menu name is required')
      .min(3, 'Menu name must be at least 3 characters')
      .max(30, 'Menu name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Menu name cannot contain special characters'
      ),
  });

  const deviceTypeModalSchema = Yup.object({
    // deviceTypeID: Yup.string().required('Device Type ID is required'),
    deviceTypeName: validateSpaces()
      .required('Device type name is required')
      .min(3, 'Device type name must be at least 3 characters')
      .max(30, 'Device type name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Device type cannot contain special characters'
      ),
    description: Yup.string()
      .required('Description is required')
      .min(3, 'Description must be at least 3 characters')
      .max(100, 'Description cannot exceed 100 characters'),
  });

  const deviceModelModalSchema = Yup.object({
    deviceTypeId: Yup.string().required('Device type is required'),
    deviceModelName: validateSpaces()
      .required('Device model name is required')
      .min(3, 'Device model name must be at least 3 characters')
      .max(30, 'Device model name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9 -]*$/,
        'Device model name cannot contain special characters'
      ),
    description: Yup.string()
      .required('Description is required')
      .min(3, 'Description must be at least 3 characters')
      .max(100, 'Description cannot exceed 100 characters'),
  });

  const emailGatewayeModalSchema = Yup.object({
    smtpUserName: validateSpaces()
      .required('SMTP user name is required')
      .min(3, 'SMTP user name must be at least 3 characters')
      .max(30, 'SMTP user name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'SMTP user name cannot contain special characters'
      ),
    smtpPassword: Yup.string()
      .required('SMTP password  is required')
      .min(8, 'SMTP password must be at least 8 characters')
      .max(30, 'SMTP password cannot exceed 30 characters'),
    senderName: validateSpaces()
      .required('Sender name is required')
      .min(3, 'Sender name must be at least 3 characters')
      .max(30, 'Sender name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Sender name cannot contain special characters'
      ),
    senderEmail: Yup.string()
      .required('Sender email is required')
      .min(3, 'Sender email must be at least 3 characters')
      .max(30, 'Sender email cannot exceed 30 characters')
      .matches(emailRegEx, 'Invalid email format'),
    smtpServer: validateSpaces()
      .required('SMTP server is required')
      .max(100, 'SMTP server cannot exceed 100 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'SMTP server cannot contain special characters'
      ),
    smtpPort: validateSpaces()
      .required('SMTP port is required')
      .max(10, 'SMTP port cannot exceed 10 characters'),
  });

  const smsGatewayeModalSchema = Yup.object({
    templateName: validateSpaces()
      .required('Template name is required')
      .min(3, 'Template name must be at least 3 characters')
      .max(30, 'Template name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Template name cannot contain special characters'
      ),
    templateCode: validateSpaces()
      .required('Template code is required')
      .min(3, 'Template code must be at least 3 characters')
      .max(30, 'Template code cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Template code cannot contain special characters'
      ),
    templateBody: Yup.string().required('Template body is required'),
    language: validateSpaces()
      .required('Language is required')
      .min(3, 'Language must be at least 3 characters')
      .max(30, 'Language cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Language name cannot contain special characters'
      ),
  });
  const addSMSSettingModalSchema = Yup.object({
    smsGatewayName: validateSpaces()
      .required('Gateway name is required')
      .min(3, 'Gateway name must be at least 3 characters')
      .max(30, 'Gateway name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Gateway name cannot contain special characters'
      ),
    userName: validateSpaces()
      .required('User name is required')
      .min(3, 'User name must be at least 3 characters')
      .max(30, 'User name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9_ ]*$/,
        'User Name cannot contain special characters'
      ),
    passwordHash: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .max(30, 'Password cannot exceed 30 characters'),
    fromPhoneNumber: Yup.string()
      .required('Phone number is required')
      .min(8, 'Phone number must be at least 5 digits')
      .max(18, 'Phone number cannot be more than 15 digits'),
  });
  const editSMSSettingModalSchema = Yup.object({
    smsGatewayName: Yup.string()
      .required('Gateway name is required')
      .min(3, 'Gateway name must be at least 3 characters')
      .max(30, 'Gateway name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Gateway name cannot contain special characters'
      ),
    userName: Yup.string()
      .required('User name is required')
      .min(3, 'User name must be at least 3 characters')
      .max(30, 'User name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9_ ]*$/,
        'User Name cannot contain special characters'
      ),
    // passwordHash: Yup.string()
    //   .required('Password is required')
    //   .min(8, 'Password must be at least 8 characters')
    //   .max(30, 'Password cannot exceed 30 characters'),
    fromPhoneNumber: Yup.string().required('Phone number is required'),
  });
  const assignedSubscriptionModalSchema = Yup.object({
    status: Yup.string().required('Status is required'),
    planId: Yup.string().required('Plan name is required'),
    assignmentDate: Yup.string().required('Assignment date is required'),
    expiryDate: Yup.string()
      .required('Expiry date is required')
      .test(
        'end-after-start',
        'Expiry date must be after start date',
        function (expiryDate) {
          const { assignmentDate } = this.parent; // Access sibling field
          return (
            !assignmentDate ||
            !expiryDate ||
            dayjs(expiryDate).isAfter(dayjs(assignmentDate))
          );
        }
      ),
  });
  const associateSubPlanfeaturePlansModalSchema = Yup.object({
    featureId: Yup.string().required('Feature name is required'),
    planId: Yup.string().required('Plan name is required'),
  });
  const featurePlansModalSchema = Yup.object({
    featureName: validateSpaces()
      .required('Feature name is required')
      .min(3, 'Feature name must be at least 3 characters')
      .max(30, 'Feature name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Feature name cannot contain special characters'
      ),
    status: Yup.string().required('Status is required'),
    description: Yup.string()
      .required('Description is required')
      .min(3, 'Description must be at least 3 characters')
      .max(250, 'Description cannot exceed 250 characters'),
  });
  const subscriptionPlanseModalSchema = Yup.object({
    planName: validateSpaces()
      .required('Plan name is required')
      .min(3, 'Plan name must be at least 3 characters')
      .max(30, 'Plan name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Plan name cannot contain special characters'
      ),
    maxUsers: Yup.string()
      .required('Max users is required')
      .matches(onlyAcceptsNumber, 'Invalid max users format'),
    deviceCount: Yup.string()
      .required('Device count is required')
      .matches(onlyAcceptsNumber, 'Invalid device count format'),
    planValidFrom: Yup.string().required('Plan valid from is required'),
    planValidTill: Yup.string()
      .required('Plan valid till is required')
      .test(
        'end-after-start',
        'plan Valid Till must be after start date',
        function (planValidTill) {
          const { planValidFrom } = this.parent; // Access sibling field
          return (
            !planValidFrom ||
            !planValidTill ||
            dayjs(planValidTill).isAfter(dayjs(planValidFrom))
          );
        }
      ),
    price: Yup.string()
      .required('Price is required')
      .matches(onlyAcceptsNumber, 'Invalid price format'),
    billingCycle: Yup.string().required('Billing cycle is required'),
    description: Yup.string()
      .required('Description is required')
      .min(3, 'Description must be at least 3 characters')
      .max(250, 'Description cannot exceed 250 characters'),
    status: Yup.string().required('Status is required'),
  });

  const userModalSchema = Yup.object({
    name: validateSpaces()
      .required('User name is required')
      .min(3, 'User name must be at least 3 characters')
      .max(30, 'User name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9_ ]*$/,
        'User Name cannot contain special characters'
      ),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .max(30, 'Password cannot exceed 30 characters'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required')
      .min(3, 'Email must be at least 3 characters')
      .max(30, 'Email cannot exceed 30 characters')
      .matches(emailRegEx, 'Invalid email format'),
    // .test('is-com', 'Invalid email format', (value) => {
    //   if (!value) return true;
    //   return /.+\.com$/.test(value);
    // }),
    contactNumber: Yup.string()
      .required('Contact number is required')
      .min(8, 'Phone number must be at least 5 digits')
      .max(18, 'Phone number cannot be more than 15 digits'),
    assignRole: Yup.string().required('Assign role is required'),
    userType: Yup.string().required('User type is required'),
    tenantId: Yup.string()
      .nullable()
      .test(
        'tenantId-required',
        'Tenant name is required for user type tenant admin',
        function (value) {
          const { userType } = this.parent;
          if (userType === '6') {
            return value !== null && value !== undefined && value.trim() !== '';
          }
          return true;
        }
      ),
  });
  const EdituserModalSchema = Yup.object({
    name: Yup.string()
      .required('User name is required')
      .min(3, 'User name must be at least 3 characters')
      .max(30, 'User name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9_ ]*$/,
        'User Name cannot contain special characters'
      ),
    // password: Yup.string().required('Password  is required'),
    email: Yup.string()
      .required('Email is required')
      .min(3, 'Email must be at least 3 characters')
      .max(30, 'Email cannot exceed 30 characters')
      .matches(emailRegEx, 'Invalid email format'),
    contactNumber: Yup.string().required('Contact number is required'),
    userType: Yup.string().required('User type is required'),
    tenantId: Yup.string()
      .nullable()
      .test(
        'tenantId-required',
        'Tenant name is required for user type tenant admin',
        function (value) {
          const { userType } = this.parent;
          if (userType === '6') {
            return value !== null && value !== undefined && value.trim() !== '';
          }
          return true;
        }
      ),
  });
  const roleRightsModalSchema = Yup.object({
    role: Yup.string().required('Role is required'),
    // page: Yup.string().required('Page is required'),
    menu: Yup.string().required('Menu is required'),
    // isActive: Yup.boolean()
    //   .oneOf([true], 'You must accept the terms and conditions.')
    //   .required('This field is required'),
  });
  const addUserSchema = Yup.object({
    uploadImage: Yup.mixed().required('Image is required'),
    name: Yup.string()
      .required('Name is required')
      .min(3, 'Name must be at least 3 characters')
      .max(30, 'Name cannot exceed 30 characters'),
    email: Yup.string()
      .required('Email is required')
      .min(3, 'Email must be at least 3 characters')
      .max(30, 'Email cannot exceed 30 characters')
      .matches(emailRegEx, 'Invalid email format'),
    role: Yup.string().required('Role is required'),
    address1: Yup.string().required('Address is required'),
    address2: Yup.string().required('Address 2 is required'),
    countryCode: Yup.string().required('Country code is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zipCode: Yup.string().required('Zip code  is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    mobileNumber: Yup.string().required('Mobile number is required'),
  });
  const resetPasswordModalSchema = Yup.object({
    oldPassword: Yup.string().required('Old password is required'),
    newPassword: Yup.string()
      .required('New password is required')
      .min(8, 'New password must be at least 8 characters')
      .max(30, 'New password cannot exceed 30 characters'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm password is required')
      .min(8, 'Confirm password must be at least 8 characters'),
  });
  const generalModalSchema = Yup.object({
    deviceName: Yup.string()
      .required('Device name is required')
      .min(3, 'Device name must be at least 3 characters')
      .max(30, 'Device name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Device name cannot contain special characters'
      ),
    deviceModel: Yup.string().required('Device model is required'),
    IMEI_No: Yup.string().required('IMEI number is required'),
    serialNumber: Yup.string().required('Serial number is required'),
    simCardNumber: Yup.string().required('SIM card number is required'),
    simProvider: Yup.string().required('SIM Provider is required'),
  });
  const stausModalSchema = Yup.object({
    longitude: Yup.string().required('Longitude is required'),
    latitude: Yup.string().required('Latitude is required'),
  });
  const SIMManagementModalSchema = Yup.object({
    SIMNumber: Yup.string().required('SIM number is required'),
    brandName: Yup.string()
      .required('Brand name is required')
      .min(3, 'Brand name must be at least 3 characters')
      .max(30, 'Brand name cannot exceed 30 characters'),
  });
  const generalTenantModalSchema = Yup.object({
    tenantName: validateSpaces()
      .required('Tenant name is required')
      .min(3, 'Tenant name must be at least 3 characters')
      .max(30, 'Tenant name cannot exceed 30 characters')
      .matches(
        /^[-_.a-zA-Z0-9& ]*$/,
        'Device name cannot contain special characters except (._-&)'
      ),
    userName: validateSpaces()
      .required('User name is required')
      .min(3, 'User name must be at least 3 characters')
      .max(30, 'User name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'User name cannot contain special characters'
      ),
    tenantEmail: Yup.string()
      .required('Tenant email is required')
      .min(3, 'Tenant email must be at least 3 characters')
      .max(30, 'Tenant email cannot exceed 30 characters')
      .matches(emailRegEx, 'Invalid email format'),
    password: Yup.string()
      .required(password)
      .min(8, minPassword)
      .max(30, maxPassword),
    tenantPhone: Yup.string()
      .required('SIM card number is required')
      .min(10, 'Phone number must be at least 7 digits')
      .max(18, 'Phone number cannot be more than 13 digits'),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    addressLine1: validateSpaces()
      .required('Address line 1 is required')
      .min(3, 'Tenant name must be at least 3 characters')
      .max(100, 'Tenant name cannot exceed 30 characters'),
    addressLine2: Yup.string()
      .nullable()
      .notRequired() // Makes the field optional
      .test('no-only-spaces', 'Input cannot be only spaces', (value) => {
        return value ? value.trim().length > 0 : true; // Ensures input is not only spaces
      })
      .test(
        'no-leading-spaces',
        'Spaces at the beginning are not allowed',
        (value) => {
          return value ? !/^\s/.test(value) : true; // Checks for leading spaces
        }
      )
      .test(
        'no-trailing-spaces',
        'Spaces at the end are not allowed',
        (value) => {
          return value ? !/\s$/.test(value) : true; // Checks for trailing spaces
        }
      )
      .test(
        'no-consecutive-spaces',
        'Consecutive spaces are not allowed',
        (value) => {
          return value ? !/\s{2,}/.test(value) : true; // Checks for consecutive spaces
        }
      )
      .min(3, 'Address Line 2 must be at least 3 characters')
      .max(100, 'Address Line 2 cannot exceed 30 characters'),
    postalCode: Yup.string().required('Postal code is required'),
    themeColor: Yup.string().required('Theme color is required'),
    emailSettingId: Yup.string().required('Email gateway is required'),
    smsSettingId: Yup.string().required('SMS gateway is required'),
    assignRole: Yup.string().required('Assign role is required'),
  });
  const driverLicenceFormSchema = Yup.object({
    firstName: validateSpaces()
      .required('First name is required')
      .min(3, 'First name must be at least 3 characters')
      .max(50, 'First name cannot exceed 50 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'First name cannot contain special characters'
      ),
    lastName: validateSpaces()
      .required('Last name is required')
      .min(3, 'Last name must be at least 3 characters')
      .max(50, 'Last name cannot exceed 50 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Last name cannot contain special characters'
      ),
    email: validateSpaces()
      .required('Email is required')
      .matches(emailRegEx, 'Invalid email format'),
    phoneNumber: validateSpaces()
      .required('Phone number is required')
      .min(10, 'Phone number must be at least 7 digits')
      .max(18, 'Phone number cannot be more than 13 digits'),
    joiningDate: Yup.string()
      .required('Joining date is required')
      .test(
        'end-after-start',
        'Joining date must be after date of birth',
        function (joiningDate) {
          const { dateOfBirth } = this.parent; // Access sibling field
          return (
            !dateOfBirth ||
            !joiningDate ||
            dayjs(joiningDate).isAfter(dayjs(dateOfBirth))
          );
        }
      ),
    licenseNumber: Yup.string()
      .required('License number is required')
      .min(3, 'License number must be at least 3 characters')
      .max(50, 'License number cannot exceed 50 characters'),
    nationality: Yup.string()
      .required('Nationality is required')
      .min(3, 'Nationality must be at least 3 characters')
      .max(15, 'Nationality cannot exceed 15 characters'),
    nationalIdentity: Yup.string()
      .required('National identity is required')
      .min(5, 'National identity must be at least 5 characters')
      .max(20, 'National identity cannot exceed 20 characters'),
    address: Yup.string()
      .required('Address is required')
      .min(3, 'Address must be at least 3 characters')
      .max(50, 'Address cannot exceed 50 characters'),
    licenseType: validateSpaces()
      .required('License type is required')
      .min(3, 'License type must be at least 3 characters')
      .max(50, 'License type cannot exceed 50 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'License type cannot contain special characters'
      ),
    issueDate: Yup.string().required('Issue date is required'),
    dateOfBirth: Yup.string().required('Date of birth is required'),
    expiryDate: Yup.string()
      .required('Expiry date is required')
      .test(
        'end-after-start',
        'Expiry date must be after issue date',
        function (expiryDate) {
          const { issueDate } = this.parent;
          return (
            !issueDate ||
            !expiryDate ||
            dayjs(expiryDate).isAfter(dayjs(issueDate))
          );
        }
      ),
  });
  const addDriverDocumentSchema = Yup.object({
    // entityType: Yup.string().required('Entity Type is required'),
    documentType: Yup.string().required('Document Type is required'),
    documentPicture: Yup.string().required('Document Picture is required'),
    issueDate: Yup.string().required('Issue date is required'),
    expiryDate: Yup.string()
      .required('Expiry date is required')
      .test(
        'end-after-start',
        'Expiry date must be after issue date',
        function (expiryDate) {
          const { issueDate } = this.parent;
          return (
            !issueDate ||
            !expiryDate ||
            dayjs(expiryDate).isAfter(dayjs(issueDate))
          );
        }
      ),
  });
  const trailerFormSchema = Yup.object({
    trailerNumber: validateSpaces()
      .required('Trailer number is required')
      .min(3, 'Trailer number must be at least 3 characters')
      .max(30, 'Trailer number cannot exceed 30 characters')
      .matches(
        /^[-a-zA-Z0-9 ]*$/,
        'Trailer number cannot contain special characters'
      ),
    trailerType: validateSpaces().required('Trailer type is required'),
    capacity: validateSpaces()
      .required('Capacity is required')
      .min(1, 'Capacity must be at least 1 characters')
      .max(5, 'Capacity cannot exceed 5 characters')
      .matches(/^[.0-9]*$/, 'Capacity cannot contain special characters'),
    width: validateSpaces()
      .required('Width is required')
      .min(1, 'Width must be at least 1 characters')
      .max(5, 'Width cannot exceed 5 characters')
      .matches(/^[.0-9]*$/, 'Width cannot contain special characters'),
    length: validateSpaces()
      .required('Length is required')
      .min(1, 'Length must be at least 1 characters')
      .max(5, 'Length cannot exceed 5 characters')
      .matches(/^[.0-9]*$/, 'Length cannot contain special characters'),
    height: validateSpaces()
      .required('Height is required')
      .min(1, 'Height must be at least 1 characters')
      .max(5, 'Height cannot exceed 5 characters')
      .matches(/^[.0-9]*$/, 'Height cannot contain special characters'),
    status: validateSpaces().required('Status is required'),
  });
  const updateTenantModalSchema = Yup.object({
    tenantName: validateSpaces()
      .required('Tenant name is required')
      .min(3, 'Tenant name must be at least 3 characters')
      .max(30, 'Tenant name cannot exceed 30 characters')
      .matches(
        /^[-_.a-zA-Z0-9& ]*$/,
        'Device name cannot contain special characters except (._-&)'
      ),
    userName: validateSpaces()
      .required('User name is required')
      .min(3, 'User name must be at least 3 characters')
      .max(30, 'User name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'User name cannot contain special characters'
      ),
    tenantEmail: Yup.string()
      .required('Tenant email is required')
      .min(3, 'Tenant email must be at least 3 characters')
      .max(30, 'Tenant email cannot exceed 30 characters')
      .matches(emailRegEx, 'Invalid email format'),
    tenantPhone: Yup.string()
      .required('SIM card number is required')
      .min(10, 'Phone number must be at least 7 digits')
      .max(18, 'Phone number cannot be more than 13 digits'),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    addressLine1: validateSpaces()
      .required('Address line 1 is required')
      .min(3, 'Tenant name must be at least 3 characters')
      .max(100, 'Tenant name cannot exceed 30 characters'),
    addressLine2: Yup.string()
      .nullable()
      .notRequired() // Makes the field optional
      .test('no-only-spaces', 'Input cannot be only spaces', (value) => {
        return value ? value.trim().length > 0 : true; // Ensures input is not only spaces
      })
      .test(
        'no-leading-spaces',
        'Spaces at the beginning are not allowed',
        (value) => {
          return value ? !/^\s/.test(value) : true; // Checks for leading spaces
        }
      )
      .test(
        'no-trailing-spaces',
        'Spaces at the end are not allowed',
        (value) => {
          return value ? !/\s$/.test(value) : true; // Checks for trailing spaces
        }
      )
      .test(
        'no-consecutive-spaces',
        'Consecutive spaces are not allowed',
        (value) => {
          return value ? !/\s{2,}/.test(value) : true; // Checks for consecutive spaces
        }
      )
      .min(3, 'Address line 2 must be at least 3 characters')
      .max(100, 'Address line 2 cannot exceed 30 characters'),
    postalCode: Yup.string().required('Postal code is required'),
    themeColor: Yup.string().required('Theme color is required'),
    emailSettingId: Yup.string().required('Email setting is required'),
    smsSettingId: Yup.string().required('SMS setting is required'),
    assignRole: Yup.string().required('Assign role is required'),
  });
  const subscriptionManagementModalSchema = Yup.object({
    tenantID: Yup.string().required('Tenant id is required'),
    currentSubscriptionPlan: Yup.string().required(
      'Current subscription plan is required'
    ),
    subscriptionStartDate: Yup.date().required(
      'Subscription start date is required'
    ),
    subscriptionEndDate: Yup.date().required(
      'Subscription end date is required'
    ),
    nextBillingDate: Yup.date().required('Next billing date is required'),
    billingFrequency: Yup.string().required('Billing frequency is required'),
    billingAmount: Yup.string().required('Billing amount is required'),
    upgrade: Yup.string().required('Upgrade is required'),
  });

  const usersManagementModalSchema = Yup.object({
    userID: Yup.string().required('User id is required'),
    userName: Yup.string()
      .required('User name is required')
      .min(3, 'User name must be at least 3 characters')
      .max(30, 'User name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9_ ]*$/,
        'User Name cannot contain special characters'
      ),
    userEmail: Yup.string()
      .required('User email is required')
      .min(3, 'User email must be at least 3 characters')
      .max(30, 'User email cannot exceed 30 characters')
      .matches(emailRegEx, 'Invalid email format'),
    userRole: Yup.string().required('User role is required'),
    tenantID: Yup.string().required('Tenant id is required'),
    createdDate: Yup.date().required('Created date is required'),
    lastModifiedDate: Yup.date().required('Last modified date is required'),
  });
  const assignDeviceModalSchema = Yup.object({
    tenantId: Yup.string().required('Tenant is required'),
    vehicle: Yup.string().required('Vehicle is required'),
    device: Yup.string().required('Device is required'),
  });
  const configurationsModalSchema = Yup.object({
    device: Yup.string().required('Device is required'),
    configurationKey: validateSpaces()
      .required('Configuration key is required')
      .min(3, 'Configuration key must be at least 3 characters')
      .max(30, 'Configuration key cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Configuration key cannot contain special characters'
      ),
    configurationValue: validateSpaces()
      .required('Configuration value is required')
      .min(3, 'Configuration value must be at least 3 characters')
      .max(30, 'Configuration value cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9.]*$/,
        'Configuration value cannot contain special characters'
      ),
  });
  const addVehicleModalSchema = Yup.object({
    vehicleID: Yup.string().required('Vehicle id is required'),
    make: Yup.string().required('Make is required'),
    model: Yup.string().required('Model is required'),
    year: Yup.date().required('Year is required'),
    VIN: Yup.string().required('VIN is required'),
    color: Yup.string().required('Color is required'),
    fuelType: Yup.string().required('Fuel type is required'),
    capacity: Yup.string().required('Capacity is required'),
  });
  const addDeviceModalSchema = Yup.object({
    deviceName: validateSpaces()
      .required('Device name is required')
      .min(3, 'Device name must be at least 3 characters')
      .max(30, 'Device name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Device name cannot contain special characters'
      ),
    deviceModel: Yup.string().required('Device model is required'),
    IMEI_No: validateSpaces()
      .required('IMEI number is required')
      .min(3, 'IMEI number must be at least 3 characters')
      .matches(
        /^[a-zA-Z0-9 -]*$/,
        'IMEI number can only contain special characters of -'
      ),
    serialNumber: validateSpaces()
      .required('Serial number is required')
      .min(3, 'Serial number must be at least 3 characters')
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        'Serial number cannot contain special characters'
      ),
    simCardNumber: Yup.string()
      .required('SIM card number is required')
      .min(8, 'Phone number must be at least 5 digits')
      .max(18, 'Phone number cannot be more than 15 digits'),
    simProvider: validateSpaces()
      .required('SIM provider is required')
      .min(3, 'SIM provider must be at least 3 characters')
      .max(30, 'SIM provider cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z\s]+$/,
        'SIM provider can only contain alphabetic characters'
      ),
    status: Yup.string().required('Status is required'),
  });
  const deviceAssociationandConfigurationsSchema = Yup.object({
    vehicleID: Yup.string().required('Vehicle id is required'),
    deviceID: Yup.string().required('Device id is required'),
    deviceType: Yup.string().required('Device type is required'),
    installationDate: Yup.date().required('Installation date is required'),
    deviceStatus: Yup.string().required('Device status is required'),
    configurationSettings: Yup.string().required(
      'Configuration settings is required'
    ),
  });
  const vehicleDocumentSchema = Yup.object({
    vehicleID: Yup.string().required('Vehicle id is required'),
    documentID: Yup.string().required('Document id is required'),
    documentType: Yup.string().required('Document type is required'),
    uploadDate: Yup.date().required('Upload date is required'),
    expiryDate: Yup.date().required('Expiry date is required'),
    documentFile: Yup.string().required('Document file is required'),
  });
  const privateModeSchema = Yup.object({
    vehicleID: Yup.string().required('Vehicle id is required'),
    privateModeStatus: Yup.string().required('Private mode status is required'),
    privateModeStartTime: Yup.date().required(
      'Private mode start time is required'
    ),
    privateModeEndTime: Yup.date().required(
      'Private mode end time is required'
    ),
    reasonForPrivateMode: Yup.string().required(
      'Reason for private mode is required'
    ),
  });
  const addDriverProfileandInformationSchema = Yup.object().shape({
    driverID: Yup.string().required('Vehicle ID is required'),
    driverFullName: Yup.string().required('Vehicle ID is required'),
    dateOfBirth: Yup.string().required('Vehicle ID is required'),
    licenseNumber: Yup.string().required('Vehicle ID is required'),
    licenseExpiry: Yup.string().required('Vehicle ID is required'),
    licenseType: Yup.string().required('Vehicle ID is required'),
    contactPhone: Yup.string().required('Vehicle ID is required'),
    contactEmail: Yup.string().required('Vehicle ID is required'),
    residentialAddress: Yup.string().required('Vehicle ID is required'),
    emergencyContactName: Yup.string().required('Vehicle ID is required'),
    emergencyContactPhone: Yup.string().required('Vehicle ID is required'),
    driverStatus: Yup.string().required('Vehicle ID is required'),
    profilePicture: Yup.mixed()
      .required('An image is required')
      .test('fileSize', 'File size is too large', (value) => {
        // Check if the value exists and its size is less than 2MB
        return value && value.size <= 1 * 1024 * 1024; // 2MB
      })
      .test('fileType', 'Unsupported file format', (value) => {
        // Check if the file type is one of the allowed formats
        return value && ['image/jpeg', 'image/png'].includes(value.type);
      }),
  });
  const vehicleInformationSchema = Yup.object().shape({
    vehicleNumber: validateSpaces().required('Vehicle number is required'),
    manufacturer: validateSpaces().required('Manufacturer is required'),
    model: validateSpaces().required('Model is required'),
    manufacturerYear: Yup.string().required('Manufacturer Year is required'),
    color: validateSpaces().required('Color is required'),
    capacity: Yup.string().required('Capacity is required'),
    vinNumber: Yup.string()
      .length(17, 'VIN must be exactly 17 characters')
      .matches(
        /^[A-HJ-NPR-Z0-9]+$/,
        'VIN must be alphanumeric without I, O, or Q'
      )
      .required('VIN is required'),
    status: Yup.string().required('Status is required'),
    ownershipType: validateSpaces().required('Ownership Type is required'),
    leaseExpiryDate: Yup.date().required('Lease Expiry Date is required'),
    purchaseDate: Yup.date().required('Purchase Date is required'),
    ownerName: validateSpaces().required('OwnerName is required'),
    policyNumber: validateSpaces().required('Policy Number is required'),
    insuranceCompany: validateSpaces().required(
      'Insurance Company is required'
    ),
    coverageAmount: Yup.string().required('Coverage Amount is required'),
    expiryDate: Yup.date().required('Expiry Date is required'),
  });
  const vehicleAssignmentModalSchema = Yup.object().shape({
    vehicleId: Yup.string().required('vehicle Id is required'),
    driverId: Yup.string().required('Driver Id  is required'),
    assignmentDate: Yup.date().required('Assignment Date is required'),
    endDate: Yup.date()
      .required('End Date is required')
      .test(
        'end-after-start',
        'plan Valid Till must be after start date',
        function (endDate) {
          const { assignmentDate } = this.parent; // Access sibling field
          return (
            !assignmentDate ||
            !endDate ||
            dayjs(endDate).isAfter(dayjs(assignmentDate))
          );
        }
      ),
    status: Yup.string().required('Status is required'),
  });
  const vehicleMaintenanceModalSchema = Yup.object().shape({
    maintenanceType: Yup.string().required('Maintenance Type is required'),
    cost: Yup.string().required('Cost is required'),
    maintenanceDate: Yup.date().required('Maintenance Date is required'),
    nextScheduledDate: Yup.date().required('Next Scheduled Date is required'),
    description: Yup.string().required('Description is required'),
  });
  const customerModalSchema = Yup.object({
    customerName: validateSpaces()
      .required('Customer name is required')
      .min(3, 'Customer name must be at least 3 characters')
      .max(30, 'Customer name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9_ ]*$/,
        'Customer Name cannot contain special characters'
      ),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required')
      .min(3, 'Email must be at least 3 characters')
      .max(30, 'Email cannot exceed 30 characters')
      .matches(emailRegEx, 'Invalid email format'),
    // .test('is-com', 'Invalid email format', (value) => {
    //   if (!value) return true;
    //   return /.+\.com$/.test(value);
    // }),
    phoneNumber: Yup.string()
      .required('Contact number is required')
      .min(8, 'Phone number must be at least 5 digits')
      .max(18, 'Phone number cannot be more than 15 digits'),
    address: Yup.string().required('Address is required'),
  });
  const addOrderSchema = Yup.object({
    customerId: Yup.string().required('Customer Id is required'),
    orderName: validateSpaces()
      .required('Order name is required')
      .min(3, 'Order name must be at least 3 characters')
      .max(30, 'Order name cannot exceed 30 characters')
      .matches(
        /^[a-zA-Z0-9_ ]*$/,
        'Order name cannot contain special characters'
      ),
    orderDate: Yup.date().required('Order date is required'),
    // pickupDate: Yup.string()
    //   .required('Pickup date is required')
    //   .test(
    //     'end-after-start',
    //     'Pickupdate must be after or equal to order date',
    //     function (pickupDate) {
    //       const { orderDate } = this.parent;
    //       return (
    //         !orderDate ||
    //         !pickupDate ||
    //         new Date(pickupDate) >= new Date(orderDate)
    //       );
    //     }
    //   ),
    deliveryDate: Yup.string()
      .required('Delivery date is required')
      .test(
        'end-after-start',
        'Delivery date must be after or equal to both order date and pickup date',
        function (deliveryDate) {
          const { orderDate, pickupDate } = this.parent;
          return (
            (!orderDate || new Date(deliveryDate) >= new Date(orderDate)) &&
            (!pickupDate || new Date(deliveryDate) >= new Date(pickupDate))
          );
        }
      ),
    pickupLocation: validateSpaces().required('Pickup location is required'),
    deliveryLocation: validateSpaces().required(
      'Delivery location is required'
    ),
    orderStatus: Yup.string().required('Order status  is required'),
    totalWeight: Yup.string().required('Total Weight is required'),
  });
  const addLoadSchema = Yup.object({
    loadStatus: Yup.string().required('Load status is required'),
    pickupDate: Yup.date().required('Date is required'),
    pickupTime: Yup.string().required('Time is required'),
    deliveryDate: Yup.date()
      .required('Date is required')
      .test('end-after-start', 'Delivery date early', function (deliveryDate) {
        const { pickupDate } = this.parent;
        return (
          !pickupDate ||
          !deliveryDate ||
          dayjs(deliveryDate).isAfter(dayjs(pickupDate)) ||
          dayjs(deliveryDate).isSame(dayjs(pickupDate))
        );
      }),

    deliveryTime: Yup.date()
      .required('Time is required')
      .test('end-after-start', 'Delivery time early', function (deliveryTime) {
        const { pickupDate, deliveryDate, pickupTime } = this.parent;

        if (!pickupDate || !pickupTime || !deliveryDate || !deliveryTime)
          return true;
        const sameDate = dayjs(deliveryDate).isSame(dayjs(pickupDate), 'day');
        return sameDate ? dayjs(deliveryTime).isAfter(dayjs(pickupTime)) : true;
      }),
    loadDispatcher: Yup.string().required('Dispatcher is required'),
    pickupAddress: Yup.string().required('Address is required'),
    pickupCountry: Yup.string().required('Country is required'),
    pickupState: Yup.string().required('State is required'),
    pickupCity: Yup.string().required('City is required'),
    pickupZipCode: Yup.string().required('Zip code is required'),
    deliveryAddress: Yup.string().required('Address is required'),
    deliveryCountry: Yup.string().required('Country is required'),
    deliveryState: Yup.string().required('State is required'),
    deliveryCity: Yup.string().required('City is required'),
    deliveryZipCode: Yup.string().required('Zip code is required'),
    customerId: Yup.string().required('Customer is required'),
    postOffice: Yup.string().required('PO is required'),
    driverId: Yup.string().required('Driver is required'),
    vehicleId: Yup.string().required('Vehicle is required'),
    trailerId: Yup.string().required('Trailer is required'),
    loadDescription: Yup.string()
      .required('Description is required')
      .min(3, 'Description must be at least 3 characters')
      .max(100, 'Description cannot exceed 100 characters'),
  });
  const editLoadSchema = Yup.object({
    loadStatus: Yup.string().required('Load status is required'),
    loadDispatcher: Yup.string().required('Dispatcher is required'),
    customerId: Yup.string().required('Customer is required'),
    pickupDate: Yup.date().required('Date is required'),
    pickupTime: Yup.string().required('Time is required'),
    driverId: Yup.string().required('Driver is required'),
    vehicleId: Yup.string().required('Vehicle is required'),
    trailerId: Yup.string().required('Trailer is required'),
    deliveryDate: Yup.date()
      .required('Date is required')
      .test('end-after-start', 'Delivery date early', function (deliveryDate) {
        const { pickupDate } = this.parent;
        return (
          !pickupDate ||
          !deliveryDate ||
          dayjs(deliveryDate).isAfter(dayjs(pickupDate)) ||
          dayjs(deliveryDate).isSame(dayjs(pickupDate))
        );
      }),

    deliveryTime: Yup.date()
      .required('Time is required')
      .test('end-after-start', 'Delivery time early', function (deliveryTime) {
        const { pickupDate, deliveryDate, pickupTime } = this.parent;

        if (!pickupDate || !pickupTime || !deliveryDate || !deliveryTime)
          return true;
        const sameDate = dayjs(deliveryDate).isSame(dayjs(pickupDate), 'day');
        return sameDate ? dayjs(deliveryTime).isAfter(dayjs(pickupTime)) : true;
      }),
    pickupAddress: Yup.string().required('Address is required'),
    deliveryAddress: Yup.string().required('Address is required'),
    stop: Yup.array().of(
      Yup.object({
        stopDate: Yup.date().required('Date  is required'),
        stopTime: Yup.date().required('Time  is required'),
        stopAddress: Yup.string().required('Address  is required'),
        stopType: Yup.string().required('Type  is required'),
      })
    ),
  });
  const addPichupAndDeliveryModal = Yup.object({
    date: Yup.string().required('Date  is required'),
    address: Yup.string().required('Location  is required'),
    sequenceNumber: Yup.string().required('Sequence number is required'),
    orders: Yup.string().required('Order is required'),
  });
  const dispatcherSchema = Yup.object({
    firstName: Yup.string().required('First Name  is required'),
    lastName: Yup.string().required('Last Name  is required'),
    email: Yup.string()
      .required('Email is required')
      .min(3, 'Email must be at least 3 characters')
      .max(30, 'Email cannot exceed 30 characters')
      .matches(emailRegEx, 'Invalid email format'),
    phone: Yup.string()
      .required('Contact number is required')
      .min(8, 'Phone number must be at least 5 digits')
      .max(18, 'Phone number cannot be more than 15 digits'),
    address: Yup.string().required('Address  is required'),
    cityId: Yup.string().required('City  is required'),
    stateId: Yup.string().required('State  is required'),
    countryId: Yup.string().required('Country  is required'),
    zipcodeId: Yup.string().required('Zip Code  is required'),
  });
  return {
    loginSchema,
    sendPasswordAtEmailSchema,
    newPasswordModalSchema,
    countryModalSchema,
    unitModalSchema,
    zipModalSchema,
    stateModalSchema,
    citiesModalSchema,
    currenciesModalSchema,
    currencyExchangeRateModalSchema,
    editLoadSchema,
    dispatcherSchema,
    timezoneModalSchema,
    vehicleTypeModalSchema,
    unitofMeasureModalSchema,
    addLoadSchema,
    vehicleMaintenanceModalSchema,
    addDriverProfileandInformationSchema,
    deviceTypeModalSchema,
    deviceModelModalSchema,
    roleModalScehema,
    menuModalScehema,
    addPichupAndDeliveryModal,
    pageModalScehema,
    rightsModalScehema,
    customerModalSchema,
    addOrderSchema,
    emailGatewayeModalSchema,
    featurePlansModalSchema,
    vehicleAssignmentModalSchema,
    addDriverDocumentSchema,
    associateSubPlanfeaturePlansModalSchema,
    smsGatewayeModalSchema,
    addUserSchema,
    addDeviceModalSchema,
    userModalSchema,
    EdituserModalSchema,
    subscriptionPlanseModalSchema,
    roleRightsModalSchema,
    vehicleDocumentSchema,
    assignPermissionScehema,
    resetPasswordModalSchema,
    generalModalSchema,
    privateModeSchema,
    configurationsModalSchema,
    deviceAssociationandConfigurationsSchema,
    stausModalSchema,
    SIMManagementModalSchema,
    generalTenantModalSchema,
    subscriptionManagementModalSchema,
    usersManagementModalSchema,
    emailTemplateModalSchema,
    assignDeviceModalSchema,
    vehicleInformationSchema,
    addVehicleModalSchema,
    assignedSubscriptionModalSchema,
    addSMSSettingModalSchema,
    editSMSSettingModalSchema,
    updateTenantModalSchema,
    driverLicenceFormSchema,
    trailerFormSchema,
  };
};
export default useValidationSchema;
