import { Button, Modal } from 'antd';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
import Checkbox from 'components/InputFields/Checkbox';
import ToastNotification from 'components/ToastNotification';
import { add_city } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createOptions } from 'utils/helper';
import useValidationSchema from 'validation/useValidationSchema';

const AddCityModal = ({ openAddCityModal, setOpenAddCityModal, refetch }) => {
  const { themeMode } = useSelector((state) => state?.states);
  const { useGetRequest, postRequest } = useApiClient();
  const [loading, setLoading] = useState(false);
  const { citiesModalSchema } = useValidationSchema();
  const formik = useFormik({
    initialValues: {
      cityId: '',
      countryId: '',
      stateId: '',
      isActive: false,
    },
    validationSchema: citiesModalSchema,
    onSubmit: ({ cityName, countryId, stateId, isActive }) => {
      setLoading(true);
      const paylaod = {
        name: cityName,
        countryId: countryId,
        stateId: stateId,
        isActive: isActive,
      };
      postRequest(add_city, paylaod)
        .then((res) => {
          if (res?.success) {
            refetch();
            formik.resetForm();
            ToastNotification('success', res.message, themeMode);
            setOpenAddCityModal(false);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )

        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCancel = () => {
    setOpenAddCityModal(false);
    formik.resetForm();
  };

  const { data: countryData } = useGetRequest(`/api/GetCountriesdropdown`, {
    refetchOnMount: 'always',
  });
  const { data: stateData, refetch: statesRefetch } = useGetRequest(
    `api/StateDropdown?Id=${formik.values.countryId}`,
    {
      enabled: false,
    }
  );
  const { data: cityData, refetch: cityRefetch } = useGetRequest(
    `api/CitiesDropdown?Id=${formik.values.stateId}`,
    {
      enabled: false,
    }
  );
  useEffect(() => {
    if (formik.values.countryId) statesRefetch();
    if (formik.values.stateId) cityRefetch();
  }, [formik.values.countryId, formik.values.stateId]);
  const countriesOptions = createOptions(
    countryData?.data,
    'name',
    'countryId'
  );
  const statesOptions = createOptions(stateData?.data, 'name', 'stateId');
  return (
    <>
      <Modal
        open={openAddCityModal}
        title={<span className=" text-[18px]">Add City</span>}
        width={400}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
            className="!shadow-none"
          >
            Submit
          </Button>,
        ]}
      >
        <>
          <div className=" grid grid-cols-12 gap-x-20 gap-y-5  py-[10px]">
            <div className=" col-span-12">
              <SelectField
                formik={formik}
                id={'countryId'}
                label={'Country'}
                required={true}
                optionId={'value'}
                optionName={'label'}
                optionData={countriesOptions}
                onChange={(newValue, _) => {
                  formik.setFieldValue('countryId', newValue);
                  formik.setFieldValue('stateId', null);
                  formik.setFieldValue('cityName', null);
                }}
              />
            </div>
            <div className=" col-span-12">
              <SelectField
                formik={formik}
                id={'stateId'}
                label={'State'}
                required={true}
                optionId={'value'}
                optionName={'label'}
                optionData={statesOptions}
                onChange={(newValue, _) => {
                  formik.setFieldValue('stateId', newValue);
                  formik.setFieldValue('cityName', null);
                }}
              />
            </div>
            <div className=" col-span-12">
              <TextField formik={formik} id={'cityName'} label={'City'} />
            </div>

            {/* copy paste */}
            {/* <div className=" col-span-6">
              <div className="wrapper-fields">
                <InputFieldCustomForm
                  label="First Name"
                  name="firstName"
                  value={formik.values.firstName}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  errorMessage={formik.errors.firstName}
                  required={true}
                >
                  <Input
                    id="firstName"
                    name="firstName"
                    prefix={<></>}
                    type="text"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{
                      border: !(
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      )
                        ? themeMode === 'light'
                          ? `1px solid ${themeInputColor.lightBorderColor}`
                          : `1px solid ${themeInputColor.darkBorderColor}`
                        : `1px solid ${themeInputColor.errorBorderColor}`,

                      borderRadius: '5px',
                      caretColor:
                        themeMode === 'light'
                          ? themeInputColor.lightCaretColor
                          : themeInputColor.darkCaretColor,
                      color:
                        themeMode === 'light'
                          ? themeInputColor.lightTextColor
                          : themeInputColor.darkTextColor,
                    }}
                  />
                </InputFieldCustomForm>
              </div>
            </div>
            <div className=" col-span-6">
              <div className="wrapper-fields">
                <InputFieldCustomForm
                  label="Single Selector"
                  name="lastName"
                  value={formik.values.lastName}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  errorMessage={formik.errors.lastName}
                  required={true}
                >
                  <Select
                    id="lastName"
                    name="lastName"
                    prefix={<></>}
                    mode="single"
                    value={formik.values.lastName}
                    onChange={(newValue, _) => {
                      formik.setFieldValue('lastName', newValue);
                    }}
                    onBlur={formik.handleBlur}
                     suffixIcon={
                      <SlArrowDown
                                               className="w-[12px] h-[12px] "
                        style={{
                          color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                        }}
                      />
                    }
                    style={{
                      border: !(
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      )
                        ? themeMode === 'light'
                          ? `1px solid ${themeInputColor.lightBorderColor}`
                          : `1px solid ${themeInputColor.darkBorderColor}`
                        : `1px solid ${themeInputColor.errorBorderColor}`,

                      borderRadius: '5px',
                      caretColor:
                        themeMode === 'light'
                          ? themeInputColor.lightCaretColor
                          : themeInputColor.darkCaretColor,
                      color:
                        themeMode === 'light'
                          ? themeInputColor.lightTextColor
                          : themeInputColor.darkTextColor,
                    }}
                  >
                    <Option                    
                          key="ssd" value="ssd">
                      bgfdvesds
                    </Option>
                    <Option 
                                          key="ssda" value="ssda">
                      bgfdveacds
                    </Option>
                    <Option                        key="ssdwa" value="ssdwa">
                      bgfdcss
                    </Option>
                    <Option                         key="ssdwe" value="ssdwe">
                      wcdsbgfds
                    </Option>
                  </Select>
                </InputFieldCustomForm>
              </div>
            </div>
            <div className=" col-span-6">
              <div className="wrapper-fields">
                <InputFieldCustomForm
                  label="Multiple Selector"
                  name="multipleSelector"
                  value={formik.values.multipleSelector}
                  error={
                    formik.touched.multipleSelector &&
                    Boolean(formik.errors.multipleSelector)
                  }
                  errorMessage={formik.errors.multipleSelector}
                  required={true}
                >
                  <Select
                    id="multipleSelector"
                    name="multipleSelector"
                    prefix={<></>}
                    mode="multiple"
                    value={formik.values.multipleSelector}
                    onChange={(newValue, _) => {
                      formik.setFieldValue('multipleSelector', newValue);
                    }}
                    onBlur={formik.handleBlur}
                     suffixIcon={
                      <SlArrowDown
                                               className="w-[12px] h-[12px] "
                        style={{
                          color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                        }}
                      />
                    }
                    style={{
                      border: !(
                        formik.touched.multipleSelector &&
                        Boolean(formik.errors.multipleSelector)
                      )
                        ? themeMode === 'light'
                          ? `1px solid ${themeInputColor.lightBorderColor}`
                          : `1px solid ${themeInputColor.darkBorderColor}`
                        : `1px solid ${themeInputColor.errorBorderColor}`,

                      borderRadius: '5px',
                      caretColor:
                        themeMode === 'light'
                          ? themeInputColor.lightCaretColor
                          : themeInputColor.darkCaretColor,
                      color:
                        themeMode === 'light'
                          ? themeInputColor.lightTextColor
                          : themeInputColor.darkTextColor,
                    }}
                  >
                    <Option key="ssd" value="ssd">
                      bgfdvesds
                    </Option>
                    <Option key="ssda" value="ssda">
                      bgfdveacds
                    </Option>
                    <Option key="ssdwa" value="ssdwa">
                      bgfdcss
                    </Option>
                    <Option key="ssdwe" value="ssdwe">
                      wcdsbgfds
                    </Option>
                  </Select>
                </InputFieldCustomForm>
              </div>
            </div>
            <div className=" col-span-6">
              <div className="wrapper-fields">
                <InputFieldCustomForm
                  label="Date Picker"
                  name="datePickerCustom"
                  value={formik.values.datePickerCustom}
                  error={
                    formik.touched.datePickerCustom &&
                    Boolean(formik.errors.datePickerCustom)
                  }
                  errorMessage={formik.errors.datePickerCustom}
                  required={true}
                >
                  <DatePicker
                    id="datePickerCustom"
                    name="datePickerCustom"
                    prefix={<></>}
                    placeholder=""
                    value={formik.values.datePickerCustom}
                    onChange={(newValue, _) => {
                      formik.setFieldValue('datePickerCustom', newValue);
                    }}
                    onBlur={formik.handleBlur}
                    style={{
                      border: !(
                        formik.touched.datePickerCustom &&
                        Boolean(formik.errors.datePickerCustom)
                      )
                        ? themeMode === 'light'
                          ? `1px solid ${themeInputColor.lightBorderColor}`
                          : `1px solid ${themeInputColor.darkBorderColor}`
                        : `1px solid ${themeInputColor.errorBorderColor}`,

                      borderRadius: '5px',
                      caretColor:
                        themeMode === 'light'
                          ? themeInputColor.lightCaretColor
                          : themeInputColor.darkCaretColor,
                      color:
                        themeMode === 'light'
                          ? themeInputColor.lightTextColor
                          : themeInputColor.darkTextColor,
                    }}
                  />
                </InputFieldCustomForm>
              </div>
            </div>
            <div className=" col-span-6">
              <div className="wrapper-fields">
                <InputFieldCustomForm
                  label="Date Range"
                  name="dateRangeCustom"
                  value={formik.values.dateRangeCustom}
                  error={
                    formik.touched.dateRangeCustom &&
                    Boolean(formik.errors.dateRangeCustom)
                  }
                  errorMessage={formik.errors.dateRangeCustom}
                  required={true}
                >
                  <RangePicker
                    id="dateRangeCustom"
                    name="dateRangeCustom"
                    prefix={<></>}
                    placeholder=""
                    value={formik.values.dateRangeCustom}
                    onChange={(newValue, _) => {
                      formik.setFieldValue('dateRangeCustom', newValue);
                    }}
                    onBlur={formik.handleBlur}
                    style={{
                      border: !(
                        formik.touched.dateRangeCustom &&
                        Boolean(formik.errors.dateRangeCustom)
                      )
                        ? themeMode === 'light'
                          ? `1px solid ${themeInputColor.lightBorderColor}`
                          : `1px solid ${themeInputColor.darkBorderColor}`
                        : `1px solid ${themeInputColor.errorBorderColor}`,

                      borderRadius: '5px',
                      caretColor:
                        themeMode === 'light'
                          ? themeInputColor.lightCaretColor
                          : themeInputColor.darkCaretColor,
                      color:
                        themeMode === 'light'
                          ? themeInputColor.lightTextColor
                          : themeInputColor.darkTextColor,
                    }}
                  />
                </InputFieldCustomForm>
              </div>
            </div> */}
            <div className="col-span-12 mt-[5px]">
              <Checkbox
                title="IsActive"
                id="isActive"
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                error={formik.errors.isActive}
                errorMessage={
                  formik.touched.isActive && formik.errors.isActive
                    ? formik.errors.isActive
                    : null
                }
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default AddCityModal;
