import { CgSortAz } from 'react-icons/cg';
import { CgSortZa } from 'react-icons/cg';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { MdDeleteOutline } from 'react-icons/md';
import { FaPlus } from 'react-icons/fa6';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Table from 'components/Table';
import { useNavigate } from 'react-router-dom';
import useApiClient from 'config/useAxiosClient';
import { useEffect, useState } from 'react';
import AddStateModal from './components/AddStateModal';
import { useSelector } from 'react-redux';
import EditStateModal from './components/EditStateModal';
import Status from 'components/Status';
import { get_states, delete_state } from 'config/services';
import { toast } from 'react-toastify';
import PopConfirm from 'components/PopConfirm/index';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { usePermissionVariables } from 'Hooks/HasPermission';
import SearchField from 'components/InputFields/SearchField';
import ToastNotification from 'components/ToastNotification';
import { getRowNumber } from 'utils/helper';

const States = () => {
  const { themeMode } = useSelector((state) => state?.states);
  const { States_Delete, States_Edit, States_Add, States_View } =
    usePermissionVariables();
  const [openAddStateModal, setOpenAddStateModal] = useState(false);
  const [openEditStateModal, setOpenEditStateModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({
    stateName: '',
    countryId: '',
    stateId: '',
    isActive: false,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const { useGetRequest, deleteRequest } = useApiClient();
  const [totalRecord, setTotalRecord] = useState(10);
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { data, isLoading, refetch } = useGetRequest(
    `api/GetAllStates?pagenumber=${pageNumber}&pagesize=${rowsPerPage}&searchquery=${search}`
  );

  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const delHandler = (record) => {
    deleteRequest(`${delete_state}${record?.stateId}`)
      .then((res) => {
        if (res?.success) {
          const newTotalRecord = totalRecord - 1; // Decrement the total record count
          setTotalRecord(newTotalRecord); // Update the state

          // Check if we need to change the page number
          if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1); // Redirect to the previous page
          }
          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };
  const navigate = useNavigate();
  const columns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      key: 'sr',
      ellipsis: true,
      width: 80,
      render: (text, row, index) =>
        getRowNumber(pageNumber, rowsPerPage, index),
    },
    {
      title: (
        <div className="flex justify-between items-center">State Name</div>
      ),
      ellipsis: true,
      dataIndex: 'name',
      sorter: (a, b) => a?.name?.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'name' ? sortOrder.order : 'ascend',
      showSorterTooltip: false,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: 'Country Name',
      dataIndex: 'countryName',
      key: 'countryName',
      ellipsis: true,
    },
    {
      title: <span className="flex items-center justify-center">Status</span>,
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      width: 300,
      ellipsis: true,
      render: (tag, record) => {
        return (
          <span>
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },
    ...(States_Edit || States_Delete
      ? [
          {
            title: (
              <span className=" flex items-center justify-center">Action</span>
            ),
            dataIndex: '',
            ellipsis: true,
            width: 100,
            render: (text, record) => (
              <span className="flex justify-center items-center space-x-2">
                {States_Edit && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                    onClick={() => {
                      setSelectedRecord({
                        stateName: record?.name,
                        countryId: record?.countryId,
                        stateId: record?.stateId,
                        isActive: record?.isActive,
                      });
                      setOpenEditStateModal(true);
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                )}
                {States_Delete && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this state? "
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    >
                      <MdDeleteOutline className=" w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                )}
              </span>
            ),
          },
        ]
      : []),
  ];
  useEffect(() => {
    if (data) {
      setTotalRecord(data?.totalRecords);
      refetch();
    }
  }, [data]);
  return (
    <div>
      <div className="md:flex space-y-[20px] md:space-y-0 justify-between mt-10 mb-26">
        <SearchField
          htmlFor="search"
          id="myInputID"
          name="search"
          placeholder={'Search by State Name'}
          value={debouncedSearch}
          setDebouncedSearch={setDebouncedSearch}
          setPageNumber={setPageNumber}
          searchLoading={searchLoading}
          setSearchLoading={setSearchLoading}
          refetch={refetch}
          debouncedSearch={debouncedSearch}
          setSearch={setSearch}
        />
        {States_Add && (
          <div className="sm:flex justify-end">
            <PrimaryButton
              title="Add State"
              icon={<FaPlus />}
              onClick={() => setOpenAddStateModal(true)}
            />
          </div>
        )}
      </div>
      <Table
        data={data?.data}
        columns={columns}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        setTotalRecord={setTotalRecord}
        totalRecord={totalRecord}
        setPageNumber={setPageNumber}
        setRowsPerPage={setRowsPerPage}
        setSortOrder={setSortOrder}
        setFilters={setFilters}
        loading={isLoading}
      />
      {openAddStateModal && (
        <AddStateModal
          setOpenAddStateModal={setOpenAddStateModal}
          openAddStateModal={openAddStateModal}
          refetch={refetch}
        />
      )}
      {openEditStateModal && (
        <EditStateModal
          setOpenEditStateModal={setOpenEditStateModal}
          openEditStateModal={openEditStateModal}
          selectedRecord={selectedRecord}
          refetch={refetch}
        />
      )}
    </div>
  );
};
export default States;
