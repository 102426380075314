import { QuestionCircleOutlined } from '@ant-design/icons';
import { Breadcrumb, Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Dropdown from 'components/Dropdown/index';
import SearchField from 'components/InputFields/SearchField';
import PopConfirm from 'components/PopConfirm';
import PopOver from 'components/PopOver';
import Status from 'components/Status';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import useAxiosClientTMS from 'config/useAxiosClientTMS';
import { usePermissionVariables } from 'Hooks/HasPermission';
import Layout from 'layout/AdminLayout';
import { useEffect, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaPlus } from 'react-icons/fa6';
import { GoHome } from 'react-icons/go';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { PiSteeringWheel } from 'react-icons/pi';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { convertDateFormat } from 'utils/helper';
import { GET_DRIVERS_KEY, GET_ORDERS_KEY } from 'utils/queryKey';
const { Title } = Typography;

const Driver = () => {
  const { useGetRequest, deleteRequest } = useAxiosClientTMS();
  const { Drivers_Add, Drivers_Edit, Drivers_Delete } =
    usePermissionVariables();

  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  // States
  const [openAddUserModal, setOpenUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({});
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const { data, error, isLoading, refetch } = useGetRequest(
    `/api/GetAlLdrivers?pagenumber=${pageNumber}&pagesize=${rowsPerPage}&searchquery=${search}`,
    {},
    GET_DRIVERS_KEY
  );
  const delHandler = (record) => {
    deleteRequest(`api/DeleteDriver?id=${record?.driverId}`)
      .then((res) => {
        if (res?.success) {
          const newTotalRecord = totalRecord - 1; // Decrement the total record count
          setTotalRecord(newTotalRecord); // Update the state

          // Check if we need to change the page number
          if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1); // Redirect to the previous page
          }
          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };

  const htmlConverter = (record) => (
    <div style={{ fontSize: '10px' }}>
      <div>
        <div>
          <p className="flex justify-between">
            <span>
              <b>License Details</b>
            </span>
          </p>
          <p className="flex justify-between">
            <span>Type: </span>
            <span>{record?.driverLicenseReponse?.licenseType}</span>
          </p>
          <p className="flex justify-between">
            <span>Number: </span>
            <span>{record?.driverLicenseReponse?.licenseNumber}</span>
          </p>
          <p className="flex justify-between">
            <span>Issue Date: </span>
            <span>
              {convertDateFormat(record?.driverLicenseReponse?.issueDate)}
            </span>
          </p>
          <p className="flex justify-between">
            <span>Expiry Date: </span>
            <span>
              {convertDateFormat(record?.driverLicenseReponse?.expiryDate)}
            </span>
          </p>
          <p className="flex justify-between">
            <span>Status: </span>
            <span>
              <Status
                tag={record?.driverLicenseReponse?.isActive}
                className="m-0 text-[10px]"
              />
            </span>
          </p>
        </div>
      </div>
    </div>
  );

  const columns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      ellipsis: true,
      width: 80,
      render: (text, row, index) => <>{index + 1}</>,
    },
    {
      title: <div className="flex justify-between items-center">Name</div>,
      dataIndex: 'firstName',
      ellipsis: true,
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder:
        sortOrder?.column?.dataIndex === 'firstName' ? sortOrder.order : null,

      render: (text, record) => {
        return (
          <Link
            to={`/driver-detail/${record?.driverId}`}
            className="hover:text-inherit"
          >
            <span className="underline cursor-pointer">{`${
              record?.firstName ?? ''
            } ${record?.lastName ?? ''}`}</span>
          </Link>
        );
      },
    },
    {
      title: <div className="flex justify-between items-center">Email</div>,
      dataIndex: 'email',
      ellipsis: true,
      // align: 'center',
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder:
        sortOrder?.column?.dataIndex === 'email' ? sortOrder.order : null,
      render: (text, record) => (
        <>
          <a
            className="!underline cursor-pointer hover:underline hover:text-inherit"
            href={`mailto:${text}`}
            // target="_blank"
            rel="noopener noreferrer"
          >
            {text}
          </a>
        </>
      ),
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      ellipsis: true,
      sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder:
        sortOrder?.column?.dataIndex === 'phoneNumber' ? sortOrder.order : null,

      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: 'Joining Date',
      dataIndex: 'joiningDate',
      ellipsis: true,
      sorter: (a, b) => a.joiningDate.localeCompare(b.joiningDate),
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder:
        sortOrder?.column?.dataIndex === 'joiningDate' ? sortOrder.order : null,

      render: (text, record) => (
        <>
          <span>{convertDateFormat(text)}</span>
        </>
      ),
    },
    {
      title: 'License Number',
      dataIndex: ['driverLicenseReponse', 'licenseNumber'],
      ellipsis: true,
      render: (text, record) => {
        const content = htmlConverter(record);
        return (
          <span>
            <PopOver content={content} title={''} placement="top" width={160}>
              <div className="!underline cursor-pointer hover:underline hover:text-inherit">
                <span>{text}</span>
              </div>``
            </PopOver>
          </span>
        );
      },
    },
    {
      title: (
        <span className="flex items-center justify-center">Driver Status</span>
      ),
      dataIndex: 'isActive',
      ellipsis: true,
      render: (tag, record) => {
        return (
          <span className="flex items-center justify-center">
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },

    ...(Drivers_Edit || Drivers_Delete
      ? [
          {
            title: (
              <span className="flex items-center justify-center">Action</span>
            ),
            dataIndex: '',
            ellipsis: true,
            width: 80,
            render: (text, record) => (
              <div className="flex justify-center">
                <Dropdown
                  overlayClassName={`${
                    themeMode === 'light'
                      ? 'action-dropdown-light'
                      : 'action-dropdown-dark'
                  }`}
                  items={[
                    ...(Drivers_Edit
                      ? [
                          {
                            key: '1',
                            label: (
                              <div
                                className="p-[2px] rounded-sm"
                                style={{
                                  backgroundColor:
                                    themeMode === 'light' ? 'white' : '#121212',
                                }}
                              >
                                <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                              </div>
                            ),
                            onClick: () => {
                              navigate(`/edit-driver/${record?.driverId}`, {
                                state: {
                                  pageNumber: pageNumber,
                                  rowsPerPage: rowsPerPage,
                                },
                              });
                            },
                          },
                        ]
                      : []),
                    ...(Drivers_Delete
                      ? [
                          {
                            key: '2',
                            label: (
                              <div
                                className="p-[2px] rounded-sm"
                                style={{
                                  backgroundColor:
                                    themeMode === 'light' ? 'white' : '#121212',
                                }}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <PopConfirm
                                  title="Delete"
                                  description="Are you sure to delete this task?"
                                  placement="left"
                                  onClick={() => delHandler(record)}
                                  icon={
                                    <div className="flex items-center justify-center">
                                      <QuestionCircleOutlined
                                        style={{
                                          color: 'red',
                                          marginTop: '5px',
                                        }}
                                      />
                                    </div>
                                  }
                                >
                                  <MdDeleteOutline className="w-[15px] h-[15px] cursor-pointer" />
                                </PopConfirm>
                              </div>
                            ),
                          },
                        ]
                      : []),
                  ]}
                >
                  <div className="!underline cursor-pointer hover:underline">
                    <BsThreeDotsVertical className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                </Dropdown>
              </div>
            ),
          },
        ]
      : []),
  ];
  // useEffect(() => {
  //   refetch();
  // }, []);

  useEffect(() => {
    if (data) {
      setTotalRecord(data?.totalRecords);
    }
  }, [data]);

  return (
    <Layout>
      <>
        <div>
          <div className=" mb-[30px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
              }}
            >
              TMS
            </Title>
            <div className="flex justify-between items-center ">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className="flex items-center space-x-4 ">
                            <PiSteeringWheel
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />

                            <span
                              className="font-bold text-[13px]"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              Driver Management
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="md:flex space-y-[20px] md:space-y-0 justify-between mt-10 mb-26">
              <SearchField
                htmlFor="search"
                id="myInputIDDriverName"
                name="search"
                placeholder={'Search by Driver Name'}
                value={debouncedSearch}
                setDebouncedSearch={setDebouncedSearch}
                setPageNumber={setPageNumber}
                searchLoading={searchLoading}
                setSearchLoading={setSearchLoading}
                refetch={refetch}
                debouncedSearch={debouncedSearch}
                setSearch={setSearch}
              />
              {Drivers_Add && (
                <div className="sm:flex justify-end">
                  <PrimaryButton
                    title="Add Driver"
                    icon={<FaPlus />}
                    onClick={() => {
                      navigate(`/add-driver`, {
                        state: {
                          pageNumber: pageNumber,
                          rowsPerPage: rowsPerPage,
                        },
                      });
                    }}
                  />
                </div>
              )}
            </div>
            <Table
              data={data?.data || []}
              columns={columns}
              pageNumber={pageNumber}
              setTotalRecord={setTotalRecord}
              rowsPerPage={rowsPerPage}
              totalRecord={totalRecord}
              setPageNumber={setPageNumber}
              setRowsPerPage={setRowsPerPage}
              setSortOrder={setSortOrder}
              setFilters={setFilters}
              loading={isLoading}
            />
          </div>
        </div>
      </>
    </Layout>
  );
};
export default Driver;
