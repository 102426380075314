import React, { useEffect, useState } from 'react';
import Layout from 'layout/AdminLayout';
import { Breadcrumb, Typography, Tabs } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GoHome } from 'react-icons/go';
import { HiDocumentText } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import Role from './components/Role';
import Pages from './components/Pages';
import Menu from './components/Menu';
import RoleRights from './components/RoleRights';
import { usePermissionVariables } from 'Hooks/HasPermission';

const { Title } = Typography;

const RoleManagement = () => {
  const { Role_View, Menu_View, Pages_View, Role_Rights_View } =
    usePermissionVariables();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  const items = [
    Role_View && {
      key: '1',
      label: 'Role',
      children: <Role />,
    },
    Menu_View && {
      key: '2',
      label: 'Menu',
      children: <Menu />,
    },
    Pages_View && {
      key: '3',
      label: 'Pages',
      children: <Pages />,
    },
    Role_Rights_View && {
      key: '4',
      label: 'Role Rights',
      children: <RoleRights />,
    },
  ].filter(Boolean);

  const [activeKey, setActiveKey] = useState(() => {
    const keyFromURL = searchParams.get('active') || '1';
    return items?.some((item) => item.key === keyFromURL) ? keyFromURL : '1';
  });

  useEffect(() => {
    const keyFromURL = searchParams.get('active') || '1';
    setActiveKey(
      items?.some((item) => item.key === keyFromURL) ? keyFromURL : '1'
    );
  }, [searchParams, items]);

  const onChange = (key) => {
    setActiveKey(key);
    navigate(`/role-management?active=${key}`);
  };

  return (
    <Layout>
      <div>
        <div className=" mb-[30px] ">
          <Title
            level={4}
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
              fontFamily: 'inter-bold',
            }}
          >
            Security
          </Title>
          <div className="flex justify-between items-center mb-20">
            <Breadcrumb
              separator="/"
              items={[
                {
                  title: (
                    <div className="flex items-center space-x-4">
                      <GoHome />
                      <span
                        className="cursor-pointer font-reg text-[13px]"
                        onClick={() => navigate('/dashboard')}
                      >
                        Dashboard
                      </span>
                    </div>
                  ),
                },
                {
                  title: (
                    <div className="flex items-center space-x-4">
                      <HiDocumentText
                        style={{
                          color: themeMode === 'light' ? colorTheme : '',
                        }}
                      />
                      <span
                        className="font-bold text-[13px]"
                        style={{
                          color: themeMode === 'light' ? colorTheme : '',
                        }}
                      >
                        Role Management
                      </span>
                    </div>
                  ),
                },
              ]}
            />
          </div>
          <div className="mt-6">
            <Tabs
              activeKey={activeKey}
              type="card"
              items={items}
              onChange={onChange}
              size="small"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RoleManagement;
