import React, { useState } from 'react';
import { Button, Modal, Input } from 'antd';
import { useFormik } from 'formik';
import useValidationSchema from 'validation/useValidationSchema';
import { themeInputColor } from 'utils/constant';
import InputFieldCustomForm from 'components/CustomFields/CustomFieldsOutlinedForm';
import { useSelector } from 'react-redux';
import useApiClient from 'config/useAxiosClient';
import { useQueryClient } from 'react-query';
import { get_all_timezones, add_timezone } from 'config/services';
import { toast } from 'react-toastify';
import ToastNotification from 'components/ToastNotification';
import TextField from 'components/CustomFields/TextField';
import Checkbox from 'components/InputFields/Checkbox';

const AddTimeZonesModal = ({
  openAddTimeZonesModal,
  setOpenAddTimeZonesModal,
  refetch,
}) => {
  const { themeMode } = useSelector((state) => state?.states);
  const { postRequest } = useApiClient();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const { timezoneModalSchema } = useValidationSchema();
  const formik = useFormik({
    initialValues: {
      name: '',
      offset: '',
      isActive: false,
    },
    validationSchema: timezoneModalSchema,
    onSubmit: ({ name, offset,isActive }) => {
      setLoading(true);
      const paylaod = {
        name: name,
        offset: offset,
        isActive: isActive,
      };
      postRequest(add_timezone, paylaod)
        .then((res) => {
          if (res?.success) {
            refetch();
            formik.resetForm();
            ToastNotification('success', res.message, themeMode);
            setOpenAddTimeZonesModal(false);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCancel = () => {
    setOpenAddTimeZonesModal(false);
    formik.resetForm();
  };
  return (
    <>
      <Modal
        open={openAddTimeZonesModal}
        title={<span className=" text-[18px]">Add Time Zone</span>}
        width={400}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
            className="!shadow-none"
          >
            Submit
          </Button>,
        ]}
      >
        <>
          <div className=" grid grid-cols-12 gap-x-20 gap-y-5 py-[10px]">
            <div className="col-span-12">
              <TextField
                formik={formik}
                id={'name'}
                label={'Name'}
                maxLength={30}
              />
            </div>
            <div className="col-span-12">
              <TextField formik={formik} id={'offset'} label={'Offset'} />
            </div>
            <div className="col-span-12 mt-5">
              <Checkbox
                title="IsActive"
                id="isActive"
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                error={formik.errors.isActive}
                errorMessage={
                  formik.touched.isActive && formik.errors.isActive
                    ? formik.errors.isActive
                    : null
                }
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default AddTimeZonesModal;
