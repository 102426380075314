import { Button, Modal } from 'antd';
import TextField from 'components/CustomFields/TextField';
import Checkbox from 'components/InputFields/Checkbox';
import TextArea from 'components/InputFields/TextArea';
import ToastNotification from 'components/ToastNotification';
import { update_sms_template } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import useValidationSchema from 'validation/useValidationSchema';

const EditSMSTemplateModal = ({
  openEditSMSTemplateModal,
  setOpenEditSMSTemplateModal,
  selectedRecord,
  refetch,
}) => {
  const { themeMode } = useSelector((state) => state?.states);
  const { putRequest } = useApiClient();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const { smsGatewayeModalSchema } = useValidationSchema();
  const formik = useFormik({
    initialValues: {
      templateName: '',
      templateCode: '',
      language: '',
      isActive: false,
      templateBody: '',
    },
    validationSchema: smsGatewayeModalSchema,
    onSubmit: ({
      templateName,
      templateCode,
      language,
      isActive,
      templateBody,
    }) => {
      setLoading(true);
      const paylaod = {
        templateId: selectedRecord?.templateId,
        tenantId: 0,
        templateName,
        templateCode,
        language,
        isActive,
        templateBody,
      };
      putRequest(update_sms_template, paylaod)
        .then((res) => {
          if (res?.success) {
            openEditSMSTemplateModal(false);
            // queryClient.invalidateQueries(get_sms_templates_by_tenant_id + 0);
            refetch();
            formik.resetForm();
            ToastNotification('success', res.message, themeMode);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) => {
          ToastNotification('error', 'Something went wrongwwwwwww', themeMode);
          console.log('sssssssssssss');
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCancel = () => {
    setOpenEditSMSTemplateModal(false);
    formik.resetForm();
  };
  useEffect(() => {
    if (selectedRecord) {
      formik.setValues({
        templateName: selectedRecord?.templateName,
        templateCode: selectedRecord?.templateCode,
        language: selectedRecord?.language,
        isActive: selectedRecord?.isActive,
        templateBody: selectedRecord?.templateBody,
      });
    }
  }, [selectedRecord, setOpenEditSMSTemplateModal]);
  return (
    <>
      <Modal
        open={openEditSMSTemplateModal}
        title={<span className=" text-[18px]">Edit SMS Template</span>}
        width={600}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <>
          <div className=" grid grid-cols-12 gap-x-20 gap-y-5  py-[10px]">
            <div className="sm:col-span-6 col-span-12">
              <TextField
                formik={formik}
                id={'templateName'}
                label={'Template Name'}
                maxLength={30}
              />
            </div>
            <div className="sm:col-span-6 col-span-12">
              <TextField
                formik={formik}
                id={'templateCode'}
                label={'Template Code'}
                maxLength={30}
              />
            </div>
            <div className="sm:col-span-6 col-span-12">
              <TextField
                formik={formik}
                id={'language'}
                label={'Language'}
                maxLength={30}
              />
            </div>
            <div className="sm:col-span-6 col-span-12 flex items-center">
              <Checkbox
                title="IsActive"
                id="isActive"
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                error={formik.errors.isActive}
                errorMessage={
                  formik.touched.isActive && formik.errors.isActive
                    ? formik.errors.isActive
                    : null
                }
              />
            </div>
            <div className=" col-span-12 mt-[5px]">
              <div className="wrapper-fields">
                <TextArea
                  id="templateBody"
                  name="templateBody"
                  rows={4}
                  placeholder="Template Body"
                  required={true}
                  maxLength={250}
                  value={formik.values.templateBody}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldTouched('templateBody', true, false);
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.templateBody &&
                    Boolean(formik.errors.templateBody)
                  }
                  errorMessage={
                    formik.touched.templateBody && formik.errors.templateBody
                      ? formik.errors.templateBody
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default EditSMSTemplateModal;
