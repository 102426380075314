import { usePermissionVariables } from 'Hooks/HasPermission';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SearchField from 'components/InputFields/SearchField';
import Status from 'components/Status';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import { delete_menu } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import AddMenuModal from './components/AddMenuModal';
import EditMenuModal from './components/EditMenuModal';
import { getRowNumber } from 'utils/helper';

const Menu = () => {
  const { useGetRequest, deleteRequest } = useApiClient();
  const { Menu_View, Menu_Add, Menu_Edit, Menu_Delete } =
    usePermissionVariables();

  const { themeMode } = useSelector((state) => state?.states);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  // States
  const [pageNumber, setPageNumber] = useState(1);
  const [openAddMenuModal, setOpenMenuModal] = useState(false);
  const [openEditMenuModal, setOpenEditMenuModal] = useState(false);
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [sortOrder, setSortOrder] = useState({});
  const [rowData, setRowData] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const { data, refetch, isLoading } = useGetRequest(
    `/api/AppMenus/GetAllMenus?pagenumber=${pageNumber}&pagesize=${rowsPerPage}&searchquery=${search}`
  );

  const delHandler = (record) => {
    deleteRequest(`${delete_menu}${record?.menuId}`)
      .then((res) => {
        // if (res?.success) {
        refetch();
        // toast.success(res.message);
        ToastNotification('success', res.message, themeMode);
        // } else {
        //   toast.error(res.message);
        // }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };
  const columns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      key: 'sr',
      ellipsis: true,
      width: 80,
      render: (text, row, index) =>
        getRowNumber(pageNumber, rowsPerPage, index),
    },
    {
      title: (
        <div className="flex justify-between items-center">
          <div className=" whitespace-nowrap"> Menu Name </div>
        </div>
      ),
      showSorterTooltip: false,
      dataIndex: 'menuName',
      sorter: (a, b) => a.menuName.localeCompare(b.menuName),
      sortDirections: ['ascend', 'descend'],
      ellipsis: true,
      sortOrder:
        sortOrder?.column?.dataIndex === 'menuName'
          ? sortOrder.order
          : 'ascend',

      render: (text, record) => (
        <>
          <span className="capitalize">{text}</span>
        </>
      ),
    },

    {
      title: <span className="flex items-center justify-center">Status</span>,
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      ellipsis: true,
      width: 300,
      render: (tag, record) => {
        return (
          <span>
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },
    ...(Menu_Edit || Menu_Delete
      ? [
          {
            title: (
              <span className=" flex items-center justify-center">Action</span>
            ),
            dataIndex: '',
            ellipsis: true,
            width: 100,
            render: (text, record) => (
              <span className=" flex justify-center items-center space-x-2">
                {Menu_Edit && (
                  <div
                    className="  p-[5px] rounded-sm "
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                    onClick={() => {
                      setRowData(record);
                      setOpenEditMenuModal(true);
                    }}
                  >
                    <MdOutlineModeEditOutline className="  w-[15px] h-[15px] cursor-pointer" />
                  </div>
                )}

                {/* <div
                  className="p-[5px] rounded-sm"
                  style={{
                    backgroundColor:
                      themeMode === 'light' ? 'white' : '#121212',
                  }}
                >
                  <PopConfirm
                    title="Delete"
                    description="Are you sure to delete this task? "
                    placement="left"
                    onClick={() => delHandler(record)}
                    icon={
                      <div className=" flex items-center justify-center ">
                        <QuestionCircleOutlined
                          style={{ color: 'red', marginTop: '5px' }}
                        />
                      </div>
                    }
                  >
                    <MdDeleteOutline className=" w-[15px] h-[15px] cursor-pointer" />
                  </PopConfirm>
                </div> */}
              </span>
            ),
          },
        ]
      : []),
  ];
  useEffect(() => {
    if (data) {
      setTotalRecord(data?.totalRecords);
    }
  }, [data]);

  return (
    <>
      <div className="sm:flex space-y-[20px] md:space-y-0 justify-between items-center  mt-10 mb-26">
        <SearchField
          htmlFor="search"
          id="myInputID"
          name="search"
          placeholder={'Search by Name'}
          value={debouncedSearch}
          setDebouncedSearch={setDebouncedSearch}
          setPageNumber={setPageNumber}
          searchLoading={searchLoading}
          setSearchLoading={setSearchLoading}
          refetch={refetch}
          debouncedSearch={debouncedSearch}
          setSearch={setSearch}
        />

        {Menu_Add && (
          <div className="sm:flex justify-end">
            <PrimaryButton
              title="Add Menu"
              icon={<FaPlus />}
              onClick={() => {
                setOpenMenuModal(true);
              }}
            />
          </div>
        )}
      </div>

      <div className="">
        <Table
          data={data?.data || []}
          columns={columns}
          pageNumber={pageNumber}
          rowsPerPage={rowsPerPage}
          setTotalRecord={setTotalRecord}
          totalRecord={totalRecord}
          setPageNumber={setPageNumber}
          setRowsPerPage={setRowsPerPage}
          loading={isLoading}
          setSortOrder={setSortOrder}
          setFilters={setFilters}
        />
      </div>
      {openAddMenuModal && (
        <AddMenuModal
          setOpenMenuModal={setOpenMenuModal}
          openAddMenuModal={openAddMenuModal}
          refetch={refetch}
        />
      )}
      {openEditMenuModal && rowData && (
        <EditMenuModal
          setOpenEditMenuModal={setOpenEditMenuModal}
          openEditMenuModal={openEditMenuModal}
          record={rowData}
          refetch={refetch}
        />
      )}
    </>
  );
};
export default Menu;
