import { Button, Modal, Select } from 'antd';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
import Checkbox from 'components/InputFields/Checkbox';
import TextArea from 'components/InputFields/TextArea';
import ToastNotification from 'components/ToastNotification';
import {
  add_device_model,
  get_device_models,
  get_device_types_by_dropdown,
} from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import useValidationSchema from 'validation/useValidationSchema';

const AddDeviceModelModal = ({
  openAddDeviceModelModal,
  setOpenAddDeviceModelModal,
  refetch,
}) => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { postRequest, useGetRequest } = useApiClient();
  const queryClient = useQueryClient();
  const { Option } = Select;
  const { data: deviceTypes } = useGetRequest(get_device_types_by_dropdown, {
    refetchOnMount: 'always',
  });

  const [loading, setLoading] = useState(false);
  const { deviceModelModalSchema } = useValidationSchema();
  const formik = useFormik({
    initialValues: {
      deviceTypeId: '',
      deviceModelName: '',
      description: '',
    },
    validationSchema: deviceModelModalSchema,
    onSubmit: ({ deviceTypeId, deviceModelName, description, isActive }) => {
      setLoading(true);
      const paylaod = {
        name: deviceModelName,
        description: description,
        deviceTypeId: deviceTypeId,
        isActive: isActive || false,
      };
      postRequest(add_device_model, paylaod)
        .then((res) => {
          if (res?.success) {
            queryClient.invalidateQueries(get_device_models);
            formik.resetForm();
            refetch();
            setOpenAddDeviceModelModal(false);
            ToastNotification('success', res.message, themeMode);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCancel = () => {
    setOpenAddDeviceModelModal(false);
    formik.resetForm();
  };

  return (
    <>
      <Modal
        open={openAddDeviceModelModal}
        title={<span className=" text-[18px]">Add Device Model</span>}
        width={400}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
            className="!shadow-none"
          >
            Submit
          </Button>,
        ]}
      >
        <>
          <div className=" grid grid-cols-12 gap-y-5 gap-x-20 py-[10px]">
            <div className=" col-span-12">
              <TextField
                formik={formik}
                id={'deviceModelName'}
                label={'Device Model Name'}
                maxLength={30}
              />
            </div>
            <div className=" col-span-12">
              <SelectField
                formik={formik}
                id={'deviceTypeId'}
                label={'Device Type'}
                optionId={'deviceTypeId'}
                optionName={'name'}
                optionData={deviceTypes?.data}
              />
            </div>
            <div className=" col-span-12">
              <div className="wrapper-fields">
                <TextArea
                  id="description"
                  name="description"
                  rows={4}
                  required={true}
                  placeholder="Description"
                  maxLength={250}
                  value={formik.values.description}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldTouched('description', true, false);
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  errorMessage={
                    formik.touched.description && formik.errors.description
                      ? formik.errors.description
                      : null
                  }
                />
              </div>
            </div>
            <div className="col-span-12 mt-[5px]">
              <Checkbox
                title="IsActive"
                id="isActive"
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                error={formik.errors.isActive}
                errorMessage={
                  formik.touched.isActive && formik.errors.isActive
                    ? formik.errors.isActive
                    : null
                }
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default AddDeviceModelModal;
