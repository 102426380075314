import { QuestionCircleOutlined } from '@ant-design/icons';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SearchField from 'components/InputFields/SearchField';
import PopConfirm from 'components/PopConfirm/index';
import PopOver from 'components/PopOver';
import Status from 'components/Status/index';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import useAxiosClientTMS from 'config/useAxiosClientTMS';
import dayjs from 'dayjs';
import { usePermissionVariables } from 'Hooks/HasPermission';
import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getRowNumber } from 'utils/helper';
import AddVehicleMaintenanceModal from './components/AddVehicleMaintenanceModal';
import EditVehicleMaintenanceModal from './components/EditVehicleMaintenanceModal';

const VehicleMaintenance = () => {
  const {
    Vehicle_Maintenance_Delete,
    Vehicle_Maintenance_Edit,
    Vehicle_Maintenance_Add,
  } = usePermissionVariables();
  const [openAddVehicleMaintenanceModal, setOpenAddVehicleMaintenanceModal] =
    useState(false);
  const [openEditVehicleMaintenanceModal, setOpenEditVehicleMaintenanceModal] =
    useState(false);
  const { tenantId, vehicleId } = useParams();
  const [rowData, setRowData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const { useGetRequest, deleteRequest } = useAxiosClientTMS();
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const { data, isLoading, refetch } = useGetRequest(
    `/api/GetAllVehicleMaintenance?pagenumber=${pageNumber}&pagesize=${rowsPerPage}&searchquery=${search}&tenantId=${tenantId}`
  );
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const navigate = useNavigate();

  const delHandler = (record) => {
    deleteRequest(`/api/DeleteVehicleMaintenance?id=${record?.maintenanceId}`)
      .then((res) => {
        if (res?.success) {
          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };

  const columns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      key: 'sr',
      ellipsis: true,
      width: 80,
      render: (text, row, index) =>
        getRowNumber(pageNumber, rowsPerPage, index),
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      key: 'cost',
      ellipsis: true,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: 'Maintenance Type',
      dataIndex: 'maintenanceType',
      key: 'maintenanceType',
      ellipsis: true,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: 'Maintenance Date',
      dataIndex: 'maintenanceDate',
      key: 'maintenanceDate',
      ellipsis: true,
      render: (text, record) => (
        <>
          <span>{dayjs(text).format('DD-MM-YYYY')}</span>
        </>
      ),
    },
    {
      title: 'Next Scheduled Date',
      dataIndex: 'nextScheduledDate',
      key: 'nextScheduledDate',
      ellipsis: true,
      render: (text, record) => (
        <>
          <span>{dayjs(text).format('DD-MM-YYYY')}</span>
        </>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      className: 'max-w-[250px]',
      ellipsis: true,
      render: (text, record) => (
        <span className="">
          <PopOver content={text} title={'Description'} width={'40%'}>
            <div className="truncate">{text}</div>
          </PopOver>
        </span>
      ),
    },
    {
      title: <span className="flex items-center justify-center">Status</span>,
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      width: 300,
      ellipsis: true,
      render: (tag, record) => {
        return (
          <span>
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },
    ...(Vehicle_Maintenance_Edit || Vehicle_Maintenance_Delete
      ? [
          {
            title: (
              <span className=" flex items-center justify-center">Action</span>
            ),
            dataIndex: '',
            ellipsis: true,
            width: 100,
            render: (text, record) => (
              <span className="flex justify-center items-center space-x-2">
                {Vehicle_Maintenance_Edit && (
                  <div
                    className=" p-[5px] rounded-sm"
                    onClick={() => {
                      setRowData(record);
                      setOpenEditVehicleMaintenanceModal(true);
                    }}
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                )}
                {Vehicle_Maintenance_Delete && (
                  <div
                    className=" p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this task? "
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    >
                      <MdDeleteOutline className=" w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                )}
              </span>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (data) {
      setTotalRecord(data?.totalRecords);
    }
  }, [data]);

  return (
    <>
      <div className="md:flex space-y-[20px] md:space-y-0 justify-between mt-10 mb-26">
        <SearchField
          htmlFor="search"
          id="myInputID"
          name="search"
          placeholder={'Search by Maintenance Type'}
          value={debouncedSearch}
          setDebouncedSearch={setDebouncedSearch}
          setPageNumber={setPageNumber}
          searchLoading={searchLoading}
          setSearchLoading={setSearchLoading}
          refetch={refetch}
          debouncedSearch={debouncedSearch}
          setSearch={setSearch}
        />
        {Vehicle_Maintenance_Add && (
          <div className="">
            <PrimaryButton
              title="Add Vehicle Maintenance"
              icon={<FaPlus />}
              onClick={() => setOpenAddVehicleMaintenanceModal(true)}
            />
          </div>
        )}
      </div>

      <Table
        data={data?.data}
        columns={columns}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        setTotalRecord={setTotalRecord}
        totalRecord={totalRecord}
        setPageNumber={setPageNumber}
        setRowsPerPage={setRowsPerPage}
        setSortOrder={setSortOrder}
        setFilters={setFilters}
        loading={isLoading}
      />
      {openAddVehicleMaintenanceModal && (
        <AddVehicleMaintenanceModal
          setOpenAddVehicleMaintenanceModal={setOpenAddVehicleMaintenanceModal}
          openAddVehicleMaintenanceModal={openAddVehicleMaintenanceModal}
          refetch={refetch}
        />
      )}
      {openEditVehicleMaintenanceModal && (
        <EditVehicleMaintenanceModal
          setOpenEditVehicleMaintenanceModal={
            setOpenEditVehicleMaintenanceModal
          }
          openEditVehicleMaintenanceModal={openEditVehicleMaintenanceModal}
          rowData={rowData}
          refetch={refetch}
        />
      )}
    </>
  );
};
export default VehicleMaintenance;
