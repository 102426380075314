import { Divider } from 'antd';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useSelector } from 'react-redux';
import AvatarDrop from './component/AvatarDrop';
import CustomTheme from './component/CustomTheme';
import RealNotification from './component/RealNotification';

const Header = ({ setSidebarOpen, isSidebarOpen }) => {
  const { themeMode, colorTheme, userName } = useSelector(
    (state) => state?.states
  );
  return (
    <>
      <div
        className=" flex sm:justify-end justify-between items-center sm:mx-[30px] mx-[10px] min-h-[50px]"
        style={{
          borderBottom:
            themeMode === 'light' ? '1px solid #f0f0f0' : '1px solid #353535',
        }}
      >
        <div
          className="sm:hidden block"
          onClick={() => {
            setSidebarOpen(!isSidebarOpen);
          }}
        >
          <GiHamburgerMenu
            className="w-[25px] h-[25px] "
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
            }}
          />
        </div>
        <div className=" flex items-center space-x-[10px] ">
          <div
            className="text-[13px] font-semiBold mx-10"
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
            }}
          >
            <RealNotification />
          </div>
          <div
            className="text-[13px] font-semiBold"
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
            }}
          >
            {userName}
          </div>
          {/* <div className="mt-[5px]">
            <MdOutlineMailOutline
              className=" w-[25px] h-[25px] "
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
              }}
            />
          </div>
          <div className="mt-[10px]">
            <Badge count="5" size="small">
              <MdNotificationsNone
                className=" w-[25px] h-[25px] "
                style={{
                  color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                }}
              />
            </Badge>
          </div> */}
          <div>
            <AvatarDrop />
          </div>
          <Divider
            type="vertical"
            style={{ background: '#f0f0f0', height: '25px' }}
          />
          <div className="">
            <CustomTheme />
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
