import usePermission from './UsePermissionHook';

export function usePermissionVariables() {
  return {
    Dashboard_View: usePermission('Dashboard.View'),
    Dashboard_Add: usePermission('Dashboard.Add'),
    Dashboard_Edit: usePermission('Dashboard.Edit'),
    Dashboard_Delete: usePermission('Dashboard.Delete'),

    Tenant_Management_View: usePermission('Tenant Management.View'),
    Tenant_Management_Add: usePermission('Tenant Management.Add'),
    Tenant_Management_Edit: usePermission('Tenant Management.Edit'),
    Tenant_Management_Delete: usePermission('Tenant Management.Delete'),

    Subscription_Plan_View: usePermission('Subscription Plan.View'),
    Subscription_Plan_Add: usePermission('Subscription Plan.Add'),
    Subscription_Plan_Edit: usePermission('Subscription Plan.Edit'),
    Subscription_Plan_Delete: usePermission('Subscription Plan.Delete'),

    Plan_Feature_View: usePermission('Plan Feature.View'),
    Plan_Feature_Add: usePermission('Plan Feature.Add'),
    Plan_Feature_Edit: usePermission('Plan Feature.Edit'),
    Plan_Feature_Delete: usePermission('Plan Feature.Delete'),

    Associate_Plan_And_Feature_View: usePermission(
      'Associate Plan And Feature.View'
    ),
    Associate_Plan_And_Feature_Add: usePermission(
      'Associate Plan And Feature.Add'
    ),
    Associate_Plan_And_Feature_Edit: usePermission(
      'Associate Plan And Feature.Edit'
    ),
    Associate_Plan_And_Feature_Delete: usePermission(
      'Associate Plan And Feature.Delete'
    ),

    Assign_Subscription_Plan_View: usePermission(
      'Assign Subscription Plan.View'
    ),
    Assign_Subscription_Plan_Add: usePermission('Assign Subscription Plan.Add'),
    Assign_Subscription_Plan_Edit: usePermission(
      'Assign Subscription Plan.Edit'
    ),
    Assign_Subscription_Plan_Delete: usePermission(
      'Assign Subscription Plan.Delete'
    ),

    All_Devices_View: usePermission('All Devices.View'),
    All_Devices_Add: usePermission('All Devices.Add'),
    All_Devices_Edit: usePermission('All Devices.Edit'),
    All_Devices_Delete: usePermission('All Devices.Delete'),

    Assign_Device_View: usePermission('Assign Device.View'),
    Assign_Device_Add: usePermission('Assign Device.Add'),
    Assign_Device_Edit: usePermission('Assign Device.Edit'),
    Assign_Device_Delete: usePermission('Assign Device.Delete'),

    Configuration_View: usePermission('Configuration.View'),
    Configuration_Add: usePermission('Configuration.Add'),
    Configuration_Edit: usePermission('Configuration.Edit'),
    Configuration_Delete: usePermission('Configuration.Delete'),

    Logs_View: usePermission('Logs.View'),
    Logs_Add: usePermission('Logs.Add'),
    Logs_Edit: usePermission('Logs.Edit'),
    Logs_Delete: usePermission('Logs.Delete'),

    SIM_Mangement_View: usePermission('Sim Management.View'),
    SIM_Mangement_Add: usePermission('Sim Management.Add'),
    SIM_Mangement_Edit: usePermission('Sim Management.Edit'),
    SIM_Mangement_Delete: usePermission('Sim Management.Delete'),

    Users_View: usePermission('User.View'),
    Users_Add: usePermission('User.Add'),
    Users_Edit: usePermission('User.Edit'),
    Users_Delete: usePermission('User.Delete'),

    Role_View: usePermission('Role.View'),
    Role_Add: usePermission('Role.Add'),
    Role_Edit: usePermission('Role.Edit'),
    Role_Delete: usePermission('Role.Delete'),

    Menu_View: usePermission('Menu.View'),
    Menu_Add: usePermission('Menu.Add'),
    Menu_Edit: usePermission('Menu.Edit'),
    Menu_Delete: usePermission('Menu.Delete'),

    Pages_View: usePermission('Pages.View'),
    Pages_Add: usePermission('Pages.Add'),
    Pages_Edit: usePermission('Pages.Edit'),
    Pages_Delete: usePermission('Pages.Delete'),

    Role_Rights_View: usePermission('Role Rights.View'),
    Role_Rights_Add: usePermission('Role Rights.Add'),
    Role_Rights_Edit: usePermission('Role Rights.Edit'),
    Role_Rights_Delete: usePermission('Role Rights.Delete'),

    Countries_View: usePermission('Countries.View'),
    Countries_Add: usePermission('Countries.Add'),
    Countries_Edit: usePermission('Countries.Edit'),
    Countries_Delete: usePermission('Countries.Delete'),

    States_View: usePermission('States.View'),
    States_Add: usePermission('States.Add'),
    States_Edit: usePermission('States.Edit'),
    States_Delete: usePermission('States.Delete'),

    Cities_View: usePermission('Cities.View'),
    Cities_Add: usePermission('Cities.Add'),
    Cities_Edit: usePermission('Cities.Edit'),
    Cities_Delete: usePermission('Cities.Delete'),

    Zip_Codes_View: usePermission('Zip Codes.View'),
    Zip_Codes_Add: usePermission('Zip Codes.Add'),
    Zip_Codes_Edit: usePermission('Zip Codes.Edit'),
    Zip_Codes_Delete: usePermission('Zip Codes.Delete'),

    Currencies_View: usePermission('Currencies.View'),
    Currencies_Add: usePermission('Currencies.Add'),
    Currencies_Edit: usePermission('Currencies.Edit'),
    Currencies_Delete: usePermission('Currencies.Delete'),

    Currency_Exchange_Rates_View: usePermission('Currency Exchange Rates.View'),
    Currency_Exchange_Rates_Add: usePermission('Currency Exchange Rates.Add'),
    Currency_Exchange_Rates_Edit: usePermission('Currency Exchange Rates.Edit'),
    Currency_Exchange_Rates_Delete: usePermission(
      'Currency Exchange Rates.Delete'
    ),

    Vehicle_Type_View: usePermission('Vehicle Type.View'),
    Vehicle_Type_Add: usePermission('Vehicle Type.Add'),
    Vehicle_Type_Edit: usePermission('Vehicle Type.Edit'),
    Vehicle_Type_Delete: usePermission('Vehicle Type.Delete'),

    Device_Type_View: usePermission('Device Type.View'),
    Device_Type_Add: usePermission('Device Type.Add'),
    Device_Type_Edit: usePermission('Device Type.Edit'),
    Device_Type_Delete: usePermission('Device Type.Delete'),

    Device_Model_View: usePermission('Device Model.View'),
    Device_Model_Add: usePermission('Device Model.Add'),
    Device_Model_Edit: usePermission('Device Model.Edit'),
    Device_Model_Delete: usePermission('Device Model.Delete'),

    Unit_of_Measure_View: usePermission('Unit Of Measure.View'),
    Unit_of_Measure_Add: usePermission('Unit Of Measure.Add'),
    Unit_of_Measure_Edit: usePermission('Unit Of Measure.Edit'),
    Unit_of_Measure_Delete: usePermission('Unit Of Measure.Delete'),

    Time_Zone_View: usePermission('Time Zone.View'),
    Time_Zone_Add: usePermission('Time Zone.Add'),
    Time_Zone_Edit: usePermission('Time Zone.Edit'),
    Time_Zone_Delete: usePermission('Time Zone.Delete'),

    SMS_Gateway_View: usePermission('Sms Gateway.View'),
    SMS_Gateway_Add: usePermission('Sms Gateway.Add'),
    SMS_Gateway_Edit: usePermission('Sms Gateway.Edit'),
    SMS_Gateway_Delete: usePermission('Sms Gateway.Delete'),

    SMS_Template_View: usePermission('Sms Template.View'),
    SMS_Template_Add: usePermission('Sms Template.Add'),
    SMS_Template_Edit: usePermission('Sms Template.Edit'),
    SMS_Template_Delete: usePermission('Sms Template.Delete'),

    Email_Gateway_View: usePermission('Email Gateway.View'),
    Email_Gateway_Add: usePermission('Email Gateway.Add'),
    Email_Gateway_Edit: usePermission('Email Gateway.Edit'),
    Email_Gateway_Delete: usePermission('Email Gateway.Delete'),

    Email_Template_View: usePermission('Email Template.View'),
    Email_Template_Add: usePermission('Email Template.Add'),
    Email_Template_Edit: usePermission('Email Template.Edit'),
    Email_Template_Delete: usePermission('Email Template.Delete'),

    Vehicles_View: usePermission('Vehicles.View'),
    Vehicles_Add: usePermission('Vehicles.Add'),
    Vehicles_Edit: usePermission('Vehicles.Edit'),
    Vehicles_Delete: usePermission('Vehicles.Delete'),

    Vehicle_Assignments_View: usePermission('Vehicle Assignments.View'),
    Vehicle_Assignments_Add: usePermission('Vehicle Assignments.Add'),
    Vehicle_Assignments_Edit: usePermission('Vehicle Assignments.Edit'),
    Vehicle_Assignments_Delete: usePermission('Vehicle Assignments.Delete'),

    Vehicle_Maintenance_View: usePermission('Vehicle Maintenance.View'),
    Vehicle_Maintenance_Add: usePermission('Vehicle Maintenance.Add'),
    Vehicle_Maintenance_Edit: usePermission('Vehicle Maintenance.Edit'),
    Vehicle_Maintenance_Delete: usePermission('Vehicle Maintenance.Delete'),

    Drivers_View: usePermission('Drivers.View'),
    Drivers_Add: usePermission('Drivers.Add'),
    Drivers_Edit: usePermission('Drivers.Edit'),
    Drivers_Delete: usePermission('Drivers.Delete'),

    Orders_View: usePermission('Orders.View'),
    Orders_Add: usePermission('Orders.Add'),
    Orders_Edit: usePermission('Orders.Edit'),
    Orders_Delete: usePermission('Orders.Delete'),

    Customers_View: usePermission('Customers.View'),
    Customers_Add: usePermission('Customers.Add'),
    Customers_Edit: usePermission('Customers.Edit'),
    Customers_Delete: usePermission('Customers.Delete'),
  };
}
