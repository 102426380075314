import { EyeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Image, Spin } from 'antd';
import Dropdown from 'components/Dropdown';
import PopConfirm from 'components/PopConfirm';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import useAxiosClientTMS from 'config/useAxiosClientTMS';
import { useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { convertDateFormat, documentOptionsLabel } from 'utils/helper';
import { GET_DOCUMENTS_BY_ENTITY_TYPE_DRIVER_DOCUMENTS } from 'utils/queryKey';

const DriverDocuments = () => {
  const { useGetRequest, deleteRequest } = useAxiosClientTMS();
  // const {
  //   Tenant_Management_Add,
  //   Tenant_Management_Edit,
  //   Tenant_Management_Delete,
  // } = usePermissionVariables();
  const { id } = useParams();
  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const queryClient = useQueryClient();

  // States
  const [openAddUserModal, setOpenUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({});
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handleIconClick = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };
  const { data: driverDocumnetData, isLoading: driverDocumnetsLoading } =
    useGetRequest(
      `/api/GetDocumentsByEntityType?entityType=driverdocuments&entityId=${id}`,
      {},
      GET_DOCUMENTS_BY_ENTITY_TYPE_DRIVER_DOCUMENTS
    );
  const handleCancel = () => {
    setSelectedRecord(null);
    setIsModalVisible(true);
  };

  const delHandler = (record) => {
    deleteRequest(`/api/DeleteDocument?id=${record?.documentId}`)
      .then((res) => {
        if (res?.success) {
          queryClient.invalidateQueries(
            GET_DOCUMENTS_BY_ENTITY_TYPE_DRIVER_DOCUMENTS
          );
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };
  const columns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: (
        <div className="flex justify-between items-center">Document Type</div>
      ),
      dataIndex: 'documentType',
      ellipsis: true,
      sorter: (a, b) => a.documentType.localeCompare(b.documentType),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'documentType'
          ? sortOrder.order
          : 'ascend',
      showSorterTooltip: false,

      render: (text, record) => {
        return (
          <span>{documentOptionsLabel(record?.documentType ?? '').label}</span>
        );
      },
    },
    {
      title: 'Issue Date',
      dataIndex: 'issuedDate',
      render: (text) => <>{convertDateFormat(text ?? '2000-11-02')}</>,
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      render: (text) => <>{convertDateFormat(text ?? '2000-11-02')} </>,
    },
    // {
    //   title: 'Entity Type',
    //   dataIndex: 'entityType',
    //   render: (text) => <>{documentOptionsLabel(text ?? '').label} </>,
    // },
    {
      title: 'View',
      dataIndex: '',
      align: 'center',
      render: (text, record) => (
        <>
          <EyeOutlined
            onClick={() => handleIconClick(record)}
            style={{ cursor: 'pointer' }}
          />
        </>
      ),
    },
    {
      title: <span className="flex items-center justify-center">Action</span>,
      dataIndex: '',
      ellipsis: true,
      width: 80,
      render: (text, record) => (
        <div className="flex justify-center">
          <Dropdown
            items={[
              {
                key: '1',
                label: (
                  <div
                    className="p-[2px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                ),
                onClick: () => {
                  navigate(`/edit-driver/${id}`);
                },
              },
              {
                key: '2',
                label: (
                  <div
                    className="p-[2px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this task?"
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={
                        <div className="flex items-center justify-center">
                          <QuestionCircleOutlined
                            style={{ color: 'red', marginTop: '5px' }}
                          />
                        </div>
                      }
                    >
                      <MdDeleteOutline className="w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                ),
              },
            ]}
          >
            <div className="!underline cursor-pointer hover:underline">
              <BsThreeDotsVertical className="w-[15px] h-[15px] cursor-pointer" />
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Spin spinning={driverDocumnetsLoading}>
        <Table
          data={
            driverDocumnetData?.data.filter(
              (record) => record?.documentType !== 'profilepicture'
            ) || []
          }
          columns={columns}
          pageNumber={pageNumber}
          setTotalRecord={setTotalRecord}
          rowsPerPage={rowsPerPage}
          totalRecord={totalRecord}
          setPageNumber={setPageNumber}
          setRowsPerPage={setRowsPerPage}
          setSortOrder={setSortOrder}
          setFilters={setFilters}
        />
      </Spin>

      {selectedRecord && (
        <Image
          style={{ display: 'none' }}
          width={200}
          src={
            selectedRecord.documentPath
              ? `${process.env.REACT_APP_IMAGE_URL}${selectedRecord.documentPath}`
              : null
          }
          alt="No document"
          preview={{
            visible: !!selectedRecord?.documentPath || false,
            src:
              process.env.REACT_APP_IMAGE_URL + selectedRecord?.documentPath ||
              '',
            onVisibleChange: () => handleCancel(),
          }}
        />
      )}
      <style jsx>{`
        .ant-image-mask {
          width: 40px;
          height: 40px;
        }
      `}</style>
    </div>
  );
};

export default DriverDocuments;
