import { QuestionCircleOutlined } from '@ant-design/icons';
import { Breadcrumb, Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Dropdown from 'components/Dropdown';
import SearchField from 'components/InputFields/SearchField';
import PopConfirm from 'components/PopConfirm';
import Status from 'components/Status';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import useAxiosClientTMS from 'config/useAxiosClientTMS';
import dayjs from 'dayjs';
import { usePermissionVariables } from 'Hooks/HasPermission';
import Layout from 'layout/AdminLayout';
import { useEffect, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaPlus } from 'react-icons/fa6';
import { GoHome } from 'react-icons/go';
import { HiMiniShoppingCart } from 'react-icons/hi2';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GET_ORDERS_KEY } from 'utils/queryKey';

const { Title } = Typography;

const Orders = () => {
  const { useGetRequest, deleteRequest } = useAxiosClientTMS();
  const { Orders_Add, Orders_Edit, Orders_Delete } = usePermissionVariables();

  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  // States
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const { data, error, isLoading, refetch } = useGetRequest(
    `/api/GetAllOrders?pagenumber=${pageNumber}&pagesize=${rowsPerPage}&searchquery=${search}`,
    {},
    GET_ORDERS_KEY
  );

  const delHandler = (record) => {
    deleteRequest(`/api/DeleteOrder?id=${record?.orderId}`)
      .then((res) => {
        if (res?.success) {
          const newTotalRecord = totalRecord - 1; // Decrement the total record count
          setTotalRecord(newTotalRecord); // Update the state

          // Check if we need to change the page number
          if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1); // Redirect to the previous page
          }
          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };
  const columns = [
    {
      title: (
        <div className="flex justify-between items-center">
          Order Tracking Id
        </div>
      ),
      showSorterTooltip: false,
      ellipsis: true,
      dataIndex: 'orderTrackingId',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.orderTrackingId.localeCompare(b.orderTrackingId),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'orderTrackingId'
          ? sortOrder.order
          : null,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: (
        <div className="flex justify-between items-center">Order Name</div>
      ),
      showSorterTooltip: false,
      ellipsis: true,
      dataIndex: 'orderName',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.orderName.localeCompare(b.orderName),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'orderName' ? sortOrder.order : null,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: (
        <div className="flex justify-between items-center">Customer Name</div>
      ),
      showSorterTooltip: false,
      ellipsis: true,
      dataIndex: 'customerName',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.customerName.localeCompare(b.customerName),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'customerName'
          ? sortOrder.order
          : null,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: (
        <div className="flex justify-between items-center">Order Date</div>
      ),
      showSorterTooltip: false,
      ellipsis: true,
      dataIndex: 'orderDate',
      defaultSortOrder: 'ascend',
      sorter: (a, b) =>
        dayjs(a.orderDate).valueOf() - dayjs(b.orderDate).valueOf(),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'orderDate' ? sortOrder.order : null,
      render: (text, record) => (
        <>
          <span>{dayjs(text).format('DD-MM-YYYY')}</span>
        </>
      ),
    },
    {
      title: (
        <div className="flex justify-between items-center">Delivery Date</div>
      ),
      showSorterTooltip: false,
      ellipsis: true,
      dataIndex: 'deliveryDate',
      defaultSortOrder: 'ascend',
      sorter: (a, b) =>
        dayjs(a.deliveryDate).valueOf() - dayjs(b.deliveryDate).valueOf(),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'deliveryDate'
          ? sortOrder.order
          : null,
      render: (text, record) => (
        <>
          <span>{dayjs(text).format('DD-MM-YYYY')}</span>
        </>
      ),
    },
    { title: 'Pickup Location', dataIndex: 'pickupLocation', ellipsis: true },
    {
      title: 'Delivery Location',
      dataIndex: 'deliveryLocation',
      ellipsis: true,
    },

    {
      title: (
        <div className="flex justify-between items-center">Total Weight</div>
      ),
      showSorterTooltip: false,
      ellipsis: true,
      dataIndex: 'totalWeight',
      sorter: (a, b) => a.totalWeight - b.totalWeight,
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'totalWeight' ? sortOrder.order : null,
    },
    {
      title: (
        <span className="flex items-center justify-center">Order Status</span>
      ),
      dataIndex: 'orderStatus',
      ellipsis: true,
      render: (tag, record) => {
        return (
          <span className="flex items-center justify-center">
            <Status tag={record?.orderStatus} />
          </span>
        );
      },
    },
    ...(Orders_Edit || Orders_Delete
      ? [
          {
            title: (
              <span className="flex items-center justify-center">Action</span>
            ),
            ellipsis: true,
            dataIndex: '',
            render: (text, record) => (
              <div className="flex justify-center">
                <Dropdown
                  overlayClassName={`${
                    themeMode === 'light'
                      ? 'action-dropdown-light'
                      : 'action-dropdown-dark'
                  }`}
                  items={[
                    ...(Orders_Edit
                      ? [
                          {
                            key: '1',
                            label: (
                              <div
                                className="p-[2px] rounded-sm"
                                style={{
                                  backgroundColor:
                                    themeMode === 'light' ? 'white' : '#121212',
                                }}
                              >
                                <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                              </div>
                            ),
                            onClick: () => {
                              navigate(`/edit-order/${record?.orderId}`);
                            },
                          },
                        ]
                      : []),
                    ...(Orders_Delete
                      ? [
                          {
                            key: '2',
                            label: (
                              <div
                                className="p-[2px] rounded-sm"
                                style={{
                                  backgroundColor:
                                    themeMode === 'light' ? 'white' : '#121212',
                                }}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <PopConfirm
                                  title="Delete"
                                  description="Are you sure to delete this task?"
                                  placement="left"
                                  onClick={() => delHandler(record)}
                                  icon={
                                    <div className="flex items-center justify-center">
                                      <QuestionCircleOutlined
                                        style={{
                                          color: 'red',
                                          marginTop: '5px',
                                        }}
                                      />
                                    </div>
                                  }
                                >
                                  <MdDeleteOutline className="w-[15px] h-[15px] cursor-pointer" />
                                </PopConfirm>
                              </div>
                            ),
                          },
                        ]
                      : []),
                  ]}
                >
                  <div className="!underline cursor-pointer hover:underline">
                    <BsThreeDotsVertical className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                </Dropdown>
              </div>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (data) {
      setTotalRecord(data?.totalRecords);
    }
  }, [data]);

  return (
    <Layout>
      <>
        <div>
          <div className=" mb-[30px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
              }}
            >
              TMS
            </Title>
            <div className="flex justify-between items-center ">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className="flex items-center space-x-4 ">
                            <HiMiniShoppingCart
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />

                            <span
                              className="font-bold text-[13px]"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              Shipping Orders
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="md:flex space-y-[20px] md:space-y-0 justify-between mt-10 mb-26">
            <SearchField
              htmlFor="search"
              id="myInputID"
              name="search"
              placeholder={'Search by Customer Name'}
              value={debouncedSearch}
              setDebouncedSearch={setDebouncedSearch}
              setPageNumber={setPageNumber}
              searchLoading={searchLoading}
              setSearchLoading={setSearchLoading}
              refetch={refetch}
              debouncedSearch={debouncedSearch}
              setSearch={setSearch}
            />
            {Orders_Add && (
              <div className="sm:flex justify-end">
                <PrimaryButton
                  title="Add Order"
                  icon={<FaPlus />}
                  onClick={() => {
                    navigate(`/add-order`);
                  }}
                />
              </div>
            )}
          </div>
          <div>
            <Table
              data={data?.data || []}
              columns={columns}
              pageNumber={pageNumber}
              setTotalRecord={setTotalRecord}
              rowsPerPage={rowsPerPage}
              totalRecord={totalRecord}
              setPageNumber={setPageNumber}
              setRowsPerPage={setRowsPerPage}
              setSortOrder={setSortOrder}
              setFilters={setFilters}
              loading={isLoading}
            />
          </div>
        </div>
      </>
    </Layout>
  );
};
export default Orders;
