// import { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';

// function usePermission(permissionKey) {
//   const [hasPermission, setHasPermission] = useState(false);
//   const Permissions = useSelector((state) => state.states?.permssionsArray);

//   useEffect(() => {
//     if (!permissionKey) {
//       setHasPermission(true);
//       return;
//     }
//     const checkPermission = () => {
//       const [pageName, action] = permissionKey.split('.');
//       let foundPermission = false;

//       for (const menu of Permissions) {
//         const page = menu.pages.find((p) => p.pageName === pageName);
//         if (page) {
//           const actionPermission = page[`can${action}`];
//           if (actionPermission) {
//             foundPermission = true;
//             setHasPermission(true);
//             break;
//           }
//         }
//       }

//       if (!foundPermission) {
//         setHasPermission(false);
//       }
//     };

//     if (Permissions && Permissions.length > 0) {
//       checkPermission();
//     }
//   }, [permissionKey, Permissions]);

//   return hasPermission;
// }

// export default usePermission;
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function usePermission(permissionKey) {
  const [hasPermission, setHasPermission] = useState(false);
  const Permissions = useSelector((state) => state.states?.permssionsArray);

  useEffect(() => {
    if (!permissionKey) {
      setHasPermission(true);
      return;
    }

    const checkPermission = (key) => {
      const [pageName, action] = key.split('.');
      for (const menu of Permissions) {
        const page = menu.pages.find((p) => p.pageName === pageName);
        if (page) {
          const actionPermission = page[`can${action}`];
          if (actionPermission) {
            return true;
          }
        }
      }
      return false;
    };

    const checkAllPermissions = () => {
      if (Array.isArray(permissionKey)) {
        return permissionKey.some((key) => checkPermission(key));
      } else {
        return checkPermission(permissionKey);
      }
    };

    if (Permissions && Permissions.length > 0) {
      const result = checkAllPermissions();
      setHasPermission(result);
    }
  }, [permissionKey, Permissions]);

  return hasPermission;
}

export default usePermission;
