import { QuestionCircleOutlined } from '@ant-design/icons';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SearchField from 'components/InputFields/SearchField';
import PopConfirm from 'components/PopConfirm/index';
import Status from 'components/Status/index';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import { delete_assign_device } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { usePermissionVariables } from 'Hooks/HasPermission';
import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { GiSettingsKnobs } from 'react-icons/gi';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddAssignDeviceModal from './components/AddAssignDeviceModal';
import AssignDeviceFilter from './components/AssignDeviceFilter';
import EditAssignDeviceModal from './components/EditAssignDeviceModal';

const AssignDevice = () => {
  const { Assign_Device_Delete, Assign_Device_Edit, Assign_Device_Add } =
    usePermissionVariables();
  const [openAddAssignDeviceModal, setOpenAddAssignDeviceModal] =
    useState(false);
  const [openEditAssignDeviceModal, setOpenEditAssignDeviceModal] =
    useState(false);
  const [openAddAssignDeviceFilterModal, setOpenAssignDeviceFilterModal] =
    useState(false);
  const [selectedAssignDeviceRecord, setSelectedAssignDeviceRecord] = useState(
    {}
  );
  const [pageNumber, setPageNumber] = useState(1);
  const { useGetRequest, deleteRequest } = useApiClient();

  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tenantId, setTenantId] = useState(0);
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const { data, isLoading, refetch } = useGetRequest(
    `/api/GetAllDevicesAssigned?pagenumber=${pageNumber}&pagesize=${rowsPerPage}&searchquery=${search}&tenantId=${tenantId}`
  );
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const navigate = useNavigate();

  const delHandler = (record) => {
    deleteRequest(`${delete_assign_device}${record?.assignVehicleId}`)
      .then((res) => {
        if (res?.success) {
          const newTotalRecord = totalRecord - 1; // Decrement the total record count
          setTotalRecord(newTotalRecord); // Update the state

          // Check if we need to change the page number
          if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1); // Redirect to the previous page
          }
          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };

  const columns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      key: 'sr',
      ellipsis: true,
      width: 80,
      render: (text, row, index) => index + 1,
    },

    {
      title: 'Device Name',
      dataIndex: 'deviceName',
      ellipsis: true,
      sorter: (a, b) => a.deviceName.localeCompare(b.deviceName),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'deviceName'
          ? sortOrder.order
          : 'ascend',
      showSorterTooltip: false,
    },
    {
      title: 'Vehicle #',
      dataIndex: 'vehicleNmber',
      ellipsis: true,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: <span className="flex items-center justify-center">Status</span>,
      dataIndex: 'isActive',
      width: 300,
      ellipsis: true,
      render: (tag, record) => {
        return (
          <span className="flex items-center justify-center">
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },
    ...(Assign_Device_Edit || Assign_Device_Delete
      ? [
          {
            title: (
              <span className=" flex items-center justify-center">Action</span>
            ),
            dataIndex: '',
            ellipsis: true,
            width: 100,
            render: (text, record) => (
              <span className="flex justify-center items-center space-x-2">
                {Assign_Device_Edit && (
                  <div
                    className=" p-[5px] rounded-sm"
                    onClick={() => {
                      setSelectedAssignDeviceRecord(record);
                      setOpenEditAssignDeviceModal(true);
                    }}
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                )}

                {Assign_Device_Delete && (
                  <div
                    className=" p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this task? "
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    >
                      <MdDeleteOutline className=" w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                )}
              </span>
            ),
          },
        ]
      : []),
  ];
  useEffect(() => {
    if (data) {
      setTotalRecord(data?.totalRecords);
    }
  }, [data]);
  return (
    <div>
      <div className="sm:flex space-y-[20px] md:space-y-0 justify-between items-center  mt-10 mb-26">
        <SearchField
          htmlFor="search"
          id="myInputID"
          name="search"
          placeholder={'Search by Name'}
          value={debouncedSearch}
          setDebouncedSearch={setDebouncedSearch}
          setPageNumber={setPageNumber}
          searchLoading={searchLoading}
          setSearchLoading={setSearchLoading}
          refetch={refetch}
          debouncedSearch={debouncedSearch}
          setSearch={setSearch}
        />
        <div className=" flex justify-between w-[150px] items-center space-x-20">
          <div className="rotate-90">
            <GiSettingsKnobs
              size={20}
              color={themeMode === 'light' ? colorTheme : '#939BC9'}
              onClick={() => {
                setOpenAssignDeviceFilterModal(true);
              }}
              className=" cursor-pointer"
            />
          </div>
          {Assign_Device_Add && (
            <div className="sm:flex justify-end">
              <PrimaryButton
                title="Assign Device"
                icon={<FaPlus />}
                onClick={() => setOpenAddAssignDeviceModal(true)}
              />
            </div>
          )}
        </div>
      </div>

      <Table
        data={data?.data}
        columns={columns}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        setTotalRecord={setTotalRecord}
        totalRecord={totalRecord}
        setPageNumber={setPageNumber}
        setRowsPerPage={setRowsPerPage}
        setSortOrder={setSortOrder}
        setFilters={setFilters}
        loading={isLoading}
      />
      {openAddAssignDeviceModal && (
        <AddAssignDeviceModal
          setOpenAddAssignDeviceModal={setOpenAddAssignDeviceModal}
          openAddAssignDeviceModal={openAddAssignDeviceModal}
          refetch={refetch}
        />
      )}
      {openEditAssignDeviceModal && (
        <EditAssignDeviceModal
          setOpenEditAssignDeviceModal={setOpenEditAssignDeviceModal}
          openEditAssignDeviceModal={openEditAssignDeviceModal}
          selectedAssignDeviceRecord={selectedAssignDeviceRecord}
          refetch={refetch}
        />
      )}
      {openAddAssignDeviceFilterModal && (
        <AssignDeviceFilter
          setOpenAssignDeviceFilterModal={setOpenAssignDeviceFilterModal}
          openAddAssignDeviceFilterModal={openAddAssignDeviceFilterModal}
          refetch={refetch}
          setTenantId={setTenantId}
          tenantId={tenantId}
        />
      )}
    </div>
  );
};
export default AssignDevice;
