import { QuestionCircleOutlined } from '@ant-design/icons';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SearchField from 'components/InputFields/SearchField';
import PopConfirm from 'components/PopConfirm/index';
import Status from 'components/Status/index';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import { delete_country } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { usePermissionVariables } from 'Hooks/HasPermission';
import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddCountryModal from './components/AddCountryModal';
import EditCountryModal from './components/EditCountryModal';
import { getRowNumber } from 'utils/helper';

const Countries = () => {
  const { Countries_Delete, Countries_Edit, Countries_Add } =
    usePermissionVariables();
  const [openAddCountryModal, setOpenAddCountryModal] = useState(false);
  const [openEditCountryModal, setOpenEditCountryModal] = useState(false);
  const [selectedCountryRecord, setSelectedCountryRecord] = useState({
    name: '',
    id: '',
    isActive: false,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const { useGetRequest, deleteRequest } = useApiClient();
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const { data, isLoading, refetch } = useGetRequest(
    `/api/GetCountries?pagenumber=${pageNumber}&pagesize=${rowsPerPage}&searchquery=${search}`
  );
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const navigate = useNavigate();

  const delHandler = (record) => {
    deleteRequest(`${delete_country}${record?.countryId}`)
      .then((res) => {
        if (res?.success) {
          const newTotalRecord = totalRecord - 1; // Decrement the total record count
          setTotalRecord(newTotalRecord); // Update the state

          // Check if we need to change the page number
          if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1); // Redirect to the previous page
          }

          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };

  const columns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      key: 'sr',
      ellipsis: true,
      width: 80,
      render: (text, row, index) =>
        getRowNumber(pageNumber, rowsPerPage, index),
    },
    {
      title: (
        <div className="flex justify-between items-center">Country Name</div>
      ),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a?.name?.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'name' ? sortOrder.order : 'ascend',
      showSorterTooltip: false,
      ellipsis: true,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: <span className="flex items-center justify-center">Status</span>,
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      width: 300,
      ellipsis: true,
      render: (tag, record) => {
        return (
          <span>
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },
    ...(Countries_Edit || Countries_Delete
      ? [
          {
            title: (
              <span className=" flex items-center justify-center">Action</span>
            ),
            dataIndex: '',
            ellipsis: true,
            width: 100,
            render: (text, record) => (
              <span className="flex justify-center items-center space-x-2">
                {Countries_Edit && (
                  <div
                    className=" p-[5px] rounded-sm"
                    onClick={() => {
                      setSelectedCountryRecord({
                        name: record.name,
                        isActive: record.isActive,
                        id: record.countryId,
                      });
                      setOpenEditCountryModal(true);
                    }}
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                )}
                {Countries_Delete && (
                  <div
                    className=" p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this country? "
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    >
                      <MdDeleteOutline className=" w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                )}
              </span>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (data) {
      setTotalRecord(data?.totalRecords);
      refetch();
    }
  }, [data]);

  return (
    <>
      <div className="md:flex space-y-[20px] md:space-y-0 justify-between mt-10 mb-26">
        <SearchField
          htmlFor="search"
          id="myInputID"
          name="search"
          placeholder={'Search by Country Name'}
          value={debouncedSearch}
          setDebouncedSearch={setDebouncedSearch}
          setPageNumber={setPageNumber}
          searchLoading={searchLoading}
          setSearchLoading={setSearchLoading}
          refetch={refetch}
          debouncedSearch={debouncedSearch}
          setSearch={setSearch}
          search={search}
        />
        {Countries_Add && (
          <div className="">
            <PrimaryButton
              title="Add Country"
              icon={<FaPlus />}
              onClick={() => setOpenAddCountryModal(true)}
            />
          </div>
        )}
      </div>
      <Table
        data={data?.data}
        columns={columns}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        setTotalRecord={setTotalRecord}
        totalRecord={totalRecord}
        setPageNumber={setPageNumber}
        setRowsPerPage={setRowsPerPage}
        setSortOrder={setSortOrder}
        setFilters={setFilters}
        loading={isLoading}
      />
      {openAddCountryModal && (
        <AddCountryModal
          setOpenAddCountryModal={setOpenAddCountryModal}
          openAddCountryModal={openAddCountryModal}
          refetch={refetch}
        />
      )}
      {openEditCountryModal && (
        <EditCountryModal
          setOpenEditCountryModal={setOpenEditCountryModal}
          openEditCountryModal={openEditCountryModal}
          selectedCountryRecord={selectedCountryRecord}
          refetch={refetch}
        />
      )}
    </>
  );
};
export default Countries;
