import React, { useState } from 'react';
import { Button, Modal, Input } from 'antd';
import TextField from 'components/CustomFields/TextField';
import { useFormik } from 'formik';
import useValidationSchema from 'validation/useValidationSchema';
import InputFieldCustomForm from 'components/CustomFields/CustomFieldsOutlinedForm';
import { useSelector } from 'react-redux';
import { themeInputColor } from 'utils/constant';
import Toast from 'components/Toast';
import {
  add_sms_gateway,
  add_sms_template,
  get_all_sms_gateway,
  get_sms_templates_by_tenant_id,
} from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useQueryClient } from 'react-query';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import PhoneInput from 'components/InputFields/PhoneInput';
import ToastNotification from 'components/ToastNotification';
import PasswordField from 'components/CustomFields/PasswordField';
import Checkbox from 'components/InputFields/Checkbox';

const AddSMSGatewayModal = ({
  openAddSMSGatewayModal,
  setOpenAddSMSGatewayModal,
}) => {
  const { contextHolder, showMessage } = Toast();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { postRequest } = useApiClient();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const { addSMSSettingModalSchema } = useValidationSchema();
  const formik = useFormik({
    initialValues: {
      smsGatewayName: '',
      userName: '',
      passwordHash: '',
      fromPhoneNumber: '+92',
      isActive: false,
    },
    validationSchema: addSMSSettingModalSchema,
    onSubmit: ({
      smsGatewayName,
      userName,
      passwordHash,
      fromPhoneNumber,
      isActive,
    }) => {
      setLoading(true);
      const paylaod = {
        smsGatewayName,
        userName,
        passwordHash,
        fromPhoneNumber,
        isActive: isActive,
        createdBy: '',
      };
      postRequest(add_sms_gateway, paylaod)
        .then((res) => {
          if (res?.success) {
            ToastNotification('success', res.message, themeMode);
            setOpenAddSMSGatewayModal(false);
            queryClient.invalidateQueries(get_all_sms_gateway);
            formik.resetForm();
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCancel = () => {
    setOpenAddSMSGatewayModal(false);
    formik.resetForm();
  };
  return (
    <>
      {contextHolder}
      <Modal
        open={openAddSMSGatewayModal}
        title={<span className=" text-[18px]">Add SMS Gateway</span>}
        width={600}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <>
          {contextHolder}

          <div className=" grid grid-cols-12 gap-x-20 gap-y-5 py-[10px]">
            <div className="sm:col-span-6 col-span-12">
              <TextField
                formik={formik}
                id={'smsGatewayName'}
                label={'Gateway Name'}
                maxLength={30}
              />
            </div>
            <div className="sm:col-span-6 col-span-12">
              <PhoneInput
                label="Phone Number"
                name="fromPhoneNumber"
                value={formik.values.fromPhoneNumber}
                isModel={true}
                onChange={(value) => {
                  if (value.length <= 18) {
                    formik.setFieldValue('fromPhoneNumber', value);
                    formik.setFieldTouched('fromPhoneNumber', true, false);
                  }
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.fromPhoneNumber &&
                  formik.errors.fromPhoneNumber
                }
                errorMessage={
                  formik.touched.fromPhoneNumber &&
                  formik.errors.fromPhoneNumber
                    ? formik.errors.fromPhoneNumber
                    : null
                }
              />
            </div>
            <div className="sm:col-span-6 col-span-12">
              <TextField
                formik={formik}
                id={'userName'}
                label={'User Name'}
                maxLength={30}
              />
            </div>
            <div className="sm:col-span-6 col-span-12">
              <PasswordField
                formik={formik}
                id={'passwordHash'}
                label={'Password'}
              />
            </div>
            <div className="col-span-12 mt-5">
              <Checkbox
                title="IsActive"
                id="isActive"
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                error={formik.errors.isActive}
                errorMessage={
                  formik.touched.isActive && formik.errors.isActive
                    ? formik.errors.isActive
                    : null
                }
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default AddSMSGatewayModal;
