import { Button, Modal } from 'antd';
import TextField from 'components/CustomFields/TextField';
import Checkbox from 'components/InputFields/Checkbox';
import TextArea from 'components/InputFields/TextArea';
import ToastNotification from 'components/ToastNotification';
import { add_device_type } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import useValidationSchema from 'validation/useValidationSchema';

const AddDeviceTypeModal = ({
  openAddDeviceTypeModal,
  setOpenAddDeviceTypeModal,
  refetch,
}) => {
  const { themeMode } = useSelector((state) => state?.states);
  const { postRequest } = useApiClient();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const { deviceTypeModalSchema } = useValidationSchema();
  const formik = useFormik({
    initialValues: {
      deviceTypeName: '',
      description: '',
      isActive: false,
    },
    validationSchema: deviceTypeModalSchema,
    onSubmit: ({ deviceTypeName, description,isActive }) => {
      setLoading(true);
      const paylaod = {
        name: deviceTypeName,
        description: description,
        isActive: isActive,
      };
      postRequest(add_device_type, paylaod)
        .then((res) => {
          if (res?.success) {
            setOpenAddDeviceTypeModal(false);
            refetch();
            formik.resetForm();
            ToastNotification('success', res.message, themeMode);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCancel = () => {
    setOpenAddDeviceTypeModal(false);
    formik.resetForm();
  };
  return (
    <>
      <Modal
        open={openAddDeviceTypeModal}
        title={<span className=" text-[18px]">Add Device Type</span>}
        width={400}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
            className="!shadow-none"
          >
            Submit
          </Button>,
        ]}
      >
        <>
          <div className=" grid grid-cols-12 gap-x-20 gap-y-5  py-[10px]">
            <div className=" col-span-12">
              <TextField
                formik={formik}
                id={'deviceTypeName'}
                label={'Device Type Name'}
                maxLength={30}
              />
            </div>
            <div className=" col-span-12">
              <div className="wrapper-fields">
                <TextArea
                  id="description"
                  name="description"
                  rows={4}
                  required={true}
                  maxLength={250}
                  placeholder="Description"
                  value={formik.values.description}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldTouched('description', true, false);
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  errorMessage={
                    formik.touched.description && formik.errors.description
                      ? formik.errors.description
                      : null
                  }
                />
              </div>
            </div>
            <div className="col-span-12 mt-5">
              <Checkbox
                title="IsActive"
                id="isActive"
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                error={formik.errors.isActive}
                errorMessage={
                  formik.touched.isActive && formik.errors.isActive
                    ? formik.errors.isActive
                    : null
                }
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default AddDeviceTypeModal;
