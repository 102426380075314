import { QuestionCircleOutlined } from '@ant-design/icons';
import { usePermissionVariables } from 'Hooks/HasPermission';
import { Breadcrumb, Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Dropdown from 'components/Dropdown';
import SearchField from 'components/InputFields/SearchField';
import PopConfirm from 'components/PopConfirm';
import Status from 'components/Status';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import { delete_users } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import Layout from 'layout/AdminLayout';
import { useEffect, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaPlus } from 'react-icons/fa6';
import { GiSettingsKnobs } from 'react-icons/gi';
import { GoHome } from 'react-icons/go';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import {
  MdCancel,
  MdDeleteOutline,
  MdOutlineModeEditOutline,
} from 'react-icons/md';
import { PiUsersThreeFill } from 'react-icons/pi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddUserModal from './component/AddUserModal';
import EditUserModal from './component/EditUserModal';
import UsersFilter from './component/UsersFilter';
import { getRowNumber } from 'utils/helper';

const { Title } = Typography;

const Users = () => {
  const { useGetRequest, deleteRequest } = useApiClient();
  const { Users_View, Users_Add, Users_Edit, Users_Delete } =
    usePermissionVariables();
  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  // States
  const [openAddUserModal, setOpenUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [openAddUserFilterModal, setOpenUserFilterModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [tenantId, setTenantId] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const { data, error, isLoading, refetch } = useGetRequest(
    `/api/User/GetAllUsers?pagenumber=${pageNumber}&pagesize=${rowsPerPage}&tenantId=${tenantId}&searchquery=${search}`
  );
  const delHandler = (record) => {
    deleteRequest(`${delete_users}${record?.userId}`)
      .then((res) => {
        if (res?.success) {
          const newTotalRecord = totalRecord - 1; // Decrement the total record count
          setTotalRecord(newTotalRecord); // Update the state

          // Check if we need to change the page number
          if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1); // Redirect to the previous page
          }
          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };
  const columns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      key: 'sr',
      // align: 'center',
      width: 80,
      ellipsis: true,
      render: (text, row, index) =>
        getRowNumber(pageNumber, rowsPerPage, index),
    },
    {
      title: <div className="flex justify-between items-center">User Name</div>,
      ellipsis: true,
      dataIndex: 'userName',
      sorter: (a, b) => a.userName.localeCompare(b.userName),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'userName'
          ? sortOrder.order
          : 'ascend',
      showSorterTooltip: false,

      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      ellipsis: true,
      render: (text, record) => (
        <>
          <a
            className="!underline cursor-pointer hover:underline hover:text-inherit"
            href={`mailto:${text}`}
            // target="_blank"
            rel="noopener noreferrer"
          >
            {text}
          </a>
        </>
      ),
    },
    { title: 'Phone #', dataIndex: 'contactNo', ellipsis: true },
    { title: 'Role', dataIndex: 'roleName', ellipsis: true },
    {
      title: <span className="flex items-center justify-center">Status</span>,
      ellipsis: true,
      dataIndex: 'isActive',
      render: (tag, record) => {
        return (
          <span className="flex items-center justify-center">
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },
    {
      title: (
        <span className="flex items-center justify-center">
          is Two Factor Enabled
        </span>
      ),
      dataIndex: 'isTwoFactorEnabled',
      ellipsis: true,
      render: (text, record) => {
        return (
          <div className="flex justify-center">
            {record?.isTwoFactorEnabled ? (
              <IoCheckmarkCircleSharp size={24} color="#389e0d" />
            ) : (
              <MdCancel size={24} color="#d4380d" />
            )}
          </div>
        );
      },
    },
    ...(Users_Edit || Users_Delete
      ? [
          {
            title: (
              <span className="flex items-center justify-center">Action</span>
            ),
            ellipsis: true,
            dataIndex: '',
            render: (text, record) => (
              <div className="flex justify-center">
                <Dropdown
                  overlayClassName={`${
                    themeMode === 'light'
                      ? 'action-dropdown-light'
                      : 'action-dropdown-dark'
                  }`}
                  items={[
                    ...(Users_Edit
                      ? [
                          {
                            key: '1',
                            label: (
                              <div
                                className="p-[2px] rounded-sm"
                                style={{
                                  backgroundColor:
                                    themeMode === 'light' ? 'white' : '#121212',
                                }}
                                onClick={() => {
                                  setRowData(record);
                                  setOpenEditUserModal(true);
                                }}
                              >
                                <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                              </div>
                            ),
                          },
                        ]
                      : []),
                    ...(Users_Delete
                      ? [
                          {
                            key: '2',
                            label: (
                              <div
                                className="p-[2px] rounded-sm"
                                style={{
                                  backgroundColor:
                                    themeMode === 'light' ? 'white' : '#121212',
                                }}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <PopConfirm
                                  title="Delete"
                                  description="Are you sure to delete this task?"
                                  placement="left"
                                  onClick={() => delHandler(record)}
                                  icon={
                                    <div className="flex items-center justify-center">
                                      <QuestionCircleOutlined
                                        style={{
                                          color: 'red',
                                          marginTop: '5px',
                                        }}
                                      />
                                    </div>
                                  }
                                >
                                  <MdDeleteOutline className="w-[15px] h-[15px] cursor-pointer" />
                                </PopConfirm>
                              </div>
                            ),
                          },
                        ]
                      : []),
                  ]}
                >
                  <BsThreeDotsVertical className="w-[15px] h-[15px] cursor-pointer" />
                </Dropdown>
              </div>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Layout>
      <>
        <div>
          <div className=" mb-[30px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
              }}
            >
              Security
            </Title>
            <div className="flex justify-between items-center ">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className="flex items-center space-x-4 ">
                            <PiUsersThreeFill
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />

                            <span
                              className="font-bold text-[13px]"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              Users
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="sm:flex space-y-[20px] md:space-y-0 justify-between items-center  mt-10 mb-26">
            <SearchField
              htmlFor="search"
              id="myInputID"
              name="search"
              placeholder={'Search by Name'}
              value={debouncedSearch}
              setDebouncedSearch={setDebouncedSearch}
              setPageNumber={setPageNumber}
              searchLoading={searchLoading}
              setSearchLoading={setSearchLoading}
              refetch={refetch}
              debouncedSearch={debouncedSearch}
              setSearch={setSearch}
            />
            <div className=" flex justify-between w-[150px] items-center">
              <div className="rotate-90">
                <GiSettingsKnobs
                  size={20}
                  color={themeMode === 'light' ? colorTheme : '#939BC9'}
                  onClick={() => {
                    setOpenUserFilterModal(true);
                  }}
                  className=" cursor-pointer"
                />
              </div>
              {Users_Add && (
                <div>
                  <PrimaryButton
                    title="Add User"
                    icon={<FaPlus />}
                    onClick={() => {
                      setOpenUserModal(true);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            <Table
              colorTheme={colorTheme}
              data={data?.data || []}
              columns={columns}
              pageNumber={pageNumber}
              setTotalRecord={setTotalRecord}
              rowsPerPage={rowsPerPage}
              totalRecord={totalRecord}
              setPageNumber={setPageNumber}
              setRowsPerPage={setRowsPerPage}
              setSortOrder={setSortOrder}
              setFilters={setFilters}
              loading={isLoading}
            />
          </div>
          {openAddUserModal && (
            <AddUserModal
              setOpenUserModal={setOpenUserModal}
              openAddUserModal={openAddUserModal}
              refetch={refetch}
            />
          )}
          {openEditUserModal && rowData && (
            <EditUserModal
              setOpenEditUserModal={setOpenEditUserModal}
              openEditUserModal={openEditUserModal}
              record={rowData}
              refetch={refetch}
            />
          )}
          {openAddUserFilterModal && (
            <UsersFilter
              setOpenUserFilterModal={setOpenUserFilterModal}
              openAddUserFilterModal={openAddUserFilterModal}
              refetch={refetch}
              setTenantId={setTenantId}
              tenantId={tenantId}
            />
          )}
        </div>
      </>
    </Layout>
  );
};
export default Users;
