import React, { useMemo, useState } from 'react';
import { Table } from 'antd';
import Layout from 'layout/AdminLayout';
import { MdOutlineModeEditOutline, MdDeleteOutline } from 'react-icons/md';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import useApiClient from 'config/useAxiosClient';

const Security = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const { useGetRequest, usePostRequest } = useApiClient();
  const { data, error, isLoading } = useGetRequest(
    'https://jsonplaceholder.typicode.com/users'
  );

  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({});
  let colorTheme = '';

  const columns = [
    { title: '#', dataIndex: 'id' },
    { title: 'Name', dataIndex: 'name' },
    { title: 'Phone #', dataIndex: 'phone' },
    {
      title: 'Action',
      dataIndex: '',
      render: (text, record) => (
        <span className="flex justify-center items-center space-x-2">
          <div className="bg-white p-[5px] rounded-sm">
            <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
          </div>
          <div className="bg-white p-[5px] rounded-sm">
            <MdDeleteOutline className="w-[15px] h-[15px] cursor-pointer" />
          </div>
        </span>
      ),
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    setPageNumber(pagination.current);
    setRowsPerPage(pagination.pageSize);
    setSortOrder(sorter);
    setFilters(filters);
  };

  const pageSizeOptions = useMemo(() => {
    const options = [];
    for (let i = 1; i * 10 <= totalRecord; i++) {
      options.push((i * 10).toString());
    }
    return options;
  }, [totalRecord]);

  return (
    <Layout>
      <>
        <div>
          <div className="flex justify-between">
            <div className="text-[28px] font-bold">Security</div>
            <div className="flex items-center space-x-20">
              <div>
                <PrimaryButton title="ROLE" />
              </div>
              <div>
                <PrimaryButton title="ADD" />
              </div>
            </div>
          </div>
          <div className="mt-20 shadow-lg">
            <div
              className={`${
                colorTheme == 'dark' ? 'bg-[#1e1e1e]' : 'bg-white '
              } px-[20px] py-[10px] rounded-[10px]`}
            >
              <Table
                columns={columns}
                dataSource={data}
                onChange={onChange}
                size="small"
                rowClassName={(record, index) =>
                  index % 2 === 0 ? 'bg-[#f7f7fa] text-black' : ''
                }
                scroll={{ x: true }}
                pagination={{
                  current: pageNumber,
                  pageSize: rowsPerPage,
                  total: totalRecord,
                  showSizeChanger: true,
                  size: 'large',
                  position: ['bottomCenter'],
                  responsive: true,
                  pageSizeOptions: pageSizeOptions,
                  onShowSizeChange: (current, size) => {
                    setRowsPerPage(size);
                    setPageNumber(1);
                  },
                  className: 'light-pagination',
                }}
                loading={isLoading} // Set loading state here
              />
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default Security;
