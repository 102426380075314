import { Breadcrumb, Spin, Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
import Checkbox from 'components/InputFields/Checkbox';
import PhoneInput from 'components/InputFields/PhoneInput';
import LabelGrid from 'components/LabelGrid';
import ToastNotification from 'components/ToastNotification';
import { get_country_by_dropdown } from 'config/services';
import useAxiosClient from 'config/useAxiosClient';
import useAxiosClientTMS from 'config/useAxiosClientTMS';
import { useFormik } from 'formik';
import Layout from 'layout/AdminLayout';
import { useEffect, useState } from 'react';
import { BiSave } from 'react-icons/bi';
import { FaCloudArrowUp } from 'react-icons/fa6';
import { GoHome } from 'react-icons/go';
import { MdCancel } from 'react-icons/md';
import { PiSteeringWheel } from 'react-icons/pi';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { createOptions } from 'utils/helper';
import {
  GET_ALL_DISPATCHERS_KEY,
  GET_COUNTRIES_DROPDOWN_KEY,
  GET_DISPATCHERS_BY_ID_KEY,
  GET_DISPATCHERS_DROPDOWN_KEY,
} from 'utils/queryKey';
import useValidationSchema from 'validation/useValidationSchema';
const { Title } = Typography;

const EditDispatcher = () => {
  const { id } = useParams();
  const { putRequest, useGetRequest } = useAxiosClientTMS();
  const { useGetRequest: useGetRequest2 } = useAxiosClient();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { dispatcherSchema } = useValidationSchema();

  const {
    data: dispatcherData,
    isLoading: dispatcherLoading,
    refetch: dispatcherRefetch,
  } = useGetRequest(
    `api/GetDispatcherById?id=${id}`,
    {},
    GET_DISPATCHERS_BY_ID_KEY
  );

  const formik = useFormik({
    initialValues: {
      firstName: dispatcherData?.data?.firstName ?? '',
      lastName: dispatcherData?.data?.lastName ?? '',
      email: dispatcherData?.data?.email ?? '',
      phone: dispatcherData?.data?.phone ?? '+92',
      address: dispatcherData?.data?.address ?? '',
      cityId: dispatcherData?.data?.cityId ?? null,
      stateId: dispatcherData?.data?.stateId ?? null,
      countryId: dispatcherData?.data?.countryId ?? null,
      zipcodeId: dispatcherData?.data?.zipcodeId ?? null,
      isActive: dispatcherData?.data?.isActive ?? true,
    },
    validationSchema: dispatcherSchema,
    onSubmit: ({
      firstName,
      lastName,
      email,
      phone,
      address,
      cityId,
      stateId,
      countryId,
      zipcodeId,
      isActive,
    }) => {
      setLoading(true);
      const paylaod = {
        dispatcherId: id,
        firstName,
        lastName,
        email,
        phone,
        address,
        city: cityId,
        state: stateId,
        country: countryId,
        zipcode: zipcodeId,
        isActive,
      };

      putRequest('/api/UpdateDispatcher', paylaod)
        .then((res) => {
          if (res?.success) {
            formik.resetForm();
            navigate('/dispatcher');
            ToastNotification('success', res?.message, themeMode);
            queryClient.invalidateQueries({
              queryKey: [GET_ALL_DISPATCHERS_KEY],
            });
            queryClient.invalidateQueries({
              queryKey: [GET_DISPATCHERS_DROPDOWN_KEY],
            });
          } else {
            ToastNotification('error', res?.message, themeMode);
          }
        })
        .catch(() => {
          ToastNotification('error', 'Something went wrong', themeMode);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const { data: countryData, isLoading: countryLoading } = useGetRequest2(
    get_country_by_dropdown,
    {},
    GET_COUNTRIES_DROPDOWN_KEY
  );
  const {
    data: stateData,
    isLoading: stateLoading,
    refetch: statesRefetch,
  } = useGetRequest2(
    `/api/StateDropdownByCountryName?country=${formik.values.countryId}`,
    {
      enabled: false,
    }
  );
  const {
    data: cityData,
    isLoading: cityLoading,
    refetch: citiesRefetch,
  } = useGetRequest2(
    `/api/CitiesDropdownByStateName?state=${formik.values.stateId}`,
    {
      enabled: false,
    }
  );

  const {
    data: zipCodeData,
    isLoading: zipCodeLoading,
    refetch: zipCodeRefetch,
  } = useGetRequest2(
    `/api/ZipCodesDropdownByCityName?city=${formik.values.cityId}`,
    {
      enabled: false,
    }
  );
  const countryOptions = createOptions(countryData?.data, 'name', 'name');
  const stateOptions = createOptions(stateData?.data, 'name', 'name');
  const cityOptions = createOptions(cityData?.data, 'name', 'name');
  const zipCodeOptions = createOptions(zipCodeData?.data, 'code', 'code');

  useEffect(() => {
    if (formik.values.countryId) statesRefetch();
  }, [formik.values.countryId]);
  useEffect(() => {
    if (formik.values.stateId) citiesRefetch();
  }, [formik.values.stateId]);
  useEffect(() => {
    if (formik.values.cityId) zipCodeRefetch();
  }, [formik.values.cityId]);
  useEffect(() => {
    formik.setValues({
      firstName: dispatcherData?.data?.firstName ?? '',
      lastName: dispatcherData?.data?.lastName ?? '',
      email: dispatcherData?.data?.email ?? '',
      phone: dispatcherData?.data?.phone ?? '+92',
      address: dispatcherData?.data?.address ?? '',
      cityId: dispatcherData?.data?.city ?? null,
      stateId: dispatcherData?.data?.state ?? null,
      countryId: dispatcherData?.data?.country ?? null,
      zipcodeId: dispatcherData?.data?.zipcode ?? null,
      isActive: dispatcherData?.data?.isActive ?? true,
    });
  }, [dispatcherData]);
  return (
    <Layout>
      <Spin spinning={dispatcherLoading}>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-[30px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
                marginBottom: '10px',
              }}
            >
              TMS
            </Title>
            <div className="flex justify-between items-center mb-20">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <PiSteeringWheel />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/dispatcher');
                              }}
                            >
                              Dispatcher Management
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4 ">
                            <FaCloudArrowUp
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />
                            <span
                              className=" font-bold text-[13px]"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              Update Dispatcher
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
              <div className="flex justify-end space-x-10">
                <div>
                  <SecondaryButton
                    title="Cancel"
                    onClick={() => {
                      formik.resetForm();
                      navigate('/dispatcher');
                    }}
                    icon={<MdCancel size={17} />}
                    className=" w-full text-[13px]"
                  />
                </div>
                <div>
                  <PrimaryButton
                    title="Save"
                    icon={<BiSave className=" w-[17px] h-[17px]" />}
                    htmlType="submit"
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
          <LabelGrid
            label="Dispatcher Info"
            className=" rounded-[5px] px-20 py-20"
          >
            <div className="grid grid-cols-12 sm:gap-x-[12px] sm:gap-y-5 sm:mb-[30px] w-full">
              <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                <TextField
                  formik={formik}
                  id={'firstName'}
                  label={'First Name'}
                  maxLength={30}
                  notModal={true}
                />
              </div>
              <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                <TextField
                  formik={formik}
                  id={'lastName'}
                  label={'Last Name'}
                  maxLength={30}
                  notModal={true}
                />
              </div>
              <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                <TextField
                  formik={formik}
                  id={'email'}
                  label={'Email'}
                  maxLength={30}
                  notModal={true}
                />
              </div>
              <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                <PhoneInput
                  label="Phone Number"
                  name="phone"
                  value={formik.values.phone}
                  onChange={(value) => {
                    if (value.length <= 18) {
                      formik.setFieldValue('phone', value);
                      formik.setFieldTouched('phone', true, false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phone && formik.errors.phone}
                  errorMessage={
                    formik.touched.phone && formik.errors.phone
                      ? formik.errors.phone
                      : null
                  }
                />
              </div>
              <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                <TextField
                  formik={formik}
                  id={'address'}
                  label={'Address'}
                  maxLength={30}
                  notModal={true}
                />
              </div>
              <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12">
                <SelectField
                  formik={formik}
                  id={'countryId'}
                  label={'Country'}
                  optionId={'value'}
                  optionName={'label'}
                  optionData={countryOptions}
                  isLoading={countryLoading}
                  notModal={true}
                  onChange={(newValue, _) => {
                    formik.setFieldValue('countryId', newValue);
                    formik.setFieldValue('stateId', null);
                    formik.setFieldValue('cityId', null);
                    formik.setFieldValue('zipcodeId', null);
                  }}
                />
              </div>
              <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12">
                <SelectField
                  formik={formik}
                  id={'stateId'}
                  label={'State'}
                  optionId={'value'}
                  optionName={'label'}
                  optionData={stateOptions}
                  isLoading={stateLoading}
                  notModal={true}
                  onChange={(newValue, _) => {
                    formik.setFieldValue('stateId', newValue);
                    formik.setFieldValue('cityId', null);
                    formik.setFieldValue('zipcodeId', null);
                  }}
                />
              </div>
              <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12">
                <SelectField
                  formik={formik}
                  id={'cityId'}
                  label={'City'}
                  optionId={'value'}
                  optionName={'label'}
                  optionData={cityOptions}
                  isLoading={cityLoading}
                  notModal={true}
                  onChange={(newValue, _) => {
                    formik.setFieldValue('cityId', newValue);
                    formik.setFieldValue('zipcodeId', null);
                  }}
                />
              </div>
              <div className=" xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12">
                <SelectField
                  formik={formik}
                  id={'zipcodeId'}
                  label={'Zip Code'}
                  optionId={'value'}
                  optionName={'label'}
                  optionData={zipCodeOptions}
                  isLoading={zipCodeLoading}
                  notModal={true}
                  onChange={(newValue, _) => {
                    formik.setFieldValue('zipcodeId', newValue);
                  }}
                />
              </div>
              <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 flex items-center">
                <Checkbox
                  title="IsActive"
                  id="isActive"
                  name="isActive"
                  checked={formik.values.isActive}
                  onChange={formik.handleChange}
                  error={formik.errors.isActive}
                  errorMessage={
                    formik.touched.isActive && formik.errors.isActive
                      ? formik.errors.isActive
                      : null
                  }
                />
              </div>
            </div>
          </LabelGrid>
        </form>
      </Spin>
    </Layout>
  );
};

export default EditDispatcher;
