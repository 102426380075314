import { Button, Modal } from 'antd';
import SelectField from 'components/CustomFields/SelectField';
import useApiClient from 'config/useAxiosClient';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createOptions } from 'utils/helper';
import * as Yup from 'yup';

const UsersFilter = ({
  openAddUserFilterModal,
  setOpenUserFilterModal,
  setTenantId,
  tenantId,
  refetch,
}) => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { useGetRequest } = useApiClient();
  const formik = useFormik({
    initialValues: {
      tenantId: null,
    },
    validationSchema: Yup.object({}),
    onSubmit: ({ tenantId }) => {
      setTenantId(tenantId);
      refetch();
      setOpenUserFilterModal(false);
    },
  });
  const {
    data: tenatData,
    isLoading: tenantLoading,
    refetch: tenantRefetch,
  } = useGetRequest(`/api/GeTenantsDropdown`, {
    refetchOnMount: 'always',
    enabled: false,
  });
  const tenantOption = createOptions(tenatData?.data, 'tenantName', 'tenantId');
  const handleCancel = () => {
    setOpenUserFilterModal(false);
    formik.resetForm();
  };
  useEffect(() => {
    tenantRefetch();
  }, [openAddUserFilterModal]);
  useEffect(() => {
    if (tenantId) {
      formik.setFieldValue('tenantId', tenantId);
    }
  }, [tenantId, openAddUserFilterModal]);
  return (
    <>
      <Modal
        open={openAddUserFilterModal}
        width={400}
        title={<span className="text-[18px]">Apply Filter</span>}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            // loading={loading}
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <>
          <div className="grid grid-cols-12">
            <div className=" sm:col-span-12 col-span-12">
              <SelectField
                formik={formik}
                id={'tenantId'}
                label={'Tenant Name'}
                optionId={'value'}
                optionName={'label'}
                optionData={tenantOption}
                isLoading={tenantLoading}
              />
            </div>
            <div className=" sm:col-span-12 col-span-12">
              <div
                className=" underline cursor-pointer text-sm mr-[5px] w-full text-right"
                style={{
                  color: themeMode === 'light' ? colorTheme : '#939BC9',
                }}
                onClick={() => {
                  refetch();
                  setTenantId(0);
                  setOpenUserFilterModal(false);
                }}
              >
                Reset Filter
              </div>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default UsersFilter;
