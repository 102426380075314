import { Button, Modal, Select } from 'antd';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
import ToastNotification from 'components/ToastNotification';
import { add_page } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { capitalizeWords } from 'utils/helper';
import useValidationSchema from 'validation/useValidationSchema';

const AddPagesModal = ({ openAddPagesModal, setOpenPagesModal, refetch }) => {
  const { postRequest, useGetRequest } = useApiClient();
  const { data: menuData } = useGetRequest(
    '/api/AppMenus/GetManusForDropdown',
    {
      refetchOnMount: 'always',
    }
  );
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const queryClient = useQueryClient();
  const { pageModalScehema } = useValidationSchema();
  const { Option } = Select;
  const createOptions = (data, labelKey, valueKey) =>
    data?.data?.map((item) => ({
      label: item[labelKey],
      value: item[valueKey],
    }));
  const menuOptions = createOptions(menuData, 'menuName', 'menuId');
  //States
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      menuSelect: null,
      pagesName: '',
      isActive: false,
      // isDeleted: false,
    },
    validationSchema: pageModalScehema,
    onSubmit: ({ pagesName, menuSelect, isActive }) => {
      setLoading(true);
      const paylaod = {
        pageId: 0,
        menuId: menuSelect,
        pageName: capitalizeWords(pagesName),
        isActive: isActive,
        // isDeleted: isDeleted,
      };
      postRequest(add_page, paylaod)
        .then((res) => {
          if (res?.success) {
            setOpenPagesModal(false);
            refetch();
            formik.resetForm();
            ToastNotification('success', res.message, themeMode);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCancel = () => {
    setOpenPagesModal(false);
    formik.resetForm();
  };

  return (
    <>
      <Modal
        open={openAddPagesModal}
        // className="!w-[400px]"
        width={400}
        title={<span className="text-[18px]">Add Pages</span>}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <>
          <div className="grid grid-cols-12 gap-x-20 ">
            <div className=" col-span-12">
              <SelectField
                formik={formik}
                id={'menuSelect'}
                label={'Select Menu'}
                optionId={'value'}
                optionName={'label'}
                optionData={menuOptions}
              />
            </div>
            <div className="col-span-12">
              <TextField
                formik={formik}
                id={'pagesName'}
                label={'Pages Name'}
              />
            </div>
          </div>
          <div className=" grid grid-cols-12 mt-6">
            {/* <div className="col-span-6 ">
              <Checkbox
                title="IsActive"
                id="isActive"
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                error={formik.errors.isActive}
                errorMessage={
                  formik.touched.isActive && formik.errors.isActive
                    ? formik.errors.isActive
                    : null
                }
              />
            </div> */}
            {/* <div className="col-span-6">
              <Checkbox
                title="Deleted ?"
                id="isDeleted"
                name="isDeleted"
                checked={formik.values.isDeleted}
                onChange={formik.handleChange}
                error={formik.errors.isDeleted}
                errorMessage={
                  formik.touched.isDeleted && formik.errors.isDeleted
                    ? formik.errors.isDeleted
                    : null
                }
              />
            </div> */}
          </div>
        </>
      </Modal>
    </>
  );
};
export default AddPagesModal;
