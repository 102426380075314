import { Button } from 'antd';
import { useSelector } from 'react-redux';

const SecondaryButton = ({
  title,
  type,
  btnType,
  icon,
  className = 'w-full !rounded-[6px] font-med text-[12px]',
  onClick,
  loading,
  disabled,
  ...props
}) => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  return (
    <div>
      <Button
        type={btnType}
        icon={icon}
        htmlType={type}
        style={{
          color: themeMode === 'light' ? colorTheme : '',
          backgroundColor: themeMode === 'light' ? 'transparent' : '',
        }}
        className={`${className} no-shadow-button ${
          themeMode === 'light' ? 'hover:!bg-[#F7F7FA]' : ''
        }`}
        onClick={onClick}
        loading={loading}
        disabled={disabled}
        {...props}
      >
        {title}
      </Button>
    </div>
  );
};

export default SecondaryButton;
