import { Input } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { themeInputColor } from 'utils/constant';
import InputFieldCustomForm from './CustomFieldsOutlinedForm';

const TextField = ({
  formik,
  id,
  label,
  ref,
  required = true,
  maxLength,
  notModal = false,
  type = 'text',
  disabled = false,
  onChange,
}) => {
  const { themeMode } = useSelector((state) => state?.states);
  const handleChange = (e) => {
    const { value } = e.target;
    if (type === 'number' && value.length > maxLength) {
      return;
    }
    formik.handleChange(e);
    formik.setFieldTouched(id, true, false);
  };

  return (
    <>
      <div className="wrapper-fields">
        <InputFieldCustomForm
          label={label}
          name={id}
          value={formik.values[id]}
          error={formik.touched[id] && Boolean(formik.errors[id])}
          errorMessage={formik.errors[id]}
          required={required}
          notModal={notModal}
        >
          <Input
            id={id}
            name={id}
            prefix={<></>}
            type={type}
            ref={ref}
            maxLength={type === 'text' ? maxLength : undefined} // Only apply maxLength for text inputs
            value={formik.values[id]}
            disabled={disabled}
            autoComplete={`new-${id}`}
            onChange={onChange ? onChange : handleChange}
            onBlur={formik.handleBlur}
            style={{
              border: !(formik.touched[id] && Boolean(formik.errors[id]))
                ? themeMode === 'light'
                  ? `1px solid ${themeInputColor.lightBorderColor}`
                  : `1px solid ${themeInputColor.darkBorderColor}`
                : `1px solid ${themeInputColor.errorBorderColor}`,
              borderRadius: '5px',
              caretColor:
                themeMode === 'light'
                  ? themeInputColor.lightCaretColor
                  : themeInputColor.darkCaretColor,
              color:
                themeMode === 'light'
                  ? themeInputColor.lightTextColor
                  : themeInputColor.darkTextColor,
            }}
          />
        </InputFieldCustomForm>
      </div>
    </>
  );
};

export default TextField;
