import { Table } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Status from 'components/Status';
import ToastNotification from 'components/ToastNotification';
import { delete_page, get_page } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { usePermissionVariables } from 'Hooks/HasPermission';
import { useEffect, useMemo, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { transformDataPages } from 'utils/helper';
import AddPagesModal from './components/AddPagesModal';
import EditPagesModal from './components/EditPagesModal';

const Pages = () => {
  const { useGetRequest, putRequest, deleteRequest } = useApiClient();
  const { Pages_View, Pages_Add, Pages_Edit, Pages_Delete } =
    usePermissionVariables();
  const { data, isLoading, refetch } = useGetRequest(get_page);
  const [checkedStates, setCheckedStates] = useState({});
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const navigate = useNavigate();

  const transformData = transformDataPages(data?.data || []);
  // States
  const [pageNumber, setPageNumber] = useState(1);
  const [openAddPagesModal, setOpenPagesModal] = useState(false);
  const [openEditPagesModal, setOpenEditPagesModal] = useState(false);
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({});
  const [rowData, setRowData] = useState({});
  const [filters, setFilters] = useState({});
  const [expandedMenuKey, setExpandedMenuKey] = useState(null);

  const delHandler = (record) => {
    deleteRequest(`${delete_page}${record?.pageId}`)
      .then((res) => {
        if (res?.success) {
          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };

  const menuColumns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      key: 'sr',
      width: 80,
      ellipsis: true,
    },
    {
      title: 'Menu Name',
      dataIndex: 'menuName',
      key: 'menuName',
      ellipsis: true,
    },
  ];
  const pageColumns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      key: 'sr',
      width: 80,
      ellipsis: true,
    },
    {
      title: <div className="flex justify-between items-center">Page Name</div>,
      ellipsis: true,
      showSorterTooltip: false,
      dataIndex: 'pageName',
      // width: isMobile ? undefined : 200,
      render: (text, record) => (
        <>
          <span className="">{text}</span>
        </>
      ),
    },
    {
      title: <span className="flex items-center justify-center">Status</span>,
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      ellipsis: true,
      width: 300,
      render: (tag, record) => {
        return (
          <span>
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },
    {
      title: <span className=" flex items-center justify-center">Action</span>,
      dataIndex: '',
      ellipsis: true,
      width: 100,
      render: (text, record) => (
        <span className=" flex justify-center items-center space-x-2">
          {Pages_Edit && (
            <div
              className="  p-[5px] rounded-sm "
              style={{
                backgroundColor: themeMode === 'light' ? 'white' : '#121212',
              }}
              onClick={() => {
                setRowData(record);
                setOpenEditPagesModal(true);
              }}
            >
              <MdOutlineModeEditOutline className="  w-[15px] h-[15px] cursor-pointer" />
            </div>
          )}
          {/* {Pages_Delete && (
            <div
              className="p-[5px] rounded-sm"
              style={{
                backgroundColor: themeMode === 'light' ? 'white' : '#121212',
              }}
            >
              <PopConfirm
                title="Delete"
                description="Are you sure to delete this page? "
                placement="left"
                onClick={() => delHandler(record)}
                icon={
                  <div className=" flex items-center justify-center ">
                    <QuestionCircleOutlined
                      style={{ color: 'red', marginTop: '5px' }}
                    />
                  </div>
                }
              >
                <MdDeleteOutline className=" w-[15px] h-[15px] cursor-pointer" />
              </PopConfirm>
            </div>
          )} */}
        </span>
      ),
    },
  ];
  const onChange = (pagination, filters, sorter, extra) => {
    setPageNumber(pagination.current);
    setRowsPerPage(pagination.pageSize);
    setSortOrder(sorter);
    setFilters(filters);
  };
  const pageSizeOptions = useMemo(() => {
    const options = [];
    for (let i = 1; i * 10 <= totalRecord; i++) {
      options.push((i * 10).toString());
    }
    return options;
  }, [totalRecord]);
  const ExpandedRowRender = ({ record }) => (
    <Table
      columns={pageColumns}
      dataSource={record.pages.map((page) => ({
        ...page,
        key: `${record.menuId}-${page.pageId}`,
      }))}
      bordered
      scroll={{ x: true }}
      pagination={false}
      rowKey="pageId"
    />
  );
  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <div className="flex items-center justify-center h-full">
          <SlArrowLeft
            className="w-[12px] h-[12px]"
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
            }}
          />
        </div>
      );
    }
    if (type === 'next') {
      return (
        <div className="flex items-center justify-center h-full">
          <SlArrowRight
            className="w-[12px] h-[12px]"
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
            }}
          />
        </div>
      );
    }
    return originalElement;
  };
  useEffect(() => {
    if (data) setTotalRecord(transformData?.length);
  }, [data]);
  return (
    <>
      {Pages_Add && (
        <div className="sm:flex justify-end">
          <PrimaryButton
            title="Add Page"
            icon={<FaPlus />}
            onClick={() => {
              setOpenPagesModal(true);
            }}
          />
        </div>
      )}

      <div className={`mt-20 ${themeMode === 'light' ? '' : 'dark-mode'}`}>
        <Table
          dataSource={transformData?.map((menu) => ({
            ...menu,
            key: `${menu.menuId}`,
          }))}
          columns={menuColumns}
          expandable={{
            expandedRowRender: (record) => (
              <ExpandedRowRender record={record} />
            ),
            rowExpandable: (record) => record.pages && record.pages.length > 0,
            expandedRowKeys: expandedMenuKey ? [expandedMenuKey] : [],
            onExpand: (expanded, record) => {
              setExpandedMenuKey(expanded ? record.key : null);
            },
          }}
          className={`${
            themeMode === 'light' ? 'light-table' : 'dark-table'
          } !overflow-x-auto`}
          onChange={onChange}
          size="small"
          bordered
          scroll={{ x: true }}
          pagination={{
            current: pageNumber,
            pageSize: rowsPerPage,
            total: totalRecord,
            showSizeChanger: true,
            size: 'small',
            hideOnSinglePage: totalRecord > 10 ? false : true,
            // position: ['bottomCenter'],
            responsive: true,
            itemRender: itemRender,
            style: {
              fill: themeMode === 'light' ? colorTheme : '#9FA8DA',
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
            },
            pageSizeOptions: pageSizeOptions,
            onShowSizeChange: (current, size) => {
              setRowsPerPage(size);
              setPageNumber(1);
            },
            className:
              themeMode === 'light' ? 'light-pagination' : 'dark-pagination',
          }}
          loading={isLoading}
        />
      </div>
      {openAddPagesModal && (
        <AddPagesModal
          setOpenPagesModal={setOpenPagesModal}
          openAddPagesModal={openAddPagesModal}
          refetch={refetch}
        />
      )}
      {openEditPagesModal && rowData && (
        <EditPagesModal
          setOpenEditPagesModal={setOpenEditPagesModal}
          openEditPagesModal={openEditPagesModal}
          record={rowData}
          refetch={refetch}
        />
      )}
    </>
  );
};
export default Pages;
