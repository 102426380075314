import { Popconfirm } from 'antd';
import React from 'react';
import './style.css';
import { useSelector } from 'react-redux';

const PopConfirm = ({
  title,
  description,
  placement,
  icon,
  onClick,
  children,
}) => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  return (
    <>
      <div>
        <Popconfirm
          title={<span className="font-semiBold text-[13px]">{title}</span>}
          description={<span className="font-reg text-[12px]">{description}</span>}
          placement={placement}
          icon={icon}
          overlayInnerStyle={{
            backgroundColor: themeMode === 'light' ? '#F7F7FA' : '#121212',
          }}
          overlayClassName={`${
            themeMode === 'light' ? 'light-theme' : 'dark-theme'
          } custom-popover `}
          okButtonProps={{ className: 'custom-ok-button' }}
          cancelButtonProps={{ className: 'custom-cancel-button' }}
          onConfirm={onClick}
          //   onOpenChange={() => console.log('open change')}
        >
          {children}
        </Popconfirm>
      </div>
    </>
  );
};

export default PopConfirm;
