import { EyeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { usePermissionVariables } from 'Hooks/HasPermission';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SearchField from 'components/InputFields/SearchField';
import PopConfirm from 'components/PopConfirm';
import PopOver from 'components/PopOver';
import Status from 'components/Status';
import Table from 'components/Table';
import { delete_email_template } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddEmailTemplatesModal from './components/AddEmailTemplatesModal';
import EditEmailTemplatesModal from './components/EditEmailTemplatesModal';

const EmailTemplates = () => {
  const {
    Email_Template_Delete,
    Email_Template_Edit,
    Email_Template_Add,
    Email_Template_View,
  } = usePermissionVariables();
  const [openAddEmailTemplatesModal, setOpenAddEmailTemplatesModal] =
    useState(false);
  const [openEditEmailTemplatesModal, setOpenEditEmailTemplatesModal] =
    useState(false);
  const [selectedRecord, setSelectedRecord] = useState({
    templateId: 1,
    tenantId: 0,
    templateName: '',
    subject: '',
    body: '',
    isActive: true,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const { useGetRequest, deleteRequest } = useApiClient();

  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const { data, error, isLoading, refetch } = useGetRequest(
    `api/GetAllEmailTemplates?tenantid=${process.env.REACT_APP_TENANT_ID}&pagenumber=${pageNumber}&pagesize=${rowsPerPage}&searchquery=${search}`
  );

  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const navigate = useNavigate();

  const delHandler = (record) => {
    deleteRequest(`${delete_email_template}${record?.templateId}`)
      .then((res) => {
        // if (res?.success) {
        const newTotalRecord = totalRecord - 1; // Decrement the total record count
        setTotalRecord(newTotalRecord); // Update the state

        // Check if we need to change the page number
        if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
          setPageNumber(pageNumber - 1); // Redirect to the previous page
        }
        refetch();
        toast.success('Email Template Deleted Successfully');
        //   toast.success(res.message);
        // } else {
        //   toast.error(res.message);
        // }
      })
      .catch((err) => toast.error('Something went wrong'));
  };

  const columns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      key: 'sr',
      // align: 'center',
      ellipsis: true,
      width: 80,
      render: (text, row, index) => index + 1,
    },
    {
      title: (
        <div className="flex justify-between items-center">Template Name</div>
      ),
      dataIndex: 'templateName',
      sorter: (a, b) => a?.templateName?.localeCompare(b.templateName),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'templateName'
          ? sortOrder.order
          : 'ascend',
      showSorterTooltip: false,
      ellipsis: true,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    { title: 'Subject', dataIndex: 'subject', ellipsis: true },
    {
      title: <span className="flex items-center justify-center">Status</span>,
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      width: 300,
      ellipsis: true,
      render: (tag, record) => {
        return (
          <span>
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },
    {
      title: 'View',
      dataIndex: 'body',
      className: 'max-w-[250px]',
      ellipsis: true,
      align: 'center',
      render: (text, record) => {
        const content = <div dangerouslySetInnerHTML={{ __html: text }} />;
        return (
          <span>
            <PopOver content={content} title={'Email Template'} placement="top">
              <div className="truncate">
                <EyeOutlined
                  className="cursor-pointer"
                  style={{
                    color: themeMode === 'light' ? colorTheme : '#9f9b93',
                  }}
                />
              </div>
            </PopOver>
          </span>
        );
      },
    },
    ...(Email_Template_Edit || Email_Template_Delete
      ? [
          {
            title: (
              <span className=" flex items-center justify-center">Action</span>
            ),
            ellipsis: true,
            dataIndex: '',
            render: (text, record) => (
              <span className="flex justify-center items-center space-x-2">
                {Email_Template_Edit && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                    onClick={() => {
                      setOpenEditEmailTemplatesModal(true);
                      setSelectedRecord({
                        templateId: record?.templateId,
                        tenantId: record?.tenantId,
                        templateName: record?.templateName,
                        subject: record?.subject,
                        body: record?.body,
                        isActive: record?.isActive,
                      });
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                )}
                {Email_Template_Delete && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this task? "
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    >
                      <MdDeleteOutline className=" w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                )}
              </span>
            ),
          },
        ]
      : []),
  ];
  useEffect(() => {
    if (data) {
      setTotalRecord(data?.totalRecords);
    }
  }, [data]);
  return (
    <div>
      <div className="md:flex space-y-[20px] md:space-y-0 justify-between mt-10 mb-26">
        <SearchField
          htmlFor="search"
          id="myInputID"
          name="search"
          placeholder={'Search'}
          value={debouncedSearch}
          setDebouncedSearch={setDebouncedSearch}
          setPageNumber={setPageNumber}
          searchLoading={searchLoading}
          setSearchLoading={setSearchLoading}
          refetch={refetch}
          debouncedSearch={debouncedSearch}
          setSearch={setSearch}
        />
        {Email_Template_Add && (
          <div className="sm:flex justify-end">
            <PrimaryButton
              title="Add Email Template "
              icon={<FaPlus />}
              onClick={() => setOpenAddEmailTemplatesModal(true)}
            />
          </div>
        )}
      </div>
      <Table
        data={data?.data}
        columns={columns}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        setTotalRecord={setTotalRecord}
        totalRecord={totalRecord}
        setPageNumber={setPageNumber}
        setRowsPerPage={setRowsPerPage}
        setSortOrder={setSortOrder}
        setFilters={setFilters}
        loading={isLoading}
      />
      {openAddEmailTemplatesModal && (
        <AddEmailTemplatesModal
          setOpenAddEmailTemplatesModal={setOpenAddEmailTemplatesModal}
          openAddEmailTemplatesModal={openAddEmailTemplatesModal}
          refetch={refetch}
        />
      )}
      {openEditEmailTemplatesModal && (
        <EditEmailTemplatesModal
          setOpenEditEmailTemplatesModal={setOpenEditEmailTemplatesModal}
          openEditEmailTemplatesModal={openEditEmailTemplatesModal}
          selectedRecord={selectedRecord}
          refetch={refetch}
        />
      )}
    </div>
  );
};
export default EmailTemplates;
