import { QuestionCircleOutlined } from '@ant-design/icons';
import { usePermissionVariables } from 'Hooks/HasPermission';
import { Breadcrumb, Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SearchField from 'components/InputFields/SearchField';
import PopConfirm from 'components/PopConfirm';
import Status from 'components/Status';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import useApiClient from 'config/useAxiosClient';
import Layout from 'layout/AdminLayout';
import { useEffect, useState } from 'react';
import { BsBuildingFillLock } from 'react-icons/bs';
import { FaPlus } from 'react-icons/fa6';
import { GoHome } from 'react-icons/go';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GET_TENANTS_KEY, GET_VEHICLES_KEY } from 'utils/queryKey';
const { Title } = Typography;

const TenantManagement = () => {
  const { useGetRequest, deleteRequest } = useApiClient();
  const {
    Tenant_Management_Add,
    Tenant_Management_Edit,
    Tenant_Management_Delete,
  } = usePermissionVariables();

  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  // States
  const [openAddUserModal, setOpenUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const { data, error, isLoading, refetch } = useGetRequest(
    `/api/GetAllTenants?pagenumber=${pageNumber}&pagesize=${rowsPerPage}&searchquery=${search}`,
    {},
    GET_TENANTS_KEY
  );

  const delHandler = (record) => {
    deleteRequest(`/api/DeleteTenant?id=${record?.tenantId}`)
      .then((res) => {
        if (res?.success) {
          const newTotalRecord = totalRecord - 1; // Decrement the total record count
          setTotalRecord(newTotalRecord); // Update the state

          // Check if we need to change the page number
          if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1); // Redirect to the previous page
          }
          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };

  const columns = [
    {
      title: <div className="flex justify-between items-center">Name</div>,
      dataIndex: 'tenantName',
      ellipsis: true,
      sorter: (a, b) => a.tenantName.localeCompare(b.tenantName),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'tenantName'
          ? sortOrder.order
          : 'ascend',
      showSorterTooltip: false,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'tenantEmail',
      ellipsis: true,
      render: (text, record) => (
        <>
          <a
            className="!underline cursor-pointer hover:underline hover:text-inherit"
            href={`mailto:${text}`}
            // target="_blank"
            rel="noopener noreferrer"
          >
            {text}
          </a>
        </>
      ),
    },
    {
      title: 'Tenant Phone',
      dataIndex: 'tenantPhone',
      ellipsis: true,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      ellipsis: true,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },

    {
      title: 'State',
      dataIndex: 'state',
      ellipsis: true,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: 'City',
      dataIndex: 'city',
      ellipsis: true,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },

    {
      title: <span className="flex items-center justify-center">Status</span>,
      dataIndex: 'isActive',
      ellipsis: true,
      render: (tag, record) => {
        return (
          <span className="flex items-center justify-center">
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },
    ...(Tenant_Management_Edit || Tenant_Management_Delete
      ? [
          {
            title: (
              <span className="flex items-center justify-center">Action</span>
            ),
            dataIndex: '',
            ellipsis: true,
            width: 100,
            render: (text, record) => (
              <span className="flex justify-center items-center space-x-2">
                {Tenant_Management_Edit && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                    onClick={() => {
                      navigate(`/edit-tenant/${record.tenantId}`);
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                )}
                {Tenant_Management_Delete && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure you want to delete this tenant?"
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={
                        <div className="flex items-center justify-center">
                          <QuestionCircleOutlined
                            style={{ color: 'red', marginTop: '5px' }}
                          />
                        </div>
                      }
                    >
                      <MdDeleteOutline className="w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                )}
              </span>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (data) {
      setTotalRecord(data?.data?.length);
    }
  }, [data]);

  return (
    <Layout>
      <>
        <div>
          <div className=" mb-[30px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
              }}
            >
              Tenant Management
            </Title>
            <div className="flex justify-between items-center ">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className="flex items-center space-x-4 ">
                            <BsBuildingFillLock
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />

                            <span
                              className="font-bold text-[13px]"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              Tenant Management
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="sm:flex space-y-[20px] md:space-y-0 justify-between items-center  mt-10 mb-26">
            <SearchField
              htmlFor="search"
              id="myInputID"
              name="search"
              placeholder={'Search by Name'}
              value={debouncedSearch}
              setDebouncedSearch={setDebouncedSearch}
              setPageNumber={setPageNumber}
              searchLoading={searchLoading}
              setSearchLoading={setSearchLoading}
              refetch={refetch}
              debouncedSearch={debouncedSearch}
              setSearch={setSearch}
            />
            {Tenant_Management_Add && (
              <div>
                <PrimaryButton
                  title="Add Tenant"
                  icon={<FaPlus />}
                  onClick={() => {
                    navigate('/add-tenant');
                  }}
                />
              </div>
            )}
          </div>
          <div>
            <Table
              data={data?.data || []}
              columns={columns}
              pageNumber={pageNumber}
              setTotalRecord={setTotalRecord}
              rowsPerPage={rowsPerPage}
              totalRecord={totalRecord}
              setPageNumber={setPageNumber}
              setRowsPerPage={setRowsPerPage}
              setSortOrder={setSortOrder}
              setFilters={setFilters}
              loading={isLoading}
            />
          </div>
        </div>
      </>
    </Layout>
  );
};
export default TenantManagement;
