import { QuestionCircleOutlined } from '@ant-design/icons';
import { usePermissionVariables } from 'Hooks/HasPermission';
import { Typography } from 'antd';
import PopConfirm from 'components/PopConfirm';
import Table from 'components/Table';
import { get_loges } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useState } from 'react';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const { Title } = Typography;

const Logs = () => {
  const queryClient = useQueryClient();
  const { useGetRequest, deleteRequest } = useApiClient();
  const { Logs_Add, Logs_Edit, Logs_Delete } = usePermissionVariables();
  const { data, error, isLoading, refetch } = useGetRequest(get_loges);
  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [pageNumber, setPageNumber] = useState(1);
  const [openAddGeneralModal, setOpenGeneralModal] = useState(false);
  const [openEditGeneralModal, setOpenEditGeneralModal] = useState(false);
  const [rowData, setRowData] = useState({});

  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });

  const delHandler = (record) => {
    deleteRequest(`/api/Roles/Delete?role=${record?.roleId}`)
      .then((res) => {
        if (res?.success) {
          refetch();
          toast.success(res?.message);
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => toast.error('Something went wrong'));
  };
  const columns = [
    {
      title: 'Time Stamp',
      dataIndex: 'roleName',
      ellipsis: true,
      sorter: (a, b) => a.roleName.localeCompare(b.roleName),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'roleName'
          ? sortOrder.order
          : 'ascend',
      showSorterTooltip: false,
    },
    { title: 'RPM', dataIndex: 'roleName', ellipsis: true },
    { title: 'ODO METER', dataIndex: 'roleName', ellipsis: true },
    { title: 'Power ON', dataIndex: 'roleName', ellipsis: true },
    { title: 'Power OFF ', dataIndex: 'roleName', ellipsis: true },
    { title: 'Moving ', dataIndex: 'roleName', ellipsis: true },
    { title: 'Idling ', dataIndex: 'roleName', ellipsis: true },
    { title: 'Location ', dataIndex: 'roleName', ellipsis: true },
    {
      title: <span className=" flex items-center justify-center">Action</span>,
      dataIndex: '',
      width: 100,
      ellipsis: true,
      render: (text, record) => (
        <span className=" flex justify-center items-center space-x-2">
          <div
            className="  p-[5px] rounded-sm "
            style={{
              backgroundColor: themeMode === 'light' ? 'white' : '#121212',
            }}
            onClick={() => {
              setRowData(record);
              setOpenEditGeneralModal(true);
            }}
          >
            <MdOutlineModeEditOutline className="  w-[15px] h-[15px] cursor-pointer" />
          </div>
          <div
            className="p-[5px] rounded-sm"
            style={{
              backgroundColor: themeMode === 'light' ? 'white' : '#121212',
            }}
          >
            <PopConfirm
              title="Delete"
              description="Are you sure to delete this task? "
              placement="left"
              onClick={() => delHandler(record)}
              icon={
                <div className=" flex items-center justify-center ">
                  <QuestionCircleOutlined
                    style={{ color: 'red', marginTop: '5px' }}
                  />
                </div>
              }
            >
              <MdDeleteOutline className=" w-[15px] h-[15px] cursor-pointer" />
            </PopConfirm>
          </div>
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="">
        <Table
          data={data?.data || []}
          loading={isLoading}
          columns={columns}
          pageNumber={pageNumber}
          rowsPerPage={rowsPerPage}
          totalRecord={totalRecord}
          setTotalRecord={setTotalRecord}
          setPageNumber={setPageNumber}
          setRowsPerPage={setRowsPerPage}
          setSortOrder={setSortOrder}
          setFilters={setFilters}
        />
      </div>
    </>
  );
};
export default Logs;
