import { QuestionCircleOutlined } from '@ant-design/icons';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import PopConfirm from 'components/PopConfirm/index';
import Status from 'components/Status/index';
import Table from 'components/Table';
import { delete_country, get_states } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { usePermissionVariables } from 'Hooks/HasPermission';
import { useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { VehicleData } from 'utils/constant';

const Vehicles = () => {
  const { Vehicles_Delete, Vehicles_Edit, Vehicles_Add } =
    usePermissionVariables();

  const [pageNumber, setPageNumber] = useState(1);
  const { useGetRequest, deleteRequest } = useApiClient();
  const { data, isLoading, refetch } = useGetRequest(get_states);
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const navigate = useNavigate();

  const delHandler = (record) => {
    deleteRequest(`${delete_country}${record?.countryId}`)
      .then((res) => {
        // if (res?.success) {
        refetch();
        toast.success('Country Deleted Successfully');
        //   toast.success(res.message);
        // } else {
        //   toast.error(res.message);
        // }
      })
      .catch((err) => toast.error('Something went wrong'));
  };

  const columns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      key: 'sr',
      ellipsis: true,
      width: 80,
      render: (text, row, index) => index + 1,
    },
    {
      title: (
        <div className="flex justify-between items-center">Vehicle Name</div>
      ),

      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a?.name?.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'name ' ? sortOrder.order : 'ascend',

      ellipsis: true,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: 'License Plate',
      dataIndex: 'licensePlate',
      key: 'licensePlate',
      ellipsis: true,
    },
    {
      title: 'Vehicle Type',
      dataIndex: 'vehicleType',
      key: 'VehicleType',
      ellipsis: true,
    },
    {
      title: <span className="flex items-center justify-center">Status</span>,
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      width: 300,
      ellipsis: true,
      render: (tag, record) => {
        return (
          <span>
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },
    ...(Vehicles_Edit || Vehicles_Delete
      ? [
          {
            title: (
              <span className=" flex items-center justify-center">Action</span>
            ),
            dataIndex: '',
            ellipsis: true,
            width: 100,
            render: (text, record) => (
              <span className="flex justify-center items-center space-x-2">
                {Vehicles_Edit && (
                  <div
                    className=" p-[5px] rounded-sm"
                    onClick={() => {
                      navigate(`/edit-vehicle/${1}`);
                    }}
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                )}

                {Vehicles_Delete && (
                  <div
                    className=" p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this task? "
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    >
                      <MdDeleteOutline className=" w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                )}
              </span>
            ),
          },
        ]
      : []),
  ];

  return (
    <div>
      {Vehicles_Add && (
        <div className="sm:flex justify-end">
          <PrimaryButton
            title="Add Vehicles"
            icon={<FaPlus />}
            onClick={() => {
              navigate('/add-vehicle');
            }}
          />
        </div>
      )}

      <Table
        data={VehicleData}
        columns={columns}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        setTotalRecord={setTotalRecord}
        totalRecord={totalRecord}
        setPageNumber={setPageNumber}
        setRowsPerPage={setRowsPerPage}
        setSortOrder={setSortOrder}
        setFilters={setFilters}
        loading={isLoading}
      />
    </div>
  );
};
export default Vehicles;
