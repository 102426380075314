import { Breadcrumb, Tabs, Typography } from 'antd';
import { usePermissionVariables } from 'Hooks/HasPermission';
import Layout from 'layout/AdminLayout';
import { BsThreeDots } from 'react-icons/bs';
import { GoHome } from 'react-icons/go';
import { RiListSettingsFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Cities from './components/Cities';
import Countries from './components/Countries';
import Currencies from './components/Currencies';
import DeviceType from './components/DeviceType';
import DeviceTypeModel from './components/DeviceTypeModel';
import EmailGateway from './components/EmailGateway';
import EmailTemplates from './components/EmailTemplates';
import SMSGateway from './components/SMSGateway';
import SMSTemplate from './components/SMSTemplate';
import States from './components/States';
import TimeZones from './components/TimeZones';
import UnitOfMeasure from './components/UnitOfMeasure';
import VehicleType from './components/VehicleType';
import ZipCodes from './components/ZipCodes';
import { useEffect, useState } from 'react';
const { Title } = Typography;

const Settings = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const {
    Email_Template_View,
    Subscription_Plans_View,
    SMS_Gateway_View,
    Email_Gateway_View,
    Time_Zone_View,
    Unit_of_Measure_View,
    Device_Type_View,
    Device_Model_View,
    Vehicle_Type_View,
    Currency_Exchange_Rates_View,
    SMS_Template_View,
    Currencies_View,
    Zip_Codes_View,
    Cities_View,
    States_View,
    Countries_View,
  } = usePermissionVariables();

  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const [searchParams] = useSearchParams();

  const items = [
    Countries_View && {
      key: '1',
      label: 'Countries',
      children: <Countries />,
    },
    States_View && {
      key: '2',
      label: 'States',
      children: <States />,
    },
    Cities_View && {
      key: '3',
      label: 'Cities',
      children: <Cities />,
    },
    Zip_Codes_View && {
      key: '4',
      label: 'Zip Codes',
      children: <ZipCodes />,
    },
    Currencies_View && {
      key: '5',
      label: 'Currencies',
      children: <Currencies />,
    },
    // Currency_Exchange_Rates_View && {
    //   key: '6',
    //   label: 'Currency Exchange Rates',
    //   children: <CurrencyRateExchange />,
    // },
    Vehicle_Type_View && {
      key: '7',
      label: 'Vehicle Type',
      children: <VehicleType />,
    },
    Device_Type_View && {
      key: '8',
      label: 'Device Type',
      children: <DeviceType />,
    },
    Device_Model_View && {
      key: '9',
      label: 'Device Model',
      children: <DeviceTypeModel />,
    },
    Unit_of_Measure_View && {
      key: '10',
      label: 'Unit of Measure',
      children: <UnitOfMeasure />,
    },
    Time_Zone_View && {
      key: '11',
      label: 'Time Zone',
      children: <TimeZones />,
    },
    SMS_Gateway_View && {
      key: '12',
      label: 'SMS Gateway',
      children: <SMSGateway />,
    },
    SMS_Template_View && {
      key: '13',
      label: 'SMS Template',
      children: <SMSTemplate />,
    },
    Email_Gateway_View && {
      key: '14',
      label: 'Email Gateway',
      children: <EmailGateway />,
    },
    Email_Template_View && {
      key: '15',
      label: 'Email Template',
      children: <EmailTemplates />,
    },
  ];

  const [activeKey, setActiveKey] = useState(null); 

  useEffect(() => {
    const keyFromURL = searchParams.get('active') || '1';
    setActiveKey(
      items?.some((item) => item.key === keyFromURL) ? keyFromURL : '1'
    );
  }, [searchParams, items]);

  const onChange = (key) => {
    setActiveKey(key);
    navigate(`/settings?active=${key}`);
  };

  return (
    <Layout>
      <>
        <div>
          <Title
            level={4}
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
              fontFamily: 'inter-bold',
            }}
          >
            Settings
          </Title>
          <div className="flex justify-between items-center mb-20">
            <div>
              <Breadcrumb
                separator="/"
                items={[
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <GoHome />
                          <span
                            className=" cursor-pointer font-reg text-[13px]"
                            onClick={() => {
                              navigate('/dashboard');
                            }}
                          >
                            Dashboard
                          </span>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <RiListSettingsFill
                            style={{
                              color: themeMode === 'light' ? colorTheme : '',
                            }}
                          />
                          <span
                            className=" font-bold text-[13px]"
                            style={{
                              color: themeMode === 'light' ? colorTheme : '',
                            }}
                          >
                            Admin Setup
                          </span>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </div>

          <Tabs
            // tabPosition={isMobile ? 'left' : ''}
            activeKey={activeKey}
            items={items}
            type="card"
            onChange={onChange}
            moreIcon={
              <span>
                <BsThreeDots
                  style={{
                    color: themeMode === 'light' ? colorTheme : '#939BC9',
                  }}
                />
              </span>
            }
            size="small"
          />
        </div>
      </>
    </Layout>
  );
};
export default Settings;
