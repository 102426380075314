import { Button, Modal } from 'antd';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
import Checkbox from 'components/InputFields/Checkbox';
import ToastNotification from 'components/ToastNotification';
import useAxiosClientTMS from 'config/useAxiosClientTMS';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { createOptions } from 'utils/helper';
import useValidationSchema from 'validation/useValidationSchema';

const EditUnitesModal = ({
  openEditUnitesModal,
  setOpenEditUnitesModal,
  refetch,
  record,
}) => {
  const { themeMode } = useSelector((state) => state?.states);
  const queryClient = useQueryClient();
  const { putRequest, useGetRequest } = useAxiosClientTMS();
  const [loading, setLoading] = useState(false);
  const { unitModalSchema } = useValidationSchema();
  const {
    data: driverData,
    isLoading: driverIsLoading,
    refetch: driverDataRefetch,
  } = useGetRequest('/api/GetdriversDropdown', {
    refetchOnMount: 'always',
  });
  const {
    data: vehiclesData,
    isLoading: vehiclesIsLoading,
    refetch: vehiclesDataRefetch,
  } = useGetRequest('/api/GetVehiclesDropdown', {
    refetchOnMount: 'always',
  });
  const { data: trailerData, isLoading: trailerTypeIsLoading } = useGetRequest(
    'api/GetTrailerDropdown',
    {
      refetchOnMount: 'always',
    }
  );

  const driversOptions = createOptions(driverData?.data, 'name', 'id');
  const vehiclessOptions = createOptions(vehiclesData?.data, 'number', 'id');
  const trailerOptions = createOptions(
    trailerData?.data,
    'trailerNumber',
    'id'
  );
  const formik = useFormik({
    initialValues: {
      unitName: '',
      vehicleId: '',
      driverId: '',
      trailerId: '',
      trackingDeviceId: '',
      isActive: false,
    },
    validationSchema: unitModalSchema,
    onSubmit: ({
      unitName,
      vehicleId,
      driverId,
      trailerId,
      isActive,
      trackingDeviceId,
    }) => {
      setLoading(true);
      const paylaod = {
        unitId: record?.unitId,
        unitName: unitName,
        vehicleId: vehicleId,
        driverId: driverId,
        trailerId: trailerId,
        trackingDeviceId: 1,
        isActive: isActive,
      };
      putRequest(`/api/UpdateUnit`, paylaod)
        .then((res) => {
          if (res?.success) {
            refetch();
            formik.resetForm();
            ToastNotification('success', res.message, themeMode);
            setOpenEditUnitesModal(false);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const handleCancel = () => {
    setOpenEditUnitesModal(false);
    formik.resetForm();
  };
  useEffect(() => {
    if (record) {
      formik.setValues({
        unitName: record?.unitName,
        trailerId: record?.trailerId,
        driverId: record?.driverId,
        vehicleId: record?.vehicleId,
        isActive: record?.isActive,
      });
    }
  }, [record, openEditUnitesModal]);

  return (
    <>
      <Modal
        open={openEditUnitesModal}
        title={<span className=" text-[18px]">Edit Units</span>}
        width={600}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <>
          <div className=" grid grid-cols-12 gap-x-20  py-[10px]">
            <div className="col-span-6">
              <TextField
                formik={formik}
                id={'unitName'}
                label={'Unit Name'}
                maxLength={30}
              />
            </div>
            <div className="col-span-6 ">
              <SelectField
                formik={formik}
                id={'vehicleId'}
                label={'Vehicle'}
                optionId={'value'}
                optionName={'label'}
                optionData={vehiclessOptions}
                isLoading={vehiclesIsLoading}
              />
            </div>
            <div className="col-span-6 ">
              <SelectField
                formik={formik}
                id={'driverId'}
                label={'Driver'}
                optionId={'value'}
                optionName={'label'}
                optionData={driversOptions}
                isLoading={driverIsLoading}
              />
            </div>
            <div className="col-span-6 ">
              <SelectField
                formik={formik}
                id={'trailerId'}
                label={'Trailer'}
                optionId={'value'}
                optionName={'label'}
                optionData={trailerOptions}
                isLoading={trailerTypeIsLoading}
              />
            </div>
            <div className="col-span-6 ">
              <SelectField
                formik={formik}
                id={'trackingDeviceId'}
                label={'Tracking Device'}
                optionId={'value'}
                optionName={'label'}
                optionData={driversOptions}
                isLoading={driverIsLoading}
              />
            </div>
            <div className="col-span-12">
              <Checkbox
                title="IsActive"
                id="isActive"
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                error={formik.errors.isActive}
                errorMessage={
                  formik.touched.isActive && formik.errors.isActive
                    ? formik.errors.isActive
                    : null
                }
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default EditUnitesModal;
