import { Checkbox, Dropdown } from 'antd';
import { useState } from 'react';
import { FaFilter } from 'react-icons/fa6';
import { useSelector } from 'react-redux';

const FleetFilter = ({ setFilter, filter }) => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const [checkedKeys, setCheckedKeys] = useState([]);

  const handleCheckboxChange = (checkedValues) => {
    if (checkedValues.length === 0) {
      // Clear all selections if nothing is checked
      setCheckedKeys([]);
      setFilter([]);
      return;
    }
    if (checkedValues.includes('all')) {
      setCheckedKeys(options.map((option) => option.value));
      setFilter(options.map((option) => option.value));
    } else {
      const filteredValues = checkedValues.filter((value) => value !== 'all');
      setCheckedKeys(filteredValues);
      setFilter(filteredValues);
    }
  };

  const options = [
    {
      label: (
        <span
          className={`text-[12px] flex ${
            themeMode === 'light' ? 'text-black' : 'text-[#9FA8DA]'
          }`}
        >
          {/* <FiTruck
            size={14}
            style={{
              marginRight: 8,
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
            }}
          /> */}
          All
        </span>
      ),
      value: 'all',
    },
    {
      label: (
        <span
          className={`text-[12px] flex ${
            themeMode === 'light' ? 'text-black' : 'text-[#9FA8DA]'
          }`}
        >
          {/* <FiTruck
            size={14}
            style={{
              marginRight: 8,
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
            }}
          /> */}
          In-Transit
        </span>
      ),
      value: 'In-Transit',
    },
    {
      label: (
        <span
          className={`text-[12px] flex ${
            themeMode === 'light' ? 'text-black' : 'text-[#9FA8DA]'
          }`}
        >
          {/* <PiSteeringWheel
            size={14}
            style={{
              marginRight: 8,
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
            }}
          /> */}
          Pending
        </span>
      ),
      value: 'Pending',
    },

    {
      label: (
        <span
          className={`text-[12px] flex ${
            themeMode === 'light' ? 'text-black' : 'text-[#9FA8DA]'
          }`}
        >
          {/* <PiSteeringWheel
            size={14}
            style={{
              marginRight: 8,
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
            }}
          /> */}
          Completed
        </span>
      ),
      value: 'Completed',
    },
    {
      label: (
        <span
          className={`text-[12px] flex ${
            themeMode === 'light' ? 'text-black' : 'text-[#9FA8DA]'
          }`}
        >
          {/* <PiSteeringWheel
            size={14}
            style={{
              marginRight: 8,
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
            }}
          /> */}
          Cancel
        </span>
      ),
      value: 'Cancel',
    },
  ];

  const dropdownContent = (
    <div
      style={{
        backgroundColor: themeMode === 'light' ? '#F7F7FA' : '#121212',
        padding: '10px',
        borderRadius: '4px',
        width: 150,
      }}
    >
      <Checkbox.Group
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        options={options}
        value={checkedKeys}
        onChange={handleCheckboxChange}
      />
    </div>
  );

  return (
    <div className="cursor-pointer">
      <Dropdown
        overlay={dropdownContent}
        trigger={['click']}
        placement=""
        overlayStyle={{ width: 200 }}
        arrow
      >
        <FaFilter color={themeMode === 'light' ? colorTheme : '#9FA8DA'} />
      </Dropdown>
    </div>
  );
};

export default FleetFilter;
