import { Avatar, Dropdown, Menu } from 'antd';
import useApiClient from 'config/useAxiosClient';
import { useState } from 'react';
import { MdLockReset } from 'react-icons/md';
import { RiShutDownLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import {
  setClientId,
  setColorTheme,
  setForgetEmail,
  setPermssionsArray,
  setTenantSecretKey,
  setThemeMode,
  setToken,
  setUserName,
} from 'store/slices';
import ResetPasswordModal from './ResetPasswordModal';

const AvatarDrop = () => {
  const token = useSelector((state) => state.states.token);
  const { themeMode, colorTheme, userName } = useSelector(
    (state) => state?.states
  );
  const dispatch = useDispatch();
  //states
  const [openResetPasswordModel, setOpenResetPasswordModel] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(['']);
  const { useGetRequestAuth } = useApiClient();
  const { refetch } = useGetRequestAuth(`logout?accesstoken=${token}`, {
    enabled: false,
  });
  const handleMenuClick = ({ key }) => {
    setSelectedKeys([key]);
    switch (key) {
      case '1':
        setOpenResetPasswordModel(true);
        break;
      case '2':
        refetch();
        dispatch(setThemeMode('light'));
        dispatch(setPermssionsArray([]));
        dispatch(setToken(''));
        dispatch(setTenantSecretKey(""));
        dispatch(setUserName(''));
        dispatch(setClientId(''));
        dispatch(setForgetEmail(''));
        dispatch(setColorTheme('#0d0c0c'));

        break;

      default:
        console.log('Unknown item clicked');
    }
  };
  const menu = (
    <Menu
      onClick={handleMenuClick}
      selectedKeys={selectedKeys}
      className=" !space-y-5 "
      style={{ backgroundColor: themeMode === 'light' ? '#F7F7FA' : '#121212' }}
    >
      <Menu.Item
        key="1"
        icon={
          <MdLockReset
            size={14}
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
            }}
          />
        }
      >
        <span
          className={` text-[12px] ${
            themeMode === 'light' ? 'text-black' : 'text-[#9FA8DA]'
          }`}
        >
          Reset Password
        </span>
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={
          <RiShutDownLine
            size={14}
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
            }}
          />
        }
      >
        <span
          className={`text-[12px] ${
            themeMode === 'light' ? 'text-black' : 'text-[#9FA8DA]'
          }`}
        >
          Logout
        </span>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <div className=" cursor-pointer">
        <Dropdown
          overlay={menu}
          trigger={['click']}
          placement="bottom"
          overlayStyle={{ width: 150 }}
        >
          {/* <Avatar
            size="default"
            icon={<MdOutlinePersonOutline />}
            //  src={<img src="" alt="avatar" />}
          /> */}
          <Avatar
            style={{ backgroundColor: '#f56a00', verticalAlign: 'middle' }}
            size="default"
            gap={1}
          >
            {userName ? userName.charAt(0).toUpperCase() : 'U'}
          </Avatar>
        </Dropdown>
      </div>
      {openResetPasswordModel && (
        <ResetPasswordModal
          setOpenResetPasswordModel={setOpenResetPasswordModel}
          openResetPasswordModel={openResetPasswordModel}
        />
      )}
    </>
  );
};

export default AvatarDrop;
