import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { Divider, Timeline } from 'antd';
import Accordion from 'components/Accordion';
import DatePickerField from 'components/CustomFields/DatePickerField';
import TimePickerField from 'components/CustomFields/TimePickerField';
import SearchField from 'components/InputFields/SearchField';
import Switch from 'components/Switch';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { FaArrowLeftLong, FaArrowRightLong } from 'react-icons/fa6';
import truckIcon from 'images/delivery-truck.svg';
import Layout from 'layout/AdminLayout';
import { useState } from 'react';
import { FiFlag } from 'react-icons/fi';
import { useSelector } from 'react-redux';
const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: 'calc(100vh - 110px)',
  borderRadius: '5px',
};
const center = {
  lat: 31.468854, // default latitude
  lng: 74.451038, // default longitude
};
const TripOverView = () => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDLGitEALPVLPoPzj7XTq2uA3Us-QutdiM',
    libraries,
  });
  const formik = useFormik({
    initialValues: { date: '' },
    onSubmit: ({}) => {},
  });
  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
  const PanelContent = () => {
    return (
      <>
        <div>
          <div className=" flex space-x-8 items-center">
            <div>
              <Switch />
            </div>
            <div className=" text-[11px]">Show the route</div>
          </div>
          <div className=" mt-[20px]">
            <div className=" flex space-x-8 items-center">
              <div>
                <FiFlag size={11} />
              </div>
              <div className=" text-[11px]">#9631</div>
            </div>
            <div className=" flex space-x-8 items-center">
              <div>
                <FiFlag size={11} />
              </div>
              <div className=" text-[11px]">#9631</div>
            </div>
            <div className=" flex space-x-8 items-center">
              <div>
                <FiFlag size={11} />
              </div>
              <div className=" text-[11px]">#9631</div>
            </div>
            <div className=" flex space-x-8 items-center">
              <div>
                <FiFlag size={11} />
              </div>
              <div className=" text-[11px]">#9631</div>
            </div>
          </div>
          <Divider
            style={{
              borderColor: '#F0F0F0',
            }}
          />
          <div>
            <Timeline
              items={[
                {
                  children: (
                    <>
                      <div className=" text-[11px] pt-[3px]">
                        Brownolo Rd 58 London E8 4NS
                      </div>
                    </>
                  ),
                },
                {
                  children: (
                    <>
                      <div className=" text-[11px] pt-[3px]">
                        Brownolo Rd 58 London E8 4NS
                      </div>
                    </>
                  ),
                },
              ]}
            />
          </div>
          <div className=" text-center ">More Details</div>
        </div>
      </>
    );
  };
  const items = [
    {
      key: '1',
      label: <span className=" font-semiBold text-[12px]">Trip #674382</span>,
      children: <PanelContent />,
    },
    {
      key: '2',
      label: <span className=" font-semiBold text-[12px]">Order #674382</span>,
      children: <p>{text}</p>,
    },
    {
      key: '3',
      label: <span className=" font-semiBold text-[12px]">Order #674382</span>,
      children: <p>{text}</p>,
    },
  ];
  return (
    <Layout>
      <>
        <div className="flex space-x-[20px] h-auto -mx-30">
          <div className="  w-[25%] px-[10px] py-[10px]">
            <div className=" font-bold">TripOverView</div>
            <div className=" mt-[20px]">
              <SearchField
                htmlFor="search"
                id="myInputID"
                name="search"
                placeholder={'Search by Country Name'}
                value={debouncedSearch}
                setDebouncedSearch={setDebouncedSearch}
                setPageNumber={setPageNumber}
                searchLoading={searchLoading}
                setSearchLoading={setSearchLoading}
                // refetch={refetch}
                debouncedSearch={debouncedSearch}
                setSearch={setSearch}
                search={search}
              />
            </div>
            <div className=" flex justify-between items-center space-x-10">
              <div className=" w-full">
                <DatePickerField
                  formik={formik}
                  id={'date'}
                  label={'Date'}
                  minDate={dayjs()}
                  notModal={true}
                />
              </div>
              <div className=" w-[120px]">
                <TimePickerField
                  formik={formik}
                  id={'date'}
                  label={'Time'}
                  notModal={true}
                  suffix={false}
                />
              </div>
            </div>
            <div>
              <Accordion items={items} />
            </div>
          </div>
          <div className="  w-[75%]">
            {isLoaded ? (
              <>
                <div>
                  <GoogleMap
                    className="rounded-sm"
                    mapContainerStyle={mapContainerStyle}
                    options={{
                      minZoom: 3,
                      zoomControl: true,
                      streetViewControl: true,
                      mapTypeControl: true,
                      mapTypeControlOptions: {
                        // eslint-disable-next-line no-undef
                        position: google.maps.ControlPosition.TOP_LEFT,
                      },
                      fullscreenControl: true,
                      fullscreenControlOptions: {
                        // eslint-disable-next-line no-undef
                        position: google.maps.ControlPosition.LEFT_BOTTOM,
                      },
                      disableDefaultUI: true,
                    }}
                    zoom={10}
                    center={center}
                  >
                    <Marker
                      position={center}
                      icon={{
                        url: truckIcon,
                        scaledSize: new window.google.maps.Size(30, 30),
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(25, 25),
                      }}
                    />
                  </GoogleMap>
                </div>
              </>
            ) : (
              <>
                <div className="w-full flex items-center justify-center">
                  Loading
                </div>
              </>
            )}
          </div>
        </div>
      </>
    </Layout>
  );
};

export default TripOverView;
