import React, { useState, useEffect, useRef } from 'react';
import { Button, Divider, Input, Modal } from 'antd';
import { useFormik } from 'formik';
// import useValidationSchema from 'validation/useValidationSchema';
import {
  Editor,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  KeyBindingUtil,
  convertFromHTML,
  ContentState,
} from 'draft-js';

import 'draft-js/dist/Draft.css';
import { stateToHTML } from 'draft-js-export-html';
import useValidationSchema from 'validation/useValidationSchema';
import { get_email_templates, update_email_template } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { themeInputColor } from 'utils/constant';
import InputFieldCustomForm from 'components/CustomFields/CustomFieldsOutlinedForm';
import { useSelector } from 'react-redux';
import Checkbox from 'components/InputFields/Checkbox';
import ToastNotification from 'components/ToastNotification';
import TextField from 'components/CustomFields/TextField';

function keyBindingFunction(event) {
  if (
    KeyBindingUtil.hasCommandModifier(event) &&
    event.shiftKey &&
    event.key === 'x'
  ) {
    return 'strikethrough';
  }

  return getDefaultKeyBinding(event);
}

const EditEmailTemplatesRateModal = ({
  openEditEmailTemplatesModal,
  setOpenEditEmailTemplatesModal,
  selectedRecord,
  refetch,
}) => {
  const { themeMode } = useSelector((state) => state.states);
  const { putRequest } = useApiClient();
  const queryClient = useQueryClient();
  const blocksFromHTML = convertFromHTML(selectedRecord?.body);
  const initialContent = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(initialContent)
  );
  const editor = useRef(null);
  function focusEditor() {
    editor?.current?.focus();
  }

  const currentStyleInline = editorState.getCurrentInlineStyle();
  const selection = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const blockKey = selection.getStartKey();
  const block = contentState.getBlockForKey(blockKey);
  const blockType = block.getType();

  //   console.log('currentStyleInline', currentStyleInline, 'blockType', blockType);
  // const inline = new Set(
  // 	currentStyleInline?._map?._list?._tail.array.map((style) => style[0]),
  // );
  const styles = {
    editor: {
      border: '1px solid #f0f0f0',
      minHeight: '15rem',
      borderRadius: '5px',
      cursor: 'text',
      padding: 20,
    },
  };

  const handleItalic = () => {
    const newState = RichUtils.toggleInlineStyle(editorState, 'ITALIC');
    setEditorState(newState);
  };

  const handleBold = () => {
    const newState = RichUtils.toggleInlineStyle(editorState, 'BOLD');
    setEditorState(newState);
  };
  const handleUnderline = () => {
    const newState = RichUtils.toggleInlineStyle(editorState, 'UNDERLINE');
    setEditorState(newState);
  };
  const handleCode = () => {
    const newState = RichUtils.toggleInlineStyle(editorState, 'CODE');
    setEditorState(newState);
  };

  const handleOl = () => {
    const newState = RichUtils.toggleBlockType(
      editorState,
      'ordered-list-item'
    );
    setEditorState(newState);
  };

  const handleH1 = () => {
    const newState = RichUtils.toggleBlockType(editorState, 'header-one');
    setEditorState(newState);
  };
  const handleH2 = () => {
    const newState = RichUtils.toggleBlockType(editorState, 'header-two');
    setEditorState(newState);
  };
  const handleH3 = () => {
    const newState = RichUtils.toggleBlockType(editorState, 'header-three');
    setEditorState(newState);
  };
  const handleH4 = () => {
    const newState = RichUtils.toggleBlockType(editorState, 'header-four');
    setEditorState(newState);
  };
  const handleH5 = () => {
    const newState = RichUtils.toggleBlockType(editorState, 'header-five');
    setEditorState(newState);
  };
  const handleH6 = () => {
    const newState = RichUtils.toggleBlockType(editorState, 'header-six');
    setEditorState(newState);
  };

  const myBlockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();
    if (type === 'blockquote') {
      return 'superFancyBlockquote';
    }
  };

  const [loading, setLoading] = useState(false);

  const { emailTemplateModalSchema } = useValidationSchema();
  const formik = useFormik({
    initialValues: {
      templateName: '',
      emailSubject: '',
      isActive: false,
    },
    validationSchema: emailTemplateModalSchema,
    onSubmit: ({ templateName, emailSubject, isActive }) => {
      setLoading(true);
      const html = stateToHTML(editorState.getCurrentContent());
      const paylaod = {
        templateId: selectedRecord?.templateId,
        tenantId: 0,
        templateName: templateName,
        subject: emailSubject,
        body: html,
        isActive: isActive || false,
      };
      putRequest(update_email_template + selectedRecord?.templateId, paylaod)
        .then((res) => {
          if (res?.success) {
            setLoading(false);
            setEditorState(() => EditorState.createEmpty());
            setOpenEditEmailTemplatesModal(false);
            // queryClient.invalidateQueries(get_email_templates);
            refetch();
            formik.resetForm();
            ToastNotification('success', res.message, themeMode);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )

        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCancel = () => {
    setEditorState(() => EditorState.createEmpty());
    setOpenEditEmailTemplatesModal(false);
    formik.resetForm();
  };

  useEffect(() => {
    focusEditor();
  }, []);

  useEffect(() => {
    if (selectedRecord) {
      formik.setValues({
        templateName: selectedRecord?.templateName || '',
        emailSubject: selectedRecord?.subject || '',
        isActive: selectedRecord?.isActive || false,
      });
    }
  }, [selectedRecord, openEditEmailTemplatesModal]);

  return (
    <>
      <Modal
        open={openEditEmailTemplatesModal}
        title={<span className=" text-[18px]">Edit Email Template </span>}
        width={800}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <>
          <div className=" grid grid-cols-12 gap-x-20  pt-[10px]">
            <div className="sm:col-span-6 col-span-12">
              <TextField
                formik={formik}
                id={'templateName'}
                label={'Template Name'}
                maxLength={30}
              />
            </div>
            <div className="sm:col-span-6 col-span-12">
              <TextField
                formik={formik}
                id={'emailSubject'}
                label={'Email Subject'}
                maxLength={30}
              />
            </div>
            <div className="col-span-12 mt-5">
              <Checkbox
                title="IsActive"
                id="isActive"
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                className="ml-4"
                error={formik.errors.isActive}
                errorMessage={
                  formik.touched.isActive && formik.errors.isActive
                    ? formik.errors.isActive
                    : null
                }
              />
            </div>
          </div>
          <Divider
            style={{ borderColor: themeMode === 'dark' ? '#EBE7E5' : null }}
          />
          <div className="flex flex-wrap gap-4 mb-16 ">
            <Button
              onClick={handleBold}
              style={{
                backgroundColor:
                  currentStyleInline?._map?._list?._tail?.array?.some(
                    (style) => Array.isArray(style) && style[0] === 'BOLD' // Check if 'style' is an array before accessing 'style[0]'
                  )
                    ? themeMode === 'light'
                      ? '#f2f3f4'
                      : '#46485a'
                    : '',
              }}
            >
              <svg width="24" height="24" focusable="false">
                <path
                  fill={themeMode === 'dark' ? '#9f9b93' : '#000'}
                  d="M7.8 19c-.3 0-.5 0-.6-.2l-.2-.5V5.7c0-.2 0-.4.2-.5l.6-.2h5c1.5 0 2.7.3 3.5 1 .7.6 1.1 1.4 1.1 2.5a3 3 0 0 1-.6 1.9c-.4.6-1 1-1.6 1.2.4.1.9.3 1.3.6s.8.7 1 1.2c.4.4.5 1 .5 1.6 0 1.3-.4 2.3-1.3 3-.8.7-2.1 1-3.8 1H7.8Zm5-8.3c.6 0 1.2-.1 1.6-.5.4-.3.6-.7.6-1.3 0-1.1-.8-1.7-2.3-1.7H9.3v3.5h3.4Zm.5 6c.7 0 1.3-.1 1.7-.4.4-.4.6-.9.6-1.5s-.2-1-.7-1.4c-.4-.3-1-.4-2-.4H9.4v3.8h4Z"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </Button>
            <Button
              onClick={handleItalic}
              style={{
                backgroundColor:
                  currentStyleInline?._map?._list?._tail?.array?.some(
                    (style) => Array.isArray(style) && style[0] === 'ITALIC'
                  )
                    ? themeMode === 'light'
                      ? '#f2f3f4'
                      : '#46485a'
                    : '',
              }}
            >
              <svg width="24" height="24" focusable="false">
                <path
                  fill={themeMode === 'dark' ? '#9f9b93' : '#000'}
                  d="m16.7 4.7-.1.9h-.3c-.6 0-1 0-1.4.3-.3.3-.4.6-.5 1.1l-2.1 9.8v.6c0 .5.4.8 1.4.8h.2l-.2.8H8l.2-.8h.2c1.1 0 1.8-.5 2-1.5l2-9.8.1-.5c0-.6-.4-.8-1.4-.8h-.3l.2-.9h5.8Z"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </Button>
            <Button
              onClick={handleUnderline}
              style={{
                backgroundColor:
                  currentStyleInline?._map?._list?._tail?.array?.some(
                    (style) => Array.isArray(style) && style[0] === 'UNDERLINE'
                  )
                    ? themeMode === 'light'
                      ? '#f2f3f4'
                      : '#46485a'
                    : '',
              }}
            >
              <svg width="24" height="24" focusable="false">
                <path
                  fill={themeMode === 'dark' ? '#9f9b93' : '#000'}
                  d="M16 5c.6 0 1 .4 1 1v5.5a4 4 0 0 1-.4 1.8l-1 1.4a5.3 5.3 0 0 1-5.5 1 5 5 0 0 1-1.6-1c-.5-.4-.8-.9-1.1-1.4a4 4 0 0 1-.4-1.8V6c0-.6.4-1 1-1s1 .4 1 1v5.5c0 .3 0 .6.2 1l.6.7a3.3 3.3 0 0 0 2.2.8 3.4 3.4 0 0 0 2.2-.8c.3-.2.4-.5.6-.8l.2-.9V6c0-.6.4-1 1-1ZM8 17h8c.6 0 1 .4 1 1s-.4 1-1 1H8a1 1 0 0 1 0-2Z"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </Button>
            <Button
              onClick={handleCode}
              style={{
                backgroundColor:
                  currentStyleInline?._map?._list?._tail?.array?.some(
                    (style) => Array.isArray(style) && style[0] === 'CODE'
                  )
                    ? themeMode === 'light'
                      ? '#f2f3f4'
                      : '#46485a'
                    : '',
              }}
            >
              <svg width="24" height="24" focusable="false">
                <g fill-rule="nonzero">
                  <path
                    fill={themeMode === 'dark' ? '#9f9b93' : '#000'}
                    d="M9.8 15.7c.3.3.3.8 0 1-.3.4-.9.4-1.2 0l-4.4-4.1a.8.8 0 0 1 0-1.2l4.4-4.2c.3-.3.9-.3 1.2 0 .3.3.3.8 0 1.1L6 12l3.8 3.7ZM14.2 15.7c-.3.3-.3.8 0 1 .4.4.9.4 1.2 0l4.4-4.1c.3-.3.3-.9 0-1.2l-4.4-4.2a.8.8 0 0 0-1.2 0c-.3.3-.3.8 0 1.1L18 12l-3.8 3.7Z"
                  ></path>
                </g>
              </svg>
            </Button>
            <Button
              onClick={handleOl}
              style={{
                backgroundColor:
                  blockType === 'ordered-list-item'
                    ? themeMode === 'light'
                      ? '#f2f3f4'
                      : '#46485a'
                    : '',
              }}
            >
              Order List
            </Button>
            <Button
              onClick={handleH1}
              style={{
                backgroundColor:
                  blockType === 'header-one'
                    ? themeMode === 'light'
                      ? '#f2f3f4'
                      : '#46485a'
                    : '',
              }}
            >
              H1
            </Button>
            <Button
              onClick={handleH2}
              style={{
                backgroundColor:
                  blockType === 'header-two'
                    ? themeMode === 'light'
                      ? '#f2f3f4'
                      : '#46485a'
                    : '',
              }}
            >
              H2
            </Button>
            <Button
              onClick={handleH3}
              style={{
                backgroundColor:
                  blockType === 'header-three'
                    ? themeMode === 'light'
                      ? '#f2f3f4'
                      : '#46485a'
                    : '',
              }}
            >
              H3
            </Button>
            <Button
              onClick={handleH4}
              style={{
                backgroundColor:
                  blockType === 'header-four'
                    ? themeMode === 'light'
                      ? '#f2f3f4'
                      : '#46485a'
                    : '',
              }}
            >
              H4
            </Button>
            <Button
              onClick={handleH5}
              style={{
                backgroundColor:
                  blockType === 'header-five'
                    ? themeMode === 'light'
                      ? '#f2f3f4'
                      : '#46485a'
                    : '',
              }}
            >
              H5
            </Button>
            <Button
              onClick={handleH6}
              style={{
                backgroundColor:
                  blockType === 'header-six'
                    ? themeMode === 'light'
                      ? '#f2f3f4'
                      : '#46485a'
                    : '',
              }}
            >
              H6
            </Button>
            {/* <Button onClick={handleHighlight}>HIGHLIGHT</Button> */}
            {/* <Button onClick={handleSubmitTemplates}>Submit Template</Button> */}
          </div>
          <div style={styles.editor} onClick={focusEditor} aria-hidden>
            <Editor
              ref={editor}
              blockStyleFn={myBlockStyleFn}
              //   handleKeyCommand={handleKeyCommand}
              keyBindingFn={keyBindingFunction}
              editorState={editorState}
              onChange={(editorState) => setEditorState(editorState)}
              placeholder={
                blockType === 'ordered-list-item' ? '' : 'Write here...'
              }
            />
          </div>
        </>
      </Modal>
    </>
  );
};
export default EditEmailTemplatesRateModal;
