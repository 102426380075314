import { EyeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { usePermissionVariables } from 'Hooks/HasPermission';
import { Breadcrumb, Divider, Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Drawer from 'components/Drawer/Drawer';
import Dropdown from 'components/Dropdown';
import SearchField from 'components/InputFields/SearchField';
import Loading from 'components/Loading';
import PopConfirm from 'components/PopConfirm';
import PopOver from 'components/PopOver';
import Status from 'components/Status';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import { delete_subscribtion_plan } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import Layout from 'layout/AdminLayout';
import { useEffect, useState } from 'react';
import { BsCoin, BsThreeDotsVertical } from 'react-icons/bs';
import { FaPlus } from 'react-icons/fa6';
import { GoHome } from 'react-icons/go';
import { IoIosCheckmark } from 'react-icons/io';
import { LiaMoneyCheckSolid } from 'react-icons/lia';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { convertDateFormat } from 'utils/helper';
import { GET_SUBSCRIPTION_PLAN_KEY, GET_VEHICLES_KEY } from 'utils/queryKey';

const { Title } = Typography;

const Index = () => {
  const {
    Subscription_Plan_Delete,
    Subscription_Plan_Edit,
    Subscription_Plan_Add,
  } = usePermissionVariables();

  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const { useGetRequest, deleteRequest } = useApiClient();
  const [open, setOpen] = useState(false);
  const [planId, setPlanId] = useState('');
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const { data, error, isLoading, refetch } = useGetRequest(
    `api/GetAllSubscriptionPlans?pagenumber=${pageNumber}&pagesize=${rowsPerPage}&searchquery=${search}`,
    {},
    GET_SUBSCRIPTION_PLAN_KEY
  );
  const {
    data: SubPlanData,
    isLoading: planByIdLoading,
    refetch: refetchPlanById,
  } = useGetRequest(`/api/GetSubscriptionPlanById?id=${planId}`, {
    enabled: false,
  });

  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const delHandler = (record) => {
    deleteRequest(delete_subscribtion_plan + record?.planId)
      .then((res) => {
        if (res?.success) {
          const newTotalRecord = totalRecord - 1; // Decrement the total record count
          setTotalRecord(newTotalRecord); // Update the state

          // Check if we need to change the page number
          if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1); // Redirect to the previous page
          }
          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };

  const columns = [
    // {
    //   title: 'Sr #',
    //   dataIndex: 'sr',
    //   ellipsis: true,
    //   width: 80,
    //   render: (text, row, index) => index + 1,
    // },
    {
      title: <div className="flex justify-between items-center">Plan Name</div>,
      ellipsis: true,
      dataIndex: 'planName',
      sorter: (a, b) => a.planName.localeCompare(b.planName),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'planName'
          ? sortOrder.order
          : 'ascend',
      showSorterTooltip: false,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: 'Billing Cycle',
      dataIndex: 'billingCycle',
      ellipsis: true,
    },
    {
      title: 'Device Count',
      dataIndex: 'deviceCount',
      ellipsis: true,
    },
    {
      title: 'Max Users',
      dataIndex: 'maxUsers',
      ellipsis: true,
    },
    {
      title: 'Price',
      dataIndex: 'price',
      ellipsis: true,
      render: (text, record) => <span className="">{`${text}$`}</span>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      className: 'max-w-[250px]',
      ellipsis: true,
      render: (text, record) => (
        <span className="">
          <PopOver content={text} title={'Description'} width={'40%'}>
            <div className="truncate">{text}</div>
          </PopOver>
        </span>
      ),
    },
    {
      title: 'Valid From',
      dataIndex: 'planValidFrom',
      ellipsis: true,
      key: 'planValidFrom',
      render: (data, record) => convertDateFormat(record.planValidFrom),
    },
    {
      title: 'Valid Till',
      dataIndex: 'planValidTill',
      ellipsis: true,
      key: 'planValidTill',
      render: (data, record) => convertDateFormat(record.planValidTill),
    },
    {
      title: <span className="flex items-center justify-center">Status</span>,
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 120,
      ellipsis: true,
      render: (tag, record) => {
        return (
          <span>
            <Status tag={record?.status} />
          </span>
        );
      },
    },
    ...(Subscription_Plan_Edit || Subscription_Plan_Delete
      ? [
          {
            title: (
              <span className=" flex items-center justify-center">Action</span>
            ),
            ellipsis: true,
            dataIndex: '',
            width: 100,
            render: (text, record) => (
              <div className="flex justify-center">
                <Dropdown
                  overlayClassName={`${
                    themeMode === 'light'
                      ? 'action-dropdown-light'
                      : 'action-dropdown-dark'
                  }`}
                  items={[
                    {
                      key: '1',
                      label: (
                        <div
                          className="p-[2px] rounded-sm"
                          style={{
                            backgroundColor:
                              themeMode === 'light' ? 'white' : '#121212',
                          }}
                          onClick={() => {
                            setPlanId(record?.planId);
                            setOpen(!open);
                          }}
                        >
                          <EyeOutlined className="w-[15px] h-[15px] cursor-pointer" />
                        </div>
                      ),
                    },
                    ...(Subscription_Plan_Edit
                      ? [
                          {
                            key: '2',
                            label: (
                              <div
                                className="p-[2px] rounded-sm"
                                style={{
                                  backgroundColor:
                                    themeMode === 'light' ? 'white' : '#121212',
                                }}
                                onClick={() => {
                                  navigate(
                                    `/edit-subscription-plan/${record?.planId}`
                                  );
                                }}
                              >
                                <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                              </div>
                            ),
                          },
                        ]
                      : []),
                    ...(Subscription_Plan_Delete
                      ? [
                          {
                            key: '3',
                            label: (
                              <div
                                className="p-[2px] rounded-sm"
                                style={{
                                  backgroundColor:
                                    themeMode === 'light' ? 'white' : '#121212',
                                }}
                                onClick={(e) => e.stopPropagation()}
                              >
                                <PopConfirm
                                  title="Delete"
                                  description="Are you sure you want to delete this Subscription Plan?"
                                  placement="left"
                                  onClick={() => delHandler(record)}
                                  icon={
                                    <div className="flex items-center justify-center">
                                      <QuestionCircleOutlined
                                        style={{
                                          color: 'red',
                                          marginTop: '5px',
                                        }}
                                      />
                                    </div>
                                  }
                                >
                                  <MdDeleteOutline className="w-[15px] h-[15px] cursor-pointer" />
                                </PopConfirm>
                              </div>
                            ),
                          },
                        ]
                      : []),
                  ]}
                >
                  <BsThreeDotsVertical className="w-[15px] h-[15px] cursor-pointer" />
                </Dropdown>
              </div>
            ),
            // render: (text, record) => (
            //   <span className="flex justify-center items-center space-x-2">
            //     {Subscription_Plan_Edit && (
            //       <div
            //         className="p-[5px] rounded-sm"
            //         style={{
            //           backgroundColor:
            //             themeMode === 'light' ? 'white' : '#121212',
            //         }}
            //         onClick={() => {
            //           navigate(`/edit-subscription-plan/${1}`);
            //         }}
            //       >
            //         <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
            //       </div>
            //     )}
            //     {Subscription_Plan_Delete && (
            //       <div
            //         className="p-[5px] rounded-sm"
            //         style={{
            //           backgroundColor:
            //             themeMode === 'light' ? 'white' : '#121212',
            //         }}
            //       >
            //         <PopConfirm
            //           title="Delete"
            //           description="Are you sure to delete this page? "
            //           placement="left"
            //           onClick={() => delHandler(record)}
            //           icon={
            //             <div className=" flex items-center justify-center ">
            //               <QuestionCircleOutlined
            //                 style={{ color: 'red', marginTop: '5px' }}
            //               />
            //             </div>
            //           }
            //         >
            //           <MdDeleteOutline className=" w-[15px] h-[15px] cursor-pointer" />
            //         </PopConfirm>
            //       </div>
            //     )}
            //   </span>
            // ),
          },
        ]
      : []),
  ];

  const drawerBody = () => {
    return (
      <>
        {planByIdLoading ? (
          <div className="flex justify-center items-center h-full">
            <Loading />
          </div>
        ) : (
          <div>
            <div className="flex justify-center mt-35 mb-10">
              <BsCoin
                size={80}
                color={themeMode === 'light' ? colorTheme : '#9FA8DA'}
              />
            </div>
            <div className="text-[25px] font-bold text-center">
              {SubPlanData?.data?.planName}
            </div>
            <div className=" text-center">
              <span className="text-[25px] font-[500]">
                ${SubPlanData?.data?.price}
              </span>
              /
              <sub className="bottom-[0.05em] text-[12px]">
                {SubPlanData?.data?.billingCycle}
              </sub>
            </div>

            <div className="mt-6 text-center">
              {SubPlanData?.data?.description}
            </div>
            <Divider
              style={{
                backgroundColor: themeMode === 'light' ? '' : '#8e8a83',
              }}
            />
            <div className="grid gap-y-10 my-10">
              <div>
                <div
                  key={SubPlanData?.data?.maxUsers + 'maxUsers'}
                  className="flex items-center mb-[-5px]"
                >
                  <IoIosCheckmark
                    className="w-25 h-25"
                    color={themeMode === 'light' ? colorTheme : '#9FA8DA'}
                  />{' '}
                  {'Max Users'}
                </div>
                <div className="ml-25 leading-none">
                  <sub className="bottom-0">
                    {`This subscription allows a maximum of ${SubPlanData?.data?.maxUsers} users.`}
                  </sub>
                </div>
              </div>
              <div>
                <div
                  key={SubPlanData?.data?.deviceCount + 'deviceCount'}
                  className="flex items-center mb-[-5px]"
                >
                  <IoIosCheckmark
                    className="w-25 h-25"
                    color={themeMode === 'light' ? colorTheme : '#9FA8DA'}
                  />{' '}
                  {'Device Count '}
                </div>
                <div className="ml-25 leading-none">
                  <sub className="bottom-0">
                    {`Maximum ${SubPlanData?.data?.deviceCount} devices can be used with this subscription.`}
                  </sub>
                </div>
              </div>
              {SubPlanData?.data?.features?.map((item, index) => (
                <div>
                  <div key={index} className="flex items-center mb-[-5px]">
                    <IoIosCheckmark
                      className="w-25 h-25"
                      color={themeMode === 'light' ? colorTheme : '#9FA8DA'}
                    />{' '}
                    {item?.featureName}
                  </div>
                  <div className="ml-25 leading-none">
                    <sub className="bottom-0">{item.description}</sub>
                  </div>
                </div>
              ))}
            </div>
            {/* <PrimaryButton
            title="Assgin Plan"
            icon={<FaPlus />}
            onClick={() => {}}
          /> */}
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (planId) {
      refetchPlanById();
    }
  }, [planId, setPlanId]);

  useEffect(() => {
    if (data) {
      setTotalRecord(data?.totalRecords);
    }
  }, [data]);

  return (
    <Layout>
      <>
        <div>
          <div className=" mb-[30px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
              }}
            >
              Subscription Plan Management
            </Title>
            <div className="flex justify-between items-center mb-20">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <LiaMoneyCheckSolid
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />
                            <span
                              className=" font-bold text-[13px]"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              Subscription Plan Management
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>

          <div className="md:flex space-y-[20px] md:space-y-0 justify-between mt-10 mb-26">
            <SearchField
              htmlFor="search"
              id="myInputID"
              name="search"
              placeholder={'Search by Plan Name'}
              value={debouncedSearch}
              setDebouncedSearch={setDebouncedSearch}
              setPageNumber={setPageNumber}
              searchLoading={searchLoading}
              setSearchLoading={setSearchLoading}
              refetch={refetch}
              debouncedSearch={debouncedSearch}
              setSearch={setSearch}
            />
            {Subscription_Plan_Add && (
              <div className="sm:flex justify-end">
                <PrimaryButton
                  title="Add Subscription Plan"
                  icon={<FaPlus />}
                  onClick={() => {
                    navigate(`/add-subscription-plan`);
                  }}
                />
              </div>
            )}
          </div>
          <Table
            data={data?.data}
            columns={columns}
            pageNumber={pageNumber}
            rowsPerPage={rowsPerPage}
            setTotalRecord={setTotalRecord}
            totalRecord={totalRecord}
            setPageNumber={setPageNumber}
            setRowsPerPage={setRowsPerPage}
            setSortOrder={setSortOrder}
            setFilters={setFilters}
            loading={isLoading}
          />
        </div>
        <Drawer body={drawerBody()} open={open} setOpen={setOpen} />
      </>
    </Layout>
  );
};
export default Index;
