import { Button, Modal, Select } from 'antd';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
import Checkbox from 'components/InputFields/Checkbox';
import ToastNotification from 'components/ToastNotification';
import { update_page } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { capitalizeWords } from 'utils/helper';
import useValidationSchema from 'validation/useValidationSchema';

const EditPagesModal = ({
  openEditPagesModal,
  setOpenEditPagesModal,
  record,
  refetch,
}) => {
  const { putRequest, useGetRequest } = useApiClient();
  const { data: menuData } = useGetRequest('/api/AppMenus/GetAllMenus', {
    refetchOnMount: 'always',
  });
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const { pageModalScehema } = useValidationSchema();
  const { Option } = Select;
  const createOptions = (data, labelKey, valueKey) =>
    data?.data?.map((item) => ({
      label: item[labelKey],
      value: item[valueKey],
    }));
  const menuOptions = createOptions(menuData, 'menuName', 'menuId');
  const formik = useFormik({
    initialValues: {
      initialValues: {
        menuSelect: null,
        pagesName: '',
        isActive: '',
        // isDeleted: '',
      },
    },
    validationSchema: pageModalScehema,
    onSubmit: ({ pagesName, menuSelect, isActive }) => {
      setLoading(true);
      const paylaod = {
        pageId: record?.pageId,
        menuId: menuSelect,
        pageName: capitalizeWords(pagesName),
        isActive: isActive,
      };
      putRequest(`${update_page}${record?.pageId}`, paylaod)
        .then((res) => {
          if (res?.success) {
            setOpenEditPagesModal(false);
            refetch();
            formik.resetForm();
            ToastNotification('success', res?.message, themeMode);
          } else {
            ToastNotification('error', res?.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCancel = () => {
    setOpenEditPagesModal(false);
    formik.resetForm();
  };

  useEffect(() => {
    formik.setValues({
      menuSelect: record?.menuId,
      pagesName: record?.pageName,
      isActive: record?.isActive,
      // isDeleted: record?.isDeleted,
    });
  }, [record?.menuId, openEditPagesModal]);

  return (
    <>
      <Modal
        open={openEditPagesModal}
        // className="!w-[400px]"
        width={400}
        title={<span className="text-[18px]">Edit Pages</span>}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
          >
            Update
          </Button>,
        ]}
      >
        <>
          <div className="grid grid-cols-12 gap-x-20  py-[10px]">
            <div className=" col-span-12">
              <SelectField
                formik={formik}
                id={'menuSelect'}
                label={'Select Menu'}
                optionId={'value'}
                optionName={'label'}
                optionData={menuOptions}
              />
            </div>
            <div className="col-span-12">
              <TextField
                formik={formik}
                id={'pagesName'}
                label={'Pages Name'}
                disabled={true}
              />
            </div>
          </div>
          <div className=" grid grid-cols-12 mt-6">
            <div className="col-span-6 ">
              <Checkbox
                title="IsActive"
                id="isActive"
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                error={formik.errors.isActive}
                errorMessage={
                  formik.touched.isActive && formik.errors.isActive
                    ? formik.errors.isActive
                    : null
                }
              />
            </div>
            {/* <div className="col-span-6">
              <Checkbox
                title="Deleted ?"
                id="isDeleted"
                name="isDeleted"
                checked={formik.values.isDeleted}
                onChange={formik.handleChange}
                error={formik.errors.isDeleted}
                errorMessage={
                  formik.touched.isDeleted && formik.errors.isDeleted
                    ? formik.errors.isDeleted
                    : null
                }
              />
            </div> */}
          </div>
        </>
      </Modal>
    </>
  );
};
export default EditPagesModal;
