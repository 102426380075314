import { Button, Modal, Select } from 'antd';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
import Checkbox from 'components/InputFields/Checkbox';
import ToastNotification from 'components/ToastNotification';
import { add_zipCode, get_country_by_dropdown } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { createOptions } from 'utils/helper';
import useValidationSchema from 'validation/useValidationSchema';

const AddZipModal = ({ openAddZipModal, setOpenAddZipModal, refetch }) => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { useGetRequest, postRequest } = useApiClient();
  const queryClient = useQueryClient();

  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const { zipModalSchema } = useValidationSchema();
  const formik = useFormik({
    initialValues: {
      zipCode: '',
      countryId: '',
      stateId: '',
      city: '',
      isActive: false,
    },
    validationSchema: zipModalSchema,
    onSubmit: ({ zipCode, city, isActive }) => {
      setLoading(true);
      const paylaod = {
        code: `${zipCode}`,
        cityId: city,
        isActive: isActive,
      };
      postRequest(add_zipCode, paylaod)
        .then((res) => {
          if (res?.success) {
            refetch();
            formik.resetForm();
            ToastNotification('success', res.message, themeMode);
            setOpenAddZipModal(false);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCancel = () => {
    setOpenAddZipModal(false);
    formik.resetForm();
  };
  const { data: countryData } = useGetRequest(get_country_by_dropdown, {
    refetchOnMount: 'always',
  });
  const { data: stateData, refetch: statesRefetch } = useGetRequest(
    `api/StateDropdown?Id=${formik.values.countryId}`,
    {
      enabled: false,
    }
  );
  const { data: cityData, refetch: cityRefetch } = useGetRequest(
    `api/CitiesDropdown?Id=${formik.values.stateId}`,
    {
      enabled: false,
    }
  );
  useEffect(() => {
    if (formik.values.countryId) statesRefetch();
    if (formik.values.stateId) cityRefetch();
  }, [formik.values.countryId, formik.values.stateId]);
  const countriesOptions = createOptions(
    countryData?.data,
    'name',
    'countryId'
  );
  const statesOptions = createOptions(stateData?.data, 'name', 'stateId');
  const citiesOptions = createOptions(cityData?.data, 'name', 'cityId');
  return (
    <>
      <Modal
        open={openAddZipModal}
        title={<span className=" text-[18px]">Add Zip Code</span>}
        width={400}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <>
          <div className=" grid grid-cols-12 gap-x-20 gap-y-5 py-[10px]">
            <div className=" col-span-12">
              <SelectField
                formik={formik}
                id={'countryId'}
                label={'Country'}
                required={true}
                optionId={'value'}
                optionName={'label'}
                optionData={countriesOptions}
                onChange={(newValue, _) => {
                  formik.setFieldValue('countryId', newValue);
                  formik.setFieldValue('stateId', null);
                  formik.setFieldValue('city', null);
                  formik.setFieldValue('zipCode', null);
                }}
              />
            </div>
            <div className=" col-span-12">
              <SelectField
                formik={formik}
                id={'stateId'}
                label={'State'}
                required={true}
                optionId={'value'}
                optionName={'label'}
                optionData={statesOptions}
                onChange={(newValue, _) => {
                  formik.setFieldValue('stateId', newValue);
                  formik.setFieldValue('city', null);
                  formik.setFieldValue('zipCode', null);
                }}
              />
            </div>
            <div className=" col-span-12">
              <SelectField
                formik={formik}
                id={'city'}
                label={'City'}
                optionId={'value'}
                optionName={'label'}
                optionData={citiesOptions}
                onChange={(newValue, _) => {
                  formik.setFieldValue('city', newValue);
                  formik.setFieldValue('zipCode', null);
                }}
              />
            </div>
            <div className="col-span-12">
              <TextField
                formik={formik}
                id={'zipCode'}
                label={'Zip Code'}
                type={'number'}
                maxLength={20}
              />
            </div>
            <div className="col-span-12 mt-[5px]">
              <Checkbox
                title="IsActive"
                id="isActive"
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                error={formik.errors.isActive}
                errorMessage={
                  formik.touched.isActive && formik.errors.isActive
                    ? formik.errors.isActive
                    : null
                }
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default AddZipModal;
