import { Button, Modal, Select } from 'antd';
import TextField from 'components/CustomFields/TextField';
import Checkbox from 'components/InputFields/Checkbox';
import ToastNotification from 'components/ToastNotification';
import { update_menu } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { BsPeople } from 'react-icons/bs';
import { FiClock } from 'react-icons/fi';
import { GoHome } from 'react-icons/go';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { LuShieldCheck } from 'react-icons/lu';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { capitalizeWords } from 'utils/helper';
import useValidationSchema from 'validation/useValidationSchema';

const EditPagesModal = ({
  openEditMenuModal,
  setOpenEditMenuModal,
  record,
  refetch,
}) => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { putRequest, useGetRequest } = useApiClient();
  const queryClient = useQueryClient();
  const { data, error, isLoading } = useGetRequest('/api/AppPages/GetAllPages');
  const { menuModalScehema } = useValidationSchema();
  const { Option } = Select;

  const options = data?.data?.map((item) => {
    return {
      label: item.pageName,
      value: item.pageId,
    };
  });

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      menuName: '',
      isActive: false,
      // isDeleted: false,
    },
    validationSchema: menuModalScehema,
    onSubmit: ({ menuName, pageSelect, isActive }) => {
      setLoading(true);
      const paylaod = {
        menuId: record?.menuId,
        menuName: capitalizeWords(menuName),
        pageId: pageSelect,
        updatedBy: 'string',
        isActive: isActive,
        // isDeleted: isDeleted,
      };
      putRequest(update_menu, paylaod)
        .then((res) => {
          if (res?.success) {
            setOpenEditMenuModal(false);
            refetch();
            formik.resetForm();
            ToastNotification('success', res.message, themeMode);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCancel = () => {
    setOpenEditMenuModal(false);
    formik.resetForm();
  };
  useEffect(() => {
    if (record) {
      formik.setValues({
        menuName: record?.menuName,
        isActive: record?.isActive,
        // isDeleted: record?.isDeleted,
      });
    }
  }, [record, openEditMenuModal]);
  const menuOptions = [
    {
      value: 'dashboard',
      label: 'Dashboard',
      icon: <GoHome size={14} />,
    },
    {
      value: 'customers',
      label: 'Customers',
      icon: <BsPeople size={14} />,
    },
    {
      value: 'timesheet',
      label: 'Time Sheet',
      icon: <FiClock size={14} />,
    },
    {
      value: 'security',
      label: 'Security',
      icon: <LuShieldCheck size={14} />,
    },
    {
      value: 'settings',
      label: 'Settings',
      icon: <HiOutlineDocumentText size={14} />,
    },
  ];
  return (
    <>
      <Modal
        open={openEditMenuModal}
        // className="!w-[400px]"
        width={400}
        title={<span className="text-[18px]">Edit Menu</span>}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
          >
            Update
          </Button>,
        ]}
      >
        <>
          <div className="grid grid-cols-12 gap-x-20  py-[10px]">
            <div className="col-span-12">
              <TextField
                formik={formik}
                id={'menuName'}
                label={'Menu Name'}
                maxLength={30}
              />
            </div>
          </div>
          <div className=" grid grid-cols-12 mt-6">
            <div className="col-span-6 ">
              <Checkbox
                title="IsActive"
                id="isActive"
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                error={formik.errors.isActive}
                errorMessage={
                  formik.touched.isActive && formik.errors.isActive
                    ? formik.errors.isActive
                    : null
                }
              />
            </div>
            {/* <div className="col-span-6">
              <Checkbox
                title="Deleted ?"
                id="isDeleted"
                name="isDeleted"
                checked={formik.values.isDeleted}
                onChange={formik.handleChange}
                error={formik.errors.isDeleted}
                errorMessage={
                  formik.touched.isDeleted && formik.errors.isDeleted
                    ? formik.errors.isDeleted
                    : null
                }
              />
            </div> */}
          </div>
        </>
      </Modal>
    </>
  );
};
export default EditPagesModal;
