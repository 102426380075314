import { Button, Modal } from 'antd';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
import Checkbox from 'components/InputFields/Checkbox';
import TextArea from 'components/InputFields/TextArea';
import ToastNotification from 'components/ToastNotification';
import { update_role } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { createOptions } from 'utils/helper';
import useValidationSchema from 'validation/useValidationSchema';

const EditRoleModal = ({
  openEditRoleModal,
  setOpenEditRoleModal,
  record,
  refetch,
}) => {
  const [loading, setLoading] = useState(false);
  const { useGetRequest } = useApiClient();
  // const { data, error, isLoading } = useGetRequest(
  //   `/api/Roles/GetRole?roleid=${roleId}`
  // );
  const { roleModalScehema } = useValidationSchema();
  const { themeMode } = useSelector((state) => state?.states);
  const { postRequest } = useApiClient();
  const queryClient = useQueryClient();
  const formik = useFormik({
    initialValues: {
      roleName: '',
      roleDescription: '',
      isActive: false,
      userTypeId: null,
    },
    validationSchema: roleModalScehema,
    onSubmit: ({ roleName, roleDescription, isActive, userTypeId }) => {
      setLoading(true);
      const paylaod = {
        roleId: record?.roleId,
        tenantId: 0,
        roleName: roleName,
        usertypeId: userTypeId,
        description: roleDescription,
        updatedBy: '',
        isActive: isActive,
      };
      postRequest(update_role, paylaod)
        .then((res) => {
          // if (res?.success) {
          ToastNotification('success', 'Edit Role Successfully', themeMode);
          setOpenEditRoleModal(false);
          refetch();
          formik.resetForm();
          // toast.success(res.message);
          // } else {
          //   toast.error(res.message);
          // }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCancel = () => {
    setOpenEditRoleModal(false);
    formik.resetForm();
  };

  const { data: roleType, isLoading: roleTypeLoading } = useGetRequest(
    `/api/User/GetUserTypes`,
    {
      refetchOnMount: 'always',
    }
  );

  const roleTypeOptions = createOptions(
    roleType?.data,
    'userTypeName',
    'userTypeId'
  );

  useEffect(() => {
    if (record) {
      formik.setValues({
        roleId: record?.roleId,
        tenantId: 0,
        roleName: record?.roleName,
        roleDescription: record?.description,
        updatedBy: '',
        isActive: record?.isActive,
        userTypeId: record?.userTypeId,
      });
    }
  }, [record, openEditRoleModal]);

  return (
    <>
      <Modal
        open={openEditRoleModal}
        // className="!w-[400px]"
        width={400}
        title={<span className="text-[18px]">Edit Role</span>}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
          >
            Update
          </Button>,
        ]}
      >
        <>
          <div className="grid grid-cols-12 gap-x-20">
            <div className="col-span-12">
              <TextField
                formik={formik}
                id={'roleName'}
                label={'Name'}
                maxLength={30}
              />
            </div>
            <div className="col-span-12">
              <SelectField
                formik={formik}
                id={'userTypeId'}
                label={'Type'}
                optionId={'value'}
                optionName={'label'}
                optionData={roleTypeOptions || []}
              />
            </div>
            <div className=" col-span-12">
              <div className="wrapper-fields">
                <TextArea
                  id="roleDescription"
                  name="roleDescription"
                  rows={4}
                  required={true}
                  placeholder="Description"
                  maxLength={250}
                  value={formik.values.roleDescription}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldTouched('roleDescription', true, false);
                  }}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.roleDescription &&
                    Boolean(formik.errors.roleDescription)
                  }
                  errorMessage={
                    formik.touched.roleDescription &&
                    formik.errors.roleDescription
                      ? formik.errors.roleDescription
                      : null
                  }
                />
              </div>
            </div>
            <div className="col-span-6 ">
              <Checkbox
                title="IsActive"
                id="isActive"
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                // error={formik.errors.isActive}
                // errorMessage={
                //   formik.touched.isActive && formik.errors.isActive
                //     ? formik.errors.isActive
                //     : null
                // }
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default EditRoleModal;
