import { Progress } from 'antd';
import CustomDrawer from 'components/CustomDrawer';
import InfoPopover from 'components/PopOver/InfoPopOver';
import Switch from 'components/Switch';
import Layout from 'layout/AdminLayout';
import { useState } from 'react';
import { BsGraphUpArrow, BsSpeedometer2 } from 'react-icons/bs';
import { FaEye } from 'react-icons/fa';
import {
  FaArrowDownLong,
  FaArrowLeftLong,
  FaArrowRightLong,
  FaArrowUpLong,
  FaLocationArrow,
} from 'react-icons/fa6';
import { GoAlertFill } from 'react-icons/go';
import { LuClock4 } from 'react-icons/lu';
import { useSelector } from 'react-redux';

const FuelEfficiency = () => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const [open, setOpen] = useState(false);
  const DrawerBody = () => {
    return (
      <>
        <div>
          <div>
            <div className=" text-[13px] font-bold">TN 14 G 1191</div>
            <div className=" text-[10px] ">Tekhqs New Center</div>
          </div>
          <div className=" h-[200px] w-full rounded-[8px] mt-[20px]">
            <img
              src="https://img.freepik.com/free-photo/truck-with-white-trailer-that-says-scania-side_123827-23486.jpg"
              alt=""
              className=" h-full w-full  rounded-[8px]"
            />
          </div>
          <div className=" grid grid-cols-12 mt-[20px] gap-x-[10px]">
            <div className=" col-span-4 px-[5px]">
              <div className=" font-semiBold text-[12px]">Speed</div>
              <div className=" flex justify-between items-center">
                <div className=" text-[10px]">125 km/h</div>
                <div>
                  <BsSpeedometer2 />
                </div>
              </div>
            </div>
            <div className=" col-span-4 px-[5px]">
              <div className=" font-semiBold text-[12px]">Altitude</div>
              <div className=" flex justify-between items-center">
                <div className=" text-[10px]">209 m</div>
                <div>
                  <BsGraphUpArrow />
                </div>
              </div>
            </div>
            <div className=" col-span-4 px-[5px]">
              <div className=" font-semiBold text-[12px]">Direction</div>
              <div className=" flex justify-between items-center">
                <div className=" text-[10px]">NE</div>
                <div>
                  <FaLocationArrow />
                </div>
              </div>
            </div>
          </div>
          <div className=" flex justify-between mt-[20px] py-[10px] px-[7px] rounded-[8px] bg-[#F6F4F3] ">
            <div className=" w-full">
              <div className=" text-[12px] font-semiBold">
                GA Trucks Pvt Ltd.
              </div>
              <div className=" text-[10px]">
                72 New Jercy 11 phase <br />
                Anna Nagar, Chennar Pakistan
              </div>
            </div>
            <div className=" w-full flex flex-col justify-between text-end">
              <div className=" font-semiBold text-[12px]">54 Clients</div>
              <div>
                <div className=" text-[11px]">Total Drives</div>
                <div className=" text-[10px] font-semiBold">53234 km</div>
              </div>
            </div>
          </div>
          <div className=" flex justify-between mt-[20px]">
            <div className=" flex space-x-5">
              <div className=" w-[35px] h-[35px]">
                <img
                  src="https://img.freepik.com/free-photo/truck-with-white-trailer-that-says-scania-side_123827-23486.jpg"
                  alt=""
                  className=" h-full w-full  rounded-[3px]"
                />
              </div>
              <div>
                <div className=" text-[12px] font-semiBold">Stalin Maria</div>
                <div className=" text-[10px]">DHA</div>
              </div>
            </div>
            <div className=" text-[12px] font-semiBold">21 Trips</div>
          </div>
        </div>
      </>
    );
  };
  return (
    <Layout>
      <>
        <div>
          <div className=" flex justify-end">
            <div
              className={`${
                themeMode === 'light' ? '#F7F7FA' : 'bg-black'
              } flex justify-center items-center w-[24px] h-[24px] mb-[20px] rounded-[6px] cursor-pointer border-[#DCDCDC] border-[1px]`}
              onClick={() => setOpen((prev) => !prev)}
            >
              <InfoPopover content={'Fleet Details'}>
                <FaEye
                  size={12}
                  style={{
                    color: themeMode === 'light' ? colorTheme : '#ffffff',
                  }}
                />
              </InfoPopover>
            </div>
          </div>
          <div className=" space-y-[20px]">
            <div className=" grid grid-cols-12 gap-x-15">
              <div className=" col-span-4 bg-[#F6F4F3] shadow-md px-[20px] py-[20px] rounded-[8px]">
                <div className=" w-full flex justify-between ">
                  <div>
                    <div className=" font-bold">75000 L</div>
                    <div>Fuel Usage</div>
                  </div>
                  <div className=" flex justify-center items-center">
                    <div>
                      <Progress
                        type="circle"
                        percent={60}
                        format={() => null}
                        strokeColor={`${
                          themeMode == 'dark' ? 'white' : colorTheme
                        }`}
                        size={50}
                        strokeWidth={10}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-span-4 bg-[#F6F4F3] shadow-md px-[20px] py-[20px] rounded-[8px]">
                <div className=" w-full flex justify-between ">
                  <div>
                    <div className=" font-bold">43,033 hm</div>
                    <div>Distance</div>
                  </div>
                  <div className=" flex justify-center items-center">
                    <div>
                      <Progress
                        type="circle"
                        percent={60}
                        format={() => null}
                        strokeColor={`${
                          themeMode == 'dark' ? 'white' : colorTheme
                        }`}
                        size={50}
                        strokeWidth={10}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-span-4 bg-[#F6F4F3] shadow-md px-[20px] py-[20px] rounded-[8px]">
                <div className=" w-full flex justify-between ">
                  <div>
                    <div className=" font-bold">2h:33m:21s</div>
                    <div>Ideal Time</div>
                  </div>
                  <div className=" flex justify-center items-center">
                    <div>
                      <Progress
                        type="circle"
                        percent={60}
                        format={() => null}
                        strokeColor={`${
                          themeMode == 'dark' ? 'white' : colorTheme
                        }`}
                        size={50}
                        strokeWidth={10}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" space-y-[20px]">
              <div className=" font-bold">Summary</div>
              <div className="flex space-x-[20px]">
                <div className=" w-[25%] bg-[#F6F4F3] shadow-md">
                  <div className=" space-y-20 space-x-[10px] p-[15px] shadow-md px-[20px] py-[20px] rounded-[8px]">
                    <div className=" font-bold">Vehicle Control</div>
                    <div className="flex justify-between items-center space-x-[15px]">
                      <div className="bg-white shadow-md px-[20px] py-[20px] rounded-[8px] space-y-10 w-full">
                        <div className=" flex justify-center items-center">
                          <LuClock4 size={25} />
                        </div>
                        <div className=" font-semiBold text-[12px] text-center">
                          <div>Driver</div>
                          <div>Duration</div>
                        </div>
                        <div className=" flex justify-center items-center">
                          <Switch />
                        </div>
                      </div>
                      <div className="bg-white shadow-md px-[20px] py-[20px] rounded-[8px] space-y-10 w-full">
                        <div className=" flex justify-center items-center">
                          <GoAlertFill size={25} />
                        </div>
                        <div className=" font-semiBold text-[12px] text-center">
                          <div>Alert</div>
                          <div>Sync</div>
                        </div>
                        <div className=" flex justify-center items-center">
                          <Switch />
                        </div>
                      </div>
                    </div>
                    <div className="bg-white shadow-md px-[20px] py-[20px] rounded-[8px] ">
                      <div className=" flex justify-between items-center">
                        <div className=" w-full flex items-center justify-center">
                          <div>
                            <div>
                              <Progress
                                type="dashboard"
                                percent={80}
                                strokeColor={`${
                                  themeMode == 'dark' ? 'white' : colorTheme
                                }`}
                                size={70}
                                strokeWidth={10}
                              />
                            </div>
                            <div className=" text-center text-[10px]">
                              <div>Fuel</div>
                              <div>Efficiency</div>
                            </div>
                          </div>
                        </div>
                        <div className=" w-full flex items-center justify-center">
                          <div>
                            <div>
                              <Progress
                                type="dashboard"
                                percent={35}
                                strokeColor={`${
                                  themeMode == 'dark' ? 'white' : colorTheme
                                }`}
                                size={70}
                                strokeWidth={10}
                              />
                            </div>
                            <div className=" text-center text-[10px]">
                              <div>Fuel</div>
                              <div>EConomy</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white shadow-md px-[20px] py-[20px] rounded-[8px]">
                      <div className=" flex items-center justify-center space-x-10">
                        <div>
                          <BsSpeedometer2 size={25} />
                        </div>
                        <div>
                          <div className=" text-[14px] font-semiBold">
                            90km/hr
                          </div>
                          <div className=" text-[10px]">Average Speed</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" w-[75%] flex flex-col relative bg-[#F6F4F3] justify-center space-y-[30px]  shadow-md px-[20px] py-[20px] rounded-[8px] ">
                  <div className=" grid grid-cols-12">
                    <div className="col-span-3 ">
                      <div className=" flex items-center justify-center ">
                        <div className=" space-y-7">
                          <div className=" text-[11px] text-center font-semiBold">
                            Reverse
                          </div>
                          <div>
                            <Progress
                              type="circle"
                              percent={60}
                              strokeColor={`${
                                themeMode == 'dark' ? 'white' : colorTheme
                              }`}
                              size={60}
                              strokeWidth={10}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-3">
                      <div className=" flex items-center justify-center ">
                        <div className=" space-y-7">
                          <div className=" text-[11px] text-center font-semiBold">
                            Gear 1
                          </div>
                          <div>
                            <Progress
                              type="circle"
                              percent={60}
                              strokeColor={`${
                                themeMode == 'dark' ? 'white' : colorTheme
                              }`}
                              size={60}
                              strokeWidth={10}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-3">
                      <div className=" flex items-center justify-center ">
                        <div className=" space-y-7">
                          <div className=" text-[11px] text-center font-semiBold">
                            Gear 3
                          </div>
                          <div>
                            <Progress
                              type="circle"
                              percent={60}
                              strokeColor={`${
                                themeMode == 'dark' ? 'white' : colorTheme
                              }`}
                              size={60}
                              strokeWidth={10}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-3">
                      <div className=" flex items-center justify-center ">
                        <div className=" space-y-7">
                          <div className=" text-[11px] text-center font-semiBold">
                            Gear 5
                          </div>
                          <div>
                            <Progress
                              type="circle"
                              percent={60}
                              strokeColor={`${
                                themeMode == 'dark' ? 'white' : colorTheme
                              }`}
                              size={60}
                              strokeWidth={10}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" grid grid-cols-12">
                    <div className="col-span-3">
                      {/* <div className=" flex items-center justify-center h-full">
                        <div className=" pb-[15px]">
                          <div>
                            <FaArrowUpLong />
                          </div>
                          <div className="ml-[10px]">
                            <FaArrowRightLong />
                          </div>
                        </div>
                      </div> */}
                      <div className=" absolute top-[165px] left-[125px]">
                        <div className=" border-black border-2 w-[2px] h-[85px]" />
                      </div>
                      <div className=" absolute top-[250px] left-[125px]">
                        <div className=" border-black border-2 w-[397px] h-[2px]" />
                      </div>
                    </div>
                    <div className="col-span-3 flex justify-center">
                      {/* <div className=" flex items-center justify-center h-full ">
                        <div className="">
                          <div className=" flex items-center justify-center">
                            <FaArrowUpLong />
                          </div>
                          <div className=" flex space-x-7">
                            <div>
                              <FaArrowLeftLong />
                            </div>
                            <div>
                              <FaArrowRightLong />
                            </div>
                          </div>
                          <div className=" flex items-center justify-center">
                            <FaArrowDownLong />
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <div className="col-span-3 ">
                      <div className=" flex items-center justify-center ">
                        <div className=" space-y-7">
                          <div className=" text-[11px] text-center font-semiBold">
                            Neutral
                          </div>
                          <div>
                            <Progress
                              type="circle"
                              percent={60}
                              strokeColor={`${
                                themeMode == 'dark' ? 'white' : colorTheme
                              }`}
                              size={60}
                              strokeWidth={10}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-3 flex justify-center">
                      <div className=" flex items-center justify-center h-full ">
                        <div className="">
                          <div className=" flex items-center justify-center">
                            <FaArrowUpLong />
                          </div>
                          <div className=" mr-[15px]">
                            <FaArrowLeftLong />
                          </div>
                          <div className=" flex items-center justify-center">
                            <FaArrowDownLong />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" grid grid-cols-12">
                    <div className="col-span-3"></div>
                    <div className="col-span-3">
                      <div className=" flex items-center justify-center ">
                        <div className=" space-y-7">
                          <div className=" text-[11px] text-center font-semiBold">
                            Gear 2
                          </div>
                          <div>
                            <Progress
                              type="circle"
                              percent={60}
                              strokeColor={`${
                                themeMode == 'dark' ? 'white' : colorTheme
                              }`}
                              size={60}
                              strokeWidth={10}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-3">
                      <div className=" flex items-center justify-center ">
                        <div className=" space-y-7">
                          <div className=" text-[11px] text-center font-semiBold">
                            Gear 4
                          </div>
                          <div>
                            <Progress
                              type="circle"
                              percent={60}
                              strokeColor={`${
                                themeMode == 'dark' ? 'white' : colorTheme
                              }`}
                              size={60}
                              strokeWidth={10}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-3">
                      <div className=" flex items-center justify-center ">
                        <div className=" space-y-7">
                          <div className=" text-[11px] text-center font-semiBold">
                            Gear 6
                          </div>
                          <div>
                            <Progress
                              type="circle"
                              percent={60}
                              strokeColor={`${
                                themeMode == 'dark' ? 'white' : colorTheme
                              }`}
                              size={60}
                              strokeWidth={10}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomDrawer
          open={open}
          setOpen={setOpen}
          title="Fleet Details"
          placement="right"
          width={400}
          children={<DrawerBody />}
        />
      </>
    </Layout>
  );
};

export default FuelEfficiency;
