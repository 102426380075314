import { QuestionCircleOutlined } from '@ant-design/icons';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import PopConfirm from 'components/PopConfirm/index';
import Status from 'components/Status/index';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import { delete_configuration, get_configuration } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { usePermissionVariables } from 'Hooks/HasPermission';
import { useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddConfigurationsModal from './components/AddConfigurationsModal';
import EditConfigurationsModal from './components/EditConfigurationsModal';

const Configurations = () => {
  const { Configuration_Delete, Configuration_Edit, Configuration_Add } =
    usePermissionVariables();
  const [openAddConfigurationsModal, setOpenAddConfigurationsModal] =
    useState(false);
  const [openEditConfigurationsModal, setOpenEditConfigurationsModal] =
    useState(false);
  const [selectedConfigurationsRecord, setSelectedConfigurationsRecord] =
    useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const { useGetRequest, deleteRequest } = useApiClient();
  const { data, isLoading, refetch } = useGetRequest(get_configuration);
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const navigate = useNavigate();

  const delHandler = (record) => {
    deleteRequest(`${delete_configuration}${record?.configurationId}`)
      .then((res) => {
        if (res?.success) {
          const newTotalRecord = totalRecord - 1; // Decrement the total record count
          setTotalRecord(newTotalRecord); // Update the state

          // Check if we need to change the page number
          if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1); // Redirect to the previous page
          }
          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };

  const columns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      key: 'sr',
      ellipsis: true,
      width: 80,
      render: (text, row, index) => index + 1,
    },
    {
      title: 'Configuration Key',
      dataIndex: 'configKey',
      ellipsis: true,
      sorter: (a, b) => a.configKey.localeCompare(b.configKey),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'configKey'
          ? sortOrder.order
          : 'ascend',
      showSorterTooltip: false,
    },
    { title: 'Configuration Value', dataIndex: 'configValue', ellipsis: true },
    {
      title: <span className="flex items-center justify-center">Status</span>,
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      width: 300,
      ellipsis: true,
      render: (tag, record) => {
        return (
          <span>
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },
    ...(Configuration_Edit || Configuration_Delete
      ? [
          {
            title: (
              <span className=" flex items-center justify-center">Action</span>
            ),
            dataIndex: '',
            ellipsis: true,
            width: 100,
            render: (text, record) => (
              <span className="flex justify-center items-center space-x-2">
                {Configuration_Edit && (
                  <div
                    className=" p-[5px] rounded-sm"
                    onClick={() => {
                      setSelectedConfigurationsRecord(record);
                      setOpenEditConfigurationsModal(true);
                    }}
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                )}
                {Configuration_Delete && (
                  <div
                    className=" p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this task? "
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    >
                      <MdDeleteOutline className=" w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                )}
              </span>
            ),
          },
        ]
      : []),
  ];
  return (
    <div>
      {Configuration_Add && (
        <div className="sm:flex justify-end">
          <PrimaryButton
            title="Add Configurations"
            icon={<FaPlus />}
            onClick={() => setOpenAddConfigurationsModal(true)}
          />
        </div>
      )}

      <Table
        data={data?.data}
        columns={columns}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        setTotalRecord={setTotalRecord}
        totalRecord={totalRecord}
        setPageNumber={setPageNumber}
        setRowsPerPage={setRowsPerPage}
        setSortOrder={setSortOrder}
        setFilters={setFilters}
        loading={isLoading}
      />
      {openAddConfigurationsModal && (
        <AddConfigurationsModal
          setOpenAddConfigurationsModal={setOpenAddConfigurationsModal}
          openAddConfigurationsModal={openAddConfigurationsModal}
          refetch={refetch}
        />
      )}
      {openEditConfigurationsModal && (
        <EditConfigurationsModal
          setOpenEditConfigurationsModal={setOpenEditConfigurationsModal}
          openEditConfigurationsModal={openEditConfigurationsModal}
          selectedConfigurationsRecord={selectedConfigurationsRecord}
          refetch={refetch}
        />
      )}
    </div>
  );
};
export default Configurations;
