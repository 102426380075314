import { Breadcrumb, Tabs, Typography } from 'antd';
import Layout from 'layout/AdminLayout';
import { useEffect, useState } from 'react';
import {
  BsChevronDoubleDown,
  BsChevronDoubleUp,
  BsThreeDots,
} from 'react-icons/bs';
import { CgDetailsMore } from 'react-icons/cg';
import { FiTruck } from 'react-icons/fi';
import { GoHome } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import VehicleDashboard from './components/VehicleDashboard';
import Vehicleinfo from './components/Vehicleinfo';
import VehicleMaintenance from './components/VehicleMaintenance';
import VehiclesDocuments from './components/VehiclesDocuments';

const { Title } = Typography;

const VehicleDetails = () => {
  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const [isAtBottom, setIsAtBottom] = useState(false);

  const items = [
    {
      key: '1',
      label: 'Dashboard',
      children: <VehicleDashboard />,
    },
    {
      key: '2',
      label: 'Loads',
      children: '',
    },
    {
      key: '3',
      label: 'Maintenance',
      children: <VehicleMaintenance />,
    },
    {
      key: '4',
      label: 'Drivers',
      children: '',
    },
    {
      key: '5',
      label: 'Devices',
      children: '',
    },
    {
      key: '6',
      label: 'Doucments',
      children: <VehiclesDocuments />,
    },
  ];

  const onChange = (key) => {};

  const handleScroll = () => {
    if (isAtBottom) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const checkScrollPosition = () => {
      const isBottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 100;
      setIsAtBottom(isBottom);
    };
    window.addEventListener('scroll', checkScrollPosition);
    return () => window.removeEventListener('scroll', checkScrollPosition);
  }, []);

  return (
    <Layout>
      <div>
        <div className="mb-[30px]">
          <Title
            level={4}
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
              fontFamily: 'inter-bold',
            }}
          >
            TMS
          </Title>
          <div className="flex justify-between items-center mb-20">
            <div>
              <Breadcrumb
                separator="/"
                items={[
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <GoHome />
                          <span
                            className=" cursor-pointer font-reg text-[13px]"
                            onClick={() => {
                              navigate('/dashboard');
                            }}
                          >
                            Dashboard
                          </span>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <FiTruck />
                          <span
                            className=" cursor-pointer font-reg text-[13px]"
                            onClick={() => {
                              navigate('/vehicles');
                            }}
                          >
                            Vehicles
                          </span>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4 ">
                          <CgDetailsMore
                            style={{
                              color: themeMode === 'light' ? colorTheme : '',
                            }}
                          />
                          <span
                            className=" font-bold text-[13px]"
                            style={{
                              color: themeMode === 'light' ? colorTheme : '',
                            }}
                          >
                            Details
                          </span>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="pb-[40px]">
          <div>
            <Vehicleinfo />
          </div>
          <div className=" mt-[20px]">
            <Tabs
              defaultActiveKey="1"
              items={items}
              type="card"
              onChange={onChange}
              moreIcon={
                <span>
                  <BsThreeDots
                    style={{
                      color: themeMode === 'light' ? colorTheme : '#939BC9',
                    }}
                  />
                </span>
              }
              size="small"
            />
          </div>
        </div>
      </div>

      <div
        className="fixed bottom-5 right-5 cursor-pointer rounded-full p-7 shadow-lg transition-all duration-300 animate-bounce"
        style={{
          backgroundColor: colorTheme,
        }}
        onClick={handleScroll}
      >
        <div className="flex justify-center items-center">
          {isAtBottom ? (
            <BsChevronDoubleUp size={14} style={{ color: '#fff' }} />
          ) : (
            <BsChevronDoubleDown size={14} style={{ color: '#fff' }} />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default VehicleDetails;
