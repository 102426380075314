import { HolderOutlined } from '@ant-design/icons';
import Layout from 'layout/AdminLayout';
import { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
const PickupAndDeliveryStatus = () => {
  const [items, setItems] = useState([
    { type: 'Pickup', location: '123 Main St', time: '08:45' },
    { type: 'Delivery', location: '456 Elm St', time: '09:15' },
    { type: 'Pickup', location: '789 Oak St', time: '10:30' },
    { type: 'Delivery', location: '321 Pine St', time: '11:00' },
    { type: 'Pickup', location: '654 Maple St', time: '11:45' },
    { type: 'Delivery', location: '987 Cedar St', time: '12:30' },
    { type: 'Pickup', location: '159 Spruce St', time: '13:15' },
    { type: 'Delivery', location: '753 Birch St', time: '13:45' },
    { type: 'Pickup', location: '852 Willow St', time: '14:30' },
    { type: 'Delivery', location: '951 Ash St', time: '15:00' },
    { type: 'Pickup', location: '102 River Rd', time: '15:45' },
    { type: 'Delivery', location: '203 Lake Rd', time: '16:15' },
    { type: 'Pickup', location: '304 Park Ave', time: '17:00' },
    { type: 'Delivery', location: '405 Ocean Blvd', time: '17:30' },
    { type: 'Pickup', location: '506 Mountain Rd', time: '18:00' },
    { type: 'Delivery', location: '607 Valley Dr', time: '18:30' },
    { type: 'Pickup', location: '708 Sunset St', time: '19:00' },
    { type: 'Delivery', location: '809 Sunrise Blvd', time: '19:30' },
    { type: 'Pickup', location: '910 Meadow Ln', time: '20:00' },
    { type: 'Delivery', location: '1012 Forest Dr', time: '20:30' },
  ]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    setItems(reorderedItems);
  };

  return (
    <Layout>
      <div className="flex gap-[15px]">
        <div className="w-[40%]">
          <div className="flex w-full px-[10px] py-[10px] bg-[#F7F7FA] font-semiBold text-[12px]">
            <div className="w-[100px]"></div>
            <div className="w-full">Sequence</div>
            <div className="w-full">Type</div>
            <div className="w-full">Location</div>
            <div className="w-full text-center">Time</div>
          </div>
          <div
            className="overflow-y-auto show-scroll"
            style={{ maxHeight: 'calc(100vh - 160px)' }}
          >
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {items.map((item, index) => (
                      <Draggable
                        key={index}
                        draggableId={`draggable-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="flex w-full px-[10px] py-[10px] text-[10px] border-b-[1px] border-[#f0f0f0]"
                          >
                            <div className=" w-[100px]">
                              <HolderOutlined />
                            </div>
                            <div className="w-full">{index + 1}</div>
                            <div
                              className="w-full"
                              style={{
                                color: item.type === 'Pickup' ? 'green' : 'red',
                              }}
                            >
                              {item.type}
                            </div>
                            <div className="w-full">{item.location}</div>
                            <div className="w-full text-center">
                              {item.time}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
        <div className="w-[60%]">map</div>
      </div>
    </Layout>
  );
};

export default PickupAndDeliveryStatus;
