import { Breadcrumb, Image, Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import DatePickerField from 'components/CustomFields/DatePickerField';
import TextField from 'components/CustomFields/TextField';
import Checkbox from 'components/InputFields/Checkbox';
import PhoneInput from 'components/InputFields/PhoneInput';
import LabelGrid from 'components/LabelGrid';
import InfoPopover from 'components/PopOver/InfoPopOver';
import ToastNotification from 'components/ToastNotification';
import useAxiosClientTMS from 'config/useAxiosClientTMS';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import Layout from 'layout/AdminLayout';
import { useState } from 'react';
import { BiSave } from 'react-icons/bi';
import { BsExclamationCircle } from 'react-icons/bs';
import { FaPlus } from 'react-icons/fa';
import { GoHome } from 'react-icons/go';
import { MdCancel } from 'react-icons/md';
import { PiSteeringWheel } from 'react-icons/pi';
import { RxCrossCircled } from 'react-icons/rx';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { DATE_FORMATS } from 'utils/constant';
import { documentOptionsLabel } from 'utils/helper';
import {
  GET_DOCUMENTS_BY_ENTITY_TYPE_DRIVER_DOCUMENTS,
  GET_DRIVERS_KEY,
} from 'utils/queryKey';
import useValidationSchema from 'validation/useValidationSchema';
import AddDocumentModal from './AddDocumentModal';
import PictureUpload from './PictureUpload';
const { Title, Text } = Typography;

const Drivers = () => {
  const location = useLocation();
  const { pageNumber, rowsPerPage } = location.state || {
    pageNumber: 1,
    rowsPerPage: 10,
  };
  const { postRequest, postRequestFormData, useGetRequest } =
    useAxiosClientTMS();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [loading, setLoading] = useState(false);
  const [openDocumentModal, setDocumentModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [items, setItems] = useState([]);
  const [fileList, setFileList] = useState([]);

  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { refetch: driverRefetch } = useGetRequest(
    `/api/GetAlLdrivers?pagenumber=${pageNumber}&pagesize=${rowsPerPage}&searchquery=`,
    { enabled: false }
  );
  const { driverLicenceFormSchema, addDriverDocumentSchema } =
    useValidationSchema();
  const formikDocuments = useFormik({
    initialValues: {
      // entityType: '',
      documentType: '',
      documentPicture: '',
      issueDate: '',
      expiryDate: '',
    },
    validationSchema: addDriverDocumentSchema,
    onSubmit: ({
      documentName,
      documentType,
      documentPicture,
      issueDate,
      expiryDate,
      entityType,
    }) => {
      setLoading(true);
      const paylaod = {
        EntityType: 'driverdocuments',
        documentType: documentType,
        documentPicture: documentPicture,
        issueDate: dayjs(issueDate).format(DATE_FORMATS.DATE_ONLY),
        expiryDate: dayjs(expiryDate).format(DATE_FORMATS.DATE_ONLY),
      };
      addItem(paylaod);
      setLoading(false);
      formikDocuments.resetForm();
      setDocumentModal(false);
    },
  });
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      phoneNumber: '+92',
      email: '',
      joiningDate: '',
      licenseNumber: '',
      issueDate: '',
      expiryDate: '',
      address: '',
      licenseType: '',
      profile: '',
      nationality: '',
      nationalIdentity: '',
      isActiveDriver: false,
      isActiveLicence: false,
    },
    validationSchema: driverLicenceFormSchema,
    onSubmit: ({
      firstName,
      lastName,
      dateOfBirth,
      phoneNumber,
      email,
      address,
      joiningDate,
      nationality,
      nationalIdentity,
      licenseNumber,
      issueDate,
      expiryDate,
      licenseType,
      isActiveDriver,
      isActiveLicence,
      profile,
    }) => {
      setLoading(true);
      const paylaod = {
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber,
        email: email,
        joiningDate: dayjs(joiningDate).format(DATE_FORMATS.PAYLOAD_FORMAT),
        dateofBirth: dayjs(dateOfBirth).format(DATE_FORMATS.PAYLOAD_DATE_ONLY),
        nic: nationalIdentity,
        nationality: nationality,
        isActive: isActiveDriver,
        address: address,
        licenseDto: {
          isActive: isActiveLicence,
          licenseNumber: licenseNumber,
          issueDate: dayjs(issueDate).format(DATE_FORMATS.PAYLOAD_FORMAT),
          expiryDate: dayjs(expiryDate).format(DATE_FORMATS.PAYLOAD_FORMAT),
          licenseType: licenseType,
        },
      };

      postRequest('/api/AddDriver', paylaod)
        .then((res) => {
          if (res?.success) {
            if (items?.length > 0) {
              items.forEach((item) => {
                const formDataPayload = {
                  DocumentType: item?.documentType,
                  EntityType: item?.EntityType,
                  IssuedDate: item?.issueDate || '',
                  ExpiryDate: item?.expiryDate || '',
                  DocumentFile: item?.documentPicture,
                  EntityId: res?.data?.driverId,
                };

                postRequestFormData('/api/AddDocument', formDataPayload).then(
                  (documentRes) => {
                    if (documentRes?.success) {
                      queryClient.invalidateQueries({
                        queryKey: [
                          GET_DOCUMENTS_BY_ENTITY_TYPE_DRIVER_DOCUMENTS,
                        ],
                      });
                    }
                  }
                );
              });
            }
            if (profile) {
              postRequestFormData('/api/AddDocument', {
                EntityType: 'driverdocuments',
                DocumentType: 'profilepicture',
                DocumentFile: profile,
                EntityId: res?.data?.driverId,
              });
            }
            formik.resetForm();
            navigate('/drivers');
            ToastNotification('success', res?.message, themeMode);
            driverRefetch();
            queryClient.invalidateQueries({
              queryKey: [GET_DRIVERS_KEY],
            });
          } else {
            ToastNotification('error', res?.message, themeMode);
          }
        })
        .catch(() => {
          ToastNotification('error', 'Something went wrong', themeMode);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const addItem = (paylaod) => {
    const newItem = paylaod || {
      documentName: '',
      documentType: '',
      documentPicture: '',
      issueDate: dayjs().format(DATE_FORMATS.DATE_ONLY),
      expiryDate: dayjs().format(DATE_FORMATS.DATE_ONLY),
    };
    setItems([...items, newItem]);
  };
  const deleteItem = (index) => {
    setItems(items.filter((_, i) => i !== index));
  };

  return (
    <Layout>
      <>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-[30px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
                marginBottom: '10px',
              }}
            >
              TMS
            </Title>
            <div className="flex justify-between items-center mb-20">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <PiSteeringWheel />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/drivers');
                              }}
                            >
                              Driver Management
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4 ">
                            <FaPlus
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />
                            <span
                              className=" font-bold text-[13px]"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              Add Driver
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
              <div className="flex justify-end space-x-10">
                <div>
                  <SecondaryButton
                    title="Cancel"
                    onClick={() => {
                      formik.resetForm();
                      navigate('/drivers');
                    }}
                    icon={<MdCancel size={17} />}
                    className=" w-full text-[13px]"
                  />
                </div>
                <div>
                  <PrimaryButton
                    title="Save"
                    icon={<BiSave className=" w-[17px] h-[17px]" />}
                    htmlType="submit"
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
          <LabelGrid label="Driver Info" className=" rounded-[5px] px-20 py-20">
            <div className="md:flex gap-40">
              <div className=" flex justify-center ">
                <PictureUpload
                  formik={formik}
                  id={'profile'}
                  setFileList={setFileList}
                  fileList={fileList}
                />
              </div>
              <div className="grid grid-cols-12 sm:gap-x-[20px] sm:gap-y-5 sm:mb-[30px]  w-full">
                <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                  <TextField
                    formik={formik}
                    id={'firstName'}
                    label={'First Name'}
                    maxLength={30}
                    notModal={true}
                  />
                </div>
                <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                  <TextField
                    formik={formik}
                    id={'lastName'}
                    label={'Last Name'}
                    maxLength={30}
                    notModal={true}
                  />
                </div>
                <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                  <DatePickerField
                    formik={formik}
                    id={'dateOfBirth'}
                    label={'Date Of Birth'}
                    notModal={true}
                  />
                </div>
                <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                  <TextField
                    formik={formik}
                    id={'email'}
                    label={'Email'}
                    maxLength={30}
                    notModal={true}
                  />
                </div>
                <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                  <PhoneInput
                    label="Phone Number"
                    name="phoneNumber"
                    value={formik.values.phoneNumber}
                    onChange={(value) => {
                      if (value.length <= 18) {
                        formik.setFieldValue('phoneNumber', value);
                        formik.setFieldTouched('phoneNumber', true, false);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                    errorMessage={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                        ? formik.errors.phoneNumber
                        : null
                    }
                  />
                </div>
                <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                  <TextField
                    formik={formik}
                    id={'address'}
                    label={'Address'}
                    maxLength={30}
                    notModal={true}
                  />
                </div>
                <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                  <DatePickerField
                    formik={formik}
                    id={'joiningDate'}
                    label={'Joining Date'}
                    notModal={true}
                  />
                </div>
                <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                  <TextField
                    formik={formik}
                    id={'nationality'}
                    label={'Nationality'}
                    maxLength={30}
                    notModal={true}
                  />
                </div>
                <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                  <TextField
                    formik={formik}
                    id={'nationalIdentity'}
                    label={'National Identity'}
                    maxLength={30}
                    notModal={true}
                  />
                </div>
                <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 flex items-center">
                  <Checkbox
                    title="IsActive"
                    id="isActiveDriver"
                    name="isActiveDriver"
                    checked={formik.values.isActiveDriver}
                    onChange={formik.handleChange}
                    error={formik.errors.isActiveDriver}
                    errorMessage={
                      formik.touched.isActiveDriver &&
                      formik.errors.isActiveDriver
                        ? formik.errors.isActiveDriver
                        : null
                    }
                  />
                </div>
              </div>
            </div>
          </LabelGrid>
          <LabelGrid
            label="Licence Info"
            className=" rounded-[5px] px-20 py-20"
          >
            <div className="grid grid-cols-12 sm:gap-x-[20px] sm:gap-y-0">
              <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                <TextField
                  formik={formik}
                  id={'licenseNumber'}
                  label={'License Number'}
                  maxLength={30}
                  notModal={true}
                />
              </div>
              <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                <TextField
                  formik={formik}
                  id={'licenseType'}
                  label={'License Type'}
                  maxLength={30}
                  notModal={true}
                />
              </div>
              <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                <DatePickerField
                  formik={formik}
                  id={'issueDate'}
                  label={'Issue Date'}
                  notModal={true}
                />
              </div>
              <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                <DatePickerField
                  formik={formik}
                  id={'expiryDate'}
                  label={'Expiry Date'}
                  notModal={true}
                />
              </div>
              <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 flex items-center">
                <Checkbox
                  title="IsActive"
                  id="isActiveLicence"
                  name="isActiveLicence"
                  checked={formik.values.isActiveLicence}
                  onChange={formik.handleChange}
                  error={formik.errors.isActiveLicence}
                  errorMessage={
                    formik.touched.isActiveLicence &&
                    formik.errors.isActiveLicence
                      ? formik.errors.isActiveLicence
                      : null
                  }
                />
              </div>
            </div>

            <div className="flex justify-end items-center">
              <div
                className=" text-sm underline ml-[10px] cursor-pointer my-[10px]"
                style={{
                  color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                }}
                onClick={() => setDocumentModal(true)}
              >
                <span className="flex">Upload Document</span>
              </div>
              <sup>
                <InfoPopover content={'Upload Documents'}>
                  <BsExclamationCircle className="ml-2 w-10 h-10" />
                </InfoPopover>
              </sup>
            </div>
            <div
              className={`grid grid-cols-6 text-sm gap-20 ${
                items?.length !== 0 ? 'mt-15' : ''
              }`}
            >
              {items?.map((item, index) => (
                <>
                  <div className="flex items-center gap-8" key={index}>
                    <Text
                      underline
                      code
                      className="cursor-pointer text-[12px]"
                      onClick={() => setVisible(true)}
                    >
                      {documentOptionsLabel(item.documentType).label || '--'}
                    </Text>
                    <div
                      onClick={() => deleteItem(index)}
                      className="cursor-pointer"
                    >
                      <RxCrossCircled className="w-15 mt-2 h-15 text-[red]" />
                    </div>
                  </div>

                  <Image
                    width={200}
                    style={{ display: 'none' }}
                    src={
                      item?.documentPicture?.url || // If documentPicture contains a URL
                      (item?.documentPicture instanceof File &&
                        URL.createObjectURL(item.documentPicture)) ||
                      ''
                    }
                    preview={{
                      visible,
                      src:
                        item?.documentPicture?.url ||
                        (item?.documentPicture instanceof File &&
                          URL.createObjectURL(item?.documentPicture)) ||
                        '',
                      onVisibleChange: (value) => {
                        setVisible(value);
                      },
                    }}
                  />
                </>
              ))}
            </div>
          </LabelGrid>
        </form>
        {openDocumentModal && (
          // && items?.length === 0
          <AddDocumentModal
            setDocumentModal={setDocumentModal}
            openDocumentModal={openDocumentModal}
            items={items}
            setItems={setItems}
            formikDocuments={formikDocuments}
          />
        )}
      </>
    </Layout>
  );
};

export default Drivers;
