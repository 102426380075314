import { Typography, theme } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const { Title } = Typography;
const { useToken } = theme;

const data = [
  { name: 'Jan', value: 400 },
  { name: 'Feb', value: 300 },
  { name: 'Mar', value: 200 },
  { name: 'Apr', value: 278 },
  { name: 'May', value: 189 },
  { name: 'Jun', value: 239 },
  { name: 'Jul', value: 349 },
  { name: 'Aug', value: 200 },
];

const CustomTooltip = ({ payload, label, active }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white flex justify-center items-center rounded-lg  shadow-lg w-[50px] h-[50px]">
        <div className="">
          <p className="text-md font-bold text-[#5A5881]">{label}</p>
          {payload.map((entry, index) => (
            <p key={`item-${index}`} className="text-sm text-[#5A5881]">
              {/* <span
              className="text-base font-semibold"
              style={{ color: entry.color }}
            >
              {entry.name}:{' '}
            </span> */}
              <span>{entry.value}</span>
            </p>
          ))}
        </div>
      </div>
    );
  }

  return null;
};
const LineChartWithShadow = () => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const [activeIndex, setActiveIndex] = useState(null);
  const { token } = useToken();
  const primaryColor = token.colorPrimary;

  const handleMouseOver = (data, index) => {
    setActiveIndex(index);
  };

  const handleMouseOut = () => {
    setActiveIndex(null);
  };

  const renderCustomAxisTick = (props) => {
    const { x, y, payload, index } = props;
    const isActive = index === activeIndex;
    return (
      <text
        x={x}
        y={y}
        dy={16}
        textAnchor="middle"
        fill={isActive ? primaryColor : '#666'}
        fontSize={isActive ? 14 : 12}
        fontWeight={isActive ? 'bold' : 'normal'}
      >
        {payload.value}
      </text>
    );
  };

  return (
    <>
      <div
        className=" h-[250px] px-[15px] py-[20px]  rounded-sm shadow-md"
        style={{
          backgroundColor: themeMode === 'light' ? '#f7f7fa' : '#121212',
        }}
      >
        <div className="  flex justify-end items-center">
          <div className=" flex justify-between items-center space-x-5">
            <div className="">
              <Title
                level={5}
                style={{
                  color: primaryColor,
                  fontFamily: 'inter-semibold',
                  height: 18,
                  fontSize: '12px',
                }}
              >
                Travel Last Month
              </Title>
            </div>
          </div>
        </div>
        <div>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart
              data={data}
              margin={{ top: 10, right: 50, left: 0, bottom: 0 }}
              onMouseMove={(e) =>
                e.isTooltipActive &&
                handleMouseOver(e.activePayload[0], e.activeTooltipIndex)
              }
              onMouseLeave={handleMouseOut}
            >
              <defs>
                <filter id="shadow" height="200%">
                  <feDropShadow
                    dx="0"
                    dy="20" // Increase shadow offset downwards
                    stdDeviation="15" // Increase blur radius
                    floodColor={primaryColor} // Shadow color
                    floodOpacity="0.5" // Increase shadow opacity
                  />
                </filter>
              </defs>
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <XAxis
                dataKey="name"
                axisLine={false}
                tick={renderCustomAxisTick}
                tickLine={false}
              />
              <YAxis axisLine={false} tick={false} />
              <Tooltip cursor={false} content={<CustomTooltip />} />
              <Line
                layout="horizontal"
                type="monotone"
                dataKey="value"
                stroke={primaryColor}
                strokeWidth={3}
                filter="url(#shadow)"
                dot={{ stroke: primaryColor, strokeWidth: 2 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};

export default LineChartWithShadow;
