import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { Select, Tag } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import InputFieldCustomForm from 'components/CustomFields/CustomFieldsOutlinedForm';
import DatePickerField from 'components/CustomFields/DatePickerField';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
import Checkbox from 'components/InputFields/Checkbox';
import TextArea from 'components/InputFields/TextArea';
import Loading from 'components/Loading';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import truckIcon from 'images/delivery-truck.svg';
import Layout from 'layout/AdminLayout';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { BiSave } from 'react-icons/bi';
import { SlArrowDown } from 'react-icons/sl';
import { useSelector } from 'react-redux';
import { themeInputColor } from 'utils/constant';
import * as Yup from 'yup';
const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: 'calc(100vh - 100px)',
  borderRadius: '5px',
};
const polylinePath = [
  { lat: 37.7749, lng: -122.4194 },
  { lat: 37.8044, lng: -122.2712 },
  { lat: 37.6879, lng: -122.4702 },
  { lat: 38.775, lng: -122.4194 },
];

const options = {
  strokeColor: '#FF0000',
  strokeOpacity: 1,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};
const center = { lat: 37.7749, lng: -122.4194 };
const RoutePlanning = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDLGitEALPVLPoPzj7XTq2uA3Us-QutdiM',
    libraries,
  });

  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const formik = useFormik({
    initialValues: {
      routeName: '',
      assignRoute: null,
      repeatThisRoute: false,
      startDestination: null,
      startArrival: '',
      startDeparture: '',
      startNotes: '',
      endDestination: null,
      endArrival: '',
      endDeparture: '',
      endNotes: '',
    },

    onSubmit: (values) => {
      // handle form submission
    },
  });
  const innerFormik = useFormik({
    initialValues: {
      stop: [],
    },
    validationSchema: Yup.object({
      stop: Yup.array().of(
        Yup.object({
          stopDestination: Yup.string().required('Destination is required'),
          stopArrival: Yup.string().required('Arrival time is required'),
          stopDeparture: Yup.string().required('Departure time is required'),
        })
      ),
    }),
    onSubmit: (values) => {
      // handle form submission
    },
  });
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(innerFormik.values.stop);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    innerFormik.setFieldValue('stop', items);
  };

  const handleAdd = async () => {
    const errors = await innerFormik.validateForm();
    if (Object.keys(errors).length === 0) {
      innerFormik.setValues({
        ...innerFormik.values,
        stop: [
          ...innerFormik.values.stop,
          {
            stopDestination: null,
            stopArrival: '',
            stopDeparture: '',
            stopNotes: '',
          },
        ],
      });
    }
  };
  const handleRemove = (index) => {
    const updatedStop = innerFormik.values.stop?.filter((_, i) => i !== index);
    innerFormik.setValues({
      ...innerFormik.values,
      planFeature: updatedStop,
    });
  };
  if (loadError) {
    return <div>Error loading maps</div>;
  }
  return (
    <Layout>
      <>
        <div className=" flex justify-between space-x-10">
          <div className=" w-[60%]">
            <div className=" flex justify-between items-end">
              <div className=" text-[20px] font-bold">Create New Route</div>
              <div>
                <PrimaryButton
                  title={'Save'}
                  icon={<BiSave size={17} />}
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                />
              </div>
            </div>

            <div>
              <TextField
                formik={formik}
                id={'routeName'}
                label={'Route Name'}
                maxLength={30}
                notModal={true}
              />
            </div>
            <div className="grid grid-cols-12 gap-[15px]">
              <div className=" col-span-6">
                <SelectField
                  formik={formik}
                  id={'assignRoute'}
                  label={'Assign Right To'}
                  optionId={'value'}
                  optionName={'label'}
                  optionData={[
                    { label: 'Driver', value: 'Driver' },
                    { label: 'Vehicle', value: 'Vehicle' },
                    { label: 'Un-Assigned', value: 'UnAssigned' },
                  ]}
                  notModal={true}
                />
              </div>
              <div className=" col-span-6 mt-[12px]">
                <Checkbox
                  title="Repeat This Route"
                  id="repeatThisRoute"
                  name="repeatThisRoute"
                  checked={formik.values.repeatThisRoute}
                  onChange={formik.handleChange}
                  error={formik.errors.repeatThisRoute}
                  errorMessage={
                    formik.touched.repeatThisRoute &&
                    formik.errors.repeatThisRoute
                      ? formik.errors.repeatThisRoute
                      : null
                  }
                />
              </div>
            </div>
            <div>
              <div className=" flex justify-between items-center py-[20px]">
                <div className=" text-[16px] font-semiBold">Route Stop</div>
                <div>
                  <PrimaryButton
                    title={'Add stop'}
                    onClick={() => {
                      innerFormik.handleSubmit();
                      handleAdd();
                    }}
                  />
                </div>
              </div>

              <div className="flex w-full px-[10px] py-[10px] bg-[#F7F7FA] font-semiBold text-[12px]">
                <div className="w-[300px]">Type</div>
                <div className="w-full text-center">Destination</div>
                <div className="w-full text-center">Scheduled Arrival</div>
                <div className="w-full text-center">Scheduled Departure</div>
                <div className="w-full text-center">Notes</div>
              </div>

              <div className="flex items-center w-full px-[10px] text-[10px] border-b-[1px] border-[#f0f0f0]">
                <div className="w-[300px]">
                  <Tag color="#008000">Start</Tag>
                </div>
                <div className="w-full px-10">
                  <SelectField
                    formik={formik}
                    id={'startDestination'}
                    label={'Destination'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={[
                      { label: 'Driver', value: 'Driver' },
                      { label: 'Vehicle', value: 'Vehicle' },
                      { label: 'Un-Assigned', value: 'UnAssigned' },
                    ]}
                    notModal={true}
                  />
                </div>
                <div className="w-full px-10">
                  <DatePickerField
                    formik={formik}
                    id={'startArrival'}
                    label={'Arrival'}
                    minDate={dayjs()}
                    notModal={true}
                  />
                </div>
                <div className="w-full px-10">
                  <DatePickerField
                    formik={formik}
                    id={'startDeparture'}
                    label={'Departure'}
                    minDate={dayjs()}
                    notModal={true}
                  />
                </div>
                <div className="w-full px-10">
                  <div className="wrapper-fields ">
                    <TextArea
                      id="startNotes"
                      name="startNotes"
                      rows={1}
                      placeholder="Notes"
                      required={true}
                      value={formik.values.startNotes}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.startNotes &&
                        Boolean(formik.errors.startNotes)
                      }
                      errorMessage={
                        formik.touched.startNotes && formik.errors.startNotes
                          ? formik.errors.startNotes
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {innerFormik?.values?.stop?.map((item, index) => (
                        <Draggable
                          key={index}
                          draggableId={`draggable-${index}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="flex items-center w-full px-[10px] text-[10px] border-b-[1px] border-[#f0f0f0]"
                            >
                              <div className="w-[300px]">
                                <Tag color="#899499">Stop</Tag>
                              </div>
                              <div className="w-full px-10">
                                <div className="wrapper-fields mt-[10px]">
                                  <InputFieldCustomForm
                                    label="Destination"
                                    id={`stop.${index}.stopDestination`}
                                    value={
                                      innerFormik.values.stop[index]
                                        .stopDestination
                                    }
                                    error={
                                      innerFormik.touched.stop?.[index]
                                        ?.stopDestination &&
                                      Boolean(
                                        innerFormik.errors.stop?.[index]
                                          ?.stopDestination
                                      )
                                    }
                                    errorMessage={
                                      innerFormik.errors.stop?.[index]
                                        ?.stopDestination
                                    }
                                    required={true}
                                    notModal={true}
                                  >
                                    <Select
                                      id={`stop.${index}.stopDestination`}
                                      name={`stop.${index}.stopDestination`}
                                      prefix={<></>}
                                      mode="single"
                                      showSearch
                                      optionFilterProp="label"
                                      value={
                                        innerFormik.values.stop[index]
                                          .stopDestination
                                      }
                                      autoComplete={`new-stopDestination`}
                                      onChange={(value) => {
                                        innerFormik.setFieldValue(
                                          `stop.${index}.stopDestination`,
                                          value
                                        );
                                        innerFormik.setFieldTouched(
                                          `stop.${index}.stopDestination`,
                                          true,
                                          false
                                        );
                                      }}
                                      onBlur={innerFormik.handleBlur}
                                      suffixIcon={
                                        false ? (
                                          <Loading size="small" />
                                        ) : (
                                          <SlArrowDown
                                            className="w-[12px] h-[12px]"
                                            style={{
                                              color: !(
                                                innerFormik.touched.stop?.[
                                                  index
                                                ]?.stopDestination &&
                                                Boolean(
                                                  innerFormik.errors.stop?.[
                                                    index
                                                  ]?.stopDestination
                                                )
                                              )
                                                ? themeMode === 'light'
                                                  ? colorTheme
                                                  : themeInputColor.darkIconColor
                                                : themeInputColor.errorIconColor,
                                            }}
                                          />
                                        )
                                      }
                                      style={{
                                        border: !(
                                          innerFormik.touched.stop?.[index]
                                            ?.stopDestination &&
                                          Boolean(
                                            innerFormik.errors.stop?.[index]
                                              ?.stopDestination
                                          )
                                        )
                                          ? themeMode === 'light'
                                            ? `1px solid ${themeInputColor.lightBorderColor}`
                                            : `1px solid ${themeInputColor.darkBorderColor}`
                                          : `1px solid ${themeInputColor.errorBorderColor}`,
                                        borderRadius: '5px',
                                        caretColor:
                                          themeMode === 'light'
                                            ? themeInputColor.lightCaretColor
                                            : themeInputColor.darkCaretColor,
                                        color:
                                          themeMode === 'light'
                                            ? themeInputColor.lightTextColor
                                            : themeInputColor.darkTextColor,
                                      }}
                                    />
                                  </InputFieldCustomForm>
                                </div>
                              </div>
                              <div className="w-full px-10">
                                <SelectField
                                  formik={formik}
                                  id={'assignRoute'}
                                  label={'Assign Right To'}
                                  optionId={'value'}
                                  optionName={'label'}
                                  optionData={[
                                    { label: 'Driver', value: 'Driver' },
                                    { label: 'Vehicle', value: 'Vehicle' },
                                    {
                                      label: 'Un-Assigned',
                                      value: 'UnAssigned',
                                    },
                                  ]}
                                  notModal={true}
                                />
                              </div>
                              <div className="w-full px-10">
                                <SelectField
                                  formik={formik}
                                  id={'assignRoute'}
                                  label={'Assign Right To'}
                                  optionId={'value'}
                                  optionName={'label'}
                                  optionData={[
                                    { label: 'Driver', value: 'Driver' },
                                    { label: 'Vehicle', value: 'Vehicle' },
                                    {
                                      label: 'Un-Assigned',
                                      value: 'UnAssigned',
                                    },
                                  ]}
                                  notModal={true}
                                />
                              </div>
                              <div className="w-full px-10">
                                <SelectField
                                  formik={formik}
                                  id={'assignRoute'}
                                  label={'Assign Right To'}
                                  optionId={'value'}
                                  optionName={'label'}
                                  optionData={[
                                    { label: 'Driver', value: 'Driver' },
                                    { label: 'Vehicle', value: 'Vehicle' },
                                    {
                                      label: 'Un-Assigned',
                                      value: 'UnAssigned',
                                    },
                                  ]}
                                  notModal={true}
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className="flex items-center w-full px-[10px] text-[10px] border-b-[1px] border-[#f0f0f0]">
                <div className="w-[300px]">
                  <Tag color="#ff0000">End</Tag>
                </div>
                <div className="w-full px-10">
                  <SelectField
                    formik={formik}
                    id={'endDestination'}
                    label={'Destination'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={[
                      { label: 'Driver', value: 'Driver' },
                      { label: 'Vehicle', value: 'Vehicle' },
                      { label: 'Un-Assigned', value: 'UnAssigned' },
                    ]}
                    notModal={true}
                  />
                </div>
                <div className="w-full px-10">
                  <DatePickerField
                    formik={formik}
                    id={'endArrival'}
                    label={'Arrival'}
                    minDate={dayjs()}
                    notModal={true}
                  />
                </div>
                <div className="w-full px-10">
                  <DatePickerField
                    formik={formik}
                    id={'endDeparture'}
                    label={'Departure'}
                    minDate={dayjs()}
                    notModal={true}
                  />
                </div>
                <div className="w-full px-10">
                  <div className="wrapper-fields ">
                    <TextArea
                      id="endNotes"
                      name="endNotes"
                      rows={1}
                      placeholder="Notes"
                      required={true}
                      value={formik.values.endNotes}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.endNotes &&
                        Boolean(formik.errors.endNotes)
                      }
                      errorMessage={
                        formik.touched.endNotes && formik.errors.endNotes
                          ? formik.errors.endNotes
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" w-[40%]">
            <>
              {isLoaded ? (
                <>
                  <div>
                    <GoogleMap
                      className="rounded-sm"
                      mapContainerStyle={mapContainerStyle}
                      zoom={10}
                      center={center}
                    >
                      <Marker
                        // position={markerPosition}
                        // ref={markerRef}
                        icon={{
                          url: truckIcon,
                          scaledSize: new window.google.maps.Size(30, 30),
                          origin: new window.google.maps.Point(0, 0),
                          anchor: new window.google.maps.Point(25, 25),
                          rotation: 215,
                        }}
                      />
                    </GoogleMap>
                  </div>
                </>
              ) : (
                <>
                  <div className="w-full flex items-center justify-center py-[80px]">
                    <Loading />
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default RoutePlanning;
