import React from 'react';
import { Drawer } from 'antd';
const CustomDrawer = ({
  open,
  setOpen,
  title,
  placement,
  width,
  extra,
  children,
}) => {
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Drawer
        title={title}
        placement={placement}
        closable={false}
        onClose={onClose}
        open={open}
        width={width}
        key={placement}
        extra={extra}
      >
        {children}
      </Drawer>
    </>
  );
};
export default CustomDrawer;
