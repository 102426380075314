import { ClockCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  DatePicker,
  Input,
  Select,
  Spin,
  Tag,
  TimePicker,
  Typography,
} from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import InputFieldCustomForm from 'components/CustomFields/CustomFieldsOutlinedForm';
import DatePickerField from 'components/CustomFields/DatePickerField';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
import TimePickerField from 'components/CustomFields/TimePickerField';
import Loading from 'components/Loading';
import PopConfirm from 'components/PopConfirm';
import ToastNotification from 'components/ToastNotification';
import useAxiosClientTMS from 'config/useAxiosClientTMS';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { MdDeleteOutline } from 'react-icons/md';
import { PiSteeringWheel, PiUsersFourLight } from 'react-icons/pi';
import { SlArrowDown } from 'react-icons/sl';
import { useSelector } from 'react-redux';
import { themeInputColor } from 'utils/constant';
import { createOptions } from 'utils/helper';
import {
  GET_CUSTOMERS_DROPDOWN_KEY,
  GET_DISPATCHERS_DROPDOWN_KEY,
  GET_DRIVERS_DROPDOWN_KEY,
  GET_TRAILERS_DROPDOWN_KEY,
  GET_VEHICLES_DROPDOWN_KEY,
} from 'utils/queryKey';
import MapModal from './MapModal';

const { Title, Text } = Typography;

const EditLoad = ({ loading, formik, innerFormik }) => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { useGetRequest } = useAxiosClientTMS();
  const [isMapModalOpen, setMapModalOpen] = useState(false);

  const { data: dispatcherData, isLoading: dispatcherLoading } = useGetRequest(
    `/api/GetDispatcherDropdown`,
    {},
    GET_DISPATCHERS_DROPDOWN_KEY
  );
  const { data: driverData, isLoading: driverLoading } = useGetRequest(
    '/api/GetdriversDropdown',
    {},
    GET_DRIVERS_DROPDOWN_KEY
  );
  const { data: vehicleData, isLoading: vehicleLoading } = useGetRequest(
    '/api/GetVehiclesDropdown',
    {},
    GET_VEHICLES_DROPDOWN_KEY
  );
  const { data: trailerData, isLoading: trailerLoading } = useGetRequest(
    '/api/GetTrailerDropdown',
    {},
    GET_TRAILERS_DROPDOWN_KEY
  );
  const { data: customerData, isLoading: customerLoading } = useGetRequest(
    '/api/GetCustomerDropdown',
    {},
    GET_CUSTOMERS_DROPDOWN_KEY
  );
  const customerOptions = createOptions(customerData?.data, 'name', 'id');
  const driversOptions = createOptions(driverData?.data, 'name', 'id');
  const vehicleOptions = createOptions(vehicleData?.data, 'number', 'id');
  const trailerOptions = createOptions(
    trailerData?.data,
    'trailerNumber',
    'id'
  );

  const dispatcherOptions = createOptions(dispatcherData?.data, 'name', 'id');

  const isValidStopDateOrder = (stops) => {
    for (let i = 1; i < stops.length; i++) {
      if (
        dayjs(stops[i].stopDate).isBefore(dayjs(stops[i - 1].stopDate), 'day')
      ) {
        return { isValid: false, errorIndex: i };
      }
    }
    return { isValid: true, errorIndex: -1 };
  };

  const isValidStopTimeOrder = (stops) => {
    for (let i = 1; i < stops.length; i++) {
      const currentStopDate = dayjs(stops[i].stopDate);
      const previousStopDate = dayjs(stops[i - 1].stopDate);
      const currentStopTime = dayjs(stops[i].stopTime, 'HH:mm:ss');
      const previousStopTime = dayjs(stops[i - 1].stopTime, 'HH:mm:ss');

      if (currentStopDate.isSame(previousStopDate, 'day')) {
        if (currentStopTime.isBefore(previousStopTime, 'second')) {
          console.log(
            `Error: Stop time must be greater than or equal to the previous stop time on the same day.`
          );
          return { isValid: false, errorIndex: i };
        }
      } else if (currentStopDate.isBefore(previousStopDate, 'day')) {
        return { isValid: false, errorIndex: i };
      }
    }
    return { isValid: true, errorIndex: -1 };
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(innerFormik.values.stop);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const dateOrderResult = isValidStopDateOrder(items);
    const timeOrderResult = isValidStopTimeOrder(items);

    if (!dateOrderResult.isValid) {
      ToastNotification(
        'error',
        `Error: Stop dates are not in the correct order. Issue at index ${dateOrderResult.errorIndex}.`,
        themeMode
      );
      return;
    }

    if (!timeOrderResult.isValid) {
      ToastNotification(
        'error',
        `Error: Stop times are not in the correct order. Issue at index ${timeOrderResult.errorIndex}.`,
        themeMode
      );
      return;
    }

    innerFormik.setFieldValue('stop', items);
  };

  const handleAdd = async () => {
    const errors = await innerFormik.validateForm();

    const dateOrderResult = isValidStopDateOrder(innerFormik.values.stop);
    const timeOrderResult = isValidStopTimeOrder(innerFormik.values.stop);

    if (
      Object.keys(errors).length === 0 &&
      dateOrderResult.isValid &&
      timeOrderResult.isValid
    ) {
      innerFormik.setValues({
        ...innerFormik.values,
        stop: [
          ...innerFormik.values.stop,
          {
            stopDate: '',
            stopTime: '',
            stopAddress: '',
            stopType: null,
          },
        ],
      });
    } else {
      if (!dateOrderResult.isValid) {
        ToastNotification(
          'error',
          `Error: Stop Date at # ${
            dateOrderResult.errorIndex + 1
          }  are not in the correct order `,
          themeMode
        );
      }
      if (!timeOrderResult.isValid) {
        ToastNotification(
          'error',
          `Error: Stop times at  ${
            timeOrderResult.errorIndex + 1
          } are not in the correct order`,
          themeMode
        );
      }
    }
  };

  const handleRemove = (index) => {
    const updatedStop = innerFormik.values.stop?.filter((_, i) => i !== index);
    innerFormik.setValues({
      ...innerFormik.values,
      stop: updatedStop,
    });
  };
  useEffect(() => {
    innerFormik.validateForm();
  }, [formik.values.pickupDate, formik.values.deliveryDate]);

  return (
    <>
      <Spin spinning={loading}>
        <form onSubmit={formik.handleSubmit}>
          <div className={`flex gap-20 place-items-start`}>
            <div className="grid flex-1">
              <Title
                style={{
                  color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                  fontFamily: 'inter-bold',
                  marginBottom: '0px',
                  fontSize: '12px',
                }}
              >
                Load
              </Title>
              <div>
                <SelectField
                  formik={formik}
                  id={'loadStatus'}
                  label={'Status'}
                  optionId={'value'}
                  optionName={'label'}
                  optionData={[
                    { value: 'Pending', label: 'Pending' },
                    { value: 'In-Transit', label: 'In-Transit' },
                  ]}
                  notModal={true}
                />
              </div>
              <div>
                <SelectField
                  formik={formik}
                  id={'loadDispatcher'}
                  label={'Dispatcher'}
                  optionId={'value'}
                  optionName={'label'}
                  optionData={dispatcherOptions}
                  isLoading={dispatcherLoading}
                  notModal={true}
                />
              </div>
            </div>
            <div className="grid flex-1">
              <Title
                style={{
                  color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                  fontFamily: 'inter-bold',
                  marginBottom: '0px',
                  fontSize: '12px',
                }}
              >
                <div className="flex gap-3">
                  <PiUsersFourLight size={14} />
                  Customer
                </div>
              </Title>
              <div className=" ">
                <SelectField
                  formik={formik}
                  id={'customerId'}
                  label={'Customer'}
                  optionId={'value'}
                  optionName={'label'}
                  optionData={customerOptions}
                  isLoading={customerLoading}
                  notModal={true}
                />
              </div>
              <div>
                <TextField
                  formik={formik}
                  id={'postOffice'}
                  label={'PO'}
                  maxLength={30}
                  notModal={true}
                />
              </div>
            </div>
            <div className="grid flex-1">
              <Title
                style={{
                  color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                  fontFamily: 'inter-bold',
                  marginBottom: '0px',
                  fontSize: '12px',
                }}
              >
                <div className="flex gap-3">
                  <PiSteeringWheel size={14} />
                  Driver
                </div>
              </Title>
              <div className=" ">
                <SelectField
                  formik={formik}
                  id={'driverId'}
                  label={'Driver'}
                  optionId={'value'}
                  disabled={formik.values.loadStatus === 'In-Transit'}
                  optionName={'label'}
                  optionData={driversOptions}
                  isLoading={driverLoading}
                  notModal={true}
                />
              </div>
              <div className=" ">
                <SelectField
                  formik={formik}
                  id={'vehicleId'}
                  label={'Vehicle'}
                  optionId={'value'}
                  optionName={'label'}
                  disabled={formik.values.loadStatus === 'In-Transit'}
                  optionData={vehicleOptions}
                  isLoading={vehicleLoading}
                  notModal={true}
                />
              </div>
              <div className=" ">
                <SelectField
                  formik={formik}
                  id={'trailerId'}
                  label={'Trailer'}
                  optionId={'value'}
                  optionName={'label'}
                  disabled={formik.values.loadStatus === 'In-Transit'}
                  optionData={trailerOptions}
                  isLoading={trailerLoading}
                  notModal={true}
                />
              </div>
            </div>
          </div>
          <div>
            <div className=" flex justify-between items-center py-[20px]">
              <div
                className=" text-[14px] text-green-600 font-semiBold cursor-pointer underline"
                onClick={() => {
                  setMapModalOpen(true);
                }}
              >
                View Route
              </div>
              <PrimaryButton
                title={'Add stop'}
                onClick={() => {
                  innerFormik.handleSubmit();
                  handleAdd();
                }}
              />
            </div>

            <div className="flex w-full px-[10px] py-[10px] bg-[#F7F7FA] font-semiBold text-[12px]">
              <div className="w-[300px]">Type</div>
              <div className="w-full text-center">Date</div>
              <div className="w-full text-center">Time</div>
              <div className="w-full text-center">Address</div>
              <div className="w-full text-center ">Type</div>
              <div className=" text-center w-[400px] ">Action</div>
            </div>

            <div className="flex items-center w-full px-[10px] py-[10px] text-[10px] border-b-[1px] border-[#f0f0f0]">
              <div className="w-[300px]">
                <Tag color="#008000">Pickup</Tag>
              </div>
              <div className="w-full px-10">
                <DatePickerField
                  formik={formik}
                  id={'pickupDate'}
                  label={'Date'}
                  notModal={true}
                />
              </div>
              <div className="w-full px-10">
                <TimePickerField
                  formik={formik}
                  id={'pickupTime'}
                  label={'Time'}
                  notModal={true}
                />
              </div>
              <div className="w-full px-10">
                <TextField
                  formik={formik}
                  id={'pickupAddress'}
                  label={'Address'}
                  notModal={true}
                />
              </div>
              <div className="w-full px-10 flex justify-center items-center">
                <Tag color="green">Pickup</Tag>
              </div>
              <div className="w-[300px] px-10  ">
                <div className=" ml-[20px]">
                  <PopConfirm
                    title="Delete"
                    description="Are you sure to delete this task?"
                    placement="left"
                    onClick={() => {
                      formik.setValues({
                        ...formik.values,
                        pickupDate: '',
                        pickupTime: '',
                        pickupAddress: '',
                      });
                    }}
                    icon={
                      <div className="flex items-center justify-center">
                        <QuestionCircleOutlined
                          style={{
                            color: 'red',
                            marginTop: '5px',
                          }}
                        />
                      </div>
                    }
                  >
                    <MdDeleteOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </PopConfirm>
                </div>
              </div>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {innerFormik?.values?.stop?.map((item, index) => (
                      <Draggable
                        key={index}
                        draggableId={`draggable-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="flex items-center w-full px-[10px] text-[10px] border-b-[1px] py-[10px]  border-[#f0f0f0]"
                          >
                            <div className="w-[300px]">
                              <Tag color="#899499">Stop {index + 1}</Tag>
                            </div>
                            <div className="w-full px-10">
                              <div className="wrapper-fields mt-[10px]">
                                <InputFieldCustomForm
                                  label="Date"
                                  id={`stop.${index}.stopDate`}
                                  value={
                                    innerFormik.values.stop[index].stopDate
                                  }
                                  error={
                                    innerFormik.touched.stop?.[index]
                                      ?.stopDate &&
                                    Boolean(
                                      innerFormik.errors.stop?.[index]?.stopDate
                                    )
                                  }
                                  errorMessage={
                                    innerFormik.errors.stop?.[index]?.stopDate
                                  }
                                  required={true}
                                  notModal={true}
                                >
                                  <DatePicker
                                    id={`stop.${index}.stopDate`}
                                    name={`stop.${index}.stopDate`}
                                    prefix={<></>}
                                    placeholder=""
                                    minDate={''}
                                    value={
                                      innerFormik.values.stop[index].stopDate
                                    }
                                    autoComplete={`off`}
                                    onChange={(value) => {
                                      innerFormik.setFieldValue(
                                        `stop.${index}.stopDate`,
                                        value
                                      );
                                      innerFormik.setFieldTouched(
                                        `stop.${index}.stopDate`,
                                        true,
                                        false
                                      );
                                    }}
                                    onBlur={innerFormik.handleBlur}
                                    style={{
                                      border: !(
                                        innerFormik.touched.stop?.[index]
                                          ?.stopDate &&
                                        Boolean(
                                          innerFormik.errors.stop?.[index]
                                            ?.stopDate
                                        )
                                      )
                                        ? themeMode === 'light'
                                          ? `1px solid ${themeInputColor.lightBorderColor}`
                                          : `1px solid ${themeInputColor.darkBorderColor}`
                                        : `1px solid ${themeInputColor.errorBorderColor}`,

                                      borderRadius: '5px',
                                      caretColor:
                                        themeMode === 'light'
                                          ? themeInputColor.lightCaretColor
                                          : themeInputColor.darkCaretColor,
                                      color:
                                        themeMode === 'light'
                                          ? themeInputColor.lightTextColor
                                          : themeInputColor.darkTextColor,
                                    }}
                                  />
                                </InputFieldCustomForm>
                              </div>
                            </div>
                            <div className="w-full px-10">
                              <div className="wrapper-fields mt-[10px]">
                                <InputFieldCustomForm
                                  label="Time"
                                  id={`stop.${index}.stopTime`}
                                  value={
                                    innerFormik.values.stop[index].stopTime
                                  }
                                  error={
                                    innerFormik.touched.stop?.[index]
                                      ?.stopTime &&
                                    Boolean(
                                      innerFormik.errors.stop?.[index]?.stopTime
                                    )
                                  }
                                  errorMessage={
                                    innerFormik.errors.stop?.[index]?.stopTime
                                  }
                                  required={true}
                                  notModal={true}
                                >
                                  <TimePicker
                                    id={`stop.${index}.stopTime`}
                                    name={`stop.${index}.stopTime`}
                                    prefix={<></>}
                                    placeholder=""
                                    suffixIcon={<ClockCircleOutlined />}
                                    value={
                                      innerFormik.values.stop[index].stopTime
                                    }
                                    autoComplete={`off`}
                                    onChange={(value) => {
                                      innerFormik.setFieldValue(
                                        `stop.${index}.stopTime`,
                                        value
                                      );
                                      innerFormik.setFieldTouched(
                                        `stop.${index}.stopTime`,
                                        true,
                                        false
                                      );
                                    }}
                                    onBlur={innerFormik.handleBlur}
                                    style={{
                                      border: !(
                                        innerFormik.touched.stop?.[index]
                                          ?.stopTime &&
                                        Boolean(
                                          innerFormik.errors.stop?.[index]
                                            ?.stopTime
                                        )
                                      )
                                        ? themeMode === 'light'
                                          ? `1px solid ${themeInputColor.lightBorderColor}`
                                          : `1px solid ${themeInputColor.darkBorderColor}`
                                        : `1px solid ${themeInputColor.errorBorderColor}`,

                                      borderRadius: '5px',
                                      caretColor:
                                        themeMode === 'light'
                                          ? themeInputColor.lightCaretColor
                                          : themeInputColor.darkCaretColor,
                                      color:
                                        themeMode === 'light'
                                          ? themeInputColor.lightTextColor
                                          : themeInputColor.darkTextColor,
                                    }}
                                  />
                                </InputFieldCustomForm>
                              </div>
                            </div>
                            <div className="w-full px-10">
                              <div className="wrapper-fields mt-[10px]">
                                <InputFieldCustomForm
                                  label="Address"
                                  id={`stop.${index}.stopAddress`}
                                  value={
                                    innerFormik.values.stop[index].stopAddress
                                  }
                                  error={
                                    innerFormik.touched.stop?.[index]
                                      ?.stopAddress &&
                                    Boolean(
                                      innerFormik.errors.stop?.[index]
                                        ?.stopAddress
                                    )
                                  }
                                  errorMessage={
                                    innerFormik.errors.stop?.[index]
                                      ?.stopAddress
                                  }
                                  required={true}
                                  notModal={true}
                                >
                                  <Input
                                    id={`stop.${index}.stopAddress`}
                                    name={`stop.${index}.stopAddress`}
                                    value={
                                      innerFormik.values.stop[index]
                                        ?.stopAddress || ''
                                    } // Ensure it has a default value
                                    autoComplete="off"
                                    onChange={(e) => {
                                      innerFormik.setFieldValue(
                                        `stop.${index}.stopAddress`,
                                        e.target.value
                                      );
                                      innerFormik.setFieldTouched(
                                        `stop.${index}.stopAddress`,
                                        true,
                                        false
                                      );
                                    }}
                                    onBlur={innerFormik.handleBlur}
                                    style={{
                                      border:
                                        innerFormik.touched.stop?.[index]
                                          ?.stopAddress &&
                                        Boolean(
                                          innerFormik.errors.stop?.[index]
                                            ?.stopAddress
                                        )
                                          ? `1px solid ${themeInputColor.errorBorderColor}`
                                          : themeMode === 'light'
                                          ? `1px solid ${themeInputColor.lightBorderColor}`
                                          : `1px solid ${themeInputColor.darkBorderColor}`,
                                      borderRadius: '5px',
                                      caretColor:
                                        themeMode === 'light'
                                          ? themeInputColor.lightCaretColor
                                          : themeInputColor.darkCaretColor,
                                      color:
                                        themeMode === 'light'
                                          ? themeInputColor.lightTextColor
                                          : themeInputColor.darkTextColor,
                                      width: '90%',
                                      height: '22px',
                                    }}
                                  />
                                </InputFieldCustomForm>
                              </div>
                            </div>
                            <div className="w-full px-10">
                              <div className="wrapper-fields mt-[10px]">
                                <InputFieldCustomForm
                                  label="Type"
                                  id={`stop.${index}.stopType`}
                                  value={
                                    innerFormik.values.stop[index].stopType
                                  }
                                  error={
                                    innerFormik.touched.stop?.[index]
                                      ?.stopType &&
                                    Boolean(
                                      innerFormik.errors.stop?.[index]?.stopType
                                    )
                                  }
                                  errorMessage={
                                    innerFormik.errors.stop?.[index]?.stopType
                                  }
                                  required={true}
                                  notModal={true}
                                >
                                  <Select
                                    id={`stop.${index}.stopType`}
                                    name={`stop.${index}.stopType`}
                                    prefix={<></>}
                                    mode="single"
                                    showSearch
                                    optionFilterProp="label"
                                    value={
                                      innerFormik.values.stop[index].stopType
                                    }
                                    autoComplete={`new-stopType`}
                                    onChange={(value) => {
                                      innerFormik.setFieldValue(
                                        `stop.${index}.stopType`,
                                        value
                                      );
                                      innerFormik.setFieldTouched(
                                        `stop.${index}.stopType`,
                                        true,
                                        false
                                      );
                                    }}
                                    options={[
                                      { value: 'pickup', label: 'Pickup' },
                                      { value: 'delivery', label: 'Delivery' },
                                    ]}
                                    onBlur={innerFormik.handleBlur}
                                    suffixIcon={
                                      false ? (
                                        <Loading size="small" />
                                      ) : (
                                        <SlArrowDown
                                          className="w-[12px] h-[12px]"
                                          style={{
                                            color: !(
                                              innerFormik.touched.stop?.[index]
                                                ?.stopType &&
                                              Boolean(
                                                innerFormik.errors.stop?.[index]
                                                  ?.stopType
                                              )
                                            )
                                              ? themeMode === 'light'
                                                ? colorTheme
                                                : themeInputColor.darkIconColor
                                              : themeInputColor.errorIconColor,
                                          }}
                                        />
                                      )
                                    }
                                    style={{
                                      border: !(
                                        innerFormik.touched.stop?.[index]
                                          ?.stopDestination &&
                                        Boolean(
                                          innerFormik.errors.stop?.[index]
                                            ?.stopDestination
                                        )
                                      )
                                        ? themeMode === 'light'
                                          ? `1px solid ${themeInputColor.lightBorderColor}`
                                          : `1px solid ${themeInputColor.darkBorderColor}`
                                        : `1px solid ${themeInputColor.errorBorderColor}`,
                                      borderRadius: '5px',
                                      caretColor:
                                        themeMode === 'light'
                                          ? themeInputColor.lightCaretColor
                                          : themeInputColor.darkCaretColor,
                                      color:
                                        themeMode === 'light'
                                          ? themeInputColor.lightTextColor
                                          : themeInputColor.darkTextColor,
                                    }}
                                  />
                                </InputFieldCustomForm>
                              </div>
                            </div>
                            <div className="w-[300px] px-10">
                              <div className=" ml-[20px]">
                                <PopConfirm
                                  title="Delete"
                                  description="Are you sure to delete this task?"
                                  placement="left"
                                  onClick={() => handleRemove(index)}
                                  icon={
                                    <div className="flex items-center justify-center">
                                      <QuestionCircleOutlined
                                        style={{
                                          color: 'red',
                                          marginTop: '5px',
                                        }}
                                      />
                                    </div>
                                  }
                                >
                                  <MdDeleteOutline className="w-[15px] h-[15px] cursor-pointer" />
                                </PopConfirm>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {/* <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {formik?.values?.stop?.map((_, index) => (
                      <Draggable
                        key={index}
                        draggableId={`draggable-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="flex items-center w-full px-[10px] text-[10px] border-b-[1px] border-[#f0f0f0]"
                          >
                            <div className="w-[300px]">
                              <Tag color="#899499">Stop {index + 1}</Tag>
                            </div>
                            <div className="w-full px-10">
                              <div className="wrapper-fields mt-[10px]">
                                <InputFieldCustomForm
                                  label="Date"
                                  id={`stop.${index}.stopDate`}
                                  value={formik.values.stop[index].stopDate}
                                  error={
                                    formik.touched.stop?.[index]?.stopDate &&
                                    Boolean(
                                      formik.errors.stop?.[index]?.stopDate
                                    )
                                  }
                                  errorMessage={
                                    formik.errors.stop?.[index]?.stopDate
                                  }
                                  required={true}
                                  notModal={true}
                                >
                                  <DatePicker
                                    id={`stop.${index}.stopDate`}
                                    name={`stop.${index}.stopDate`}
                                    prefix={<></>}
                                    placeholder=""
                                    minDate={''}
                                    value={formik.values.stop[index].stopDate}
                                    autoComplete={`off`}
                                    onChange={(value) => {
                                      formik.setFieldValue(
                                        `stop.${index}.stopDate`,
                                        value
                                      );
                                      formik.setFieldTouched(
                                        `stop.${index}.stopDate`,
                                        true,
                                        false
                                      );
                                    }}
                                    onBlur={formik.handleBlur}
                                    style={{
                                      border: !(
                                        formik.touched.stop?.[index]
                                          ?.stopDate &&
                                        Boolean(
                                          formik.errors.stop?.[index]?.stopDate
                                        )
                                      )
                                        ? themeMode === 'light'
                                          ? `1px solid ${themeInputColor.lightBorderColor}`
                                          : `1px solid ${themeInputColor.darkBorderColor}`
                                        : `1px solid ${themeInputColor.errorBorderColor}`,

                                      borderRadius: '5px',
                                      caretColor:
                                        themeMode === 'light'
                                          ? themeInputColor.lightCaretColor
                                          : themeInputColor.darkCaretColor,
                                      color:
                                        themeMode === 'light'
                                          ? themeInputColor.lightTextColor
                                          : themeInputColor.darkTextColor,
                                    }}
                                  />
                                </InputFieldCustomForm>
                              </div>
                            </div>
                            <div className="w-full px-10">
                              <div className="wrapper-fields mt-[10px]">
                                <InputFieldCustomForm
                                  label="Time"
                                  id={`stop.${index}.stopTime`}
                                  value={formik.values.stop[index].stopTime}
                                  error={
                                    formik.touched.stop?.[index]?.stopTime &&
                                    Boolean(
                                      formik.errors.stop?.[index]?.stopTime
                                    )
                                  }
                                  errorMessage={
                                    formik.errors.stop?.[index]?.stopTime
                                  }
                                  required={true}
                                  notModal={true}
                                >
                                  <TimePicker
                                    id={`stop.${index}.stopTime`}
                                    name={`stop.${index}.stopTime`}
                                    prefix={<></>}
                                    placeholder=""
                                    suffixIcon={<ClockCircleOutlined />}
                                    value={formik.values.stop[index].stopTime}
                                    autoComplete={`off`}
                                    onChange={(value) => {
                                      formik.setFieldValue(
                                        `stop.${index}.stopTime`,
                                        value
                                      );
                                      formik.setFieldTouched(
                                        `stop.${index}.stopTime`,
                                        true,
                                        false
                                      );
                                    }}
                                    onBlur={formik.handleBlur}
                                    style={{
                                      border: !(
                                        formik.touched.stop?.[index]
                                          ?.stopTime &&
                                        Boolean(
                                          formik.errors.stop?.[index]?.stopTime
                                        )
                                      )
                                        ? themeMode === 'light'
                                          ? `1px solid ${themeInputColor.lightBorderColor}`
                                          : `1px solid ${themeInputColor.darkBorderColor}`
                                        : `1px solid ${themeInputColor.errorBorderColor}`,

                                      borderRadius: '5px',
                                      caretColor:
                                        themeMode === 'light'
                                          ? themeInputColor.lightCaretColor
                                          : themeInputColor.darkCaretColor,
                                      color:
                                        themeMode === 'light'
                                          ? themeInputColor.lightTextColor
                                          : themeInputColor.darkTextColor,
                                    }}
                                  />
                                </InputFieldCustomForm>
                              </div>
                            </div>
                            <div className="w-full px-10">
                              <div className="wrapper-fields mt-[10px]">
                                <InputFieldCustomForm
                                  label="Address"
                                  id={`stop.${index}.stopAddress`}
                                  value={formik.values.stop[index].stopAddress}
                                  error={
                                    formik.touched.stop?.[index]?.stopAddress &&
                                    Boolean(
                                      formik.errors.stop?.[index]?.stopAddress
                                    )
                                  }
                                  errorMessage={
                                    formik.errors.stop?.[index]?.stopAddress
                                  }
                                  required={true}
                                  notModal={true}
                                >
                                  <Input
                                    id={`stop.${index}.stopAddress`}
                                    name={`stop.${index}.stopAddress`}
                                    value={
                                      formik.values.stop[index]?.stopAddress ||
                                      ''
                                    } // Ensure it has a default value
                                    autoComplete="off"
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        `stop.${index}.stopAddress`,
                                        e.target.value
                                      );
                                      formik.setFieldTouched(
                                        `stop.${index}.stopAddress`,
                                        true,
                                        false
                                      );
                                    }}
                                    onBlur={formik.handleBlur}
                                    style={{
                                      border:
                                        formik.touched.stop?.[index]
                                          ?.stopAddress &&
                                        Boolean(
                                          formik.errors.stop?.[index]
                                            ?.stopAddress
                                        )
                                          ? `1px solid ${themeInputColor.errorBorderColor}`
                                          : themeMode === 'light'
                                          ? `1px solid ${themeInputColor.lightBorderColor}`
                                          : `1px solid ${themeInputColor.darkBorderColor}`,
                                      borderRadius: '5px',
                                      caretColor:
                                        themeMode === 'light'
                                          ? themeInputColor.lightCaretColor
                                          : themeInputColor.darkCaretColor,
                                      color:
                                        themeMode === 'light'
                                          ? themeInputColor.lightTextColor
                                          : themeInputColor.darkTextColor,
                                      width: '90%',
                                      height: '22px',
                                    }}
                                  />
                                </InputFieldCustomForm>
                              </div>
                            </div>
                            <div className="w-full px-10">
                              <div className="wrapper-fields mt-[10px]">
                                <InputFieldCustomForm
                                  label="Type"
                                  id={`stop.${index}.stopType`}
                                  value={formik.values.stop[index].stopType}
                                  error={
                                    formik.touched.stop?.[index]?.stopType &&
                                    Boolean(
                                      formik.errors.stop?.[index]?.stopType
                                    )
                                  }
                                  errorMessage={
                                    formik.errors.stop?.[index]?.stopType
                                  }
                                  required={true}
                                  notModal={true}
                                >
                                  <Select
                                    id={`stop.${index}.stopType`}
                                    name={`stop.${index}.stopType`}
                                    prefix={<></>}
                                    mode="single"
                                    showSearch
                                    optionFilterProp="label"
                                    value={formik.values.stop[index].stopType}
                                    autoComplete={`new-stopType`}
                                    onChange={(value) => {
                                      formik.setFieldValue(
                                        `stop.${index}.stopType`,
                                        value
                                      );
                                      formik.setFieldTouched(
                                        `stop.${index}.stopType`,
                                        true,
                                        false
                                      );
                                    }}
                                    options={[
                                      { value: 'pickup', label: 'Pickup' },
                                      { value: 'delivery', label: 'Delivery' },
                                    ]}
                                    onBlur={formik.handleBlur}
                                    suffixIcon={
                                      false ? (
                                        <Loading size="small" />
                                      ) : (
                                        <SlArrowDown
                                          className="w-[12px] h-[12px]"
                                          style={{
                                            color: !(
                                              formik.touched.stop?.[index]
                                                ?.stopType &&
                                              Boolean(
                                                formik.errors.stop?.[index]
                                                  ?.stopType
                                              )
                                            )
                                              ? themeMode === 'light'
                                                ? colorTheme
                                                : themeInputColor.darkIconColor
                                              : themeInputColor.errorIconColor,
                                          }}
                                        />
                                      )
                                    }
                                    style={{
                                      border: !(
                                        formik.touched.stop?.[index]
                                          ?.stopDestination &&
                                        Boolean(
                                          formik.errors.stop?.[index]
                                            ?.stopDestination
                                        )
                                      )
                                        ? themeMode === 'light'
                                          ? `1px solid ${themeInputColor.lightBorderColor}`
                                          : `1px solid ${themeInputColor.darkBorderColor}`
                                        : `1px solid ${themeInputColor.errorBorderColor}`,
                                      borderRadius: '5px',
                                      caretColor:
                                        themeMode === 'light'
                                          ? themeInputColor.lightCaretColor
                                          : themeInputColor.darkCaretColor,
                                      color:
                                        themeMode === 'light'
                                          ? themeInputColor.lightTextColor
                                          : themeInputColor.darkTextColor,
                                    }}
                                  />
                                </InputFieldCustomForm>
                              </div>
                            </div>
                            <div className="w-[300px] px-10">
                              <PopConfirm
                                title="Delete"
                                description="Are you sure to delete this task?"
                                placement="left"
                                onClick={() => handleRemove(index)}
                                icon={
                                  <div className="flex items-center justify-center">
                                    <QuestionCircleOutlined
                                      style={{
                                        color: 'red',
                                        marginTop: '5px',
                                      }}
                                    />
                                  </div>
                                }
                              >
                                <MdDeleteOutline className="w-[15px] h-[15px] cursor-pointer" />
                              </PopConfirm>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext> */}
            <div className="flex items-center w-full px-[10px] py-[10px]  text-[10px] border-b-[1px] border-[#f0f0f0]">
              <div className="w-[300px]">
                <Tag color="#ff0000">Delivery</Tag>
              </div>
              <div className="w-full px-10">
                <DatePickerField
                  formik={formik}
                  id={'deliveryDate'}
                  label={'Date'}
                  notModal={true}
                />
              </div>
              <div className="w-full px-10">
                <TimePickerField
                  formik={formik}
                  id={'deliveryTime'}
                  label={'Time'}
                  notModal={true}
                />
              </div>
              <div className="w-full px-10">
                <TextField
                  formik={formik}
                  id={'deliveryAddress'}
                  label={'Address'}
                  notModal={true}
                />
              </div>
              {/* <div className="w-full px-10">
                <div className="wrapper-fields ">
                  <TextArea
                    id="endNotes"
                    name="endNotes"
                    rows={1}
                    placeholder="Notes"
                    required={true}
                    value={formik.values.endNotes}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.endNotes && Boolean(formik.errors.endNotes)
                    }
                    errorMessage={
                      formik.touched.endNotes && formik.errors.endNotes
                        ? formik.errors.endNotes
                        : null
                    }
                  />
                </div>
              </div> */}
              <div className="w-full px-10 flex justify-center items-center">
                <Tag color="red">Delivery</Tag>
              </div>
              <div className="w-[300px] px-10">
                <div className=" ml-[20px]">
                  {' '}
                  <PopConfirm
                    title="Delete"
                    description="Are you sure to delete this task?"
                    placement="left"
                    onClick={() => {
                      formik.setValues({
                        ...formik.values,
                        deliveryDate: '',
                        deliveryTime: '',
                        deliveryAddress: '',
                      });
                    }}
                    icon={
                      <div className="flex items-center justify-center">
                        <QuestionCircleOutlined
                          style={{
                            color: 'red',
                            marginTop: '5px',
                          }}
                        />
                      </div>
                    }
                  >
                    <MdDeleteOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </PopConfirm>
                </div>
              </div>
            </div>
          </div>
          {isMapModalOpen && (
            <MapModal
              isMapModalOpen={isMapModalOpen}
              setMapModalOpen={setMapModalOpen}
              formik={formik}
              innerFormik={innerFormik}
            />
          )}
        </form>
      </Spin>
    </>
  );
};

export default EditLoad;
