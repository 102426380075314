import { Breadcrumb, Select, Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import DatePickerField from 'components/CustomFields/DatePickerField';
import SelectField from 'components/CustomFields/SelectField';
import Checkbox from 'components/InputFields/Checkbox';
import LabelGrid from 'components/LabelGrid';
import ToastNotification from 'components/ToastNotification';
import { add_all_devices } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useFormik } from 'formik';
import TextField from 'components/CustomFields/TextField';
import { usePermissionVariables } from 'Hooks/HasPermission';
import { BiDollarCircle } from 'react-icons/bi';
import Layout from 'layout/AdminLayout';
import { useState } from 'react';
import { BiSave } from 'react-icons/bi';
import { FaPlus } from 'react-icons/fa6';
import { GoHome } from 'react-icons/go';
import { HiOutlineShoppingCart } from 'react-icons/hi2';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useValidationSchema from 'validation/useValidationSchema';
import Switch from 'components/Switch';
import TextArea from 'components/InputFields/TextArea';
const { Title } = Typography;

const AddRateCardManagement = () => {
  const { Logs_View, Status_View, Configurations_View, General_View } =
    usePermissionVariables();
  const { postRequest, useGetRequest, deleteRequest } = useApiClient();
  const [checked, setChecked] = useState(false);
  const { addDeviceModalSchema } = useValidationSchema();
  const { data, error, isLoading, refetch } = useGetRequest(
    '/api/GetDeviceModels'
  );
  const deviceModelOptions = data?.data?.map((item) => {
    return {
      value: item.deviceModelId,
      label: item.name,
    };
  });
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  const formik = useFormik({
    initialValues: {
      rateCardName: '',
      serviceType: null,
      baseRate: '',
      pricePerDistanceUnit: '',
      pricePerWeightUnit: '',
      fuelSurcharge: '',
      timeOfDay: '',
      vehicleTypeSurcharge: '',
      priorityDelivery: '',
      taxPercentage: '',
      validFrom: '',
      validTo: '',
      comments: '',
    },
    validationSchema: addDeviceModalSchema,
    onSubmit: ({
      deviceName,
      deviceModel,
      IMEI_No,
      serialNumber,
      simCardNumber,
      simProvider,
      status,
      isActive,
    }) => {
      setLoading(true);
      const paylaod = {
        deviceIdentifier: 'string',
        deviceName: deviceName,
        deviceModelID: deviceModel,
        serialNumber: serialNumber,
        imei: IMEI_No,
        simCardNumber: simCardNumber,
        simProvider: simProvider,
        status: status,
        isActive: isActive,
      };
      postRequest(add_all_devices, paylaod)
        .then((res) => {
          if (res?.success) {
            formik.resetForm();
            ToastNotification('success', res.message, themeMode);
            navigate('/device-management');
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <Layout>
      <>
        <div className="mb-[30px] ">
          <Title
            level={4}
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
              fontFamily: 'inter-bold',
            }}
          >
            Add Rate Card
          </Title>
          <div className="flex justify-between items-center mb-20">
            <div>
              <Breadcrumb
                separator="/"
                items={[
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <GoHome />
                          <span
                            className=" cursor-pointer font-reg text-[13px]"
                            onClick={() => {
                              navigate('/dashboard');
                            }}
                          >
                            Dashboard
                          </span>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <BiDollarCircle />
                          <span
                            className=" cursor-pointer font-reg text-[13px]"
                            onClick={() => {
                              navigate('/orders');
                            }}
                          >
                            Rate Card
                          </span>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <FaPlus
                            style={{
                              color: themeMode === 'light' ? colorTheme : '',
                            }}
                          />
                          <span
                            className=" font-bold text-[13px]"
                            style={{
                              color: themeMode === 'light' ? colorTheme : '',
                            }}
                          >
                            Add Rate Card
                          </span>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="">
          <form onSubmit={formik.handleSubmit}>
            <LabelGrid
              label=" Add Rate Card"
              className=" rounded-[5px] px-20 py-20"
            >
              <div className="grid grid-cols-12 sm:gap-x-[20px]  gap-y-[25px] sm:gap-y-0 ">
                <div className="  col-span-12 md:col-span-3">
                  <TextField
                    formik={formik}
                    id={'rateCardName'}
                    label={'Rate Card Name'}
                    maxLength={30}
                    notModal={true}
                  />
                </div>
                <div className="  col-span-12 md:col-span-3">
                  <SelectField
                    formik={formik}
                    id={'serviceType'}
                    label={'Service Type'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={deviceModelOptions}
                    notModal={true}
                  />
                </div>
                <div className="  col-span-12 md:col-span-3">
                  <TextField
                    formik={formik}
                    id={'baseRate'}
                    label={'Base Rate'}
                    type="number"
                    maxLength={30}
                    notModal={true}
                  />
                </div>
                <div className="  col-span-12 md:col-span-3">
                  <TextField
                    formik={formik}
                    id={'pricePerDistanceUnit'}
                    label={'Price Per km'}
                    maxLength={30}
                    notModal={true}
                  />
                </div>
                <div className="  col-span-12 md:col-span-3">
                  <TextField
                    formik={formik}
                    id={'pricePerWeightUnit'}
                    label={'Price Per kg'}
                    maxLength={30}
                    notModal={true}
                  />
                </div>
              </div>
              <div className=" flex items-center space-x-3 my-20">
                <div className=" text-[13px]">Additional Pricing Factors:</div>
                <div>
                  <Switch
                    checked={checked}
                    onChange={() => {
                      setChecked((prev) => !prev);
                    }}
                  />
                </div>
              </div>
              {checked && (
                <div className="grid grid-cols-12 sm:gap-x-[20px]  gap-y-[25px] sm:gap-y-0 ">
                  <div className="  col-span-12 md:col-span-3">
                    <TextField
                      formik={formik}
                      id={'fuelSurcharge'}
                      label={'Fuel Surcharge'}
                      maxLength={30}
                      notModal={true}
                    />
                  </div>
                  <div className="  col-span-12 md:col-span-3">
                    <TextField
                      formik={formik}
                      id={'timeOfDay'}
                      label={'Time Of Day'}
                      maxLength={30}
                      notModal={true}
                    />
                  </div>
                  <div className="  col-span-12 md:col-span-3">
                    <TextField
                      formik={formik}
                      id={'vehicleTypeSurcharge'}
                      label={'Vehicle Type Surcharge'}
                      maxLength={30}
                      notModal={true}
                    />
                  </div>
                  <div className="  col-span-12 md:col-span-3">
                    <TextField
                      formik={formik}
                      id={'priorityDelivery'}
                      label={'Priority Delivery'}
                      maxLength={30}
                      notModal={true}
                    />
                  </div>
                  <div className="  col-span-12 md:col-span-3">
                    <TextField
                      formik={formik}
                      id={'taxPercentage'}
                      label={'Tax Percentage'}
                      maxLength={30}
                      notModal={true}
                    />
                  </div>
                  <div className="col-span-12 my-10">
                    <TextArea
                      id="comments"
                      rows={4}
                      required={true}
                      maxLength={250}
                      name="comments"
                      placeholder="Comments"
                      value={formik.values.comments}
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.setFieldTouched('comments', true, false);
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.comments &&
                        Boolean(formik.errors.comments)
                      }
                      errorMessage={
                        formik.touched.comments && formik.errors.comments
                          ? formik.errors.comments
                          : null
                      }
                    />
                  </div>
                </div>
              )}
              <div className="flex justify-end mt-[10px]">
                <PrimaryButton
                  title="Save"
                  icon={<BiSave className=" w-[17px] h-[17px]" />}
                  htmlType="submit"
                  loading={loading}
                />
              </div>
            </LabelGrid>
          </form>
        </div>
      </>
    </Layout>
  );
};
export default AddRateCardManagement;
