import { GoogleMap, Polyline, useLoadScript } from '@react-google-maps/api';
import { Divider, Empty, Skeleton, Typography } from 'antd';
import SearchField from 'components/InputFields/SearchField';
import Status from 'components/Status';
import useAxiosClientTMS from 'config/useAxiosClientTMS';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Layout from 'layout/AdminLayout';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FaLocationDot } from 'react-icons/fa6';
import { IoStop } from 'react-icons/io5';
import { TbRefresh } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { mapStyles } from 'utils/constant';
import { convertDateFormat } from 'utils/helper';
import { GET_FLEET_VIEW_KEY } from 'utils/queryKey';
import FleetFilter from './components/FleetFilter';

// Extend dayjs with the necessary plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

const { Text } = Typography;
const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: 'calc(100vh - 195px)',
  borderRadius: '5px',
};
const center = {
  lat: 31.468854, // default latitude
  lng: 74.451038, // default longitude
};
const FleetView = () => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const [isRotating, setIsRotating] = useState(false);
  const navigate = useNavigate();
  const mapRef = useRef();
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [filter, setFilter] = useState([]);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });
  const { useGetRequest } = useAxiosClientTMS();
  const {
    data: fleetData,
    isLoading: fleetLoading,
    refetch,
  } = useGetRequest(
    `/api/GetFleetView?pagenumber=${pageNumber}&filter=${filter?.join(
      ','
    )}&pagesize=100&searchquery=${search}`,
    {},
    GET_FLEET_VIEW_KEY
  );
  const mapRefMarker = useRef();

  const onLoadMarker = useCallback((marker) => {
    mapRefMarker.current = marker;
  }, []);
  const [path, setPath] = useState([]);
  const [pathLine, setPathLine] = useState(
    'gunwFblubMuAvCqMf@{O}KkXQ}T|KyRhtCsRbqAJza@dQhMt_AjUzq@oEvk@|dAlZdj@xRnj@hYhZtZtb@iA~y@qW~e@a^|dBceAt`EfiAha@pgDp~Bf_Azq@r`@|h@j^jq@hy@|b@|eCrqAphGdmDj|Bh}B`~@bxBxQdiBdQ~rAzq@~}Al{@jaC`u@~{AlcEbqElfBveA`bF|qCn{A`}@ldCps@tkP~nFpyGpvBtkA~s@hgAzwB`nDhdGzxC`vFldDttFzrBlpCd_Dp`E|k@pl@`b@rp@|O||EiPnrAww@~tByaApfEe^zmBaV`_CcNzpAvU|a@v~@zFvi@tY~XleAzq@xjAhoBrdDjl@rjBrl@diAbmArsBfNrp@pg@z]lp@baBt_@vSpThe@x_BbdFpe@feAru@tg@d]`Tl^rp@|e@n}Ar}AzrDlb@nOIjq@iBdwAIGnc@nFdDsNuIlBkZiFbAoQlAwh@_Bk_@lNue@~cC`YlhCfU|s@~IbW`}AlC`}AbMx`Ajd@fw@xVrc@bBdd@{`@v_A_Jpt@zMzo@bu@xi@|]dq@xDtuDdn@z{DwMtz@`UxbAwAlt@xi@~|@ltA|rEbgAroEhpBreEns@x|@p]|_@Aht@zYjz@tc@v[rqAj|AxZnqApGpy@np@pqAhX`w@h{@h~vAvy@zx@xXrmAtwAd|@z`BfzAbxD~rArqF~u@thApg@hlA`j@pv@lXh~@bYx}BbPdrD`p@~wD_VdmEaW|wC~Sj_BZtgBc@rlB~EfgBcTr|@pBbn@nr@~|Bvv@|pB|QnzBv|@xlB`k@dkBjl@nqC`[teBz|@jjBvAf`Brt@v~BtFtaBhw@vhAbaC|`Dtw@vg@vf@rq@xe@hnAvpAvuA~k@z`Afb@dsAj|@zy@b\t|@nYdgAle@r`A~m@vrAra@~wAn}AliExQpu@r@xvBdRdfAneBl{CjVps@|k@za@teBb}BnoAzqA|jAnzAvh@xqAfw@bhBneA~eCruEjyDrqB`iAd_BeCts@vi@b|@px@p{Az~@hIndDzGns@n_@nb@t`@tf@aGncA}W|zA_@eJb}@bIhi@loAdg@flBdrAreCvz@~eAbbAj{@zeB~aBpzAplA`aBnq@neAj}@xrBzaDpoB|`CbzApxAhrChuAp_FreBpe@pQbc@lz@h[paAvi@hi@jxAnm@z{@za@piAxZnm@vIbh@l_@b]bu@|{@`~@bMhX}@ls@xGhv@h@bq@MxyAb^d_Bvm@xfCpJrm@wLtf@sKtg@'
  );
  useEffect(() => {
    const latlng =
      window.google?.maps?.geometry?.encoding?.decodePath(pathLine);
    console.log('Decoded Lat/Lng Points:', latlng);

    setPath(latlng);
  }, [isLoaded, pathLine]);

  const onLoad = useCallback((map) => {
    mapRef.current = map;
    // if (!isEmpty(trackingData)) {
    //   const bounds = new window.google.maps.LatLngBounds();
    //   trackingData?.forEach((item) => {
    //     bounds.extend({
    //       lat: item?.lat,
    //       lng: item?.lng,
    //     });
    //   });
    //   mapRef?.current?.fitBounds(bounds);
    // } else {
    const bounds = new window.google.maps.LatLngBounds();
    if (center) {
      bounds.extend({
        lat: center?.lat,
        lng: center?.lng,
      });
    }

    mapRef?.current?.fitBounds(bounds);
    // }
  }, []);

  const setTimeFalse = () => {
    setTimeout(() => {
      setIsRotating(false);
    }, 1000);
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  return (
    <Layout>
      <div className="lg:mx-[-30px]">
        <div
          className={`flex max-md:flex-wrap md:h-auto max-md:h-[500px] justify-between`}
        >
          <div className={`pr-10 max-md:pt-4 md:w-[450px] w-full `}>
            <div className="flex justify-between items-center">
              <SearchField
                htmlFor="search"
                id="myInputID"
                name="search"
                placeholder={'Search'}
                value={debouncedSearch}
                setDebouncedSearch={setDebouncedSearch}
                setPageNumber={setPageNumber}
                searchLoading={searchLoading}
                setSearchLoading={setSearchLoading}
                refetch={refetch}
                debouncedSearch={debouncedSearch}
                setSearch={setSearch}
                search={search}
                width="w-[250px] max-sm:w-full"
              />
              <div className=" flex justify-between items-center mx-10">
                <div className="mx-[5px]">
                  <FleetFilter setFilter={setFilter} filter={filter} />
                </div>
                <div
                  className={`mx-[5px] cursor-pointer ${
                    isRotating ? 'rotate' : ''
                  }`}
                  onClick={() => {
                    setIsRotating(true);
                    refetch();
                    setTimeFalse();
                  }}
                >
                  <TbRefresh
                    color={themeMode === 'light' ? colorTheme : '#9FA8DA'}
                  />
                </div>
              </div>
            </div>
            <Divider className="my-8" />
            {fleetLoading ? (
              <>
                <Skeleton />
                <Divider />
                <Skeleton />
                <Divider />
                <Skeleton />
              </>
            ) : (
              <div
                className="overflow-y-auto show-scroll"
                style={{ maxHeight: 'calc(100vh - 195px)' }}
              >
                {!isEmpty(fleetData?.data) ? (
                  fleetData?.data?.map((item, index) => {
                    return (
                      <div
                        className={`cursor-pointer border-solid border-[1px] border-r-0 border-t-0 border-l-0 border-[#d9d9d9] px-8 py-20 !text-[10px] hover:bg-[#f7f7fa] mr-[10px]`}
                        key={index}
                        onClick={() => {
                          navigate(`/fleet-view-detail/${item?.loadId}`);
                        }}
                      >
                        <div className="font-med grid gap-15">
                          <div className="flex justify-between">
                            <div className="flex items-center gap-5">
                              <div>
                                <IoStop color="#899499" size={16} />
                              </div>
                              <div>
                                <Text className="text-[11px] font-med font-[500]">
                                  {`${item?.driverName}`} (
                                  <span className="text-[#333030] text-[9px]">
                                    {item?.vehicleNumber}
                                  </span>
                                  )
                                </Text>
                              </div>
                            </div>
                            <div>
                              <Text className="text-[11px] font-med font-[500]">
                                <Status
                                  tag={item?.loadStatus}
                                  className="me-0"
                                />
                              </Text>
                            </div>
                          </div>
                          <div className="flex justify-between">
                            <div className="flex items-center gap-7">
                              <div>
                                <FaLocationDot color="#899499" size={14} />
                              </div>
                              <div>
                                <Text className="text-[11px] font-med font-[500]">
                                  {item?.loadPoints[0]?.location}
                                </Text>
                              </div>
                            </div>
                            <div>
                              <Text className="text-[11px] font-med font-[500] whitespace-nowrap">
                                {convertDateFormat(item?.loadDate)}
                              </Text>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </div>
            )}
          </div>
          <div
            className={`max-md:h-[50%] md:h-[100%] md:mx-0 mx-3 max-md:pt-10`}
            style={{ width: '100%', height: '100%' }}
          >
            {isLoaded ? (
              <>
                <div>
                  <GoogleMap
                    zoom={12}
                    mapContainerStyle={{
                      width: '100%',
                      height: 'calc(100vh - 120px)',
                      borderRadius: '10px',
                    }}
                    options={{
                      minZoom: 3,
                      zoomControl: true,
                      zoomControlOptions: {
                        position:
                          window.google.maps.ControlPosition.LEFT_BOTTOM,
                      },
                      mapTypeControl: true,
                      streetViewControl: true,
                      fullscreenControl: false,
                      fullscreenControlOptions: {
                        position:
                          window.google.maps.ControlPosition.RIGHT_BOTTOM,
                      },
                      mapTypeControlOptions: {
                        position: window.google.maps.ControlPosition.RIGHT_TOP,
                      },
                      styles: mapStyles,
                      disableDefaultUI: true,
                    }}
                    onLoad={onLoad}
                  >
                    <Polyline
                      path={path}
                      options={{
                        strokeColor: '#113D4E',
                        strokeWeight: 4,
                        strokeOpacity: 1,
                      }}
                    />
                    {/* <Polyline
                      path={trackingData?.map((item) => ({
                        lat: item?.latitude,
                        lng: item?.longitude,
                      }))}
                      options={{
                        strokeColor: '#113D4E',
                        strokeWeight: 4,
                        strokeOpacity: 1,
                      }}
                    /> */}
                    {/* {!isEmpty(trackingData) &&
                      trackingData?.map((item) => (
                        <Marker
                          onLoad={onLoadMarker}
                          position={{
                            lat: Number(item?.lat),
                            lng: Number(item?.lng),
                          }}
                          icon={{
                            // url: truckIcon,
                            scaledSize: new window.google.maps.Size(30, 30),
                            origin: new window.google.maps.Point(0, 0),
                            anchor: new window.google.maps.Point(25, 25),
                            rotation: 215,
                            fillColor: 'red',
                            fillOpacity: 3,
                            strokeWeight: 0.5,
                            strokeColor: '#fff',
                          }}
                        />
                      ))} */}
                  </GoogleMap>
                </div>
              </>
            ) : (
              <>
                <div className="w-full flex items-center justify-center">
                  Loading
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FleetView;
