import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setClientId,
  setColorTheme,
  setForgetEmail,
  setPermssionsArray,
  setTenantSecretKey,
  setThemeMode,
  setToken,
  setUserName,
} from 'store/slices';

const useApiClient = () => {
  const token = useSelector((state) => state.states.token);
  const tenantSecretKey = useSelector((state) => state.states.tenantSecretKey);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const encryptionKey = CryptoJS.enc.Utf8.parse(
    process.env.HRMS_PAYLOAD_SECRET_KEY
  ); // 32 characters for AES-256
  const iv = CryptoJS.enc.Utf8.parse(process.env.HRMS_IV_KEY); // 16 characters for the IV

  const encryptPayload = (payload) => {
    const payloadString = JSON.stringify(payload);
    const encryptedPayload = CryptoJS.AES.encrypt(
      payloadString,
      encryptionKey,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    ).toString();
    return encryptedPayload;
  };

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
      TenantSecretKey: tenantSecretKey || undefined,
    },
  });

  const axiosAuth = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_AUTH,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  let isSessionExpired = false;

  // Add response interceptor
  axiosClient.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 403)
      ) {
        if (!isSessionExpired) {
          isSessionExpired = true;
          dispatch(setThemeMode('light'));
          dispatch(setPermssionsArray([]));
          dispatch(setToken(''));
          dispatch(setTenantSecretKey(''));
          dispatch(setUserName(''));
          dispatch(setClientId(''));
          dispatch(setForgetEmail(''));
          dispatch(setColorTheme('#0d0c0c'));
          // toast.error('Your session has expired. Please log in again.');
        }
        return Promise.reject(new Error('Session Expired')); // Prevent further API calls
      }
      return Promise.reject(error);
    }
  );

  const fetcher = async (URL) => {
    const response = await axiosClient.get(URL);
    return response.data;
  };
  const fetcherAuth = async (URL) => {
    const response = await axiosAuth.get(URL);
    return response.data;
  };
  const useGetRequest = (URL, options = {}, key) => {
    return useQuery([key ?? URL, URL], () => fetcher(URL), options);
  };
  const postRequest = async (URL, payload) => {
    try {
      const encryptedPayload = encryptPayload(payload);
      const response = await axiosClient.post(URL, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Post Request Error:', error);
      throw error;
    }
  };
  const useGetRequestAuth = (URL, options = {}) => {
    return useQuery([URL], () => fetcherAuth(URL), options);
  };
  const postRequestAuth = async (URL, payload) => {
    try {
      const encryptedPayload = encryptPayload(payload);
      const response = await axiosAuth.post(URL, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Post Request Error:', error);
      throw error;
    }
  };

  const patchRequest = async (URL, payload) => {
    try {
      const response = await axiosClient.patch(URL, payload);
      return response.data;
    } catch (error) {
      console.error('Patch Request Error:', error);
      throw error;
    }
  };
  const putRequest = async (URL, payload) => {
    try {
      const response = await axiosClient.put(URL, payload);
      return response.data;
    } catch (error) {
      console.error('Patch Request Error:', error);
      throw error;
    }
  };

  const deleteRequest = async (URL) => {
    try {
      const response = await axiosClient.delete(URL);
      return response.data;
    } catch (error) {
      console.error('Delete Request Error:', error);
      throw error;
    }
  };

  const postRequestFormData = async (URL, payload) => {
    try {
      const formData = new FormData();
      for (const key in payload) {
        if (Array.isArray(payload[key])) {
          payload[key].forEach((file) => {
            formData.append(key, file);
          });
        } else {
          formData.append(key, payload[key]);
        }
      }
      const response = await axiosClient.post(URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      console.error('Post FormData Request Error:', error);
      throw error;
    }
  };

  return {
    useGetRequest,
    useGetRequestAuth,
    postRequest,
    postRequestAuth,
    putRequest,
    patchRequest,
    deleteRequest,
    postRequestFormData,
  };
};

export default useApiClient;

// Use of React Query in case of POST,PATCH,DELETE

// const usePostRequest = (URL, options = {}) => {
//   return useMutation(
//     async (payload) => {
//       const encryptedPayload = encryptPayload(payload);
//       const response = await axiosClient.post(URL, options, {
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
//       return response.data;
//     },
//     {
//       ...options,
//       onError: (error) => {
//         console.error('Post Request Error:', error);
//         toast.error('An error occurred. Please try again.');
//       },
//     }
//   );
// };

// const usePatchRequest = (URL, options = {}) => {
//   return useMutation(
//     async (payload) => {
//       const response = await axiosClient.patch(URL, payload);
//       return response.data;
//     },
//     {
//       ...options,
//       onError: (error) => {
//         console.error('Patch Request Error:', error);
//         toast.error('An error occurred. Please try again.');
//       },
//     }
//   );
// };

// const useDeleteRequest = (URL, options = {}) => {
//   return useMutation(
//     async () => {
//       const response = await axiosClient.delete(URL);
//       return response.data;
//     },
//     {
//       ...options,
//       onError: (error) => {
//         console.error('Delete Request Error:', error);
//         toast.error('An error occurred. Please try again.');
//       },
//     }
//   );
// };

// const usePostRequestFormData = (URL, options = {}) => {
//   return useMutation(
//     async (payload) => {
//       const formData = new FormData();
//       for (const key in payload) {
//         if (Array.isArray(payload[key])) {
//           payload[key].forEach((file) => {
//             formData.append(key, file);
//           });
//         } else {
//           formData.append(key, payload[key]);
//         }
//       }
//       const response = await axiosClient.post(URL, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       return response.data;
//     },
//     {
//       ...options,
//       onError: (error) => {
//         console.error('Post FormData Request Error:', error);
//         toast.error('An error occurred. Please try again.');
//       },
//     }
//   );
