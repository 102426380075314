import { Button, Modal } from 'antd';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
import ToastNotification from 'components/ToastNotification';
import { get_country_by_dropdown, update_city } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useValidationSchema from 'validation/useValidationSchema';

const EditCityModal = ({
  openEditCityModal,
  setOpenEditCityModal,
  selectedCityRecord,
  refetch,
}) => {
  const { themeMode } = useSelector((state) => state?.states);
  const { useGetRequest, putRequest } = useApiClient();
  const [loading, setLoading] = useState(false);
  const { citiesModalSchema } = useValidationSchema();
  const formik = useFormik({
    initialValues: {
      cityId: '',
      countryId: '',
      stateId: '',
    },
    validationSchema: citiesModalSchema,
    onSubmit: ({ cityName, countryId, stateId }) => {
      setLoading(true);
      const paylaod = {
        name: cityName,
        countryId: countryId,
        cityId: selectedCityRecord?.cityId,
        stateId: stateId,
      };
      putRequest(`${update_city}`, paylaod)
        .then((res) => {
          if (res?.success) {
            refetch();
            formik.resetForm();
            ToastNotification('success', res.message, themeMode);
            setOpenEditCityModal(false);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )

        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCancel = () => {
    setOpenEditCityModal(false);
    formik.resetForm();
  };

  const { data: countryData } = useGetRequest(get_country_by_dropdown, {
    refetchOnMount: 'always',
  });
  const { data: stateData, refetch: statesRefetch } = useGetRequest(
    `api/StateDropdown?Id=${formik.values.countryId}`,
    {
      enabled: false,
    }
  );
  const { data: cityData, refetch: cityRefetch } = useGetRequest(
    `api/CitiesDropdown?Id=${formik.values.stateId}`,
    {
      enabled: false,
    }
  );
  useEffect(() => {
    if (formik.values.countryId) statesRefetch();
    if (formik.values.stateId) cityRefetch();
  }, [formik.values.countryId, formik.values.stateId]);

  useEffect(() => {
    if (selectedCityRecord) {
      formik.setValues({
        cityName: selectedCityRecord?.name || '',
        countryId: selectedCityRecord?.countryId || '',
        stateId: selectedCityRecord?.stateId || '',
      });
    }
  }, [selectedCityRecord, openEditCityModal]);

  return (
    <>
      <Modal
        open={openEditCityModal}
        title={<span className=" text-[18px]">Edit City</span>}
        width={400}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
            className="!shadow-none"
          >
            Submit
          </Button>,
        ]}
      >
        <>
          <div className=" grid grid-cols-12 gap-x-20 gap-y-5  py-[10px]">
            <div className=" col-span-12">
              <SelectField
                formik={formik}
                id={'countryId'}
                label={'Country'}
                required={true}
                optionId={'countryId'}
                optionName={'name'}
                optionData={countryData?.data}
                onChange={(newValue, _) => {
                  formik.setFieldValue('countryId', newValue);
                  formik.setFieldValue('stateId', null);
                  formik.setFieldValue('cityName', null);
                }}
              />
            </div>
            <div className=" col-span-12">
              <SelectField
                formik={formik}
                id={'stateId'}
                label={'State'}
                optionId={'stateId'}
                optionName={'name'}
                optionData={stateData?.data}
                onChange={(newValue, _) => {
                  formik.setFieldValue('stateId', newValue);
                  formik.setFieldValue('cityName', null);
                }}
              />
            </div>
            <div className=" col-span-12">
              <TextField formik={formik} id={'cityName'} label={'City'} />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default EditCityModal;
