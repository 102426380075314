import { QuestionCircleOutlined } from '@ant-design/icons';
import { Breadcrumb, Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SearchField from 'components/InputFields/SearchField';
import PopConfirm from 'components/PopConfirm/index';
import Status from 'components/Status/index';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import useAxiosClientTMS from 'config/useAxiosClientTMS';
import { usePermissionVariables } from 'Hooks/HasPermission';
import Layout from 'layout/AdminLayout';
import { useEffect, useState } from 'react';
import { FaTruck } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa6';
import { GoHome } from 'react-icons/go';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GET_VEHICLES_KEY } from 'utils/queryKey';

const { Title } = Typography;

const Vehicles = () => {
  const { Vehicles_Delete, Vehicles_Edit, Vehicles_Add } =
    usePermissionVariables();

  const [pageNumber, setPageNumber] = useState(1);
  const { useGetRequest, deleteRequest } = useAxiosClientTMS();
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const { data, isLoading, refetch } = useGetRequest(
    `/api/GetAllVehicles?pagenumber=${pageNumber}&pagesize=${rowsPerPage}&searchquery=${search}`,
    {},
    GET_VEHICLES_KEY
  );
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const navigate = useNavigate();

  const delHandler = (record) => {
    deleteRequest(`/api/DeleteVehicle?id=${record.vehicleId}`)
      .then((res) => {
        if (res?.success) {
          const newTotalRecord = totalRecord - 1; // Decrement the total record count
          setTotalRecord(newTotalRecord); // Update the state

          // Check if we need to change the page number
          if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1); // Redirect to the previous page
          }
          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };

  const columns = [
    {
      title: <span className="">Vehicle Number</span>,
      dataIndex: 'vehicleNumber',
      key: 'vehicleNumber',
      ellipsis: true,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.vehicleNumber.localeCompare(b.vehicleNumber),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'vehicleNumber'
          ? sortOrder.order
          : null,
      render: (text, record) => (
        <>
          <span
            className="underline cursor-pointer"
            onClick={() => {
              navigate(
                `/vehicles-details/${record?.tenantId}/${record?.vehicleId}`
              );
            }}
          >
            {text}
          </span>
        </>
      ),
    },
    {
      title: 'Manufacturer',
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      ellipsis: true,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.manufacturer.localeCompare(b.manufacturer),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'manufacturer'
          ? sortOrder.order
          : null,
    },

    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
      ellipsis: true,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.model.localeCompare(b.model),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'model' ? sortOrder.order : null,
    },
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      ellipsis: true,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.color.localeCompare(b.color),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'color' ? sortOrder.order : null,
    },
    {
      title: 'Capacity',
      dataIndex: 'capacity',
      key: 'capacity',
      ellipsis: true,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.capacity.localeCompare(b.capacity),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'capacity' ? sortOrder.order : null,
    },
    {
      title: 'Manufacturer Year',
      dataIndex: 'manufacturerYear',
      key: 'manufacturerYear',
      ellipsis: true,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.manufacturerYear - b.manufacturerYear,
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'manufacturerYear'
          ? sortOrder.order
          : null,
    },
    {
      title: <span className="flex items-center justify-center">Status</span>,
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      width: 300,
      ellipsis: true,
      render: (tag, record) => {
        return (
          <span>
            <Status tag={record?.status} />
          </span>
        );
      },
    },
    ...(Vehicles_Edit || Vehicles_Delete
      ? [
          {
            title: (
              <span className=" flex items-center justify-center">Action</span>
            ),
            dataIndex: '',
            ellipsis: true,
            width: 100,
            render: (text, record) => (
              <span className="flex justify-center items-center space-x-2">
                {Vehicles_Edit && (
                  <div
                    className=" p-[5px] rounded-sm"
                    onClick={() => {
                      navigate(
                        `/edit-vehicle-information/${record?.vehicleId}`
                      );
                    }}
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                )}
                {Vehicles_Delete && (
                  <div
                    className=" p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this task? "
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    >
                      <MdDeleteOutline className=" w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                )}
              </span>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    if (data) setTotalRecord(data?.totalRecords);
  }, [data]);

  return (
    <Layout>
      <>
        <div>
          <div className=" mb-[30px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
                marginBottom: '10px',
              }}
            >
              TMS
            </Title>
            <div className="flex justify-between items-center mb-20">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <FaTruck
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />
                            <span
                              className=" font-bold text-[13px]"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              Vehicle
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
            <div className="md:flex space-y-[20px] md:space-y-0 justify-between mt-10 mb-26">
              <SearchField
                htmlFor="search"
                id="myInputID"
                name="search"
                placeholder={'Search by Vehicle Number'}
                value={debouncedSearch}
                setDebouncedSearch={setDebouncedSearch}
                setPageNumber={setPageNumber}
                searchLoading={searchLoading}
                setSearchLoading={setSearchLoading}
                refetch={refetch}
                debouncedSearch={debouncedSearch}
                setSearch={setSearch}
              />
              {Vehicles_Add && (
                <div className="">
                  <PrimaryButton
                    title="Add Vehicle "
                    icon={<FaPlus />}
                    onClick={() => navigate(`/add-vehicle-information`)}
                  />
                </div>
              )}
            </div>

            <Table
              data={data?.data}
              columns={columns}
              pageNumber={pageNumber}
              rowsPerPage={rowsPerPage}
              setTotalRecord={setTotalRecord}
              totalRecord={totalRecord}
              setPageNumber={setPageNumber}
              setRowsPerPage={setRowsPerPage}
              setSortOrder={setSortOrder}
              setFilters={setFilters}
              loading={isLoading}
            />
          </div>
        </div>
      </>
    </Layout>
  );
};
export default Vehicles;
