import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { Breadcrumb, Progress, Typography } from 'antd';
import Loading from 'components/Loading';
import truckIcon from 'images/delivery-truck.svg';
import Layout from 'layout/AdminLayout';
import { BsBuildingFillLock } from 'react-icons/bs';
import { GoHome } from 'react-icons/go';
import { HiUsers } from 'react-icons/hi2';
import { IoMdArrowRoundUp } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import CustomBarCharts from './components/CustomBarCharts';
import DoubleLineChart from './components/DoubleLineChart';
const { Title } = Typography;
const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: '190px',
  borderRadius: '5px',
};
const polylinePath = [
  { lat: 37.7749, lng: -122.4194 },
  { lat: 37.8044, lng: -122.2712 },
  { lat: 37.6879, lng: -122.4702 },
  { lat: 38.775, lng: -122.4194 },
];

const options = {
  strokeColor: '#FF0000',
  strokeOpacity: 1,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};
const center = { lat: 37.7749, lng: -122.4194 };
// Data for Jobs Bar Chart
const jobsData = [
  { name: 'Total Jobs', count: 7200 },
  { name: 'Completed Jobs', count: 1800 },
  { name: 'In Progress Jobs', count: 2600 },
  { name: 'Active Jobs', count: 1200 },
  { name: 'On Hold Jobs', count: 800 },
];

// Data for Vehicles Pie Chart
const vehiclesData = [
  { name: 'Total Vehicles', value: 4500 },
  { name: 'Active Vehicles', value: 4000 },
  { name: 'Maintenance', value: 200 },
  { name: 'Assigned Vehicles', value: 4200 },
  { name: 'Unassigned Vehicles', value: 300 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

// Data for Drivers Line Chart
const driversData = [
  { name: 'Jan', ActiveDrivers: 300, TotalDrivers: 500 },
  { name: 'Feb', ActiveDrivers: 320, TotalDrivers: 520 },
  { name: 'Mar', ActiveDrivers: 350, TotalDrivers: 550 },
  // Add more data points if needed
];

// Jobs Bar Chart Component
const JobsChart = () => (
  <ResponsiveContainer width="100%" height={200}>
    <BarChart
      data={jobsData}
      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="count" fill="#8884d8" />
    </BarChart>
  </ResponsiveContainer>
);

// Vehicles Pie Chart Component
const VehiclesChart = () => (
  <ResponsiveContainer width="100%" height={200}>
    <PieChart>
      <Pie
        data={vehiclesData}
        cx="50%"
        cy="50%"
        outerRadius={100}
        label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
        dataKey="value"
      >
        {vehiclesData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  </ResponsiveContainer>
);

// Drivers Line Chart Component
const DriversChart = () => (
  <ResponsiveContainer width="100%" height={200}>
    <LineChart
      data={driversData}
      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Line type="monotone" dataKey="ActiveDrivers" stroke="#8884d8" />
      <Line type="monotone" dataKey="TotalDrivers" stroke="#82ca9d" />
    </LineChart>
  </ResponsiveContainer>
);

// Main Dashboard Component
const Dashboard = () => {
  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDLGitEALPVLPoPzj7XTq2uA3Us-QutdiM',
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }
  return (
    <Layout>
      <>
        <div>
          <div className=" mb-[30px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
              }}
            >
              TMS
            </Title>
            <div className="flex justify-between items-center ">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className="flex items-center space-x-4 ">
                            <BsBuildingFillLock
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />

                            <span
                              className="font-bold text-[13px]"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              Status Board
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className=" space-y-[20px]">
            <div className=" flex justify-between items-center  space-x-[20px]">
              <div className=" w-[35%] grid grid-cols-12 gap-y-[10px] gap-x-10 ">
                <div className=" h-auto col-span-6 bg-[#F6F4F3] shadow-md px-[20px] py-[20px] space-y-[5px] rounded-[8px]">
                  <div className=" flex justify-between">
                    <div>Load</div>
                    <div className="flex justify-center items-center w-[30px] h-[30px] bg-[#DCDEFC] rounded-[6px]">
                      <HiUsers
                        color={themeMode === 'light' ? colorTheme : '#9FA8DA'}
                      />
                    </div>
                  </div>
                  <div className=" font-bold text-[16px]">35.564</div>
                  <div className=" flex items-center justify-between">
                    <div className=" flex items-center">
                      <div>
                        <IoMdArrowRoundUp
                          size={13}
                          color={themeMode === 'light' ? colorTheme : '#9FA8DA'}
                        />
                      </div>
                      <div className=" text-green-600 text-[13px]">5.27%</div>
                    </div>
                    <div className=" text-[12px]">Since last month</div>
                  </div>
                </div>
                <div className=" h-auto col-span-6 bg-[#F6F4F3] shadow-md px-[20px] py-[20px] space-y-[5px] rounded-[8px]">
                  <div className=" flex justify-between">
                    <div>Vehical</div>
                    <div className="flex justify-center items-center w-[30px] h-[30px] bg-[#DCDEFC] rounded-[6px]">
                      <HiUsers
                        color={themeMode === 'light' ? colorTheme : '#9FA8DA'}
                      />
                    </div>
                  </div>
                  <div className=" font-bold text-[16px]">35.564</div>
                  <div className=" flex items-center justify-between">
                    <div className=" flex items-center">
                      <div>
                        <IoMdArrowRoundUp
                          size={13}
                          color={themeMode === 'light' ? colorTheme : '#9FA8DA'}
                        />
                      </div>
                      <div className=" text-green-600 text-[13px]">5.27%</div>
                    </div>
                    <div className=" text-[12px]">Since last month</div>
                  </div>
                </div>
                <div className=" h-auto col-span-6 bg-[#F6F4F3] shadow-md px-[20px] py-[20px] space-y-[5px] rounded-[8px]">
                  <div className=" flex justify-between">
                    <div>Driver</div>
                    <div className="flex justify-center items-center w-[30px] h-[30px] bg-[#DCDEFC] rounded-[6px]">
                      <HiUsers
                        color={themeMode === 'light' ? colorTheme : '#9FA8DA'}
                      />
                    </div>
                  </div>
                  <div className=" font-bold text-[16px]">35.564</div>
                  <div className=" flex items-center justify-between">
                    <div className=" flex items-center">
                      <div>
                        <IoMdArrowRoundUp
                          size={13}
                          color={themeMode === 'light' ? colorTheme : '#9FA8DA'}
                        />
                      </div>
                      <div className=" text-green-600 text-[13px]">5.27%</div>
                    </div>
                    <div className=" text-[12px]">Since last month</div>
                  </div>
                </div>
                <div className=" h-auto col-span-6 bg-[#F6F4F3] shadow-md px-[20px] py-[20px] space-y-[5px] rounded-[8px]">
                  <div className=" flex justify-between">
                    <div>Trailer</div>
                    <div className="flex justify-center items-center w-[30px] h-[30px] bg-[#DCDEFC] rounded-[6px]">
                      <HiUsers
                        color={themeMode === 'light' ? colorTheme : '#9FA8DA'}
                      />
                    </div>
                  </div>
                  <div className=" font-bold text-[16px]">35.564</div>
                  <div className=" flex items-center justify-between">
                    <div className=" flex items-center">
                      <div>
                        <IoMdArrowRoundUp
                          size={13}
                          color={themeMode === 'light' ? colorTheme : '#9FA8DA'}
                        />
                      </div>
                      <div className=" text-green-600 text-[13px]">5.27%</div>
                    </div>
                    <div className=" text-[12px]">Since last month</div>
                  </div>
                </div>
              </div>
              <div className=" w-[65%] shadow-md rounded-[8px]">
                <CustomBarCharts />
              </div>
            </div>
            <div className=" flex justify-between space-x-[20px]">
              <div className="md:w-[70%] shadow-md rounded-[8px] bg-[#F6F4F3]">
                <DoubleLineChart />
              </div>
              <>
                <div
                  className=" w-full md:w-[30%] h-auto space-y-25  rounded-sm p-20  shadow-md "
                  style={{
                    backgroundColor:
                      themeMode === 'light' ? '#f7f7fa' : '#121212',
                  }}
                >
                  <>
                    {isLoaded ? (
                      <>
                        <div>
                          <GoogleMap
                            className="rounded-sm"
                            mapContainerStyle={mapContainerStyle}
                            zoom={10}
                            center={center}
                          >
                            <Marker
                              position={center}
                              icon={{
                                url: truckIcon,
                                scaledSize: new window.google.maps.Size(30, 30),
                                origin: new window.google.maps.Point(0, 0),
                                anchor: new window.google.maps.Point(25, 25),
                                rotation: 215,
                              }}
                            />
                          </GoogleMap>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="w-full flex items-center justify-center py-[80px]">
                          <Loading />
                        </div>
                      </>
                    )}
                    <div className=" space-y-[10px]">
                      <div>
                        <div className=" text-[12px] -mb-[12px]">New York</div>
                        <div className=" w-full">
                          <Progress
                            percent={51}
                            strokeColor={
                              themeMode === 'light' ? colorTheme : '#121212'
                            }
                            strokeWidth={5}
                            format={(percent) => (
                              <div className="text-center text-[#5A5881] ">
                                {`${percent}k`}
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <div>
                        <div className=" text-[12px] -mb-[12px]">
                          San Francisco
                        </div>
                        <div className=" w-full">
                          <Progress
                            percent={51}
                            strokeColor={
                              themeMode === 'light' ? colorTheme : '#121212'
                            }
                            strokeWidth={5}
                            format={(percent) => (
                              <div className="text-center text-[#5A5881] ">
                                {`${percent}k`}
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <div>
                        <div className=" text-[12px] -mb-[12px]">Sydney</div>
                        <div className=" w-full">
                          <Progress
                            percent={51}
                            strokeColor={
                              themeMode === 'light' ? colorTheme : '#121212'
                            }
                            strokeWidth={5}
                            format={(percent) => (
                              <div className="text-center text-[#5A5881] ">
                                {`${percent}k`}
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <div>
                        <div className=" text-[12px] -mb-[12px]">Singapore</div>
                        <div className=" w-full">
                          <Progress
                            percent={51}
                            strokeColor={
                              themeMode === 'light' ? colorTheme : '#121212'
                            }
                            strokeWidth={5}
                            format={(percent) => (
                              <div className="text-center text-[#5A5881] ">
                                {`${percent}k`}
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default Dashboard;
