import { Breadcrumb, Select, Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SelectField from 'components/CustomFields/SelectField';
import Checkbox from 'components/InputFields/Checkbox';
import LabelGrid from 'components/LabelGrid';
import ToastNotification from 'components/ToastNotification';
import { add_all_devices } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useFormik } from 'formik';
import { usePermissionVariables } from 'Hooks/HasPermission';
import Layout from 'layout/AdminLayout';
import { useState } from 'react';
import { BiSave } from 'react-icons/bi';
import { FaPlus } from 'react-icons/fa6';
import { GoHome } from 'react-icons/go';
import { TbBasket } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useValidationSchema from 'validation/useValidationSchema';
const { Title } = Typography;

const AddJobs = () => {
  const { Logs_View, Status_View, Configurations_View, General_View } =
    usePermissionVariables();
  const { postRequest, useGetRequest, deleteRequest } = useApiClient();
  const { addDeviceModalSchema } = useValidationSchema();
  const { data, error, isLoading, refetch } = useGetRequest(
    '/api/GetDeviceModels'
  );
  const deviceModelOptions = data?.data?.map((item) => {
    return {
      value: item.deviceModelId,
      label: item.name,
    };
  });
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  const formik = useFormik({
    initialValues: {
      customerName: null,
      jobType: null,
      assignDriver: null,
      assignVehicle: null,
      totalOrders: '',
      isActive: false,
    },
    validationSchema: addDeviceModalSchema,
    onSubmit: ({
      deviceName,
      deviceModel,
      IMEI_No,
      serialNumber,
      simCardNumber,
      simProvider,
      status,
      isActive,
    }) => {
      setLoading(true);
      const paylaod = {
        deviceIdentifier: 'string',
        deviceName: deviceName,
        deviceModelID: deviceModel,
        serialNumber: serialNumber,
        imei: IMEI_No,
        simCardNumber: simCardNumber,
        simProvider: simProvider,
        status: status,
        isActive: isActive,
      };
      postRequest(add_all_devices, paylaod)
        .then((res) => {
          if (res?.success) {
            formik.resetForm();
            ToastNotification('success', res.message, themeMode);
            navigate('/device-management');
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <Layout>
      <>
        <div className="mb-[30px] ">
          <Title
            level={4}
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
              fontFamily: 'inter-bold',
            }}
          >
            Add Jobs
          </Title>
          <div className="flex justify-between items-center mb-20">
            <div>
              <Breadcrumb
                separator="/"
                items={[
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <GoHome />
                          <span
                            className=" cursor-pointer font-reg text-[13px]"
                            onClick={() => {
                              navigate('/dashboard');
                            }}
                          >
                            Dashboard
                          </span>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <TbBasket />
                          <span
                            className=" cursor-pointer font-reg text-[13px]"
                            onClick={() => {
                              navigate('/jobs');
                            }}
                          >
                            Jobs
                          </span>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <FaPlus
                            style={{
                              color: themeMode === 'light' ? colorTheme : '',
                            }}
                          />
                          <span
                            className=" font-bold text-[13px]"
                            style={{
                              color: themeMode === 'light' ? colorTheme : '',
                            }}
                          >
                            Add Jobs
                          </span>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="">
          <form onSubmit={formik.handleSubmit}>
            <LabelGrid label="Add Jobs" className=" rounded-[5px] px-20 py-20">
              <div className="grid grid-cols-12 sm:gap-x-[30px]  gap-y-[25px] sm:gap-y-0 ">
                <div className="  col-span-12 md:col-span-3">
                  <SelectField
                    formik={formik}
                    id={'customerName'}
                    label={'Customer Name'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={deviceModelOptions}
                    notModal={true}
                  />
                </div>
                <div className="  col-span-12 md:col-span-3">
                  <SelectField
                    formik={formik}
                    id={'jobType'}
                    label={'Job Type'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={deviceModelOptions}
                    notModal={true}
                  />
                </div>
                <div className="  col-span-12 md:col-span-3">
                  <SelectField
                    formik={formik}
                    id={'assignVehicle'}
                    label={'Assign Vehicle'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={deviceModelOptions}
                    notModal={true}
                  />
                </div>
                <div className="  col-span-12 md:col-span-3">
                  <SelectField
                    formik={formik}
                    id={'assignDriverType'}
                    label={'Assign Driver'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={deviceModelOptions}
                    notModal={true}
                  />
                </div>
                <div className="  col-span-12 md:col-span-3">
                  <SelectField
                    formik={formik}
                    id={'totalOrders'}
                    label={'Total Orders'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={deviceModelOptions}
                    notModal={true}
                  />
                </div>
                <div className="mt-20">
                  <Checkbox
                    title="IsActive"
                    id="isActive"
                    name="isActive"
                    checked={formik.values.isActive}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className="flex justify-end">
                <PrimaryButton
                  title="Save"
                  icon={<BiSave className=" w-[17px] h-[17px]" />}
                  htmlType="submit"
                  loading={loading}
                />
              </div>
            </LabelGrid>
          </form>
        </div>
      </>
    </Layout>
  );
};
export default AddJobs;
