import LabelGrid from 'components/LabelGrid';
import React, { useState } from 'react';
import Checkbox from 'components/InputFields/Checkbox';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { BiSave } from 'react-icons/bi';

const ProofOfPickupConfig = () => {
  const [checkbox, setCheckbox] = useState({
    qr: false,
    photo: false,
    signature: false,
  });
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckbox({
      ...checkbox,
      [name]: checked,
    });
  };
  const handleSubmit = () => {};
  return (
    <>
      <LabelGrid
        label="Proof of Pickup Configuration"
        className=" rounded-[5px] px-20 py-20"
      >
        <div className=" grid grid-cols-12">
          <div className=" col-span-4">
            <Checkbox
              title="QR"
              id="qr"
              name="qr"
              checked={checkbox.qr}
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="col-span-4">
            <Checkbox
              title="Photo"
              id="photo"
              name="photo"
              checked={checkbox.photo}
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="col-span-4">
            <Checkbox
              title="Signature"
              id="signature"
              name="signature"
              checked={checkbox.signature}
              onChange={handleCheckboxChange}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <PrimaryButton
            title="Save"
            icon={<BiSave className=" w-[17px] h-[17px]" />}
            htmlType="submit"
            onClick={handleSubmit}
            // loading={loading}
          />
        </div>
      </LabelGrid>
    </>
  );
};

export default ProofOfPickupConfig;
