import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { nonPersistedSlice } from './nonPersistSlices';
import slices from './slices';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, slices);
const nonPersistedReducer = nonPersistedSlice.reducer;

export const store = configureStore({
  reducer: {
    states: persistedReducer,
    nonPersistedStates: nonPersistedReducer,
  },
});

export const persistor = persistStore(store);
