import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { ConfigProvider } from 'antd';
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { setRealNotifications, setTrackingData } from 'store/nonPersistSlices';
import { playSound } from 'utils/helper';
import { AllRoutes } from './routes';
import PrivateRoute from './routes/PrivateRoute';
import ProtectedRoute from './routes/ProtectedRoute';

function App() {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const dispatch = useDispatch();
  const [connection, setConnection] = useState(null);

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl('https://tms-api4.tekhqs.net/notificationHub')
      .configureLogging(LogLevel.Information)
      .build();
    setConnection(connect);
    const startConnection = async () => {
      try {
        await connect.start();
      } catch (err) {
        console.error('Error connecting to SignalR hub:', err);
        setTimeout(startConnection, 5000);
      }
    };
    startConnection();
    return () => {
      connect.stop();
      console.log('Disconnected from SignalR hub');
    };
  }, []);

  useEffect(() => {
    if (connection) {
      connection.on('ReceiveNotification', (message) => {
        dispatch(
          setRealNotifications({
            message: message?.message,
            isRead: message?.isRead,
            title: message?.title,
            time: message?.time,
            notificationId: message?.notificationId,
          })
        );
    
      });
      connection.on('receivetrackerdata', (data) => {
        data?.coordinates?.map((item) => dispatch(setTrackingData(item)));
      });

      connection.onclose(() => {
        console.log('Connection closed');
      });
    }
  }, [connection]);

  return (
    <>
      <BrowserRouter>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: themeMode === 'dark' ? '#939BC9' : colorTheme,
              colorPrimaryHover: '',
              colorBorder: themeMode === 'dark' ? '#939BC9' : '#d9d9d9',
              colorText: themeMode === 'dark' ? '#9F9B93' : 'black',
              colorTextPlaceholder:
                themeMode === 'dark' ? '#EBE7E5' : '#d9d9d9',
            },
            components: {
              Input: {
                hoverBorderColor: themeMode === 'dark' ? '' : colorTheme,
                activeBorderColor: themeMode === 'dark' ? '' : colorTheme,
              },
              Button: {
                primaryShadow: 'none',
                defaultColor: themeMode === 'dark' ? '#939BC9' : colorTheme,
                defaultBg: themeMode === 'dark' ? 'transparent' : '',
                defaultBorderColor:
                  themeMode === 'dark' ? '#939BC9' : colorTheme,
                defaultHoverBg: themeMode === 'dark' ? 'transparent' : '',
                borderColorDisabled:
                  themeMode === 'dark' ? '#939BC9' : colorTheme,
                colorTextDisabled: themeMode === 'dark' ? 'black' : 'white',
                colorBgContainerDisabled:
                  themeMode === 'dark' ? '#939BC9' : colorTheme,
              },
              Select: {
                selectorBg: themeMode === 'dark' ? '#121212' : '',
                optionSelectedColor: themeMode === 'dark' ? '#939BC9' : '',
                optionSelectedBg: themeMode === 'dark' ? '#121212' : '',
              },
              Breadcrumb: {
                itemColor: themeMode === 'dark' ? '#9FA8DA' : '#000000',
                lastItemColor: themeMode === 'dark' ? '#9FA8DA' : '#000000',
                separatorColor: themeMode === 'dark' ? '#9FA8DA' : '#000000',
              },

              Table: {
                headerBg: themeMode == 'dark' ? '#121212' : 'white',
                headerColor: themeMode == 'dark' ? '#EBE7E5' : '#000000',
                borderColor: themeMode == 'dark' ? '#353535' : '#f0f0f0',
                headerSplitColor: themeMode == 'dark' ? 'white' : 'white',
                rowHoverBg: themeMode == 'dark' ? '' : '#F7F7FA',
                headerFilterHoverBg: '',
                headerSortHoverBg: themeMode == 'dark' ? '#1F1F1F' : 'white',
                headerSortActiveBg: themeMode == 'dark' ? '#1F1F1F' : '#F7F7FA',
                rowExpandedBg: themeMode == 'dark' ? '#1F1F1F' : 'white',
                headerBorderRadius: '0px',
                backgroundColor: 'red',
              },
              Pagination: {
                itemActiveBg: themeMode == 'dark' ? '#1F1F1F' : 'white',
              },
              Tabs: {
                itemColor: themeMode == 'dark' ? '#EBE7E5' : 'black',
                inkBarColor: themeMode === 'dark' ? '#939BC9' : colorTheme,
                itemActiveColor: themeMode === 'dark' ? '#939BC9' : colorTheme,
              },
              Modal: {
                titleColor: themeMode == 'dark' ? '#939BC9' : colorTheme,
                headerBg: themeMode == 'dark' ? 'black' : '#F7F7FA',
                contentBg: themeMode == 'dark' ? 'black' : '#F7F7FA',
              },
              Timeline: {
                tailColor: themeMode === 'dark' ? '#939BC9' : colorTheme,
                tailWidth: 1,
              },
              Badge: {
                textFontSizeSM: 10,
              },
            },
          }}
        >
          <Toaster position="top-center" reverseOrder={false} />
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            {AllRoutes.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={
                  item.isPrivate ? (
                    <PrivateRoute>{item.page}</PrivateRoute>
                  ) : (
                    <ProtectedRoute>{item.page}</ProtectedRoute>
                  )
                }
              />
            ))}
            {/* {AllRoutes.map((item, index) => (
            <Route
              key={index}
              path={item.path}
              element={
                <AuthRoute
                  isPrivate={item.isPrivate}
                  isPermission={item.isPermission}
                >
                  {item.page}
                </AuthRoute>
              }
            />
          ))} */}
          </Routes>
        </ConfigProvider>
      </BrowserRouter>
      <style jsx>{`
        body {
          background-color: ${themeMode === 'light' ? '#ffffff' : '#1f1f1f'};
        }
        .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
          background-color: ${themeMode === 'light' ? '#fff' : '#000000'};
        }
        .ant-dropdown
          .ant-dropdown-menu
          .ant-dropdown-menu-item-selected:hover {
          background-color: ${themeMode === 'light' ? '#00000012' : '#1f1f1f'};
        }
        .ant-select-item-option-active {
          background-color: ${themeMode === 'light'
            ? '#00000012'
            : '#1f1f1f'}!important;
        }

        .ant-dropdown-menu .ant-dropdown-menu-item-active {
          background-color: ${themeMode === 'light'
            ? ''
            : '#1f1f1f'} !important;
        }
        .ps-menu-button:hover {
          background-color: ${themeMode === 'light'
            ? ''
            : '#434343 !important'};
          color: ${themeMode === 'light' ? '' : '#ebe7e5 !important'};
        }
        .ps-menu-button {
          border-left-color: ${themeMode === 'light'
            ? ''
            : '#939BC9'} !important;
        }
        .ant-select-dropdown {
          background-color: ${themeMode === 'light' ? '' : '#000000'};
        }
        .ant-select-dropdown .ant-select-item-empty .ant-empty-description {
          color: ${themeMode === 'light' ? '' : '#ebe7e5'};
        }
        .ant-btn-dashed {
          border-color: ${themeMode === 'light' ? colorTheme : '#939BC9'};
        }
      `}</style>
    </>
  );
}

export default App;
