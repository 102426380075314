import { Table } from 'antd';
import LabelGrid from 'components/LabelGrid';
import { useEffect, useState } from 'react';
// import './style.css';
import { Breadcrumb, Select, Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import InputFieldCustomForm from 'components/CustomFields/CustomFieldsOutlinedForm';
import Checkbox from 'components/InputFields/Checkbox';
import ToastNotification from 'components/ToastNotification';
import { add_role_rights } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useFormik } from 'formik';
import Layout from 'layout/AdminLayout';
import { BiSave } from 'react-icons/bi';
import { GoHome } from 'react-icons/go';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { MdModeEditOutline } from 'react-icons/md';
import { SlArrowDown } from 'react-icons/sl';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { themeInputColor } from 'utils/constant';
import useValidationSchema from 'validation/useValidationSchema';

const { Title } = Typography;
const { Option } = Select;
function generatePermissionsArray(pages) {
  return pages?.map((page) => ({
    key: page.value,
    pageId: page.value,
    pageName: page.label,
    fullControl: false,
    canAdd: false,
    canView: false,
    canEdit: false,
    canDelete: false,
  }));
}
function generatePagesArray(pages) {
  return pages?.map((page) => ({
    pageId: page.pageId,
    pageName: page.pageName,
    fullControl: page?.fullControl,
    canAdd: page?.canAdd,
    canView: page?.canView,
    canEdit: page?.canEdit,
    canDelete: page?.canDelete,
  }));
}
const AddRoleRights = () => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { postRequest, useGetRequest } = useApiClient();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: rolesData } = useGetRequest('/api/Roles/GetRoleDropdown', {
    refetchOnMount: 'always',
  });
  const { data: menuData } = useGetRequest(
    `/api/AppMenus/GetManusForDropdown`,
    {
      refetchOnMount: 'always',
    }
  );
  const { roleRightsModalSchema } = useValidationSchema();
  const { Option } = Select;
  const createOptions = (data, labelKey, valueKey) =>
    data?.data?.map((item) => ({
      label: item[labelKey],
      value: item[valueKey],
    }));
  const createRoleOptions = (data, labelKey, valueKey) =>
    data?.map((item) => ({
      label: item[labelKey],
      value: item[valueKey],
    }));
  const roleOptions = createRoleOptions(rolesData?.data, 'roleName', 'roleId');
  const menuOptions = createOptions(menuData, 'menuName', 'menuId');
  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckboxes] = useState([]);
  const handleCheckboxChange = (recordKey, checkboxName, checked) => {
    setCheckboxes((prevCheckboxes) => {
      return prevCheckboxes.map((checkbox) => {
        if (checkbox.key === recordKey) {
          let updatedCheckbox = { ...checkbox };

          if (checkboxName === 'fullControl') {
            updatedCheckbox = {
              ...updatedCheckbox,
              fullControl: checked,
              canView: checked,
              canAdd: checked,
              canEdit: checked,
              canDelete: checked,
            };
          } else {
            updatedCheckbox[checkboxName] = checked;

            if (checkboxName === 'canView' && !checked) {
              updatedCheckbox.canAdd = false;
              updatedCheckbox.canEdit = false;
              updatedCheckbox.canDelete = false;
            }
            if (['canAdd', 'canEdit', 'canDelete'].includes(checkboxName)) {
              updatedCheckbox.canView = true;
            }

            const allChecked =
              updatedCheckbox.canAdd &&
              updatedCheckbox.canView &&
              updatedCheckbox.canEdit &&
              updatedCheckbox.canDelete;
            updatedCheckbox.fullControl = allChecked;
          }

          return updatedCheckbox;
        }

        return checkbox;
      });
    });
  };

  const formik = useFormik({
    initialValues: {
      role: null,
      menu: null,
    },
    validationSchema: roleRightsModalSchema,
    onSubmit: ({ role, menu }) => {
      setLoading(true);
      const paylaod = {
        roleId: formik.values.role,
        menuId: formik.values.menu,
        permission: generatePagesArray(checkbox),
      };
      console.log('payload', paylaod);
      postRequest(add_role_rights, paylaod)
        .then((res) => {
          if (res?.success) {
            formik.resetForm();
            setCheckboxes([]);
            ToastNotification('success', res.message, themeMode);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const {
    data: pagesDataByMenuID,
    isLoading,
    refetch: pageDataRefetch,
  } = useGetRequest(`/api/AppPages/GetpagebyMenuid?id=${formik.values.menu}`, {
    enabled: false,
  });
  const pageOptionsByMenuID = createOptions(
    pagesDataByMenuID,
    'pageName',
    'pageId'
  );

  const columns = [
    {
      title: 'Page Name',
      dataIndex: 'pageName',
      ellipsis: true,
      key: 'pageName',
      render: (text, record) => record.pageName,
    },
    {
      title: 'Full Control',
      align: 'center',
      dataIndex: 'fullControl',
      ellipsis: true,
      key: 'fullControl',
      render: (text, record) => (
        <span className="flex justify-center items-center">
          <Checkbox
            type="checkbox"
            checked={record.fullControl}
            onChange={(e) =>
              handleCheckboxChange(
                record.pageId,
                'fullControl',
                e.target.checked
              )
            }
          />
        </span>
      ),
    },
    {
      title: 'Can View',
      align: 'center',
      dataIndex: 'canView',
      ellipsis: true,
      key: 'canView',
      render: (text, record) => (
        <span className="flex justify-center items-center">
          <Checkbox
            type="checkbox"
            checked={record.canView}
            onChange={(e) =>
              handleCheckboxChange(record.pageId, 'canView', e.target.checked)
            }
          />
        </span>
      ),
    },
    {
      title: 'Can Add',
      align: 'center',
      dataIndex: 'canAdd',
      ellipsis: true,
      key: 'canAdd',
      render: (text, record) => (
        <span className="flex justify-center items-center">
          <Checkbox
            type="checkbox"
            checked={record.canAdd}
            onChange={(e) =>
              handleCheckboxChange(record.pageId, 'canAdd', e.target.checked)
            }
          />
        </span>
      ),
    },
    {
      title: 'Can Edit',
      align: 'center',
      dataIndex: 'canEdit',
      ellipsis: true,
      key: 'canEdit',
      render: (text, record) => (
        <span className="flex justify-center items-center">
          <Checkbox
            type="checkbox"
            checked={record.canEdit}
            onChange={(e) =>
              handleCheckboxChange(record.pageId, 'canEdit', e.target.checked)
            }
          />
        </span>
      ),
    },
    {
      title: 'Can Delete',
      align: 'center',
      dataIndex: 'canDelete',
      ellipsis: true,
      key: 'canDelete',
      render: (text, record) => (
        <span className="flex justify-center items-center">
          <Checkbox
            type="checkbox"
            checked={record.canDelete}
            onChange={(e) =>
              handleCheckboxChange(record.pageId, 'canDelete', e.target.checked)
            }
          />
        </span>
      ),
    },
  ];
  useEffect(() => {
    if (formik.values.menu) pageDataRefetch();
  }, [formik.values.menu]);
  useEffect(() => {
    if (formik.values.menu) {
      pageDataRefetch();
    }
    if (pageOptionsByMenuID) {
      setCheckboxes(generatePermissionsArray(pageOptionsByMenuID || []));
    }
  }, [pagesDataByMenuID, formik.values.menu]);

  // const paylaod = {
  //   roleId: formik.values.role,
  //   menuId: formik.values.menu,
  //   permission: generatePagesArray(checkbox),
  // };
  // console.log('payload', paylaod);
  return (
    <Layout>
      <>
        <div className=" mb-[30px] ">
          <Title
            level={4}
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
              fontFamily: 'inter-bold',
            }}
          >
            Assign Permission
          </Title>
          <div className="flex justify-between items-center ">
            <div>
              <Breadcrumb
                separator="/"
                items={[
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <GoHome />
                          <span
                            className="cursor-pointer font-reg"
                            onClick={() => {
                              navigate('/dashboard');
                            }}
                          >
                            Dashboard
                          </span>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <HiOutlineDocumentText />
                          <span
                            className="cursor-pointer font-reg"
                            onClick={() => {
                              navigate(`/role-management?active=4`);
                            }}
                          >
                            Role Management
                          </span>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <MdModeEditOutline
                            style={{
                              color: themeMode === 'light' ? colorTheme : '',
                            }}
                          />
                          <span
                            className=" font-bold"
                            style={{
                              color: themeMode === 'light' ? colorTheme : '',
                            }}
                          >
                            Assign Permission
                          </span>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <LabelGrid
            label="Role Permissions"
            className=" rounded-[5px] px-20 py-20"
          >
            <div className="grid grid-cols-12 sm:gap-x-[30px]  gap-y-[25px] sm:gap-y-0 ">
              <div className=" col-span-12 sm:col-span-4">
                <div className="wrapper-fields">
                  <InputFieldCustomForm
                    label="Role"
                    name="role"
                    value={formik.values.role}
                    error={formik.touched.role && Boolean(formik.errors.role)}
                    errorMessage={formik.errors.role}
                    required={true}
                    notModal={true}
                  >
                    <Select
                      id="role"
                      name="role"
                      prefix={<></>}
                      mode="single"
                      showSearch
                      optionFilterProp="children"
                      value={formik.values.role}
                      onChange={(newValue, _) => {
                        formik.setFieldValue('role', newValue);
                      }}
                      onBlur={formik.handleBlur}
                      suffixIcon={
                        <SlArrowDown
                          className="w-[12px] h-[12px]"
                          style={{
                            color: !(
                              formik.touched.role && Boolean(formik.errors.role)
                            )
                              ? themeMode === 'light'
                                ? colorTheme
                                : themeInputColor.darkIconColor
                              : themeInputColor.errorIconColor,
                          }}
                        />
                      }
                      style={{
                        border: !(
                          formik.touched.role && Boolean(formik.errors.role)
                        )
                          ? themeMode === 'light'
                            ? `1px solid ${themeInputColor.lightBorderColor}`
                            : `1px solid ${themeInputColor.darkBorderColor}`
                          : `1px solid ${themeInputColor.errorBorderColor}`,

                        borderRadius: '5px',
                        caretColor:
                          themeMode === 'light'
                            ? themeInputColor.lightCaretColor
                            : themeInputColor.darkCaretColor,
                        color:
                          themeMode === 'light'
                            ? themeInputColor.lightTextColor
                            : themeInputColor.darkTextColor,
                      }}
                    >
                      {roleOptions?.map((item) => (
                        <Option key={item?.value} value={item?.value}>
                          {item?.label}
                        </Option>
                      ))}
                    </Select>
                  </InputFieldCustomForm>
                </div>
              </div>
              <div className="  col-span-12 sm:col-span-4">
                <div className="wrapper-fields">
                  <InputFieldCustomForm
                    label="Menu"
                    name="menu"
                    value={formik.values.menu}
                    error={formik.touched.menu && Boolean(formik.errors.menu)}
                    errorMessage={formik.errors.menu}
                    required={true}
                    notModal={true}
                  >
                    <Select
                      id="menu"
                      name="menu"
                      prefix={<></>}
                      mode="single"
                      showSearch
                      optionFilterProp="children"
                      value={formik.values.menu}
                      onChange={(newValue, _) => {
                        formik.setFieldValue('menu', newValue);
                      }}
                      onBlur={formik.handleBlur}
                      suffixIcon={
                        <SlArrowDown
                          className="w-[12px] h-[12px]"
                          style={{
                            color: !(
                              formik.touched.menu && Boolean(formik.errors.menu)
                            )
                              ? themeMode === 'light'
                                ? colorTheme
                                : themeInputColor.darkIconColor
                              : themeInputColor.errorIconColor,
                          }}
                        />
                      }
                      style={{
                        border: !(
                          formik.touched.menu && Boolean(formik.errors.menu)
                        )
                          ? themeMode === 'light'
                            ? `1px solid ${themeInputColor.lightBorderColor}`
                            : `1px solid ${themeInputColor.darkBorderColor}`
                          : `1px solid ${themeInputColor.errorBorderColor}`,

                        borderRadius: '5px',
                        caretColor:
                          themeMode === 'light'
                            ? themeInputColor.lightCaretColor
                            : themeInputColor.darkCaretColor,
                        color:
                          themeMode === 'light'
                            ? themeInputColor.lightTextColor
                            : themeInputColor.darkTextColor,
                      }}
                    >
                      {menuOptions?.map((item) => (
                        <Option key={item?.value} value={item?.value}>
                          {item?.label}
                        </Option>
                      ))}
                    </Select>
                  </InputFieldCustomForm>
                </div>
              </div>
            </div>
          </LabelGrid>

          <div className="mt-[40px]">
            <Table
              dataSource={checkbox || []}
              columns={columns}
              loading={isLoading}
              size="small"
              scroll={{ x: true }}
              pagination={false}
              bordered
              className={`${
                themeMode === 'light' ? 'light-table' : 'dark-table'
              }`}
            />
          </div>
          <div className="flex justify-end mt-[30px] pb-[30px]">
            <PrimaryButton
              title="Save"
              icon={<BiSave className=" w-[17px] h-[17px]" />}
              htmlType="submit"
              loading={loading}
            />
          </div>
        </form>
      </>
    </Layout>
  );
};

export default AddRoleRights;
