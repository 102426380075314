// import { AdminHeader } from 'components';
import Header from 'components/Header';
import SideBar from 'components/Sidebar';
import { useState } from 'react';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
function Layout({ children }) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [isSidebarOpen, setSidebarOpen] = useState(isMobile ? false : true);
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  return (
    <>
      <div
        className={`flex`}
        style={{
          backgroundColor: themeMode === 'light' ? 'white' : '#1F1F1F',
        }}
      >
        <ProSidebarProvider>
          <SideBar
            isMobile={isMobile}
            isSidebarOpen={isSidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />
        </ProSidebarProvider>
        <div className="w-full">
          <div
            style={{
              backgroundColor: themeMode === 'light' ? 'white' : '#1f1f1f',
            }}
            className={`w-full sticky top-0 z-10`}
          >
            <Header
              isSidebarOpen={isSidebarOpen}
              setSidebarOpen={setSidebarOpen}
            />
          </div>
          <div
            className={`p-[10px] lg:p-0 lg:px-[50px] lg:py-[30px] lg:ml-0 ${
              themeMode === 'light' ? 'light-theme' : 'dark-theme'
            }`}
            style={{ height: 'auto' }}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;
