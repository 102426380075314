import { toast } from 'react-hot-toast';

const ToastNotification = (type, message, themeMode) => {
  const notify = () => {
    const commonStyle = {
      borderRadius: '6px', // Customize the border radius
      padding: '10px', // Adjust the padding
      color: themeMode === 'light' ? 'black' : '#E7E3E1', // Text color
      fontSize: '12px',
      fontFamily: 'inter-reg', // Add desired font-family
    };
    const playSound = (soundPath) => {
      const audio = new Audio(soundPath);
      audio.play();
    };
    switch (type) {
      case 'success':
        playSound('/sounds/success-sound.wav');
        toast.success(message, {
          style: {
            ...commonStyle,
            backgroundColor: themeMode === 'light' ? '' : '#121212',
          },
        });
        break;
      case 'error':
        playSound('/sounds/fail-sound.mp3');
        toast.error(message, {
          style: {
            ...commonStyle,
            backgroundColor: themeMode === 'light' ? '' : '#121212',
          },
        });
        break;
      case 'loading':
        toast.loading(message);
        break;
      default:
        toast(message);
    }
  };
  return notify();
};

export default ToastNotification;
