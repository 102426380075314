import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en/en.json';
import de from './locales/de/de.json';
import ar from './locales/ar/ar.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      // English (en) - United States
      en: {
        translation: en,
      },

      // German (de) - Germany
      de: {
        translation: de,
      },
      ar: {
        translation: ar,
      },
    },
    fallbackLng: 'en', // when the key is missing
    debug: true,
    returnObjects: true, // so it does not try to stringify objects
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    // lng: "en",
    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
