import LabelGrid from 'components/LabelGrid';
import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
// import './style.css';
import { useFormik } from 'formik';
import useValidationSchema from 'validation/useValidationSchema';
import { Select } from 'antd';
import SelectField from 'components/InputFields/SelectField';
import { SlArrowDown } from 'react-icons/sl';
import { initialData, options } from 'utils/constant';
import InputFieldCustomForm from 'components/CustomFields/CustomFieldsOutlinedForm';
import Checkbox from 'components/InputFields/Checkbox';
import { useSelector } from 'react-redux';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { BiSave } from 'react-icons/bi';
import useApiClient from 'config/useAxiosClient';
import { useQueryClient } from 'react-query';
import Layout from 'layout/AdminLayout';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { GoHome } from 'react-icons/go';
import { Breadcrumb } from 'antd';
import { Typography } from 'antd';
import { themeInputColor } from 'utils/constant';
import { useNavigate, useParams } from 'react-router-dom';
import { MdModeEditOutline } from 'react-icons/md';
import { toast } from 'react-toastify';
import { BsPeople } from 'react-icons/bs';
import { FiClock } from 'react-icons/fi';
import { LuShieldCheck } from 'react-icons/lu';
import { update_role_rights, get_role_rights_by_id } from 'config/services';
const { Title } = Typography;
const { Option } = Select;

const AssignPermission = () => {
  const { id } = useParams();
  const { putRequest, useGetRequest } = useApiClient();
  const {
    data: roleRightsData,
    error,
    isLoading,
    refetch: refetchRoleRightsByid,
  } = useGetRequest(`${get_role_rights_by_id}${id}`);
  const initialData = [
    {
      key: roleRightsData?.data?.roleRightId,
      pageName: roleRightsData?.data?.pageName,
      fullControl: roleRightsData?.data?.fullControl || false,
      canView: roleRightsData?.data?.canView || false,
      canEdit: roleRightsData?.data?.canEdit || false,
      canDelete: roleRightsData?.data?.canDelete || false,
    },
  ];
  const { data: rolesData } = useGetRequest('/api/Roles/GetAllRoles');
  // const { data: rightsData } = useGetRequest('/api/Rights/GetAllRights');
  // const { data: pagesData } = useGetRequest('/api/AppPages/GetAllPages');
  const { data: menuData } = useGetRequest('/api/AppMenus/GetAllMenus');
  const [data, setData] = useState([]);
  const { assignPermissionScehema } = useValidationSchema();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const navigate = useNavigate();
  const createOptions = (data, labelKey, valueKey) =>
    data?.data?.map((item) => ({
      label: item[labelKey],
      value: item[valueKey],
    }));

  const createRoleOptions = (data, labelKey, valueKey) =>
    data?.map((item) => ({
      label: item[labelKey],
      value: item[valueKey],
    }));
  const roleOptions = createRoleOptions(rolesData, 'roleName', 'roleId');
  const menuOptions = createOptions(menuData, 'menuName', 'menuId');

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      role: null,
      right: null,
      page: null,
      menu: null,
    },
    validationSchema: assignPermissionScehema,
    onSubmit: ({ role, right, page, menu }) => {
      setLoading(true);
      const paylaod = {
        roleRightId: roleRightsData?.data?.roleRightId,
        roleId: role,
        menuId: menu,
        pageId: page,
        tenantId: 0,
        fullControl: data[0]?.fullControl,
        canEdit: data[0]?.canEdit,
        canAdd: data[0]?.canAdd,
        canView: data[0]?.canView,
        canDelete: data[0]?.canDelete,
      };
      putRequest(update_role_rights, paylaod)
        .then((res) => {
          if (res?.success) {
            refetchRoleRightsByid();
            formik.resetForm();
            navigate(`/role-management?active=4`);
            toast.success(res.message);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => toast.error('Something went wrong'))
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCheckboxChange = (key, permission, checked) => {
    const updatedData = data?.map((item) => {
      if (item.key === key) {
        if (permission === 'fullControl') {
          return {
            ...item,
            fullControl: checked,
            // canSearch: checked,
            canView: checked,
            canEdit: checked,
            canDelete: checked,
            canAdd: checked,
            // canPrint: checked,
          };
        } else {
          const updatedItem = { ...item, [permission]: checked };

          if (permission === 'canView' && !checked) {
            updatedItem.canEdit = false;
            updatedItem.canDelete = false;
            updatedItem.canAdd = false;
          }

          const allChecked =
            updatedItem.canView &&
            updatedItem.canEdit &&
            updatedItem.canDelete &&
            updatedItem.canAdd;

          return {
            ...updatedItem,
            fullControl: allChecked,
            canView:
              permission === 'canDelete' ||
              permission === 'canEdit' ||
              permission === 'canAdd'
                ? checked || updatedItem.canView
                : updatedItem.canView,
          };
        }
      }
      return item;
    });
    setData(updatedData);
  };
  const headers = [
    { title: 'Page Name', dataIndex: 'pageName' },
    { title: 'Full Control', dataIndex: 'fullControl' },
    { title: 'Can View', dataIndex: 'canView' },
    { title: 'Can Add', dataIndex: 'canAdd' },
    { title: 'Can Edit', dataIndex: 'canEdit' },
    { title: 'Can Delete', dataIndex: 'canDelete' },
  ];
  // const headers = [{ title: 'Role Name', dataIndex: 'roleName' }];

  // rightOptions?.forEach((right) => {
  //   headers.push({ title: right.label, dataIndex: right.value });
  // });

  const columns = headers.map((header) => ({
    title: (
      <span
        className={
          header.dataIndex !== 'pageName'
            ? 'flex items-center justify-center whitespace-nowrap'
            : ''
        }
      >
        {header.title}
      </span>
    ),
    dataIndex: header.dataIndex,
    render: (text, record) =>
      header.dataIndex !== 'pageName' ? (
        <span className="flex justify-center items-center">
          <Checkbox
            type="checkbox"
            checked={record[header.dataIndex]}
            onChange={(e) =>
              handleCheckboxChange(
                record.key,
                header.dataIndex,
                e.target.checked
              )
            }
          />
        </span>
      ) : (
        record[header.dataIndex]
      ),
  }));

  useEffect(() => {
    if (id) {
      refetchRoleRightsByid();
    }
  }, [id]);

  useEffect(() => {
    if (roleRightsData) {
      formik.setValues({
        role: roleRightsData?.data?.roleId,
        right: null,
        page: roleRightsData?.data?.pageId,
        menu: roleRightsData?.data?.menuId,
      });
    }
  }, [roleRightsData]);
  useEffect(() => {
    if (roleRightsData) {
      setData([
        {
          roleRightId: roleRightsData?.data?.roleRightId,
          pageName: roleRightsData?.data?.pageName,
          fullControl: roleRightsData?.data?.fullControl || false,
          canView: roleRightsData?.data?.canView || false,
          canAdd: roleRightsData?.data?.canAdd || false,
          canEdit: roleRightsData?.data?.canEdit || false,
          canDelete: roleRightsData?.data?.canDelete || false,
        },
      ]);
    }
  }, [roleRightsData, id]);

  return (
    <Layout>
      <>
        <div className=" mb-[30px] ">
          <Title
            level={4}
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
              fontFamily: 'inter-bold',
            }}
          >
            Assign Permission
          </Title>
          <div className="flex justify-between items-center ">
            <div>
              <Breadcrumb
                separator="/"
                items={[
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <GoHome />
                          <span
                            className="cursor-pointer font-reg"
                            onClick={() => {
                              navigate('/dashboard');
                            }}
                          >
                            Dashboard
                          </span>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <HiOutlineDocumentText />
                          <span
                            className="cursor-pointer font-reg"
                            onClick={() => {
                              navigate(`/role-management?active=4`);
                            }}
                          >
                            Role Management
                          </span>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <MdModeEditOutline
                            style={{
                              color: themeMode === 'light' ? colorTheme : '',
                            }}
                          />
                          <span
                            className=" font-bold"
                            style={{
                              color: themeMode === 'light' ? colorTheme : '',
                            }}
                          >
                            Assign Permission
                          </span>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex justify-end">
            <PrimaryButton
              title="Save"
              icon={<BiSave className=" w-[17px] h-[17px]" />}
              htmlType="submit"
              loading={loading}
            />
          </div>
          <LabelGrid
            label="Role Permissions"
            className=" rounded-[5px] px-20 py-20"
          >
            <div className="grid grid-cols-12 sm:gap-x-[30px]  gap-y-[25px] sm:gap-y-0 ">
              <div className="col-span-12 sm:col-span-4">
                <SelectField
                  formik={formik}
                  id={'role'}
                  label={'Role'}
                  optionId={'value'}
                  optionName={'label'}
                  optionData={roleOptions}
                  notModal={true}
                />
              </div>
              <div className="  col-span-12 sm:col-span-4">
                <SelectField
                  formik={formik}
                  id={'menu'}
                  label={'Menu'}
                  optionId={'value'}
                  optionName={'label'}
                  optionData={menuOptions}
                  notModal={true}
                />
              </div>
            </div>
          </LabelGrid>
        </form>
        <div className="mt-[40px]">
          <Table
            dataSource={data}
            columns={columns}
            loading={isLoading}
            size="small"
            scroll={{ x: true }}
            pagination={false}
            bordered
            className={`${
              themeMode === 'light' ? 'light-table' : 'dark-table'
            }`}
          />
        </div>
      </>
    </Layout>
  );
};

export default AssignPermission;
