import { ClockCircleOutlined } from '@ant-design/icons';
import {
  GoogleMap,
  Marker,
  Polyline,
  useLoadScript,
} from '@react-google-maps/api';
import { Timeline, Typography } from 'antd';
import 'components/HelperStyle/PlaybackSeekbar.css';
import useAxiosClientTMS from 'config/useAxiosClientTMS';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Layout from 'layout/AdminLayout';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DATE_FORMATS, mapStyles } from 'utils/constant';
import { dayjsFormat } from 'utils/helper';
import { GET_LOADBYIDASYNC_KEY } from 'utils/queryKey';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

const { Text } = Typography;
const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: 'calc(100vh - 120px)',
  borderRadius: '5px',
};
const center = {
  lat: 31.468854, // default latitude
  lng: 74.451038, // default longitude
};
const FleetViewDetail = () => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { trackingData } = useSelector((state) => state?.nonPersistedStates);
  console.log('themeMode:', themeMode);
  console.log('colorTheme:', colorTheme); // Check if this is a valid color value
  console.log('trackingData:', trackingData);
  const { id } = useParams();
  const { useGetRequest } = useAxiosClientTMS();
  const mapRef = useRef();
  const playerRef = useRef(null);
  const sliderRef = useRef(0);
  const indexRef = useRef(0);
  const progressRef = useRef(0);
  const rotationAngle = useRef(0);
  const speedRef = useRef(1);
  const isAnimatingRef = useRef(false);
  const mapRefMarker = useRef();
  const firstCycleIndex = useRef(false);
  const copyTrackingData = useRef([]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });
  const [mapsPoints, setMapsPoints] = useState([]);
  const {
    data: loadByIdData,
    isLoading: loadByIdDataLoading,
    refetch: loadByIdRefetch,
  } = useGetRequest(
    `/api/GetLoadByIdAsync?id=${id}`,
    { refetchOnMount: 'always' },
    GET_LOADBYIDASYNC_KEY
  );

  const timeLineItems = loadByIdData?.data?.loadPoints?.map((point, index) => ({
    dot: (
      <ClockCircleOutlined
        style={{
          fontSize: '12px',
        }}
      />
    ),
    color: 'green',
    children: (
      <>
        <div>
          <Text className="text-[11px] font-[600] font-med ">
            {point?.pointType} (
            <span className="text-[#0000009c]">
              {loadByIdData?.data?.loadStatus}
            </span>
            )
          </Text>
        </div>

        <div className="flex gap-10 font-med ">
          <Text className="text-[10px] font-[500] font-med">Distance:</Text>
          <Text className="text-[10px] font-[500] font-med">
            {point?.distance}
          </Text>
        </div>
        <div className="flex gap-10 font-med ">
          <Text className="text-[10px] font-[500] font-med">
            {point?.packets}
          </Text>
        </div>
        <div className="flex gap-10">
          <Text className="text-[10px] font-[500] font-med">From:</Text>
          <Text className="text-[10px] font-[500] font-med">
            <p className=" font-[600] !mb-0">{point?.location}</p>
          </Text>
        </div>
        <div className="flex gap-10 mb-4">
          <Text className="text-[10px] font-[500] font-med">Date & Time:</Text>
          <Text className="text-[10px] font-[500] font-med">
            {dayjsFormat(point?.scheduleDate, DATE_FORMATS.DATE_TIME)}
          </Text>
        </div>
      </>
    ),
  }));

  const moveMarker = (position) => {
    if (mapRefMarker.current) {
      mapRefMarker.current.setPosition(position);
      mapRefMarker.current.setIcon({
        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 4,
        rotation: Number(rotationAngle.current || 0),
        fillColor: 'red',
        fillOpacity: 5,
        strokeWeight: 1,
        strokeColor: '#fff',
      });
    }
  };

  const interpolate = (start, end, factor) => {
    return {
      lat: start.lat + (end.lat - start.lat) * factor,
      lng: start.lng + (end.lng - start.lng) * factor,
    };
  };
  const calculateBearing = (start, end) => {
    const lat1 = (start.lat * Math.PI) / 180;
    const lng1 = (start.lng * Math.PI) / 180;
    const lat2 = (end.lat * Math.PI) / 180;
    const lng2 = (end.lng * Math.PI) / 180;

    const dLng = lng2 - lng1;
    const y = Math.sin(dLng) * Math.cos(lat2);
    const x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLng);

    const bearing = (Math.atan2(y, x) * 180) / Math.PI;
    return (bearing + 360) % 360;
  };
  const animate = () => {
    const currentIndex = indexRef.current;
    const nextIndex = Math.min(currentIndex + 1, trackingData?.length - 1);

    if (nextIndex < trackingData?.length) {
      const startPoint = trackingData[currentIndex];
      const endPoint = trackingData[nextIndex];
      if (!startPoint || !endPoint) {
        console.warn('Invalid path points for interpolation.');
        isAnimatingRef.current = false;
        return;
      }

      // if (mapRef.current) {
      //   const map = mapRef.current;
      //   const bounds = map.getBounds();

      //   if (!bounds?.contains(endPoint)) {
      //     map.panTo(endPoint);
      //   }
      // }

      progressRef.current += 0.01 * speedRef.current;

      if (progressRef.current >= 1) {
        progressRef.current = 0;
        indexRef.current += 1;
        sliderRef.current += 1;
      } else {
        const interpolatedPosition = interpolate(
          startPoint,
          endPoint,
          progressRef.current
        );
        rotationAngle.current = calculateBearing(startPoint, endPoint);
        moveMarker(interpolatedPosition);
      }

      if (isAnimatingRef.current) {
        requestAnimationFrame(animate);
      }
      if (playerRef.current) {
        const sliderDOM = playerRef.current;
        const input = sliderDOM.querySelector('input');
        const playButton = sliderDOM.querySelector('button[title="Play"]');
        const stopButton = sliderDOM.querySelector('button[title="Pause"]');
        if (playButton && stopButton && isAnimatingRef.current) {
          playButton.setAttribute('style', 'display: none;');
          stopButton.setAttribute('style', 'display: block;');
        }
        if (input) {
          input.value = currentIndex;
        }
      }
    } else {
      isAnimatingRef.current = false;
      if (playerRef.current) {
        const sliderDOM = playerRef.current;
        const playButton = sliderDOM.querySelector('button[title="Play"]');
        const stopButton = sliderDOM.querySelector('button[title="Pause"]');
        if (playButton && stopButton && !isAnimatingRef.current) {
          playButton.setAttribute('style', 'display: block;');
          stopButton.setAttribute('style', 'display: none;');
        }
      }
    }
  };

  const startAnimation = () => {
    if (!isAnimatingRef.current) {
      isAnimatingRef.current = true;
      indexRef.current = firstCycleIndex.current
        ? Math.max((copyTrackingData.current?.length ?? 0) - 1, 0)
        : Math.max((trackingData?.length ?? 0) - 1, 0);
      progressRef.current = 0;
      firstCycleIndex.current = true;
      console.log('copyTrackingData.current', copyTrackingData.current);

      copyTrackingData.current = [...trackingData];
      console.log('copyTrackingData.current2', copyTrackingData.current);

      animate();
    }
  };

  const onLoadMarker = useCallback((marker) => {
    mapRefMarker.current = marker;
  }, []);

  const onLoad = useCallback(
    (map) => {
      mapRef.current = map;
      const bounds = new window.google.maps.LatLngBounds();
      mapsPoints?.forEach((item) => {
        if (item?.lat) {
          bounds.extend({
            lat: item?.lat,
            lng: item?.lng,
          });
        }
      });
      trackingData.forEach((point) => {
        if (point?.lat && point?.lng) {
          bounds.extend({ lat: point.lat, lng: point.lng });
        }
      });
      mapRef?.current?.fitBounds(bounds);
    },
    [mapsPoints, trackingData]
  );

  useEffect(() => {
    if (loadByIdData?.data?.loadPoints) {
      const points = loadByIdData?.data?.loadPoints.map((item) => ({
        lat: item?.latitude ?? 0,
        lng: item?.longitude ?? 0,
      }));
      setMapsPoints(points);
    }
  }, [loadByIdData]);

  useEffect(() => {
    if (!window.google) return;
    const bounds = new window.google.maps.LatLngBounds();
    mapsPoints?.forEach((item) => {
      if (item?.lat) {
        bounds.extend({
          lat: item?.lat,
          lng: item?.lng,
        });
      }
    });
    trackingData.forEach((point) => {
      if (point?.lat && point?.lng) {
        bounds.extend({ lat: point.lat, lng: point.lng });
      }
    });
    mapRef?.current?.fitBounds(bounds);
  }, [mapsPoints]);

  useEffect(() => {
    startAnimation();
  }, [trackingData]);
  console.log('mapsPoints', mapsPoints, loadByIdData?.data?.loadPoints);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  return (
    <Layout>
      <div className="lg:mx-[-30px]">
        <div
          className={`flex max-md:flex-wrap md:h-auto max-md:h-[500px] justify-between`}
        >
          <div className={`max-md:pt-4 max-md:w-full`}>
            <div
              className="overflow-y-auto overflow-x-hidden show-scroll bg-[#ffffff] mr-[5px]"
              style={{ maxHeight: 'calc(100vh - 120px)' }}
            >
              <div className="w-[350px] max-md:w-full">
                <div
                  className={`cursor-pointer border-solid border-[1px] border-r-0 border-t-0 border-l-0 border-[#d9d9d9] px-8 py-12 !text-[10px] hover:bg-[#f7f7fa]`}
                >
                  <div className="flex items-center">
                    <div className="grid gap-10">
                      <Text
                        style={{ color: colorTheme }}
                        className="text-[13px] font-med font-[600]"
                      >
                        Load Details
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="pl-1 mt-20">
                  <Timeline items={timeLineItems} />
                </div>
              </div>
            </div>
          </div>
          <div
            className={`max-md:h-[50%] md:h-[100%] md:mx-0 mx-3 max-md:pt-10`}
            style={{ width: '100%', height: '100%' }}
          >
            {isLoaded ? (
              <>
                <div>
                  <GoogleMap
                    zoom={12}
                    mapContainerStyle={{
                      width: '100%',
                      height: 'calc(100vh - 120px)',
                      borderRadius: '10px',
                    }}
                    options={{
                      minZoom: 1,
                      zoomControl: true,
                      zoomControlOptions: {
                        position:
                          window.google.maps.ControlPosition.LEFT_BOTTOM,
                      },
                      mapTypeControl: true,
                      streetViewControl: true,
                      fullscreenControl: false,
                      fullscreenControlOptions: {
                        position:
                          window.google.maps.ControlPosition.RIGHT_BOTTOM,
                      },
                      mapTypeControlOptions: {
                        position: window.google.maps.ControlPosition.RIGHT_TOP,
                      },
                      styles: mapStyles,
                      disableDefaultUI: true,
                    }}
                    onLoad={onLoad}
                  >
                    <Polyline
                      path={trackingData || []}
                      options={{
                        preserveViewport: true,
                        strokeColor: '#113D4E',
                        strokeWeight: 4,
                        strokeOpacity: 1,
                      }}
                    />
                    <Marker
                      onLoad={onLoadMarker}
                      position={trackingData[indexRef?.current]}
                      icon={{
                        path: window.google.maps.SymbolPath
                          .FORWARD_CLOSED_ARROW,
                        scale: 4,
                        rotation:
                          calculateBearing(
                            trackingData[indexRef?.current] || {
                              lat: 0,
                              lon: 0,
                            },
                            trackingData[indexRef?.current + 1] || {
                              lat: 0,
                              lon: 0,
                            }
                          ) || 0,
                        fillColor: 'red',
                        fillOpacity: 3,
                        strokeWeight: 0.5,
                        strokeColor: '#fff',
                      }}
                    />
                    {mapsPoints?.map((item) => (
                      <Marker
                        position={item}
                        icon={{
                          fillColor: 'green',
                          fillOpacity: 3,
                          strokeWeight: 0.5,
                          strokeColor: '#fff',
                        }}
                      />
                    ))}
                  </GoogleMap>
                </div>
              </>
            ) : (
              <>
                <div className="w-full flex items-center justify-center">
                  Loading
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FleetViewDetail;
