import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { Button, Modal, Tag } from 'antd';
import Loading from 'components/Loading';
import truckIcon from 'images/delivery-truck.svg';
import { FaArrowRightLong } from 'react-icons/fa6';
const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: 'calc(100vh - 350px)',
  borderRadius: '5px',
};

const center = { lat: 37.7749, lng: -122.4194 };

const MapModal = ({ isMapModalOpen, setMapModalOpen, formik, innerFormik }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDLGitEALPVLPoPzj7XTq2uA3Us-QutdiM',
    libraries,
  });
  if (loadError) {
    return <div>Error loading maps</div>;
  }
  const handleCancel = () => {
    setMapModalOpen(false);
  };

  return (
    <>
      <Modal
        open={isMapModalOpen}
        title={<span className=" text-[18px]">Map</span>}
        width={1000}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <>
          <div className=" mt-[20px]">
            <div className=" flex items-center flex-wrap">
              <div className=" flex items-center">
                <Tag color="#008000">{formik.values.pickupAddress}</Tag>
                <div className=" mb-[15px]">
                  <div className=" text-[10px]">120 m</div>
                  <div className="flex justify-center ">
                    <FaArrowRightLong />
                  </div>
                </div>
              </div>
              {innerFormik?.values?.stop?.map((items, index) => {
                return (
                  items?.stopAddress && (
                    <div key={index} className="flex items-center ml-[10px]">
                      <Tag color="#899499">{items?.stopAddress}</Tag>
                      <div className="mb-[15px]">
                        <div className="text-[10px]">120 m</div>
                        <div className="flex justify-center">
                          <FaArrowRightLong />
                        </div>
                      </div>
                    </div>
                  )
                );
              })}

              <div className="ml-[10px]">
                <Tag color="#ff0000">{formik.values.deliveryAddress}</Tag>
              </div>
            </div>
            <div className=" mt-[40px]">
              {isLoaded ? (
                <>
                  <div>
                    <GoogleMap
                      className="rounded-sm"
                      mapContainerStyle={mapContainerStyle}
                      zoom={10}
                      center={center}
                    >
                      <Marker
                        // position={markerPosition}
                        // ref={markerRef}
                        icon={{
                          url: truckIcon,
                          scaledSize: new window.google.maps.Size(30, 30),
                          origin: new window.google.maps.Point(0, 0),
                          anchor: new window.google.maps.Point(25, 25),
                          rotation: 215,
                        }}
                      />
                    </GoogleMap>
                  </div>
                </>
              ) : (
                <>
                  <div className="w-full flex items-center justify-center py-[80px]">
                    <Loading />
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default MapModal;
