import { Avatar, Breadcrumb, Image, Spin, Tabs, Typography } from 'antd';
import Dropdown from 'components/Dropdown/index';
import PopConfirm from 'components/PopConfirm';

import { QuestionCircleOutlined, UserOutlined } from '@ant-design/icons';
import useAxiosClientTMS from 'config/useAxiosClientTMS';
import Layout from 'layout/AdminLayout';
import { useEffect, useState } from 'react';
import { BsThreeDots, BsThreeDotsVertical } from 'react-icons/bs';
import { FiUser } from 'react-icons/fi';
import { GoHome } from 'react-icons/go';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { PiSteeringWheel, PiTruckLight } from 'react-icons/pi';

import Status from 'components/Status';
import Table from 'components/Table';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { BiSolidUserDetail } from 'react-icons/bi';
import { TbJumpRope } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { convertDateFormat } from 'utils/helper';
import { GET_DRIVERS_BY_ID_KEY } from 'utils/queryKey';
import DriverDocuments from './DriverDocuments';
const { Title } = Typography;

const Details = () => {
  const { useGetRequest, deleteRequest } = useAxiosClientTMS();
  // const {
  //   Tenant_Management_Add,
  //   Tenant_Management_Edit,
  //   Tenant_Management_Delete,
  // } = usePermissionVariables();

  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  // States
  const [openAddUserModal, setOpenUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({});
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });

  const { id } = useParams();
  const {
    data: driverData,
    refetch,
    isLoading: driverLoading,
  } = useGetRequest(`api/GetDriverById?id=${id}`, {}, GET_DRIVERS_BY_ID_KEY);

  const columns = [
    {
      title: (
        <div className="flex justify-between items-center">Incidents Type</div>
      ),
      dataIndex: 'incidentsType',
      ellipsis: true,
      sorter: (a, b) => a.incidentsType.localeCompare(b.incidentsType),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'incidentsType'
          ? sortOrder.order
          : 'ascend',
      showSorterTooltip: false,

      render: (text, record) => {
        return <span>{`${record?.incidentsType ?? ''}`}</span>;
      },
    },
    {
      title: 'Incidents Date',
      dataIndex: 'incidentsDate',
      ellipsis: true,
      align: 'center',
      render: (text, record) => <>{text}</>,
    },
    {
      title: 'Time',
      dataIndex: 'time',
      ellipsis: true,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: 'Incidents',
      dataIndex: 'incidents',
      ellipsis: true,
      align: 'center',
      render: (text, record) => <>{text}</>,
    },
    {
      title: 'Distance (KM)',
      dataIndex: 'distance',
      ellipsis: true,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      ellipsis: true,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: <span className="flex items-center justify-center">Action</span>,
      dataIndex: '',
      ellipsis: true,
      width: 80,
      render: (text, record) => (
        <div className="flex justify-center">
          <Dropdown
            items={[
              {
                key: '1',
                label: (
                  <div
                    className="p-[2px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                ),
                onClick: () => {
                  navigate(`/edit-driver/${id}`);
                },
              },
              {
                key: '2',
                label: (
                  <div
                    className="p-[2px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this task?"
                      placement="left"
                      onClick={() => {}}
                      icon={
                        <div className="flex items-center justify-center">
                          <QuestionCircleOutlined
                            style={{ color: 'red', marginTop: '5px' }}
                          />
                        </div>
                      }
                    >
                      <MdDeleteOutline className="w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                ),
              },
            ]}
          >
            <div className="!underline cursor-pointer hover:underline">
              <BsThreeDotsVertical className="w-[15px] h-[15px] cursor-pointer" />
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];

  const {
    firstName,
    lastName,
    phoneNumber,
    email,
    joiningDate,
    dateofBirth,
    nic,
    nationality,
    address,
    driverLicenseReponse,
    profilePicture,
    isActive: driverIsActive,
  } = driverData?.data || {};
  const { licenseNumber, issueDate, expiryDate, licenseType } =
    driverLicenseReponse || {};

  const items = [
    {
      key: '1',
      label: 'Trip History',
      children: (
        <>
          <Table
            data={[
              {
                id: '1',
                incidentsType: 'Distracted driving',
                incidentsDate: '2023-12-06',
                time: '18:13:44',
                incidents: '5',
                distance: '120 km',
                duration: '01h:30m',
              },
              {
                id: '2',
                incidentsType: 'Distracted driving',
                incidentsDate: '2023-12-07',
                time: '16:15:14',
                incidents: '3',
                distance: '90 km',
                duration: '01h:05m',
              },
            ]}
            columns={columns}
            pageNumber={pageNumber}
            setTotalRecord={setTotalRecord}
            rowsPerPage={rowsPerPage}
            totalRecord={totalRecord}
            setPageNumber={setPageNumber}
            setRowsPerPage={setRowsPerPage}
            setSortOrder={setSortOrder}
            setFilters={setFilters}
          />
        </>
      ),
    },
    {
      key: '2',
      label: 'Documents',
      children: <DriverDocuments />,
    },
  ];

  const onChange = (key) => {};

  useEffect(() => {
    if (driverData) {
      setTotalRecord(driverData?.data?.length);
    }
  }, [driverData]);

  return (
    <Layout>
      <>
        <div>
          <div className=" mb-[30px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
              }}
            >
              TMS
            </Title>
            <div className="flex justify-between items-center ">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className="flex items-center space-x-4 ">
                            <PiSteeringWheel
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />

                            <span
                              className="cursor-pointer font-reg text-[13px]"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                              onClick={() => {
                                navigate('/drivers');
                              }}
                            >
                              Driver Management
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className="flex items-center space-x-4 ">
                            <BiSolidUserDetail
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />

                            <span
                              className="font-bold text-[13px]"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              Driver Details
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <Spin spinning={driverLoading}>
            <div
              className={`flex justify-between max-sm:justify-center lg:gap-70 gap-10 max-lg:flex-wrap ${
                themeMode === 'light' ? 'bg-[#f1f1f14f]' : 'bg-[#121212]'
              }  p-30 rounded-sm font-reg`}
            >
              <div className="flex gap-40 max-md:flex-wrap max-md:justify-center">
                {/* <div> */}
                <div className={`w-[200px]`}>
                  {profilePicture ? (
                    <Image
                      style={{ borderRadius: '50%' }}
                      width={200}
                      fileType={'circle-image'}
                      preview={profilePicture ? true : false}
                      src={`${
                        profilePicture
                          ? process.env.REACT_APP_IMAGE_URL + profilePicture
                          : null
                      }`}
                      alt="No profile picture"
                    />
                  ) : (
                    <Avatar shape="square" size={200} icon={<UserOutlined />} />
                  )}
                </div>
                {/* </div> */}
                <div>
                  <div
                    className={`text-[20px] font-bold ${
                      themeMode === 'light' ? 'text-[#212532]' : 'text-[#fff]'
                    }`}
                  >
                    {`${firstName ?? ''} ${lastName ?? ''}`}
                  </div>
                  <div
                    className={`flex gap-10 ${
                      themeMode === 'light' ? 'text-[#888C99]' : 'text-[#fff]'
                    } text-[13px]`}
                  >
                    <div className="flex items-center gap-10">
                      <FiUser />
                      CT01
                    </div>
                    <div className="flex items-center gap-10">
                      <PiTruckLight />
                      LC72YKK
                    </div>
                  </div>
                  <div className="mt-15 lg:w-[350px] md:lg:w-[300px] text-[12px]">
                    <div className="grid grid-cols-2 leading-loose">
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? 'text-[#888C99]'
                            : 'text-[#fff]'
                        }`}
                      >
                        Date of Birth
                      </span>
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? ' text-[#1B202C]'
                            : 'text-[#fff]'
                        }`}
                      >
                        {dateofBirth ?? '--'}
                      </span>
                    </div>
                    <hr />
                    <div className="grid grid-cols-2 leading-loose">
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? 'text-[#888C99]'
                            : 'text-[#fff]'
                        }`}
                      >
                        Nationality
                      </span>
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? ' text-[#1B202C]'
                            : 'text-[#fff]'
                        }`}
                      >
                        {nationality ?? '--'}
                      </span>
                    </div>
                    <hr />
                    <div className="grid grid-cols-2 leading-loose">
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? 'text-[#888C99]'
                            : 'text-[#fff]'
                        }`}
                      >
                        National ID
                      </span>
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? ' text-[#1B202C]'
                            : 'text-[#fff]'
                        }`}
                      >
                        {nic ?? '--'}
                      </span>
                    </div>
                    <hr />
                    <div className="grid grid-cols-2 leading-loose">
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? 'text-[#888C99]'
                            : 'text-[#fff]'
                        }`}
                      >
                        Phone Number
                      </span>
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? ' text-[#1B202C]'
                            : 'text-[#fff]'
                        }`}
                      >
                        {`${phoneNumber ?? '--'} `}
                      </span>
                    </div>
                    <hr />
                    <div className="grid grid-cols-2 leading-loose">
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? 'text-[#888C99]'
                            : 'text-[#fff]'
                        }`}
                      >
                        Email Address
                      </span>
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? ' text-[#1B202C]'
                            : 'text-[#fff]'
                        }`}
                      >
                        {`${email ?? '--'} `}
                      </span>
                    </div>
                    <hr />
                    <div className="grid grid-cols-2 leading-loose">
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? 'text-[#888C99]'
                            : 'text-[#fff]'
                        }`}
                      >
                        Address
                      </span>
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? ' text-[#1B202C]'
                            : 'text-[#fff]'
                        }`}
                      >
                        {`${address ?? '--'} `}
                      </span>
                    </div>
                    <hr />
                    <div className="grid grid-cols-2 leading-loose">
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? 'text-[#888C99]'
                            : 'text-[#fff]'
                        }`}
                      >
                        Join Date
                      </span>
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? ' text-[#1B202C]'
                            : 'text-[#fff]'
                        }`}
                      >
                        {`${
                          joiningDate ? convertDateFormat(joiningDate) : '--'
                        } `}
                      </span>
                    </div>
                    <hr />
                    <div className="grid grid-cols-2 leading-loose">
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? 'text-[#888C99]'
                            : 'text-[#fff]'
                        }`}
                      >
                        Licence Number
                      </span>
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? ' text-[#1B202C]'
                            : 'text-[#fff]'
                        }`}
                      >
                        {`${licenseNumber ?? '--'} `}
                      </span>
                    </div>
                    <hr />
                    <div className="grid grid-cols-2 leading-loose">
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? 'text-[#888C99]'
                            : 'text-[#fff]'
                        }`}
                      >
                        Licence Type
                      </span>
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? ' text-[#1B202C]'
                            : 'text-[#fff]'
                        }`}
                      >
                        {`${licenseType ?? '--'} `}
                      </span>
                    </div>
                    <hr />
                    <div className="grid grid-cols-2 leading-loose">
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? 'text-[#888C99]'
                            : 'text-[#fff]'
                        }`}
                      >
                        Issue Date
                      </span>
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? ' text-[#1B202C]'
                            : 'text-[#fff]'
                        }`}
                      >
                        {`${issueDate ? convertDateFormat(issueDate) : '--'} `}
                      </span>
                    </div>
                    <hr />
                    <div className="grid grid-cols-2 leading-loose">
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? 'text-[#888C99]'
                            : 'text-[#fff]'
                        }`}
                      >
                        Expiry Date
                      </span>
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? ' text-[#1B202C]'
                            : 'text-[#fff]'
                        }`}
                      >
                        {`${expiryDate ? convertDateFormat(expiryDate) : '--'}`}
                      </span>
                    </div>
                    <hr />
                    <div className="grid grid-cols-2 leading-loose">
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? 'text-[#888C99]'
                            : 'text-[#fff]'
                        }`}
                      >
                        Status
                      </span>
                      <span
                        className={`col-span-1 ${
                          themeMode === 'light'
                            ? ' text-[#1B202C]'
                            : 'text-[#fff]'
                        } flex items-center`}
                      >
                        <Status
                          className="h-16 flex items-center"
                          tag={driverIsActive}
                        />
                      </span>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
              <div className="lg:mt-60 max-sm:w-full">
                <div
                  className={`flex items-center gap-5 ${
                    themeMode === 'light' ? 'bg-[#98979c14]' : 'bg-[#ffffff14]'
                  } sm:w-[150px] max-sm:shrink h-30 m-5 px-6 pt-4 ${
                    themeMode === 'light' ? 'text-[#575b65]' : 'text-[#f1f1f1]'
                  }`}
                >
                  <TbJumpRope /> 150
                </div>
                <div
                  className={`flex items-center gap-5 ${
                    themeMode === 'light' ? 'bg-[#98979c14]' : 'bg-[#ffffff14]'
                  } sm:w-[150px] max-sm:shrink h-30 m-5 px-6 pt-4 text-[#FD6F52]`}
                >
                  <AiOutlineExclamationCircle />
                  141
                </div>
                <div
                  className={`flex items-center gap-5 ${
                    themeMode === 'light' ? 'bg-[#98979c14]' : 'bg-[#ffffff14]'
                  } sm:w-[150px] max-sm:shrink h-30 m-5 px-6 pt-4 text-[#46c095]`}
                >
                  <TbJumpRope />
                  66
                </div>
              </div>
            </div>
          </Spin>
        </div>
        <div className=" mt-[20px]">
          <Tabs
            defaultActiveKey="1"
            items={items}
            type="card"
            onChange={onChange}
            moreIcon={
              <span>
                <BsThreeDots
                  style={{
                    color: themeMode === 'light' ? colorTheme : '#939BC9',
                  }}
                />
              </span>
            }
            size="small"
          />
        </div>
        <style jsx>
          {`
            .ant-image-mask {
              border-radius: 50%;
            }
          `}
        </style>
      </>
    </Layout>
  );
};
export default Details;
