import { Avatar, Divider, Progress, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import LineChartWithShadow from './SimpleLineChart';

const VehicleDashboard = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  return (
    <>
      <div
        className=" md:flex rounded-sm py-4 shadow-md"
        style={{
          backgroundColor: themeMode === 'light' ? '#f7f7fa' : '#121212',
        }}
      >
        <div className=" flex  w-full ">
          <div className=" p-10">
            <Avatar
              style={{
                width: '100px',
                height: '100px',
              }}
              src={<img src={'/images/images/driver.jpg'} alt="avatar" />}
            />
          </div>
          <div className=" flex flex-col justify-evenly">
            <div
              className=" font-bold"
              style={{
                color: themeMode === 'light' ? '' : '#9FA8DA',
              }}
            >
              Daved baker
            </div>
            <div className=" text-[13px]">
              <div>
                <span
                  className="font-semiBold "
                  style={{
                    color: themeMode === 'light' ? '' : '#9FA8DA',
                  }}
                >
                  Group :
                </span>
                WA
              </div>
              <div>
                <span
                  className="font-semiBold "
                  style={{
                    color: themeMode === 'light' ? '' : '#9FA8DA',
                  }}
                >
                  User :
                </span>
                DAVID
              </div>
            </div>
            <div className=" flex">
              <div>
                <Tag color="magenta">Cold Transport</Tag>
              </div>
              <div>
                <Tag color="magenta">Petrol</Tag>
              </div>
            </div>
          </div>
        </div>
        <Divider
          type={isMobile ? 'horizontal' : 'vertical'}
          style={{
            borderColor: '',
            height: isMobile ? '' : '120px',
          }}
        />
        <div className=" w-full px-[20px] py-[15px] mt-[16px]">
          <div className=" flex w-full justify-between text-[13px] space-x-20">
            <div className="w-full">
              <span
                className="font-semiBold"
                style={{
                  color: themeMode === 'light' ? '' : '#9FA8DA',
                }}
              >
                Phone :
              </span>
              030000000
            </div>
            <div className="w-full ">
              <span
                className="font-semiBold "
                style={{
                  color: themeMode === 'light' ? '' : '#9FA8DA',
                }}
              >
                ODD :
              </span>
              69km
            </div>
          </div>
          <div className=" flex w-full justify-between text-[13px] space-x-20">
            <div className="w-full">
              <span
                className="font-semiBold "
                style={{
                  color: themeMode === 'light' ? '' : '#9FA8DA',
                }}
              >
                Email :
              </span>
              driver@gmail.com
            </div>
            <div className="w-full">
              <span
                className="font-semiBold "
                style={{
                  color: themeMode === 'light' ? '' : '#9FA8DA',
                }}
              >
                Runtime :
              </span>
              2hr 43 min
            </div>
          </div>
          <div className=" flex w-full justify-between text-[13px] space-x-20">
            <div className="w-full">
              <span
                className="font-semiBold "
                style={{
                  color: themeMode === 'light' ? '' : '#9FA8DA',
                }}
              >
                License No. :
              </span>
              RYUEH45345yt
            </div>
            <div className="w-full">
              <span
                className="font-semiBold "
                style={{
                  color: themeMode === 'light' ? '' : '#9FA8DA',
                }}
              >
                Speed :
              </span>
              69 km/hr
            </div>
          </div>
        </div>
        <Divider
          type={isMobile ? 'horizontal' : 'vertical'}
          style={{
            borderColor: '',
            height: isMobile ? '' : '120px',
          }}
        />
        <div className=" w-full  px-[20px] mt-[18px]">
          <div className=" text-[13px] ">
            <div className=" font-semiBold -mb-5">NF3444HJ454356</div>
            <div>Engine Number</div>
          </div>
          <div className=" text-[13px] mt-[7px]">
            <div className=" font-semiBold -mb-5">NF3444HJ454356</div>
            <div>Chassis Number</div>
          </div>
        </div>
      </div>
      <div className=" mt-[20px] md:flex md:space-x-10 space-y-10 md:space-y-0">
        <div className=" md:w-[40%] w-full">
          <LineChartWithShadow />
        </div>
        <div
          className=" md:w-[60%] w-full flex  p-20 rounded-sm shadow-md"
          style={{
            backgroundColor: themeMode === 'light' ? '#f7f7fa' : '#121212',
          }}
        >
          <div className=" w-full space-y-20">
            <div
              className=" text-[14px] font-bold"
              style={{
                color: themeMode === 'light' ? '' : '#9FA8DA',
              }}
            >
              Reminders
            </div>
            <div className=" space-y-8">
              <div className=" text-[14px] font-semiBold">
                Services Reminders
              </div>
              <div className=" grid grid-cols-12 gap-y-10">
                <div
                  className=" col-span-6  p-4"
                  style={{
                    borderLeft:
                      themeMode === 'light'
                        ? `4px solid ${colorTheme}`
                        : '4px solid #9FA8DA',
                  }}
                >
                  <div
                    className=" text-[13px] font-semiBold "
                    style={{
                      color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                    }}
                  >
                    155 000Km
                  </div>
                  <div className=" text-sm">15/11/2021</div>
                </div>
                <div
                  className=" col-span-6  p-4"
                  style={{
                    borderLeft:
                      themeMode === 'light'
                        ? `4px solid ${colorTheme}`
                        : '4px solid #9FA8DA',
                  }}
                >
                  <div
                    className=" text-[13px] font-semiBold "
                    style={{
                      color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                    }}
                  >
                    155 000Km
                  </div>
                  <div className=" text-sm">15/11/2021</div>
                </div>
                <div
                  className=" col-span-6  p-4"
                  style={{
                    borderLeft:
                      themeMode === 'light'
                        ? `4px solid ${colorTheme}`
                        : '4px solid #9FA8DA',
                  }}
                >
                  <div
                    className=" text-[13px] font-semiBold "
                    style={{
                      color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                    }}
                  >
                    155 000Km
                  </div>
                  <div className=" text-sm">15/11/2021</div>
                </div>
                <div
                  className=" col-span-6  p-4"
                  style={{
                    borderLeft:
                      themeMode === 'light'
                        ? `4px solid ${colorTheme}`
                        : '4px solid #9FA8DA',
                  }}
                >
                  <div
                    className=" text-[13px] font-semiBold "
                    style={{
                      color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                    }}
                  >
                    155 000Km
                  </div>
                  <div className=" text-sm">15/11/2021</div>
                </div>
              </div>
            </div>
          </div>
          <div className=" w-full space-y-40">
            <div
              className=" text-[14px] font-bold"
              style={{
                color: themeMode === 'light' ? '' : '#9FA8DA',
              }}
            >
              Services
            </div>
            <div className=" flex space-x-30 items-center">
              <div>
                <Progress
                  type="circle"
                  percent={70}
                  format={(percent) => (
                    <div
                      className={`${
                        themeMode === 'dark' ? 'text-[#f6f4f3]' : colorTheme
                      } text-center text-[12px] space-y-5`}
                    >
                      <div>Check-In</div>
                    </div>
                  )}
                  strokeColor={`${themeMode == 'dark' ? 'white' : colorTheme}`}
                  size={100}
                  strokeWidth={10}
                />
              </div>
              <div>
                <div>Last Check-in </div>
                <div>
                  was at
                  <span
                    className=" ml-[3px] font-semiBold text-[16px]"
                    style={{
                      color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                    }}
                  >
                    1225km
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VehicleDashboard;
