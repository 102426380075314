import { QuestionCircleOutlined } from '@ant-design/icons';
import { usePermissionVariables } from 'Hooks/HasPermission';
import { Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SearchField from 'components/InputFields/SearchField';
import PopConfirm from 'components/PopConfirm';
import PopOver from 'components/PopOver';
import Status from 'components/Status';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import { delete_role, get_role_rights } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { getRowNumber } from 'utils/helper';
import AddRoleModal from './component/AddRoleModal';
import EditRoleModal from './component/EditRoleModal';
const { Title } = Typography;

const Role = () => {
  const queryClient = useQueryClient();
  const { useGetRequest, deleteRequest } = useApiClient();
  const { Role_View, Role_Add, Role_Edit, Role_Delete } =
    usePermissionVariables();
  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const [pageNumber, setPageNumber] = useState(1);
  const [openAddRoleModal, setOpenRoleModal] = useState(false);
  const [openEditRoleModal, setOpenEditRoleModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');

  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const { data, error, isLoading, refetch } = useGetRequest(
    `/api/Roles/GetAllRoles?pageNumber=${pageNumber}&pageSize=${rowsPerPage}&searchQuery=${search}`
  );
  const delHandler = (record) => {
    deleteRequest(`${delete_role}${record?.roleId}`)
      .then((res) => {
        if (res?.success) {
          const newTotalRecord = totalRecord - 1; // Decrement the total record count
          setTotalRecord(newTotalRecord); // Update the state

          // Check if we need to change the page number
          if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1); // Redirect to the previous page
          }
          refetch();
          ToastNotification('success', 'Role Deleted Successfully', themeMode);
          queryClient.invalidateQueries(get_role_rights);
        } else {
          ToastNotification('error', res?.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };
  const columns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      key: 'sr',
      ellipsis: true,
      width: 80,
      render: (text, row, index) =>
        getRowNumber(pageNumber, rowsPerPage, index),
    },
    {
      title: <div className="flex justify-between items-center">Role Name</div>,
      showSorterTooltip: false,

      ellipsis: true,
      dataIndex: 'roleName',
      sorter: (a, b) => a.roleName.localeCompare(b.roleName),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'roleName'
          ? sortOrder.order
          : 'ascend',

      render: (text, record) => (
        <>
          <span className="">{text}</span>
        </>
      ),
    },

    {
      title: 'User Type',
      dataIndex: 'userTypeName',
      ellipsis: true,
      render: (text, record) => (
        <span className="">
          <div className="truncate">{text}</div>
        </span>
      ),
    },

    {
      title: 'Description',
      dataIndex: 'description',
      className: 'max-w-[250px]',
      ellipsis: true,
      render: (text, record) => (
        <span className="">
          <PopOver content={text} title={'Description'} width={'40%'}>
            <div className="truncate">{text}</div>
          </PopOver>
        </span>
      ),
    },
    {
      title: <span className="flex items-center justify-center">Status</span>,
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      width: 300,
      render: (tag, record) => {
        return (
          <span>
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },
    ...(Role_Edit || Role_Delete
      ? [
          {
            title: (
              <span className="flex items-center justify-center">Action</span>
            ),
            dataIndex: '',
            ellipsis: true,
            width: 100,
            render: (text, record) => (
              <span className="flex justify-center items-center space-x-2">
                {Role_Edit && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                    onClick={() => {
                      setRowData(record);
                      setOpenEditRoleModal(true);
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                )}
                {Role_Delete && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this task?"
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={
                        <div className="flex items-center justify-center">
                          <QuestionCircleOutlined
                            style={{ color: 'red', marginTop: '5px' }}
                          />
                        </div>
                      }
                    >
                      <MdDeleteOutline className="w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                )}
              </span>
            ),
          },
        ]
      : []),
  ];
  useEffect(() => {
    if (data) {
      setTotalRecord(data?.totalRecords);
    }
  }, [data]);
  return (
    <>
      <div className="sm:flex space-y-[20px] md:space-y-0 justify-between items-center  mt-10 mb-26">
        <SearchField
          htmlFor="search"
          id="myInputID"
          name="search"
          placeholder={'Search by Role'}
          value={debouncedSearch}
          setDebouncedSearch={setDebouncedSearch}
          setPageNumber={setPageNumber}
          searchLoading={searchLoading}
          setSearchLoading={setSearchLoading}
          refetch={refetch}
          debouncedSearch={debouncedSearch}
          setSearch={setSearch}
        />
        {Role_Add && (
          <div className="sm:flex justify-end">
            <PrimaryButton
              title="Add Role"
              icon={<FaPlus />}
              onClick={() => {
                setOpenRoleModal(true);
              }}
            />
          </div>
        )}
      </div>
      <div className="">
        <Table
          data={data?.data || []}
          loading={isLoading}
          columns={columns}
          pageNumber={pageNumber}
          setTotalRecord={setTotalRecord}
          rowsPerPage={rowsPerPage}
          totalRecord={totalRecord}
          setPageNumber={setPageNumber}
          setRowsPerPage={setRowsPerPage}
          setSortOrder={setSortOrder}
          setFilters={setFilters}
        />
      </div>
      {openAddRoleModal && (
        <AddRoleModal
          setOpenRoleModal={setOpenRoleModal}
          openAddRoleModal={openAddRoleModal}
          refetch={refetch}
        />
      )}
      {openEditRoleModal && rowData && (
        <EditRoleModal
          setOpenEditRoleModal={setOpenEditRoleModal}
          openEditRoleModal={openEditRoleModal}
          record={rowData}
          refetch={refetch}
        />
      )}
    </>
  );
};
export default Role;
