import { Breadcrumb, Tabs, Typography } from 'antd';
import Layout from 'layout/AdminLayout';
import { BsThreeDots } from 'react-icons/bs';
import { GoHome } from 'react-icons/go';
import { MdAdminPanelSettings } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

import { usePermissionVariables } from 'Hooks/HasPermission';
import ProofOfDeliveryConfig from './components/ProofOfDeliveryConfig';
import ProofOfPickupConfig from './components/ProofOfPickupConfig';
import Rules from './components/Rules';
const { Title } = Typography;

const MasterSettings = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { Email_Template_View } = usePermissionVariables();
  const onChange = (key) => {};
  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  const items = [
    {
      key: '1',
      label: 'EcoDriving',
      children: '',
    },
    {
      key: '2',
      label: 'Proof of Pickup Config',
      children: <ProofOfPickupConfig />,
    },
    {
      key: '3',
      label: 'Proof of Delivery Config',
      children: <ProofOfDeliveryConfig />,
    },
    {
      key: '4',
      label: 'Rules',
      children: <Rules />,
    },
    {
      key: '5',
      label: 'Route Optimization',
      children: '',
    },
    {
      key: '6',
      label: 'Geofencing',
      children: '',
    },
    {
      key: '7',
      label: 'Notifications and Alerts',
      children: '',
    },
  ];

  return (
    <Layout>
      <>
        <div>
          <div className=" mb-[30px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
              }}
            >
              Settings
            </Title>
            <div className="flex justify-between items-center mb-20">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <MdAdminPanelSettings
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />
                            <span
                              className=" font-bold text-[13px]"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              Master Settings
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
            <div className="mt-6">
              <Tabs
                // tabPosition={isMobile ? 'left' : ''}
                defaultActiveKey="1"
                items={items}
                type="card"
                onChange={onChange}
                moreIcon={
                  <span>
                    <BsThreeDots
                      style={{
                        color: themeMode === 'light' ? colorTheme : '#939BC9',
                      }}
                    />
                  </span>
                }
                size="small"
              />
            </div>
          </div>
          <div></div>
        </div>
      </>
    </Layout>
  );
};
export default MasterSettings;
