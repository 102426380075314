import React, { useMemo, useState } from 'react';
import Layout from 'layout/AdminLayout';
import { Tabs } from 'antd';
import { Breadcrumb } from 'antd';
import { GoHome } from 'react-icons/go';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useApiClient from 'config/useAxiosClient';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import { RiMapPinUserFill } from 'react-icons/ri';
import { BsDeviceSsdFill } from 'react-icons/bs';
import { usePermissionVariables } from 'Hooks/HasPermission';
import Devices from './components/Devices';
import Configurations from './components/Configurations';
import Logs from './components/Logs';
import AssignDevice from './components/AssignDevice';

const { Title } = Typography;

const DeviceManagement = () => {
  const {
    Logs_View,
    Configuration_View,
    Assign_Device_View,
    All_Devices_View,
  } = usePermissionVariables();
  const navigate = useNavigate();
  const onChange = (key) => {};
  const [searchParams] = useSearchParams();
  const active = searchParams.get('active');
  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  const items = [
    All_Devices_View && {
      key: '1',
      label: 'All Devices',
      children: <Devices />,
    },
    Assign_Device_View && {
      key: '2',
      label: 'Assign Device',
      children: <AssignDevice />,
    },
    Configuration_View && {
      key: '3',
      label: 'Configuration',
      children: <Configurations />,
    },
    Logs_View && {
      key: '4',
      label: 'Logs',
      children: <Logs />,
    },
  ];
  return (
    <Layout>
      <>
        <div>
          <div className=" mb-[30px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
              }}
            >
              Tracker Settings
            </Title>
            <div className="flex justify-between items-center ">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className="flex items-center space-x-4 ">
                            <BsDeviceSsdFill
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />

                            <span
                              className="font-bold text-[13px]"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              Device Management
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div>
            <div>
              <Tabs
                // tabPosition={isMobile ? 'left' : ''}
                type="card"
                defaultActiveKey={active || 1}
                items={items}
                onChange={onChange}
                size="small"
              />
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};
export default DeviceManagement;
