import { createSlice } from '@reduxjs/toolkit';
import { playSound } from 'utils/helper';

//
const initialNonPersistedState = {
  realNotifications: [],
  trackingData: [],
};

export const nonPersistedSlice = createSlice({
  name: 'nonPersistedSlice',
  initialState: initialNonPersistedState,
  reducers: {
    setRealNotifications: (state, action) => {
      const existingIndex = state.realNotifications.findIndex(
        (notif) => notif.notificationId === action.payload.notificationId
      );
      if (existingIndex !== -1) {
        state.realNotifications[existingIndex] = action.payload;
      } else {
        state.realNotifications = [...state.realNotifications, action.payload];
        playSound('/sounds/success-sound.wav');
      }
    },
    setTrackingData: (state, action) => {
      state.trackingData = [
        ...state.trackingData,
        {
          lat: Number(action.payload.latitude),
          lng: Number(action.payload.longitude),
        },
      ];
    },
  },
});

// Export actions for non-persisted slice
export const { setRealNotifications, setTrackingData } =
  nonPersistedSlice.actions;
export default nonPersistedSlice.reducer;
