import { Button, Modal } from 'antd';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
import Checkbox from 'components/InputFields/Checkbox';
import PhoneInput from 'components/InputFields/PhoneInput';
import ToastNotification from 'components/ToastNotification';
import { update_users } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createOptions } from 'utils/helper';
import useValidationSchema from 'validation/useValidationSchema';
const EditUserModal = ({
  openEditUserModal,
  setOpenEditUserModal,
  record,
  refetch,
}) => {
  const { themeMode } = useSelector((state) => state?.states);
  const { EdituserModalSchema } = useValidationSchema();
  const { useGetRequest } = useApiClient();
  const { putRequest } = useApiClient();

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: '',
      password: '',
      email: '',
      contactNumber: '+92',
      assignRole: null,
      tenantId: null,
      userType: null,
      isTwoFactorEnabled: false,
      isActive: false,
    },
    validationSchema: EdituserModalSchema,
    onSubmit: ({
      name,
      password,
      email,
      contactNumber,
      assignRole,
      isTwoFactorEnabled,
      isActive,
      tenantId,
      userType,
    }) => {
      setLoading(true);
      const paylaod = {
        userid: record?.userId,
        userName: name,
        email: email,
        contactNo: contactNumber,
        roleId: assignRole,
        isTwoFactorEnabled: isTwoFactorEnabled,
        isActive: isActive,
        tenantId: tenantId || 0,
        usertypeId: userType,
      };

      putRequest(`${update_users}${record?.userId}`, paylaod)
        .then((res) => {
          // if (res?.success) {
          setOpenEditUserModal(false);
          refetch();
          formik.resetForm();
          ToastNotification('success', 'User Updated successfully', themeMode);
          // toast.success(res.message);
          // } else {
          //   toast.error(res.message);
          // }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const { data: userTypeData, isLoading: userTypeLoading } = useGetRequest(
    `/api/User/GetUserTypes`,
    {
      refetchOnMount: 'always',
    }
  );
  const { data: tenatData, isLoading: tenantLoading } = useGetRequest(
    `/api/GeTenantsDropdown`,
    {
      refetchOnMount: 'always',
    }
  );
  const {
    data: assignRoleData,
    refetch: userTypeRefetch,
    isLoading: assignRoleLoading,
  } = useGetRequest(
    `api/Roles/GetRoleByUsertype?usertypeId=${formik.values.userType}`,
    {
      enabled: false,
    }
  );
  const userTypeOptions = createOptions(
    userTypeData?.data,
    'userTypeName',
    'userTypeId'
  );
  const assignRoleOption = createOptions(
    assignRoleData?.data,
    'roleName',
    'roleId'
  );
  const tenantOption = createOptions(tenatData?.data, 'tenantName', 'tenantId');
  const handleCancel = () => {
    setOpenEditUserModal(false);
  };
  useEffect(() => {
    if (formik.values.userType) userTypeRefetch();
  }, [formik.values.userType]);

  useEffect(() => {
    if (record) {
      formik.setValues({
        ...formik.values,
        name: record?.userName,
        email: record?.email,
        assignRole: record?.roleId,
        contactNumber: record?.contactNo,
        userType: record?.userTypeId,
        tenantId: record?.tenantId || null,
        isTwoFactorEnabled: record?.isTwoFactorEnabled,
        isActive: record?.isActive,
      });
    }
  }, [record, openEditUserModal]);
  return (
    <>
      <Modal
        open={openEditUserModal}
        width={600}
        title={<span className="text-[18px]">Update User Info</span>}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
          >
            Update
          </Button>,
        ]}
      >
        <>
          <div className="grid grid-cols-12 gap-x-20 gap-y-5  py-[5px]">
            <div className="sm:col-span-6 col-span-12">
              <TextField
                formik={formik}
                id={'name'}
                label={'User Name'}
                maxLength={30}
              />
            </div>
            <div className="sm:col-span-6 col-span-12">
              <TextField formik={formik} id={'email'} label={'Email'} />
            </div>
            <div className="sm:col-span-6 col-span-12">
              <PhoneInput
                label="Contact Number"
                name="contactNumber"
                value={formik.values.contactNumber}
                isModel={true}
                onChange={(value) => {
                  if (value.length <= 18) {
                    formik.setFieldValue('contactNumber', value);
                    formik.setFieldTouched('contactNumber', true, false);
                  }
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.contactNumber && formik.errors.contactNumber
                }
                errorMessage={
                  formik.touched.contactNumber && formik.errors.contactNumber
                    ? formik.errors.contactNumber
                    : null
                }
              />
            </div>
            <div className=" sm:col-span-6 col-span-12">
              <SelectField
                formik={formik}
                id={'userType'}
                label={'User Type'}
                optionId={'value'}
                optionName={'label'}
                optionData={userTypeOptions}
                isLoading={userTypeLoading}
                onChange={(newValue, _) => {
                  formik.setFieldValue('userType', newValue);
                  formik.setFieldValue('assignRole', null);
                }}
              />
            </div>
            <div className=" sm:col-span-6 col-span-12">
              <SelectField
                formik={formik}
                id={'assignRole'}
                label={'Assign Role'}
                optionId={'value'}
                optionName={'label'}
                optionData={assignRoleOption}
                isLoading={assignRoleLoading}
              />
            </div>
            {formik.values.userType === 6 && (
              <div className=" sm:col-span-6 col-span-12">
                <SelectField
                  formik={formik}
                  id={'tenantId'}
                  label={'Tenant Name'}
                  optionId={'value'}
                  optionName={'label'}
                  optionData={tenantOption}
                  isLoading={tenantLoading}
                />
              </div>
            )}
          </div>
          <div className=" grid grid-cols-12 gap-x-20  py-[10px] mt-5">
            <div className="sm:col-span-6 col-span-12">
              <Checkbox
                title="IsActive"
                id="isActive"
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                error={formik.errors.isActive}
                errorMessage={
                  formik.touched.isActive && formik.errors.isActive
                    ? formik.errors.isActive
                    : null
                }
              />
            </div>
            <div className="sm:col-span-6 col-span-12">
              <Checkbox
                title="IsTwoFactorEnabled"
                id="isTwoFactorEnabled"
                name="isTwoFactorEnabled"
                checked={formik.values.isTwoFactorEnabled}
                onChange={formik.handleChange}
                error={formik.errors.isTwoFactorEnabled}
                errorMessage={
                  formik.touched.isTwoFactorEnabled &&
                  formik.errors.isTwoFactorEnabled
                    ? formik.errors.isTwoFactorEnabled
                    : null
                }
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default EditUserModal;
