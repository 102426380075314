import React from 'react';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
const PrimaryButton = ({
  title,
  type,
  icon,
  className = 'w-full !rounded-[6px]  font-med text-[12px] h-[32px]',
  onClick,
  loading,
  disabled,
  ...props
}) => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  return (
    <div>
      <Button
        type="primary"
        icon={icon}
        htmlType={type}
        // style={buttonStyle}
        className={`${className} ${
          themeMode === 'light'
            ? 'text-white'
            : 'text-[#121212] hover:!text-[#121212]'
        } no-shadow-button`}
        onClick={onClick}
        loading={loading}
        disabled={disabled}
        {...props}
      >
        {title}
      </Button>
    </div>
  );
};
export default PrimaryButton;
