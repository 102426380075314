import { Breadcrumb, Input, Select, Spin, Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import InputFieldCustomForm from 'components/CustomFields/CustomFieldsOutlinedForm';
import DatePickerField from 'components/CustomFields/DatePickerField';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
import TextArea from 'components/InputFields/TextArea';
import LabelGrid from 'components/LabelGrid';
import PopConfirm from 'components/PopConfirm';
import ToastNotification from 'components/ToastNotification';
import useApiClient from 'config/useAxiosClient';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import Layout from 'layout/AdminLayout';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { BiSave } from 'react-icons/bi';
import { FaCloudArrowUp, FaPlus } from 'react-icons/fa6';
import { GoHome } from 'react-icons/go';
import { MdCancel, MdOutlineManageAccounts } from 'react-icons/md';
import { RiDragMove2Fill } from 'react-icons/ri';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BILLING_CYCLE,
  subscriptionPlanOptions,
  themeInputColor,
} from 'utils/constant';
import { GET_SUBSCRIPTION_PLAN_KEY } from 'utils/queryKey';
import useValidationSchema, {
  validateSpaces,
} from 'validation/useValidationSchema';
import * as Yup from 'yup';
const { Title } = Typography;

const EditSubscriptionPlan = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { Option } = Select;
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { putRequest, useGetRequest } = useApiClient();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const { subscriptionPlanseModalSchema } = useValidationSchema();
  const { data, error, isLoading, refetch } = useGetRequest(
    `/api/GetSubscriptionPlanById?id=${id}`
  );
  const innerFormik = useFormik({
    initialValues: {
      planFeature: [{ featureName: '', description: '', status: '' }],
    },
    validationSchema: Yup.object({
      planFeature: Yup.array().of(
        Yup.object({
          featureName: validateSpaces().required('Feature name is required'),
          description: validateSpaces().required(
            'Feature description is required'
          ),
        })
      ),
    }),
    onSubmit: ({}) => {},
  });
  const formik = useFormik({
    initialValues: {
      planName: '',
      description: '',
      price: null,
      billingCycle: '',
      maxUsers: null,
      deviceCount: null,
      planValidFrom: '',
      planValidTill: '',
      status: null,
    },
    validationSchema: subscriptionPlanseModalSchema,
    onSubmit: ({
      planName,
      description,
      price,
      billingCycle,
      maxUsers,
      deviceCount,
      planValidFrom,
      planValidTill,
      status,
    }) => {
      if (innerFormik.values.planFeature.length !== 0) {
        if (Object.keys(innerFormik.errors).length === 0) {
          setLoading(true);
          const paylaod = {
            planId: id,
            planName: planName,
            description: description,
            price: Number(price),
            billingCycle: billingCycle,
            maxUsers: Number(maxUsers),
            deviceCount: Number(deviceCount),
            planValidFrom: dayjs(planValidFrom).format(
              'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
            ),
            planValidTill: dayjs(planValidTill).format(
              'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
            ),
            status: status,
            features: innerFormik.values.planFeature,
          };
          putRequest(`api/UpdateSubscriptionPlan`, paylaod)
            .then((res) => {
              if (res?.success) {
                queryClient.invalidateQueries({
                  queryKey: [GET_SUBSCRIPTION_PLAN_KEY],
                });
                refetch();
                formik.resetForm();
                navigate('/plan-management');
                ToastNotification('success', res.message, themeMode);
              } else {
                ToastNotification('error', res.message, themeMode);
              }
            })
            .catch((err) =>
              ToastNotification('error', 'Something went wrong', themeMode)
            )
            .finally(() => {
              setLoading(false);
            });
        }
      } else {
        ToastNotification(
          'error',
          'Atleast one plan feature is required',
          themeMode
        );
      }
    },
  });

  const handleAdd = async () => {
    const errors = await innerFormik.validateForm();
    if (Object.keys(errors).length === 0) {
      innerFormik.setValues({
        ...innerFormik.values,
        planFeature: [
          ...innerFormik.values.planFeature,
          { featureName: '', description: '' },
        ],
      });
    }
  };
  const handleRemove = (index) => {
    const updatedFeature = innerFormik.values.planFeature?.filter(
      (_, i) => i !== index
    );
    innerFormik.setValues({
      ...innerFormik.values,
      planFeature: updatedFeature,
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(innerFormik.values.planFeature);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    innerFormik.setFieldValue('planFeature', items);
  };

  useEffect(() => {
    if (data) {
      formik.setValues({
        ...formik.values,
        planName: data?.data?.planName,
        description: data?.data?.description,
        price: data?.data?.price || '0',
        billingCycle: data?.data?.billingCycle,
        maxUsers: data?.data?.maxUsers,
        deviceCount: data?.data?.deviceCount,
        planValidFrom: dayjs(data?.data?.planValidFrom),
        planValidTill: dayjs(data?.data?.planValidTill),
        status: data?.data?.status,
      });
      innerFormik.setValues({
        ...innerFormik.values,
        planFeature: data?.data?.features?.map((feature) => ({
          featureId: feature.featureId || 0,
          featureName: feature.featureName || '',
          description: feature.description || '',
          isActive: feature.isActive || false,
        })),
      });
    }
  }, [data]);

  return (
    <Layout>
      <>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-[30px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
                marginBottom: '10px',
              }}
            >
              Subscription Plan
            </Title>
            <div className="flex justify-between items-center mb-20">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <MdOutlineManageAccounts />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/plan-management');
                              }}
                            >
                              Subscription Plan
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4 ">
                            <FaCloudArrowUp
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />
                            <span
                              className=" font-bold text-[13px]"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              Update Plan
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
              <div className="flex justify-end space-x-10">
                <div>
                  <SecondaryButton
                    title="Cancel"
                    onClick={() => {
                      navigate('/plan-management');
                    }}
                    icon={<MdCancel size={17} />}
                    className=" w-full text-[13px]"
                  />
                </div>
                <div>
                  <PrimaryButton
                    title="Update"
                    icon={<BiSave className=" w-[17px] h-[17px]" />}
                    //   htmlType="submit"
                    onClick={() => {
                      innerFormik.handleSubmit();
                      formik.handleSubmit();
                    }}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
          <Spin spinning={isLoading}>
            <div className=" pb-[40px]">
              <LabelGrid
                label="Plan Info"
                className=" rounded-[5px] px-20 py-20"
              >
                <div className="grid grid-cols-12 sm:gap-x-[20px] gap-y-[6px] sm:gap-y-[5px] ">
                  <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                    <TextField
                      formik={formik}
                      id={'planName'}
                      label={'Plan Name'}
                      maxLength={30}
                      notModal={true}
                    />
                  </div>
                  <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                    <TextField
                      formik={formik}
                      id={'price'}
                      label={'Price'}
                      maxLength={10}
                      type={'number'}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.length <= 10) {
                          formik.setFieldValue('price', value);
                          formik.setFieldTouched('price', true, false);
                        }
                      }}
                      notModal={true}
                    />
                  </div>
                  <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                    <SelectField
                      formik={formik}
                      id={'billingCycle'}
                      label={'Billing Cycle'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={BILLING_CYCLE}
                      notModal={true}
                    />
                  </div>
                  <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                    <TextField
                      formik={formik}
                      id={'maxUsers'}
                      label={'Max Users'}
                      type={'number'}
                      maxLength={10}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.length <= 10) {
                          formik.setFieldValue('maxUsers', value);
                          formik.setFieldTouched('maxUsers', true, false);
                        }
                      }}
                      notModal={true}
                    />
                  </div>
                  <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                    <TextField
                      formik={formik}
                      id={'deviceCount'}
                      label={'Device Count'}
                      maxLength={10}
                      type={'number'}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value.length <= 10) {
                          formik.setFieldValue('deviceCount', value);
                          formik.setFieldTouched('deviceCount', true, false);
                        }
                      }}
                      notModal={true}
                    />
                  </div>
                  <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                    <DatePickerField
                      formik={formik}
                      id={'planValidFrom'}
                      label={'Plan Valid From'}
                      minDate={dayjs()}
                      notModal={true}
                    />
                  </div>
                  <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                    <DatePickerField
                      formik={formik}
                      id={'planValidTill'}
                      label={'Plan Valid Till'}
                      minDate={dayjs(formik.values.planValidFrom).add(1, 'day')}
                      notModal={true}
                    />
                  </div>
                  <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                    <SelectField
                      formik={formik}
                      id={'status'}
                      label={'Status'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={subscriptionPlanOptions}
                      notModal={true}
                    />
                  </div>
                  <div className=" col-span-6 mt-[10px]">
                    <TextArea
                      id="description"
                      rows={4}
                      name="description"
                      placeholder="Description"
                      value={formik.values.description}
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.setFieldTouched('description', true, false);
                      }}
                      onBlur={formik.handleBlur}
                      required={true}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      errorMessage={
                        formik.touched.description && formik.errors.description
                          ? formik.errors.description
                          : null
                      }
                    />
                  </div>
                </div>
              </LabelGrid>
              <LabelGrid
                label="Plan Feature"
                className=" rounded-[5px] px-20 py-20 "
              >
                <div className="md:block hidden">
                  <div className=" flex ">
                    <div className="w-[80%]">
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {innerFormik.values.planFeature?.map(
                                (feature, index) => (
                                  <Draggable
                                    key={index}
                                    draggableId={`draggable-${index}`}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className="grid grid-cols-12 sm:gap-x-[10px] gap-y-[6px] sm:gap-y-0 mt-[0px]"
                                      >
                                        {/* Drag and Drop Icon */}
                                        <div className="flex items-center justify-end">
                                          <RiDragMove2Fill
                                            size={16}
                                            className="cursor-grab hover:cursor-grabbing transition-colors duration-200"
                                            color={
                                              themeMode === 'light'
                                                ? colorTheme
                                                : '#9FA8DA'
                                            }
                                          />
                                        </div>
                                        <div className="col-span-3 flex">
                                          <div className="wrapper-fields">
                                            <InputFieldCustomForm
                                              label="Feature Name"
                                              id={`planFeature.${index}.featureName`}
                                              value={feature.featureName}
                                              error={
                                                innerFormik.touched
                                                  .planFeature?.[index]
                                                  ?.featureName &&
                                                Boolean(
                                                  innerFormik.errors
                                                    .planFeature?.[index]
                                                    ?.featureName
                                                )
                                              }
                                              errorMessage={
                                                innerFormik.errors
                                                  .planFeature?.[index]
                                                  ?.featureName
                                              }
                                              required={true}
                                              notModal={true}
                                            >
                                              <Input
                                                id={`planFeature.${index}.featureName`}
                                                name={`planFeature.${index}.featureName`}
                                                type="text"
                                                maxLength={30}
                                                value={feature.featureName}
                                                onChange={(e) => {
                                                  innerFormik.handleChange(e);
                                                  innerFormik.setFieldTouched(
                                                    `planFeature.${index}.featureName`,
                                                    true,
                                                    false
                                                  );
                                                }}
                                                onBlur={innerFormik.handleBlur}
                                                style={{
                                                  height: '25px',
                                                  fontSize: '14px',
                                                  border: !(
                                                    innerFormik.touched[
                                                      `planFeature.${index}.featureName`
                                                    ] &&
                                                    Boolean(
                                                      innerFormik.errors
                                                        .planFeature?.[index]
                                                        ?.featureName
                                                    )
                                                  )
                                                    ? '1px solid #ccc' // Replace with your dynamic border color
                                                    : '1px solid red', // Replace with your error color
                                                  borderRadius: '5px',
                                                  caretColor: '#000', // Replace with your dynamic caret color
                                                  color: '#000', // Replace with your dynamic text color
                                                }}
                                              />
                                            </InputFieldCustomForm>
                                          </div>
                                        </div>
                                        <div className="col-span-6">
                                          <div className="wrapper-fields">
                                            <InputFieldCustomForm
                                              label="Feature Description"
                                              id={`planFeature.${index}.description`}
                                              value={feature.description}
                                              error={
                                                innerFormik.touched
                                                  .planFeature?.[index]
                                                  ?.description &&
                                                Boolean(
                                                  innerFormik.errors
                                                    .planFeature?.[index]
                                                    ?.description
                                                )
                                              }
                                              errorMessage={
                                                innerFormik.errors
                                                  .planFeature?.[index]
                                                  ?.description
                                              }
                                              required={true}
                                              notModal={true}
                                            >
                                              <Input
                                                id={`planFeature.${index}.description`}
                                                name={`planFeature.${index}.description`}
                                                type="text"
                                                maxLength={100}
                                                value={feature.description}
                                                onChange={(e) => {
                                                  innerFormik.handleChange(e);
                                                  innerFormik.setFieldTouched(
                                                    `planFeature.${index}.description`,
                                                    true,
                                                    false
                                                  );
                                                }}
                                                onBlur={innerFormik.handleBlur}
                                                style={{
                                                  height: '25px',
                                                  fontSize: '14px',
                                                  border: !(
                                                    innerFormik.touched[
                                                      `planFeature.${index}.description`
                                                    ] &&
                                                    Boolean(
                                                      innerFormik.errors
                                                        .planFeature?.[index]
                                                        ?.description
                                                    )
                                                  )
                                                    ? '1px solid #ccc' // Replace with your dynamic border color
                                                    : '1px solid red', // Replace with your error color
                                                  borderRadius: '5px',
                                                  caretColor: '#000', // Replace with your dynamic caret color
                                                  color: '#000', // Replace with your dynamic text color
                                                }}
                                              />
                                            </InputFieldCustomForm>
                                          </div>
                                        </div>
                                        <div className="mt-[18px] ml-[40px]">
                                          <PopConfirm
                                            title="Delete"
                                            description="Are you sure to delete this plan?"
                                            placement="left"
                                            onClick={() => handleRemove(index)}
                                          >
                                            <MdCancel
                                              size={22}
                                              color="#FF5338"
                                              className="cursor-pointer"
                                            />
                                          </PopConfirm>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                )
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                    <div className="w-[20%] mt-[10px]">
                      <div className="flex justify-end">
                        <PrimaryButton
                          title="Add More Feature"
                          icon={<FaPlus />}
                          onClick={() => {
                            innerFormik.handleSubmit();
                            handleAdd();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md:hidden block">
                  <div className="">
                    <PrimaryButton
                      title="Add More Feature"
                      icon={<FaPlus />}
                      onClick={() => {
                        innerFormik.handleSubmit();
                        handleAdd();
                      }}
                    />
                    {innerFormik.values.planFeature?.map((feature, index) => (
                      <div className="grid grid-cols-12 sm:gap-x-[20px] gap-y-[6px] sm:gap-y-0 mt-[10px]">
                        <>
                          <div className="col-span-5">
                            <div className="wrapper-fields">
                              <InputFieldCustomForm
                                label="Feature Name"
                                id={`planFeature.${index}.featureName`}
                                value={feature.featureName}
                                error={
                                  innerFormik.touched.planFeature?.[index]
                                    ?.featureName &&
                                  Boolean(
                                    innerFormik.errors.planFeature?.[index]
                                      ?.featureName
                                  )
                                }
                                errorMessage={
                                  innerFormik.errors.planFeature?.[index]
                                    ?.featureName
                                }
                                required={true}
                                notModal={true}
                              >
                                <Input
                                  id={`planFeature.${index}.featureName`}
                                  name={`planFeature.${index}.featureName`}
                                  type="text"
                                  maxLength={30}
                                  value={feature.featureName}
                                  disabled={false}
                                  size="large"
                                  onChange={(e) => {
                                    innerFormik.handleChange(e);
                                    innerFormik.setFieldTouched(
                                      `planFeature.${index}.featureName`,
                                      true,
                                      false
                                    );
                                  }}
                                  onBlur={innerFormik.handleBlur}
                                  style={{
                                    height: '25px',
                                    fontSize: '14px',
                                    border: !(
                                      innerFormik.touched[
                                        `planFeature.${index}.featureName`
                                      ] &&
                                      Boolean(
                                        innerFormik.errors.planFeature?.[index]
                                          ?.featureName
                                      )
                                    )
                                      ? themeMode === 'light'
                                        ? `1px solid ${themeInputColor.lightBorderColor}`
                                        : `1px solid ${themeInputColor.darkBorderColor}`
                                      : `1px solid ${themeInputColor.errorBorderColor}`,
                                    borderRadius: '5px',
                                    caretColor:
                                      themeMode === 'light'
                                        ? themeInputColor.lightCaretColor
                                        : themeInputColor.darkCaretColor,
                                    color:
                                      themeMode === 'light'
                                        ? themeInputColor.lightTextColor
                                        : themeInputColor.darkTextColor,
                                  }}
                                />
                              </InputFieldCustomForm>
                            </div>
                          </div>
                          <div className=" ml-[20px] col-span-5">
                            <div className="wrapper-fields">
                              <InputFieldCustomForm
                                label="Feature Description"
                                id={`planFeature.${index}.description`}
                                value={feature.description}
                                error={
                                  innerFormik.touched.planFeature?.[index]
                                    ?.description &&
                                  Boolean(
                                    innerFormik.errors.planFeature?.[index]
                                      ?.description
                                  )
                                }
                                errorMessage={
                                  innerFormik.errors.planFeature?.[index]
                                    ?.description
                                }
                                required={true}
                                notModal={true}
                              >
                                <Input
                                  id={`planFeature.${index}.description`}
                                  name={`planFeature.${index}.description`}
                                  type="text"
                                  maxLength={100}
                                  value={feature.description}
                                  disabled={false}
                                  size="large"
                                  onChange={(e) => {
                                    innerFormik.handleChange(e);
                                    innerFormik.setFieldTouched(
                                      `planFeature.${index}.description`,
                                      true,
                                      false
                                    );
                                  }}
                                  onBlur={innerFormik.handleBlur}
                                  style={{
                                    height: '25px',
                                    fontSize: '14px',
                                    border: !(
                                      innerFormik.touched[
                                        `planFeature.${index}.description`
                                      ] &&
                                      Boolean(
                                        innerFormik.errors.planFeature?.[index]
                                          ?.description
                                      )
                                    )
                                      ? themeMode === 'light'
                                        ? `1px solid ${themeInputColor.lightBorderColor}`
                                        : `1px solid ${themeInputColor.darkBorderColor}`
                                      : `1px solid ${themeInputColor.errorBorderColor}`,
                                    borderRadius: '5px',
                                    caretColor:
                                      themeMode === 'light'
                                        ? themeInputColor.lightCaretColor
                                        : themeInputColor.darkCaretColor,
                                    color:
                                      themeMode === 'light'
                                        ? themeInputColor.lightTextColor
                                        : themeInputColor.darkTextColor,
                                  }}
                                />
                              </InputFieldCustomForm>
                            </div>
                          </div>

                          <div className=" mt-[18px] ml-[40px]">
                            <PopConfirm
                              title="Delete"
                              description="Are you sure to delete this plan? "
                              placement="left"
                              onClick={() => handleRemove(index)}
                            >
                              <MdCancel
                                size={22}
                                color="#FF5338"
                                className="cursor-pointer"
                              />
                            </PopConfirm>
                          </div>
                        </>
                      </div>
                    ))}
                  </div>
                </div>
              </LabelGrid>
            </div>
          </Spin>
        </form>
      </>
    </Layout>
  );
};
export default EditSubscriptionPlan;
