import { Breadcrumb, Select, Spin, Tag, Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
import Checkbox from 'components/InputFields/Checkbox';
import PhoneInput from 'components/InputFields/PhoneInput';
import LabelGrid from 'components/LabelGrid';
import ToastNotification from 'components/ToastNotification';
import useApiClient from 'config/useAxiosClient';
import { useFormik } from 'formik';
import Layout from 'layout/AdminLayout';
import { useEffect, useState } from 'react';
import { BiSave } from 'react-icons/bi';
import { BsBuildingGear } from 'react-icons/bs';
import { FaCloudArrowUp } from 'react-icons/fa6';
import { GoHome } from 'react-icons/go';
import { MdCancel } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { colorOptions } from 'utils/constant';
import { convertDateFormat } from 'utils/helper';
import { GET_TENANTS_KEY } from 'utils/queryKey';
import useValidationSchema from 'validation/useValidationSchema';
const { Title, Text } = Typography;

const EditTenant = () => {
  const [checkbox, setCheckbox] = useState({
    isTwoFactorAuth: false,
    email: false,
    sms: false,
  });
  const queryClient = useQueryClient();
  const { putRequest, useGetRequest } = useApiClient();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { updateTenantModalSchema } = useValidationSchema();
  const { Option } = Select;
  const { id } = useParams();
  const formik = useFormik({
    initialValues: {
      tenantName: '',
      tenantEmail: '',
      tenantPhone: '+92',
      country: null,
      state: null,
      city: null,
      addressLine1: '',
      addressLine2: '',
      postalCode: null,
      userName: '',
      tenantStatus: false,
      themeColor: '',
      emailSettingId: null,
      smsSettingId: null,
      tenantLogo: '',
      assignRole: null,
    },
    validationSchema: updateTenantModalSchema,
    onSubmit: ({
      tenantName,
      tenantEmail,
      password,
      tenantPhone,
      country,
      state,
      city,
      addressLine1,
      addressLine2,
      postalCode,
      tenantStatus,
      themeColor,
      emailSettingId,
      smsSettingId,
      userName,
      assignRole,
    }) => {
      setLoading(true);
      const paylaod = {
        tenantDto: {
          tenantId: id,
          tenantName: tenantName,
          tenantEmail: tenantEmail,
          tenantPhone: tenantPhone,
          country: country,
          state: state,
          city: city,
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          postalCode: postalCode,
          isActive: tenantStatus,
        },
        tenantSettingsDto: {
          themeColor: themeColor,
          emailSettingId: emailSettingId,
          smsSettingId: smsSettingId,
          tenantLogo: 'string',
          isTwoFactorAuth: checkbox.isTwoFactorAuth,
        },
      };
      putRequest(`/api/UpdateTenant`, paylaod)
        .then((res) => {
          if (res?.success) {
            const userPaylaod = {
              userId: tenantData?.data?.userId,
              tenantId: tenantData?.data?.tenantId,
              userName: userName,
              passwordHash: password,
              email: tenantEmail,
              contactNo: tenantPhone,
              roleId: assignRole,
              isTwoFactorEnabled: true,
              isActive: tenantStatus,
            };
            putRequest(`/api/User/UpdateUser`, userPaylaod)
              .then((res) => {
                if (res?.success) {
                  formik.resetForm();
                  ToastNotification('success', res?.message, themeMode);
                  queryClient.invalidateQueries({
                    queryKey: [GET_TENANTS_KEY],
                  });
                  navigate('/tenant-management');
                } else {
                  ToastNotification('error', res.message, themeMode);
                }
              })
              .catch((err) =>
                ToastNotification('error', 'Something went wrong', themeMode)
              )
              .finally(() => {
                setLoading(false);
              });
          } else {
            ToastNotification('error', res.message, themeMode);
            if (res?.message.startsWith('Email')) {
              formik.setFieldError('tenantEmail', 'Email already exist');
            } else if (res?.message.startsWith('Phone')) {
              formik.setFieldError('tenantPhone', 'Phone number already exist');
            }
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const { refetch: TenantRefetch } = useGetRequest(
    `/api/GetAllTenants?pagenumber=1&pagesize=10&searchquery='A'`,
    {
      enabled: false,
    }
  );

  const {
    data: countries,
    isLoading: countryLoading,
    refetch: countryRefetch,
  } = useGetRequest(`/api/GetCountriesdropdown`, {
    refetchOnMount: 'always',
  });
  const {
    data: states,
    refetch: statesRefetch,
    isLoading: statesLoading,
  } = useGetRequest(`api/StateDropdown?Id=${formik.values.country}`, {
    enabled: false,
  });
  const {
    data: cities,
    refetch: citiesRefetch,
    isLoading: citiesLoading,
  } = useGetRequest(`api/CitiesDropdown?Id=${formik.values.state}`, {
    enabled: false,
  });
  const {
    data: zipCode,
    refetch: zipCodeRefetch,
    isLoading: zipCodeLoading,
  } = useGetRequest(`api/ZipCodesDropdown?Id=${formik.values.city}`, {
    enabled: false,
  });
  const { data: smsSettings, isLoading: smsSettingsLoading } = useGetRequest(
    `/api/GetAllSmsSettings`,
    {
      refetchOnMount: 'always',
    }
  );
  const { data: emailSettings, isLoading: emailSettingsLoading } =
    useGetRequest(`/api/GetAllEmailSettings`, {
      refetchOnMount: 'always',
    });

  const { data: assignRole, isLoading: assignRoleLoading } = useGetRequest(
    `api/Roles/GetRoleByUsertype?usertypeId=${process.env.REACT_APP_TENANT_ROLE_ID}`,
    {
      refetchOnMount: 'always',
    }
  );

  const {
    data: tenantData,
    error,
    isLoading,
    refetch,
  } = useGetRequest(`/api/GetTenantById?id=${id}`);

  const createOptions = (data, labelKey, valueKey) =>
    data?.data?.map((item) => ({
      label: item[labelKey],
      value: item[valueKey],
    }));

  const countriesOptions = createOptions(countries, 'name', 'countryId');
  const statesOptions = createOptions(states, 'name', 'stateId');
  const citiesOptions = createOptions(cities, 'name', 'cityId');
  const zipCodeOptions = createOptions(zipCode, 'code', 'zipCodeId');
  const assignRoleOptions = createOptions(assignRole, 'roleName', 'roleId');

  const smsSettingOptions = createOptions(
    smsSettings,
    'smsGatewayName',
    'smsSettingsId'
  );
  const emailSettingOptions = createOptions(
    emailSettings,
    'senderName',
    'settingId'
  );
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    setCheckbox((prevCheckbox) => {
      const updatedCheckbox = { ...prevCheckbox, [name]: checked };

      if (name === 'isTwoFactorAuth') {
        if (!checked) {
          updatedCheckbox.email = false;
          updatedCheckbox.sms = false;
        } else {
          updatedCheckbox.email = true;
          updatedCheckbox.sms = true;
        }
      } else {
        const bothUnchecked = !updatedCheckbox.email && !updatedCheckbox.sms;
        updatedCheckbox.isTwoFactorAuth = !bothUnchecked;
      }

      return updatedCheckbox;
    });
  };
  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (formik.values.country) statesRefetch();
    if (formik.values.state) citiesRefetch();
    if (formik.values.city) zipCodeRefetch();
  }, [formik.values.country, formik.values.state, formik.values.city]);

  useEffect(() => {
    if (tenantData?.data) {
      const {
        tenantName,
        tenantEmail,
        tenantPhone,
        countryId,
        stateId,
        cityId,
        userName,
        addressLine1,
        addressLine2,
        postalCodeId,
        isActive: tenantStatus,
        themeColor,
        emailSettingId,
        smsSettingId,
        tenantLogo,
        isTwoFactorAuth,
        roleId,
      } = tenantData.data;
      formik.setValues({
        ...formik.values,
        tenantName: tenantName || '',
        tenantEmail: tenantEmail || '',
        tenantPhone: tenantPhone || '+92',
        country: countryId || null,
        state: stateId || null,
        city: cityId || null,
        addressLine1: addressLine1 || '',
        addressLine2: addressLine2 || '',
        postalCode: postalCodeId || null,
        tenantStatus: tenantStatus || false,
        themeColor: themeColor || '',
        emailSettingId: emailSettingId || null,
        smsSettingId: smsSettingId || null,
        tenantLogo: tenantLogo || '',
        userName: userName || '',
        assignRole: roleId || null,
      });
      setCheckbox({
        ...checkbox,
        isTwoFactorAuth: isTwoFactorAuth || false,
      });
    }
  }, [tenantData?.data]);

  return (
    <Layout>
      <>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-[30px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
                marginBottom: '10px',
              }}
            >
              Tenant Management
            </Title>
            <div className="flex justify-between items-center mb-20">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <BsBuildingGear />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/tenant-management');
                              }}
                            >
                              Tenant Management
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4 ">
                            <FaCloudArrowUp
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />
                            <span
                              className=" font-bold text-[13px]"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              Update Tenant
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
              <div className="flex justify-end space-x-10">
                <div>
                  <SecondaryButton
                    title="Cancel"
                    onClick={() => {
                      navigate('/tenant-management');
                    }}
                    icon={<MdCancel size={17} />}
                    className=" w-full text-[13px]"
                  />
                </div>
                <div>
                  <PrimaryButton
                    title="Update"
                    icon={<BiSave className=" w-[17px] h-[17px]" />}
                    htmlType="submit"
                    loading={loading}
                    disabled={loading}
                  />
                </div>
              </div>
            </div>
          </div>
          <Spin spinning={isLoading}>
            <>
              <LabelGrid
                label="Tenant User Credentials"
                className=" rounded-[5px] px-20 py-20"
              >
                <div className="grid grid-cols-12 sm:gap-x-[20px]  gap-y-[25px] sm:gap-y-0 ">
                  <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                    <TextField
                      formik={formik}
                      id={'tenantName'}
                      label={'Tenant Name'}
                      maxLength={30}
                      notModal={true}
                    />
                  </div>
                  <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                    <TextField
                      formik={formik}
                      id={'tenantEmail'}
                      label={'Tenant Email'}
                      maxLength={30}
                      notModal={true}
                    />
                  </div>
                  <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                    <PhoneInput
                      label="Tenant Phone"
                      name="tenantPhone"
                      value={formik.values.tenantPhone}
                      onChange={(value) => {
                        if (value.length <= 18) {
                          formik.setFieldValue('tenantPhone', value);
                          formik.setFieldTouched('tenantPhone', true, false);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.tenantPhone && formik.errors.tenantPhone
                      }
                      errorMessage={
                        formik.touched.tenantPhone && formik.errors.tenantPhone
                          ? formik.errors.tenantPhone
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-12 sm:gap-x-[20px] mt-[5px] gap-y-[25px] sm:gap-y-0 ">
                  <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                    <SelectField
                      formik={formik}
                      id={'country'}
                      label={'Country'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={countriesOptions}
                      isLoading={countryLoading}
                      notModal={true}
                      onChange={(newValue, _) => {
                        formik.setFieldValue('country', newValue);
                        formik.setFieldValue('state', null);
                        formik.setFieldValue('city', null);
                        formik.setFieldValue('postalCode', null);
                      }}
                    />
                  </div>
                  <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                    <SelectField
                      formik={formik}
                      id={'state'}
                      label={'State'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={statesOptions}
                      isLoading={statesLoading}
                      notModal={true}
                      onChange={(newValue, _) => {
                        formik.setFieldValue('state', newValue);
                        formik.setFieldValue('city', null);
                        formik.setFieldValue('postalCode', null);
                      }}
                    />
                  </div>
                  <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12">
                    <SelectField
                      formik={formik}
                      id={'city'}
                      label={'City'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={citiesOptions}
                      isLoading={citiesLoading}
                      notModal={true}
                      onChange={(newValue, _) => {
                        formik.setFieldValue('city', newValue);
                        formik.setFieldValue('postalCode', null);
                      }}
                    />
                  </div>
                  <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                    <SelectField
                      formik={formik}
                      id={'postalCode'}
                      label={'Postal Code'}
                      optionId={'value'}
                      optionName={'label'}
                      isLoading={zipCodeLoading}
                      optionData={zipCodeOptions}
                      notModal={true}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-12 sm:gap-x-[20px] mt-[5px] gap-y-[6px] max-sm:mt-10 sm:gap-y-0 ">
                  <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                    <TextField
                      formik={formik}
                      id={'addressLine1'}
                      label={'Address Line 1'}
                      maxLength={100}
                      notModal={true}
                    />
                  </div>
                  <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                    <TextField
                      formik={formik}
                      id={'addressLine2'}
                      label={'Address Line 2'}
                      maxLength={100}
                      required={false}
                      notModal={true}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-12 sm:gap-x-[20px] mt-[5px] gap-y-[6px] sm:gap-y-0 ">
                  <div className=" xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12">
                    <SelectField
                      formik={formik}
                      id={'themeColor'}
                      label={'Theme Color'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={colorOptions}
                      notModal={true}
                      optionJSX={(item) => (
                        <div className="flex justify-between items-center">
                          <div>{item.label}</div>
                          <div
                            className="rounded-sm h-[25px] w-[50px]"
                            style={{
                              backgroundColor: item.value,
                              color: 'white',
                            }}
                          />
                        </div>
                      )}
                    />
                  </div>
                  <div className=" xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12">
                    <SelectField
                      formik={formik}
                      id={'emailSettingId'}
                      label={'Email Gateway'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={emailSettingOptions}
                      notModal={true}
                      isLoading={emailSettingsLoading}
                    />
                  </div>
                  <div className=" xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12">
                    <SelectField
                      formik={formik}
                      id={'smsSettingId'}
                      label={'SMS Gateway'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={smsSettingOptions}
                      notModal={true}
                      isLoading={smsSettingsLoading}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-12 sm:gap-x-[20px]  gap-y-[25px] sm:gap-y-0 mt-[15px]">
                  <div className=" col-span-3">
                    <Checkbox
                      title="IsActive"
                      id="tenantStatus"
                      name="tenantStatus"
                      checked={formik.values.tenantStatus}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className=" col-span-3">
                    <Checkbox
                      title="isTwoFactorAuth"
                      id="isTwoFactorAuth"
                      name="isTwoFactorAuth"
                      checked={checkbox.isTwoFactorAuth}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </div>
              </LabelGrid>
              <LabelGrid
                label="Tenant User Credentials"
                className=" rounded-[5px] px-20 py-20"
              >
                <div className="grid grid-cols-12 sm:gap-x-[20px]  gap-y-[25px] sm:gap-y-0 ">
                  <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                    <TextField
                      formik={formik}
                      id={'userName'}
                      label={'User Name'}
                      maxLength={30}
                      notModal={true}
                    />
                  </div>
                  <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                    <TextField
                      formik={formik}
                      id={'tenantEmail'}
                      label={'User Email'}
                      maxLength={30}
                      required={false}
                      notModal={true}
                      disabled={true}
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.setFieldTouched('tenantEmail', true, false);
                      }}
                    />
                  </div>
                  <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                    <SelectField
                      formik={formik}
                      id={'assignRole'}
                      label={'Assign Role'}
                      optionId={'value'}
                      optionName={'label'}
                      optionData={assignRoleOptions}
                      notModal={true}
                      isLoading={assignRoleLoading}
                    />
                  </div>
                  {/* <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 mt-[15px]">
                    <Checkbox
                      title="isTwoFactorAuth"
                      id="isTwoFactorAuth"
                      name="isTwoFactorAuth"
                      checked={checkbox.isTwoFactorAuth}
                      onChange={handleCheckboxChange}
                    />
                  </div> */}
                </div>
                <div className=" flex items-center w-full mt-[15px] space-x-">
                  <div className=" flex items-center space-x-10">
                    <div
                      className={`w-full whitespace-nowrap text-[12px] font-med ${
                        themeMode === 'light'
                          ? 'text-[#0d0c0c]'
                          : 'text-[#9f9b93]'
                      }`}
                    >
                      Secret Key :
                    </div>
                    <div className=" w-full">
                      <Tag color="gold">
                        {tenantData?.data?.sharedSecretKey}
                      </Tag>
                    </div>
                  </div>
                  <div className=" text-sm w-full">
                    <Text code>
                      Valid till:
                      {convertDateFormat(
                        tenantData?.data?.secretExpirationDate
                      )}
                    </Text>
                  </div>
                </div>
              </LabelGrid>
            </>
          </Spin>
        </form>
      </>
    </Layout>
  );
};
export default EditTenant;
