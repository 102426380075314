import { createSlice } from '@reduxjs/toolkit';

// Add Initial States
const initialState = {
  token: null,
  clientId: null,
  colorTheme: '#0d0c0cee',
  themeMode: 'light',
  permssionsArray: [],
  forgetEmail: '',
  userName: '',
  tenantSecretKey: '',
};

// Added Slice and reducers for get and update the states
export const Slices = createSlice({
  name: 'TMS',
  initialState: initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setThemeMode: (state, action) => {
      state.themeMode = action.payload;
    },
    setColorTheme: (state, action) => {
      state.colorTheme = action.payload;
    },
    setPermssionsArray: (state, action) => {
      state.permssionsArray = action.payload;
    },
    setClientId: (state, action) => {
      state.clientId = action.payload;
    },
    setForgetEmail: (state, action) => {
      state.forgetEmail = action.payload;
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setTenantSecretKey: (state, action) => {
      state.tenantSecretKey = action.payload;
    },
  },
});
export const {
  setToken,
  setColorTheme,
  setThemeMode,
  setPermssionsArray,
  setClientId,
  setForgetEmail,
  setUserName,
  setTenantSecretKey,
} = Slices.actions;
export default Slices.reducer;
