import { UploadOutlined } from '@ant-design/icons';
import { Image, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import ToastNotification from 'components/ToastNotification';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import './styles.css';

const PictureUpload = ({
  formik,
  id,
  label = 'Upload Image',
  textSize = 'text-[12px]',
  listType = 'picture-circle',
  height = 150,
  width = 150,
  fileList,
  setFileList,
}) => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [showUploadButton, setShowUploadButton] = useState(true);
  const [previewImage, setPreviewImage] = useState('');

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const beforeUpload = (file) => {
    const isPNG = file.type === 'image/png';
    const isJPG = file.type === 'image/jpg';
    const isJPEG = file.type === 'image/jpeg';
    const isImageTypeCorrect = !isPNG && !isJPG && !isJPEG;
    if (isImageTypeCorrect) {
      setFileList([]);
      formik.setFieldValue(id, null);
      setPreviewImage('');
      ToastNotification(
        'error',
        `${file.name} is not a png, jpg or jpeg file`,
        themeMode
      );
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      ToastNotification('error', `Image must smaller than 2MB!`, themeMode);
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const handleChange = ({ fileList }) => {
    if (fileList?.length === 0) {
      setFileList([]);
      formik.setFieldValue(id, null);
    } else {
      setFileList(fileList);
      formik.setFieldValue(id, fileList[0]?.originFileObj || null);
    }
  };
  const handleRemove = () => {
    formik.setFieldValue(id, null);
    setPreviewImage('');
    setFileList([]);
    setShowUploadButton(false);
    setTimeout(() => {
      setShowUploadButton(true);
    }, 700);
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <div className=" flex items-center space-x-7">
        <UploadOutlined
          className={` ${
            formik.touched[id] && Boolean(formik.errors[id]) ? `` : ''
          }`}
        />
        <div
          className={`${textSize} mb-2 underline ${
            formik.touched[id] && Boolean(formik.errors[id]) ? '' : ''
          }`}
        >
          {label}
        </div>
      </div>
    </button>
  );
  return (
    <div
      className={`${
        listType === 'picture-card' ? '!w-full' : '!w-[165px]'
      } ${listType}-upload`}
    >
      <form
        onSubmit={formik.handleSubmit}
        className={`${
          listType === 'picture-card' ? '!h-[110px]' : '!h-[150px]'
        } mb-10`}
      >
        <div>
          <ImgCrop showGrid rotationSlider aspectSlider showReset>
            <Upload
              listType={listType}
              fileList={fileList}
              style={{ width: width, height: height }}
              onPreview={handlePreview}
              onChange={handleChange}
              onRemove={handleRemove}
              beforeUpload={beforeUpload}
              //   beforeUpload={() => {
              //     return false;
              //   }}
              maxCount={1}
              multiple={false}
              accept=".jpg,.jpeg,.png"
              className="custom-upload flex justify-center"
            >
              {fileList?.length === 0 && showUploadButton && uploadButton}
            </Upload>
          </ImgCrop>
          {previewImage && (
            <Image
              wrapperStyle={{
                display: 'none',
              }}
              style={{ width: width, height: height }}
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(''),
              }}
              src={previewImage}
            />
          )}
        </div>
        <div className=" flex justify-start items-center">
          {formik.errors[id] && formik.touched[id] ? (
            <div className="text-error text-sm">{formik.errors[id]}</div>
          ) : null}
        </div>
      </form>
      <style jsx>
        {`
          .ant-upload-wrapper
            .ant-upload-list.ant-upload-list-picture-circle
            .ant-upload-list-item-error {
            border-color: ${themeMode === 'light' ? colorTheme : '#939BC9'};
          }
          .ant-upload-wrapper
            .ant-upload-list.ant-upload-list-picture-card
            .ant-upload-list-item-error {
            border-color: ${themeMode === 'light' ? colorTheme : '#939BC9'};
          }
        `}
      </style>
    </div>
  );
};

export default PictureUpload;
