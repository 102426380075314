import React, { useEffect, useState } from 'react';
import { Button, Modal, Input } from 'antd';
import { useFormik } from 'formik';
import useValidationSchema from 'validation/useValidationSchema';
import InputFieldCustomForm from 'components/CustomFields/CustomFieldsOutlinedForm';
import { useSelector } from 'react-redux';
import { themeInputColor } from 'utils/constant';
import { get_all_sms_gateway, update_sms_gateway } from 'config/services';
import { toast } from 'react-toastify';
import useApiClient from 'config/useAxiosClient';
import { useQueryClient } from 'react-query';
import Checkbox from 'components/InputFields/Checkbox';
import PhoneInput from 'components/InputFields/PhoneInput';
import ToastNotification from 'components/ToastNotification';
import TextField from 'components/CustomFields/TextField';

const EditSMSGatewayModal = ({
  openEditSMSGatewayModal,
  setOpenEditSMSGatewayModal,
  selectedRecord,
}) => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { putRequest } = useApiClient();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const { editSMSSettingModalSchema } = useValidationSchema();
  const formik = useFormik({
    initialValues: {
      smsGatewayName: '',
      userName: '',
      // passwordHash: '',
      fromPhoneNumber: '+92',
      isActive: false,
    },
    validationSchema: editSMSSettingModalSchema,
    onSubmit: ({ smsGatewayName, userName, fromPhoneNumber }) => {
      setLoading(true);
      const paylaod = {
        smsSettingsId: selectedRecord?.smsSettingsId,
        smsGatewayName,
        userName,
        fromPhoneNumber,
        // createdBy: '',
        passwordHash: '',
      };
      putRequest(update_sms_gateway, paylaod)
        .then((res) => {
          if (res?.success) {
            setOpenEditSMSGatewayModal(false);
            queryClient.invalidateQueries(get_all_sms_gateway);
            formik.resetForm();
            ToastNotification('success', res.message, themeMode);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCancel = () => {
    setOpenEditSMSGatewayModal(false);
    formik.resetForm();
  };
  useEffect(() => {
    if (selectedRecord) {
      formik.setValues({
        smsGatewayName: selectedRecord?.smsGatewayName,
        userName: selectedRecord?.username,
        fromPhoneNumber: selectedRecord?.fromPhoneNumber,
        isActive: selectedRecord?.isActive,
      });
    }
  }, [selectedRecord, setOpenEditSMSGatewayModal]);

  return (
    <>
      <Modal
        open={openEditSMSGatewayModal}
        title={<span className=" text-[18px]">Edit SMS Gateway</span>}
        width={600}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <>
          <div className=" grid grid-cols-12 gap-x-20 gap-y-5 py-[10px]">
            <div className="sm:col-span-6 col-span-12">
              <TextField
                formik={formik}
                id={'smsGatewayName'}
                label={'Gateway Name'}
                maxLength={30}
              />
            </div>
            <div className="sm:col-span-6 col-span-12">
              <PhoneInput
                label="Phone Number"
                name="fromPhoneNumber"
                value={formik.values.fromPhoneNumber}
                isModel={true}
                onChange={(value) => {
                  if (value.length <= 18) {
                    formik.setFieldValue('fromPhoneNumber', value);
                    formik.setFieldTouched('fromPhoneNumber', true, false);
                  }
                }}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.fromPhoneNumber &&
                  formik.errors.fromPhoneNumber
                }
                errorMessage={
                  formik.touched.fromPhoneNumber &&
                  formik.errors.fromPhoneNumber
                    ? formik.errors.fromPhoneNumber
                    : null
                }
              />
            </div>
            <div className="sm:col-span-6 col-span-12">
              <TextField
                formik={formik}
                id={'userName'}
                label={'User Name'}
                maxLength={30}
              />
            </div>
            <div className="sm:col-span-6 col-span-12 flex items-center mt-5">
              <Checkbox
                title="IsActive"
                id="isActive"
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                error={formik.errors.isActive}
                errorMessage={
                  formik.touched.isActive && formik.errors.isActive
                    ? formik.errors.isActive
                    : null
                }
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default EditSMSGatewayModal;
