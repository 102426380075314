import { QuestionCircleOutlined } from '@ant-design/icons';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SearchField from 'components/InputFields/SearchField';
import PopConfirm from 'components/PopConfirm/index';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import { delete_city } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { usePermissionVariables } from 'Hooks/HasPermission';
import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddCityModal from './components/AddCityModal';
import EditCityModal from './components/EditCityModal';
import { getRowNumber } from 'utils/helper';

const Cities = () => {
  const { Cities_Delete, Cities_Edit, Cities_Add, Cities_View } =
    usePermissionVariables();
  const [openAddCityModal, setOpenAddCityModal] = useState(false);
  const [openEditCityModal, setOpenEditCityModal] = useState(false);
  const [selectedCityRecord, setSelectedCityRecord] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const { useGetRequest, deleteRequest } = useApiClient();
  const [totalRecord, setTotalRecord] = useState(10);
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { data, isLoading, refetch } = useGetRequest(
    `api/GetCities?pagenumber=${pageNumber}&pagesize=${rowsPerPage}&searchquery=${search}`
  );
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });

  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const delHandler = (record) => {
    deleteRequest(`${delete_city}${record?.cityId}`)
      .then((res) => {
        if (res?.success) {
          const newTotalRecord = totalRecord - 1; // Decrement the total record count
          setTotalRecord(newTotalRecord); // Update the state

          // Check if we need to change the page number
          if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1); // Redirect to the previous page
          }
          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('success', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };
  const columns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      key: 'sr',
      // align: 'center',
      width: 80,
      ellipsis: true,
      render: (text, row, index) =>
        getRowNumber(pageNumber, rowsPerPage, index),
    },
    {
      title: <div className="flex justify-between items-center">City Name</div>,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a?.name?.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'name' ? sortOrder.order : 'ascend',
      showSorterTooltip: false,
      ellipsis: true,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: 'State Name',
      dataIndex: 'stateName',
      key: 'stateName',
      ellipsis: true,
    },
    {
      title: 'Country Name',
      dataIndex: 'countryName',
      key: 'countryName',
      ellipsis: true,
    },
    ...(Cities_Edit || Cities_Delete
      ? [
          {
            title: (
              <span className=" flex items-center justify-center">Action</span>
            ),
            dataIndex: '',
            ellipsis: true,
            width: 100,
            render: (text, record) => (
              <span className="flex justify-center items-center space-x-2">
                {Cities_Edit && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                    onClick={() => {
                      setOpenEditCityModal(true);
                      setSelectedCityRecord(record);
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                )}
                {Cities_Delete && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this city? "
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    >
                      <MdDeleteOutline className=" w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                )}
              </span>
            ),
          },
        ]
      : []),
  ];
  useEffect(() => {
    if (data) {
      setTotalRecord(data?.totalRecords);
      refetch();
    }
  }, [data]);
  return (
    <div>
      <div className="md:flex space-y-[20px] md:space-y-0 justify-between mt-10 mb-26">
        <SearchField
          htmlFor="search"
          id="myInputID"
          name="search"
          placeholder={'Search by City Name'}
          value={debouncedSearch}
          setDebouncedSearch={setDebouncedSearch}
          setPageNumber={setPageNumber}
          searchLoading={searchLoading}
          setSearchLoading={setSearchLoading}
          refetch={refetch}
          debouncedSearch={debouncedSearch}
          setSearch={setSearch}
        />
        {Cities_Add && (
          <div className="sm:flex justify-end">
            <PrimaryButton
              title="Add City"
              icon={<FaPlus />}
              onClick={() => setOpenAddCityModal(true)}
            />
          </div>
        )}
      </div>
      <Table
        data={data?.data}
        columns={columns}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        setTotalRecord={setTotalRecord}
        totalRecord={totalRecord}
        setPageNumber={setPageNumber}
        setRowsPerPage={setRowsPerPage}
        setSortOrder={setSortOrder}
        setFilters={setFilters}
        loading={isLoading}
      />
      {openAddCityModal && (
        <AddCityModal
          setOpenAddCityModal={setOpenAddCityModal}
          openAddCityModal={openAddCityModal}
          refetch={refetch}
        />
      )}
      {openEditCityModal && (
        <EditCityModal
          setOpenEditCityModal={setOpenEditCityModal}
          openEditCityModal={openEditCityModal}
          selectedCityRecord={selectedCityRecord}
          refetch={refetch}
        />
      )}
    </div>
  );
};
export default Cities;
