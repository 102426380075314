import { QuestionCircleOutlined } from '@ant-design/icons';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SearchField from 'components/InputFields/SearchField';
import PopConfirm from 'components/PopConfirm/index';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import { delete_zipCode } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { usePermissionVariables } from 'Hooks/HasPermission';
import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddZipModal from './components/AddZipModal';
import EditZipModal from './components/EditZipModal';
import { getRowNumber } from 'utils/helper';

const ZipCodes = () => {
  const { Zip_Codes_Delete, Zip_Codes_Edit, Zip_Codes_Add, Zip_Codes_View } =
    usePermissionVariables();
  const [openAddZipModal, setOpenAddZipModal] = useState(false);
  const [openEditZipModal, setOpenEditZipModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedRecord, setSelectedRecord] = useState({});

  const { useGetRequest, deleteRequest } = useApiClient();
  const [totalRecord, setTotalRecord] = useState(10);
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { data, isLoading, refetch } = useGetRequest(
    `api/GetZipCodes?pagenumber=${pageNumber}&pagesize=${rowsPerPage}&searchquery=${search}`
  );

  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });

  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const delHandler = (record) => {
    deleteRequest(`${delete_zipCode}${record?.zipCodeId}`)
      .then((res) => {
        if (res?.success) {
          const newTotalRecord = totalRecord - 1; // Decrement the total record count
          setTotalRecord(newTotalRecord); // Update the state

          // Check if we need to change the page number
          if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1); // Redirect to the previous page
          }
          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };
  const columns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      key: 'sr',
      // align: 'center',
      ellipsis: true,
      width: 80,
      render: (text, row, index) =>
        getRowNumber(pageNumber, rowsPerPage, index),
    },
    {
      title: <div className="flex justify-between items-center">Zip Code</div>,
      dataIndex: 'code',
      sorter: (a, b) => a?.code?.localeCompare(b.code),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'code' ? sortOrder.order : 'ascend',
      showSorterTooltip: false,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: 'City Name',
      dataIndex: 'cityName',
      key: 'cityName',
      ellipsis: true,
    },
    ...(Zip_Codes_Edit || Zip_Codes_Delete
      ? [
          {
            title: (
              <span className=" flex items-center justify-center">Action</span>
            ),
            dataIndex: '',
            ellipsis: true,
            width: 100,
            render: (text, record) => (
              <span className="flex justify-center items-center space-x-2">
                {Zip_Codes_Edit && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                    onClick={() => {
                      setOpenEditZipModal(true);
                      setSelectedRecord(record);
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                )}
                {Zip_Codes_Delete && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this zip code? "
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    >
                      <MdDeleteOutline className=" w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                )}
              </span>
            ),
          },
        ]
      : []),
  ];
  useEffect(() => {
    if (data) {
      setTotalRecord(data?.totalRecords);
      refetch();
    }
  }, [data]);
  return (
    <div>
      <div className="md:flex space-y-[20px] md:space-y-0 justify-between mt-10 mb-26">
        <SearchField
          htmlFor="search"
          id="myInputID"
          name="search"
          placeholder={'Search by Zip Code'}
          value={debouncedSearch}
          setDebouncedSearch={setDebouncedSearch}
          setPageNumber={setPageNumber}
          searchLoading={searchLoading}
          setSearchLoading={setSearchLoading}
          refetch={refetch}
          debouncedSearch={debouncedSearch}
          setSearch={setSearch}
        />
        {Zip_Codes_Add && (
          <div className="sm:flex justify-end">
            <PrimaryButton
              title="Add Zip Code"
              icon={<FaPlus />}
              onClick={() => setOpenAddZipModal(true)}
            />
          </div>
        )}
      </div>
      <Table
        data={data?.data}
        columns={columns}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        setTotalRecord={setTotalRecord}
        totalRecord={totalRecord}
        setPageNumber={setPageNumber}
        setRowsPerPage={setRowsPerPage}
        setSortOrder={setSortOrder}
        setFilters={setFilters}
        loading={isLoading}
      />
      {openAddZipModal && (
        <AddZipModal
          setOpenAddZipModal={setOpenAddZipModal}
          openAddZipModal={openAddZipModal}
          refetch={refetch}
        />
      )}
      {openEditZipModal && (
        <EditZipModal
          setOpenEditZipModal={setOpenEditZipModal}
          openEditZipModal={openEditZipModal}
          selectedRecord={selectedRecord}
          refetch={refetch}
        />
      )}
    </div>
  );
};
export default ZipCodes;
