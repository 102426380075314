import PrimaryButton from 'components/Buttons/PrimaryButton';
import PasswordField from 'components/CustomFields/PasswordField';
import Toast from 'components/Toast';
import ToastNotification from 'components/ToastNotification';
import useApiClient from 'config/useAxiosClient';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useValidationSchema from 'validation/useValidationSchema';

const NewPassword = () => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { contextHolder, showMessage } = Toast();
  const { t } = useTranslation('translation');
  const { newPassword, confirmPassword } = t('Label');
  const { postRequest } = useApiClient();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Fetch Query Parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const dispatch = useDispatch();
  const {
    heading,
    resetPasswordButton,
    link,
    oneLineDescription,
    manyLineDescription,
  } = t('NewPassword');
  const { newPasswordModalSchema } = useValidationSchema();
  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: newPasswordModalSchema,
    onSubmit: ({ confirmPassword }) => {
      setLoading(true);
      // dispatch(setToken(1234));
      const paylaod = {
        newPassword: confirmPassword,
        token: token,
      };
      postRequest(`/api/User/SetNewPassword`, paylaod)
        .then((res) => {
          if (res?.success) {
            ToastNotification('success', res?.message, themeMode);
            navigate('/login');
            setLoading(false);
          } else {
            ToastNotification('error', res?.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', err?.response?.data?.message, themeMode)
        )
        .finally(() => setLoading(false));
    },
  });
  return (
    <>
      {contextHolder}
      <div className=" flex">
        <div className="w-full h-[100vh]">
          <div className="w-full h-[90%] flex justify-center items-center px-[20px]">
            <div className="w-[400px]">
              <div
                className="text-[32px] font-bold text-center"
                style={{
                  color: themeMode === 'light' ? colorTheme : '',
                }}
              >
                {heading}
              </div>
              <div className=" text-[14px] text-[#676E85] mt-[10px] text-center tracking-tight">
                {oneLineDescription}
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="mt-[20px]">
                  <div className='space-y-[35px]'>
                    <div>
                      <PasswordField
                        formik={formik}
                        id={'newPassword'}
                        label={newPassword}
                        notModal={true}
                      />
                    </div>
                    <div>
                      <PasswordField
                        formik={formik}
                        id={'confirmPassword'}
                        label={confirmPassword}
                        notModal={true}
                      />
                    </div>
                  </div>
                  <div className=" flex justify-end !mt-26">
                    <div
                      className=" flex items-center space-x-5 "
                      onClick={() => {
                        navigate('/login');
                      }}
                    >
                      <div
                        className="text-[13px] font-semiBold cursor-pointer underline"
                        style={{
                          color: themeMode === 'light' ? colorTheme : '',
                        }}
                      >
                        {link}
                      </div>
                    </div>
                  </div>
                  <div>
                    <PrimaryButton
                      title={resetPasswordButton}
                      className="w-full !rounded-[6px] font-med text-[12px] mt-0 h-[38px]"
                      type="submit"
                      loading={loading}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="w-full relative h-[100vh] sm:block hidden">
          <img
            src="/images/images/movingTruck.jpg"
            className="absolute h-[100vh] w-full"
          />
          {/* <img
            src="/images/images/loginBackGround.png"
            className="absolute h-[100vh] w-full"
          />
          <div className="absolute flex flex-col justify-between h-[100vh] px-[50px] py-[100px] ">
            <div className=" space-y-5 ">
              <div className=" flex items-center justify-center">
                <img
                  src="/images/images/TRACKER APP.png"
                  className=" w-[450px] h-[40px]"
                />
              </div>
              <div className=" text-center ml-[20px] pt-[10px]  text-[#FFFFFF] text-[14px] font-reg">
                {manyLineDescription}
              </div>
            </div>
            <div className=" flex justify-center items-center">
              <img
                src="/images/images/loginBackGroundImage1.png"
                className="w-[558.58px] h-[347.11px]"
              />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default NewPassword;
