import React, { useState } from 'react';
import { Button, Modal, Input, Select } from 'antd';
import { useFormik } from 'formik';
import useValidationSchema from 'validation/useValidationSchema';
import { useQueryClient } from 'react-query';
import useApiClient from 'config/useAxiosClient';
import { SlArrowDown } from 'react-icons/sl';
import Checkbox from 'components/InputFields/Checkbox';
import InputFieldCustomForm from 'components/CustomFields/CustomFieldsOutlinedForm';
import { useSelector } from 'react-redux';
import { themeInputColor } from 'utils/constant';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import TextField from 'components/CustomFields/TextField';
import SelectField from 'components/CustomFields/SelectField';

const AddSimManagementModal = ({
  openAddSimManagementModal,
  setOpenSimManagementModal,
  refetch,
}) => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { postRequest, useGetRequest } = useApiClient();
  const queryClient = useQueryClient();
  const { SIMManagementModalSchema } = useValidationSchema();
  const { Option } = Select;
  // States
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      SIMNumber: '',
      brandName: null,
      isActive: false,
    },
    validationSchema: SIMManagementModalSchema,
    onSubmit: ({ SIMNumber, brandName, isActive }) => {
      setLoading(true);
      const paylaod = {};
      postRequest(`/api/User/AddNewUser`, paylaod)
        .then((res) => {
          if (res?.success) {
            refetch();
            formik.resetForm();
            setOpenSimManagementModal(false);
            toast.success(res.message);
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => toast.error('Something went wrong'))
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleCancel = () => {
    setOpenSimManagementModal(false);
    formik.resetForm();
  };
  return (
    <>
      <Modal
        open={openAddSimManagementModal}
        width={400}
        title={<span className="text-[18px]">Add SIM</span>}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <>
          <div className="grid grid-cols-12 gap-x-20 py-[10px]">
            <div className="col-span-12">
              <TextField formik={formik} id={'SIMNumber'} label={'SIM #'} />
            </div>
            <div className=" col-span-12">
              <SelectField
                formik={formik}
                id={'brandName'}
                label={'Brand Name'}
                optionId={'value'}
                optionName={'label'}
                optionData={[]}
              />
            </div>
          </div>
          <div className=" grid grid-cols-12">
            <div className="col-span-6">
              <Checkbox
                title="IsActive"
                id="isActive"
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                error={formik.errors.isActive}
                errorMessage={
                  formik.touched.isActive && formik.errors.isActive
                    ? formik.errors.isActive
                    : null
                }
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default AddSimManagementModal;
