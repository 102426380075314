import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import LanguageSelect from 'components/LanguageSelect';
import {
  setToken,
  setClientId,
  setUserName,
  setTenantSecretKey,
} from 'store/slices';
import { useDispatch } from 'react-redux';
import useValidationSchema from 'validation/useValidationSchema';
import useApiClient from 'config/useAxiosClient';
import Checkbox from 'components/InputFields/Checkbox';
import InputFieldCustomForm from 'components/CustomFields/CustomFieldsOutlinedForm';
import { useSelector } from 'react-redux';
import { themeInputColor } from 'utils/constant';
import { Input } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Toast from 'components/Toast';
import ToastNotification from 'components/ToastNotification';
import PasswordField from 'components/CustomFields/PasswordField';
import TextField from 'components/CustomFields/TextField';

const Login = () => {
  const { contextHolder, showMessage } = Toast();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { t } = useTranslation('translation');
  const { userName, email, password } = t('Label');
  const { postRequestAuth } = useApiClient();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    loginButton,
    heading,
    forgotPassword,
    rememberMe,
    oneLineDescription,
    manyLineDescription,
  } = t('Login');
  const { loginSchema } = useValidationSchema();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      languageSelect: '',
      rememberMe: false,
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      setLoading(true);
      // dispatch(setToken(1234));
      const paylaod = {
        userName: values?.email,
        password: values?.password,
      };
      postRequestAuth(`/Auth`, paylaod)
        .then((res) => {
          if (res?.success && res?.data) {
            ToastNotification('success', res?.message, themeMode);
            dispatch(setToken(res?.data?.token));
            dispatch(setTenantSecretKey(res?.data?.tenantKey));
            dispatch(setClientId(res?.data?.userid));
            dispatch(setUserName(res?.data?.username));
            setTimeout(() => {
              window.location.reload();
            }, 500);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => setLoading(false));
    },
  });
  return (
    <>
      <div className="flex">
        <div className="w-full h-[100vh]">
          <div className="w-full flex justify-end px-[2px] pt-[2px]">
            <div className=" w-[200px]">
              <LanguageSelect />
            </div>
          </div>
          <div className="w-full h-[90%] flex justify-center items-center px-[20px]">
            <div className="w-[400px]">
              <div
                className="text-[32px] font-bold text-center"
                style={{
                  color: themeMode === 'light' ? colorTheme : '',
                }}
              >
                {heading}
              </div>
              <div className=" text-[14px] text-[#676E85] mt-[10px] text-center">
                {oneLineDescription}
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="space-y-[40px]  sm:space-y-[16px] mt-[20px] grid">
                  {/* <div className="col-span-6"> */}
                  <TextField
                    formik={formik}
                    id={'email'}
                    label={email}
                    notModal={true}
                  />
                  <PasswordField
                    formik={formik}
                    id={'password'}
                    label={password}
                    notModal={true}
                  />
                  <div className="flex justify-end items-center !mt-16">
                    {/* <div>
                      <Checkbox
                        title={rememberMe}
                        id="rememberMe"
                        name="rememberMe"
                        checked={formik.values.rememberMe}
                        onChange={formik.handleChange}
                        error={formik.errors.rememberMe}
                        errorMessage={
                          formik.touched.rememberMe
                            ? formik.errors.rememberMe
                            : undefined
                        }
                      />
                    </div> */}
                    <div>
                      <div
                        className="text-[12px] font-semiBold cursor-pointer underline"
                        style={{
                          color: themeMode === 'light' ? colorTheme : '',
                        }}
                        onClick={() => {
                          navigate('/forgot-password');
                        }}
                      >
                        {forgotPassword}
                      </div>
                    </div>
                  </div>
                  <div>
                    <PrimaryButton
                      title={loginButton}
                      type="submit"
                      loading={loading}
                      className="w-full !rounded-[6px] font-med text-[12px] mt-0 h-[38px]"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="w-full relative sm:block hidden px-2">
          <img
            src="/images/images/movingTruck.jpg"
            className="absolute h-[100vh] w-full"
          />
          {/* <img
            src="/images/images/loginBackGround.png"
            className="absolute h-[100vh] w-full"
          />
          <div className="absolute flex flex-col justify-between h-[100vh] px-[50px] py-[100px] ">
            <div className=" space-y-5 ">
              <div className=" flex items-center justify-center">
                <img
                  src="/images/images/TRACKER APP.png"
                  className=" w-[450px] h-[40px]"
                />
              </div>
              <div className=" text-center ml-[20px] pt-[10px]  text-[#FFFFFF] text-[14px] font-reg">
                {manyLineDescription}
              </div>
            </div>
            <div className=" flex justify-center items-center">
              <img
                src="/images/images/loginBackGroundImage1.png"
                className="w-[558.58px] h-[347.11px]"
              />
            </div>
          </div>*/}
        </div>
      </div>
    </>
  );
};

export default Login;
