import dayjs from 'dayjs';
import { DATE_FORMATS, DocumentsTypeKeyValue } from 'utils/constant';
export const getRowNumber = (pageNumber, rowsPerPage, index) => {
  return (pageNumber - 1) * rowsPerPage + index + 1;
};
export const convertDateFormat = (date) =>
  dayjs(date).format(DATE_FORMATS.DATE_ONLY);

export const dayjsFormat = (date, format) => dayjs(date).format(format);

export function capitalizeWords(str) {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export function doesMenuExist(permissions, menuNames) {
  const result = {};

  menuNames?.forEach((menuName) => {
    result[menuName] = permissions.some(
      (permission) => permission.menuName === menuName
    );
  });

  return result;
}

export function transformDataRoleRights(data) {
  const roles = {};
  data?.forEach((item) => {
    if (!roles[item.roleId]) {
      roles[item.roleId] = {
        roleId: item.roleId,
        roleName: item.roleName,
        roleDescription: item.roleDescription,
        menus: {},
      };
    }

    if (!roles[item.roleId].menus[item.menuId]) {
      roles[item.roleId].menus[item.menuId] = {
        menuId: item.menuId,
        menuName: item.menuName,
        roleId: item.roleId,
        pages: [],
      };
    }

    roles[item.roleId].menus[item.menuId].pages.push({
      roleId: item.roleId,
      menuId: item.menuId,
      pageId: item.pageId,
      roleRightId: item.roleRightId,
      pageName: item.pageName,
      fullControl: item.fullControl,
      canAdd: item.canAdd,
      canEdit: item.canEdit,
      canView: item.canView,
      canDelete: item.canDelete,
    });
  });

  let roleCounter = 1;

  return Object.values(roles)
    .sort((a, b) => a.roleId - b.roleId)
    .map((role) => {
      role.sr = roleCounter++;

      let menuCounter = 1;
      role.menus = Object.values(role.menus)
        .sort((a, b) => a.menuId - b.menuId)
        .map((menu) => {
          menu.sr = `${role.sr}-${menuCounter++}`;

          let pageCounter = 1;
          menu.pages = menu.pages
            .sort((a, b) => a.pageId - b.pageId)
            .map((page) => ({
              ...page,
              sr: `${menu.sr}-${pageCounter++}`,
            }));

          return menu;
        });

      return role;
    });
}

export function transformDataPages(data) {
  // Sort the data by menuId
  data.sort((a, b) => a.menuId - b.menuId);

  const menuMap = new Map();
  let menuIndex = 1; // Initialize the menu serial number

  data.forEach((item) => {
    if (!menuMap.has(item.menuId)) {
      // Add menu entry with sr number
      menuMap.set(item.menuId, {
        key: item.menuId,
        sr: menuIndex, // Menu serial number
        menuId: item.menuId,
        menuName: item.menuName,
        pages: [],
      });

      menuIndex++; // Increment menu serial number
    }

    const menu = menuMap.get(item.menuId);

    // Add page entry with sr number in format "menu-sr"
    const pageSr = `${menu.sr}-${menu.pages.length + 1}`; // "1-1", "1-2", etc.

    menu.pages.push({
      key: item.pageId,
      sr: pageSr,
      pageId: item.pageId,
      menuId: item.menuId,
      pageName: item.pageName,
      isActive: item.isActive,
      menuId: item.menuId,
    });
  });

  return Array.from(menuMap.values());
}

export function transformData_Associate_Plan_and_Feature(data) {
  const sortedData = data?.sort((a, b) => {
    if (a.planId === b.planId) {
      return a.featureId - b.featureId;
    }
    return a.planId - b.planId;
  });

  const plansMap = {};

  sortedData?.forEach((item) => {
    if (!plansMap[item.planId]) {
      plansMap[item.planId] = {
        sr: Object.keys(plansMap).length + 1,
        planId: item.planId,
        planName: item.planName,
        features: [],
      };
    }

    plansMap[item.planId].features.push({
      sr: `${item.planId}-${plansMap[item.planId].features.length + 1}`,
      id: item.id,
      planId: item.planId,
      featureId: item.featureId,
      featureName: item.featureName,
      isActive: item.isActive,
    });
  });

  return Object.values(plansMap);
}

export const createOptions = (data, labelKey, valueKey) =>
  data?.map((item) => ({
    label: item[labelKey],
    value: item[valueKey],
  }));

export const documentOptionsLabel = (Key) => {
  const DocumentsType = DocumentsTypeKeyValue.find((x) => Key === x.value);
  return { label: DocumentsType?.label };
};
export const playSound = (soundPath) => {
  const audio = new Audio(soundPath);
  audio.play();
};
