import { QuestionCircleOutlined } from '@ant-design/icons';
import { usePermissionVariables } from 'Hooks/HasPermission';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SearchField from 'components/InputFields/SearchField';
import PopConfirm from 'components/PopConfirm';
import Status from 'components/Status';
import Table from 'components/Table';
import Toast from 'components/Toast';
import ToastNotification from 'components/ToastNotification';
import { delete_sms_gateway, get_all_sms_gateway } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import AddSMSGatewayModal from './component/AddSMSGatewayModal';
import EditSMSGatewayModal from './component/EditSMSGatewayModal';

const SMSGateway = () => {
  const {
    SMS_Gateway_Delete,
    SMS_Gateway_Edit,
    SMS_Gateway_Add,
    SMS_Gateway_View,
  } = usePermissionVariables();
  const { contextHolder, showMessage } = Toast();
  const [openAddSMSGatewayModal, setOpenAddSMSGatewayModal] = useState(false);
  const [openEditSMSGatewayModal, setOpenEditSMSGatewayModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const { useGetRequest, deleteRequest } = useApiClient();
  const { data, isLoading, refetch } = useGetRequest(get_all_sms_gateway);
  const [totalRecord, setTotalRecord] = useState(10);
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });

  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  const delHandler = (record) => {
    deleteRequest(`${delete_sms_gateway}${record?.smsSettingsId}`)
      .then((res) => {
        if (res?.success) {
          const newTotalRecord = totalRecord - 1; // Decrement the total record count
          setTotalRecord(newTotalRecord); // Update the state

          // Check if we need to change the page number
          if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1); // Redirect to the previous page
          }
          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };

  const columns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      width: 80,
      ellipsis: true,
      render: (text, row, index) => <>{index + 1}</>,
    },
    {
      title: (
        <div className="flex justify-between items-center">Gateway Name</div>
      ),
      ellipsis: true,
      dataIndex: 'smsGatewayName',
      sorter: (a, b) => a.smsGatewayName.localeCompare(b.smsGatewayName),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'smsGatewayName'
          ? sortOrder.order
          : 'ascend',
      showSorterTooltip: false,
      render: (text, record) => (
        <>
          <span>{text || 'N/A'}</span>
        </>
      ),
    },
    { title: 'User Name', dataIndex: 'username', ellipsis: true },
    { title: 'Phone Number', dataIndex: 'fromPhoneNumber', ellipsis: true },
    {
      title: <span className="flex items-center justify-center">Status</span>,
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      width: 300,
      ellipsis: true,
      render: (tag, record) => {
        return (
          <span>
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },
    ...(SMS_Gateway_Edit || SMS_Gateway_Delete
      ? [
          {
            title: (
              <span className=" flex items-center justify-center">Action</span>
            ),
            ellipsis: true,
            width: 100,
            dataIndex: '',
            render: (text, record) => (
              <span className="flex justify-center items-center space-x-2">
                {SMS_Gateway_Edit && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                    onClick={() => {
                      setSelectedRecord(record);
                      setOpenEditSMSGatewayModal(true);
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                )}
                {SMS_Gateway_Delete && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this task? "
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    >
                      <MdDeleteOutline className=" w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                )}
              </span>
            ),
          },
        ]
      : []),
  ];

  return (
    <div>
      <div className="md:flex space-y-[20px] md:space-y-0 justify-between mt-10 mb-26">
        <SearchField
          htmlFor="search"
          id="myInputID"
          name="search"
          placeholder={'Search'}
          value={debouncedSearch}
          setDebouncedSearch={setDebouncedSearch}
          setPageNumber={setPageNumber}
          searchLoading={searchLoading}
          setSearchLoading={setSearchLoading}
          refetch={refetch}
          debouncedSearch={debouncedSearch}
          setSearch={setSearch}
        />
        {SMS_Gateway_Add && (
          <div className="sm:flex justify-end">
            <PrimaryButton
              title="Add SMS Gateway"
              icon={<FaPlus />}
              onClick={() => setOpenAddSMSGatewayModal(true)}
            />
          </div>
        )}
      </div>
      <Table
        data={data?.data}
        columns={columns}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        setTotalRecord={setTotalRecord}
        totalRecord={totalRecord}
        setPageNumber={setPageNumber}
        setRowsPerPage={setRowsPerPage}
        setSortOrder={setSortOrder}
        setFilters={setFilters}
        loading={isLoading}
      />
      {openAddSMSGatewayModal && (
        <AddSMSGatewayModal
          setOpenAddSMSGatewayModal={setOpenAddSMSGatewayModal}
          openAddSMSGatewayModal={openAddSMSGatewayModal}
        />
      )}
      {openEditSMSGatewayModal && (
        <EditSMSGatewayModal
          setOpenEditSMSGatewayModal={setOpenEditSMSGatewayModal}
          openEditSMSGatewayModal={openEditSMSGatewayModal}
          selectedRecord={selectedRecord}
        />
      )}
    </div>
  );
};
export default SMSGateway;
