import { Select } from 'antd';
import Loading from 'components/Loading';
import { SlArrowDown } from 'react-icons/sl';
import { useSelector } from 'react-redux';
import { themeInputColor } from 'utils/constant';
import InputFieldCustomForm from './CustomFieldsOutlinedForm';

const SelectField = ({
  formik,
  id,
  label,
  required = true,
  optionData,
  optionId,
  optionName,
  notModal = false,
  disabled = false,
  isLoading = false,
  onChange,
  optionJSX,
}) => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { Option } = Select;

  return (
    <>
      <div className="wrapper-fields">
        <InputFieldCustomForm
          label={label}
          name={id}
          value={formik.values[id]}
          error={formik.touched[id] && Boolean(formik.errors[id])}
          errorMessage={formik.errors[id]}
          required={required}
          notModal={notModal}
        >
          <Select
            id={id}
            name={id}
            prefix={<></>}
            mode="single"
            showSearch
            optionFilterProp="label"
            value={formik.values[id]}
            autoComplete={`new-${id}`}
            onChange={
              onChange
                ? onChange
                : (newValue, _) => {
                    formik.setFieldValue(id, newValue);
                  }
            }
            disabled={disabled}
            onBlur={formik.handleBlur}
            suffixIcon={
              isLoading ? (
                <>
                  <Loading size="small" />
                </>
              ) : (
                <SlArrowDown
                  className="w-[12px] h-[12px] "
                  style={{
                    color: !(formik.touched[id] && Boolean(formik.errors[id]))
                      ? themeMode === 'light'
                        ? colorTheme
                        : themeInputColor.darkIconColor
                      : themeInputColor.errorIconColor,
                  }}
                />
              )
            }
            style={{
              border: !(formik.touched[id] && Boolean(formik.errors[id]))
                ? themeMode === 'light'
                  ? `1px solid ${themeInputColor.lightBorderColor}`
                  : `1px solid ${themeInputColor.darkBorderColor}`
                : `1px solid ${themeInputColor.errorBorderColor}`,

              borderRadius: '5px',
              caretColor:
                themeMode === 'light'
                  ? themeInputColor.lightCaretColor
                  : themeInputColor.darkCaretColor,
              color:
                themeMode === 'light'
                  ? themeInputColor.lightTextColor
                  : themeInputColor.darkTextColor,
            }}
          >
            {Array.isArray(optionData) &&
              optionData?.map((item) => {
                return (
                  <Option
                    key={item[optionId]}
                    value={item[optionId]}
                    label={item.label}
                  >
                    {optionJSX ? optionJSX(item) : item[optionName]}
                  </Option>
                );
              })}
          </Select>
        </InputFieldCustomForm>
      </div>
    </>
  );
};

export default SelectField;
