// import {
//   Circle,
//   DrawingManager,
//   GoogleMap,
//   Polygon,
//   Rectangle,
//   useLoadScript,
// } from '@react-google-maps/api';
// import { Button, Typography } from 'antd';
// import dayjs from 'dayjs';
// import relativeTime from 'dayjs/plugin/relativeTime';
// import timezone from 'dayjs/plugin/timezone';
// import utc from 'dayjs/plugin/utc';
// import Layout from 'layout/AdminLayout';
// import { debounce } from 'lodash';
// import { useCallback, useRef, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// // Extend dayjs with the necessary plugins
// dayjs.extend(utc);
// dayjs.extend(timezone);
// dayjs.extend(relativeTime);

// const { Text } = Typography;
// const libraries = ['places', 'drawing'];
// const mapContainerStyle = {
//   width: '100%',
//   height: 'calc(100vh - 195px)',
//   borderRadius: '5px',
// };
// const center = {
//   lat: 31.468854, // default latitude
//   lng: 74.451038, // default longitude
// };
// const GeoFencing = () => {
//   const { themeMode, colorTheme } = useSelector((state) => state?.states);
//   const navigate = useNavigate();
//   const mapRef = useRef();
//   const listItemRefs = useRef(new Map());
//   const [pageNumber, setPageNumber] = useState(1);
//   const [search, setSearch] = useState('');
//   const [searchLoading, setSearchLoading] = useState(false);
//   const [debouncedSearch, setDebouncedSearch] = useState('');
//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: 'AIzaSyDLGitEALPVLPoPzj7XTq2uA3Us-QutdiM',
//     libraries,
//   });

//   const data = [];
//   const [hoveredUnitId, setHoveredUnitId] = useState(null);

//   const debouncedSetHoveredUnitId = useCallback(
//     debounce((unitId) => {
//       setHoveredUnitId(unitId);
//     }, 1000),
//     []
//   );
//   //   const handleSearch = (e) => {
//   //     const value = e?.currentTarget?.value;
//   //     setFilterRequest({
//   //       ...filterRequest,
//   //       search: !isEmpty(value) ? value : undefined,
//   //     });
//   //   };
//   const [allOverlays, setAllOverlays] = useState([]);
//   const [selectedShape, setSelectedShape] = useState(null);

//   const handleCheckboxChange = (value, checked) => {};
//   const onLoad = useCallback((map) => {
//     mapRef.current = map;
//   }, []);
//   const clearSelection = () => {
//     if (selectedShape) {
//       selectedShape.setEditable(false);
//       setSelectedShape(null);
//     }
//   };
//   const setSelection = (shape) => {
//     clearSelection();
//     setSelectedShape(shape);
//     shape.setEditable(true);
//   };

//   const deleteSelectedShape = () => {
//     if (selectedShape) {
//       selectedShape.setMap(null);
//       setSelectedShape(null);
//     }
//   };

//   const deleteAllShapes = () => {
//     allOverlays.forEach((overlay) => overlay.overlay.setMap(null));
//     setAllOverlays([]);
//   };

//   const onOverlayComplete = (event) => {
//     console.log('event', event);
//     const overlay = event.overlay;
//     setAllOverlays((current) => [...current, event]);
//     if (event.type === 'polyline') {
//       const path = overlay.getPath();
//       const polylineCoords = [];
//       for (let i = 0; i < path.getLength(); i++) {
//         const point = path.getAt(i);
//         polylineCoords.push({ lat: point.lat(), lng: point.lng() });
//       }
//       console.log('Polyline Coordinates:', polylineCoords);
//     }
//     if (event.type === 'polygon') {
//       const path = overlay.getPath();
//       const polygonCoords = [];
//       for (let i = 0; i < path.getLength(); i++) {
//         const point = path.getAt(i);
//         polygonCoords.push({ lat: point.lat(), lng: point.lng() });
//       }
//       console.log('Polygon Coordinates:', polygonCoords);
//     } else if (event.type === 'circle') {
//       const center = overlay.getCenter();
//       const radius = overlay.getRadius();
//       const circleData = {
//         center: { lat: center.lat(), lng: center.lng() },
//         radius,
//       };
//       console.log('Circle Data:', circleData);
//     } else if (event.type === 'rectangle') {
//       const bounds = overlay.getBounds();
//       const ne = bounds.getNorthEast();
//       const sw = bounds.getSouthWest();
//       const rectangleCoords = {
//         northeast: { lat: ne.lat(), lng: ne.lng() },
//         southwest: { lat: sw.lat(), lng: sw.lng() },
//       };
//       console.log('Rectangle Coordinates:', rectangleCoords);
//     }

//     if (event.type !== 'marker') {
//       const newShape = overlay;
//       newShape.type = event.type;
//       overlay.addListener('click', () => {
//         setSelection(newShape);
//       });

//       setSelection(newShape);
//     }
//   };
//   const circleCenter = { lat: 34.226635428089615, lng: 72.24383923502961 };
//   const circleRadius = 28356.303289040705;
//   console.log('seelection', selectedShape, allOverlays);

//   if (loadError) {
//     return <div>Error loading maps</div>;
//   }

//   if (loadError) {
//     return <div>Error loading Google Maps API</div>;
//   }

//   return (
//     <Layout>
//       <div className="mx-[-30px]">
//         <div
//           className={`flex max-md:flex-wrap md:h-auto max-md:h-[500px] justify-between`}
//         >
//           <div
//             className={`max-md:h-[50%] md:h-[100%] md:mx-0 mx-3`}
//             style={{ width: '100%', height: '100%' }}
//           >
//             {isLoaded ? (
//               <>
//                 <div>
//                   <GoogleMap
//                     id="map"
//                     mapContainerStyle={mapContainerStyle}
//                     center={center}
//                     zoom={5}
//                     onLoad={onLoad}
//                   >
//                     <DrawingManager
//                       onOverlayComplete={onOverlayComplete}
//                       options={{
//                         drawingControl: true,
//                         drawingControlOptions: {
//                           position:
//                             window.google.maps.ControlPosition.TOP_CENTER,
//                           drawingModes: [
//                             'circle',
//                             'polygon',
//                             'rectangle',
//                             'polyline',
//                           ],
//                         },
//                         circleOptions: {
//                           fillColor: '#ffff00',
//                           fillOpacity: 0.2,
//                           strokeWeight: 3,
//                           clickable: true,
//                           editable: true,
//                           draggable: true,
//                           zIndex: 1,
//                         },
//                         polygonOptions: {
//                           clickable: true,
//                           draggable: true,
//                           editable: true,
//                           fillColor: '#ffff00',
//                           fillOpacity: 1,
//                         },
//                         rectangleOptions: {
//                           clickable: true,
//                           draggable: true,
//                           editable: true,
//                           fillColor: '#fff',
//                           fillOpacity: 0.5,
//                         },
//                       }}
//                     />
//                     <Polygon
//                       path={[
//                         { lat: 32.14305002414342, lng: 72.57138310195992 },
//                         { lat: 32.708723455047696, lng: 71.39584599258492 },
//                         { lat: 33.79283398238715, lng: 72.92294560195992 },
//                         { lat: 32.71796718591096, lng: 74.88949833633492 },
//                         { lat: 32.56069359243589, lng: 74.04355107070992 },
//                         { lat: 32.15235191982852, lng: 74.19735966445992 },
//                       ]}
//                       options={{
//                         fillColor: '#FF0000',
//                         fillOpacity: 0.4,
//                         strokeColor: '#FF0000',
//                         strokeOpacity: 1,
//                         strokeWeight: 2,
//                         clickable: false,
//                         draggable: false,
//                         editable: false,
//                       }}
//                     />
//                     <Circle
//                       center={circleCenter}
//                       radius={circleRadius}
//                       options={{
//                         fillColor: '#0000FF',
//                         fillOpacity: 0.2,
//                         strokeColor: '#0000FF',
//                         strokeOpacity: 0.8,
//                         strokeWeight: 2,
//                         clickable: false,
//                         draggable: false,
//                         editable: false,
//                       }}
//                     />
//                     <Rectangle
//                       bounds={{
//                         north: 33.379494202949154,
//                         south: 30.867678101739294,
//                         east: 81.80684160676807,
//                         west: 75.51167559114307,
//                       }}
//                       options={{
//                         fillColor: '#00FF00',
//                         fillOpacity: 0.3,
//                         strokeColor: '#00FF00',
//                         strokeOpacity: 0.8,
//                         strokeWeight: 2,
//                         clickable: false,
//                         draggable: false,
//                         editable: false,
//                       }}
//                     />
//                   </GoogleMap>

//                   <Button onClick={deleteSelectedShape}>
//                     Delete Selected Area
//                   </Button>
//                   <Button onClick={deleteAllShapes}>Delete All Areas</Button>
//                 </div>
//               </>
//             ) : (
//               <>
//                 <div className="w-full flex items-center justify-center">
//                   Loading
//                 </div>
//               </>
//             )}
//           </div>
//         </div>
//       </div>
//     </Layout>
//   );
// };

// export default GeoFencing;
import {
  Autocomplete,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import { Input } from 'antd';
import InputFieldCustomForm from 'components/CustomFields/CustomFieldsOutlinedForm';
import { useState } from 'react';

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

// Default map container styles
const mapContainerStyle = {
  width: '100%',
  height: '500px',
};

// Default map center
const defaultCenter = {
  lat: 22.54992,
  lng: 0,
};

const Geofencing = () => {
  const [map, setMap] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [markerPosition, setMarkerPosition] = useState(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: API_KEY,
    libraries: ['places'], // Ensure the Places library is loaded
  });

  const handleLoad = (mapInstance) => {
    setMap(mapInstance);
  };

  const handleAutocompleteLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const handlePlaceSelect = () => {
    if (!autocomplete) return;

    const place = autocomplete.getPlace();
    if (place.geometry) {
      setSelectedPlace(place);
      const location = place.geometry.location;

      // Update map and marker
      setMarkerPosition({ lat: location.lat(), lng: location.lng() });
      setSearchValue(place?.formatted_address);

      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.panTo(location);
        map.setZoom(15);
      }
    }
  };

  return isLoaded ? (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <div className="wrapper-fields">
          <InputFieldCustomForm
            label="Address"
            name="pickupAddress"
            notModal={true}
            required={true}
            value={selectedPlace?.formatted_address || ''}
          >
            <Autocomplete
              onLoad={handleAutocompleteLoad}
              onPlaceChanged={handlePlaceSelect}
            >
              <Input
                type="text"
                placeholder=""
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </Autocomplete>
          </InputFieldCustomForm>
        </div>
      </div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={defaultCenter}
        zoom={3}
        onLoad={handleLoad}
        options={{
          disableDefaultUI: true,
          gestureHandling: 'greedy',
        }}
      >
        {markerPosition && <Marker position={markerPosition} />}
      </GoogleMap>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default Geofencing;
