import React, { useMemo, useState } from 'react';
import { PiUsersThreeFill } from 'react-icons/pi';
import Layout from 'layout/AdminLayout';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { MdDeleteOutline } from 'react-icons/md';
import { FaPlus } from 'react-icons/fa6';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Table from 'components/Table';
import { Breadcrumb } from 'antd';
import { GoHome } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';
import useApiClient from 'config/useAxiosClient';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Status from 'components/Status';
import { BsSimFill } from 'react-icons/bs';
import PopConfirm from 'components/PopConfirm';
import { toast } from 'react-toastify';
import { usePermissionVariables } from 'Hooks/HasPermission';
import EditSimManagementModal from './component/EditSimManagementModal';
import AddSimManagementModal from './component/AddSimManagementModal';
const { Title } = Typography;

const SimManagement = () => {
  const { useGetRequest, deleteRequest } = useApiClient();
  const {
    SIM_Mangement_View,
    SIM_Mangement_Add,
    SIM_Mangement_Edit,
    SIM_Mangement_Delete,
  } = usePermissionVariables();

  const { data, error, isLoading, refetch } = useGetRequest(
    '/api/User/GetAllUsers'
  );
  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  // States
  const [openAddSimManagementModal, setOpenSimManagementModal] =
    useState(false);
  const [openEditSimManagementModal, setOpenEditSimManagementModal] =
    useState(false);
  const [rowData, setRowData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const delHandler = (record) => {
    deleteRequest(`/api/User/DeleteUser?userId=${record?.userId}`)
      .then((res) => {
        if (res?.success) {
          refetch();
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => toast.error('Something went wrong'));
  };

  const columns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      width: 80,
      ellipsis: true,
      render: (text, row, index) => index + 1,
    },
    {
      title: 'SIM #',
      dataIndex: 'simNumber',
      ellipsis: true,
      render: (text, record) => (
        <>
          <span>{'111-222-0'}</span>
        </>
      ),
    },
    {
      title: 'Brand Name',
      dataIndex: 'brandName',
      ellipsis: true,
      render: (text, record) => (
        <>
          <span>{'Mobilink'}</span>
        </>
      ),
    },
    {
      title: <span className="flex items-center justify-center">Status</span>,
      dataIndex: 'isActive',
      ellipsis: true,
      width: 300,
      render: (tag, record) => {
        return (
          <span className="flex items-center justify-center">
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },
    ...(SIM_Mangement_Edit || SIM_Mangement_Delete
      ? [
          {
            title: (
              <span className=" flex items-center justify-center">Action</span>
            ),
            dataIndex: '',
            width: 100,
            ellipsis: true,
            render: (text, record) => (
              <span className="flex justify-center items-center space-x-2">
                {SIM_Mangement_Edit && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                    onClick={() => {
                      setRowData(record);
                      setOpenEditSimManagementModal(true);
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                )}
                {SIM_Mangement_Delete && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this task? "
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={
                        <div className=" flex items-center justify-center ">
                          <QuestionCircleOutlined
                            style={{ color: 'red', marginTop: '5px' }}
                          />
                        </div>
                      }
                    >
                      <MdDeleteOutline className=" w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                )}
              </span>
            ),
          },
        ]
      : []),
  ];

  return (
    <Layout>
      <>
        <div>
          <div className=" mb-[30px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
              }}
            >
              Tracker Settings
            </Title>
            <div className="flex justify-between items-center ">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className="flex items-center space-x-4 ">
                            <BsSimFill
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />

                            <span
                              className="font-bold text-[13px]"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              SIM Management
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
              {SIM_Mangement_Add && (
                <div>
                  <PrimaryButton
                    title="Add SIM"
                    icon={<FaPlus />}
                    onClick={() => {
                      setOpenSimManagementModal(true);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            <Table
              colorTheme={colorTheme}
              data={data?.data || []}
              columns={columns}
              pageNumber={pageNumber}
              rowsPerPage={rowsPerPage}
              totalRecord={totalRecord}
              setTotalRecord={setTotalRecord}
              setPageNumber={setPageNumber}
              setRowsPerPage={setRowsPerPage}
              setSortOrder={setSortOrder}
              setFilters={setFilters}
              loading={isLoading}
            />
          </div>
          {openAddSimManagementModal && (
            <AddSimManagementModal
              setOpenSimManagementModal={setOpenSimManagementModal}
              openAddSimManagementModal={openAddSimManagementModal}
              refetch={refetch}
            />
          )}

          {openEditSimManagementModal && rowData && (
            <EditSimManagementModal
              setOpenEditSimManagementModal={setOpenEditSimManagementModal}
              openEditSimManagementModal={openEditSimManagementModal}
              record={rowData}
              refetch={refetch}
            />
          )}
        </div>
      </>
    </Layout>
  );
};
export default SimManagement;
