import { Button, Modal, Select } from 'antd';
import SelectField from 'components/CustomFields/SelectField';
import Checkbox from 'components/InputFields/Checkbox';
import ToastNotification from 'components/ToastNotification';
import useApiClient from 'config/useAxiosClient';
import useAxiosClientTMS from 'config/useAxiosClientTMS';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import useValidationSchema from 'validation/useValidationSchema';

const AddAssignDeviceModal = ({
  openAddAssignDeviceModal,
  setOpenAddAssignDeviceModal,
  refetch,
}) => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { postRequest, useGetRequest } = useApiClient();
  const { useGetRequest: useGetRequest3 } = useAxiosClientTMS();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const { assignDeviceModalSchema } = useValidationSchema();
  const { data: tenantData, isLoading: tenantLoading } = useGetRequest(
    '/api/GeTenantsDropdown'
  );
  const tenantOptions = tenantData?.data?.map((item) => {
    return {
      value: item.tenantId,
      label: item.tenantName,
    };
  });
  const { data, error, isLoading } = useGetRequest('/api/GetAllDevices');
  const deviceOptions = data?.data?.map((item) => {
    return {
      value: item.deviceId,
      label: item.deviceName,
    };
  });
  const { Option } = Select;
  const formik = useFormik({
    initialValues: {
      tenantId: null,
      vehicle: null,
      device: null,
      isActive: false,
    },
    validationSchema: assignDeviceModalSchema,
    onSubmit: ({ tenantId, device, vehicle, isActive }) => {
      setLoading(true);
      const paylaod = {
        tenantId: tenantId,
        vehicleId: vehicle,
        deviceId: device,
        status: 'Assigned',
        isActive: isActive,
      };
      postRequest('/api/AddDeviceAssign', paylaod)
        .then((res) => {
          if (res?.success) {
            refetch();
            formik.resetForm();
            ToastNotification('success', res.message, themeMode);
            setOpenAddAssignDeviceModal(false);
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const {
    data: vehicles,
    refetch: vehiclesRefetch,
    isLoading: vehiclesLoading,
  } = useGetRequest3(
    `/api/GetVehiclesDropdownByTenantId?id=${formik.values.tenantId}`,
    {
      enabled: false,
    }
  );
  const vehicleOptions = vehicles?.data?.map((item) => {
    return {
      value: item.id,
      label: item.number,
    };
  });
  const handleCancel = () => {
    setOpenAddAssignDeviceModal(false);
    formik.resetForm();
  };
  useEffect(() => {
    if (formik.values.tenantId !== null) vehiclesRefetch();
  }, [formik.values.tenantId]);

  return (
    <>
      <Modal
        open={openAddAssignDeviceModal}
        title={<span className=" text-[18px]">Assign Device</span>}
        width={400}
        onOk={formik.handleSubmit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" className="!shadow-none" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>,
        ]}
      >
        <>
          <div className=" grid grid-cols-12 gap-x-20  py-[5px]">
            <div className=" col-span-12">
              <SelectField
                formik={formik}
                id={'tenantId'}
                label={'Tenant'}
                optionId={'value'}
                optionName={'label'}
                optionData={tenantOptions}
              />
            </div>
            <div className=" col-span-12">
              <SelectField
                formik={formik}
                id={'vehicle'}
                label={'Vehicle'}
                optionId={'value'}
                optionName={'label'}
                optionData={vehicleOptions}
                isLoading={vehiclesLoading}
              />
            </div>
            <div className=" col-span-12">
              <SelectField
                formik={formik}
                id={'device'}
                label={'Device'}
                optionId={'value'}
                optionName={'label'}
                optionData={deviceOptions}
              />
            </div>
            <div className="col-span-12 mt-[10px]">
              <Checkbox
                title="IsActive"
                id="isActive"
                name="isActive"
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                // error={formik.errors.isActive}
                // errorMessage={
                //   formik.touched.isActive && formik.errors.isActive
                //     ? formik.errors.isActive
                //     : null
                // }
              />
            </div>
          </div>
        </>
      </Modal>
    </>
  );
};
export default AddAssignDeviceModal;
