import { QuestionCircleOutlined } from '@ant-design/icons';
import { Breadcrumb, Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Dropdown from 'components/Dropdown/index';
import SearchField from 'components/InputFields/SearchField';
import PopConfirm from 'components/PopConfirm';
import Status from 'components/Status';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import useAxiosClientTMS from 'config/useAxiosClientTMS';
import { usePermissionVariables } from 'Hooks/HasPermission';
import Layout from 'layout/AdminLayout';
import { useEffect, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaTrailer } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa6';
import { GoHome } from 'react-icons/go';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GET_TRAILERS_KEY } from 'utils/queryKey';
const { Title } = Typography;

const Driver = () => {
  const { useGetRequest, deleteRequest } = useAxiosClientTMS();
  const { Drivers_Add, Drivers_Edit, Drivers_Delete } =
    usePermissionVariables();

  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  // States
  const [openAddUserModal, setOpenUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({});
  const [search, setSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const { data, error, isLoading, refetch } = useGetRequest(
    `/api/GetAllTrailer?pagenumber=${pageNumber}&pagesize=${rowsPerPage}&searchquery=${search}`,
    {},
    GET_TRAILERS_KEY
  );
  const delHandler = (record) => {
    deleteRequest(`api/DeleteTrailer?id=${record?.trailerId}`)
      .then((res) => {
        if (res?.success) {
          const newTotalRecord = totalRecord - 1; // Decrement the total record count
          setTotalRecord(newTotalRecord); // Update the state

          // Check if we need to change the page number
          if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1); // Redirect to the previous page
          }
          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };

  const columns = [
    {
      title: 'Sr #',
      dataIndex: 'sr',
      ellipsis: true,
      width: 80,
      render: (text, row, index) => <>{index + 1}</>,
    },
    {
      title: (
        <div className="flex justify-between items-center">Trailer Number</div>
      ),
      dataIndex: 'trailerNumber',
      ellipsis: true,
      sorter: (a, b) => a.trailerNumber.localeCompare(b.trailerNumber),
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder:
        sortOrder?.column?.dataIndex === 'trailerNumber'
          ? sortOrder.order
          : null,

      render: (text) => {
        return <>{text}</>;
      },
    },
    {
      title: 'Trailer Type',
      dataIndex: 'trailerType',
      ellipsis: true,
      sorter: (a, b) => a.trailerType.localeCompare(b.trailerType),
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder:
        sortOrder?.column?.dataIndex === 'trailerType' ? sortOrder.order : null,
      render: (text) => <>{text}</>,
    },
    {
      title: 'Capacity',
      dataIndex: 'capacity',
      ellipsis: true,
      sorter: (a, b) => a.capacity.localeCompare(b.capacity),
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder:
        sortOrder?.column?.dataIndex === 'capacity' ? sortOrder.order : null,

      render: (text) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: 'Width',
      dataIndex: 'width',
      ellipsis: true,
      sorter: (a, b) => a.width.localeCompare(b.width),
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder:
        sortOrder?.column?.dataIndex === 'width' ? sortOrder.order : null,

      render: (text) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: 'Height',
      dataIndex: 'height',
      ellipsis: true,
      sorter: (a, b) => a.height.localeCompare(b.height),
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder:
        sortOrder?.column?.dataIndex === 'height' ? sortOrder.order : null,

      render: (text) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: 'Length',
      dataIndex: 'length',
      ellipsis: true,
      sorter: (a, b) => a.length.localeCompare(b.length),
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      sortOrder:
        sortOrder?.column?.dataIndex === 'length' ? sortOrder.order : null,

      render: (text) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: (
        <span className="flex items-center justify-center">Trailer Status</span>
      ),
      dataIndex: 'status',
      ellipsis: true,
      render: (tag, record) => {
        return (
          <span className="flex items-center justify-center">
            <Status tag={record?.status} />
          </span>
        );
      },
    },
    {
      title: <span className="flex items-center justify-center">Status</span>,
      dataIndex: 'isActive',
      ellipsis: true,
      render: (tag, record) => {
        return (
          <span className="flex items-center justify-center">
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },
    {
      title: <span className="flex items-center justify-center">Action</span>,
      dataIndex: '',
      ellipsis: true,
      width: 80,
      render: (text, record) => (
        <div className="flex justify-center">
          <Dropdown
            overlayClassName={`${
              themeMode === 'light'
                ? 'action-dropdown-light'
                : 'action-dropdown-dark'
            }`}
            items={[
              {
                key: '1',
                label: (
                  <div
                    className="p-[2px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                ),
                onClick: () => {
                  navigate(`/edit-trailer/${record?.trailerId}`, {
                    state: {
                      pageNumber: pageNumber,
                    },
                  });
                },
              },
              {
                key: '2',
                label: (
                  <div
                    className="p-[2px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this task?"
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={
                        <div className="flex items-center justify-center">
                          <QuestionCircleOutlined
                            style={{
                              color: 'red',
                              marginTop: '5px',
                            }}
                          />
                        </div>
                      }
                    >
                      <MdDeleteOutline className="w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                ),
              },
            ]}
          >
            <div className="!underline cursor-pointer hover:underline">
              <BsThreeDotsVertical className="w-[15px] h-[15px] cursor-pointer" />
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];
  // useEffect(() => {
  //   refetch();
  // }, []);

  useEffect(() => {
    if (data) {
      setTotalRecord(data?.totalRecords);
    }
  }, [data]);

  return (
    <Layout>
      <>
        <div>
          <div className=" mb-[30px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
              }}
            >
              TMS
            </Title>
            <div className="flex justify-between items-center ">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className="flex items-center space-x-4 ">
                            <FaTrailer
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />

                            <span
                              className="font-bold text-[13px]"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              Trailer Management
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="md:flex space-y-[20px] md:space-y-0 justify-between mt-10 mb-26">
              <SearchField
                htmlFor="search"
                id="myInputIDTrailerName"
                name="search"
                placeholder={'Search by Trailer Name'}
                value={debouncedSearch}
                setDebouncedSearch={setDebouncedSearch}
                setPageNumber={setPageNumber}
                searchLoading={searchLoading}
                setSearchLoading={setSearchLoading}
                refetch={refetch}
                debouncedSearch={debouncedSearch}
                setSearch={setSearch}
              />
              {Drivers_Add && (
                <div className="sm:flex justify-end">
                  <PrimaryButton
                    title="Add Trailer"
                    icon={<FaPlus />}
                    onClick={() => {
                      navigate(`/add-trailer`, {
                        state: {
                          totalPage: Math.ceil(data?.totalRecords / 10),
                        },
                      });
                    }}
                  />
                </div>
              )}
            </div>
            <Table
              data={data?.data || []}
              columns={columns}
              pageNumber={pageNumber}
              setTotalRecord={setTotalRecord}
              rowsPerPage={rowsPerPage}
              totalRecord={totalRecord}
              setPageNumber={setPageNumber}
              setRowsPerPage={setRowsPerPage}
              setSortOrder={setSortOrder}
              setFilters={setFilters}
              loading={isLoading}
            />
          </div>
        </div>
      </>
    </Layout>
  );
};
export default Driver;
