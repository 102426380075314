const GET_ORDERS_KEY = 'GetAllOrders';
const GET_VEHICLES_KEY = 'GetAllVehicles';
const GET_DEVICES_KEY = 'GetAllDevices';
const GET_LOADS_KEY = 'GetAllLoads';
const GET_SUBSCRIPTION_PLAN_KEY = 'GetAllSubscriptionPlans';
const GET_TRAILERS_KEY = 'GetAllTrailer';
const GET_TENANTS_KEY = 'GetAllTenants';
const GET_DRIVERS_KEY = 'GetAlLdrivers';
const GET_DOCUMENTS_BY_ENTITY_TYPE_DRIVER_DOCUMENTS =
  'GetDocumentsByEntityTypeDriverdocuments';
const GET_DOCUMENTS_BY_ENTITY_TYPE_VEHICLE_DOCUMENTS =
  'GetDocumentsByEntityTypeVehicledocument';
const GET_DRIVERS_BY_ID_KEY = 'GetDriverById';
const GET_DRIVERS_DROPDOWN_KEY = 'GetdriversDropdown';
const GET_VEHICLES_DROPDOWN_KEY = 'GetdriversDropdown';
const GET_TRAILERS_DROPDOWN_KEY = 'GetdriversDropdown';
const GET_CUSTOMERS_DROPDOWN_KEY = 'GetCustomerDropdown';
const GET_COUNTRIES_DROPDOWN_KEY = 'GetCountriesdropdown';
const GET_LOADBYIDASYNC_KEY = 'GetLoadByIdAsync';
const GET_ALL_DISPATCHERS_KEY = 'GetAllDispatchers';
const GET_DISPATCHERS_BY_ID_KEY = 'GetDispatcherById';
const GET_DISPATCHERS_DROPDOWN_KEY = 'GetDispatcherById';
const GET_FLEET_VIEW_KEY = 'GetFleetView';
export {
  GET_ALL_DISPATCHERS_KEY,
  GET_COUNTRIES_DROPDOWN_KEY,
  GET_CUSTOMERS_DROPDOWN_KEY,
  GET_DISPATCHERS_BY_ID_KEY,
  GET_DISPATCHERS_DROPDOWN_KEY,
  GET_DOCUMENTS_BY_ENTITY_TYPE_DRIVER_DOCUMENTS,
  GET_DOCUMENTS_BY_ENTITY_TYPE_VEHICLE_DOCUMENTS,
  GET_DRIVERS_BY_ID_KEY,
  GET_DRIVERS_DROPDOWN_KEY,
  GET_DRIVERS_KEY,
  GET_FLEET_VIEW_KEY,
  GET_LOADBYIDASYNC_KEY,
  GET_DEVICES_KEY,
  GET_LOADS_KEY,
  GET_ORDERS_KEY,
  GET_SUBSCRIPTION_PLAN_KEY,
  GET_TENANTS_KEY,
  GET_TRAILERS_DROPDOWN_KEY,
  GET_TRAILERS_KEY,
  GET_VEHICLES_DROPDOWN_KEY,
  GET_VEHICLES_KEY,
};
