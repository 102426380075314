import React, { useState } from 'react';
import { Breadcrumb } from 'antd';
import LabelGrid from 'components/LabelGrid';
import Layout from 'layout/AdminLayout';
import { GoHome } from 'react-icons/go';
import { BiSave } from 'react-icons/bi';
import TextField from 'components/InputFields/TextField';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { PiUsersThreeBold } from 'react-icons/pi';
import { Typography } from 'antd';
import { MdModeEditOutline } from "react-icons/md";
import { useSelector } from 'react-redux';

const { Title } = Typography;

const EditUser = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [iconsVisible, setIconsVisible] = useState(false);
  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    setSelectedImage(selectedFile);
    e.target.value = null;
    //   if (selectedFile && selectedFile.type.startsWith('image/')) {
    //     // Check image size
    //     const maxSizeInBytes = 2.5 * 1024 * 1024;
    //     if (selectedFile.size > maxSizeInBytes) {
    //       toast.error('Please Select an Image File Smaller Than 2.5 MB.');
    //       e.target.value = null;
    //       return;
    //     }
    //     const img = new Image();
    //     img.src = URL.createObjectURL(selectedFile);
    //     img.onload = function () {
    //       if (this.width >= 600 && this.height >= 700) {
    //         setSelectedImage(selectedFile);
    //         formik.setFieldValue('image', selectedFile);
    //       } else {
    //         toast.error(
    //           'Please select an image with dimensions greater than or equal to 700x700.'
    //         );
    //         e.target.value = null;
    //       }
    //     };
    //   } else {
    //     toast.error('Please Select a valid Image File.');
    //     e.target.value = null;
    //   }
  };
  const handleRemoveImage = () => {
    setSelectedImage(null);
  };
  return (
    <Layout>
      <div>
        <div className=" mb-[30px] ">
          <Title
            level={4}
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
              fontFamily: 'inter-bold',
            }}
          >
            Edit User
          </Title>
          <div className=" flex justify-between items-center ">
            <div>
              <Breadcrumb
                separator="/"
                items={[
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <GoHome />
                          <span
                            className=" cursor-pointer font-reg"
                            onClick={() => {
                              navigate('/dashboard');
                            }}
                          >
                            Dashboard
                          </span>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <PiUsersThreeBold />
                          <span
                            className=" cursor-pointer font-reg"
                            onClick={() => {
                              navigate('/users');
                            }}
                          >
                            Users
                          </span>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <MdModeEditOutline   style ={{color : themeMode === 'light' ? colorTheme : ""}} />
                          <span className=" font-bold"   style ={{color : themeMode === 'light' ? colorTheme : ""}}>Edit User</span>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
            </div>
            <div>
              <PrimaryButton
                title="Save"
                icon={<BiSave className=" w-[17px] h-[17px]" />}
              />
            </div>
          </div>
        </div>
        <div className=" mb-[40px]">
          <LabelGrid
            label="Basic Information"
            className="border-[#f0f0f0] border-[1px] rounded-[5px] px-20 py-20"
          >
            <div className="grid grid-cols-12 sm:gap-x-[30px] gap-y-[25px] sm:gap-y-0 mt-[20px]">
              <div className=" col-span-12 sm:col-span-3 ">
                {selectedImage ? (
                  <>
                    <div
                      className="w-full relative "
                      // onMouseEnter={() => {
                      //   setIconsVisible(true);
                      // }}
                      // onMouseLeave={() => {
                      //   setIconsVisible(false);
                      // }}
                    >
                      <div className="relative z-0">
                        <img
                          src={URL.createObjectURL(selectedImage)}
                          alt="Selected"
                          className=" w-full h-[100px] object-fill flex items-center justify-center rounded-[8px] "
                        />
                      </div>
                      {/* {iconsVisible && (
                      <div className="absolute z-10 top-[1px] flex items-center justify-center w-full h-[100px]  rounded-[8px] ">
                        <div className=" flex items-center justify-center space-x-5">
                          <div className=" text-white cursor-pointer">
                            <EyeOutlined />
                          </div>
                          <div
                            className=" text-white cursor-pointer"
                            onClick={handleRemoveImage}
                          >
                            <DeleteOutlined />
                          </div>
                        </div>
                      </div>
                    )} */}
                    </div>
                  </>
                ) : (
                  <>
                    <label
                      htmlFor="upload-image"
                      className=" w-full h-[100px] border-[#113D4E] border-dashed border-[1px] font-semibold text-[14px]  flex items-center justify-center rounded-[8px] cursor-pointer"
                      style={{
                        border:
                          themeMode === 'light'
                            ? `1px dashed ${colorTheme}`
                            : '1px dashed white',
                        color: themeMode === 'light' ? '#000000' : '#9F9B93',
                      }}
                    >
                      Upload Image here
                    </label>
                  </>
                )}
                <input
                  className=" hidden"
                  type="file"
                  id="upload-image"
                  accept="image/jpeg, image/png, image/jpg"
                  name="image"
                  onChange={handleImageChange}
                />
              </div>
              <div className=" col-span-12 sm:col-span-3">
                <div>
                  <TextField
                    id="name"
                    label="Name"
                    // type="text"
                    // name="email"
                    // placeholder="Enter User Email"
                    // value={formik.values.email}
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    // error={formik.touched.email && Boolean(formik.errors.email)}
                    // errorMessage={
                    //   formik.touched.email && formik.errors.email
                    //     ? formik.errors.email
                    //     : null
                    // }
                  />
                </div>
              </div>
              <div className=" col-span-12 sm:col-span-3">
                <div>
                  <TextField
                    id="email"
                    label="Email"
                    // type="text"
                    // name="email"
                    // placeholder="Enter User Email"
                    // value={formik.values.email}
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    // error={formik.touched.email && Boolean(formik.errors.email)}
                    // errorMessage={
                    //   formik.touched.email && formik.errors.email
                    //     ? formik.errors.email
                    //     : null
                    // }
                  />
                </div>
              </div>
              <div className=" col-span-12 sm:col-span-3">
                <div>
                  <TextField
                    id="role"
                    label="Role"
                    // type="text"
                    // name="email"
                    // placeholder="Enter User Email"
                    // value={formik.values.email}
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    // error={formik.touched.email && Boolean(formik.errors.email)}
                    // errorMessage={
                    //   formik.touched.email && formik.errors.email
                    //     ? formik.errors.email
                    //     : null
                    // }
                  />
                </div>
              </div>
            </div>
          </LabelGrid>
        </div>
        <div>
          <LabelGrid
            label="Address & Contact"
            className="border-[#f0f0f0] border-[1px] rounded-[5px] px-20 py-20"
          >
            <div className="grid grid-cols-12 sm:gap-x-[30px] gap-y-[25px] sm:gap-y-0 mt-[20px]">
              <div className=" col-span-12 sm:col-span-3">
                <div>
                  <TextField
                    id="address1"
                    label="Address"
                    // type="text"
                    // name="email"
                    // placeholder="Enter User Email"
                    // value={formik.values.email}
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    // error={formik.touched.email && Boolean(formik.errors.email)}
                    // errorMessage={
                    //   formik.touched.email && formik.errors.email
                    //     ? formik.errors.email
                    //     : null
                    // }
                  />
                </div>
              </div>
              <div className=" col-span-12 sm:col-span-3">
                <div>
                  <TextField
                    id="address2"
                    label="Address2"
                    // type="text"
                    // name="email"
                    // placeholder="Enter User Email"
                    // value={formik.values.email}
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    // error={formik.touched.email && Boolean(formik.errors.email)}
                    // errorMessage={
                    //   formik.touched.email && formik.errors.email
                    //     ? formik.errors.email
                    //     : null
                    // }
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 sm:gap-x-[30px] gap-y-[25px] sm:gap-y-0 mt-[20px] ">
              <div className=" col-span-12 sm:col-span-3">
                <div>
                  <TextField
                    id="countryCode"
                    label="Country Code"
                    // type="text"
                    // name="email"
                    // placeholder="Enter User Email"
                    // value={formik.values.email}
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    // error={formik.touched.email && Boolean(formik.errors.email)}
                    // errorMessage={
                    //   formik.touched.email && formik.errors.email
                    //     ? formik.errors.email
                    //     : null
                    // }
                  />
                </div>
              </div>
              <div className=" col-span-12 sm:col-span-3">
                <div>
                  <TextField
                    id="city"
                    label="City"
                    // type="text"
                    // name="email"
                    // placeholder="Enter User Email"
                    // value={formik.values.email}
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    // error={formik.touched.email && Boolean(formik.errors.email)}
                    // errorMessage={
                    //   formik.touched.email && formik.errors.email
                    //     ? formik.errors.email
                    //     : null
                    // }
                  />
                </div>
              </div>
              <div className=" col-span-12 sm:col-span-3">
                <div>
                  <TextField
                    id="state"
                    label="State"
                    // type="text"
                    // name="email"
                    // placeholder="Enter User Email"
                    // value={formik.values.email}
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    // error={formik.touched.email && Boolean(formik.errors.email)}
                    // errorMessage={
                    //   formik.touched.email && formik.errors.email
                    //     ? formik.errors.email
                    //     : null
                    // }
                  />
                </div>
              </div>
              <div className=" col-span-12 sm:col-span-3">
                <div>
                  <TextField
                    id="zipCode"
                    label="Zip Code"
                    // type="text"
                    // name="email"
                    // placeholder="Enter User Email"
                    // value={formik.values.email}
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    // error={formik.touched.email && Boolean(formik.errors.email)}
                    // errorMessage={
                    //   formik.touched.email && formik.errors.email
                    //     ? formik.errors.email
                    //     : null
                    // }
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 sm:gap-x-[30px] gap-y-[25px] sm:gap-y-0 mt-[20px] ">
              <div className=" col-span-12 sm:col-span-3">
                <div>
                  <TextField
                    id="phoneNumber"
                    label="Phone No."
                    // type="text"
                    // name="email"
                    // placeholder="Enter User Email"
                    // value={formik.values.email}
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    // error={formik.touched.email && Boolean(formik.errors.email)}
                    // errorMessage={
                    //   formik.touched.email && formik.errors.email
                    //     ? formik.errors.email
                    //     : null
                    // }
                  />
                </div>
              </div>
              <div className=" col-span-12 sm:col-span-3">
                <div>
                  <TextField
                    id="mobileNumber"
                    label="Mobile Phone No."
                    // type="text"
                    // name="email"
                    // placeholder="Enter User Email"
                    // value={formik.values.email}
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    // error={formik.touched.email && Boolean(formik.errors.email)}
                    // errorMessage={
                    //   formik.touched.email && formik.errors.email
                    //     ? formik.errors.email
                    //     : null
                    // }
                  />
                </div>
              </div>
            </div>
          </LabelGrid>
        </div>
      </div>
    </Layout>
  );
};

export default EditUser;
