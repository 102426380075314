import { Breadcrumb, Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import { delete_users, get_users } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { usePermissionVariables } from 'Hooks/HasPermission';
import Layout from 'layout/AdminLayout';
import { useEffect, useState } from 'react';
import { BiSolidDollarCircle } from 'react-icons/bi';
import { FaPlus } from 'react-icons/fa6';
import { GoHome } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customerData } from 'utils/constant';
import RateCardActions from './components/RateCardActions';

const { Title } = Typography;

const RateCardManagement = () => {
  const { useGetRequest, deleteRequest } = useApiClient();
  const { Users_View, Users_Add, Users_Edit, Users_Delete } =
    usePermissionVariables();

  const { data, error, isLoading, refetch } = useGetRequest(get_users);
  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  // States
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });
  const delHandler = (record) => {
    deleteRequest(`${delete_users}${record?.userId}`)
      .then((res) => {
        if (res?.success) {
          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };
  const columns = [
    {
      title: (
        <div className="flex justify-between items-center">Service Name</div>
      ),
      ellipsis: true,
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'name' ? sortOrder.order : 'ascend',

      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    {
      title: 'Price per Kilometer/Unit',
      dataIndex: 'contactPerson',
      ellipsis: true,
    },
    { title: 'Base Rate', dataIndex: 'emailAddress', ellipsis: true },
    {
      title: 'Price per Weight Unit',
      dataIndex: 'phoneNumber',
      ellipsis: true,
    },
    {
      title: 'Valid From/To Dates ',
      dataIndex: 'businessType',
      ellipsis: true,
    },

    {
      title: <span className=" flex items-center justify-center">Action</span>,
      dataIndex: '',
      ellipsis: true,
      width: 100,
      render: (text, record) => (
        <span className="flex justify-center items-center space-x-2">
          <div
            className=" p-[5px] rounded-sm"
            onClick={() => {}}
            style={{
              backgroundColor: themeMode === 'light' ? 'white' : '#121212',
            }}
          >
            <RateCardActions />
          </div>
        </span>
      ),
    },
  ];

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Layout>
      <>
        <div>
          <div className=" mb-[30px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
              }}
            >
              TMS
            </Title>
            <div className="flex justify-between items-center ">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className="flex items-center space-x-4 ">
                            <BiSolidDollarCircle
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />

                            <span
                              className="font-bold text-[13px]"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              Rate Management
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
              {Users_Add && (
                <div>
                  <PrimaryButton
                    title="Add Card"
                    icon={<FaPlus />}
                    onClick={() => {
                      navigate(`/add-rate-card-management`);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            <Table
              data={customerData}
              columns={columns}
              pageNumber={pageNumber}
              setTotalRecord={setTotalRecord}
              rowsPerPage={rowsPerPage}
              totalRecord={totalRecord}
              setPageNumber={setPageNumber}
              setRowsPerPage={setRowsPerPage}
              setSortOrder={setSortOrder}
              setFilters={setFilters}
              // loading={isLoading}
            />
          </div>
        </div>
      </>
    </Layout>
  );
};
export default RateCardManagement;
