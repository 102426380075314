import {
  GoogleMap,
  Marker,
  Polyline,
  useLoadScript,
} from '@react-google-maps/api';
import { Progress } from 'antd';
import Loading from 'components/Loading';
import truckIcon from 'images/delivery-truck.svg';
import { useRef } from 'react';
import { useEffect, useState } from 'react';
import { GiCarWheel } from 'react-icons/gi';
import { useSelector } from 'react-redux';
const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: '190px',
  borderRadius: '5px',
};
const polylinePath = [
  { lat: 37.7749, lng: -122.4194 },
  { lat: 37.8044, lng: -122.2712 },
  { lat: 37.6879, lng: -122.4702 },
  { lat: 38.775, lng: -122.4194 },
];

const options = {
  strokeColor: '#FF0000',
  strokeOpacity: 1,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};
const center = { lat: 37.7749, lng: -122.4194 };

const Vehicleinfo = () => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDLGitEALPVLPoPzj7XTq2uA3Us-QutdiM',
    libraries,
  });
  const [markerPosition, setMarkerPosition] = useState(polylinePath[0]);
  const [progress, setProgress] = useState(0);
  const [iconRotation, setIconRotation] = useState(0);
  const markerRef = useRef(null);

  useEffect(() => {
    const moveMarker = () => {
      if (progress < 1) {
        setProgress((prev) => Math.min(prev + 0.000001, 1)); // Increase progress
      }
    };

    const animateMarker = () => {
      moveMarker();
      requestAnimationFrame(animateMarker);
    };

    animateMarker(); // Start animation

    return () => cancelAnimationFrame(animateMarker);
  }, [progress]);

  useEffect(() => {
    if (progress < 1) {
      const interpolatedPosition = interpolatePosition(polylinePath, progress);
      setMarkerPosition(interpolatedPosition);

      // Update rotation based on direction
      const nextPosition = interpolatePosition(polylinePath, progress + 0.001);
      const heading = calculateHeading(markerPosition, nextPosition);
      setIconRotation(heading);
    } else {
      // Set marker to final position at 100%
      setMarkerPosition(polylinePath[polylinePath.length - 1]);
    }
  }, [progress, polylinePath]);

  // Function to interpolate position along the path
  const interpolatePosition = (path, progress) => {
    const pathLength = path.length;
    const segmentLength = 1 / (pathLength - 1);
    const segmentIndex = Math.min(
      Math.floor(progress / segmentLength),
      pathLength - 2
    );
    const segmentProgress =
      (progress - segmentIndex * segmentLength) / segmentLength;

    const start = path[segmentIndex];
    const end = path[segmentIndex + 1];

    const lat = start.lat + (end.lat - start.lat) * segmentProgress;
    const lng = start.lng + (end.lng - start.lng) * segmentProgress;

    return { lat, lng };
  };

  // Function to calculate heading in degrees
  const calculateHeading = (start, end) => {
    const dy = end.lat - start.lat;
    const dx = end.lng - start.lng;
    const heading = Math.atan2(dy, dx) * (180 / Math.PI); // Convert to degrees
    return heading >= 0 ? heading : heading + 360; // Normalize to 0-360 degrees
  };
  if (loadError) {
    return <div>Error loading maps</div>;
  }

  return (
    <>
      <div className="  w-full">
        <div className="  md:flex justify-between w-full md:space-x-[10px] space-y-[0px] md:space-y-0">
          <div
            className=" w-full md:w-[70%] h-full flex pt-40 pb-20 rounded-sm shadow-md"
            style={{
              backgroundColor: themeMode === 'light' ? '#f7f7fa' : '#121212',
            }}
          >
            <div className=" w-[85%] space-y-30">
              <div className=" w-full flex items-center justify-center">
                <img
                  src="/images/images/truckVector.png"
                  className=" h-[200px] w-[580px]"
                  style={
                    {
                      // mixBlendMode: 'multiply',
                      // transform: 'rotateY(180deg)',
                    }
                  }
                />
              </div>
              <div className=" flex justify-center items-center">
                <div className="w-full flex justify-center items-center space-x-10">
                  <div className="">
                    <div className=" flex items-center space-x-5">
                      <GiCarWheel
                        style={{
                          color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                        }}
                      />
                      <div
                        className="text-[13px]"
                        style={{
                          color: themeMode === 'light' ? '' : '#9F9B93',
                        }}
                      >
                        Back-Left
                      </div>
                    </div>
                    <div
                      className=" font-semibold text-[13px] text-center"
                      style={{
                        color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                      }}
                    >
                      25 PSI
                    </div>
                  </div>
                  <div className=" ">
                    <div className=" flex items-center space-x-5">
                      <GiCarWheel
                        style={{
                          color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                        }}
                      />
                      <div
                        className=" text-[13px]"
                        style={{
                          color: themeMode === 'light' ? '' : '#9F9B93',
                        }}
                      >
                        Back-Right
                      </div>
                    </div>
                    <div
                      className=" font-semibold text-[13px] text-center"
                      style={{
                        color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                      }}
                    >
                      18 PSI
                    </div>
                  </div>
                </div>
                <div className="w-full flex justify-center items-center space-x-10">
                  <div className=" ">
                    <div className=" flex items-center space-x-5">
                      <GiCarWheel
                        style={{
                          color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                        }}
                      />
                      <div
                        className="text-[13px]"
                        style={{
                          color: themeMode === 'light' ? '' : '#9F9B93',
                        }}
                      >
                        Front-Left
                      </div>
                    </div>
                    <div
                      className=" font-semibold text-[13px] text-center"
                      style={{
                        color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                      }}
                    >
                      34 PSI
                    </div>
                  </div>
                  <div className=" ">
                    <div className=" flex items-center space-x-5">
                      <GiCarWheel
                        style={{
                          color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                        }}
                      />
                      <div
                        className=" text-[13px]"
                        style={{
                          color: themeMode === 'light' ? '' : '#9F9B93',
                        }}
                      >
                        Front-Right
                      </div>
                    </div>
                    <div
                      className=" font-semibold text-[13px] text-center"
                      style={{
                        color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                      }}
                    >
                      30 PSI
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" w-[15%] flex flex-col justify-between">
              <div className="flex  items-center ">
                <Progress
                  type="circle"
                  percent={70}
                  format={(percent) => (
                    <div
                      className={`${
                        themeMode === 'dark' ? 'text-[#f6f4f3]' : colorTheme
                      } text-center text-[12px] space-y-5`}
                    >
                      <div className=" font-bold">{`${percent}%`}</div>
                      <div>Fuel</div>
                    </div>
                  )}
                  strokeColor={`${themeMode == 'dark' ? 'white' : colorTheme}`}
                  size={80}
                  strokeWidth={7}
                />
              </div>
              <div className="flex  items-center ">
                <Progress
                  type="circle"
                  percent={45}
                  format={(percent) => (
                    <div
                      className={`${
                        themeMode === 'dark' ? 'text-[#f6f4f3]' : colorTheme
                      } text-center text-[12px] space-y-5`}
                    >
                      <div className=" font-bold">{`${percent}%`}</div>
                      <div>Oil</div>
                    </div>
                  )}
                  strokeColor={`${themeMode == 'dark' ? 'white' : colorTheme}`}
                  size={80}
                  strokeWidth={7}
                />
              </div>
              <div className="flex items-center ">
                <Progress
                  type="circle"
                  percent={60}
                  format={(percent) => (
                    <div
                      className={`${
                        themeMode === 'dark' ? 'text-[#f6f4f3]' : colorTheme
                      } text-center text-[12px] space-y-5`}
                    >
                      <div className=" font-bold">{`${percent}%`}</div>
                      <div className=" text-sm">Break Oil</div>
                    </div>
                  )}
                  strokeColor={`${themeMode == 'dark' ? 'white' : colorTheme}`}
                  size={80}
                  strokeWidth={7}
                />
              </div>
            </div>
          </div>

          <>
            <div
              className=" w-full md:w-[30%] h-auto space-y-25  rounded-sm p-20  shadow-md "
              style={{
                backgroundColor: themeMode === 'light' ? '#f7f7fa' : '#121212',
              }}
            >
              <>
                {isLoaded ? (
                  <>
                    <div>
                      <GoogleMap
                        className="rounded-sm"
                        mapContainerStyle={mapContainerStyle}
                        zoom={10}
                        center={center}
                      >
                        <Marker
                          position={markerPosition}
                          ref={markerRef}
                          icon={{
                            url: truckIcon,
                            scaledSize: new window.google.maps.Size(30, 30),
                            origin: new window.google.maps.Point(0, 0),
                            anchor: new window.google.maps.Point(25, 25),
                            rotation: 215,
                          }}
                        />
                        <Polyline path={polylinePath} options={options} />
                      </GoogleMap>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="w-full flex items-center justify-center py-[80px]">
                      <Loading />
                    </div>
                  </>
                )}

                <div className=" flex justify-between items-center !mt-[10px]">
                  <div>
                    <div
                      className="text-[13px]"
                      style={{
                        color: themeMode === 'light' ? '' : '#9F9B93',
                      }}
                    >
                      <span
                        className="font-semiBold"
                        style={{
                          color: themeMode === 'light' ? '' : '#9FA8DA',
                        }}
                      >
                        Body Type :
                      </span>
                      Truck
                    </div>
                    <div
                      className="text-[13px]"
                      style={{
                        color: themeMode === 'light' ? '' : '#9F9B93',
                      }}
                    >
                      <span
                        className="font-semiBold"
                        style={{
                          color: themeMode === 'light' ? '' : '#9FA8DA',
                        }}
                      >
                        Color :
                      </span>
                      White
                    </div>
                    <div
                      className="text-[13px]"
                      style={{
                        color: themeMode === 'light' ? '' : '#9F9B93',
                      }}
                    >
                      <span
                        className="font-semiBold"
                        style={{
                          color: themeMode === 'light' ? '' : '#9FA8DA',
                        }}
                      >
                        GVM :
                      </span>
                      3 TON
                    </div>
                    <div
                      className="text-[13px]"
                      style={{
                        color: themeMode === 'light' ? '' : '#9F9B93',
                      }}
                    >
                      <span
                        className="font-semiBold"
                        style={{
                          color: themeMode === 'light' ? '' : '#9FA8DA',
                        }}
                      >
                        Registeration :
                      </span>
                      69xyz
                    </div>
                  </div>
                  <div>
                    <div
                      className="text-[13px]"
                      style={{
                        color: themeMode === 'light' ? '' : '#9F9B93',
                      }}
                    >
                      <span
                        className="font-semiBold"
                        style={{
                          color: themeMode === 'light' ? '' : '#9FA8DA',
                        }}
                      >
                        Status :
                      </span>
                      Registed
                    </div>
                    <div
                      className="text-[13px]"
                      style={{
                        color: themeMode === 'light' ? '' : '#9F9B93',
                      }}
                    >
                      <span
                        className="font-semiBold"
                        style={{
                          color: themeMode === 'light' ? '' : '#9FA8DA',
                        }}
                      >
                        Year :
                      </span>
                      2022
                    </div>
                    <div
                      className="text-[13px]"
                      style={{
                        color: themeMode === 'light' ? '' : '#9F9B93',
                      }}
                    >
                      <span
                        className="font-semiBold"
                        style={{
                          color: themeMode === 'light' ? '' : '#9FA8DA',
                        }}
                      >
                        State :
                      </span>
                      OLD
                    </div>
                    <div
                      className="text-[13px]"
                      style={{
                        color: themeMode === 'light' ? '' : '#9F9B93',
                      }}
                    >
                      <span
                        className="font-semiBold"
                        style={{
                          color: themeMode === 'light' ? '' : '#9FA8DA',
                        }}
                      >
                        Exp :
                      </span>
                      12/25/2025
                    </div>
                  </div>
                </div>
              </>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default Vehicleinfo;
