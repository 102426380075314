import { Breadcrumb, Select, Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
import Checkbox from 'components/InputFields/Checkbox';
import PhoneInput from 'components/InputFields/PhoneInput';
import LabelGrid from 'components/LabelGrid';
import ToastNotification from 'components/ToastNotification';
import { add_all_devices } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useFormik } from 'formik';
import { usePermissionVariables } from 'Hooks/HasPermission';
import Layout from 'layout/AdminLayout';
import { useState } from 'react';
import { BiSave } from 'react-icons/bi';
import { BsDeviceSsd } from 'react-icons/bs';
import { FaPlus } from 'react-icons/fa6';
import { GoHome } from 'react-icons/go';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deviceStatusOptions } from 'utils/constant';
import { GET_DEVICES_KEY } from 'utils/queryKey';
import useValidationSchema from 'validation/useValidationSchema';
const { Title } = Typography;

const AddNewDevice = () => {
  const { Logs_View, Status_View, Configurations_View, General_View } =
    usePermissionVariables();
  const queryClient = useQueryClient();
  const { postRequest, useGetRequest, deleteRequest } = useApiClient();
  const { addDeviceModalSchema } = useValidationSchema();
  const { data, error, isLoading, refetch } = useGetRequest(
    '/api/GetDeviceModelsdropdown',
    { refetchOnMount: 'always' }
  );
  const deviceModelOptions = data?.data?.map((item) => {
    return {
      value: item.deviceModelId,
      label: item.name,
    };
  });
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);

  const formik = useFormik({
    initialValues: {
      deviceName: '',
      deviceModel: null,
      IMEI_No: '',
      serialNumber: '',
      simCardNumber: '+92',
      simProvider: '',
      status: null,
      isActive: false,
    },
    validationSchema: addDeviceModalSchema,
    onSubmit: ({
      deviceName,
      deviceModel,
      IMEI_No,
      serialNumber,
      simCardNumber,
      simProvider,
      status,
      isActive,
    }) => {
      setLoading(true);
      const paylaod = {
        deviceIdentifier: 'string',
        deviceName: deviceName,
        deviceModelID: deviceModel,
        serialNumber: serialNumber,
        imei: IMEI_No,
        simCardNumber: simCardNumber,
        simProvider: simProvider,
        status: status,
        isActive: isActive,
      };
      postRequest(add_all_devices, paylaod)
        .then((res) => {
          if (res?.success) {
            formik.resetForm();
            ToastNotification('success', res.message, themeMode);
            queryClient.invalidateQueries({
              queryKey: [GET_DEVICES_KEY],
            });
            navigate('/device-management');
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });
  console.log('formik', formik.errors);
  return (
    <Layout>
      <>
        <div className="mb-[30px] ">
          <Title
            level={4}
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
              fontFamily: 'inter-bold',
            }}
          >
            Add New Device
          </Title>
          <div className="flex justify-between items-center mb-20">
            <div>
              <Breadcrumb
                separator="/"
                items={[
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <GoHome />
                          <span
                            className=" cursor-pointer font-reg text-[13px]"
                            onClick={() => {
                              navigate('/dashboard');
                            }}
                          >
                            Dashboard
                          </span>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <BsDeviceSsd />
                          <span
                            className=" cursor-pointer font-reg text-[13px]"
                            onClick={() => {
                              navigate('/device-management');
                            }}
                          >
                            Device Management
                          </span>
                        </div>
                      </>
                    ),
                  },
                  {
                    title: (
                      <>
                        <div className=" flex items-center space-x-4">
                          <FaPlus
                            style={{
                              color: themeMode === 'light' ? colorTheme : '',
                            }}
                          />
                          <span
                            className=" font-bold text-[13px]"
                            style={{
                              color: themeMode === 'light' ? colorTheme : '',
                            }}
                          >
                            Add New Device
                          </span>
                        </div>
                      </>
                    ),
                  },
                ]}
              />
            </div>
            <PrimaryButton
              title="Save"
              icon={<BiSave className=" w-[17px] h-[17px]" />}
              htmlType="submit"
              loading={loading}
              onClick={() => {
                formik.handleSubmit();
              }}
            />
          </div>
        </div>
        <div className="">
          <form onSubmit={formik.handleSubmit}>
            <LabelGrid
              label="Add Device Settings"
              className=" rounded-[5px] px-20 py-20"
            >
              <div className="grid grid-cols-12 sm:gap-x-[30px]  gap-y-[25px] sm:gap-y-[5px] ">
                <div className=" col-span-12 md:col-span-3">
                  <TextField
                    formik={formik}
                    id={'deviceName'}
                    notModal={true}
                    label={'Device Name'}
                    maxLength={30}
                  />
                </div>
                <div className="  col-span-12 md:col-span-3">
                  <SelectField
                    formik={formik}
                    id={'deviceModel'}
                    label={'Device Model'}
                    notModal={true}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={deviceModelOptions}
                  />
                </div>
                <div className=" col-span-12 md:col-span-3">
                  <TextField
                    formik={formik}
                    id={'IMEI_No'}
                    notModal={true}
                    label={'IMEI #'}
                    maxLength={30}
                  />
                </div>
                <div className=" col-span-12 md:col-span-3">
                  <TextField
                    formik={formik}
                    id={'serialNumber'}
                    label={'Serial Number'}
                    notModal={true}
                    maxLength={30}
                  />
                </div>
                <div className=" col-span-12 md:col-span-3">
                  <PhoneInput
                    label="SIM Card Number"
                    name="simCardNumber"
                    value={formik.values.simCardNumber}
                    onChange={(value) => {
                      if (value.length <= 18) {
                        formik.setFieldValue('simCardNumber', value);
                        formik.setFieldTouched('simCardNumber', true, false);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.simCardNumber &&
                      formik.errors.simCardNumber
                    }
                    errorMessage={
                      formik.touched.simCardNumber &&
                      formik.errors.simCardNumber
                        ? formik.errors.simCardNumber
                        : null
                    }
                  />
                </div>
                <div className="  col-span-12 md:col-span-3">
                  <TextField
                    formik={formik}
                    id={'simProvider'}
                    label={'SIM Provider'}
                    maxLength={30}
                    notModal={true}
                  />
                </div>
                <div className="  col-span-12 md:col-span-3">
                  <SelectField
                    formik={formik}
                    id={'status'}
                    label={'Status'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={deviceStatusOptions}
                    notModal={true}
                  />
                </div>
                <div className="mt-20">
                  <Checkbox
                    title="IsActive"
                    id="isActive"
                    name="isActive"
                    checked={formik.values.isActive}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </LabelGrid>
          </form>
        </div>
      </>
    </Layout>
  );
};
export default AddNewDevice;
