import { Breadcrumb, Image, Spin, Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import DatePickerField from 'components/CustomFields/DatePickerField';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
import LabelGrid from 'components/LabelGrid';
import InfoPopover from 'components/PopOver/InfoPopOver';
import ToastNotification from 'components/ToastNotification';
import useAxiosClientTMS from 'config/useAxiosClientTMS';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import Layout from 'layout/AdminLayout';
import { useEffect, useState } from 'react';
import { BiSave } from 'react-icons/bi';
import { BsExclamationCircle } from 'react-icons/bs';
import { FaCloudArrowUp } from 'react-icons/fa6';
import { FiTruck } from 'react-icons/fi';
import { GoHome } from 'react-icons/go';
import { MdCancel } from 'react-icons/md';
import { RxCrossCircled } from 'react-icons/rx';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DATE_FORMATS, isActiveOptions } from 'utils/constant';
import { documentOptionsLabel } from 'utils/helper';
import {
  GET_DOCUMENTS_BY_ENTITY_TYPE_VEHICLE_DOCUMENTS,
  GET_VEHICLES_KEY,
} from 'utils/queryKey';
import useValidationSchema from 'validation/useValidationSchema';
import AddVehicleDocumnetModal from './AddVehicleDocumnetModal';
const { Title, Text } = Typography;

const EditVehicleInformation = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { putRequest, useGetRequest, deleteRequest, postRequestFormData } =
    useAxiosClientTMS();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const [items, setItems] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleUpload, setVisibleUpload] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [previewSrc, setPreviewSrc] = useState('');
  const { vehicleInformationSchema, addDriverDocumentSchema } =
    useValidationSchema();
  const [openAddVehicleDocumnetModal, setOpenAddVehicleDocumnetModal] =
    useState(false);
  const { data, isLoading, refetch } = useGetRequest(
    `/api/GetVehiclebyId?id=${id}`,
    {
      refetchOnMount: 'always',
    }
  );
  const { data: DocumentData, refetch: documentRefetch } = useGetRequest(
    `/api/GetDocumentsByEntityType?entityType=vehicledocuments&entityId=${id}`,
    {},
    GET_DOCUMENTS_BY_ENTITY_TYPE_VEHICLE_DOCUMENTS
  );
  const formikDocuments = useFormik({
    initialValues: {
      documentType: '',
      documentPicture: '',
      issueDate: '',
      expiryDate: '',
    },
    validationSchema: addDriverDocumentSchema,
    onSubmit: ({ documentType, documentPicture, issueDate, expiryDate }) => {
      setLoading(true);
      const paylaod = {
        EntityType: 'vehicledocuments',
        documentType: documentType,
        documentPicture: documentPicture,
        issueDate: dayjs(issueDate).format(DATE_FORMATS.PAYLOAD_FORMAT),
        expiryDate: dayjs(expiryDate).format(DATE_FORMATS.PAYLOAD_FORMAT),
      };
      addItem(paylaod);
      formikDocuments.resetForm();
      setLoading(false);
      setOpenAddVehicleDocumnetModal(false);
    },
  });
  const formik = useFormik({
    initialValues: {
      vehicleNumber: '',
      manufacturer: '',
      model: '',
      manufacturerYear: '',
      color: '',
      capacity: '',
      vinNumber: '',
      status: null,
      ownershipType: null,
      leaseExpiryDate: '',
      purchaseDate: '',
      ownerName: '',
      policyNumber: '',
      insuranceCompany: '',
      coverageAmount: '',
      expiryDate: '',
    },
    validationSchema: vehicleInformationSchema,
    onSubmit: ({
      vehicleNumber,
      manufacturer,
      model,
      manufacturerYear,
      color,
      capacity,
      status,
      ownershipType,
      leaseExpiryDate,
      purchaseDate,
      ownerName,
      insuranceCompany,
      policyNumber,
      expiryDate,
      coverageAmount,
    }) => {
      setLoading(true);
      const paylaod = {
        vehicleId: id,
        tenantId: 0,
        vehicleNumber: vehicleNumber,
        manufacturer: manufacturer,
        model: model,
        manufacturerYear: dayjs(manufacturerYear).format('YYYY'),
        color: color,
        capacity: capacity,
        status: status,
        vehicleOwnershipRequestDTO: {
          ownershipId: 0,
          vehicleId: 0,
          tenantId: 0,
          ownershipType: ownershipType,
          leaseExpiryDate: dayjs(leaseExpiryDate).format(
            'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
          ),
          purchaseDate: dayjs(purchaseDate).format(
            'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
          ),
          ownerName: ownerName,
        },
        vehicleInsuranceRequestDTO: {
          insuranceId: 0,
          tenantId: 0,
          vehicleId: 0,
          policyNumber: policyNumber,
          insuranceCompany: insuranceCompany,
          coverageAmount: coverageAmount,
          expiryDate: dayjs(expiryDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        },
      };
      putRequest(`/api/UpdateVehicle`, paylaod)
        .then((res) => {
          if (res?.success) {
            formik.resetForm();
            refetch();
            ToastNotification('success', res.message, themeMode);
            navigate('/vehicles');
            queryClient.invalidateQueries({
              queryKey: [GET_VEHICLES_KEY],
            });
            queryClient.invalidateQueries({
              queryKey: [GET_DOCUMENTS_BY_ENTITY_TYPE_VEHICLE_DOCUMENTS],
            });
            if (items?.length > 0) {
              items.forEach((item) => {
                const formDataPayload = {
                  DocumentType: item?.documentType,
                  EntityType: 'vehicledocuments',
                  IssueDate: item?.issueDate || '',
                  ExpiryDate: item?.expiryDate || '',
                  DocumentFile: item?.documentPicture,
                  EntityId: res?.data?.vehicleId,
                };
                postRequestFormData('/api/AddDocument', formDataPayload);
              });
            }
          } else {
            ToastNotification('error', res.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });
  useEffect(() => {
    if (data) {
      const {
        vehicleNumber,
        model,
        status,
        capacity,
        color,
        vin,
        manufacturerYear,
        manufacturer,
        vehicleOwnership: {
          leaseExpiryDate,
          ownerName,
          purchaseDate,
          ownershipType,
        },
        vehicleInsurance: {
          coverageAmount,
          expiryDate,
          insuranceCompany,
          policyNumber,
        },
      } = data.data;
      formik.setValues({
        ...formik.values,
        vehicleNumber: vehicleNumber,
        manufacturer: manufacturer,
        model: model,
        vinNumber: vin,
        manufacturerYear: dayjs(manufacturerYear),
        color: color,
        capacity: capacity,
        status: status,
        ownershipType: ownershipType,
        leaseExpiryDate: dayjs(leaseExpiryDate),
        purchaseDate: dayjs(purchaseDate),
        ownerName: ownerName,
        policyNumber: policyNumber,
        insuranceCompany: insuranceCompany,
        coverageAmount: coverageAmount,
        expiryDate: dayjs(expiryDate),
      });
    }
  }, [data]);
  const addItem = (paylaod) => {
    const newItem = paylaod || {
      documentName: '',
      documentType: '',
      documentPicture: '',
      issueDate: dayjs().format(DATE_FORMATS.PAYLOAD_FORMAT),
      expiryDate: dayjs().format(DATE_FORMATS.PAYLOAD_FORMAT),
    };
    setItems([...items, newItem]);
  };
  const deleteItem = (index) => {
    setItems(items.filter((_, i) => i !== index));
  };
  const handlePreview = (index) => {
    setPreviewSrc(
      process.env.REACT_APP_IMAGE_URL +
        DocumentData?.data[index]?.documentPath || ''
    );
    setVisible(true);
  };
  const handleVisibleChange = (index, value) => {
    setVisibleUpload((prev) => {
      const updatedVisibleUploads = [...prev];
      updatedVisibleUploads[index] = value;
      return updatedVisibleUploads;
    });
  };
  const delHandler = (record) => {
    deleteRequest(`/api/DeleteDocument?id=${record?.documentId}`)
      .then((res) => {
        if (res?.success) {
          if (record?.documentType === 'profilepicture') {
            formik.setFieldValue('profile', null);
            setFileList([]);
          }
          documentRefetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };
  return (
    <Layout>
      <>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-[30px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
                marginBottom: '10px',
              }}
            >
              TMS
            </Title>
            <div className="flex justify-between items-center mb-20">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <FiTruck />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/vehicles');
                              }}
                            >
                              Vehicles
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4 ">
                            <FaCloudArrowUp
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />
                            <span
                              className=" font-bold text-[13px]"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              Update Vehicle
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
              <div className="flex justify-end space-x-10">
                <div>
                  <SecondaryButton
                    title="Cancel"
                    onClick={() => {
                      navigate('/vehicles');
                    }}
                    icon={<MdCancel size={17} />}
                    className=" w-full text-[13px]"
                  />
                </div>
                <div>
                  <PrimaryButton
                    title="Save"
                    icon={<BiSave className=" w-[17px] h-[17px]" />}
                    htmlType="submit"
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
          <Spin spinning={isLoading}>
            <LabelGrid
              label="Vehicle Info"
              className=" rounded-[5px] px-20 py-20"
            >
              <div className="grid grid-cols-12 sm:gap-x-[20px] gap-y-[6px] sm:gap-y-5 ">
                <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                  <TextField
                    notModal={true}
                    formik={formik}
                    id={'vehicleNumber'}
                    label={'Vehicle Number'}
                    maxLength={30}
                  />
                </div>
                <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                  <TextField
                    notModal={true}
                    formik={formik}
                    id={'manufacturer'}
                    label={'Manufacturer'}
                    maxLength={30}
                  />
                </div>
                <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                  <TextField
                    notModal={true}
                    formik={formik}
                    id={'model'}
                    label={'Model'}
                    maxLength={30}
                  />
                </div>
                <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                  <DatePickerField
                    formik={formik}
                    id={'manufacturerYear'}
                    label={'Manufacturer Year'}
                    notModal={true}
                    picker="year"
                  />
                </div>
                <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                  <TextField
                    notModal={true}
                    formik={formik}
                    id={'color'}
                    label={'Color'}
                    maxLength={30}
                  />
                </div>
                <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                  <TextField
                    notModal={true}
                    formik={formik}
                    id={'capacity'}
                    label={'Capacity'}
                    maxLength={10}
                    type={'number'}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.length <= 10) {
                        formik.setFieldValue('capacity', value);
                        formik.setFieldTouched('capacity', true, false);
                      }
                    }}
                  />
                </div>
                <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                  <TextField
                    notModal={true}
                    formik={formik}
                    id={'vinNumber'}
                    label={'VIN'}
                    maxLength={17}
                  />
                </div>
                <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                  <SelectField
                    formik={formik}
                    id={'status'}
                    label={'Status'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={isActiveOptions}
                    notModal={true}
                  />
                </div>
              </div>
            </LabelGrid>
            <LabelGrid
              label="Vehicle OwnerShip"
              className=" rounded-[5px] px-20 py-20"
            >
              <div className="grid grid-cols-12 sm:gap-x-[20px] gap-y-[6px] sm:gap-y-0 ">
                <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                  <SelectField
                    formik={formik}
                    id={'ownershipType'}
                    label={'Ownership Type'}
                    optionId={'value'}
                    optionName={'label'}
                    optionData={[
                      {
                        value: 'lease',
                        label: 'Lease',
                      },
                      {
                        value: 'owner',
                        label: 'Owner',
                      },
                    ]}
                    notModal={true}
                  />
                </div>
                <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                  <TextField
                    notModal={true}
                    formik={formik}
                    id={'ownerName'}
                    label={'Name'}
                    maxLength={30}
                  />
                </div>

                <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                  <DatePickerField
                    formik={formik}
                    id={'purchaseDate'}
                    label={'Purchase Date'}
                    notModal={true}
                  />
                </div>
                {formik.values.ownershipType === 'lease' && (
                  <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                    <DatePickerField
                      formik={formik}
                      id={'leaseExpiryDate'}
                      label={'Lease Expiry Date'}
                      notModal={true}
                    />
                  </div>
                )}
              </div>
            </LabelGrid>
            <LabelGrid
              label="Vehicle Insurance"
              className=" rounded-[5px] px-20 py-20"
            >
              <div className="grid grid-cols-12 sm:gap-x-[20px] gap-y-[6px] sm:gap-y-0 ">
                <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                  <TextField
                    notModal={true}
                    formik={formik}
                    id={'policyNumber'}
                    label={'Policy Number'}
                    maxLength={30}
                  />
                </div>
                <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                  <TextField
                    notModal={true}
                    formik={formik}
                    id={'insuranceCompany'}
                    label={'Insurance Company'}
                    maxLength={30}
                  />
                </div>

                <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                  <TextField
                    notModal={true}
                    formik={formik}
                    id={'coverageAmount'}
                    label={'Coverage Amount'}
                    maxLength={10}
                    type={'number'}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.length <= 10) {
                        formik.setFieldValue('coverageAmount', value);
                        formik.setFieldTouched('coverageAmount', true, false);
                      }
                    }}
                  />
                </div>
                <div className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 ">
                  <DatePickerField
                    formik={formik}
                    id={'expiryDate'}
                    label={'Expiry Date'}
                    notModal={true}
                  />
                </div>
              </div>
            </LabelGrid>
            <LabelGrid
              label="Vehicle Documents"
              className=" rounded-[5px] px-20 py-20"
            >
              {/* {items?.length === 0 && ( */}
              <div className="flex justify-end items-center">
                <div
                  className=" text-sm underline ml-[10px] cursor-pointer my-[10px]"
                  style={{
                    color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                  }}
                  onClick={() => setOpenAddVehicleDocumnetModal(true)}
                >
                  <span className="flex">Upload Document</span>
                </div>
                <sup>
                  <InfoPopover content={'Upload Documents'}>
                    <BsExclamationCircle className="ml-2 w-10 h-10" />
                  </InfoPopover>
                </sup>
              </div>
              {/* )} */}
              <div
                className={`grid grid-cols-6 text-sm gap-20 ${
                  items?.length !== 0 ? 'mt-15' : ''
                }`}
              >
                {DocumentData?.data?.map((item, index) => (
                  <>
                    <div className="flex items-center gap-8" key={index}>
                      <Text
                        underline
                        code
                        className="cursor-pointer text-[12px]"
                        onClick={() => handlePreview(index)}
                      >
                        {documentOptionsLabel(item.documentType).label || '--'}
                      </Text>
                      <div
                        onClick={() => delHandler(item)}
                        className="cursor-pointer"
                      >
                        <RxCrossCircled className="w-15 mt-2 h-15 text-[red]" />
                      </div>
                    </div>
                  </>
                ))}

                {items?.map((item, index) => (
                  <>
                    <div className="flex items-center gap-8" key={index}>
                      <Text
                        underline
                        code
                        className="cursor-pointer text-[12px]"
                        onClick={() => {
                          handleVisibleChange(index, true);
                        }}
                      >
                        {documentOptionsLabel(item.documentType).label || '--'}
                      </Text>
                      <div
                        onClick={() => deleteItem(index)}
                        className="cursor-pointer"
                      >
                        <RxCrossCircled className="w-15 mt-2 h-15 text-[red]" />
                      </div>
                    </div>
                    {visibleUpload[index] && (
                      <Image
                        width={200}
                        style={{ display: 'none' }}
                        src={
                          item?.documentPicture?.url ||
                          (item?.documentPicture instanceof File &&
                            URL.createObjectURL(item.documentPicture)) ||
                          ''
                        }
                        preview={{
                          visible: visibleUpload[index] || false,
                          src:
                            item?.documentPicture?.url ||
                            (item?.documentPicture instanceof File &&
                              URL.createObjectURL(item.documentPicture)) ||
                            '',
                          onVisibleChange: (value) =>
                            handleVisibleChange(index, value),
                        }}
                      />
                    )}
                  </>
                ))}
              </div>
            </LabelGrid>
            {visible && (
              <Image
                width={200}
                style={{ display: 'none' }}
                src={previewSrc || ''}
                preview={{
                  visible,
                  src: previewSrc || '',
                  onVisibleChange: (value) => {
                    setVisible(value);
                  },
                }}
              />
            )}
          </Spin>
        </form>
        {openAddVehicleDocumnetModal && (
          <AddVehicleDocumnetModal
            setOpenAddVehicleDocumnetModal={setOpenAddVehicleDocumnetModal}
            openAddVehicleDocumnetModal={openAddVehicleDocumnetModal}
            items={items}
            setItems={setItems}
            formikDocuments={formikDocuments}
          />
        )}
      </>
    </Layout>
  );
};
export default EditVehicleInformation;
