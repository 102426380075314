import { QuestionCircleOutlined } from '@ant-design/icons';
import { usePermissionVariables } from 'Hooks/HasPermission';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import PopConfirm from 'components/PopConfirm';
import Status from 'components/Status';
import Table from 'components/Table';
import ToastNotification from 'components/ToastNotification';
import { delete_all_devices } from 'config/services';
import useApiClient from 'config/useAxiosClient';
import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { MdDeleteOutline, MdOutlineModeEditOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GET_DEVICES_KEY } from 'utils/queryKey';

const Devices = () => {
  const { postRequest, useGetRequest, deleteRequest } = useApiClient();
  const navigate = useNavigate();
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { All_Devices_Add, All_Devices_Edit, All_Devices_Delete } =
    usePermissionVariables();

  const { data, error, isLoading, refetch } = useGetRequest(
    '/api/GetAllDevices',
    {},
    GET_DEVICES_KEY
  );
  // States
  const [openAddUserModal, setOpenUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecord, setTotalRecord] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState({});
  const [filters, setFilters] = useState({
    // name: ["Joe"],
  });

  const columns = [
    { title: 'Device ID', dataIndex: 'deviceIdentifier', ellipsis: true },
    {
      title: (
        <div className="flex justify-between items-center">Device Name</div>
      ),
      dataIndex: 'deviceName',
      ellipsis: true,
      sorter: (a, b) => a.deviceName.localeCompare(b.deviceName),
      sortDirections: ['ascend', 'descend'],
      sortOrder:
        sortOrder?.column?.dataIndex === 'deviceName'
          ? sortOrder.order
          : 'ascend',
      showSorterTooltip: false,
      render: (text, record) => (
        <>
          <span>{text}</span>
        </>
      ),
    },
    { title: 'Device Model', dataIndex: 'deviceModelName', ellipsis: true },
    { title: 'IMEI No', dataIndex: 'imei', ellipsis: true },

    { title: 'SIM Provider', dataIndex: 'simProvider', ellipsis: true },
    { title: 'SIM Card #', dataIndex: 'simCardNumber', ellipsis: true },
    { title: 'Serial #', dataIndex: 'serialNumber', ellipsis: true },
    {
      title: <span className="flex items-center justify-center">Status</span>,
      dataIndex: 'isActive',
      ellipsis: true,
      render: (tag, record) => {
        return (
          <span className="flex items-center justify-center">
            <Status tag={record?.isActive} />
          </span>
        );
      },
    },
    ...(All_Devices_Edit || All_Devices_Delete
      ? [
          {
            title: (
              <span className=" flex items-center justify-center">Action</span>
            ),
            dataIndex: '',
            render: (text, record) => (
              <span className="flex justify-center items-center space-x-2">
                {All_Devices_Edit && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                    onClick={() => {
                      setRowData(record);
                      navigate(`/edit-device/${record?.deviceId}`);
                    }}
                  >
                    <MdOutlineModeEditOutline className="w-[15px] h-[15px] cursor-pointer" />
                  </div>
                )}

                {All_Devices_Delete && (
                  <div
                    className="p-[5px] rounded-sm"
                    style={{
                      backgroundColor:
                        themeMode === 'light' ? 'white' : '#121212',
                    }}
                  >
                    <PopConfirm
                      title="Delete"
                      description="Are you sure to delete this task? "
                      placement="left"
                      onClick={() => delHandler(record)}
                      icon={
                        <div className=" flex items-center justify-center ">
                          <QuestionCircleOutlined
                            style={{ color: 'red', marginTop: '5px' }}
                          />
                        </div>
                      }
                    >
                      <MdDeleteOutline className=" w-[15px] h-[15px] cursor-pointer" />
                    </PopConfirm>
                  </div>
                )}
              </span>
            ),
          },
        ]
      : []),
  ];

  const delHandler = (record) => {
    deleteRequest(`${delete_all_devices}${record?.deviceId}`)
      .then((res) => {
        if (res?.success) {
          const newTotalRecord = totalRecord - 1; // Decrement the total record count
          setTotalRecord(newTotalRecord); // Update the state

          // Check if we need to change the page number
          if (newTotalRecord % rowsPerPage === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1); // Redirect to the previous page
          }
          refetch();
          ToastNotification('success', res.message, themeMode);
        } else {
          ToastNotification('error', res.message, themeMode);
        }
      })
      .catch((err) =>
        ToastNotification('error', 'Something went wrong', themeMode)
      );
  };
  useEffect(() => {
    refetch();
  }, [data?.data]);

  return (
    <>
      {All_Devices_Add && (
        <div className="sm:flex justify-end">
          <PrimaryButton
            title="Add New Device"
            icon={<FaPlus />}
            onClick={() => {
              navigate('/add-new-device');
            }}
          />
        </div>
      )}

      <Table
        colorTheme={colorTheme}
        data={data?.data || []}
        columns={columns}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        setTotalRecord={setTotalRecord}
        totalRecord={totalRecord}
        setPageNumber={setPageNumber}
        setRowsPerPage={setRowsPerPage}
        setSortOrder={setSortOrder}
        setFilters={setFilters}
        //   loading={isLoading}
      />
    </>
  );
};
export default Devices;
