import { Drawer as AntDrawer } from 'antd';
import { useSelector } from 'react-redux';
import './index.css';
const Drawer = ({ body, open, setOpen, fontSize = '12px' }) => {
  const { themeMode } = useSelector((state) => state?.states);
  const classNames = {
    body: 'my-drawer-body',
    mask: 'my-drawer-mask',
    header: 'my-drawer-header',
    footer: 'my-drawer-footer',
    content: 'my-drawer-content',
  };
  const drawerStyles = {
    mask: {
      backdropFilter: 'blur(2px)',
    },
    content: {
      boxShadow:
        themeMode === 'light' ? '-10px 0 10px #666' : '-10px 0 10px #3e3838',
      borderLeft: themeMode === 'light' ? '2px dotted #333' : '2px dotted #333',
      backgroundColor: themeMode === 'light' ? '' : '#000000',
    },
    header: {
      display: 'none',
    },
    body: {
      fontSize: fontSize,
      border: themeMode === 'light' ? '1px solid #D0E0FF' : '1px solid #3e3838',
      margin: '5px',
      borderRadius: '8px',
      color: themeMode === 'light' ? 'black' : 'light',
      backgroundImage:
        themeMode === 'light'
          ? 'linear-gradient(to bottom, #EEEDFF, #cdcbe2b7)'
          : 'linear-gradient(#343434, #000000)',
    },
  };
  return (
    <>
      <AntDrawer
        placement="right"
        onClose={() => setOpen(!open)}
        open={open}
        width={300}
        className={classNames.body}
        style={drawerStyles.content}
        maskStyle={drawerStyles.mask}
        headerStyle={drawerStyles.header}
        bodyStyle={drawerStyles.body}
      >
        {body}
      </AntDrawer>
    </>
  );
};
export default Drawer;
