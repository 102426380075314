import { NotificationOutlined } from '@ant-design/icons';
import { Badge, Dropdown, Menu } from 'antd';
import PopOver from 'components/PopOver';
import dayjs from 'dayjs';
import { useState } from 'react';
import { BsBell } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';

const RealNotification = () => {
  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { realNotifications } = useSelector(
    (state) => state?.nonPersistedStates
  );

  const [selectedKeys, setSelectedKeys] = useState([null]);
  const dispatch = useDispatch();
  const handleMenuClick = ({ key }) => {
    // dispatch(
    //   setRealNotifications({
    //     notificationId: key,
    //     isRead: true,
    //   })
    // );
  };

  const itemsNotifications = (
    <Menu.Item key={'no-notification'}>
      <span
        className={`text-[10px] ${
          themeMode === 'light' ? 'text-black' : 'text-[#9FA8DA]'
        }`}
      >
        {'No Notification'}
      </span>
    </Menu.Item>
  );

  const items =
    realNotifications?.length > 0
      ? realNotifications?.map((item, index) => (
          <Menu.Item
            key={item?.notificationId}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            icon={
              <Badge dot={!item?.isRead}>
                <NotificationOutlined style={{ fontSize: 12 }} />
              </Badge>
            }
          >
            <span
              className={`text-[11px]  ${
                themeMode === 'light' ? 'text-black' : 'text-[#9FA8DA]'
              }
              ${!item?.isRead ? 'font-[600]' : ''}  
              `}
            >
              {item?.message?.length > 40 ? (
                <PopOver
                  title={item?.title}
                  content={item?.message}
                  width={225}
                >
                  <div className="cursor-pointer text-[11px] hover:text-inherit">
                    {item?.title}
                  </div>
                  <div className="cursor-pointer text-[10px] hover:text-inherit">
                    {item?.message.slice(0, 40)}...
                  </div>
                  <div className="cursor-pointer font-[500] text-[9px]">
                    {dayjs(item?.time).fromNow()}
                  </div>
                </PopOver>
              ) : (
                <>
                  <div className="cursor-pointer text-[11px] ">
                    {item?.title}
                  </div>
                  <div className="cursor-pointer text-[10px]">
                    {item?.message}
                  </div>
                  <div className="cursor-pointer font-[500] text-[9px]">
                    {dayjs(item?.time).fromNow()}
                  </div>
                </>
              )}
            </span>
          </Menu.Item>
        ))
      : itemsNotifications;

  const menu = (
    <Menu
      onClick={handleMenuClick}
      selectedKeys={selectedKeys}
      className="!space-y-5 show-scroll max-h-[300px] min-h-[40px] overflow-y-auto"
      style={{
        backgroundColor: themeMode === 'light' ? '#F7F7FA' : '#121212',
      }}
    >
      {items}
    </Menu>
  );

  return (
    <div className="cursor-pointer flex items-center">
      <Dropdown
        overlay={menu}
        trigger={['click']}
        placement="bottom"
        overlayStyle={{ maxWidth: 320 }}
        arrow
      >
        <Badge count={items?.length} showZero color="#f50" size="small">
          <BsBell
            size={17}
            style={{
              color: themeMode === 'light' ? colorTheme : '#9FA8DA',
            }}
          />
        </Badge>
      </Dropdown>
    </div>
  );
};

export default RealNotification;
