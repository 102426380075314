import { Typography, theme } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const { Title } = Typography;
const { useToken } = theme;

// Custom tooltip component
const CustomTooltip = ({ payload, label, active }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white flex justify-center items-center rounded-lg shadow-lg w-[50px] h-[50px]">
        <div>
          <p className="text-md font-bold text-[#5A5881]">{label}</p>
          {payload.map((entry, index) => (
            <p key={`item-${index}`} className="text-sm text-[#5A5881]">
              <span>{entry.value}</span>
            </p>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

// Main component
const DoubleLineChart = () => {
  const { themeMode } = useSelector((state) => state?.states);
  const [activeIndex, setActiveIndex] = useState(null);
  const { token } = useToken();
  const primaryColor = token.colorPrimary;

  const handleMouseOver = (data, index) => {
    setActiveIndex(index);
  };

  const handleMouseOut = () => {
    setActiveIndex(null);
  };

  const renderCustomXAxisTick = (props) => {
    const { x, y, payload, index } = props;
    const isActive = index === activeIndex;
    return (
      <text
        x={x}
        y={y}
        dy={16}
        textAnchor="middle"
        fill={isActive ? primaryColor : '#666'}
        fontSize={isActive ? 14 : 12}
        fontWeight={isActive ? 'bold' : 'normal'}
      >
        {payload.value}
      </text>
    );
  };
  const renderCustomYAxisTick = (props) => {
    const { x, y, payload, index } = props;
    const isActive = index === activeIndex;
    return (
      <text
        x={x}
        y={y}
        dy={16}
        dx={-20}
        textAnchor="middle"
        fill={isActive ? primaryColor : '#666'}
        fontSize={12}
        fontWeight="normal"
      >
        {payload.value}
      </text>
    );
  };
  // Sample data for the chart
  const data = [
    { name: 'Mon', value: 4000, value2: 2400 },
    { name: 'Tue', value: 3000, value2: 1398 },
    { name: 'Wed', value: 2000, value2: 9800 },
    { name: 'Thus', value: 2780, value2: 3908 },
    { name: 'Fri', value: 1890, value2: 4800 },
    { name: 'Sat', value: 2390, value2: 3800 },
    { name: 'Sun', value: 3490, value2: 4300 },
  ];

  return (
    <div
      className="h-[250px] px-[15px] py-[20px] rounded-[8px]"
      style={{
        backgroundColor: themeMode === 'light' ? '#f6f4f3' : '#121212',
      }}
    >
      <div className="flex justify-end items-center">
        <div className="flex justify-between items-center space-x-5">
          <div
            className="w-[7px] h-[7px] rounded-full"
            style={{ backgroundColor: primaryColor }}
          />
          <div>
            <Title
              level={5}
              style={{
                color: primaryColor,
                fontFamily: 'inter-semibold',
                height: 18,
              }}
            >
              Line Chart
            </Title>
          </div>
        </div>
      </div>
      <div>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={data}
            // margin={{ top: 10, right: 50, left: 0, bottom: 0 }}
            onMouseMove={(e) =>
              e.isTooltipActive &&
              handleMouseOver(e.activePayload[0], e.activeTooltipIndex)
            }
            onMouseLeave={handleMouseOut}
          >
            <defs>
              <filter id="shadow" height="200%">
                <feDropShadow
                  dx="0"
                  dy="20"
                  stdDeviation="15"
                  floodColor={primaryColor}
                  floodOpacity="0.5"
                />
              </filter>
            </defs>
            <XAxis
              dataKey="name"
              axisLine={false}
              tick={renderCustomXAxisTick}
              tickLine={false}
            />
            <YAxis
              tick={renderCustomYAxisTick}
              axisLine={false}
              tickLine={false}
            />
            <Tooltip cursor={false} content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey="value"
              stroke={primaryColor}
              strokeWidth={3}
              filter="url(#shadow)"
              dot={{ stroke: primaryColor, strokeWidth: 2 }}
            />
            <Line
              type="monotone"
              dataKey="value2" // New line for the second dataset
              stroke="#82ca9d" // Different color for the second line
              strokeWidth={3}
              filter="url(#shadow)"
              dot={{ stroke: '#82ca9d', strokeWidth: 2 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default DoubleLineChart;
