import { Breadcrumb, Spin, Typography } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import SecondaryButton from 'components/Buttons/SecondaryButton';
import SelectField from 'components/CustomFields/SelectField';
import TextField from 'components/CustomFields/TextField';
import Checkbox from 'components/InputFields/Checkbox';
import LabelGrid from 'components/LabelGrid';
import SvgTrailer from 'components/SVG/SvgTrailer';
import ToastNotification from 'components/ToastNotification';
import useAxiosClientTMS from 'config/useAxiosClientTMS';
import { useFormik } from 'formik';
import Layout from 'layout/AdminLayout';
import { useEffect, useState } from 'react';
import { BiSave } from 'react-icons/bi';
import { FaCloudArrowUp } from 'react-icons/fa6';
import { GoHome } from 'react-icons/go';
import { MdCancel } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { trailerStatus } from 'utils/constant';
import { createOptions } from 'utils/helper';
import { GET_TRAILERS_KEY } from 'utils/queryKey';
import useValidationSchema from 'validation/useValidationSchema';
const { Title, Text } = Typography;

const Drivers = () => {
  const location = useLocation();
  const { pageNumber } = location.state || { pageNumber: 1 };
  const queryClient = useQueryClient();
  const { useGetRequest, putRequest } = useAxiosClientTMS();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { themeMode, colorTheme } = useSelector((state) => state?.states);
  const { trailerFormSchema } = useValidationSchema();

  const { id } = useParams();
  const {
    data: TrailerData,
    isLoading,
    refetch,
  } = useGetRequest(`api/GetTrailerByIdAsync?id=${id}`, {
    refetchOnMount: 'always',
  });
  const {
    data: trailerData,
    error,
    isLoading: trailerLoading,
  } = useGetRequest(`/api/TrailerTypesDropdown`, {
    refetchOnMount: 'always',
  });
  const trailerOptions = createOptions(
    trailerData?.data,
    'trailerType',
    'trailerTypeId'
  );

  const formik = useFormik({
    initialValues: {
      trailerNumber: '',
      trailerType: '',
      capacity: null,
      length: null,
      width: null,
      height: null,
      status: null,
      isActive: false,
    },
    validationSchema: trailerFormSchema,
    onSubmit: ({
      trailerNumber,
      trailerType,
      capacity,
      length,
      width,
      height,
      status,
      isActive,
    }) => {
      setLoading(true);
      const paylaod = {
        trailerId: id,
        trailerNumber,
        trailerTypeId: trailerType,
        capacity,
        length,
        width,
        height,
        status,
        isActive,
      };
      putRequest(`/api/UpdateTrailer`, paylaod)
        .then((res) => {
          if (res?.success) {
            queryClient.invalidateQueries({
              queryKey: [GET_TRAILERS_KEY],
            });
            ToastNotification('success', res?.message, themeMode);
            formik.resetForm();
            navigate('/trailers');
          } else {
            ToastNotification('error', res?.message, themeMode);
          }
        })
        .catch((err) =>
          ToastNotification('error', 'Something went wrong', themeMode)
        )
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    if (TrailerData?.data) {
      const {
        trailerNumber,
        trailerTypeId,
        capacity,
        length,
        width,
        height,
        status,
        isActive,
      } = TrailerData?.data;

      formik.setValues({
        ...formik.values,
        trailerNumber,
        trailerType: trailerTypeId,
        capacity,
        length,
        width,
        height,
        status,
        isActive,
      });
    }
  }, [TrailerData?.data]);

  return (
    <Layout>
      <Spin spinning={isLoading}>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-[30px] ">
            <Title
              level={4}
              style={{
                color: themeMode === 'light' ? colorTheme : '#9FA8DA',
                fontFamily: 'inter-bold',
                marginBottom: '10px',
              }}
            >
              TMS
            </Title>
            <div className="flex justify-between items-center mb-20">
              <div>
                <Breadcrumb
                  separator="/"
                  items={[
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <GoHome />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/dashboard');
                              }}
                            >
                              Dashboard
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4">
                            <SvgTrailer size={20} />
                            <span
                              className=" cursor-pointer font-reg text-[13px]"
                              onClick={() => {
                                navigate('/trailers');
                              }}
                            >
                              Trailer Management
                            </span>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: (
                        <>
                          <div className=" flex items-center space-x-4 ">
                            <FaCloudArrowUp
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            />
                            <span
                              className=" font-bold text-[13px]"
                              style={{
                                color: themeMode === 'light' ? colorTheme : '',
                              }}
                            >
                              Edit Trailer
                            </span>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
              <div className="flex justify-end space-x-10">
                <div>
                  <SecondaryButton
                    title="Cancel"
                    onClick={() => {
                      navigate('/trailers');
                    }}
                    icon={<MdCancel size={17} />}
                    className=" w-full text-[13px]"
                  />
                </div>
                <div>
                  <PrimaryButton
                    title="Save"
                    icon={<BiSave className=" w-[17px] h-[17px]" />}
                    htmlType="submit"
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
          <LabelGrid label="Driver Info" className=" rounded-[5px] px-20 py-20">
            <div className="grid grid-cols-12 sm:gap-x-[20px] sm:gap-y-5 sm:mb-[30px] ">
              <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                <TextField
                  formik={formik}
                  id={'trailerNumber'}
                  label={'Trailer Number'}
                  maxLength={30}
                  notModal={true}
                />
              </div>
              <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                <SelectField
                  formik={formik}
                  id={'trailerType'}
                  label={'Trailer Type'}
                  maxLength={30}
                  notModal={true}
                  isLoading={trailerLoading}
                  optionData={trailerOptions}
                  optionId={'value'}
                  optionName={'label'}
                />
              </div>
              <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                <TextField
                  formik={formik}
                  id={'capacity'}
                  label={'Capacity'}
                  maxLength={30}
                  type="number"
                  notModal={true}
                />
              </div>
              <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                <TextField
                  formik={formik}
                  id={'width'}
                  label={'Width'}
                  type="number"
                  maxLength={30}
                  notModal={true}
                />
              </div>
              <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                <TextField
                  formik={formik}
                  id={'height'}
                  type="number"
                  label={'Height'}
                  maxLength={30}
                  notModal={true}
                />
              </div>
              <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                <TextField
                  formik={formik}
                  id={'length'}
                  label={'Length'}
                  type="number"
                  maxLength={30}
                  notModal={true}
                />
              </div>
              <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 ">
                <SelectField
                  formik={formik}
                  id={'status'}
                  label={'Assign Status'}
                  maxLength={30}
                  notModal={true}
                  optionData={trailerStatus}
                  optionId={'value'}
                  optionName={'label'}
                />
              </div>
              <div className="xl:col-span-3 lg:col-span-4 sm:col-span-6 col-span-12 flex items-center">
                <Checkbox
                  title="IsActive"
                  id="isActive"
                  name="isActive"
                  checked={formik.values.isActive}
                  onChange={formik.handleChange}
                  error={formik.errors.isActive}
                  errorMessage={
                    formik.touched.isActive && formik.errors.isActive
                      ? formik.errors.isActive
                      : null
                  }
                />
              </div>
            </div>
          </LabelGrid>
        </form>
      </Spin>
    </Layout>
  );
};

export default Drivers;
